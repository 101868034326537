import { CAPITAL } from "../constants";
import capitalService from "../services/capital.service";

import {
  GET_CAPITAL_LIST,
  // ADD_CAPITAL,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  LOAD_CAPITAL_SKELETON,
} from "./types";
export const getCapitalList = (companyId) => async (dispatch) => {
  dispatch({type: LOAD_CAPITAL_SKELETON, payload: false})
  return capitalService.getCapital(companyId).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_CAPITAL_LIST, payload: resp });
    }else{
      dispatch({type: LOAD_CAPITAL_SKELETON, payload: false})
    }
  });
};

export const addCapital = (payload) => async (dispatch) => {
  return capitalService.addCapital(payload).then((resp) => {
    if (resp.status) {
      if (payload?.capitalType === CAPITAL.paidUp) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data, });
      }
      // dispatch({type: ADD_CAPITAL,payload: resp.data,});
      dispatch(getCapitalList(payload.company));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve();
  });
};