import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  BOB_SERVICES,
  CUSTOM_SELECT_STYLES,
  I_POP_OVER,
  POSITIONS,
  POTENTIAL_PURPOSES,
  POTENTIAL_PURPOSE_COMPANIES,
  POTENTIAL_PURPOSE_PRODUCT_SERVICES,
  POTENTIAL_PURPOSE_PROFILES,
  PROFILE_STATUS,
  SELECT_BOX_THEME,
  country,
} from "../../constants";
import Select from "react-select";
import SuccessModal from "./SuccessModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBusinessProfile,
  editBusinessProfile,
} from "../../actions/bookOfBusiness";
import InfoModalStatic from "../dashboard/infoModalStatic";
import { isDesktop, isMobile } from "react-device-detect";

export const getPotentialPurpose = (company) => {
  var position = null;
  var service = null;
  if (company) {
    if ("bookOfBusinessGroupId" in company) {
      position = company?.bookOfBusinessGroupId?.position || null;
      service = company?.bookOfBusinessGroupId?.service || null;
    } else {
      position =
        (Array.isArray(company?.bookOfBusinessGroup)
          ? company?.bookOfBusinessGroup[0]?.position
          : company?.bookOfBusinessGroup?.position) || null;
      service =
        (Array.isArray(company?.bookOfBusinessGroup)
          ? company?.bookOfBusinessGroup[0]?.service
          : company?.bookOfBusinessGroup?.service) || null;
    }

    if (position && service) {
      var positionList =
        BOB_SERVICES.PROFILES === service
          ? POTENTIAL_PURPOSE_PROFILES
          : BOB_SERVICES.PRODUCT_AND_SERVICES === service
          ? POTENTIAL_PURPOSE_PRODUCT_SERVICES
          : BOB_SERVICES.COMPANIES === service
          ? POTENTIAL_PURPOSE_COMPANIES
          : [];
      return positionList.filter((x) => x.value === position)[0]?.name || null;
    }
  } else {
    return "";
  }
};

export default function ProfileForm({
  formOpen,
  formClose,
  companyDetail,
  bobSelected,
  isEdit,
  setIsEdit,
  limit,
  setStatus,
  status,
  setPage,
  setActivePage,
  isTempBob,
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const [successOpen, setSuccessOpen] = useState();
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { isDefaultCompany } = useSelector((state) => state.company);
  const isTempRef = useRef(false);
  const formSubmitRef = useRef();

  const formData = watch();

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: mobileFields,
    append: appendMobile,
    remove: removeMobile,
  } = useFieldArray(
    {
      control,
      required: true,
      name: "number",
    },
    {
      control,
      required: true,
      name: "areaCode",
    }
  );
  const handleclose = async () => {
    if (isTempBob || bobSelected === null) {
      if (bobSelected && "isTemp" in bobSelected) {
        isTempRef.current = bobSelected?.isTemp;
      } else {
        isTempRef.current = true;
      }
      await onSubmit(formData);
    }
    reset();
    setIsEdit(false);
    formClose();
  };

  useEffect(() => {
    // var designation = POSITIONS.filter(
    //   (x) =>
    //     x?.value ===
    //     parseInt(
    //       companyDetail?.position || companyDetail?.defaultDesignationRole || 1
    //     )
    // )[0];
    // setValue("designation", designation);
    if (!isEdit) {
      return;
    }
    if (isEdit && bobSelected) {
      setValue("first_name", bobSelected?.first_name);
      setValue("last_name", bobSelected?.last_name);
      setValue("email", bobSelected?.email);
      bobSelected?.email?.forEach((email, index) => {
        setValue(`email[${index}]`, email);
      });
      setValue("number", bobSelected?.mobileNumber);
      bobSelected?.mobileNumber?.forEach((number, index) => {
        setValue(`areaCode[${index}]`, number?.areaCode);
        setValue(`number[${index}]`, number?.number);
      });
      setValue("companyName", bobSelected?.company?.companyName);
      setValue(
        "position",
        POSITIONS.filter((x) => x.value === parseInt(bobSelected?.position))[0]
          ?.name
      );
      setValue("industry", bobSelected?.industry);
      setValue("linkdin", bobSelected?.linkedInUrl);
      setValue("country", bobSelected?.country);
      setValue("companyProfile", bobSelected?.companyProfile);
      setValue("positionProfile", bobSelected?.positionProfile);

      setValue("serviceProductName", bobSelected?.serviceProductName);
      setValue("description", bobSelected?.description);
      setValue("landingPageUrl", bobSelected?.landingPageUrl);
      setValue("companyType", bobSelected?.companyType);
      setValue("potentialPurpose", bobSelected?.potentialPurpose);
      if (companyDetail?.bookOfBusinessGroup?.isDefault)
        setValue(
          "customPotentialPurposeInput",
          POTENTIAL_PURPOSES?.filter(
            (x) => x.value === bobSelected?.customPotentialPurposeInput
          )[0]
        );
    }
  }, [setValue, isEdit, bobSelected, POSITIONS, formOpen]);

  useEffect(() => {
    if (emailFields.length === 0) {
      appendEmail("");
    }
    if (mobileFields.length === 0) {
      appendMobile("");
    }
  }, [emailFields, mobileFields, appendEmail, appendMobile]);

  const onSubmit = async (data) => {
    setLoader(true);
    if (data) {
      const result =
        data?.number.length > 0 &&
        data?.number?.map((number, index) => ({
          areaCode: data?.areaCode ? data?.areaCode[index] : null,
          number: number,
        }));
      let payload = {
        first_name: data?.first_name || null,
        last_name: data?.last_name || null,
        pEmail: data?.email,
        mobileNumber: result,
        companyProfile: data?.companyProfile || null,
        positionProfile: data?.positionProfile || null,
        country: data?.country || null,
        industry: data.industry || null,
        linkedInUrl: data.linkdin || null,
        position: parseInt(data?.designation?.value) || null,
        customPotentialPurposeInput: companyDetail?.bookOfBusinessGroup
          ?.isDefault
          ? data?.customPotentialPurposeInput?.value
          : null,
        status: status,

        serviceProductName: data.serviceProductName || null,
        description: data.description || null,
        landingPageUrl: data.landingPageUrl || null,
        companyType: data.companyType || null,
        isTemp: isTempRef.current,
      };
      isTempRef.current = false;
      var resp = null;
      if (isEdit) {
        resp = await dispatch(
          editBusinessProfile(
            payload,
            companyDetail?.id,
            bobSelected?.id,
            limit,
            status
          )
        );
      } else {
        resp = await dispatch(
          await addBusinessProfile(payload, companyDetail?.id, limit, status)
        );
      }
      reset();
      formClose();
      setPage(1);
      setActivePage(0);
      if (resp && resp.status) {
        if (!resp.details?.isTemp) {
          setSuccessOpen(resp.details);
          setStatus(status);
        }
      }
      setLoader(false);
    }
  };

  const isFieldEmpty = (index) => {
    const areaCodeValue = watch(`areaCode.${index}`);
    const numberValue = watch(`number.${index}`);
    return areaCodeValue && numberValue;
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <Modal
        show={formOpen}
        onHide={handleclose}
        className="moddal-primary importModal ps-0"
      >
        <Modal.Body>
          <div className="header">
            <div>
              <h4>
                {isDefaultCompany ? (
                  "Suggest a profile"
                ) : (
                  <>
                    {isEdit && !bobSelected?.isTemp ? "Edit" : "Add"}{" "}
                    {status === PROFILE_STATUS.SUGGESTED_PROFILES
                      ? `suggested 
                  ${
                    companyDetail?.bookOfBusinessGroup?.service ===
                    BOB_SERVICES.PROFILES
                      ? "profile"
                      : companyDetail?.bookOfBusinessGroup?.service ===
                        BOB_SERVICES.COMPANIES
                      ? "company"
                      : companyDetail?.bookOfBusinessGroup?.service ===
                        BOB_SERVICES.PRODUCT_AND_SERVICES
                      ? "service or product"
                      : ""
                  }`
                      : status === PROFILE_STATUS.WARM_PROFILES
                      ? `warm ${
                          companyDetail?.bookOfBusinessGroup?.service ===
                          BOB_SERVICES.PROFILES
                            ? "profile"
                            : companyDetail?.bookOfBusinessGroup?.service ===
                              BOB_SERVICES.COMPANIES
                            ? "company"
                            : companyDetail?.bookOfBusinessGroup?.service ===
                              BOB_SERVICES.PRODUCT_AND_SERVICES
                            ? "service or product"
                            : ""
                        }`
                      : status === PROFILE_STATUS.MEETING_REQUESTS
                      ? "meeting request"
                      : ""}
                  </>
                )}
                &nbsp;
                {companyDetail?.bookOfBusinessGroup?.service === BOB_SERVICES.PROFILES && (
                  <img
                    src="assets/img/info-icon.svg"
                    onClick={() =>
                      handleClickInfo(isDefaultCompany ? I_POP_OVER.DEFAULT_SUGGESTED_PROFILE : I_POP_OVER.COMPANY_SUGGESTED_ADD_PROFILE)
                    }
                    alt=""
                    className="m-0 pb-1 cursorPointer"
                  />
                )}
              </h4>
              <p>
                Account name: <span>{companyDetail?.companyName || "-"}</span>{" "}
                <br />
                Sugggested by:{" "}
                <span>
                  {user?.first_name + " " + user?.last_name || "-"}
                </span>{" "}
                {isDesktop ? "" : <br />}Book of
                business:{" "}
                <span>
                  {companyDetail?.bookOfBusinessGroup
                    ? companyDetail?.bookOfBusinessGroup?.name || "-"
                    : "-"}
                </span>
              </p>
            </div>
            <i
              onClick={!loader && handleclose}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modalBody">
            <form ref={formSubmitRef} onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                {companyDetail?.bookOfBusinessGroup?.service ===
                BOB_SERVICES.PROFILES ? (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        First name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert first name"
                        {...register("first_name", { required: true })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="errorMessage">
                          First name is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Last name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert last name"
                        {...register("last_name", { required: true })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="errorMessage">
                          Last name is required
                        </span>
                      )}
                    </div>
                  </>
                ) : companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.COMPANIES ? (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Company name"
                        {...register("companyProfile", { required: true })}
                      />
                      {errors.companyProfile?.type === "required" && (
                        <span className="errorMessage">
                          Company name is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company type *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Company type"
                        {...register("companyType", { required: true })}
                      />
                      {errors.companyType?.type === "required" && (
                        <span className="errorMessage">
                          Company type is required
                        </span>
                      )}
                    </div>
                  </>
                ) : companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.PRODUCT_AND_SERVICES ? (
                  <>
                    <div className="col-md-12">
                      <label className="labelForm" htmlFor="">
                        Service or product name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Service or product name"
                        {...register("serviceProductName", { required: true })}
                      />
                      {errors.serviceProductName?.type === "required" && (
                        <span className="errorMessage">
                          Service or product name is required
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-md-6">
                  {emailFields.map((field, index) => (
                    <div
                      key={field.id}
                      className="col-lg-12 fieldPadding"
                      style={{ position: "relative" }}
                    >
                      <label className="labelForm" htmlFor="">
                        Email address {index > 0 ? index + 1 : ""}*
                      </label>
                      <div className="removeBtnContainer">
                        {index > 0 && (
                          <div
                            onClick={() => removeEmail(index)}
                            className="removeBtn"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </div>
                        )}
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Insert email address"
                          {...register(`email.${index}`, {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                              message: "Invalid email format",
                            },
                            validate: {
                              isUnique: (value) => {
                                // Check if the email is unique
                                const emails = getValues("email");
                                return (
                                  emails.findIndex((item) => item === value) ===
                                    index ||
                                  "Email already exists, please use another email"
                                );
                              },
                            },
                          })}
                        />
                      </div>
                      {errors?.email?.[index] && (
                        <span className="errorMessage">
                          {errors.email?.[index].message}
                        </span>
                      )}
                    </div>
                  ))}
                  <div className="col-lg-6">
                    <div
                      onClick={() => appendEmail("")}
                      className="addMoreFeature"
                    >
                      <img src="assets/img/plusBtn.svg" alt="" />
                      Add email address
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {mobileFields.map((field, index) => (
                    <div className="row" key={field.id}>
                      <div
                        className="col-lg-5 fieldPadding"
                        style={{ position: "relative" }}
                      >
                        <label className="labelForm" htmlFor="">
                          Country code {index > 0 ? index + 1 : ""}
                        </label>

                        <Controller
                          name={`areaCode.${index}`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Select
                              className="select-border"
                              theme={SELECT_BOX_THEME}
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                              options={country}
                              isClearable={true}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  whiteSpace: "nowrap",
                                  minHeight: "44px",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  fontSize: "14px",
                                }),
                              }}
                              getOptionLabel={(option) =>
                                `${option.dial_code} (${option.name})`
                              }
                              getOptionValue={(option) => option.dial_code}
                              placeholder="Country code"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="col-lg-7 fieldPadding"
                        style={{ position: "relative" }}
                      >
                        <label className="labelForm" htmlFor="">
                          Mobile number {index > 0 ? index + 1 : ""}
                        </label>
                        <div className="removeBtnContainer">
                          {index > 0 && (
                            <div
                              onClick={() => removeMobile(index)}
                              className="removeBtn"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          )}
                          <input
                            autoComplete="off"
                            type="number"
                            className="form-control"
                            placeholder="Mobile number"
                            {...register(`number.${index}`, {
                              required: false,
                              validate: {
                                isUnique: (value) => {
                                  // Check if the email is unique
                                  const number = getValues("number");
                                  return (
                                    number.findIndex(
                                      (item) => item === value
                                    ) === index ||
                                    "Mobile number already exists, please use another number"
                                  );
                                },
                              },
                            })}
                          />
                        </div>
                      </div>
                      {errors?.number?.[index] && (
                        <span className="errorMessage">
                          {errors.number?.[index].message}
                        </span>
                      )}
                      {errors?.areaCode?.[index] && (
                        <span className="errorMessage">
                          {errors.areaCode?.[index].message}
                        </span>
                      )}
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-lg-7">
                      <div
                        onClick={() =>
                          isFieldEmpty(mobileFields.length - 1) &&
                          appendMobile("")
                        }
                        className="addMoreFeature"
                      >
                        <img src="assets/img/plusBtn.svg" alt="" />
                        Add mobile number
                      </div>
                    </div>
                  </div>
                </div>

                {companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.PROFILES && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert company profile"
                        {...register("companyProfile", { required: false })}
                      />
                      {errors.companyProfile?.type === "required" && (
                        <span className="errorMessage">
                          Company profile name is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Position
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert position"
                        {...register("positionProfile", { required: false })}
                      />
                      {errors.positionProfile?.type === "required" && (
                        <span className="errorMessage">
                          Position profile is required
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Country
                  </label>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        className="select-border"
                        theme={SELECT_BOX_THEME}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        options={country}
                        isClearable={true}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        placeholder="Select country"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    )}
                  />
                  {errors.country?.type === "required" && (
                    <span className="errorMessage">Country is required</span>
                  )}
                </div>

                {companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.PROFILES && (
                  <div className="col-md-6">
                    <label className="labelForm" htmlFor="">
                      LinkedIn profile URL
                    </label>
                    <input
                      maxLength={200}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Insert linkedIn profile URL"
                      {...register("linkdin", {
                        required: false,
                        // "Linkedin profile URL is required",
                        // pattern: {
                        //   value: /^https:\/\/www\.linkedin\.com+/,
                        //   message: "Please enter a valid linkedin URL",
                        //   // https://www.linkedin.com/ this is required
                        // },
                      })}
                      // defaultValue="https://www.linkedin.com"
                    />
                    {errors.linkdin && (
                      <span className="errorMessage">
                        {errors.linkdin.message}
                      </span>
                    )}
                  </div>
                )}

                {(companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.COMPANIES ||
                  companyDetail?.bookOfBusinessGroup?.service ===
                    BOB_SERVICES.PRODUCT_AND_SERVICES) && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Landing page URL
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Landing page URL"
                        {...register("landingPageUrl", false)}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Industry
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={50}
                    placeholder="Enter industry"
                    {...register("industry", {
                      required: false,
                    })}
                  />
                  {/* <Controller
                    name="industry"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        isMulti
                        className="select-border"
                        theme={SELECT_BOX_THEME}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        options={industry}
                        isClearable={true}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Select Industry"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    )}
                  /> */}
                  {errors.industry?.type === "required" && (
                    <span className="errorMessage">Industry is required</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Potential purpose *{" "}
                    <img
                      onClick={() =>
                        handleClickInfo(
                          I_POP_OVER.BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE
                        )
                      }
                      src="assets/img/info-icon.svg"
                      className="cursor-pointer"
                      alt=""
                    />
                  </label>

                  {companyDetail?.bookOfBusinessGroup?.isDefault ? (
                    <>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a potential reason why you like to introduce this profile to other users"
                        maxLength={50}
                        {...register("customPotentialPurposeInput", {
                          required: true,
                        })}
                      /> */}

                      <Controller
                        name="customPotentialPurposeInput"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            className="select-border"
                            theme={SELECT_BOX_THEME}
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            options={POTENTIAL_PURPOSES}
                            isClearable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            placeholder={`${isDefaultCompany ? "Enter a potential reason why you like to introduce this profile to other users" : "Enter a potential reason why you would like to meet this profile"}`}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                      {errors.customPotentialPurposeInput?.type ===
                        "required" && (
                        <span className="errorMessage">
                          Potential purpose is required
                        </span>
                      )}
                    </>
                  ) : (
                    <div className="form-control role-border">
                      <p className="role">
                        {getPotentialPurpose(companyDetail)}
                      </p>
                      {/* getValues("designation")?.name */}
                    </div>
                  )}
                </div>

                {(companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.COMPANIES ||
                  companyDetail?.bookOfBusinessGroup?.service ===
                    BOB_SERVICES.PRODUCT_AND_SERVICES) && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        One sentence summary
                      </label>
                      <textarea
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="One sentence summary"
                        {...register("description", false)}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="note">
                {companyDetail?.isDefault ? (
                  <p className="desc">
                    <span>Important note: </span>
                    By confirming, you are suggesting to other users that you know this profile and can introduce this profile to them. So you may then be invited by other users who may be interested to know and work with this profile. Note that we protect these details everytime by ONLY showing other users your name and the type of profile you can introduce them to. No contact details will be shown. 
                  </p>
                ) : (
                  <p className="desc">
                    <span>Important note: </span>
                    By confirming, you are adding this profile to the {status === PROFILE_STATUS.SUGGESTED_PROFILES ? `suggested` : status === PROFILE_STATUS.WARM_PROFILES ? `warm profile` : status === PROFILE_STATUS.MEETING_REQUESTS ? "meeting request" : ""} list of {companyDetail?.companyName} for the objective of engaging with this profile and/or other similar profiles. You may then be connected with other users who may help you meet this profile or other similar profiles.
                  </p>
                )}
              </div>

              <div className="importBtn">
                <button
                  type="submit"
                  disabled={loader}
                  className="customAddButton removePadding"
                >
                  {loader && (
                    <i className="animateSpin fa-solid fa-circle-notch"></i>
                  )}
                  &nbsp;
                  {isEdit && !bobSelected?.isTemp ? "Edit " : "Add "}
                  {companyDetail?.bookOfBusinessGroup?.service ===
                  BOB_SERVICES.PROFILES
                    ? "profile"
                    : companyDetail?.bookOfBusinessGroup?.service ===
                      BOB_SERVICES.COMPANIES
                    ? "company"
                    : companyDetail?.bookOfBusinessGroup?.service ===
                      BOB_SERVICES.PRODUCT_AND_SERVICES
                    ? "Service or Product"
                    : ""}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessModal
        successOpen={successOpen}
        successClose={() => setSuccessOpen(false)}
        isEdit={isEdit}
        reset={reset}
        status={parseInt(status)}
      />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
