import moment from "moment";
import React, { useState } from "react";
import InfoModalStatic from "./infoModalStatic";
import { I_POP_OVER } from "../../constants";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function BusinessDetail({ company }) {
  const { loadCompany } = useSelector((state) => state.company);
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <div className="card-headings businessVerification">
        
        <div className="businessTitle">
          <h6 className="payment-text mb-0">
            Business verification details
          </h6>
          <img
            className="cursorPointer"
            onClick={() => handleClickInfo(I_POP_OVER.BUSINESS_DETAIL)}
            src="assets/img/info-icon.svg"
            alt=""
          />
        </div>

        <div className="status">
          {!loadCompany ? (
            <>
              <p>&nbsp;Verification status:</p>
              <span
                className={company?.isBusinessVerified ? "approved" : "pending"}
              >
                {company?.isBusinessVerified ? "Approved" : "Pending"}
              </span>
            </>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="header">UEN</div>
          {!loadCompany ? (
            <div className="bussinessvalue">{company?.uen || "-"}</div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <div className="header">Registration date</div>
          {!loadCompany ? (
            <div className="bussinessvalue">
              {company?.registrationDate
                ? moment(company?.registrationDate).format("DD/MM/YYYY")
                : "-"}
            </div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <div className="header">Business expiry</div>
          {!loadCompany ? (
            <div className="bussinessvalue">
              {company?.businessExpiry
                ? moment(company?.businessExpiry).format("DD/MM/YYYY")
                : "-"}
            </div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>

        <div className="col-md-4 colPadding">
          <div className="header">Country of incorporation</div>
          {!loadCompany ? (
            <div className="bussinessvalue">
              {company?.countryOfIncorporation || "-"}
            </div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
        <div className="col-md-4 colPadding">
          <div className="header">Entity type</div>
          {!loadCompany ? (
            <div className="bussinessvalue">{company?.entityType || "-"}</div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
        <div className="col-md-4 colPadding">
          <div className="header">Entity status</div>
          {!loadCompany ? (
            <div className="bussinessvalue">{company?.entityStatus || "-"}</div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>

        <div className="col-md-8 colPadding">
          <div className="header">Company type</div>
          {!loadCompany ? (
            <div className="bussinessvalue">{company?.companyType || "-"}</div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>

        <div className="col-md-4 colPadding">
          <div className="header">Date added</div>
          {!loadCompany ? (
            <div className="bussinessvalue"><TimeFormat time={company?.createdAt} /></div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
        <div className="col-md-12 colPadding">
          <div className="header">Business constitution</div>
          {!loadCompany ? (
            <div className="bussinessvalue">
              {company?.businessConstitution || "-"}
            </div>
          ) : (
            <div>
              <ContentLoader width="100%" height={15}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
              </ContentLoader>
            </div>
          )}
        </div>
      </div>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
