import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import PromoteModal from "../../components/promotemodal/promoteModal";
import AddressProof from "./AddressProof";
import { useDispatch, useSelector } from "react-redux";
import { VERIFICATION_TYPE } from "../../constants";
import { getProfileAction } from "../../actions/user";

export default function Verification() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [modalShow, setModalShow] = useState(false);
  const [proofModalShow, setProofModalShow] = useState(false);
  const [proofModalType, setProofModalType] = useState(null);

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch])

  const modalClose = () => {
    setModalShow(false);
  };

  const proofModalClose = () => {
    setProofModalType(null);
    setProofModalShow(false);
  };
  const handleProofShow = (type) => {
    setProofModalType(type);
    setProofModalShow(true);
  }
  return (
    <>
      <Header title="Verification" subtitle="Verification" />
      <div className="container-fluid inner-container-fluid py-4 paddingBottomMD">
        <div className="row">
          <div className="col-lg-12 col-12 mb-3">
            <h5 className="main-page-title">Trust and verification</h5>
            <ul  className="edit-description-adv-bullet">
              <li>The InProved safe score is the core of which we handle verification and due diligence.</li>
              <li>The safe score is a value that indicates to what extent we have been able to verify whom a user says they are.</li>
              <li>Users who are safest to work with are those who put in more effort to verify themselves to become users who are safer to work with.</li>
            </ul>
            {/* <p className="edit-description-adv">
              The InProved safe score is the core of which we handle verification and due diligence.<br/>The safe score is a value that indicates to what extent we have been able to verify whom a user says they are. <br/>Users who are safest to work with are those who put in more effort to verify themselves to become users who are safer to work with.
            </p> */}
          </div>
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div className="card ">
              <div className="card-body">
                <p className="trust-text">Trust scale</p>
                <div className="trust-main table-trust">
                  <div className="table-trust-moblie">
                    <div className="table-responsive">
                      <table className="table align-items-center mb-0">
                        <tbody>
                          <tr>
                            <td style={{ width: "22%" }}>
                              <div className="d-flex">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm table-heading">
                                    Current score
                                  </h6>
                                  <span className="business-font">{(user.currentScore ? user.currentScore : 0)}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm table-heading">
                                    Level
                                  </h6>
                                  <span className="business-font">
                                    {(user.currentScore ? user.currentScore : 0) < 50 ? 'Low' : (user.currentScore ? user.currentScore : 0) >= 50 & (user.currentScore ? user.currentScore : 0) < 100 ? 'Good' : "Excellent"}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar verification-color"
                        role="progressbar"
                        aria-valuenow={(user.currentScore ? user.currentScore : 0) + '%'}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: (user.currentScore ? user.currentScore : 0) + "%" }}
                      >
                        <span className="sr-only">{(user.currentScore ? user.currentScore : 0)}% Complete</span>
                      </div>
                    </div>
                    <div className="row mt-3 progress-text ">
                      <div className="col-4">
                        <h6 className="mb-0 small-text-heading">Low</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="mb-0 text-center small-text-heading">Good</h6>
                      </div>
                      <div className="col-4">
                        <h6
                          className="mb-0 small-text-heading"
                          style={{ textAlign: "end" }}
                        >
                          Excellent
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-4 verification-section">
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className={user && user.isEmailVerified ? 'd-flex align-items-center ' : "d-flex location-text align-items-center "}>
                          <div className={`icon icon-shape icon-sm shadow border-radius-md text-center me-2 justify-content-center email-bg ${user && user.isEmailVerified ? '' : 'locate-icon'} verification-icon`}>
                            <img src="assets/img/email.png" alt="" />
                          </div>
                          <span className="proofText">Email</span>
                        </div>
                        <div className="row text-ponits">
                          <span className={user && user.isEmailVerified ? "business-font" : "connect-btn-v business-font"}>15</span> <p>points</p>
                        </div>
                        {user && user.isEmailVerified ? (
                          <p className=" text-sm ponit-text mt-0">
                            Verified
                          </p>
                        ) : (
                          <p className=" text-sm ponit-text pending mt-0">
                            Pending verification
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className={user && user.isPhonVerified ? 'd-flex align-items-center' : "d-flex location-text align-items-center"}>
                          <div className={`icon icon-shape icon-sm shadow border-radius-md  text-center me-2 align-items-center justify-content-center email-bg ${user && user.isPhonVerified ? '' : 'locate-icon'} verification-icon`}>
                            <img src="assets/img/iph.png" alt="" />
                          </div>
                          <span className="proofText">Phone</span>
                        </div>
                        <div className="row text-ponits">
                          <span className={user && user.isPhonVerified ? "business-font" : "connect-btn-v business-font"}>15</span> <p>points</p>
                        </div>
                        {user && user.isPhonVerified ? (
                          <p className=" text-sm ponit-text mt-0">
                            Verified
                          </p>
                        ) : (
                          <button
                            onClick={() => handleProofShow(VERIFICATION_TYPE.MOBILE)}
                            className="btn connect-btn upload-document"
                          >
                            Upload
                          </button>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className={user && user.isAddressVerified ? 'd-flex align-items-center' : "d-flex location-text align-items-center"}>
                          <div className={`icon icon-shape icon-sm shadow border-radius-md  text-center me-2 align-items-center justify-content-center email-bg ${user && user.isAddressVerified ? '' : 'locate-icon'} verification-icon`}>
                            <img src="assets/img/location.svg" alt="" />
                          </div>
                          <span className="proofText">Proof of address</span>
                        </div>
                        <div className="row text-ponits">
                          <span className={user && user.isAddressVerified ? "business-font" : "connect-btn-v business-font"}>
                            35
                          </span> <p>points</p>
                        </div>
                        {user && user.isAddressVerified ? (
                          <p className=" text-sm ponit-text mt-0">
                            Verified
                          </p>
                        ) : (
                          user && user.addressProof ? (
                            <p className=" text-sm ponit-text pending mt-0">
                              Pending verification
                            </p>
                          ) : (
                            <button
                              onClick={() => handleProofShow(VERIFICATION_TYPE.ADDRESS)}
                              className="btn connect-btn upload-document"
                            >
                              Upload
                            </button>
                          )
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className={user && user.isIdentityVerified ? 'd-flex align-items-center' : "d-flex location-text align-items-center"}>
                          <div className={`icon icon-shape icon-sm shadow border-radius-md  text-center me-2 align-items-center justify-content-center email-bg ${user && user.isIdentityVerified ? '' : 'locate-icon'} verification-icon`}>
                            <img src="assets/img/identity.svg" alt="" />
                          </div>
                          <span className="proofText">Proof of identity</span>
                        </div>
                        <div className="row text-ponits">
                          <span className={user && user.isIdentityVerified ? "business-font" : "connect-btn-v business-font"}>
                            35
                          </span> <p>points</p>
                        </div>
                        {user && user.isIdentityVerified ? (
                          <p className=" text-sm ponit-text mt-0">
                            Verified
                          </p>
                        ) : (
                          user && user.identityProof ? (
                            <p className=" text-sm ponit-text pending mt-0">
                              Pending verification
                            </p>
                          ) : (
                            <button
                              onClick={() => handleProofShow(VERIFICATION_TYPE.IDENTITY)}
                              className="btn connect-btn upload-document"
                            >
                              Upload
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PromoteModal modalShow={modalShow} modalClose={modalClose} />
      <AddressProof
        proofModalType={proofModalType}
        proofModalShow={proofModalShow}
        proofModalClose={proofModalClose}
      />
    </>
  );
}
