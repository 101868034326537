import { useState } from "react";
import { sendMessageToAdmin } from "../../../actions/chat";
import { useNavigate } from "react-router";
import { MESSAGE_TYPE } from "../../../constants";
import { companyLink } from "../../../components/ContactUs/ContactUs";

export default function ActivityLogSegment({selectedCompany}) {
    const navigate = useNavigate();
    const [activitySegmentLoader, SetActivitySegmentLoader] = useState();

    const sendMessageToAdminfunc = async () => {
        SetActivitySegmentLoader(true);
        await sendMessageToAdmin(`<strong>Speak to admin:</strong> I am interested to have InProved's help to build ${companyLink(selectedCompany)}`, selectedCompany, MESSAGE_TYPE.CONTACT_US_BUTTON, navigate, [])
        SetActivitySegmentLoader(false);
    }

    return (
        <>
            <div className="suggestedRightSection activitySegment" style={{ backgroundImage: "url(assets/img/planBack.jpg)" }}>
                <div className="planHolder">
                    <div className="planName">
                        <h4>We can build holding companies for you.</h4>
                        <p className="planDescription">
                            Sit back to enjoy owning from Singapore:
                        </p>
                        <ul className="segmentUL">
                            <li>Investment businesses like FOs and SPVs</li>
                            <li>Active businesses like consulting</li>
                            <li>Regulated businesses like crypto and funds</li>
                        </ul>
                        <div className="upgradePlan">
                            <div className="row">
                                <div className="col-md-12">
                                    <button disabled={activitySegmentLoader} onClick={sendMessageToAdminfunc} className="planUpgrade">{activitySegmentLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Speak to admin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}