import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyActivity } from "../../actions/company";
import { getUserActivityAction } from "../../actions/user";
import Activity from "./activity";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function ProfileActivity() {
  const dispatch = useDispatch();
  const { activity, activityCount } = useSelector((state) => state.user);
  const { company, companyActivity, companyActivityCount } = useSelector((state) => state.company);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [selectedPage, setSelectedPage] = useState(1);
  const [filterType, setFilterType] = useState(-1);
  const [filterCompanyId, setFilterCompanyId] = useState(null);

  const getActivityList = useCallback(() => {
    if (filterCompanyId) {
      dispatch(getCompanyActivity(filterCompanyId, page, limit, filterType))
    } else {
      dispatch(getUserActivityAction(page, filterType))
    }
  }, [dispatch, filterCompanyId, page, filterType])

  useEffect(() => {
    getActivityList();
  }, [page, getActivityList])


  useEffect(() => {
    setPage(1)
    setSelectedPage(0);
  }, [filterType])

  useEffect(() => {
    setPage(1)
    setSelectedPage(0);
  }, [filterCompanyId])


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSelectedPage(selectedPage);
    setPage(selectedPage + 1)
  };

  return (
    <>
      <div className="card-header pb-0">
        <div className="row">
          <div className="col-md-6 col-12 d-flex align-items-center">
            <h6 className="mt-2 mb-3">User activity</h6>
          </div>
          <div
            className="col-md-4 col-12 profileActivity"
          >

            <div className="filter-button">
              <DropdownButton
                as={ButtonGroup}
                key="down"
                className="companyActivityFilterProfile"
                id="dropdown-button-drop-down"
                drop="down"
                variant="secondary"
                title={`${filterCompanyId ? company.filter(x => x.id === filterCompanyId)[0].companyName : "Select company"} `}
              >
                <Dropdown.Item className={`${filterCompanyId === null ? 'active' : ''}`} onClick={() => setFilterCompanyId(null)}>Select company</Dropdown.Item>
                {company && company.length > 0 && company.map((info, index) => (
                  <Dropdown.Item key={index} className={`${filterCompanyId === info.id ? 'active' : ''}`} onClick={() => setFilterCompanyId(info.id)}>{info.companyName} <br/><span className="wrapTime"><TimeFormat time={info.createdAt} /></span></Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div
            className="col-md-2 col-12 profileActivity"
          >
            <div className="filter-button">
              <DropdownButton
                as={ButtonGroup}
                key="down"
                id="dropdown-button-drop-down"
                drop="down"
                variant="secondary"
                title={`${filterType === -1 ? 'Latest' : "Oldest"}`}
              >
                <Dropdown.Item className={`${filterType === -1 ? 'active' : ''}`} onClick={() => setFilterType(-1)}>Latest</Dropdown.Item>
                <Dropdown.Item className={`${filterType === 1 ? 'active' : ''}`} onClick={() => setFilterType(1)}>Oldest</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>

      <Activity activity={filterCompanyId ? companyActivity : activity} activityCount={filterCompanyId ? companyActivityCount : activityCount} selectedPage={selectedPage} handlePageClick={handlePageClick} />

      {/* <div className="card-body activity-logs p-3">
        <h6 className="text-body text-xs font-weight-bolder today-text-profile">Today</h6>
        
        {activity && activity.length > 0 ? activity.map((item, index) => (
          <div key={index} className="row mt-3">
            <div className="col-lg-8 col-xl-8  col-8">
              <div className="d-flex flex-column">
                <h6 className="profile-text text-sm mb-1">{item.description}</h6>
                <span className="text-xs datetime">{moment(item.createdAt).format('DD MMMM YYYY,') + ' at ' + moment(item.createdAt).format(' h:mm A')}</span>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4  col-4">
              <div className="d-flex-right text-right">
                <h6 className="profile-text text-sm mb-1">{item.status === 1 ? 'Pending verification' : item.status === 2 ? "Completed" : "Rejected"}</h6>
              </div>
            </div>
          </div>
        )) : (
          <div className="d-flex pt-3">
            <div className="d-flex flex-column justify-content-center ">
              <h6 className="mb-0 text-sm table-heading text-center">No Activity Log
              </h6>
            </div>
          </div>
        )}

        {activityCount > 1 && (
          <div className="pagination-section">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={activityCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>
        )}
      </div> */}
    </>
  );
}
