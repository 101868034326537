import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // or 'next/link' if you're using Next.js
import { appSettings } from "../../Router/constant";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD, SUPPORTED_PAYMENT_METHOD_NAME } from "../../constants";
import ContentLoader from "react-content-loader";

const Breadcrumbs = ({ mobileHeader, loader }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentMethodSelection, savedbankInfoDetails, currencyWatchCrumbs, stripeType } = useSelector((state) => state.stripe);
  // URL-to-display-name mapping
  // ${currencyWatchCrumbs ? currencyWatchCrumbs : ""} 
  const nameMap = {
    banktransfer: paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
      paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
      ? SUPPORTED_PAYMENT_METHOD_NAME[paymentMethodSelection]
      : paymentMethodSelection ===
        SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
        ? `Crypto transfer`
        : "-",
    // ${getValues("currency") ? getValues("currency").label : ""}
    bankdetail: paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
      ? "Bank details"
      : paymentMethodSelection ===
        SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
        ? `InProved ${savedbankInfoDetails?.currency?.label} details`
        : "-",
    makepayment: "Make payment"
    // Add more mappings as needed
  };

  const pathnames = location.pathname?.split(`${appSettings}/`)?.filter((x) => x);
  const splitPatname = pathnames[0]?.split("/")?.filter((x) => x);

  // Check if it's the first page or root level
  const isFirstPage = splitPatname?.length <= 1;
  const shouldHideFirstItem = parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS; // Replace with your condition

  return (
    <nav aria-label="breadcrumb" className="breadcrumbs-container-custom">
      <ol className={`breadcrumb-custom ${isMobile ? "breadcrumb-custom-mobile" : ""} ${isFirstPage ? "breadcrumb-custom-mobile-first-page" : ""}`}>
        {isMobile && !isFirstPage ? (
          // Back button for mobile view (not shown on the first page)
          <li className="breadcrumb-item-custom">
            <button
              onClick={() => navigate(-1)} // Go back one step in history
              className="back-button-custom">
              <img src="assets/img/backIcon.svg" alt="" />
            </button>
            <p>{mobileHeader?.replace(/-/g, " ")}</p>
          </li>
        ) : (
          loader ?
            [1, 2, 3, 4].map((index) => (
              <li key={index} className="breadcrumb-item-custom">
                <ContentLoader width={80} height={20}>
                  <rect rx="5" ry="5" width={100} height={100} />
                </ContentLoader>
              </li>
            ))
            :
            // Full breadcrumbs for desktop view
            splitPatname.map((value, index) => {
              const to = `/${splitPatname?.slice(0, index + 1)?.join("/")}`;
              const isLast = index === splitPatname.length - 1;
              if (shouldHideFirstItem && index === 0) {
                return null;
              }
              // Map value to a human-readable name or fallback to the URL part
              const displayName = nameMap[value] || value?.replace(/-/g, " ");
              return isLast ? (
                <li
                  key={to}
                  className="breadcrumb-item-custom active"
                  aria-current="page">
                  {displayName}
                </li>
              ) : (
                <li key={to} className="breadcrumb-item-custom">
                  <Link to={appSettings + to}>{displayName}</Link>
                  <img src="assets/img/breadcrumbIcon.svg" alt="" />
                </li>
              );
            })
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
