import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import TaskCards from "./taskCards";
import TaskModal from "./taskModal";
import { useDispatch, useSelector } from "react-redux";
import { getTaskList } from "../../actions/tasks";
import TaskLoadMore from "./TaskLoadMore";
import { TASK_STATUS_ENUM } from "../../constants";
import ContentLoader from "react-content-loader";

export default function Task() {
  const dispatch = useDispatch();
  const { selectedCompany, hasPermission } = useSelector((state) => state.company);
  const { taskBacklog, taskInProgress, taskComplete, loadTask } = useSelector((state) => state.task);

  const [recordBackLog, setRecordBackLog] = useState(3);
  const [recordComplete, setRecordComplete] = useState(3);
  const [recordInProgress, setRecordInProgress] = useState(3);

  const [taskModalShow, setTaskModalShow] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [trigger, setTrigger] = useState(0);



  useEffect(() => {
    callTaskGetFunction()  
  }, [])

  const callTaskGetFunction = () => {
    if (selectedCompany && selectedCompany.id) {
      dispatch(getTaskList(selectedCompany.id));
    }
  }

  const taskModalClose = () => {
    setTaskModalShow(false);
    setEditItem(null)
    setTrigger(0);
  };

  const handleTaskModalShow = (identify) => {
    setTaskModalShow(true)
    if (identify === null) {
      setEditItem(null)
    }
    setTrigger(trigger + 1);
  };

  const onLoad = (type) => {
    if (type === TASK_STATUS_ENUM.BACKLOG) {
      setRecordBackLog(recordBackLog + 2)
    } else if (type === TASK_STATUS_ENUM.INPROGRESS) {
      setRecordInProgress(recordInProgress + 2)
    } else if (type === TASK_STATUS_ENUM.COMPLETE) {
      setRecordComplete(recordComplete + 2)
    }
  };

  const editItemTrigger = (item) => {
    setEditItem(item)
    handleTaskModalShow(true)
  }
  return (
    <>
      <Header title="Tasks" subtitle="Tasks" />
      <div className="container-fluid inner-container-fluid py-4 whole-task-section paddingBottomMD">
        {/* <div className="row mb-4"></div> */}
        {!loadTask ? (
          <div className="row">
            <div className="col-lg-4 col-xs-12 col-md-6 col-border customScroll">
              <h3 className="main-page-title mb-3">Backlog</h3>
              <div className="row">
                {taskBacklog && taskBacklog.length > 0 && taskBacklog.slice(0, recordBackLog).map((item, index) => (
                  <div className="col-12 mt-3" key={index}>
                    <div className="card">
                      <TaskCards item={item} editItem={editItemTrigger} />
                    </div>
                  </div>
                ))}
              </div>
              <TaskLoadMore task={taskBacklog} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.BACKLOG)} record={recordBackLog} />
              {hasPermission ? (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card  right-bottom cursor-pointer"
                      onClick={() => handleTaskModalShow(null)}
                    >
                      <p>
                        Add task
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card right-bottom cursor-pointer"
                    >
                      <p>
                        No permission to add task
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xs-12 col-md-6 col-border customScroll mt-lg-0">
              <h3 className="main-page-title mb-3">In progress </h3>
              <div className="row">
                {taskInProgress && taskInProgress.length > 0 && taskInProgress.slice(0, recordInProgress).map((item, index) => (
                  <div className="col-12 mt-3" key={index}>
                    <div className="card">
                      <TaskCards item={item} editItem={editItemTrigger} />
                    </div>
                  </div>
                ))}
              </div>
              <TaskLoadMore task={taskInProgress} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.INPROGRESS)} record={recordInProgress} />
              {hasPermission ? (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card right-bottom cursor-pointer"
                      onClick={() => handleTaskModalShow()}
                    >
                      <p>
                        Add task
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card right-bottom cursor-pointer"
                    >
                      <p>
                        No permission to add task
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xs-12 col-md-6 customScroll mt-lg-0">
              {/* right-card-color */}
              <h3 className="main-page-title mb-3">Complete</h3>
              <div className="row">
                {taskComplete && taskComplete.length > 0 ? taskComplete.slice(0, recordComplete).map((item, index) => (
                  <div className="col-12 mt-3" key={index}>
                    <div className="card right-card">
                      <TaskCards item={item} />
                    </div>
                  </div>
                )) : (
                  <div className="col-12 mt-3">
                    <div className="card right-bottom cursor-pointer">
                      <p>
                        No completed task
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <TaskLoadMore task={taskComplete} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.COMPLETE)} record={recordComplete} />

            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4">
              <ContentLoader width="100%" height={450}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="450" />
              </ContentLoader>
            </div>
            <div className="col-md-4">
              <ContentLoader width="100%" height={450}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="450" />
              </ContentLoader>
            </div>
            <div className="col-md-4">
              <ContentLoader width="100%" height={450}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="450" />
              </ContentLoader>
            </div>
          </div>
        )}
      </div>
      <TaskModal
        taskModalShow={taskModalShow}
        taskModalClose={taskModalClose}
        editItem={editItem}
        trigger={trigger}
      />
    </>
  );
}
