import { GET_SETTINGS, GET_SETTINGS_PROMOTER_LIST } from "../actions/types";
import { BANK_TRANSFER_DETAILS, CRYPTO_NETWORK_DETAILS } from "../constants";
const initialState = {
  settings: {},
  bankTransferDetail: BANK_TRANSFER_DETAILS,
  cryptoNetworkDetail: CRYPTO_NETWORK_DETAILS,
  defaultPromoter: [],
};
export default function Settings(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: payload || {}
      };

    case GET_SETTINGS_PROMOTER_LIST:
      return {
        ...state,
        defaultPromoter: payload || []
      };

    default:
      return state;
  }
}
