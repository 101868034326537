export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_FAILED_MESSAGE = "SET_FAILED_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const ADD_COMPANY_LIST = "ADD_COMPANY_LIST";
export const EDIT_COMPANY_LIST = "EDIT_COMPANY_LIST";
export const GET_CAPITAL_LIST = "GET_CAPITAL_LIST";
export const ADD_CAPITAL = "ADD_CAPITAL";
export const EDIT_FAIL = "EDIT_FAIL";
export const GET_FILES = "GET_FILES";
export const ADD_FILES = "ADD_FILES";
export const RESET_FILES_TRIGGER = "RESET_FILES_TRIGGER";
export const GET_SHAREHOLDER_LIST = "GET_SHAREHOLDER_LIST";
export const ADD_SHAREHOLDER = "ADD_SHAREHOLDER";
export const ADD_SHAREHOLDER_FAIL = "ADD_SHAREHOLDER_FAIL";
export const GET_OFFICER_LIST = "GET_OFFICER_LIST";
export const ADD_OFFICER = "ADD_OFFICER";
export const ADD_OFFICER_FAIL = "ADD_OFFICER_FAIL";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const USER_PROFILE = "USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const GET_COMPANY_ACTIVITY_LIST = "GET_COMPANY_ACTIVITY_LIST";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const GET_PROMOTER_LIST = "GET_PROMOTER_LIST";
export const GET_MERGE_PROMOTER_LIST = "GET_MERGE_PROMOTER_LIST"
export const GET_INVITE_DETAIL_BY_TOKEN = "GET_INVITE_DETAIL_BY_TOKEN";
export const CHANGE_PERMISSION_UPDATES = "CHANGE_PERMISSION_UPDATES";
export const TWO_FACTOR_LOGIN_SUCCESS = "TWO_FACTOR_LOGIN_SUCCESS";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_AUTH_USER_INFO = "SET_AUTH_USER_INFO";
export const SET_STRIPE_MODAL = "SET_STRIPE_MODAL";
export const SET_STRIPE_SECRET_TOKEN = "SET_STRIPE_SECRET_TOKEN";
export const SET_STRIPE_SUBSCRIPTION = "SET_STRIPE_SUBSCRIPTION";
export const SET_STRIPE_PLANS = "SET_STRIPE_PLANS";
export const SET_STRIPE_PAYMENT = "SET_STRIPE_PAYMENT";
export const SET_PAYMENT_METHOD_SELECTION = "SET_PAYMENT_METHOD_SELECTION"
export const SET_VERIFICATION_EMAIL = "SET_VERIFICATION_EMAIL";
export const GET_BANNER_LIST = "GET_BANNER_LIST"
export const INIT_POPUP = "INIT_POPUP"
export const DESTROY_SESSION = "DESTROY_SESSION"
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU"
export const LOADING_COMPANY = "LOADING_COMPANY"
export const GET_PENDING_INVITES = "GET_PENDING_INVITES"
export const GET_SKIP_INVITES = "GET_SKIP_INVITES"
export const INCREMENT_CURRENT_INVITE = "INCREMENT_CURRENT_INVITE"
export const SET_MY_INFO = "SET_MY_INFO"
export const SET_QUESTIONS = "SET_QUESTIONS"
export const SET_MY_INFO_STATUS = "SET_MY_INFO_STATUS"
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION"
export const SET_NOTIFICATION_BANNER = "SET_NOTIFICATION_BANNER"
export const TOGGLE_MENU_SIDEBAR = "TOGGLE_MENU_SIDEBAR"
export const TWILIO_TOKEN = "TWILIO_TOKEN"
export const CHAT_RESPONSE = "CHAT_RESPONSE"
export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST"
export const SET_CHANNEL_LAST_MESSAGE = "SET_CHANNEL_LAST_MESSAGE"
export const SET_CHANNEL_MESSAGE = "SET_CHANNEL_MESSAGE"
export const SET_CHANNEL_NEW_MESSAGE = "SET_CHANNEL_NEW_MESSAGE"
export const RESET_CHANNEL_MESSAGE = "RESET_CHANNEL_MESSAGE"
export const SET_TYPING_INDICATOR = "SET_TYPING_INDICATOR"
export const REMOVE_TYPING_INDICATOR = "REMOVE_TYPING_INDICATOR"
export const CLEAR_TYPING_INDICATOR = "CLEAR_TYPING_INDICATOR"
export const SET_CLIENT = "SET_CLIENT"
export const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION"
export const SET_CHAT_CONVERSATION_MEDIA = "SET_CHAT_CONVERSATION_MEDIA"
export const SET_BULK_CHANNEL_MESSAGE = "SET_BULK_CHANNEL_MESSAGE"
export const MY_INFO_LOADING = "MY_INFO_LOADING"
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE"
export const SET_RESET_PASSWORD_VERIFICATION_EMAIL = "SET_RESET_PASSWORD_VERIFICATION_EMAIL"
export const TOGGLE_ASK_ADDA = "TOGGLE_ASK_ADDA"
export const GET_USER_GUIDE = "GET_USER_GUIDE"
export const LOAD_COMPANY_SKELETON = "LOAD_COMPANY_SKELETON"
export const LOAD_COMPANY_ACTIVITY_SKELETON = "LOAD_COMPANY_ACTIVITY_SKELETON"
export const LOAD_STRIPE_PAYMENT = "LOAD_STRIPE_PAYMENT"
export const LOAD_FILES_SKELETON = "LOAD_FILES_SKELETON"
export const LOAD_SHAREHOLDER_SKELETON = "LOAD_SHAREHOLDER_SKELETON"
export const LOAD_OFFICER_SKELETON = "LOAD_OFFICER_SKELETON"
export const LOAD_TASK_SKELETON = "LOAD_TASK_SKELETON"
export const LOAD_COMPANY_SKELETON_FORCE = "LOAD_COMPANY_SKELETON_FORCE"
export const LOAD_USER_GUIDE_SKELETON = "LOAD_USER_GUIDE_SKELETON"
export const LOAD_BOB_SKELETON = "LOAD_BOB_SKELETON"
export const SET_ALL_COMPANY_APPOINTED_LIST = "SET_ALL_COMPANY_APPOINTED_LIST"
export const GET_OBJECTIVE = "GET_OBJECTIVE"
export const LOAD_PRODUCT_LIST = "LOAD_PRODUCT_LIST"
export const AUTH_ADMIN_ACCESS = "AUTH_ADMIN_ACCESS"


// Actions
export const UPSERT_CONVERSATION = "UPSERT_CONVERSATION"
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION"
export const UPDATE_CURRENT_CONVERSATION = "UPDATE_CURRENT_CONVERSATION"
export const CONVERSATION_LAST_READ_INDEX = "CONVERSATION_LAST_READ_INDEX"
export const ADD_MESSAGES = "ADD_MESSAGES"
export const PUSH_MESSAGES = "PUSH_MESSAGES"
export const REMOVE_MESSAGES = "REMOVE_MESSAGES"
export const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS"
export const UPDATE_UNREAD_MESSAGES = "UPDATE_UNREAD_MESSAGES"
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION"
export const ADD_ATTACHMENT = "ADD_ATTACHMENT"
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS"
export const TYPING_STARTED = "TYPING_STARTED"
export const TYPING_ENDED = "TYPING_ENDED"
export const UPDATE_LOADING_STATE = "UPDATE_LOADING_STATE"
export const SET_DEFAULT_CURRENT_MESSAGE_LENGTH = "SET_DEFAULT_CURRENT_MESSAGE_LENGTH"
export const SET_CHAT_SEARCH_RESULT = "SET_CHAT_SEARCH_RESULT"
export const SET_CHAT_SEARCH_MESSAGE_ID = "SET_CHAT_SEARCH_MESSAGE_ID"
export const SET_CHAT_LOADING_SKELETON = "SET_CHAT_LOADING_SKELETON"


// Signnow
export const SET_SIGNNOW_DOCUMENT_SIGNER = "SET_SIGNNOW_DOCUMENT_SIGNER"
export const REMOVE_SIGNNOW_DOCUMENT_SIGNER = "REMOVE_SIGNNOW_DOCUMENT_SIGNER"
export const RESET_SIGNNOW_DOCUMENT = "RESET_SIGNNOW_DOCUMENT"
export const SET_SIGNNOW_INITIATE_SIGNATURE = "SET_SIGNNOW_INITIATE_SIGNATURE"
export const SET_SIGNNOW_DOCUMENT = "SET_SIGNNOW_DOCUMENT"
export const SET_SIGNNOW_SIGNER_DOCUMENT = "SET_SIGNNOW_SIGNER_DOCUMENT"
export const SET_SIGNNOW_ACTIVATE_SIGNATURE = "SET_SIGNNOW_ACTIVATE_SIGNATURE"
export const SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL = "SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL"
export const SET_SIGNATURE_LIST_SIGNERS = "SET_SIGNATURE_LIST_SIGNERS"
export const SET_ALL_DOCUMENT_BY_CHANNEL = "SET_ALL_DOCUMENT_BY_CHANNEL"
export const SET_SIGNNOW_DOCUMENT_TEMPORARY = "SET_YOUSIGN_DOCUMENT_TEMPORARY"
export const SET_ALL_TEMPLATE_FILES = "SET_ALL_TEMPLATE_FILES"

// Book of Business
export const GET_SUGGESTED_PROFILE = "GET_SUGGESTED_PROFILE"
export const GET_IMPORT_LIST = "GET_IMPORT_LIST"
export const IMPORT_SUCCESS_MESSAGE = "IMPORT_SUCCESS_MESSAGE"
export const IMPORT_ERROR_MESSAGE = "IMPORT_ERROR_MESSAGE"
export const IMPORT_PROGRESS = "IMPORT_PROGRESS"


// 
export const GET_SUSBCRIPTION_PLAN_PRODUCTS = "GET_SUSBCRIPTION_PLAN_PRODUCTS"
export const SELECTED_SUSBCRIPTION_PLAN_PRODUCTS = "SELECTED_SUSBCRIPTION_PLAN_PRODUCTS"
export const SET_BANK_CRYPTO_DETAILS = "SET_BANK_CRYPTO_DETAILS"

// 
export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_PROMOTER_LIST = "GET_SETTINGS_PROMOTER_LIST"
export const SET_STRIPE_PAYABLE_AMOUNT = "SET_STRIPE_PAYABLE_AMOUNT"
// User Dashboard
export const GET_USER_DASHBOARD = "GET_USER_DASHBOARD"
export const USER_DASHBOARD_LOADING_SKELETON = "USER_DASHBOARD_LOADING_SKELETON"
export const USER_DASHBOARD_TOUR = "USER_DASHBOARD_TOUR"
export const SET_ENABLE_TOUR_RIDE = "SET_ENABLE_TOUR_RIDE"
export const USER_GUIDE_POPUP = "USER_GUIDE_POPUP"
export const TRANSACTION_SEGMENT_LIST = "TRANSACTION_SEGMENT_LIST"

export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST"
export const LOAD_REFERRAL_SKELETON = "LOAD_REFERRAL_SKELETON"
export const GET_BID_GOLD_PRICE = "GET_BID_GOLD_PRICE"
export const SET_BID_GOLD_HEADER_CURRENCY = "SET_BID_GOLD_HEADER_CURRENCY"
export const SET_OVERRIDE_PLAN_PRODUCT_PRICE = "SET_OVERRIDE_PLAN_PRODUCT_PRICE"
export const SET_LOAD_CLIENT = "SET_LOAD_CLIENT"
export const USER_GUIDE_TYPE = "USER_GUIDE_TYPE"
export const HIDE_BOB_TOUR_RIDE = "HIDE_BOB_TOUR_RIDE"
export const LOAD_CAPITAL_SKELETON = "LOAD_CAPITAL_SKELETON"
export const COMPANY_VERIFICATION_STEP_FAILED = "COMPANY_VERIFICATION_STEP_FAILED"
export const SET_BANK_TRANSFER_BACK_TRIGGER = "SET_BANK_TRANSFER_BACK_TRIGGER"
export const SET_LOGIN_EMAIL_TEMPORARILY = "SET_LOGIN_EMAIL_TEMPORARILY"
export const MANUAL_PAYMENT_PROCEEDING = "MANUAL_PAYMENT_PROCEEDING"
export const HOLD_SID_CONVERSATION = "HOLD_SID_CONVERSATION"
export const OPEN_LATEST_CONVERSATION = "OPEN_LATEST_CONVERSATION"
export const CHAT_MEDIA = "CHAT_MEDIA"
export const LOADING_CHAT_MEDIA = "LOADING_CHAT_MEDIA"
export const CHAT_PREVIEWER_MEDIA = "CHAT_PREVIEWER_MEDIA"
export const SET_HOLD_CURRENCY_AMOUNT_PAID_UP = "SET_HOLD_CURRENCY_AMOUNT_PAID_UP"
export const SET_FORCE_CART_PAYABLE_AMOUNT = "SET_FORCE_CART_PAYABLE_AMOUNT"
export const SET_INPUT_BASE_CONVERTION_CHANGE_TRIGGER = "SET_INPUT_BASE_CONVERTION_CHANGE_TRIGGER"
export const SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE = "SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE"
export const SET_EMBED_ESIGN_POPUP_FLOW_LINK = "SET_EMBED_ESIGN_POPUP_FLOW_LINK"
export const SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID = "SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID"
export const SET_HOLD_NEXT_INVITE_TEMP = "SET_HOLD_NEXT_INVITE_TEMP"
export const SET_HOLD_NEXT_INVITE_TEMP_DATA = "SET_HOLD_NEXT_INVITE_TEMP_DATA"
export const SET_USER_DASHBOARD_BULLION_RECORD = "SET_USER_DASHBOARD_BULLION_RECORD"
export const SET_USER_DASHBOARD_BULLION_RECORD_LOADING = "SET_USER_DASHBOARD_BULLION_RECORD_LOADING"
export const SET_USER_DASHBOARD_METAL_ALLOCATION = "SET_USER_DASHBOARD_METAL_ALLOCATION"
export const SET_USER_DASHBOARD_METAL_ALLOCATION_LOADING = "SET_USER_DASHBOARD_METAL_ALLOCATION_LOADING"
export const SET_USER_DASHBOARD_PROFIT_LOSS = "SET_USER_DASHBOARD_PROFIT_LOSS"
export const SET_USER_DASHBOARD_PROFIT_LOSS_LOADING = "SET_USER_DASHBOARD_PROFIT_LOSS_LOADING"
export const SET_COMPANY_MENU_ACTION = "SET_COMPANY_MENU_ACTION"
export const SET_COMPANY_ADD_SHOW_ACTION = "SET_COMPANY_ADD_SHOW_ACTION"
export const SET_PENDING_COMPANY_SELECT = "SET_PENDING_COMPANY_SELECT"
export const PRODUCT_PLAN_LOADER = "PRODUCT_PLAN_LOADER"
export const IS_VALID_TOKEN = "IS_VALID_TOKEN"
export const SET_CURRENCY_WATCH = "SET_CURRENCY_WATCH"
export const SET_TRANSACTION_STATUS = "SET_TRANSACTION_STATUS"
export const SET_TRANSACTION_STATUS_LOADER = "SET_TRANSACTION_STATUS_LOADER"
export const GET_COMPANY_DASHBOARD = "GET_COMPANY_DASHBOARD"
export const COMPANY_DASHBOARD_LOADING_SKELETON = "COMPANY_DASHBOARD_LOADING_SKELETON"
export const SET_COMPANY_DASHBOARD_BULLION_RECORD = "SET_COMPANY_DASHBOARD_BULLION_RECORD"
export const SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING = "SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING"
export const SET_COMPANY_DASHBOARD_METAL_ALLOCATION = "SET_COMPANY_DASHBOARD_METAL_ALLOCATION"
export const SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING = "SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING"
export const SET_COMPANY_DASHBOARD_PROFIT_LOSS = "SET_COMPANY_DASHBOARD_PROFIT_LOSS"
export const SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING = "SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING"
export const SET_TRIPLEA_URL = "SET_TRIPLEA_URL"
export const SET_TRIPLEA_STATE = "SET_TRIPLEA_STATE"