import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import { ACTIVITY, CUSTOM_SELECT_STYLES, HOLDERS, POSITIONS, SELECT_BOX_THEME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { appointShareholderAction } from "../../actions/shareHolder";
import { appointDirectorAction } from "../../actions/officer";
import { appointPromoterAction } from "../../actions/promoters";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-hot-toast";

export default function AppointModal({
  appointModalShow,
  appointModalClose,
  performObj,
  type,
}) {

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const { shareHolder } = useSelector((state) => state.shareHolder);
  const { officers } = useSelector((state) => state.officers);

  if (type === HOLDERS.promoters) {
    var positions = POSITIONS.filter(
      x => x.value === HOLDERS.directors || x.value === HOLDERS.shareHolders
    );
    const isShareHolderAppointed = shareHolder?.filter((x => x.email === performObj?.email))
    const isDirectorAppointed = officers?.filter((x => x.email === performObj?.email))
    if (isShareHolderAppointed?.length > 0) {
      positions = positions.filter(x => x.value !== HOLDERS.shareHolders);
    }
    if (isDirectorAppointed?.length > 0) {
      positions = positions.filter(x => x.value !== HOLDERS.directors);
    }
    if (isShareHolderAppointed?.length === 0 && isDirectorAppointed?.length === 0) {
      positions.push({
        value: -1,
        name: "Shareholder and director"
      });
    }
  }

  const getPositionName = (position) => {
    var returnVal = "-";
    var filter = POSITIONS.filter((x) => x.value === position);
    if (filter.length > 0) {
      returnVal = filter[0].name;
    }
    return returnVal;
  };

  const submitAppoint = () => {
    const { company, id } = performObj;
    if (type === HOLDERS.shareHolders) {
      dispatch(
        appointShareholderAction(company ? company._id : selectedCompany.id, id)
      );
      appointModalClose();
    } else if (type === HOLDERS.directors) {
      dispatch(
        appointDirectorAction(company ? company._id : selectedCompany.id, id)
      );
      appointModalClose();
    } else if (type === HOLDERS.promoters) {
      const proposedPosition = getValues('proposedPosition') || null;
      if (proposedPosition && (proposedPosition.value === -1 || proposedPosition.value === HOLDERS.shareHolders || proposedPosition.value === HOLDERS.directors)) {
        if (company || selectedCompany.id) {
          dispatch(
            appointPromoterAction(company ? company._id : selectedCompany.id, id, proposedPosition.value)
          );
          reset()
          appointModalClose();
        }
      } else {
        toast.error("Please select position to continue.");
      }
    }
  };
  return (
    <>
      <Modal
        show={appointModalShow}
        onHide={appointModalClose}
        className="custom-modal delete-information-width"
      >
        <div className="modal-header p-0">
          <h5 className="edit-title">
            Are you sure you want to appoint this{" "}
            {type === HOLDERS.shareHolders
              ? "shareholder"
              : type === HOLDERS.directors
                ? "director"
                : "promoter"}
            ?
          </h5>
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={appointModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5 mt-3">
              <p className="input-text heading">Name</p>
              <span className="col-form-label company-text">
                {type === HOLDERS.shareHolders || type === HOLDERS.directors
                  ? performObj.name
                  : performObj.first_name + " " + performObj.last_name}
              </span>
            </div>
            <div className="col-lg-7 mt-3">
              <p className="input-text heading">Position</p>
              <span className={`col-form-label ${!HOLDERS.promoters ? 'company-text' : ""}`}>
                {(type === HOLDERS.shareHolders || type === HOLDERS.directors) ? getPositionName(performObj.position) : type === HOLDERS.promoters ? (
                  <div className="form-group form-margin">
                    <Controller
                      name="proposedPosition"
                      rules={{ required: true }}
                      control={control}
                      // defaultValue={null}
                      render={({ field }) => (
                        <Select
                          theme={SELECT_BOX_THEME}
                          styles={CUSTOM_SELECT_STYLES}
                          className="select-border"
                          {...field}
                          options={positions}
                          isClearable={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          placeholder="Select Position"
                        />
                      )}
                    />
                    {errors.proposedPosition?.type === "required" && (
                      <span className="errorMessage">
                        Position is required
                      </span>
                    )}
                  </div>
                ) : "-"}
              </span>
            </div>
          </div>

          <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              By proceeding, we will notify you directly and advise you
              accordingly on the next steps to formalise the changes.{" "}
            </span>
          </p>

          <div className="modal-footer modal-bottom-action">
            <ContactUs handleClose={appointModalClose} />
            <button
              onClick={submitAppoint}
              type="button"
              className="btn agree-button"
            >
              Agree & continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
