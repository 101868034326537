import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChargeReceiptUrlAction, getInvoiceDetailAction, getStripeSubscriptionAction, getTransactionStatusAction } from "../../actions/stripe";
import ContentLoader from "react-content-loader";
import ReactPaginate from "react-paginate";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Form } from "react-bootstrap";
import Currency from "../../components/currency/currency";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import { SUBSCRIPTION_MODULE_TYPE_NAME, SUPPORTED_PAYMENT_METHOD, SUPPORTED_PAYMENT_METHOD_NAME } from "../../constants";
import CryptoCurrency from "../../components/currency/cryptoCurrency";
import { selectedTransactionSegmentRecord } from "../../actions/subscriptionPlan";

export default function PaymentTransaction() {
  const dispatch = useDispatch();
  const { control } = useForm();
  const { subscriptions, loadSubscription, subscriptionsCount } = useSelector((state) => state.stripe);
  const { transactionsSegmentRecord } = useSelector((state) => state.subscriptionPlanProducts)
  const { selectedCompany } = useSelector((state) => state.company);
  const [record, setRecord] = useState(3);
  const onLoad = () => {
    setRecord(subscriptions.length);
  };

  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const limit = useWatch({ control, name: "limit" });

  useEffect(() => {
    getTransactionList()
  }, [selectedCompany, page, limit])


  const planButtonTrigger = (item) => {
    dispatch(selectedTransactionSegmentRecord(item))
    if (item.id) {
      dispatch(getTransactionStatusAction(item.id))
    }
  }

  const getTransactionList = () => {
    const { id } = selectedCompany;
    if (id) {
      dispatch(getStripeSubscriptionAction(id, page, limit));
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  useEffect(() => {
    // Reset activePage to 0 whenever the "limit" field changes
    setActivePage(0);
    setPage(1);
  }, [limit, selectedCompany]);


  const getInvoiceAction = (id) => {
    dispatch(getInvoiceDetailAction(id));
  };

  return (
    <>
      <div className="tableHolder paymentPaidupTableHolder">

        <table>
          <thead className="tableHeader">
            <tr>
              <th>Transaction id</th>
              <th>Name/Purpose</th>
              <th className="transDateTimeWidth">Date/Time</th>
              <th>Amount</th>
              <th>Payment method</th>
              <th>Receipts</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {!loadSubscription ? (
              subscriptions && subscriptions.length > 0 ? (
                subscriptions.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="tableHeader">
                      <td className={`cursorPointer ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        <p className="date">{item.paymentId}</p>
                      </td>
                      <td className={`cursorPointer ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        <div className="planName">
                          <p className="namePurpose">{item?.subscriptionPlan?.name ? item?.subscriptionPlan?.name : SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType] || "-"}</p>
                        </div>
                        <p className="planType">{item?.subscriptionPlan?.name !== "" ? SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType] : "" || "-"}</p>
                      </td>
                      <td className={`cursorPointer transDateTimeWidth ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        <p className="date"><TimeFormat time={item.createdAt} /></p>
                      </td>
                      <td className={`cursorPointer transAmount ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        {/* {item?.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || item?.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ? ( */}
                        <>
                          <Currency currency={item?.currency} />
                          <CurrencyFormat currency={item?.currency} value={item?.amount / 100} decimal={2} />
                        </>
                        {/* // ) : (
                        //   <>
                        //     <CryptoCurrency currency={item?.currency} />
                        //     <CurrencyFormat currency={item?.currency} value={item?.amount / 100} decimal={5} />
                        //   </>
                        // )} */}
                      </td>


                      <td className={`cursorPointer transPayment ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>{SUPPORTED_PAYMENT_METHOD_NAME[item?.paymentMethodSelection] || ''} </td>
                      <td className={`${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        {item?.payment_intent && item?.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ? (
                          <button onClick={() => dispatch(getChargeReceiptUrlAction(item?.payment_intent))} className="btn btn-link invoice-id-amount  payment-trans p-0 m-0">
                            <img src="assets/img/pdf.svg" alt="pdf" />
                            PDF
                          </button>
                        ) :
                          "-"}
                      </td>
                      <td className={`cursorPointer ${transactionsSegmentRecord === item ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item)}>
                        <button className={`${item?.payment_status} statusButton`}>{item?.payment_status === 'attempted to pay' ? 'Attempted' : item?.payment_status}</button>
                      </td>
                    </tr>
                    <tr>
                      {/* <th className="tableHrsection" colSpan="6">
                        <hr />
                      </th> */}
                    </tr>
                  </React.Fragment>

                ))
              ) : (
                <tr>
                  <td className="pb-0">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-center ">
                        <h6 className="mb-0 text-sm table-heading pb-1">
                          No transaction
                        </h6>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <div className="px-3">
                {[
                  100, 90, 80, 70, 80, 75, 65, 60, 50, 55, 60, 90, 65, 70, 80,
                ].map((item, index) => (
                  <ContentLoader key={index} width={item + "%"} height={15}>
                    <rect rx="5" ry="5" width={item + "%"} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </tbody>
        </table>

        {!loadSubscription && (
          subscriptions && subscriptions.length > 0 && (
            <div className="tableBottom">
              <div className="filterButton">
                <div className="dropdownButton">
                  <Controller
                    name="limit"
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        className="select-border"
                        placeholder="10"
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Form.Select>
                    )}
                  />
                </div>
              </div>
              <div>
                {subscriptionsCount > 0 && (
                  <ReactPaginate
                    previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                    nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                    pageCount={subscriptionsCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination customPagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
