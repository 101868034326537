import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../../components/ContactUs/ContactUs";
import { BOB_SERVICES } from "../../constants";

export default function DeleteBob({
  removeBobModalShow,
  removeBobModalClose,
  removeBobModalCloseConfirm,
  actionData,
}) {

  return (
    <>
      <Modal
        show={removeBobModalShow}
        onHide={removeBobModalClose}
        className="custom-modal delete-information-width"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={removeBobModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title">
            Are you sure you want to remove this record?
          </h5>
          <div className="row">
            <div className="col-lg-12">
              <p className="input-text heading">
                {actionData?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES ? "Full Name/Country" : BOB_SERVICES.PRODUCT_AND_SERVICES === actionData?.bookOfBusinessGroupId?.service ? "Product or Service Name" : BOB_SERVICES.COMPANIES === actionData?.bookOfBusinessGroupId?.service ? "Company name and type" : ""}
              </p>
              <p className="col-form-label company-text p-0 m-0">
                {BOB_SERVICES.PROFILES === actionData?.bookOfBusinessGroupId?.service ? actionData?.first_name + " " + actionData?.last_name : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(actionData?.bookOfBusinessGroupId?.service) ? (BOB_SERVICES.COMPANIES === actionData?.bookOfBusinessGroupId?.service ? actionData.companyProfile : actionData.serviceProductName) + " " + (BOB_SERVICES.COMPANIES === actionData?.bookOfBusinessGroupId?.service ? actionData.companyType : "") : ""}
              </p>
            </div>
          </div>

          <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              This will not be reverted.
            </span>
          </p>

          <div className="modal-footer modal-bottom-action">
            <ContactUs handleClose={removeBobModalClose} />
            <button
              onClick={removeBobModalCloseConfirm}
              type="button"
              className="btn agree-button"
            >
              Agree & Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
