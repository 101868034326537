import React, { useEffect } from 'react';
import ContentLoader from "react-content-loader";
import { PRODUCT_GOLD_TYPE_NAME_INITIAL, USER_DASHBOARD_BULLION } from '../../../../constants';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from '../../../../components/currencyFormat/currencyFormat';
import { updateCompanyDashboardAction } from '../../../../actions/companyDashboard';

export default function CompanyMetalAllocation({ isEditing, setIsEditing }) {

    const {
        control,
        reset,
        register,
        handleSubmit,
    } = useForm();
    const dispatch = useDispatch()
    const { companyMetalAllocation, isCompanyDashboardMetalAllocationLoading } = useSelector((state) => state.companyDashboard);

    const { isAdminLoggedIn } = useSelector((state) => state.auth);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "companyMetalAllocation",
    });

    useEffect(() => {
        const getMetalAllocation = () => {
            reset();
            companyMetalAllocation && companyMetalAllocation?.length > 0 && companyMetalAllocation.forEach(element => {
                append({
                    type: element.type,
                    currency: element.currency,
                    metal: element?.metal,
                    allocation: element?.allocation,
                    convertionCurrency: element?.convertionCurrency,
                    convertionBalance: element?.convertionBalance,
                    productName: element?.productName
                })
            });
        }
        getMetalAllocation();
    }, [companyMetalAllocation])

    const onSubmit = async (data) => {
        await dispatch(updateCompanyDashboardAction(data))
        setIsEditing(null);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='accountBorderSection'>
                    {isCompanyDashboardMetalAllocationLoading ? (
                        <div className='row cashHederSection cashHederSectionPadding'>
                            <div className='col-md-12'>
                                <div style={{ width: '100%', marginLeft: 'auto' }}>
                                    <ContentLoader width="100%" height={15}>
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                    </ContentLoader>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='row cashHederSection cashHederSectionPadding'>
                            <div className='col-12 justifySection'>
                                <h6 className='cashHeaderText cashDarkText m-0'>Bullion portfolio</h6>
                                {/* {isAdminLoggedIn && (
                                    isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                        <button className="btn save-button customFilterButton" type='submit'>
                                            Save
                                        </button>
                                    ) : (
                                        <span className="btn btn-link  px-3 mb-0 edit-button" onClick={() => setIsEditing(USER_DASHBOARD_BULLION.METALALLOCATION)}>
                                            <i className="fas fa-pencil-alt edit-button me-2"></i>
                                        </span>
                                    )
                                )} */}
                                {isAdminLoggedIn && (<span className="btn btn-link  px-3 mb-0 edit-button faded-edit"></span>)}
                            </div>
                        </div>
                    )}


                    <div className='col-12'><hr className='m-0'></hr></div>

                    <div className='cashBottomPadding'>

                        <div className='row'>
                            <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 3 : 4}`}><p className='currentBalanceText m-0'>Qty</p></div>
                            <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 4 : 4}`}><p className='currentBalanceText m-0'>Product Name</p></div>
                            <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 3 : 4} rightAlignText`}><p className='currentBalanceText m-0'>Avg buy price</p></div>
                            {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                <div className='col-2 deleteIcon'><p className='currentBalanceText m-0'>Action</p></div>
                            ) : (
                                <></>
                            )}
                        </div>

                        {isCompanyDashboardMetalAllocationLoading ? (
                            <div className='row'>
                                <div className='col-12 currentBalanceDetails'>
                                    <ContentLoader width="100%" height={15}>
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                    </ContentLoader>
                                </div>
                            </div>
                        ) : (
                            fields?.map((record, index) => (
                                <div key={record.id} className='row'>
                                    <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 3 : 4}`}>
                                        {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                            <input
                                                type='text'
                                                className='form-control customInputField mb-2'
                                                {...register(`metalAllocation.${index}.metal`)}
                                            />
                                        ) : (
                                            <h6 className='currentBalanceDetails m-0'>{record?.metal || "-"} {record?.type === PRODUCT_GOLD_TYPE_NAME_INITIAL.HEDGEKILOGRAM ? PRODUCT_GOLD_TYPE_NAME_INITIAL.KILOGRAM : record?.type || "-"}</h6>
                                        )}
                                    </div>
                                    <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 4 : 4}`}>
                                        {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                            <input
                                                type='text'
                                                className='form-control customInputField mb-2'
                                                {...register(`metalAllocation.${index}.metal`)}
                                            />
                                        ) : (
                                            <h6 className='currentBalanceDetails m-0'>{record?.productName?.join(", ") || "-"}</h6>
                                        )}
                                    </div>
                                    <div className={`col-${isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? 3 : 4} rightAlignText`}>
                                        {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                            <input
                                                type='text'
                                                className='form-control customInputField mb-2 textInputAlignText'
                                                {...register(`metalAllocation.${index}.allocation`)}
                                            />
                                        ) : (
                                            <h6 className='currentBalanceDetails m-0'>{record?.convertionCurrency?.toUpperCase()} <CurrencyFormat currency={record?.convertionCurrency} value={parseFloat(record?.convertionBalance)} decimal={2} /></h6>
                                        )}
                                    </div>
                                    {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                                        <div className='col-2 deleteIcon'>
                                            <div onClick={() => { remove(index); }}>
                                                <span className="mb-0">
                                                    <i className="fa fa-times-circle edit-button m-0"></i>
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ))
                        )}

                        {isEditing === USER_DASHBOARD_BULLION.METALALLOCATION ? (
                            <div className='row'>
                                <div className='col-12 addRowAlignIcon addRightPadding'>
                                    <span className="btn btn-link  px-3 mb-0 edit-button" onClick={() => { append({ metal: "", allocation: "" }); }}>
                                        <i className="fa-solid fa-plus edit-button"></i>
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}
