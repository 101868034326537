import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { removeOfficer, deleteOfficerInvite } from "../../actions/officer";
import { CONTACT_US_TRIGER, POSITIONS } from "../../constants";
import ContactUs from "../ContactUs/ContactUs";
import { INVITE_TYPE } from "./OfficerTable";

export default function RemoveModal({
  removeModalShow,
  removeModalClose,
  performObj,
  type,
  pendingOfficerList
}) {
  const dispatch = useDispatch();

  const deleteOfficer = () => {
    if(pendingOfficerList.some(x=>x.email === performObj?.email)){
      const inviteResp = pendingOfficerList.find(x=>x.email === performObj?.email)
      if (performObj.company && inviteResp?.id) {
        dispatch(deleteOfficerInvite(performObj.company._id, inviteResp?.id));
      }
    }else{
      if (performObj.company && performObj.id) {
        dispatch(removeOfficer(performObj.company._id, performObj.id));
      }
    }
    removeModalClose();
  };

  const getPositionName = (position) => {
    var returnVal = "";
    var filter = POSITIONS.filter((x) => x.value === position);
    if (filter.length > 0) {
      returnVal = filter[0].name;
    }
    return returnVal;
  };
  return (
    <>
      <Modal
        show={removeModalShow}
        onHide={removeModalClose}
        className="custom-modal delete-information-width"
      >
        <div className="modal-header p-0">
          <h5 className="edit-title">
            Are you sure you want to remove this director?
          </h5>
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={removeModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5 mt-3">
              <p className="input-text heading">Director Name</p>
              <span className="col-form-label company-text">
                {performObj.name}
              </span>
            </div>
            <div className="col-lg-3 mt-3">
              <p className="input-text heading">Position</p>
              <span className="col-form-label company-text">
                {getPositionName(performObj.position)
                  ? getPositionName(performObj.position)
                  : "-"}
              </span>
            </div>
          </div>

          <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              By proceeding, we will notify you directly and advise you
              accordingly on the next steps to formalise the changes.
            </span>
          </p>

          <div className="modal-footer modal-bottom-action ">
            <ContactUs addEditValue="director" type={CONTACT_US_TRIGER.REMOVE_SHARE_DIRETOR_PROMOTER} handleClose={removeModalClose} />
            <button
              type="button"
              onClick={deleteOfficer}
              className="btn agree-button"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
