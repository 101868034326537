import axionIns from "../axios";

const getBanner = () => {
  return axionIns.get("banner").then((response) => {return response.data});
};

const exportObject = {
  getBanner,
};
export default exportObject;
