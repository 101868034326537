import axionIns from "../axios";

const getUserGuide = (type) => {
  return axionIns.get(`userguide?identify=${type}`).then((response) => {
    return response.data;
  });
};

const postNewsLetter = () => {
  return axionIns.post("userguide/newsletter").then((response) => {
    return response.data;
  });
};

const exportOject = {
  getUserGuide,
  postNewsLetter,
};

export default exportOject;
