import React, { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyActivity } from "../../actions/company";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { Controller, useForm, useWatch } from "react-hook-form";

export default function PlanActivity({ filterType, setFilterType }) {
  const dispatch = useDispatch();
  const { control } = useForm();
  const {
    selectedCompany,
    loadCompanyActivity,
    companyActivity,
    companyActivityCount,
  } = useSelector((state) => state.company);
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(0);
  const limit = useWatch({ control, name: "limit" });


  const getCompanyActivitylist = () => {
    if (selectedCompany.id) {
      dispatch(getCompanyActivity(selectedCompany.id, page, limit, filterType.value));
    }
  };

  useEffect(() => {
    getCompanyActivitylist();
  }, [page]);

  useEffect(() => {
    setPage(1);
    getCompanyActivitylist();
    setSelectedPage(0);
  }, [filterType, limit]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.id) {
      setPage(1);
      setFilterType({value: '-1', label: 'Latest'});
      getCompanyActivitylist();
    }
  }, [selectedCompany]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSelectedPage(selectedPage);
    setPage(selectedPage + 1);
  };
  return (
    <>
      <table>
        <thead className="tableHeader">
          <tr>
            <th>Activity</th>
            <th>Date/Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="tableBody">
          {!loadCompanyActivity ? (
            companyActivity && companyActivity.length > 0 ? (
              companyActivity.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <div className="planName">
                        <p className="desc descBold">{item.description || "-"}</p>
                      </div>
                    </td>
                    <td>
                      <p className="date">
                        <TimeFormat time={item.createdAt} />
                      </p>
                    </td>
                    <td>
                    <button className={`pendingButton btn-identify-${item.status}`}>
                        {item.status === 1
                          ? "Pending verification"
                          : item.status === 2
                            ? "Completed"
                            : "Rejected"}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHrsection" colSpan="3">
                      <hr />
                    </th>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td className="pb-0">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-center ">
                      <h6 className="mb-0 text-sm table-heading pb-1 activelogWidth">
                        No activity log
                      </h6>
                    </div>
                  </div>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td>
                <div>
                  {[
                    100, 90, 80, 70, 80, 75, 65, 60, 50, 55, 60, 90, 65, 70, 80,
                  ].map((item, index) => (
                    <ContentLoader key={index} width={item + "%"} height={15}>
                      <rect rx="5" ry="5" width={item + "%"} height="15" />
                    </ContentLoader>
                  ))}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!loadCompanyActivity && (
        companyActivity && companyActivity.length > 0 && (
          <div className="tableBottom">
            <div className="filterButton">
              <div className="dropdownButton">
                <Controller
                  name="limit"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      className="select-border"
                      placeholder="10"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Form.Select>
                  )}
                />
              </div>
            </div>
            <div>
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                pageCount={companyActivityCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination customPagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={selectedPage}
              />
            </div>
          </div>
        ))}
    </>
  );
}
