import axionIns from "../axios";

const getFiles = async (payload, page, limit) => {
  return axionIns.get(`files/${payload}?page=${page}&perPage=${limit ? limit : 10}`).then((response) => {return response.data});
};

const addFiles = async (payload) => {
  return axionIns.post("files", payload).then((response) => {return response.data});
};

const uploadFile = async (payload) => {
  return await axionIns.post("/files/no-entry", payload).then((response) => {return response.data});
};

const uploadMultipleFiles = async (payload) => {
  return await axionIns.post("/files/no-entry-multiple", payload).then((response) => {return response.data});
};

const exportObject = {
  getFiles,
  addFiles,
  uploadFile,
  uploadMultipleFiles,
};
export default exportObject;
