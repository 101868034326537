import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DEVICE_TYPE } from "../../constants";
import { RoutesPaths } from "../../Router/constant";

export default function MyInfo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search) {
      const code = queryParams.get("code") || null;
      const state = queryParams.get("state") || null;
      const error = queryParams.get("error") || null;
      const errorDesc = queryParams.get("error-description") || null;
      if ((code && state) || (error && state)) {
        var splitState = state.split("-");
        const identify = splitState[splitState.length -1]

        switch (parseInt(identify)) {
          case DEVICE_TYPE.IOS:
            window.location = 'inproved://'+location.search  
            break;
          case DEVICE_TYPE.ANDRIOD:
            window.location = `inproved://inproved.com/` + location.search
            break;
          default:
            window.location = RoutesPaths.DASHBOARD+location.search
            break;
        }
      }
    }else{
      window.location = RoutesPaths.DASHBOARD
    }
  }, [dispatch, location]);

  return (
    <></>
  );
}
