import React, { useState, useEffect } from 'react';
import ContentLoader from "react-content-loader";
import { USER_DASHBOARD_BULLION } from '../../../../constants';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDashboardAction } from '../../../../actions/userDashboard';
import TimeFormat from '../../../../components/timeFormat/timeFormat';
import CurrencyFormat from '../../../../components/currencyFormat/currencyFormat';

export default function ProfileLoss({ isEditing, setIsEditing }) {
    const {
        register,
        handleSubmit,
    } = useForm();
    const dispatch = useDispatch()
    const { profitAndLoss, isUserDashboardProfitLossLoading } = useSelector((state) => state.userDashboard);
    const { isAdminLoggedIn } = useSelector((state) => state.auth);

    const onSubmit = (data) => {
        let Payload = {
            profitLoss: {
                asOf: data.asOfDateTime,
                ytd: data.PLytd,
                allocation: data.PLAllocation
            }
        }
        dispatch(updateUserDashboardAction(Payload))
        setIsEditing(null);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='accountBorderSection'>

                    {isUserDashboardProfitLossLoading ? (
                        <div className='row cashHederSection cashHederSectionPadding'>
                            <div className='col-md-12'>
                                <div style={{ width: '100%', marginLeft: 'auto' }}>
                                    <ContentLoader width="100%" height={15}>
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                    </ContentLoader>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='row cashHederSection cashHederSectionPadding'>
                            <div className='col-12 justifySection'>
                                <h6 className='cashHeaderText cashDarkText m-0'>Profit (Loss)</h6>

                                {/* {isAdminLoggedIn && (
                                    isEditing === USER_DASHBOARD_BULLION.PROFITLOSS ? (
                                        <button className="btn save-button customFilterButton" type='submit'>
                                            Save
                                        </button>
                                    ) : (
                                        <span className="btn btn-link  px-3 mb-0 edit-button" onClick={() => setIsEditing(USER_DASHBOARD_BULLION.PROFITLOSS)}>
                                            <i className="fas fa-pencil-alt edit-button me-2"></i>
                                        </span>
                                    )
                                )} */}
                                {isAdminLoggedIn && (
                                    <span className="btn btn-link  px-3 mb-0 edit-button faded-edit"></span>
                                )}
                            </div>
                        </div>
                    )}

                    <div className='col-12'><hr className='m-0'></hr></div>

                    <div className='cashBottomPadding'>
                        <div className='row'>
                            <div className='col-4'><p className='currentBalanceText m-0'>As of</p></div>
                            <div className='col-3'><p className='currentBalanceText m-0'>YTD</p></div>
                            <div className='col-5 rightAlignText'><p className='currentBalanceText m-0'>Bullion</p></div>
                        </div>

                        {isUserDashboardProfitLossLoading ? (
                            <div className='row'>
                                <div className='col-12 currentBalanceDetails'>
                                    <ContentLoader width="100%" height={15}>
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                    </ContentLoader>
                                </div>
                            </div>
                        ) : (
                            <div className='row'>
                                <div className='col-4'>
                                    {/* {isEditing === USER_DASHBOARD_BULLION.PROFITLOSS ? (
                                        <input
                                            type='text'
                                            className='form-control customInputField mb-2'
                                            {...register("asOfDateTime", {})}
                                            defaultValue={userDashboard?.profitLoss?.asOf || ""}
                                        />
                                    ) : ( */}
                                    <h6 className='currentBalanceDetails m-0 pb-0'>{profitAndLoss?.asOf || "-"}</h6>
                                    {/* )} */}
                                </div>
                                <div className='col-5'>
                                    {/* {isEditing === USER_DASHBOARD_BULLION.PROFITLOSS ? (
                                        <input
                                            type='text'
                                            className='form-control customInputField mb-2'
                                            {...register("PLytd", {})}
                                            defaultValue={userDashboard?.profitLoss?.ytd || ""}
                                        />
                                    ) : ( */}
                                    <h6 className='currentBalanceDetails m-0 pb-0'>{profitAndLoss?.convertionCurrency}&nbsp;
                                        {profitAndLoss?.ytd === '-' ? ("") : (
                                            <CurrencyFormat currency={profitAndLoss?.convertionCurrency} value={parseFloat(profitAndLoss?.ytd)} decimal={2} />
                                        )}
                                    </h6>
                                    {/* )} */}
                                </div>
                                <div className='col-3 rightAlignText'>
                                    {/* {isEditing === USER_DASHBOARD_BULLION.PROFITLOSS ? (
                                        <input
                                            type='text'
                                            className='form-control customInputField mb-2 textInputAlignText'
                                            {...register("PLAllocation", {})}
                                            defaultValue={userDashboard?.profitLoss?.allocation || ""}
                                        />
                                    ) : ( */}
                                    <h6 className='currentBalanceDetails m-0 pb-0'>{profitAndLoss?.allocation || "-"}</h6>
                                    {/* )} */}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}
