import React from "react";
import * as _ from "lodash";
import ContentLoader from "react-content-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { Accordion } from "react-bootstrap";

const ConversationViewSkeletonLayout = (props) => {
  const { type } = props;
  return (
    <>
      {type === "LEFT" ? (
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
          <li key={index}>
            <div className="row">
              <div className="col-md-12" style={{ marginRight: "12px" }}>
                <ContentLoader width={50} height={50}>
                  <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
                </ContentLoader>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ContentLoader width="100%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                </ContentLoader>
              </div>
              <div className="col-12">
                <ContentLoader width="70%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="70%" height="15" />
                </ContentLoader>
              </div>
              <div className="col-12">
                <ContentLoader width="90%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="90%" height="15" />
                </ContentLoader>
              </div>
            </div>
          </li>
        ))
      ) : type === "MIDDLE" ? (
        <>
          <div className="messageHeader">
            <div className="userName">
              <div>
                <div style={{ height: "20px" }}>
                  <ContentLoader width="100%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
                <div style={{ height: "20px" }}>
                  <ContentLoader width="70%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="70%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>

          <div
            id="scrollable"
            className={
              [].length > 0
                ? "addedMedia messageHolder customScroll"
                : "messageHolder customScroll"
            }
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              width: "100%",
            }}
          >
            <InfiniteScroll
              dataLength={0}
              hasMore={false}
              style={{
                display: "flex",
                overflow: "hidden",
                flexDirection: "column-reverse",
              }}
            >
              <div className="messageList">
                {[1, 2, 3, 4]?.map((msg, index) => (
                  <div key={index}>
                    <ContentLoader width="100%" height={15}>
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="15"
                      />
                    </ContentLoader>
                    <div className="joinedLeaveChat">
                      <div>
                        <ContentLoader width="60%" height={15}>
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="15"
                          />
                        </ContentLoader>
                      </div>
                    </div>
                    <div
                      id={"message-skeleton"}
                      className={
                        index % 2 === 0
                          ? "messageListOuter ownProperty skeleton"
                          : "messageListOuter"
                      }
                    >
                      <div className="messageListInner">
                        {index % 2 !== 0 && (
                          <div className="row">
                            <div className="col-md-12">
                              <ContentLoader width={40} height={40}>
                                <rect
                                  x="0"
                                  y="0"
                                  rx="50"
                                  ry="50"
                                  width="40"
                                  height="40"
                                />
                              </ContentLoader>
                            </div>
                          </div>
                        )}

                        <div className="messagebyUser">
                          <div className="userName">
                            <div className="userMessage">
                              {index % 2 !== 0 && (
                                <div>
                                  <ContentLoader width="30%" height={15}>
                                    <rect
                                      x="0"
                                      y="0"
                                      rx="5"
                                      ry="5"
                                      width="100%"
                                      height="15"
                                    />
                                  </ContentLoader>
                                </div>
                              )}
                              <div className="messagingService">
                                <div>
                                  <ContentLoader width="100%" height={15}>
                                    <rect
                                      x="0"
                                      y="0"
                                      rx="5"
                                      ry="5"
                                      width="100%"
                                      height="15"
                                    />
                                  </ContentLoader>
                                </div>
                                <div style={{ textAlign: "end" }}>
                                  <ContentLoader width="30%" height={15}>
                                    <rect
                                      x="0"
                                      y="0"
                                      rx="5"
                                      ry="5"
                                      width="100%"
                                      height="15"
                                    />
                                  </ContentLoader>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
          <div>
            <div>
              <ContentLoader width="100%" height={150}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="150" />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : type === "RIGHT" ? (
        <>
          <div className="profile-header">
            <div className="row">
              <div className="col-md-12">
                <ContentLoader width={64} height={64}>
                  <rect x="0" y="0" rx="50" ry="50" width="64" height="64" />
                </ContentLoader>
              </div>
            </div>

            <div style={{ marginLeft: "8px" }}>
              <div style={{ height: "20px" }}>
                <ContentLoader width="80%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="80%" height="15" />
                </ContentLoader>
              </div>
              <div style={{ height: "20px" }}>
                <ContentLoader width="60%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="60%" height="15" />
                </ContentLoader>
              </div>
            </div>
          </div>

          <div className="profile-body">
            <ul>
              <Accordion>
                {[90, 75, 65, 80, 95].map((item, index) => (
                  <Accordion.Item
                    className={"listHolder"}
                    key={index}
                    eventKey={index}
                  >
                    <div>
                      <div>
                        <ContentLoader width={`${item}%`} height={34}>
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width={`${item}%`}
                            height="34"
                          />
                        </ContentLoader>
                      </div>
                    </div>
                  </Accordion.Item>
                ))}
              </Accordion>
            </ul>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ConversationViewSkeletonLayout;
