import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { addCompanyActivity } from "../../actions/company";
import { ACTIVITY, CUSTOM_SELECT_STYLES, I_POP_OVER, MODAL_BUSINESS_IDENTIFY, SELECT_BOX_THEME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ContactUs from "../../components/ContactUs/ContactUs";
import InfoModalStatic from "./infoModalStatic";
import ContentLoader from "react-content-loader";
import { isBrowser, isDesktop, isMobile } from 'react-device-detect';

export default function ActivityCard(props, ref) {
  const dispatch = useDispatch();
  const { hasPermission, loadCompany } = useSelector((state) => state.company);
  const [modalShow, setModalShow] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const modalClose = () => {
    setModalShow(false);
    reset();
  };
  const handleShow = () => setModalShow(true);
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  useEffect(() => {
    if (modalShow) {
      setValue(
        "primaryActivity",
        ACTIVITY.filter((x) => x.title === props.company.primaryActivity)[0]
      );
      setValue(
        "secondaryActivity",
        ACTIVITY.filter((x) => x.title === props.company.secondaryActivity)[0]
      );
    }
  }, [props.company, modalShow, setValue]);

  const onSubmit = (payload) => {
    if (props.company.id) {
      var preparePayload = {
        primaryActivity:
          (payload.primaryActivity && payload.primaryActivity.title) || null,
        secondaryActivity:
          (payload.secondaryActivity && payload.secondaryActivity.title) ||
          null,
      };

      if (preparePayload.secondaryActivity === null) {
        const filterSecondary = ACTIVITY.filter((x) => x.title === props.company.secondaryActivity)
        if (filterSecondary.length === 0) {
          delete preparePayload['secondaryActivity']
        }
      }

      dispatch(addCompanyActivity(props.company.id, preparePayload)).then(
        (resp) => {
          modalClose();
          reset();
        }
      );
    }
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <div className="card-body px-0 pb-3 pt-3">
        <div className="table-responsive overflowHidden">
          <table className="table mb-0">
            <tbody className={isMobile ? 'mobile-activity' : 'desktop-activity'}>
              <tr>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div className="d-flex flex-column justify-content-center ">
                      <h6 className="mb-0 text-sm table-heading">
                        Primary activity{" "}
                        <img
                          src="assets/img/info-icon.svg"
                          onClick={() =>
                            handleClickInfo(I_POP_OVER.PRIMARY_ACTIVITY)
                          }
                          alt=""
                          className="m-0 pb-1 cursorPointer"
                        />
                      </h6>
                      {!loadCompany ? (
                        <span className="business-font activity_wrapper">
                          {props.company.primaryActivity
                            ? props.company.primaryActivity
                            : "-"}
                        </span>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm table-heading">
                        Secondary activity{" "}
                        <img
                          src="assets/img/info-icon.svg"
                          onClick={() =>
                            handleClickInfo(I_POP_OVER.SECONDARY_ACTIVITY)
                          }
                          alt=""
                          className="m-0 pb-1 cursorPointer"
                        />
                      </h6>
                      {!loadCompany ? (
                        <span className="business-font activity_wrapper">
                          {props.company.secondaryActivity
                            ? props.company.secondaryActivity
                            : "-"}
                        </span>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="text-right">
                  {!loadCompany && props.company && props.company.id && (
                    <>
                      {hasPermission && props.permission() ? (
                        <span
                          className="btn btn-link  px-3 mb-0 edit-button companySpotlightStep4-4"
                          onClick={handleShow}
                        >
                          <i
                            className="fas fa-pencil-alt edit-button me-2"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {props.company && props.company.id && (
          <>
            {!props.company.isApproved ? (
              <div className="additional-action">
                <button className="btn amendment-progress-button-second">
                  Acceptance in progress
                </button>
              </div>
            ) : null}
            {!props.company.isActivityEditApproved ? (
              <div className="additional-action">
                <button className="btn amendment-progress-button">
                  Amendment in progress
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
      <Modal
        show={modalShow}
        onHide={modalClose}
        className="custom-modal moddal-primary ps-0"
      >
        <Modal.Header className="p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={modalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row right-side-row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6">
              <h5 className="edit-title">Edit business activities</h5>
              <p className="edit-description">Every holding company is allowed to perform up to two business activities and thus two SSIC (Singapore standard industrial classification) codes. The primary code is compulsory while the secondary code is optional. You will need to select an SSIC code in mind when starting holding company. </p>
              <p className="edit-description">You can change the SSIC codes as many times as you need to. </p>
              <p className="edit-description">We will be sending you notifications directly to confirm and verify your business activities. </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 mt-4 mt-lg-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-margin">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="input-text heading">
                        Current primary business activity
                      </p>
                      <p className="company-text">
                        {props.company.primaryActivity
                          ? props.company.primaryActivity
                          : "-"}
                      </p>
                      <p className="input-text heading">
                        New primary business activity
                      </p>
                      <Controller
                        name="primaryActivity"
                        rules={{ required: true }}
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={ACTIVITY}
                            isClearable={true}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.title}
                            placeholder="Choose a Primary Business Activity"
                          />
                        )}
                      />
                      {errors.primaryActivity?.type === "required" && (
                        <span className="errorMessage">
                          Primary activity is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-lg-12 mt-3">
                        <p className="input-text heading">
                          Current secondary business activity
                        </p>
                        <p className="company-text">
                          {props.company.secondaryActivity
                            ? props.company.secondaryActivity
                            : "-"}
                        </p>
                        <p className="input-text heading">
                          New secondary business activity
                        </p>
                        <Controller
                          name="secondaryActivity"
                          control={control}
                          // defaultValue={null}
                          render={({ field }) => (
                            <Select
                              theme={SELECT_BOX_THEME}
                              styles={CUSTOM_SELECT_STYLES}
                              className="select-border"
                              {...field}
                              options={ACTIVITY}
                              isClearable={true}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.title}
                              placeholder="Choose a secondary business activity"
                            />
                          )}
                        />
                        {errors.secondaryActivity?.type === "required" && (
                          <span className="errorMessage">
                            Secondary activity is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will need to contact you directly to confirm the
                    amendment.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs type={MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY} handleClose={modalClose} />
                  <button type="submit" className="btn agree-button agreeContinue">
                    Agree & continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
