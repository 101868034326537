import { SET_STRIPE_SECRET_TOKEN, SET_STRIPE_PAYABLE_AMOUNT, SET_STRIPE_MODAL, SET_STRIPE_SUBSCRIPTION, SET_STRIPE_PAYMENT, SET_STRIPE_PLANS, LOAD_STRIPE_PAYMENT, SET_PAYMENT_METHOD_SELECTION, SET_BANK_CRYPTO_DETAILS, SET_OVERRIDE_PLAN_PRODUCT_PRICE, SET_BANK_TRANSFER_BACK_TRIGGER, MANUAL_PAYMENT_PROCEEDING, SET_FORCE_CART_PAYABLE_AMOUNT, SET_HOLD_CURRENCY_AMOUNT_PAID_UP, SET_CURRENCY_WATCH, SET_TRANSACTION_STATUS, SET_TRANSACTION_STATUS_LOADER, SET_TRIPLEA_URL, SET_TRIPLEA_STATE } from "../actions/types";
import { SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD } from "../constants";

const initialState = {
  showStripePlanByModule: {},
  stripeToken: null,
  stripeModal: false,
  stripeType: null,
  stripeSubscription: null,
  isSubscribed: null,
  isBobSubscribed: null,
  loadSubscription: false,
  subscriptions: [],
  subscriptionsCount: 0,
  stripePlans: {},
  paymentMethodSelection: null,
  savedbankInfoDetails: null,
  isPriceDisable: false,
  isOverrideProduct: false,
  cartAmount: null,
  forceCartAmount: null,
  triggerBankTransferBack: false,
  manualPaymentProceeding: false,
  holdFromCurrencyPaidUpCapital: null,
  currencyWatchCrumbs: "",
  transactionStatus: [],
  isTransactionStatusLoading: false,
  isTripleaUrl: null,
  isTripleaState: false
};
export default function (state = initialState, action) {
  const { type, payload, subscriptionCount, moduleType } = action;
  switch (type) {
    case SET_STRIPE_SECRET_TOKEN:
      return {
        ...state,
        stripeToken: payload,
      };
    case SET_STRIPE_MODAL:
      return {
        ...state,
        stripeModal: payload.stripeModal,
        stripeType: payload.stripeType,
        showStripePlanByModule: payload.showStripePlanByModule
      };

    case SET_STRIPE_SUBSCRIPTION:
      return {
        ...state,
        stripeSubscription: payload,
      };

    case SET_STRIPE_PAYMENT:
      var subscribed = false;
      var bobSubscribed = false;

      // if (payload.length > 0) {
      //   for (const [key, value] of Object.entries(SUBSCRIPTION_MODULE_TYPE)) {
      //     const payloadFilter = payload.filter(x=>x.subscriptionModuleType === value)
      //     if (payloadFilter && payloadFilter.length > 0 && payloadFilter[0].status === 'active') {
      //       if (payloadFilter[0].cancel_at_period_end && (moment().unix() > parseInt(payloadFilter[0].current_period_end))) {
      //         if(value===SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION){
      //           subscribed = false;
      //         }else if(value===SUBSCRIPTION_MODULE_TYPE.BOB) {
      //           bobSubscribed = false
      //         }
      //         break;
      //       }
      //       if(value===SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION){
      //         subscribed = true;
      //       }else if(value===SUBSCRIPTION_MODULE_TYPE.BOB) {
      //         bobSubscribed = true
      //       }
      //     }
      //   }
      // }

      return {
        ...state,
        subscriptions: payload,
        subscriptionsCount: subscriptionCount,
        isSubscribed: subscribed,
        isBobSubscribed: bobSubscribed,
        loadSubscription: false,
      };

    case LOAD_STRIPE_PAYMENT:
      return {
        ...state,
        loadSubscription: payload
      };

    case SET_STRIPE_PLANS:
      return {
        ...state,
        stripePlans: payload,
      };

    case SET_PAYMENT_METHOD_SELECTION:
      return {
        ...state,
        paymentMethodSelection: payload,
        isOverrideProduct: false,
        cartAmount: null,
        forceCartAmount: null,
        isPriceDisable: false,
        triggerBankTransferBack: false,
        manualPaymentProceeding: false
      };

    case SET_BANK_CRYPTO_DETAILS:
      return {
        ...state,
        savedbankInfoDetails: payload,
      };


    case SET_STRIPE_PAYABLE_AMOUNT:
      let isPriceDisable = (moduleType === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL || moduleType === SUBSCRIPTION_MODULE_TYPE.FUNDS) ? false : true;
      return {
        ...state,
        cartAmount: payload,
        isPriceDisable: isPriceDisable,
      };

    case SET_FORCE_CART_PAYABLE_AMOUNT:
      return {
        ...state,
        forceCartAmount: payload,
      };

    case SET_OVERRIDE_PLAN_PRODUCT_PRICE:
      let currentGoldPlan = state.stripePlans;
      if (currentGoldPlan && 'price' in currentGoldPlan) {
        currentGoldPlan.price = payload;
      }

      return {
        ...state,
        stripePlans: currentGoldPlan,
        isOverrideProduct: true,
      };

    case SET_BANK_TRANSFER_BACK_TRIGGER:
      return {
        ...state,
        triggerBankTransferBack: payload,
      }

    case MANUAL_PAYMENT_PROCEEDING:
      return {
        ...state,
        manualPaymentProceeding: payload,
      }

    case SET_HOLD_CURRENCY_AMOUNT_PAID_UP:
      return {
        ...state,
        holdFromCurrencyPaidUpCapital: payload
      }

    case SET_CURRENCY_WATCH:
      return {
        ...state,
        currencyWatchCrumbs: payload
      }

    case SET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: payload,
        isTransactionStatusLoading: false,
      }

    case SET_TRANSACTION_STATUS_LOADER:
      return {
        ...state,
        isTransactionStatusLoading: payload
      }
    case SET_TRIPLEA_URL:
      return {
        ...state,
        isTripleaUrl: payload
      }
    case SET_TRIPLEA_STATE:
      return {
        ...state,
        isTripleaState: payload
      }

    default:
      return state;
  }
}
