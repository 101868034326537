import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ContactUs from "../../components/ContactUs/ContactUs";
import { addIndividualReferralAction } from "../../actions/referralInvites";
import { CONTACT_US_TRIGER, USER_GUIDE_MODAL } from "../../constants";
import { setGuideOpenAction } from "../../actions/auth";
import toast from "react-hot-toast";

export default function AddIndividualInviteModal({
  addModalShow,
  addModalClose,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [copyLinkLoader, setCopyLinkLoader] = useState(false);

  const onSubmit = async (data) => {
    data.global = 0;
    dispatch(addIndividualReferralAction(data));
    addModalClose();
    reset();
  };

  const openUseGuide = (guideName) => {
    dispatch(setGuideOpenAction(true, guideName));
    addModalClose();
  };

  const copyLink = async () => {
    setCopyLinkLoader(true);
    var resp = await dispatch(addIndividualReferralAction({ global: 1 }));
    if (resp.status) {
      setCopyLinkLoader(false);
      const shortUrl = resp?.data?.short_url;
      if (shortUrl) {
        navigator.clipboard.writeText(shortUrl).then(() => { toast.success("URL copied to clipboard") }).catch(() => { console.error("Failed to copy text: ") });
      }
    }
    addModalClose();
    reset();
  };

  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="custom-modal edit-information-width ps-0">
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button close-button"
            onClick={addModalClose}></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">
                Invite your friend to join InProved and earn commissions
              </h5>
              {/* <p className="edit-description">
                Every business can appoint as many promoters as required.
              </p>
              <p className="edit-description">
                A promoter is any person who plays a part in forming a company
                or establishing its business (usually potential shareholders or
                directors of the business).
              </p>
              <p className="edit-description">
                A promoter can act for or as an agent of a business, or provide
                services or its own skillset to the business.
              </p>
              <p className="edit-description">
                A promoter who acts for or as an agent of the business before it
                has been legally incorporated will be personally liable for any
                contract they make on behalf or with the business.
              </p> */}
              {/* <ContactUs
                handleClose={addModalClose}
                type={CONTACT_US_TRIGER.INDIVIDUAL_REFERRAL}
                theme="spanContactUs"
              /> */}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">First Name</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="errorMessage">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">Last Name</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        {...register("last_name", {
                          required: true,
                        })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="errorMessage">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Email</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email address"
                        {...register("referral_email", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                        })}
                      />
                      {errors.referral_email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.referral_email?.type === "pattern" && (
                        <span className="errorMessage">Email is invalid</span>
                      )}
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will send an invitation to this address to invite him/her
                    to InProved. You will know when he/she has accepted the
                    invite. We will notify you when he/she has procured any item
                    from InProved and you will then receive a commission payout
                    starting from 10bp of the price of the item.{" "}
                    <b
                      onClick={() =>
                        openUseGuide(USER_GUIDE_MODAL.INVITE_REFERRAL)
                      }
                      className="cursor-pointer">
                      Learn more.
                    </b>
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  {/* <ContactUs handleClose={addModalClose} type={CONTACT_US_TRIGER.INDIVIDUAL_REFERRAL}/> */}
                  <button
                    type="button"
                    disabled={copyLinkLoader}
                    onClick={copyLink}
                    className={`btn btn-sm  mb-4 mt-3 contactus-modal-button `}>
                    {copyLinkLoader && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Copy invite link
                  </button>
                  <button type="submit" className="btn agree-button">
                    Agree & add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
