import React, { useState, useEffect } from "react";
import { getChatUserImage, getChatUserName } from "./userList";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import ContentLoader from "react-content-loader";

export const getLastMsgTime = (date) => {
  if (!date) return false;
  const chatDate = new Date(date);
  const currentDate = new Date();

  const isToday = chatDate.toDateString() === currentDate.toDateString();
  const isYesterday =
    new Date(
      chatDate.getFullYear(),
      chatDate.getMonth(),
      chatDate.getDate() + 1
    ).toDateString() === currentDate.toDateString();

  if (isToday) {
    return `Today`;
  } else if (isYesterday) {
    return `Yesterday`;
  } else {
    const timeDifference = currentDate.getTime() - chatDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    if (daysDifference <= 7) {
      const options = { weekday: "long" };
      return chatDate.toLocaleDateString("en-GB", options);
    } else {
      const options = { month: "numeric", day: "numeric", year: "numeric" };
      return chatDate.toLocaleDateString("en-GB", options);
    }
  }
};

export const filterHtml = (str, searchTerm = null, fromText = null) => {
  // var textHold = str?.replace(/(<([^>]+)>)/gi, "");
  var textHold = str;
  if (searchTerm && searchTerm.length > 0) {
    textHold = highlightedSearchText(str, searchTerm);
  }
  if (fromText && fromText.length > 0) {
    textHold = fromText + ": " + textHold;
  }
  return textHold;
};

export const highlightedSearchText = (str, searchTerm) => {
  const wrappedValue = str.replace(
    new RegExp(`(${searchTerm})(?![^<>]*>)`, "gi"),
    '<span className="highlighted">$1</span>'
  );
  // const wrappedValue = str.replace(new RegExp(searchTerm, 'gi'), (match) => {
  // return `<span class="highlighted">${match}</span>`;
  // });
  return wrappedValue;
};

const ConversationView = (props) => {
  const { convo, lastMessage, unreadMessagesCount, participants } = props;
  const { user } = useSelector((state) => state.user);
  const { isChatLoading } = useSelector((state) => state.chat);

  const author =
    props.messages && props.messages.length > 0
      ? props.messages[props.messages.length - 1]?.author
      : null;

  const showTagMentionedPeople = () => {
    if (convo.lastReadMessageIndex && props.messages.length > 0) {
      const holdRemainingMessages = props.messages.filter(
        (x) => x.index > convo.lastReadMessageIndex
      );
      const filterList = holdRemainingMessages.filter(
        (x) =>
          x.body
            .toLowerCase()
            .indexOf((user.first_name + " " + user.last_name).toLowerCase()) >
          -1
      );
      return filterList.length > 0 ? (
        <span className="tagSomeoneMessage">@</span>
      ) : (
        ""
      );
    } else {
      return "";
    }
  };

  return (
    <li
      id={`convo-${convo.sid}`}
      className={props.currentConvoSid === convo.sid ? "active" : ""}
      onClick={props.changeRoom}
    >
      <div
        style={{
          backgroundImage: `url(${getChatUserImage(participants, user)})`,
        }}
        className="profile_photo"
      ></div>

      <div className="messageDetail">
        <span className="lastSeen">
          {getLastMsgTime(_.last(props.messages)?.dateCreated)}
        </span>
        <p className="userName">
          <span
            dangerouslySetInnerHTML={{
              __html:
                convo?.friendlyName || getChatUserName(participants, user),
            }}
          ></span>
          &nbsp;
        </p>
        <p className="userDetail">
          {convo?.attributes?.companyName || ""}&nbsp;
        </p>
        <p
          className={`message ${unreadMessagesCount > 0 ? "bold" : "light"} ${
            author === "system" ? "styleItalic" : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: filterHtml(lastMessage),
          }}
        ></p>
        {unreadMessagesCount > 0 && (
          <>
            {showTagMentionedPeople()}
            <span className="messageCount"><span>{unreadMessagesCount > 5 ? '5+' : unreadMessagesCount}</span></span>
          </>
        )}
      </div>
    </li>
  );
};

export default ConversationView;
