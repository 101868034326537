import axionIns from "../axios";

const getReferralInvites = async (type = null) => {
  return axionIns.get(`invite_referral?type=${type}`).then((response) => {
    return response.data;
  });
};

const addIndividualReferral = async (data) => {
  return axionIns
    .post(`invite_referral`, JSON.stringify(data))
    .then((response) => {
      return response.data;
    });
};

const removeIndividualInvite = async (id) => {
  return axionIns.delete(`invite_referral/${id}`).then((response) => {
    return response.data;
  });
};

const editInvitePayout = async (id, payload) => {
  return axionIns.put(`invite_referral/updatepayout/${id}`, JSON.stringify(payload)).then((response) => {
    return response.data;
  });
};


const exportOject = {
  getReferralInvites,
  addIndividualReferral,
  removeIndividualInvite, editInvitePayout
};

export default exportOject;
