import axionIns from "../axios";
import { LOGIN_TYPE } from "../constants";
const signup = async (payload) => {
  return axionIns.post("auth/signup", JSON.stringify(payload)).then((response) => { return response.data });
};
const login = async (payload) => {
  return axionIns
    .post("auth/signin", JSON.stringify(payload)).then((response) => { return response.data; });
};

const socialLogin = async (idToken) => {
  return axionIns
    .post("auth/social", { token: idToken, type: LOGIN_TYPE.NORMAL }).then((response) => { return response.data; });
};
const logout = async () => {
  return axionIns.post("signout", {}).then((response) => {
    // if (response.data.status) {
    //   localStorage.removeItem("kimbocorp");
    //   localStorage.removeItem("token");
    // }
    // localStorage.removeItem("zendeskToken");
    return response.data;
  });
};

const verifyEmail = async (payload) => {
  return axionIns
    .get("auth/verify-email/" + payload).then((response) => { return response.data; });
};

const twilioToken = async (payload) => {
  return axionIns
    .get("auth/twiliotoken/" + payload).then((response) => { return response.data });
};

const adminLoginVerify = async (payload) => {
  return axionIns
    .get(`/auth/verifyadminasuser/${payload.userId}/${payload.adminId}`).then((response) => { return response.data; });
};


const forgotPassword = async (payload) => {
  return axionIns
    .post("auth/forgetpassword", JSON.stringify(payload)).then((response) => { return response.data; });
};

const forgotPasswordVerifyToken = async (payload) => {
  return axionIns
    .post("auth/verifyforgetlink", JSON.stringify(payload)).then((response) => { return response.data; });
};

const resetPassword = async (payload) => {
  return axionIns
    .post("auth/resetpassword", JSON.stringify(payload)).then((response) => { return response.data; });
};

const verifyAuthToken = async () => {
  return axionIns.get("verifyAuthToken").then((response) => { return response.data; });
};

const getUserEmailFromTokenID = async (token) => {
  return axionIns.post("auth/getUserEmailFromTokenID", JSON.stringify({token: token})).then((response) => { return response.data; });
};

export default {
  signup,
  login,
  logout,
  socialLogin,
  verifyEmail,
  adminLoginVerify,
  twilioToken,
  forgotPassword,
  forgotPasswordVerifyToken,
  resetPassword,
  verifyAuthToken,
  getUserEmailFromTokenID
};
