import React from "react";
import Header from "../../components/header/header";
import Authentication from "./authentication";
import EditPassword from "./editPassword";
import LoginDetail from "./loginDetail";

export default function Security() {
  return (
    <>
      <Header title="Security" subtitle="Security" />
      <div className="container-fluid inner-container-fluid py-4 paddingBottomMD">
        <div className="row">
          <div className="col-lg-4 col-xs-12 col-md-12">
            <div className="card new-card authentication h-40">
              <Authentication />
            </div>
          </div>
          <div className="col-lg-8 col-xs-12 col-md-12">
            <div className="card h-70 mt-4 mt-lg-0">
              <EditPassword />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-5"></div>
          <div className="col-lg-7 col-xs-12 col-md-12">
            <div className="card h-100">
              {/* <LoginDetail /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
