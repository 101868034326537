import React, { useEffect, useState } from "react";
// import { STATIC_EXTERNAL_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { sendMessageToAdmin } from "../../actions/chat";
import { useSelector } from "react-redux";
import { MESSAGE_TYPE } from "../../constants";
import { companyLink } from "../ContactUs/ContactUs";

export default function SpeakToAdmin({ designType }) {
  const navigate = useNavigate();
  const [formaliseLoader, setFormaliseLoader] = useState(true);
  const { selectedCompany } = useSelector((state) => state.company);

  useEffect(() => {
    setFormaliseLoader(false);
  }, [])


  const sendMessageToAdminfunc = async () => {
    setFormaliseLoader(true);
    await sendMessageToAdmin(`<strong>Speak to admin:</strong> I am interested in formalising my holding company for ${companyLink(selectedCompany)}`, selectedCompany, MESSAGE_TYPE.FORMALISE_MY_HOLDING_COMPANY, navigate, [])
    setFormaliseLoader(false);
  }

  return <>
    <button
      disabled={formaliseLoader}
      onClick={sendMessageToAdminfunc}
      className={`btn card-btn m-0 companySpotlightStep5 ${designType==='popup' ? "popup speakToAdmin" : "default"}`}
    >
      {formaliseLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Speak to admin
    </button>
  </>
}
