import { HOLDERS, HOLDERS_PAGE_TYPE } from "../constants";
import officerService from "../services/officer.service";
import { addPromoterAction, getPendingInvites } from "./promoters";
import {
  GET_OFFICER_LIST,
  LOAD_OFFICER_SKELETON,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
} from "./types";

export const getOfficerList = (companyId) => async (dispatch) => {
  dispatch({ type: LOAD_OFFICER_SKELETON, payload: true });
  return officerService.getOfficer(companyId).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_OFFICER_LIST, payload: resp.data, pendingInviteList: resp.pendingInviteList });
    } else {
      dispatch({ type: GET_OFFICER_LIST, payload: [], pendingInviteList: [] });
    }
  });
};

export const addOfficerAction = (payload, pageType = null) => (dispatch) => {
  dispatch({ type: LOAD_OFFICER_SKELETON, payload: true });
  return officerService.addOfficer(payload).then(async (resp) => {
    if (resp.status) {
      await dispatch(addPromoterAction(payload.company, payload));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    // if (pageType === HOLDERS_PAGE_TYPE.INVITES) {
    //   await dispatch(getPendingInvites(payload.company, HOLDERS.directors));
    // }else{
    await dispatch(getOfficerList(payload.company));
    // }
  });
};

export const updateOfficerAction =
  (companyId, officerId, payload) => (dispatch) => {
    return officerService.updateOfficer(officerId, payload).then((resp) => {
      if (resp.status) {
        if (companyId) dispatch(getOfficerList(companyId));
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      } else {
        dispatch({
          type: SET_FAILED_MESSAGE,
          payload: "Something wen't wrong",
        });
      }
    });
  };

export const removeOfficer = (companyId, payload) => (dispatch) => {
  return officerService.deleteOfficer(payload).then((resp) => {
    if (resp.status) {
      dispatch(getOfficerList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const appointDirectorAction = (companyId, payload) => (dispatch) => {
  return officerService.appointDirector(payload).then((resp) => {
    if (resp.status) {
      dispatch(getOfficerList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const getOfficerPendingList = (companyId, payload) => (dispatch) => {
  return officerService
    .pendingInviteOfficers(companyId, payload)
    .then((resp) => {
      if (resp.status) {
        dispatch({ type: GET_OFFICER_LIST, payload: resp.data, pendingInviteList: [] });
      } else {
        dispatch({ type: GET_OFFICER_LIST, payload: [], pendingInviteList: [] });
      }
    });
};

export const deleteOfficerInvite = (companyId, payload) => (dispatch) => {
  return officerService.deleteInvite(companyId, payload).then((res) => {
    // dispatch(getOfficerPendingList(companyId, 3))
    dispatch(getOfficerList(companyId));
    if (res.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: res.data })
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: res.data })
    }
  })
}

export const addDirectorChannels = (payload) => (dispatch) => {
  return officerService.addDirectorChannels(payload).then((res) => {
    if (res.status) {
      dispatch({ SET_SUCCESS_MESSAGE, payload: res.data })
    } else {
      dispatch({ SET_FAILED_MESSAGE, payload: res.data })
    }
  })
}