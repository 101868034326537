import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { verifyEmailAction } from "../../actions/auth";
import Loader from "../../components/loader/loader";
import { RoutesPaths } from "../../Router/constant";

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const { verificationEmail, loader } = useSelector((state) => state.auth);
  const loadRef = useRef(true);

  const params = useParams();
  const { token } = params;
  useEffect(() => {
    if (loadRef.current && token) {
      dispatch(verifyEmailAction(token));
    }
    return () => {
      loadRef.current = false;
    };
  }, [dispatch, token]);

  return (
    <>
      {!loader && verificationEmail ? (
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 Maincontainer">
              <div className="Innercontainer">
                <div className="Mainheading">
                  {verificationEmail && verificationEmail.data}
                </div>
                <div className="msgicon">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="declaration">
                  {verificationEmail && verificationEmail.status
                    ? "Thank you, your email has been verified. Your account is now active. Please use the link below to login to your account."
                    : "Thank you, your email has already been verified. Please use the link below to login to your account."}
                </div>
                <a className="btn btncust" href={RoutesPaths.HOME}>
                  Login to your account
                </a>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      ) :
        <Loader />
      }
    </>
  );
}
