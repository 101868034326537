import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { MESSAGE_TYPE } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "../../../../components/currencyFormat/currencyFormat";
import toast from "react-hot-toast";
import { addIndividualReferralAction, editInvitePayoutAction } from "../../../../actions/referralInvites";
import moment from "moment";
import { useForm } from "react-hook-form";
import { companyLink } from "../../../../components/ContactUs/ContactUs";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../../../Router/constant";
export default function AllInvites({ sendMessageToAdmin, adminLoader }) {
  const dispatch = useDispatch();
  const { referralInvites, loadReferral } = useSelector(
    (state) => state.referralInvites
  );
  const {
    reset,
    register,
    handleSubmit, setValue
  } = useForm();
  const { isAdminLoggedIn } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(null);
  const { selectedCompany } = useSelector((state) => state.company);
  const navigate = useNavigate()
  const [isEditingItem, setIsEditingItem] = useState(null);
  const [isSaveButton, setIsSaveButton] = useState(false);

  const [copyLinkLoader, setCopyLinkLoader] = useState(false);

  const copyLink = async () => {
    // setCopyLinkLoader(true);
    // var resp = await dispatch(addIndividualReferralAction({ global: 1 }));
    // if (resp.status) {
    //   setCopyLinkLoader(false);
    //   const shortUrl = resp?.data?.short_url;
    //   if (shortUrl) {
    //     navigator.clipboard
    //       .writeText(shortUrl)
    //       .then(() => {
    //         toast.success("URL copied to clipboard");
    //       })
    //       .catch(() => {
    //         console.error("Failed to copy text: ");
    //       });
    //   }
    // }
    navigate(RoutesPaths.INVITE)
  };

  useEffect(() => {
    setValue("payoutBalance", isEditingItem?.payoutBalance)
  }, [setValue, isEditingItem])


  const onSubmit = async (data) => {
    if (isEditingItem?.id) {
      await dispatch(editInvitePayoutAction(isEditingItem?.id, data))
    }
    setIsEditing(null);
    setIsEditingItem(null);
    setIsSaveButton(false)
    reset()
  };
  return (
    <>
      <div className="accountBorderSection">
        {loadReferral ? (
          <div className="row cashHederSectionPadding">
            <div className="col-md-12">
              <div style={{ width: "100%", marginLeft: "auto" }}>
                <ContentLoader width="100%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                </ContentLoader>
              </div>
            </div>
          </div>
        ) : (
          <div className="row cashHederSectionPadding">
            <div className="col-md-6 col-6 button_align">
              <h6 className="cashHeaderText cashDarkText m-0">
                Payout{" "}
                <span className="asof">
                  (as of {moment().format("DD/MM/YYYY")})
                </span>
              </h6>
            </div>
            <div className="col-md-6 col-6 button_align justify-content-end">
              {isAdminLoggedIn && isSaveButton &&
                <button className="btn save-button customFilterButton iconMarginLeft" type='button' onClick={handleSubmit(onSubmit)}>
                  Save
                </button>
              }
            </div>
          </div>
        )}

        <div className="col-12">
          <hr className="m-0"></hr>
        </div>

        <div className="cashBottomPadding">
          <div className="row">
            <div className={`col-6`}>
              <p className="currentBalanceText m-0">Payout balance</p>
            </div>
            <div className="col-6 rightAlignText">
              <p className="currentBalanceText m-0 ">From username</p>
            </div>
          </div>
          {loadReferral ? (
            <div className="row">
              <div className="col-12 currentBalanceDetails">
                <ContentLoader width="100%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                </ContentLoader>
              </div>
            </div>
          ) : (
            referralInvites?.map((record, index) => (
              <div key={index} className="row mb-2">
                <div className={`col-6`}>
                  <div className="editPayout">
                    {isAdminLoggedIn &&
                      <span className="btn btn-link  px-3 mb-0 edit-button iconMarginLeft payoutBalanceButton" onClick={() => { setIsEditing(index); setIsEditingItem(record); setIsSaveButton(true) }}>
                        <i className="fas fa-pencil-alt edit-button me-2"></i>
                      </span>
                    }
                    {isEditing === index ?
                      <input
                        type='text'
                        className='form-control'
                        {...register("payoutBalance", { required: true })}
                      />
                      :
                      <h6 className="currentBalanceDetails m-0 payoutBalance">
                        USD{" "}
                        <CurrencyFormat
                          currency={"usd"}
                          value={parseFloat(record?.payoutBalance)}
                          decimal={2}
                        />
                      </h6>
                    }
                  </div>
                </div>

                <div className={`col-6 rightAlignText`}>
                  <h6 className="currentBalanceDetails m-0">
                    {record?.email || "-"}
                  </h6>
                </div>
              </div>
            ))
          )}

          {loadReferral ? (
            <div className="row cashBottomButton">
              <div className="col-6">
                <ContentLoader width="72%" height={50}>
                  <rect x="0" y="0" rx="10" ry="10" width="72%" height="40" />
                </ContentLoader>
              </div>
              <div className="col-6 skeletonFlexEnd">
                <div style={{ width: "65%", marginLeft: "auto" }}>
                  <ContentLoader width="100%" height={50}>
                    <rect
                      x="0"
                      y="0"
                      rx="10"
                      ry="10"
                      width="100%"
                      height="40"
                    />
                  </ContentLoader>
                </div>
              </div>
            </div>
          ) : (
            <div className="row cashBottomButton">
              <div className="col-6">
                <button
                  onClick={copyLink}
                  disabled={
                    copyLinkLoader || adminLoader === 1 || adminLoader > 0
                      ? true
                      : false
                  }
                  type="button"
                  className="customFilterButton mobileViewImportButton">
                  {copyLinkLoader && (
                    <i className="animateSpin fa-solid fa-circle-notch"></i>
                  )}{" "}
                  Invite friends
                </button>
              </div>
              <div className="col-6 rightAlignText">
                <button
                  disabled={adminLoader === 2 || adminLoader > 0 ? true : false}
                  type="button"
                  onClick={() =>
                    sendMessageToAdmin(
                      MESSAGE_TYPE.USER_DASHBOARD,
                      `<strong>Withdraw funds</strong>: I am interested to withdraw Payout funds for ${companyLink(selectedCompany)}`,
                      2
                    )
                  }
                  className="customImportButton mobileViewImportButton m-0">
                  {adminLoader === 2 && (
                    <i className="animateSpin fa-solid fa-circle-notch"></i>
                  )}{" "}
                  Withdraw funds
                </button>
              </div>
            </div>
          )}
        </div>
      </div >
    </>
  );
}
