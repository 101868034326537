import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ProfileActivity from "./profileActivity";
import ProfileBanner from "./profileBanner";
import ProfileInfo from "./profileInfo";
import { RoutesPaths } from "../../Router/constant";

export default function Profile() {
  const { user, } = useSelector((state) => state.user);

  return (
    <>
      <Header title="Profile" subtitle="Profile" />
      <div className="container-fluid py-4">
        <ProfileBanner user={user} />
      </div>
      <div className="container-fluid minHeightVH py-4 paddingBottomMD">
        <div className="row">
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 mb-4">
            <div className="card h-100">
              <ProfileInfo />
            </div>
          </div>
          <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mb-4">
            <div className="card h-100">
              <ProfileActivity />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="footer-row mt-0">
              <h6 className="looking-text-bottom">
                Important note{" "}
                <img
                  src="assets/img/info-icon.svg"
                  alt=""
                  className="m-0 pb-1"
                />
              </h6>
              <p className="manage-share-text">The information on this page will not appear in any public domain but is used for our due diligence and account verification purposes. Learn more about our due diligence process. This tells people what our due diligence process looks like and why they 100% must do it.</p>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-12 mt-4">
            <Footer
              linkObj={{
                title: "Edit profile",
                link: RoutesPaths.ACCOUNTSETTING,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
