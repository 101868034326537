import React, { useEffect } from 'react'
import PlanDetailComponent from './PlanDetailComponent';
import PayWithCryptoPopup from './PayWithCryptoPopup';
import { useSelector } from 'react-redux';
import { SUPPORTED_PAYMENT_METHOD, SUPPORTED_PAYMENT_METHOD_NAME } from '../../constants';
import Header from '../../components/header/header';
import { useLocation, useNavigate } from 'react-router';
import { RoutesPaths } from '../../Router/constant';

export default function MakePayment() {
    const navigate = useNavigate();

    const {
        paymentMethodSelection,
        savedbankInfoDetails,
        isTripleaUrl
    } = useSelector((state) => state.stripe);
    const location = useLocation();
    const pathnames = location?.pathname?.split("/").filter((x) => x);
    useEffect(() => {
        if (!isTripleaUrl) {
            navigate(RoutesPaths.PRODUCTS_LIST_PAGE)
        }

    }, [isTripleaUrl])

    return (
        <>
            <Header
                title="Payments"
                subtitle="Payments"
                mobileHeader={
                    paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
                        paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                        ? SUPPORTED_PAYMENT_METHOD_NAME[paymentMethodSelection]
                        : paymentMethodSelection ===
                            SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                            ? `Crypto transfer`
                            : "-"
                }
            />
            <div className="container-fluid py-4 bankPage paddingBottomMD">
                {pathnames[1] == "payment" && (
                    <h4 className="header">
                        {paymentMethodSelection ===
                            SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
                            paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                            ? SUPPORTED_PAYMENT_METHOD_NAME[paymentMethodSelection]
                            : paymentMethodSelection ===
                                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                ? `Crypto transfer`
                                : "-"}
                    </h4>
                )}
                <div className="outerRow">
                    <div className="row">
                        <div className="col-md-4">
                            <PlanDetailComponent quantityWatch={savedbankInfoDetails?.quantity} />
                        </div>
                        <div className="col-md-8">
                            <PayWithCryptoPopup url={isTripleaUrl} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
