import React from "react";
import { Modal } from "react-bootstrap";
import { CAPITAL } from "../../constants";

export default function InfoModal({ infoModalShow, infoModalClose, capitalType }) {
  return (
    <>
      <Modal
        show={infoModalShow}
        onHide={infoModalClose}
        className="custom-modal info-modal ps-0"
      >
        <div className="modal-header px-0 pt-0">
          <h5 className="edit-title">
            {capitalType === CAPITAL.shared ? "Share" : capitalType === CAPITAL.paidUp ? "Paid-up" : ""} capital
          </h5>
          <button
            type="button"
            className="btn-close close-button text-dark"
            onClick={infoModalClose}
          >
          </button>
        </div>
        {capitalType === CAPITAL.shared && (
          <div className="modal-body">
            <p className="edit-description">Share capital refers to the amount of money that shareholders have committed to the holding company. Share capital can be issued with or without full payment from the shareholders.</p>
            <p className="edit-description">For example, holding company A issues 100,000 shares at $1 each to its shareholders. This brings the issued share capital to $100,000. However, the shareholders have only paid up to 50% of their share capital, which means that the paid-up capital is $50,000 and the unpaid share capital is $50,000. If shareholders pay the remaining 50% of the share capital, then holding company A's paid-up capital will be $100,000 and the share capital remains at $100,000. If holding company A subsequently issues (adds) $100,000 more in share capital, then the amount of share capital increases to $200,000.
.</p>
            {/* <p className="edit-description"><a href="https://www.kimbocorp.com/blog/what-does-share-capital-mean-to-a-virtual-business" target="_blank" rel="noreferrer">Learn more about share capital</a></p> */}
          </div>
        )}
        {capitalType === CAPITAL.paidUp && (
          <div className="modal-body">
            <p className="edit-description">Paid-up capital is the total sum of investment capital received by any holding company from its shareholders in exchange for shares. In other words, it refers to the amount of money a holding company has received from shareholders who have paid for their purchased shares.</p>
            <p className="edit-description">A holding company that sells its shares in the primary market directly to investors will increase its paid-up capital. A shareholder can get a return from the investment in the holding company if the proceeds from a buying shareholder goes to the selling shareholder without flowing into the holding company. Any additional capital flowing into the holding company by any shareholder would result in an increase in its paid-up capital.</p>
            {/* <p className="edit-description"><a href="https://www.kimbocorp.com/blog/what-does-paid-up-capital-mean-to-a-virtual-business" target="_blank" rel="noreferrer">Learn more about paid-up capital</a></p> */}
          </div>
        )}
      </Modal>
    </>
  );
}
