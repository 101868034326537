import React, { useEffect, useState } from "react";
import AddPromoterModal from "./AddPromoterModal";
import DeletePromoter from "./DeletePromoter";
import EditPromoterModal from "./editPromoterModal";
import { useDispatch, useSelector } from "react-redux";
import { backendUrl, HOLDERS, I_POP_OVER } from "../../constants";
import {
  getPromoterAction,
  removePromoterAction,
  removePromoterCompanyAction,
} from "../../actions/promoters";
import StartMessageModel from "../../components/message/StartMessageModel";
import AppointModal from "../../components/sharetable/appointModal";
import { useLocation, useNavigate } from "react-router";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { getSettingsAction } from "../../actions/settings";
import { getAllCompany } from "../../actions/company";
import InfoModalStatic from "../dashboard/infoModalStatic";

const INVITE_TYPE = {
  PROMOTERS: 0,
  INVITES: 1,
};

export default function InviteTabel(props) {
  const type = props.type;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { selectedCompany, hasPermission, loadCompany } = useSelector(
    (state) => state.company
  );
  const { mergerPromoter, holdNextInviteTempData } = useSelector((state) => state.promoters);
  const { defaultPromoter } = useSelector((state) => state.settings);
  const [addPromoterModalShow, setAddPromoterModalShow] = useState(false);
  const [editPromoterModalShow, setEditPromoterModalShow] = useState(false);
  const [removeProModalShow, setRemoveProModalShow] = useState(false);
  const [record, setRecord] = useState(5);
  const [actionData, setActionData] = useState(null);
  const [deletePromoterType, setDeletePromoterType] = useState(null);
  const [messageModal, setMessageModal] = useState(false);
  const [appointModalShow, setAppointModalShow] = useState(false);
  const [performObj, setPerformObj] = useState({});
  const [currentSelection, setCurrentSelection] = useState(null);
  const { objective } = selectedCompany
  let preDefaultPromoters = (objective?.length > 0 && objective[0]?.defaultPromoters) ? objective[0]?.defaultPromoters?.map(item => item.email) : [];
  if (defaultPromoter.length > 0) {
    preDefaultPromoters = preDefaultPromoters.concat(defaultPromoter)
  }
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  useEffect(() => {
    dispatch(getSettingsAction())
  }, [])


  useEffect(() => {
    if (props?.triggerHandleMessageInternal && holdNextInviteTempData?.type === HOLDERS.promoters) {
      const getList = mergerPromoter.find(x => x.email === holdNextInviteTempData?.email)
      if (getList) {
        props?.closeCongratulationsPopup()
        handleMessage(getList);
      } else {
        props?.closeCongratulationsPopup()
      }
    }
  }, [props?.triggerHandleMessageInternal])


  const addPromoterModalClose = () => {
    setAddPromoterModalShow(false);
    navigate({ state: "" });
  };
  const handleAdd = () => setAddPromoterModalShow(true);

  const editPromoterModalClose = () => {
    setEditPromoterModalShow(false);
  };
  // const handleEdit = () => setEditPromoterModalShow(true);

  const handleMessage = (list) => {
    setCurrentSelection(list)
    setMessageModal(true);
  };

  const appointModalClose = () => {
    setAppointModalShow(false);
  };

  const handleAppointModalShow = (valueObj) => {
    setPerformObj(valueObj);
    setAppointModalShow(true);
  };

  const removeProModalCloseConfirm = async () => {
    if (deletePromoterType === INVITE_TYPE.PROMOTERS) {
      if (actionData.id)
        await dispatch(
          removePromoterCompanyAction(selectedCompany.id, {
            promoterId: actionData.id,
          })
        );
    } else if (deletePromoterType === INVITE_TYPE.INVITES) {
      if (actionData.id)
        await dispatch(removePromoterAction(selectedCompany.id, actionData.id));
    } else {
    }
    setRemoveProModalShow(false);
  };
  const handleRemove = (type, value) => {
    setDeletePromoterType(type);
    setActionData(value);
    setRemoveProModalShow(true);
  };

  const onLoad = () => {
    setRecord(record + 5);
  };

  useEffect(() => {
    dispatch(getAllCompany())
  }, [])

  useEffect(() => {
    const { id } = selectedCompany;
    if (id) {
      // if (id && type === HOLDERS_PAGE_TYPE.INVITES) {
      // dispatch(getPendingInvites(id, 1));
      // } else {
      dispatch(getPromoterAction(id));
      // }
    }
  }, [dispatch, type, selectedCompany, location]);

  const checkAppointStatus = (currentPromoterid, type = null) => {
    var returnVal = type ? null : false;
    const { appointPromoters } = selectedCompany;
    const returnFilter = appointPromoters.filter(
      (x) => x.promoters === currentPromoterid
    );

    if (returnFilter.length > 0) {
      if (type) {
        returnVal = returnFilter[0].isAppoint
          ? "appoint"
          : returnFilter[0].isAppointed
            ? "appointed"
            : "";
      } else {
        if (!returnFilter[0].isAppoint && !returnFilter[0].isAppointed) {
          returnVal = true;
        }
      }
    } else {
      returnVal = true;
    }
    return returnVal;
  };

  useEffect(() => {
    if (location.state === "invitePromoter") {
      setAddPromoterModalShow(true);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getSettingsAction())
  }, [dispatch])

  const checkAppointPermission = (item) => {
    const filterVal = selectedCompany.appointPromoters.findIndex(x => x.promoters === item.id && x.isAppointActionTaken === true)
    var returnValue = false
    if (selectedCompany.user === user.id && filterVal === -1) {
      returnValue = true
    }
    return returnValue
  }

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <div className="share-officer">
        <div className="header-promoters">Invites</div>
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-7">
                <h3 className="business-font capital-head high-cap px-2">
                  Promoters&nbsp;
                  <img
                    src="assets/img/info-icon.svg"
                    onClick={() => handleClickInfo(I_POP_OVER.PROMOTERS)}
                    alt=""
                    className="m-0 pb-1 cursorPointer"
                  />
                </h3>
                {/* <h5 className="font-weight-bolder payment-text high-cap px-2">Promoters</h5> */}
              </div>
              <div className="col-lg-6 col-5 my-auto text-end">
                {hasPermission && (
                  <div className="dropdown float-lg-end">
                    <button
                      className="btn add-button add-button-shareholder m-0"
                      onClick={handleAdd}
                    >
                      Add promoter
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2 pt-2">
            {!loadCompany ? (
              mergerPromoter &&
                mergerPromoter.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-sharholder">
                    <thead>
                      <tr className="tableHeadings">
                        <th className="name-heading">Name</th>
                        <th className="position-heading">
                          Email address
                        </th>
                        <th className="date-heading">
                          Date added
                        </th>
                        <th className="action-heading">
                          Actions
                        </th>
                        <th className="status-heading">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-row-color">
                      {mergerPromoter &&
                        mergerPromoter.length > 0 &&
                        mergerPromoter
                          .slice(0, record)
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 shareHolderName">
                                      <div
                                        className="shareholder-img"
                                        style={{
                                          backgroundImage: `url(${item &&
                                            item.profilePic &&
                                            item.profilePic.filename
                                            ? backendUrl +
                                            item.profilePic.filename
                                            : "assets/img/Frame1.svg"
                                            })`,
                                        }}
                                      />
                                      {(item.first_name ? item.first_name : "") +
                                        " " +
                                        (item.first_name && item.last_name
                                          ? item.last_name
                                          : "")}
                                      {checkAppointStatus(item.id, true) ===
                                        "appointed" ? (
                                        <img
                                          className="verifiedIcon"
                                          src="assets/img/verified.svg"
                                          alt="verified.svg"
                                        />
                                      ) : (
                                        checkAppointStatus(item.id, true) ===
                                        "appoint" && (
                                          <img
                                            className="verifiedIcon"
                                            src="assets/img/ApprovedBadge.svg"
                                            alt="ApprovedBadge.svg"
                                          />
                                        )
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <div
                                  className="shareCreateDate shareCreateEmail activity_wrapper limit-text cursor-pointer"
                                  title={item.email}
                                >
                                  {item.email}
                                </div>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <span className="shareCreateDate">
                                  <TimeFormat time={item.createdAt} type={1} />
                                </span>
                              </td>
                              <td className="text-center">
                                {('isEmailVerified' in item && item?.isEmailVerified) ? (
                                  hasPermission && (
                                    <>
                                      {/* <span className="btn btn-link  px-3 mb-0 edit-button">
                            <i
                              className="fa fa-solid fa-edit right-delete-icon"
                              aria-hidden="true"
                              onClick={handleEdit}
                            ></i>
                          </span> */}
                                      {selectedCompany?.removePromoters?.includes(
                                        item.id
                                      ) ? ("") : (
                                        <span className="action-icons d-flex align-item-center justify-content-center flex-direction-colum btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                          {user.email !== item.email && (
                                            <i
                                              className="fa-solid fa-comment-dots mx-2"
                                              onClick={() => handleMessage(item)}
                                            ></i>
                                          )}
                                          {checkAppointStatus(item.id) && checkAppointPermission(item) && (
                                            <i
                                              className="fas fa-user-check mx-1"
                                              onClick={() =>
                                                handleAppointModalShow(item)
                                              }
                                            ></i>
                                          )}
                                          {(item.email === user.email && !preDefaultPromoters.includes(item.email)) && (
                                            <i
                                              className="fas fa-trash-alt mx-2"
                                              aria-hidden="true"
                                              onClick={() =>
                                                handleRemove(
                                                  INVITE_TYPE.PROMOTERS,
                                                  item
                                                )
                                              }
                                            ></i>
                                          )}
                                        </span>
                                      )}
                                    </>
                                  )
                                ) : (
                                  hasPermission && (
                                    <>
                                      {/* <span className="btn btn-link  px-3 mb-0 edit-button">
                              <i
                                className="fa fa-solid fa-edit right-delete-icon"
                                aria-hidden="true"
                                onClick={handleEdit}
                              ></i>
                            </span> */}
                                      {item?.is_delete_requested ? (
                                        <>&nbsp;</>
                                      ) : (
                                        (!preDefaultPromoters.includes(item.email) ? (
                                          <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                            <i
                                              className="fas fa-trash-alt"
                                              aria-hidden="true"
                                              onClick={() =>
                                                handleRemove(INVITE_TYPE.INVITES, item)
                                              }
                                            ></i>
                                          </span>
                                        ) : (<span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">&nbsp;</span>))
                                      )}
                                    </>
                                  )
                                )}
                              </td>
                              <td className="text-sm table-heading date-padding status-type">
                                {(item?.is_delete_requested || selectedCompany?.removePromoters?.includes(item.id)) ? (
                                  <button className="btn amendment-progress-button deletion">
                                    Removal in progress
                                  </button>
                                ) : (
                                  <button className={`btn ${('isEmailVerified' in item && item?.isEmailVerified) ? 'active-button-second' : ""} pending-button-second m-0`}>
                                    {('isEmailVerified' in item && item?.isEmailVerified) ? "Invite accepted" : "Pending"}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {mergerPromoter.length > record && (
                    <button
                      onClick={onLoad}
                      className="btn btn-sm  view-button mb-4 mt-3"
                    >
                      View all
                    </button>
                  )}
                </div>
              ) : (
                <div className="no-record d-flex pt-2 pb-2">
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 className="mb-0 text-sm table-heading ">
                      No promoters added
                    </h6>
                  </div>
                </div>
              )) : (
              <div className="table-responsive p-3">
                {[80, 60].map((item, index) => (
                  <ContentLoader key={index} width={item + '%'} height={15} >
                    <rect rx="5" ry="5" width={item + '%'} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Invites */}
        {/* <div className="header-promoters mt-5">Position invites</div>
        <div className="card ">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-7">
                <h3 className="business-font capital-head high-cap px-2">Promoters invites</h3>
              </div>
              <div className="col-lg-6 col-5 my-auto text-end">
                {hasPermission && (
                  <div className="dropdown float-lg-end">
                    <button
                      className="btn add-button add-button-shareholder m-0"
                      onClick={handleAdd}
                    >
                      Add promoter
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2 pt-2">
            {!loadCompany ? (
              promoters && promoters.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-sharholder">
                    <thead>
                      <tr className="tableHeadings">
                        <th className="name-heading">Name</th>
                        <th className="position-heading">
                          Email address
                        </th>
                        <th className="date-heading">
                          Date added
                        </th>
                        <th className="action-heading">
                          Actions
                        </th>
                        <th className="status-heading">
                          Status <img
                            src="assets/img/info-icon.svg"
                            onClick={() => handleClickInfo(I_POP_OVER.SHAREHOLDERS_DIRECTOR_INVITEE_STATUS)}
                            alt=""
                            className="m-0 pb-1 cursorPointer"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-row-color">
                      {promoters &&
                        promoters.length > 0 &&
                        promoters.slice(0, record).map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 shareHolderName">
                                    <div
                                      className="shareholder-img"
                                      style={{
                                        backgroundImage: `url(${item &&
                                          item.profilePic &&
                                          item.profilePic.filename
                                          ? backendUrl +
                                          item.profilePic.filename
                                          : "assets/img/Frame1.svg"
                                          })`,
                                      }}
                                    />
                                    {(item.first_name ? item.first_name : "") +
                                      " " +
                                      (item.first_name && item.last_name
                                        ? item.last_name
                                        : "")}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-sm">
                              <div
                                className="shareCreateDate shareCreateEmail activity_wrapper limit-text cursor-pointer"
                                title={item.email}
                              >
                                {item.email}
                              </div>
                            </td>
                            <td className="align-middle ">
                              <span className="shareCreateDate">
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </span>
                            </td>
                            <td className="action-icons d-flex align-item-center justify-content-center flex-direction-colum">
                              {hasPermission && (
                                <>
                                  {item?.is_delete_requested ? (
                                    <button className="btn amendment-progress-button deletion">
                                      Removal in progress
                                    </button>
                                  ) : (
                                    (!preDefaultPromoters.includes(item.email) ? (
                                      <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                        <i
                                          className="fas fa-trash-alt"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleRemove(INVITE_TYPE.INVITES, item)
                                          }
                                        ></i>
                                      </span>
                                    ) : (<span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">&nbsp;</span>))
                                  )}
                                </>
                              )}
                            </td>
                            <td className="status-type">
                              <button className="btn pending-button-second m-0 ">
                                Pending
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  {promoters.length > record && (
                    <button
                      onClick={onLoad}
                      className="btn btn-sm  view-button mb-4 mt-3"
                    >
                      View all
                    </button>
                  )}
                </div>
              ) : (
                <div className="no-record d-flex pt-2 pb-2">
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 className="mb-0 text-sm table-heading ">
                      No new invites added
                    </h6>
                  </div>
                </div>
              )) : (
              <div className="table-responsive p-3">
                {[90, 70].map((item, index) => (
                  <ContentLoader key={index} width={item + '%'} height={15} >
                    <rect rx="5" ry="5" width={item + '%'} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </div>
        </div> */}
      </div>
      <AddPromoterModal
        addPromoterModalShow={addPromoterModalShow}
        addPromoterModalClose={addPromoterModalClose}
        selectedCompany={selectedCompany}
      />
      <EditPromoterModal
        editPromoterModalShow={editPromoterModalShow}
        editPromoterModalClose={editPromoterModalClose}
        selectedCompany={selectedCompany}
      />
      <DeletePromoter
        removeProModalShow={removeProModalShow}
        removeProModalClose={() => setRemoveProModalShow(false)}
        removeProModalCloseConfirm={removeProModalCloseConfirm}
        actionData={actionData}
      />
      <StartMessageModel
        messageModal={messageModal}
        addModalClose={() => setMessageModal(false)}
        listing={selectedCompany.promoters}
        currentSelection={currentSelection}
        type={HOLDERS.promoters}
      />

      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />

      <AppointModal
        appointModalShow={appointModalShow}
        appointModalClose={appointModalClose}
        performObj={performObj}
        type={HOLDERS.promoters}
      />
    </>
  );
}
