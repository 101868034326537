import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addPromoterAction } from "../../actions/promoters";
import { useDispatch } from "react-redux";
import ContactUs from "../../components/ContactUs/ContactUs";
import { CONTACT_US_TRIGER, HOLDERS } from "../../constants";
import toast from "react-hot-toast";

export default function AddPromoterModal({
  addPromoterModalShow,
  addPromoterModalClose,
  selectedCompany,
}) {
  const dispatch = useDispatch();
  const [copyLinkLoader, setCopyLinkLoader] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { id } = selectedCompany;
    if (id) {
      data.type = HOLDERS.promoters;
      data.global = 0;
      dispatch(addPromoterAction(id, data));
      addPromoterModalClose();
      reset();
    }
  };

  const copyLink = async () => {
    setCopyLinkLoader(true);
    const { id } = selectedCompany;
    var resp = await dispatch(addPromoterAction(id,{ global: 1 ,type: HOLDERS.promoters}));
    if (resp.status) {
      setCopyLinkLoader(false);
      const shortUrl = resp?.data?.short_url;
      if (shortUrl) {
        navigator.clipboard.writeText(shortUrl).then(() => {toast.success("URL copied to clipboard")}).catch(() => {console.error("Failed to copy text: ")});
      }
    }
    addPromoterModalClose();
    reset();
  };
  return (
    <>
      <Modal
        show={addPromoterModalShow}
        onHide={addPromoterModalClose}
        className="custom-modal edit-information-width ps-0">
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button close-button"
            onClick={addPromoterModalClose}></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">Add a new promoter</h5>
              <p className="edit-description">
                Every business can appoint as many promoters as required.
              </p>
              <p className="edit-description">
                A promoter is any person who plays a part in forming a company
                or establishing its business (usually potential shareholders or
                directors of the business).
              </p>
              <p className="edit-description">
                A promoter can act for or as an agent of a business, or provide
                services or its own skillset to the business.
              </p>
              <p className="edit-description">
                A promoter who acts for or as an agent of the business before it
                has been legally incorporated will be personally liable for any
                contract they make on behalf or with the business.
              </p>
              <ContactUs
                type={CONTACT_US_TRIGER.PROMOTER}
                handleClose={addPromoterModalClose}
                theme="spanContactUs"
              />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">Promoter first name</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="errorMessage">
                          First name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">Promoter last name</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        {...register("last_name", {
                          required: true,
                        })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="errorMessage">
                          Last name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Promoter email</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email address"
                        {...register("pro_email", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                        })}
                      />
                      {errors.pro_email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.pro_email?.type === "pattern" && (
                        <span className="errorMessage">Email is invalid</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label shreholder-text"
                      >
                        Country Code
                      </label>
                      <Form.Select
                        className="select-border"
                        aria-label="Default select example"
                        {...register("countryCode", {
                          required: true,
                        })}
                        style={{paddingRight: '45px'}}
                      >
                        <option value="">Select</option>
                        {country.map((info, index) => (
                          <option key={index} value={info.dial_code}>{info.dial_code} ({info.name})</option>
                        ))}
                      </Form.Select>
                      {errors.countryCode?.type === "required" &&  getValues("countryCode") === "" && (
                        <span className="errorMessage">
                          Enter Country Code
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label shreholder-text"
                      >
                        Promoter Mobile Number
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile Number"
                        {...register("phoneNumber", {
                          required: true,
                        })}
                      />
                      {errors.phoneNumber?.type === "required" && (
                        <span className="errorMessage">
                          Mobile Number is required
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will send the promoter an invitation to join the business
                    dashboard.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  {/* <ContactUs type={CONTACT_US_TRIGER.PROMOTER} handleClose={addPromoterModalClose} /> */}
                  <button
                    type="button"
                    disabled={copyLinkLoader}
                    onClick={copyLink}
                    className={`btn btn-sm  mb-4 mt-3 contactus-modal-button `}>
                    {copyLinkLoader && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Copy invite link
                  </button>
                  <button type="submit" className="btn agree-button">
                    Agree & add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
