import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CURRENCY_NAME, GOLD_BID_PRICE_CONST_KEY } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import { userBidGoldPriceAction } from "../../actions/user";
import CurrencyFormat from "../currencyFormat/currencyFormat";
import { bidGoldHeaderCurrencyAction, paymentModalAction } from "../../actions/stripe";
import { selectedPlanProductAction } from "../../actions/subscriptionPlan";
import Select, { DropdownIndicatorProps, components } from "react-select";
import { customStyles } from "../CustomSelect/CustomSelect";
import { isBrowser, isMobile } from 'react-device-detect';


export default function GoldBid() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { bidGoldPrice, bidGoldHeaderCurrency, bidGoldHeader } = useSelector((state) => state.user);
  const goldHeaderArray = [
    GOLD_BID_PRICE_CONST_KEY.GOLD1KG,
    GOLD_BID_PRICE_CONST_KEY.GOLD1G,
  ];
  useEffect(() => {
    dispatch(userBidGoldPriceAction())
  }, [])

  const handleStipePopupAction = (productKey) => {
    // if (location.pathname === RoutesPaths.BANKTRANSFER) {
    //   navigate(RoutesPaths.PAYMENT)
    // }

    const product = bidGoldPrice[productKey]
    if (Object.keys(product).length > 0) {
      dispatch(selectedPlanProductAction(product))
      // dispatch(paymentModalAction(true, product?.planType?.value));
      if (product?.slug) {
        navigate(RoutesPaths.PRODUCT_PAGE.replace(":slug", product?.slug));
      }
    }
  }

  const handleCurrencyChange = (event) => {
    let payload = {}
    for (const property in bidGoldPrice) {
      payload[property] = parseFloat(bidGoldPrice[property].price)
    }
    dispatch(bidGoldHeaderCurrencyAction(event, payload))
  }

  const CaretDownIcon = () => {
    return <img src="assets/img/arrow_downIocn.svg" alt="" />;
  };

  // added type if using typescript
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div className={`bid-gold-price gold-bid-holder w-100`}>
        <div className="gold-bid-price-holder">
          {Object.keys(bidGoldHeader).length > 0 && Object.entries(bidGoldHeader).map(([key, value]) => (
            goldHeaderArray.includes(key) && (
              <div className="bidCurrencyHolder" key={key} onClick={() => handleStipePopupAction(key)}>
                <p className="bidName">{isMobile ? "Buy " : ""}{value?.smallDescription}
                  {isMobile && (<img className="bidInflation" src={`assets/img/${value?.inflation ? 'mobile-inflation-up' : 'mobile-inflation-down'}.svg`} alt="" />)}
                </p>
                <div className={`bidCurrency ${value?.inflation ? 'up' : 'down'}`}>
                  {isBrowser && (<img className="bidInflation" src={`assets/img/${value?.inflation ? 'up' : 'down'}.svg`} alt="" />)}
                  <p>{value?.currency?.name} <CurrencyFormat currency={value?.currency?.value} value={parseFloat(value?.price)} decimal={2} /></p>
                  {isBrowser && (<img className="bidBuy" src="assets/img/bidBuy.png" alt="bidBuy.png" />)}
                </div>
              </div>)
          ))}
        </div>
        {Object.keys(bidGoldHeader).length > 0 && (
          <div className="currencySelectBoxHolder">
            <form>
              <Select
                // theme={SELECT_BOX_THEME}
                components={{ DropdownIndicator }}
                styles={customStyles}
                className="select-border"
                options={CURRENCY_NAME}
                isClearable={false}
                isSearchable={false}
                getOptionLabel={(option) => (
                  <>
                    <img src={`assets/img/${option.value}.png`} alt={option.value} style={{ marginRight: 10, width: 20, height: 20 }} />
                    {option.value.toUpperCase()}
                  </>
                )}
                getOptionValue={(option) => option.value}
                onChange={handleCurrencyChange}
                defaultValue={bidGoldHeaderCurrency}
                placeholder="Select"
              />
            </form>
          </div>
        )}
      </div>
    </>
  );
}
