import React, { useState } from "react";
import { useSelector } from "react-redux";
import { I_POP_OVER } from "../../constants";
import AddressEdit from "./AddressEdit";
import InfoModalStatic from "./infoModalStatic";
import ContentLoader from "react-content-loader";

export default function AddressCard(props) {
  const { selectedCompany, hasPermission, loadCompany } = useSelector((state) => state.company);
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const [addrModalShow, setAddrModalShow] = useState(false);
  const addrModalClose = () => {
    setAddrModalShow(false);
  };

  const handleAddrModalShow = () => setAddrModalShow(true);

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type)
  }
  return (
    <>
      <div className="card-body px-0 pb-2 pt-2">
        <div className="table-responsive">
          <table className="table mb-0 tableaddresscard">
            <tbody>
              <tr>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div className="d-flex flex-column justify-content-center ">
                      <h6 className="mb-0 text-sm table-heading">
                        Registered address
                        {" "}<img
                          src="assets/img/info-icon.svg"
                          onClick={() => handleClickInfo(I_POP_OVER.REGISTERED_ADDRESS)}
                          alt=""
                          className="m-0 pb-1 cursorPointer"
                        />
                      </h6>
                      {!loadCompany ? (
                        <span className="business-font registered_wrapper_address">
                          {selectedCompany && selectedCompany.id ? (<>
                            {selectedCompany.primaryAddress
                              ? selectedCompany.primaryAddress + ', '
                              : selectedCompany.secondaryAddress}
                            {selectedCompany.city && selectedCompany.city + ', '}{selectedCompany.country && selectedCompany.country + ' '}
                            {selectedCompany.postalCode}
                          </>) : '-'}
                        </span>
                      ) : (
                        <div className="skeltonSection">
                          <span>
                            <ContentLoader width="100%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </span>
                          <span>
                            <ContentLoader width="90%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </span>
                          <span>
                            <ContentLoader width="70%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="80%" height="15" />
                            </ContentLoader>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td></td>
                <td className="text-right">
                  {!loadCompany && selectedCompany && selectedCompany.id && (
                    <>
                      {hasPermission && props.permission() ? (
                        <span
                          className="btn btn-link  px-3 mb-0 edit-button companySpotlightStep4-5"
                          onClick={handleAddrModalShow}
                        >
                          <i
                            className="fas fa-pencil-alt edit-button me-2"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {selectedCompany && selectedCompany.id && (
          <>
            {!selectedCompany.isApproved ? (
              <div className="additional-action">
                <button className="btn amendment-progress-button-second">
                  Acceptance in progress
                </button>
              </div>
            ) : null}
            {!selectedCompany.isAddressEditApproved ? (
              <div className="additional-action">
                <button className="btn amendment-progress-button">
                  Amendment in progress
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
      <AddressEdit
        addrModalShow={addrModalShow}
        addrModalClose={addrModalClose}
      />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
