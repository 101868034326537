import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  CURRENCY,
  CURRENCY_CRYPTO_NAME,
  CURRENCY_NAME,
  GOLD_QUANTITY_LIST,
  I_POP_OVER,
  PRODUCT_GOLD_TYPE,
  PRODUCT_GOLD_TYPE_NAME_INITIAL,
  SELECT_BOX_THEME,
  SUBSCRIPTION_MODULE_TYPE,
  SUPPORTED_PAYMENT_METHOD,
  SUPPORTED_PAYMENT_METHOD_NAME,
  privacy,
  terms,
  termsOfAdvisoryPlan,
} from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import Currency from "../../components/currency";
import {
  saveManualDetails,
  updateStripePayableAmountAction,
  createStripeTokenAction,
  extraFeeCalcAction,
  setBankTranferBackTrigger,
  currencyConversionCalcHighAction,
  setCurrencyWatch,
  payWithCryptoAction,
  forceCartAmountPayableAction,
  setTripleaUrl,
} from "../../actions/stripe";
import InfoModalStatic from "../dashboard/infoModalStatic";
import toast from "react-hot-toast";
import debounce from "debounce";
import PayWithCryptoPopup from "./PayWithCryptoPopup";
import PlanDetailComponent from "./PlanDetailComponent";

export default function BankTransfer() {
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      saveInfo: "true",
      quantity: GOLD_QUANTITY_LIST[0],
    },
  });

  const {
    stripePlans,
    paymentMethodSelection,
    stripeType,
    savedbankInfoDetails,
    isPriceDisable,
    isOverrideProduct,
    stripeSubscription,
    cartAmount,
    forceCartAmount,
    stripeToken,
    triggerBankTransferBack,
  } = useSelector((state) => state.stripe);
  const location = useLocation();
  const pathnames = location?.pathname?.split("/").filter((x) => x);

  const planId = pathnames?.[pathnames.length - 2];

  const { lockGoldPrice } = useSelector((state) => state.user);
  const { selectedCompany } = useSelector((state) => state.company);
  // const [orderModalShow, setOrderModalShow] = useState(false);
  const currencyWatch = watch("currency");
  const saveInfoWatch = watch("saveInfo");
  const quantityWatch = watch("quantity");
  const amountWatch = watch("amount");
  const cryptoAmountWatch = watch("cryptoAmount");

  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  const navigate = useNavigate();

  useEffect(() => {
    register("baseAmountRate", { required: true });
    register("transactionFee", { required: false });
    register("amount", { required: true });
    register("cryptoAmount", { required: false });
  }, []);

  const calcExtraFeeCharges = () => {
    const payload = {
      price: stripePlans?.price,
      quantity: quantityWatch.value,
      paymentMethodSelection: parseInt(paymentMethodSelection),
      goldType: stripePlans?.goldProductType?.value
    };
    dispatch(
      extraFeeCalcAction(
        payload?.paymentMethodSelection,
        parseInt(stripeType),
        payload
      )
    );
  };

  useEffect(() => {
    const checkIfSubscriptionDetailsAvailable = () => {
      if (stripePlans && Object.keys(stripePlans).length === 0) {
        navigate(RoutesPaths.PRODUCTS_LIST_PAGE);
      } else {
        if (!triggerBankTransferBack) {
          if (stripePlans?.price) {
            calcExtraFeeCharges();
          }
        }
      }
    };
    checkIfSubscriptionDetailsAvailable();
  }, [stripePlans]);

  const onSubmit = async (data) => {
    if (data?.amount > 0) {
      let additionalPayload = {
        subscriptionModuleType: parseInt(stripeType),
        paymentMethodSelection: paymentMethodSelection,
        planId:
          (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.BOB
            ? stripePlans?._id
            : stripePlans?.id) || null,
        company: selectedCompany?.id,
        baseRequestCurrency: stripePlans?.currency?.value || null,
      };
      if (
        additionalPayload.subscriptionModuleType ===
        SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
      ) {
        additionalPayload.capital = stripePlans?.capital?.id || null;
        additionalPayload.baseRequestShareType =
          stripePlans?.capital?.shareType || null;
      }
      const payload = { ...data, ...additionalPayload };
      if (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD) {
        handleProceed();
      } else if (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO) {
        await dispatch(saveManualDetails(payload));
        handleCryptoProceed()
      } else {
        await dispatch(saveManualDetails(payload));
        if (pathnames[1] == "payment") {
          navigate(RoutesPaths.PAYMENT_BANK_DETAIL);
        } else if (pathnames[1] == "dashboard") {
          navigate(RoutesPaths.DASHBOARD_BANKDETAIL.replace(":slug", planId));
        } else {
          navigate(RoutesPaths.BANKDETAIL.replace(":slug", planId));
        }
      }
    } else {
      toast.error("Amount must be greater than 0");
    }
  };

  useEffect(() => {
    if (savedbankInfoDetails && Object.keys(savedbankInfoDetails).length > 0) {
      setValue(
        "quantity",
        savedbankInfoDetails.quantity ?? GOLD_QUANTITY_LIST[0]
      );
      setValue("currency", savedbankInfoDetails?.currency);
      setValue("sendingInstruction", savedbankInfoDetails?.sendingInstruction);
      setValue("number", savedbankInfoDetails.number);
      setValue("nickName", savedbankInfoDetails.nickName);
    }
  }, [setValue, savedbankInfoDetails]);

  useEffect(() => {
    calculateCartForceAction();
  }, [cartAmount]);

  const calculateCartForceAction = (isManual = null, fromForce = null) => {
    const payload = {
      baseReqCurrency: stripePlans?.currency?.value,
      from: fromForce ? fromForce : stripePlans?.currency?.value,
      to: currencyWatch?.value,
      price: parseFloat(isManual) >= 0 ? isManual : stripePlans?.price,
      paymentMethodSelection: parseInt(paymentMethodSelection),
      moduleType: parseInt(stripeType),
      quantity: quantityWatch.value,
      isManual: isManual ? true : false,
      goldType: stripePlans?.goldProductType?.value
    };
    if (parseFloat(isManual) >= 0) {
      setValue("cryptoAmount", isManual)
    } else {
      setValue("cryptoAmount", stripePlans?.price)
    }
    dispatch(currencyConversionCalcHighAction(payload));
  };

  useEffect(() => {
    if (
      stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM &&
      [CURRENCY.INR, CURRENCY.CNY].includes(currencyWatch?.value)
    ) {
      setValue("quantity", GOLD_QUANTITY_LIST[0]);
    }
    calculateCartForceAction();
  }, [currencyWatch]);

  useEffect(() => {
    dispatch(setBankTranferBackTrigger(false));
    if (!triggerBankTransferBack) {
      calcExtraFeeCharges();
    }
  }, [quantityWatch]);

  useEffect(() => {
    setValue("baseAmountRate", forceCartAmount?.baseAmountRate);
    setValue("transactionFee", forceCartAmount?.transactionFees);
    setValue("amount", forceCartAmount?.payableAmount);
    if (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PLAN || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PRODUCT || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.BOB)) {
      setValue("cryptoAmount", stripePlans?.price)
    }
  }, [forceCartAmount]);

  // Define a variable to hold the validation rules based on the condition
  let validationRules = {
    required: {
      value: true,
      message:
        paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
          ? "Enter your account number"
          : paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
            ? "Enter you wallet address"
            : "",
    },
  };

  if (
    [
      SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER,
      SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO,
    ].includes(paymentMethodSelection)
  ) {
    validationRules.pattern = {
      value: /^[a-zA-Z0-9]+$/,
      message: "Please enter only alphanumeric value",
    };
  }

  useEffect(() => {
    if (stripeToken) {
      window.open(stripeToken, "_self", "noopener,noreferrer");
    }
  }, [stripeToken]);

  const handleProceed = () => {
    if (amountWatch && stripeSubscription && selectedCompany.id) {
      let payload = {
        companyId: selectedCompany.id,
        planId:
          parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.BOB
            ? stripeSubscription._id
            : stripeSubscription.id,
        subscriptionModuleType: parseInt(stripeType),
        paymentMethodSelection: paymentMethodSelection,
        quantity: quantityWatch,
        currency: currencyWatch,
        baseAmountRate: parseFloat(forceCartAmount?.baseAmountRate),
        baseRequestCurrency: stripeSubscription?.currency?.value || null,
        transactionFee: parseFloat(forceCartAmount?.transactionFees),
        amount: parseFloat(amountWatch),
      };
      if (
        payload.subscriptionModuleType ===
        SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
      ) {
        payload.baseRequestShareType = stripeSubscription?.capital?.shareType;
        payload.capital = stripeSubscription?.capital?.id;
        payload.loadStripeData = {
          currency: stripeSubscription?.currency?.value,
          amount: parseFloat(amountWatch),
        };
      }
      if (payload.planId || Object.keys(payload.loadStripeData).length > 0) {
        dispatch(createStripeTokenAction(payload));
      } else {
        toast.error(
          "Could not able to proceed, please contact administrator."
        );
      }
    } else {
      toast.error("Could not able to proceed, please contact administrator.");
    }
  };

  const handleCryptoProceed = async () => {
    if (amountWatch && stripeType && selectedCompany.id) {
      let payload = {
        company: selectedCompany.id,
        planId: (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.BOB ? stripePlans?._id : stripePlans?.id) || null,
        subscriptionModuleType: parseInt(stripeType),
        paymentMethodSelection: paymentMethodSelection,
        quantity: quantityWatch,
        currency: { value: stripePlans?.currency?.value, label: CURRENCY_NAME?.filter(x => x.value === stripePlans?.currency?.value)[0].label },
        baseAmountRate: parseFloat(cryptoAmountWatch) ? parseFloat(cryptoAmountWatch) * parseFloat(quantityWatch?.value) : parseFloat(amountWatch),
        baseRequestCurrency: stripePlans?.currency?.value || null,
        amount: parseFloat(cryptoAmountWatch) ? parseFloat(cryptoAmountWatch) * parseFloat(quantityWatch?.value) : parseFloat(amountWatch),
      };
      if (payload.subscriptionModuleType === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL) {
        payload.baseRequestShareType = stripePlans?.capital?.shareType;
        payload.capital = stripePlans?.capital?.id;
      }
      var resp = await dispatch(payWithCryptoAction(payload));
      if (resp?.data?.hosted_url) {
        dispatch(setTripleaUrl(resp?.data?.hosted_url, true))
        if (pathnames[1] == "payment") {
          navigate(RoutesPaths.PAYMENT_MAKEPAYMENT);
        } else if (pathnames[1] == "dashboard") {
          navigate(RoutesPaths.DASHBOARD_MAKEPAYMENT.replace(":slug", planId));
        } else {
          navigate(RoutesPaths.MAKEPAYMENT.replace(":slug", planId));
        }
      }
    } else {
      toast.error("Could not able to proceed, please contact administrator.");
    }
  }

  const quantityOption = () => {
    // setValue('quantity', GOLD_QUANTITY_LIST[0])
    if (stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM) {
      if ([CURRENCY.INR, CURRENCY.CNY].includes(currencyWatch?.value)) {
        return GOLD_QUANTITY_LIST.slice(0, 1);
      } else {
        return GOLD_QUANTITY_LIST.slice(0, 5);
      }
    } else {
      return GOLD_QUANTITY_LIST;
    }
  };

  const handleInputChange = (value) => {
    calculateCartForceAction(value ? value : 0, paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? stripePlans?.currency?.value : currencyWatch?.value);
    // const payload = {
    //   price: value,
    //   quantity: quantityWatch.value,
    //   paymentMethodSelection: parseInt(paymentMethodSelection)
    // }
    // dispatch(extraFeePartialPaymentCalcAction(payload?.paymentMethodSelection, parseInt(stripeType), payload))
    // dispatch(updateStripePayableAmountAction(e.target.value))
  };

  const debouncedHandleInputChange = debounce(handleInputChange, 500);

  const handleInputStripeAmount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
    debouncedHandleInputChange(e.target.value.length > 0 ? e.target.value : 0); // Call the debounced function
  };

  useEffect(() => {
    dispatch(setCurrencyWatch(currencyWatch && currencyWatch?.value?.toUpperCase()))
  }, [currencyWatch])


  return (
    <>
      {/* ${getValues("currency") ? getValues("currency").label : ""} */}
      <Header
        title="Payments"
        subtitle="Payments"
        mobileHeader={
          paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
            paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
            ? SUPPORTED_PAYMENT_METHOD_NAME[paymentMethodSelection]
            : paymentMethodSelection ===
              SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
              ? `Crypto transfer`
              : "-"
        }
      />
      <div className="container-fluid py-4 bankPage paddingBottomMD">
        {pathnames[1] == "payment" && (
          <h4 className="header">
            {paymentMethodSelection ===
              SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
              paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
              ? SUPPORTED_PAYMENT_METHOD_NAME[paymentMethodSelection]
              : paymentMethodSelection ===
                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                ? `Crypto transfer`
                : "-"}
          </h4>
        )}
        <div className="outerRow">
          <div className="row">
            <div className="col-md-4">
              <PlanDetailComponent quantityWatch={quantityWatch} />
            </div>

            <div className="col-md-8">
              {/* <PayWithCryptoPopup url={embedLinkUrl} /> */}
              <div className="userDetail">
                {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD) &&
                  <p className="header">
                    {paymentMethodSelection ===
                      SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                      ? "I will be sending the wire from the following bank/institution"
                      : paymentMethodSelection ===
                        SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                        ? `I will be sending tokens from the following institution`
                        : paymentMethodSelection ===
                          SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                          ? `I will be paying by card in the following currency`
                          : "-"}
                  </p>
                }
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD) && */}
                  <div className="innerDetail">
                    <div className="row">
                      {parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS && paymentMethodSelection !== SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ?
                        <div className="col-md-5 mb-3">
                          <div className="inputHolder inputHolderColumn m-0">
                            <label className="label">&nbsp;</label>
                            <div className="form-control bankTransferField">
                              <span className="upperCaseText">
                                {getValues("currency")
                                  ? getValues("currency").value
                                  : ""}
                              </span>

                            </div>
                          </div>
                        </div>
                        :
                        <div className="col-md-5 mb-3">
                          <div className="inputHolder inputHolderColumn m-0">
                            <label className="label">
                              Select{" "}
                              {paymentMethodSelection ===
                                SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
                                paymentMethodSelection ===
                                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                ? "currency"
                                : paymentMethodSelection ===
                                  SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                  ? "token"
                                  : ""}
                            </label>
                            <Controller
                              name="currency"
                              rules={{ required: true }}
                              control={control}
                              // defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  theme={SELECT_BOX_THEME}
                                  className="select-border bankTransferSelectField"
                                  {...field}
                                  options={
                                    paymentMethodSelection ===
                                      SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
                                      paymentMethodSelection ===
                                      SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                      ? CURRENCY_NAME
                                      : paymentMethodSelection ===
                                        SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                        ? CURRENCY_CRYPTO_NAME
                                        : []
                                  }
                                  isClearable={false}
                                  placeholder="Select currency"
                                />
                              )}
                            />
                          </div>

                          {errors.currency?.type === "required" && (
                            <span className="errorMessage ">
                              Select your{" "}
                              {paymentMethodSelection ===
                                SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                ? "currency"
                                : paymentMethodSelection ===
                                  SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                  ? "token"
                                  : ""}
                            </span>
                          )}
                        </div>
                      }

                      {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER) && (
                        <>
                          <div className="col-md-5 mb-3">
                            <div className="inputHolder inputHolderColumn m-0">
                              <label className="label">
                                {paymentMethodSelection ===
                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                  ? "Sending institution"
                                  : paymentMethodSelection ===
                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                    ? "Wallet or exchange provider"
                                    : ""}
                              </label>
                              <input
                                maxLength={50}
                                type="text"
                                className="form-control bankTransferField"
                                {...register("sendingInstruction", {
                                  required: true,
                                })}
                              />
                            </div>
                            {errors.sendingInstruction?.type === "required" && (
                              <span className="errorMessage ">
                                Enter your sending institution
                              </span>
                            )}
                          </div>

                          <div className="col-md-5 mb-4">
                            <div className="inputHolder inputHolderColumn m-0">
                              <label className="label">
                                {paymentMethodSelection ===
                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                  ? "Account number"
                                  : paymentMethodSelection ===
                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                    ? "Wallet address"
                                    : ""}
                              </label>
                              <input
                                maxLength={50}
                                type="text"
                                className="form-control bankTransferField"
                                {...register("number", validationRules)}
                              />
                            </div>

                            {errors && errors.number && (
                              <span className="errorMessage ">
                                {errors?.number?.message}
                              </span>
                            )}
                            {/* {errors.number?.type === "required" && (
                              <span className="errorMessage ">
                                Enter your account/token Number
                              </span>
                            )} */}
                          </div>

                          <div className="col-md-5 mb-3">
                            <div className="inputHolder inputHolderColumn m-0">
                              <label className="label">Account name</label>
                              <input
                                maxLength={40}
                                type="text"
                                className="form-control bankTransferField"
                                {...register("nickName", {
                                  required: true,
                                })}
                              />
                            </div>
                            {errors.nickName?.type === "required" && (
                              <span className="errorMessage ">
                                Enter your account name
                              </span>
                            )}
                          </div>

                          <div className="col-md-6">
                            <div className="inputHolder inputHolderColumn saveInfoColumn">
                              <label className="label">
                                Save{" "}
                                {paymentMethodSelection ===
                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                  ? "bank"
                                  : paymentMethodSelection ===
                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                    ? "wallet"
                                    : ""}{" "}
                                info
                              </label>
                              <div className="SegmentButton">
                                <label
                                  htmlFor="saveInfoYes"
                                  className={`saveInfoYesHOlder ${saveInfoWatch === "true" ? "active" : ""
                                    } cursorPointer m-0`}>
                                  <input
                                    {...register("saveInfo", {
                                      required: true,
                                    })}
                                    hidden
                                    type="radio"
                                    value={true}
                                    id="saveInfoYes"
                                  />
                                  Yes
                                </label>
                                <label
                                  htmlFor="saveInfoNo"
                                  className={`saveInfoNoHOlder ${saveInfoWatch === "false" ? "active" : ""
                                    } cursorPointer m-0`}>
                                  <input
                                    hidden
                                    {...register("saveInfo", {
                                      required: true,
                                    })}
                                    type="radio"
                                    value={false}
                                    id="saveInfoNo"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-11">
                            <p className="notifyText">
                              Note: While some of the fields above are marked
                              optional, we recommend that you enter them if
                              possible. This helps us match your sending
                              notification to the actual sent funds.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* } */}

                  <div className="submitSection innerDetail">
                    <p className="header">
                      I will be sending the following {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? "estimated" : ""} amount
                    </p>

                    <div className="row">
                      {isOverrideProduct && (
                        <div div className="col-md-5 mb-3">
                          <div className="inputHolder inputHolderColumn m-0">
                            <label className="label">
                              Select quantity{" "}
                              {stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.GRAM ? "in " + PRODUCT_GOLD_TYPE_NAME_INITIAL.GRAM :
                                stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM ? "in " + PRODUCT_GOLD_TYPE_NAME_INITIAL.KILOGRAM :
                                  stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.HEDGEKILOGRAM ? "to be hedged" : ""
                              }
                            </label>
                            <Controller
                              name="quantity"
                              rules={{ required: true }}
                              control={control}
                              // defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  theme={SELECT_BOX_THEME}
                                  className="select-border bankTransferSelectField"
                                  {...field}
                                  options={quantityOption()}
                                  isClearable={false}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  placeholder="Select quantity"
                                />
                              )}
                            />
                          </div>
                          {errors.quantity?.type === "required" && (
                            <span className="errorMessage ">
                              Select your quantity
                            </span>
                          )}
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-5 mb-3">
                            <div className="inputHolder inputHolderColumn m-0">
                              <label className="label instructionLabel">
                                {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ||
                                  (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL)) ||
                                  stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                  ? `Amount ${stripePlans?.goldProductType?.value ===
                                    PRODUCT_GOLD_TYPE.HEDGEKILOGRAM
                                    ? "to be hedged"
                                    : ""
                                  }`
                                  : "Sending amount"}
                                <img
                                  src="assets/img/instructionIcon.svg"
                                  alt=""
                                  className="instructionIcon"
                                  onClick={() =>
                                    handleClickInfo(
                                      paymentMethodSelection ===
                                        SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                        ? I_POP_OVER.BANK_TRANSFER_SENDING_AMOUNT
                                        : paymentMethodSelection ===
                                          SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                          ? I_POP_OVER.CRYPTO_TRANSFER_SENDING_AMOUNT
                                          : paymentMethodSelection ===
                                            SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                            ? I_POP_OVER.STRIPE_AMOUNT
                                            : null
                                    )
                                  }
                                />
                              </label>

                              {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ||
                                paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL) ||
                                stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER) ? (
                                <div className="amountHolder">
                                  {(parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS) ? (
                                    paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ?
                                      <input
                                        maxLength={20}
                                        type="text"
                                        className="form-control bankTransferField bankTransferFieldAmount"
                                        placeholder="Required"
                                        // onInput={(e) => {
                                        //   debounce
                                        //   // dispatch(updateStripePayableAmountAction(e.target.value = e.target.value.replace(/[^0-9.]/g, "")))
                                        // }}
                                        onInput={handleInputStripeAmount}
                                        {...register("cryptoAmount", {
                                          required: true,
                                        })}
                                      />
                                      :
                                      <input
                                        maxLength={20}
                                        type="text"
                                        className="form-control bankTransferField bankTransferFieldAmount"
                                        placeholder="Required"
                                        // onInput={(e) => {
                                        //   debounce
                                        //   // dispatch(updateStripePayableAmountAction(e.target.value = e.target.value.replace(/[^0-9.]/g, "")))
                                        // }}
                                        onInput={handleInputStripeAmount}
                                        {...register("baseAmountRate", {
                                          required: true,
                                        })}
                                      />
                                  ) : (
                                    <div className="disabled form-control bankTransferField bankTransferFieldAmount">
                                      {stripePlans?.goldProductType?.value ===
                                        PRODUCT_GOLD_TYPE.HEDGEKILOGRAM ? (
                                        <CurrencyFormat
                                          currency={getValues("currency")}
                                          value={
                                            getValues("baseAmountRate")
                                              ? parseFloat(
                                                getValues("baseAmountRate")
                                              ) * 100
                                              : "0"
                                          }
                                          decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                        />
                                      ) : (
                                        <CurrencyFormat
                                          currency={getValues("currency")}
                                          value={
                                            getValues("baseAmountRate")
                                              ? parseFloat(
                                                getValues("baseAmountRate")
                                              )
                                              : "0"
                                          }
                                          decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <p className="upperCaseText">
                                    {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? stripePlans?.currency?.value :
                                      getValues("currency")
                                        ? getValues("currency").value
                                        : ""
                                    }
                                  </p>
                                </div>
                              ) : (
                                <div className="amountHolder">
                                  {isOverrideProduct || isPriceDisable ? (
                                    <>
                                      <div className="disabled form-control bankTransferField bankTransferFieldAmount">
                                        <CurrencyFormat
                                          currency={getValues("currency")}
                                          value={
                                            getValues("amount")
                                              ? parseFloat(getValues("amount"))
                                              : "0"
                                          }
                                          decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <input
                                      maxLength={20}
                                      type="text"
                                      className="form-control bankTransferField bankTransferFieldAmount"
                                      placeholder="Required"
                                      onInput={(e) => {
                                        dispatch(
                                          updateStripePayableAmountAction(
                                            (e.target.value =
                                              e.target.value.replace(
                                                /[^0-9.]/g,
                                                ""
                                              ))
                                          )
                                        );
                                      }}
                                      {...register("amount", {
                                        required: true,
                                      })}
                                    />
                                  )}
                                  <p className="upperCaseText">
                                    {getValues("currency")
                                      ? getValues("currency").value
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </div>
                            {errors.baseAmountRate?.type === "required" && (
                              <span className="errorMessage ">
                                Enter amount
                              </span>
                            )}
                            {errors.amount?.type === "required" && (
                              <span className="errorMessage ">
                                Enter sending amount
                              </span>
                            )}
                            {parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS && cryptoAmountWatch < 100 &&
                              <span className="errorMessage ">
                                Minimum value must be at least 100
                              </span>
                            }
                          </div>

                          {/* paid up capital Extra new fields to show */}
                          {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ||
                            paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL) ||
                            stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER) && (
                              <>
                                {stripePlans?.goldProductType?.value ===
                                  PRODUCT_GOLD_TYPE.HEDGEKILOGRAM && (
                                    <div className="col-md-5 mb-3">
                                      <div className="inputHolder inputHolderColumn m-0">
                                        <label className="label instructionLabel">
                                          Cost of hedge
                                          <img
                                            src="assets/img/instructionIcon.svg"
                                            alt=""
                                            className="instructionIcon"
                                            onClick={() =>
                                              handleClickInfo(
                                                paymentMethodSelection ===
                                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                                  ? I_POP_OVER.BANK_TRANSFER_SENDING_AMOUNT
                                                  : paymentMethodSelection ===
                                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                                    ? I_POP_OVER.CRYPTO_TRANSFER_SENDING_AMOUNT
                                                    : paymentMethodSelection ===
                                                      SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                                      ? I_POP_OVER.STRIPE_AMOUNT
                                                      : null
                                              )
                                            }
                                          />
                                        </label>

                                        <div className="amountHolder">
                                          <div className="disabled form-control bankTransferField bankTransferFieldAmount">
                                            <CurrencyFormat
                                              currency={getValues("currency")}
                                              value={
                                                getValues("baseAmountRate")
                                                  ? parseFloat(
                                                    getValues("baseAmountRate")
                                                  )
                                                  : "0"
                                              }
                                              decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                            />
                                          </div>
                                          <p className="upperCaseText">
                                            {getValues("currency")
                                              ? getValues("currency").value
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD || (stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER)) &&
                                  <div className="col-md-5 mb-3">
                                    <div className="inputHolder inputHolderColumn m-0">
                                      <label className="label instructionLabel">
                                        {stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ? 'Storage fee' : `Transaction fee ${cartAmount?.charges}%`}
                                        <img
                                          src="assets/img/instructionIcon.svg"
                                          alt=""
                                          className="instructionIcon"
                                          onClick={() =>
                                            handleClickInfo(
                                              stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ? I_POP_OVER.STRIPE_STORAGE_FEES :
                                                paymentMethodSelection ===
                                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                                  ? I_POP_OVER.BANK_TRANSFER_SENDING_AMOUNT
                                                  : paymentMethodSelection ===
                                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                                    ? I_POP_OVER.CRYPTO_TRANSFER_SENDING_AMOUNT
                                                    : paymentMethodSelection ===
                                                      SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                                      ? I_POP_OVER.STRIPE_TRANSACTION_FEES
                                                      : null
                                            )
                                          }
                                        />
                                      </label>
                                      <div className="amountHolder">
                                        <div className="disabled form-control bankTransferField bankTransferFieldAmount">
                                          <CurrencyFormat
                                            currency={getValues("currency")}
                                            value={parseFloat(
                                              getValues("transactionFee")
                                            )}
                                            decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                          />
                                        </div>
                                        <p className="upperCaseText">
                                          {getValues("currency")
                                            ? getValues("currency").value
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ||
                                  paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ||
                                  stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM) && (
                                    <div className="col-md-5 mb-3">
                                      <div className="inputHolder inputHolderColumn m-0">
                                        <label className="label instructionLabel">
                                          Sending amount
                                          <img
                                            src="assets/img/instructionIcon.svg"
                                            alt=""
                                            className="instructionIcon"
                                            onClick={() =>
                                              handleClickInfo(
                                                paymentMethodSelection ===
                                                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                                  ? I_POP_OVER.BANK_TRANSFER_SENDING_AMOUNT
                                                  : paymentMethodSelection ===
                                                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                                    ? I_POP_OVER.CRYPTO_TRANSFER_SENDING_AMOUNT
                                                    : paymentMethodSelection ===
                                                      SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                                      ? I_POP_OVER.STRIPE_SENDING_AMOUNT
                                                      : null
                                              )
                                            }
                                          />
                                        </label>
                                        <div className="amountHolder">
                                          <div className="disabled form-control bankTransferField bankTransferFieldAmount">
                                            <CurrencyFormat
                                              currency={getValues("currency")}
                                              value={parseFloat(
                                                getValues("amount")
                                              )}
                                              decimal={paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? 8 : 2}
                                            />
                                          </div>
                                          <p className="upperCaseText">
                                            {getValues("currency")
                                              ? getValues("currency").value
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}

                          {/* paid up capital Extra new fields to show */}

                          <div className="col-md-12">
                            <div className="submitBtns submitBtnsColumn">
                              <button
                                className="backButton backButtonMD"
                                onClick={() => {
                                  if (pathnames[1] == "payment") {
                                    navigate(RoutesPaths.PAYMENT);
                                  } else if ((pathnames[1] == "dashboard")) {
                                    navigate(RoutesPaths.DASHBOARD_PRODUCT_PAGE.replace(":slug", planId));
                                  } else {
                                    navigate(RoutesPaths.PRODUCT_PAGE.replace(":slug", planId));
                                  }
                                }}>
                                {/* <button className="backButton backButtonMD" onClick={() => navigate(-1, { state: { data: 'bankTransfer' } })}> */}
                                Back
                              </button>
                              <button
                                disabled={amountWatch <= 0 || parseInt(stripeType) === SUBSCRIPTION_MODULE_TYPE.FUNDS && cryptoAmountWatch < 100 ? true : false}
                                type="submit"
                                className="upgradeBtn upgradeBtnMD">
                                {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                  ? SUPPORTED_PAYMENT_METHOD_NAME[
                                  paymentMethodSelection
                                  ]
                                  : "Get transfer instructions"}
                              </button>
                            </div>
                          </div>

                          {paymentMethodSelection ===
                            SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD && (
                              <div className="col-md-12 paywithcard disclaimer mt-4">
                                <p>
                                  By clicking "pay with card" you agree to the{" "}
                                  <a
                                    href={terms}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    terms and conditions
                                  </a>
                                  .
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
      {/* <PayWithCryptoPopup
        embedShowPopup={embedShowPopup}
        modalClose={() => { setEmbedShowPopup(false); setEmbedLinkUrl(null) }}
        url={embedLinkUrl} /> */}
    </>
  );
}
