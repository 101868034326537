import { GET_USER_DASHBOARD, SET_USER_DASHBOARD_BULLION_RECORD, USER_DASHBOARD_LOADING_SKELETON, SET_USER_DASHBOARD_BULLION_RECORD_LOADING, USER_DASHBOARD_TOUR, SET_USER_DASHBOARD_METAL_ALLOCATION, SET_USER_DASHBOARD_METAL_ALLOCATION_LOADING, SET_USER_DASHBOARD_PROFIT_LOSS, SET_USER_DASHBOARD_PROFIT_LOSS_LOADING } from "../actions/types";
const initialState = {
  userDashboard: {},
  isUserDashboardLoading: true,
  bullionRecord: [],
  isUserDashboardBullionBalanceLoading: true,
  metalAllocation: [],
  isUserDashboardmetalAllocationLoading: true,
  profitAndLoss: {},
  isUserDashboardProfitLossLoading: true,
  showTour: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_DASHBOARD:

      return {
        ...state,
        userDashboard: payload,
        isUserDashboardLoading: false,
      };

    case USER_DASHBOARD_LOADING_SKELETON:
      return {
        ...state,
        isUserDashboardLoading: payload,
      };

    case USER_DASHBOARD_TOUR:
      return {
        ...state,
        showTour: payload,
      };

    case SET_USER_DASHBOARD_BULLION_RECORD:
      return {
        ...state,
        bullionRecord: payload,
        isUserDashboardBullionBalanceLoading: false
      };

    case SET_USER_DASHBOARD_BULLION_RECORD_LOADING:
      return {
        ...state,
        isUserDashboardBullionBalanceLoading: payload
      };

    case SET_USER_DASHBOARD_METAL_ALLOCATION:
      return {
        ...state,
        metalAllocation: payload,
        isUserDashboardmetalAllocationLoading: false
      };

    case SET_USER_DASHBOARD_METAL_ALLOCATION_LOADING:
      return {
        ...state,
        isUserDashboardmetalAllocationLoading: payload
      };

    case SET_USER_DASHBOARD_PROFIT_LOSS:
      return {
        ...state,
        profitAndLoss: payload,
        isUserDashboardProfitLossLoading: false
      };

    case SET_USER_DASHBOARD_PROFIT_LOSS_LOADING:
      return {
        ...state,
        isUserDashboardProfitLossLoading: payload
      };

    default:
      return state;
  }
}
