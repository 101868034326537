import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "../../actions/company";
import {
  cancelStripeSubscriptionAction,
  getStripeSubscriptionAction,
  paymentModalAction,
  setStripePlansAction,
} from "../../actions/stripe";
import DeletePayment from "./DeletePayment";
import { useLocation } from "react-router";
import ContentLoader from "react-content-loader";
import { SUBSCRIPTION_MODULE_TYPE } from "../../constants";

export default function PaymentPlan() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { subscriptions, isSubscribed, loadSubscription, stripePlans } = useSelector((state) => state.stripe);
  const { selectedCompany } = useSelector((state) => state.company);
  const [cancelModal, SetCancelModal] = useState(false);

  const handleShow = () => {
    dispatch(paymentModalAction(true, SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION));
  };

  useEffect(() => {
    const { id } = selectedCompany;
    if (id) {
      dispatch(getStripeSubscriptionAction(id))
      dispatch(paymentModalAction(false, SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION))
    };
  }, [dispatch, selectedCompany]);

  const removeModalCloseConfirm = async () => {
    const { id } = selectedCompany;
    if (id) {
      const returnDispath = await dispatch(cancelStripeSubscriptionAction(id));
      if (returnDispath) {
        SetCancelModal(false);
        dispatch(getStripeSubscriptionAction(id));
        dispatch(getAllCompany());
      }
    }
  };

  useEffect(() => {
    if (location.state && selectedCompany?.subscriptionPlan?.isActive) {
      dispatch(paymentModalAction(true, SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION));
    }
  }, [location]);

  return (
    <>
      <div className="card-header pb-0 p-3">
        <div className="row">
          <div className="col-8  align-items-center">
            <h6 className="mb-0 text-sm table-heading">
              Current subscription plan
            </h6>
            {/* {!isSubscribed && (
              <p className="current-subscription">
                Your are currently being downgraded to a different plan. You will
                recieve email and Whatsapp notification when the downgrade
                completes
              </p>
            )} */}
          </div>
          <div className="col-4 text-end">
            {isSubscribed ? (
              subscriptions.length > 0 &&
              !subscriptions[0].cancel_at_period_end ? (
                selectedCompany?.subscriptionCancelRequest ? (
                  <button className="btn btn-sm upgrade-btn">
                    Your subscription cancellation is in progress
                  </button>
                ) : (
                  <button
                    className="btn btn-sm upgrade-btn"
                    onClick={() => SetCancelModal(true)}
                  >
                    Cancel
                  </button>
                )
              ) : (
                <button className="btn btn-sm upgrade-btn">
                  Your subscription cancellation has been approved.
                </button>
              )
            ) : (
              isSubscribed !== null &&
              !isSubscribed && stripePlans.length > 0 &&
              selectedCompany?.subscriptionPlan?.isActive && (
                <button className="btn btn-sm upgrade-btn" onClick={handleShow}>
                  Upgrade
                </button>
              )
            )}
          </div>
        </div>
      </div>
      <div className="card-body p-3 pt-3">
        {!loadSubscription ? (
        !isSubscribed ? (
          <h5 className="font-weight-bolder payment-title">Free</h5>
        ) : (
          <h5 className="font-weight-bolder payment-title">
            Exempt plan paid{" "}
            {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions[0].interval === "month"
              ? "monthly"
              : subscriptions &&
                subscriptions.length > 0 &&
                subscriptions[0].interval === "year"
              ? "annually"
              : "daily"}
            {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions[0].current_period_end
              ? ", valid until " +
                moment
                  .unix(subscriptions[0].current_period_end)
                  .format("DD/MM/YYYY")
              : ""}
          </h5>
        )) : (
          <div>
            {[80,60].map((item, index) => (
              <ContentLoader key={index} width={item+'%'} height={15}>
                <rect x="0" y="0" rx="5" ry="5" width={item+'%'} height="15" />
              </ContentLoader>
            ))}
          </div>
        )}
      </div>
      <DeletePayment
        removeModalShow={cancelModal}
        removeModalClose={() => SetCancelModal(false)}
        removeModalCloseConfirm={removeModalCloseConfirm}
        actionData={subscriptions}
      />
    </>
  );
}
