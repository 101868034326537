import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate  } from "react-router-dom";


export default function InviteAccept() {
  let navigate = useNavigate ();
  const params = useParams();
  const { token } = params
  useEffect(() => {
    if(token){
      localStorage.setItem('INVITE_PROMOTER_TOKEN', token)
      navigate('/')
    }
  }, [navigate, token])

  return (
    <>
    </>
  );
}
