import React, { useEffect, useState } from "react";
import ProfileDetail from "./ProfileDetail";
import "./index.css";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { getBusinessProfile } from "../../actions/bookOfBusiness";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { hideTourRideAction, updateUserExperienceAction } from "../../actions/company";
import { BrowserView } from "react-device-detect";
import { USER_GUIDE_MODAL, guide } from "../../constants";
import { setGuideOpenAction } from "../../actions/auth";

export default function SuggestedProfiles() {
  const { control, getValues } = useForm();
  const dispatch = useDispatch();
  const { businessProfiles, count, bookOfBusinessResponseService, loadBob } = useSelector(
    (state) => state.bookOfBusiness
  );

  const { selectedCompany, isDefaultCompany, showBobTourRide, showBobTourRideEntry, bobSteps, bobStepsEntry, notificationBanner, bobNotification } = useSelector((state) => state.company);
  const showDefaultJoyRideDashboard = (Object.keys(notificationBanner).length === 0 && Object.keys(bobNotification).length === 0) ? true : false;
  const [profileTab, setProfileTab] = useState(1);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [status, setStatus] = useState(profileTab);
  const [run, SetRun] = useState(false)
  // const companyName = useWatch({ control, name: "companyName" })?.companyName;
  const limit = useWatch({ control, name: "limit" });
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    if (showBobTourRide) {
      setTimeout(() => { SetRun(showBobTourRide) }, 400)
    } else {
      SetRun(false)
    }
  }, [showBobTourRide])

  useEffect(() => {
    if (!loadBob && !showBobTourRide && showBobTourRideEntry && businessProfiles && businessProfiles?.data?.length > 0) {
      setTimeout(() => { SetRun(showBobTourRideEntry) }, 400)
    } else {
      SetRun(false)
    }
  }, [loadBob, showBobTourRide, showBobTourRideEntry])

  const getCommonPofileFunc = () => {
    const { id } = selectedCompany;
    if (id) dispatch(getBusinessProfile(id, page, limit, status));
  }

  useEffect(() => {
    getCommonPofileFunc()
  }, [selectedCompany, page, limit, status, dispatch])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  const handleSlider = (index) => {
    setProfileTab(index);
    setStatus(index);
  };
  useEffect(() => {
    // Reset activePage to 0 whenever the "limit" field changes
    setActivePage(0);
    setPage(1);
  }, [limit, status]);


  const handleClose = () => {
    dispatch(hideTourRideAction())
    handleCloseNotification()
  }


  // useEffect(() => {
  //   if (!isUserDashboardLoading) {
  //     SetRun(true)
  //   }
  // }, [isUserDashboardLoading])

  // const [steps, SetSteps] = useState([
  //   {
  //     content: 'You can easily add your networks to the book of business as a reason for other users to contact YOU for the purpose of introducing this profile (or other profiles). Any profile you add will not be shown to anyone or published anywhere.',
  //     placement: 'right',
  //     styles: { options: { width: 600, }, },
  //     target: '.bobSpotlightStep1',
  //     title: 'Add Profile',
  //   },
  //   {
  //     content: 'Find out how to profit from adding new profiles to your book of business.',
  //     placement: 'bottom',
  //     styles: { options: { width: 600, }, },
  //     target: '.bobSpotlightStep2',
  //     title: 'Notification',
  //   },
  //   // {
  //   //   content: 'Add holding company” icon, and the message “You can easily create a holdco at no cost, for your precious metals business, so that you can appoint directors, shareholders and other stakeholders.',
  //   //   placement: 'bottom',
  //   //   styles: {options: {width: 600,},},
  //   //   target: '.bobSpotlightStep2',
  //   // },
  // ])


  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    setCurrentStep(data.index); 
    if (finishedStatuses.includes(status)) {
      handleCloseNotification()
    }
  };

  const handleCloseNotification = () => {
    const { id } = selectedCompany;
    if (id) {
      let payload = { showBobTourRide: false }
      if (showBobTourRide === false && showBobTourRideEntry === true) {
        payload = { showBobTourRideEntry: false }
      }
      dispatch(updateUserExperienceAction(id, payload))
    }
    SetRun(false)
  }

  return (
    <>
      <div className="bobride"></div>
      {/* <BrowserView > */}
        <Joyride
          callback={handleJoyrideCallback}
          locale={{
            skip: "X",
          }}
          continuous
          hideCloseButton
          disableScrolling={true}
          run={run}
          scrollToFirstStep
          // showProgress
          showSkipButton
          steps={(showDefaultJoyRideDashboard && !showBobTourRide && showBobTourRideEntry && businessProfiles?.data?.length > 0) ? bobStepsEntry : bobSteps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          hideBackButton={currentStep === 1}
        />
      {/* </BrowserView> */}
      <Header title="Dashboard" subtitle="Book of Business" />
      {((showDefaultJoyRideDashboard && showBobTourRide) || (showDefaultJoyRideDashboard && !showBobTourRide && showBobTourRideEntry && businessProfiles?.data?.length > 0)) && (
        <div className="container-fluid pt-4 pb-0 container-top landingPage bobSpotlightStep2" style={{paddingTop: 0}}>
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-md-12 mb-md-0 mb-0">
              <div className="banner success">
                <p>Find out how to profit from adding new profiles to your book of business</p>
                <button
                  type="button"
                  onClick={() => dispatch(setGuideOpenAction(true, USER_GUIDE_MODAL.SUGGESTED))}
                  className={false ? "btn agree-button" : "bannerBtn"}
                >
                  Learn more
                </button>

                <button
                  type="button"
                  className="close-banner"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <img src="assets/img/close-banner.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid mt-3 paddingBottomMD">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-4">
            <h3 className="suggestHeader">Book of business</h3>
          </div>
          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 mb-2">
            <div className="profileCounts">
              <div className="totalprofile">
                <h4>
                  <span>Total profiles {isDefaultCompany ? `suggested` : ""}:</span>{(isDefaultCompany ? businessProfiles?.profileCount?.suggested : businessProfiles?.profileCount?.total) || 0}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-4">
            <div className="profileHolder suggestedProfile mb-4">
              <ProfileDetail
                activePage={activePage}
                count={count}
                handlePageClick={handlePageClick}
                businessProfiles={businessProfiles}
                bookOfBusinessResponseService={bookOfBusinessResponseService}
                getValues={getValues}
                control={control}
                limit={limit}
                setStatus={setProfileTab}
                profileTab={profileTab}
                setPage={setPage}
                setActivePage={setActivePage}
                handleSlider={handleSlider}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
