import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addFilesAction } from "../../actions/files";
import DropZoneTemplate from "./DropZoneTemplate";

export default function Upload() {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const uploadFiles = (event) => {
    const file = event.target.files[0];
    uploadDispatchFile(file)
    event.target.value = null
  }

  const uploadDispatchFile = (file) => {
    var maxfileUploadSize = 1024 * 1024 * 50;
    if (file.size < maxfileUploadSize) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("company", selectedCompany.id);
      dispatch(addFilesAction(formData))
    } else {
      toast.error("Maximum file upload size is 50mb.")
    }
  }

  return (
    <>
      <div className="upload-here-img">
        <div className="row">
          <div className="col-12">
            <div className="upload-btn justify-content-between mb-3">
              <h5 className="payment-text mb-6">Upload new files</h5>
              <label className="btn btn-sm view-button mb-0 me-0 mt-0 load-btn ">
                Upload
                <input onChange={uploadFiles} type="file" hidden />
              </label>
            </div>
          </div>
          <div className="col-12">
            <DropZoneTemplate onUpload={uploadDispatchFile} />
          </div>
        </div>
      </div>
    </>
  );
}