import React, { useEffect, useState } from "react";
// import Footer from "../../components/footer";
import Header from "../../components/header/header";
import PromoteModal from "../../components/promotemodal/promoteModal";
import InviteTabel from "./inviteTabel";
import ShareTable from "../../components/sharetable/shareTable";
import { HOLDERS, HOLDERS_PAGE_TYPE, I_POP_OVER } from "../../constants";
import OfficerTable from "../../components/officer/OfficerTable";
import { useDispatch, useSelector } from "react-redux";
import IndividualInviteTable from "../InvitesIndividual/IndividualInviteTable";
import InfoModalStatic from "../dashboard/infoModalStatic";
import IndividualInviteTableGlobal from "../InvitesIndividualGlobal/IndividualInviteTableGlobal";
import ContratulationsPopup from "./ContratulationsPopup";
import { clearPromoterInviteModal, holdNextInviteDataAction, holdNextInviteTemporaryAction, showCurrentInvite } from "../../actions/promoters";

export default function Invites() {
  const dispatch = useDispatch();
  const { isDefaultCompany, selectedCompany} = useSelector((state) => state.company);
  const { holdNextInviteTemp, holdNextInviteTempData, mergerPromoter } = useSelector((state) => state.promoters);
  const [modalShow, setModalShow] = useState(false);
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const [congratulationShowPopup, SetCongratulationShowPopup] = useState(false);
  const [triggerHandleMessageInternal, SetTriggerHandleMessageInternal] = useState(false);

  const { shareHolder } = useSelector((state) => state.shareHolder);
  const { officers } = useSelector((state) => state.officers);



  const modalClose = () => {
    setModalShow(false);
  };
  const handleShow = () => setModalShow(true);

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type)
  }

  useEffect(() => {
    if (holdNextInviteTemp) {
      SetCongratulationShowPopup(true)
    }

  }, [holdNextInviteTemp])

  const congratulationClosePopupAction = () => {
    dispatch(showCurrentInvite(0));
    SetCongratulationShowPopup(false)
    dispatch(holdNextInviteTemporaryAction(false));
    // dispatch(clearPromoterInviteModal())
    SetTriggerHandleMessageInternal(false)
  }

  const goToChatPopupAction = () => {
    let getList = mergerPromoter.find(x => x.email === holdNextInviteTempData?.user?.email)
    if(getList){
      dispatch(holdNextInviteDataAction({type: HOLDERS.promoters, email: holdNextInviteTempData?.user?.email}));
    }else{
      getList = officers.find(x => x.email === holdNextInviteTempData?.user?.email)
      if(getList){
        dispatch(holdNextInviteDataAction({type: HOLDERS.directors, email: holdNextInviteTempData?.user?.email}));
      }else{
        getList = shareHolder.find(x => x.email === holdNextInviteTempData?.user?.email)
        if(getList){
          dispatch(holdNextInviteDataAction({type: HOLDERS.shareHolders, email: holdNextInviteTempData?.user?.email}));
        }
      }
    }
    SetTriggerHandleMessageInternal(true)
  }

  return (
    <>
      <Header title="Invites" subtitle="Invites" />
      <div className="container-fluid inner-container-fluid py-4 invite-page paddingBottomMD">
        <div className="row">
          <div className="col-md-4 mb-lg-0 mb-4">
            {isDefaultCompany ? (
              <>
                <h5 className="main-page-title">Refer your friends and earn commissions {" "}<img
                  src="assets/img/info-icon.svg"
                  onClick={() => handleClickInfo(I_POP_OVER.REFERAL_FRIEND)}
                  alt=""
                  className="m-0 pb-1 cursorPointer"
                /></h5>
                <p className="edit-description-adv">Here you can see the status of your referrals. Earn commissions by referring InProved to your friends.</p>
              </>
            ) : (
              <>
                <h5 className="main-page-title">Invitations</h5>
                <p className="edit-description-adv">
                  Here you can invite your clients, professional network, family
                  members and friends as promoters, directors or shareholders of{" "}
                  <strong>{selectedCompany?.companyName}</strong>.

                  {/* Here you can invite your professional network to be a promoter of
                  your holding company. A promoter is a person who does the
                  preliminary work related to starting a holding company, including
                  its creation, scope, formalisation, solicitation, progression and
                  promotion. This person usually advises the shareholders and
                  beneficial owners of the holding company on its activities and
                  makes sure all are clear on the intent and objectives of the
                  holding company. */}
                </p>
                {/* <p className="edit-description-adv">
                  Any person, including agents, consultants, accountants, legal
                  professionals, engineers or bankers, can be a promoters{" "}
                </p>
                <p className="edit-description-adv">Here you can also see other people who have been invited as potential shareholders and directors of your company</p> */}
              </>
            )}
          </div>
          <div className="col-md-8 mb-lg-0 mb-4">
            {isDefaultCompany ? (
              <>
                <div className="mb-5">
                  <IndividualInviteTable type={HOLDERS_PAGE_TYPE.INVITES} />
                </div>
                <div className="mb-5">
                  <IndividualInviteTableGlobal type={HOLDERS_PAGE_TYPE.PENDING_GLOBAL_INVITES} />
                </div>
              </>
            ) : (
              <>
                <div className="mb-4">
                  <InviteTabel type={HOLDERS_PAGE_TYPE.INVITES} triggerHandleMessageInternal={triggerHandleMessageInternal} closeCongratulationsPopup={congratulationClosePopupAction} />
                </div>
                <div className="mb-4 share-officer">
                  <ShareTable type={HOLDERS_PAGE_TYPE.INVITES} triggerHandleMessageInternal={triggerHandleMessageInternal} closeCongratulationsPopup={congratulationClosePopupAction} />
                </div>
                <div className="mb-4 share-officer">
                  <OfficerTable type={HOLDERS_PAGE_TYPE.INVITES} triggerHandleMessageInternal={triggerHandleMessageInternal} closeCongratulationsPopup={congratulationClosePopupAction} />
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <Footer
              linkObj={{ title: "Manage Your Account", link: "/profile" }}
            />
          </div>
        </div> */}
      </div>
      <PromoteModal modalShow={modalShow} modalClose={modalClose} />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
      <ContratulationsPopup actionData={holdNextInviteTempData} show={congratulationShowPopup} close={congratulationClosePopupAction} goToChatPopup={goToChatPopupAction} />
    </>
  );
}
