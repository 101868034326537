import { participantsMap } from "../conversations-objects";
import { UPDATE_PARTICIPANTS } from "../actions/types";

const initialState = {
  ParticipantsType:  {}
}

const reduxifyParticipant = (participant) => ({
  sid: participant.sid,
  attributes: participant.attributes,
  identity: participant.identity,
  type: participant.type,
  lastReadMessageIndex: participant.lastReadMessageIndex,
  isAppointed: participant.isAppointed
});

const reducer = (state = initialState, action ) => {
  switch (action.type) {
    case UPDATE_PARTICIPANTS:
      const { participants, sid } = action.payload;
      const stateRedux = action.stateValue;
      const allAppointedList = stateRedux?.company?.allAppointedList

      for (const participant of participants) {
        var isAppointed = false
        // // check if appointed director
        const filterRecord = allAppointedList.filter(x=>x === participant.identity)
        if(filterRecord.length > 0){
          isAppointed = true
        }
        participant.isAppointed = isAppointed
        participantsMap.set(participant.sid, participant);
      }

      return Object.assign({}, state, {
        [sid]: participants.map(reduxifyParticipant),
      });
    default:
      return state;
  }
};

export default reducer;
