import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import VerifyBusiness from "./VerifyBusiness";
import { useDispatch, useSelector } from "react-redux";
import {
  addInfoQuestions,
  toggleCompanyVerificationFailed,
} from "../../actions/myinfo";
import { useEffect } from "react";
import { I_POP_OVER, backendUrl } from "../../constants";
import { getObjectiveAction } from "../../actions/objective";
import InfoModalStatic from "../../pages/dashboard/infoModalStatic";
export default function AddObjective({
  businessData,
  showObjective,
  objectiveClose,
  activityModalShow,
  setShowObjective,
}) {
  const dispatch = useDispatch();
  const { pendingCompanySelected, companyVerificationStepFailed } = useSelector(
    (state) => state.company
  );

  const { objectives } = useSelector((state) => state.objective);
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const [expandCard, setExpandCard] = useState(false);

  const [selectCard, setSelectCard] = useState([]);
  const [showVerify, seShowVerify] = useState(false);

  useEffect(() => {
    if (showObjective) {
      dispatch(getObjectiveAction());
    }
  }, [showObjective]);

  useEffect(() => {
    if (
      companyVerificationStepFailed &&
      companyVerificationStepFailed?.step === 4
    ) {
      handleClickInfo(I_POP_OVER.CUSTOM_API_MESSAGE);
      seShowVerify(true);
    }
  }, [dispatch, companyVerificationStepFailed]);

  useEffect(() => {
    const answer = pendingCompanySelected?.objective;
    const savedObjectiveList =
      answer && answer.length > 0 ? answer.map((x) => x._id || x) : [];
    setSelectCard(savedObjectiveList);
  }, [pendingCompanySelected]);

  const stepBack = () => {
    activityModalShow(true);
    objectiveClose();
  };

  const saveQuestions = async () => {
    if (pendingCompanySelected?.id) {
      if (selectCard.length > 0) {
        let questionsPayload = {
          company: pendingCompanySelected.id,
          objective: selectCard,
        };
        if (questionsPayload.objective.length === 1) {
          objectiveClose();
          setSelectCard([]);
        }
        await dispatch(addInfoQuestions(questionsPayload));
      } else {
        objectiveClose();
        setSelectCard([]);
      }
    }
  };

  const handleSelectCard = (value) => {
    if (selectCard.includes(value.id)) {
      setSelectCard([]);
    } else {
      setSelectCard([value.id]);
    }
    // if (selectCard.includes(value.type)) {
    //   // If the card is already selected, remove it from the array
    //   setSelectCard(selectCard.filter((type) => type !== value.type));
    // } else {
    //   // If the card is not selected, add it to the array
    //   setSelectCard([...selectCard, value.type]);
    // }
  };

  const onSubmit = async () => {
    if (selectCard.length === 1) {
      seShowVerify(true);
    }
    await saveQuestions();
  };

  const handleClose = async () => {
    await saveQuestions();
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <Modal
        show={showObjective}
        onHide={handleClose}
        className="objectiveModalNew ps-0 custom-modal">
        <Modal.Body>
          <div className="row right-side-row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 objectiveUpper">
              <div className="modalHeaderIcons">
                <img
                  className="cursorPointer"
                  onClick={stepBack}
                  src="assets/img/backStep.svg"
                  alt=""
                />
                <button
                  type="button"
                  className="modalClose"
                  onClick={handleClose}
                  aria-label="Close">
                  <img src="assets/img/closeIcon.svg" alt="" />
                </button>
              </div>
              <h5 className="edit-title">
                Step 3: Indicate your preferred objective for{" "}
                {businessData.companyName}
              </h5>
              <p className="edit-description">
                We will be sending you notifications directly to confirm and
                verify your business activities.
              </p>
            </div>
            <div
              className={`${
                !expandCard ? "objectiveRow pt-3" : ""
              }col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12  customScrollBar`}>
              <div className="row">
                {expandCard ? (
                  <div className="col-12 objectiveExpanded">
                    <div
                      className="expandedObjectiveCard"
                      style={{
                        backgroundImage: `url(${
                          backendUrl + expandCard?.image?.filename
                        })`,
                      }}>
                      <div className="expandedObjectHold">
                        <div className="expandedObjectHoldContent">
                          <button
                            type="button"
                            onClick={() => setExpandCard(false)}
                            className="objectiveClose">
                            <img src="assets/img/objectiveClose.svg" alt="" />
                          </button>
                          <p className="expandedObjectiveName">
                            {expandCard.title}
                          </p>
                          <div
                            className="objectiveDesc"
                            dangerouslySetInnerHTML={{
                              __html: expandCard.description,
                            }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {objectives?.map((item, index) => (
                      <div key={index} className="col-sm-4">
                        <div
                          onClick={() => handleSelectCard(item)}
                          className={`${
                            selectCard.includes(item.id)
                              ? "objectiveBorder"
                              : ""
                          } objectiveCard`}
                          style={{
                            backgroundImage: selectCard.includes(item.id)
                              ? "linear-gradient(0deg, var(--primary-color),var(--primary-color)), linear-gradient(0deg, var(--primary-color), var(--primary-color))"
                              : `url(${backendUrl}/${item.image.filename})`,
                          }}>
                          <div className="objectiveCardHold">
                            {selectCard.includes(item.id) && (
                              <img
                                className="selectedIcon"
                                src="assets/img/selectedCard.svg"
                                alt=""
                              />
                            )}
                            <p className="objectiveName">{item.title}</p>
                            {!selectCard.includes(item.id) && (
                              <div className="objectiveFooter">
                                {/* <img src="assets/img/infoImage.svg" alt="" /> */}
                                <i
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setExpandCard(item);
                                  }}
                                  className="fa-solid fa-circle-info"></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 pt-3 objectiveBottom">
              {/* <p className="import-note pt-0">
                Important note:{" "}
                <span className="will-need-text">
                  We will need to contact you directly to confirm the amendment.
                </span>
              </p> */}
              <div className="modal-footer modal-bottom-action">
                <ContactUs handleClose={handleClose} />
                <button
                  onClick={onSubmit}
                  type="button"
                  className="btn agree-button"
                  disabled={selectCard?.length === 0}>
                  Save and Proceed{" "}
                  <img src="assets/img/nextStep.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <VerifyBusiness
        showVerify={showVerify}
        verifyClose={() => seShowVerify(false)}
        setShowObjective={setShowObjective}
      />
      <InfoModalStatic
        infoModalStaticCustomMessage={
          companyVerificationStepFailed?.data || null
        }
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => [
          setInfoModalStaticShow(false),
          dispatch(toggleCompanyVerificationFailed(null)),
        ]}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
