import React from "react";
import { Modal } from "react-bootstrap";
import { CONTACT_US_TRIGER, I_POP_OVER, USER_GUIDE_MODAL } from "../../constants";
import { setGuideOpenAction } from "../../actions/auth";
import { useDispatch } from "react-redux";
import ContactUs from "../../components/ContactUs/ContactUs";
import SpeakToAdmin from "../../components/SpeakToAdmin/SpeakToAdmin";
export default function InfoModalStatic({
  infoModalStaticShow,
  infoModalStaticClose,
  infoModalStaicShowType,
  infoModalStaticCustomMessage
}) {

  const dispatch = useDispatch();

  const openUseGuide = (guideName) => {
    dispatch(setGuideOpenAction(true, guideName));
    infoModalStaticClose();
  };

  const handleClose = () => {

  }

  return (
    <>
      <Modal
        show={infoModalStaticShow}
        onHide={infoModalStaticClose}
        className="custom-modal info-modal ps-0"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {infoModalStaicShowType === I_POP_OVER.BUSINESS_NAME ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Holding company name</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Every holding company needs a name to differentiate itself from
                other holding company.
              </p>
              <span className="edit-description">
                Tips of choosing a holding company name
              </span>
              <ul className="edit-description m-0">
                <li>No obscene names</li>
                <li>
                  Use caution of words that may require additional licenses, for
                  e.g crypto
                </li>
                <li>
                  Choose something that is as close as possible to your website
                  domain
                </li>
              </ul>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.REGISTERED_ADDRESS ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Registered address</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Every holding company created has a default registered address.
                You can use this default address and put it on contracts or
                agreements immediately.
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.WEBSITE ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Company website</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Although not compulsory, including a website url would
                legitimise the holding company greatly.
              </p>
              <span className="edit-description">
                Tips of choosing a website url
              </span>
              <ul className="edit-description m-0">
                <li>use a .com extension</li>
                <li>keep it short and simple</li>
                <li>avoid numbers or hyphens</li>
                <li>be unique and memorable</li>
                <li>use keywords</li>
                <li>do your research</li>
                <li>use a domain name generator</li>
              </ul>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.PRIMARY_ACTIVITY ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Primary activity</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Each holding company must have at least one business activity. The primary activity is your key business activity. This activity is what your company is created to do primarily
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.SECONDARY_ACTIVITY ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Secondary activity</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                The secondary activity is not compulsory. If added, this
                activity refers to the supporting activity that supports the
                primary activity.
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.SHAREHOLDERS ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Shareholders</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                A shareholder is a person who owns a portion of the holding company when he/she contributes, financially or non-financially, towards the holding company. By paying for the shares, or being issued with shares, the shareholder is a partial owner of the holding company.
              </p>
              <p className="edit-description">
                Here you can see individual persons being added as potential shareholders to a holding company. People here who accept the invitation will show up in the message (chat) section. You can then communicate directly with to explain and convince them to invest in the holding company's business.
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.DIRECTORS ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Directors</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                A director is a person who is responsible for managing the affairs of the holding company and setting the business's strategic direction. A director has the legal duty to advance the interest of the holding company, act honestly and practise good faith in exercising the given powers. A director is also required to ensure there are resources within the holding company for the preparation of financial statements, corporate filings and other disclosures and record keeping (i.e. minutes of meetings).
              </p>
              <p className="edit-description">
                Here you can see individual persons being added as potential directors to a holding company. People here who accept the invitation will show up in the message (chat) section. You can then communicate directly with them to explain and convince them to contribute to the holding company's business</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.PROMOTERS ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Shareholders</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">A promoter is a person who does the preliminary work related to starting a holding company, including its creation, scope, formalisation, solicitation, progression and promotion. This person usually advises the shareholders and beneficial owners of the holding company on its activities and makes sure all are clear on the intent and objectives of the holding company.</p>
              <p className="edit-description">Any person, including agents, consultants, accountants, legal professionals, engineers or bankers, can be a promoter.</p>
              <p className="edit-description">They are usually ideal persons to become directors and c-level executives of that company.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.LICENSES ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Licenses</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Coming soon
                {/* Some businesses need licenses and permits to operate in their chosen activity. We look forward to simplify the process of helping you acquire the licenses your business needs. */}
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.VERIFY_BUSINESS ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Verify business</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Choose myinfo Business if the business is already registered in Singapore. Choose Manual Verification if  the business is registered outside Singapore or has not been registered.
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BUSINESS_DETAIL ? (
          <>
            <div className="modal-header px-0 pt-0">
              <h5 className="edit-title">Business details</h5>
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Details shown here are extracted from myinfo business or provided to you after your business is formalised.
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_ACTION_INDIVIDUAL ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">“Make public" will allow InProved to show your email address and full name to other users who are interested in having you introduce this profile (or any profile you add) to them.</p>
              <p className="edit-description">Note that no attempt will be made by anyone to connect or engage with this profile as the profile details will be hidden for all users.</p>
              <p className="edit-description">You may then receive email notifications and other app notifications should you be invited by other users to make introduction to this profile (or any profile you add).</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_ACTION_COMPANY ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">“Connect" will allow InProved to introduce this profile to you.</p>
              <p className="edit-description">Note that while we cannot ensure the profile will reciprocate, you are at least assured that there is a real attempt to connect with this profile for the purpose of introducing this profile to you.</p>
              <p className="edit-description">You may then receive email notifications and other app notifications should this profile accept the connection request.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_SUGGESTED_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                You can suggest this profile for any of the below functions
              </p>
              <ol>
                <li>Potential customer - someone whom you believe can be customer for a particular business</li>
                <li>Potential investor - someone whom you believe can be an investor for a particular business</li>
                <li>Potential director - someone whom you believe can be a director for a particular business</li>
                <li>Potential employee - someone whom you believe can be an employee for a particular business</li>
                <li>Potential vendor or supplier - someone whom you believe can supply products or services to a particular business</li>
              </ol>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                Potential purpose refers to the potential role that you are considering this profile for. You can contact us to tell us what role you are hoping to appoint. Some of the roles include:
              </p>
              <ol>
                <li>Potential customer - someone whom you believe can be customer for your business</li>
                <li>Potential investor - someone whom you believe can be an investor for your business</li>
                <li>Potential director - someone whom you believe can be a director for your business</li>
                <li>Potential employee - someone whom you believe can be an employee for your business</li>
                <li>Potential vendor or supplier - someone whom you believe can supply products or services to your business</li>
              </ol>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.SHAREHOLDERS_DIRECTOR_STATUS ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">This shows the status of the user pertaining to this particular business account</p>
              <p className="edit-description">Pending - user has been invited pending acceptance</p>
              <p className="edit-description">Invite accepted - user has accepted invite and available to chat</p>
              <p className="edit-description">Removal in progress - user is being uninvited from this business account and not from the platform in general</p>
              <p className="edit-description">Official - user is an official stakeholder of the business</p>

              {/* <p className="edit-description">Acceptance in progress - This appointment is pending admin approval.</p>
              <p className="edit-description">Amendment in progress - This amendment is pending admin approval.</p>
              <p className="edit-description">Removal in progress - This removal is pending admin approval.</p>
              <p className="edit-description">Enquire about all its status via the admin chat.</p> */}
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.REFERAL_FRIEND ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">Here you can invite your friends to join InProved.com. We will show you when any of your invited friends have accepted the invite and notify you when they have procured any item from InProved. You will then receive a commission payout starting from 10bp of the price of the item. <b onClick={() => openUseGuide(USER_GUIDE_MODAL.INVITE_REFERRAL)} className="cursor-pointer">Learn more.</b></p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.DEFAULT_SUGGESTED_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">You can suggest your friends and professional networks to other companies created on InProved.com. <b onClick={() => openUseGuide(USER_GUIDE_MODAL.SUGGESTED)} className="cursor-pointer">Learn more.</b>
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.COMPANY_SUGGESTED_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                This list stores potential stakeholders who may help your holding company or fund, such as potential investors. You may add existing ones you know to send them an invite or contact us to find new ones
                {/* You can see profiles that InProved and other users have suggested for your business. <b onClick={() => openUseGuide(USER_GUIDE_MODAL.COMPANY_SUGGESTED)} className="cursor-pointer">Learn more.</b> */}
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.COMPANY_WARM_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                This list stores potential stakeholders who may be interested to help your holding company or fund, such as potential investors. You may add existing ones you know to send them an invite or contact us to find new ones.
                {/* Profiles who have shown an interest in the affairs of this company will be shown here. */}
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.COMPANY_MEETING_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                This list stores potential stakeholders who are willing to speak and learn more about your holding company or fund, such as potential investors. You may add existing ones you know to send them an invite or contact us to find new ones.
                {/* Profiles who have shown an interest to speak with directors of the company will be shown here. */}
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.COMPANY_SUGGESTED_ADD_PROFILE ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">You can also add a profile who you like to meet or engage with. InProved will try to help you with the “first-hand shake”. While we cannot ensure this profile will reciprocate, you are at least assured we will try. If so, you may then receive email notifications from this profile or other users who may help you meet more of such profiles. <b onClick={() => openUseGuide(USER_GUIDE_MODAL.COMPANY_SUGGESTED)} className="cursor-pointer">Learn more.</b>
              </p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.BANK_TRANSFER_SENDING_AMOUNT ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">This is the nett amount which InProved should receive for the quoted product or service. Please ensure to select “sender pay all fees” when executing your bank transfer.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.CRYPTO_TRANSFER_SENDING_AMOUNT ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">This is the nett amount which InProved should receive for the quoted product or service. Do ensure you account for any gas fees when executing your crypto transfer.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.STRIPE_AMOUNT ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">This is the quoted price of the product or service.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.STRIPE_TRANSACTION_FEES ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">We charge an additional 5% on top of the quoted price to cover for payment gateway transaction costs.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.STRIPE_STORAGE_FEES ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">We automatically store your product with Brinks Singapore who charges the quoted fees on a yearly basis. You will receive the proof of deposit and storage details after your product is deposited in the Brinks Vault in Singapore.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.STRIPE_SENDING_AMOUNT ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">This is the nett amount which you are sending after all fees and charges.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.PAYMENT_PAID_UP_CAPITAL_LIMIT ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">We can only accept paid-up capital equal to or more than $100 in USD or SGD. Top up the paid-up capital to a minimum of $100 in SGD or USD to pay for the paid-up capital.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.SHAREHOLDERS_DIRECTOR_INVITEE_STATUS ? (
          <>
            <div className="modal-header px-0 pt-0">

              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">Invitation status will change when the invitee accepts the invite.</p>
            </div>
          </>
        ) : infoModalStaicShowType === I_POP_OVER.CUSTOM_API_MESSAGE ? (
          <>
            <div className="modal-header px-0 pt-0">
              <button
                type="button"
                className="btn-close close-button text-dark"
                onClick={infoModalStaticClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="edit-description">
                {infoModalStaticCustomMessage}
              </p>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="modal-footer modal-bottom-action align-text-left">
          {infoModalStaicShowType === I_POP_OVER.BUSINESS_DETAIL ? (
            <SpeakToAdmin designType="popup" />
          ) : (
            infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE && (
              <ContactUs type={CONTACT_US_TRIGER.BOB_POTENTIAL_PURPOSE} handleClose={handleClose} />
            )
          )}
          <button
            onClick={infoModalStaticClose}
            type="button"
            className="btn agree-button"
          >
            Got it
          </button>
        </div>
      </Modal>
    </>
  );
}
