import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { backendUrl, CUSTOM_SELECT_STYLES, HOLDERS, SELECT_BOX_THEME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { addChannel, createMembersAPI, getTwilioToken } from "../../actions/chat";
import { RoutesPaths } from "../../Router/constant";
import { useNavigate } from "react-router";
import {
  Client
} from "@twilio/conversations";
import { toast } from "react-hot-toast";

export default function StartMessageModel(props) {
  const { messageModal, addModalClose, listing, type, currentSelection } = props;
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.user);
  const {
    control,
    handleSubmit,
    formState: { },
  } = useForm();
  const navigate = useNavigate();

  const [chatUserList, setChatUserList] = useState([]);
  const [isShowChatBtn, setisShowChatBtn] = useState(false);
  const [chatGroupName, setChatGroupName] = useState("");
  const [chatGroupMessage, setChatGroupMessage] = useState("");
  const [conversationLoader, setConversationLoader] = useState(true);

  useEffect(() => {
    setConversationLoader(false);
  }, [])

  const userList = (listing && listing.length > 0 && listing.filter((x) => x.email !== user.email && x.email !== "")) || [];
  const onSubmit = async () => {
    try {
      let { id } = selectedCompany;
      if (id) {
        let list = [];
        let members = [];

        chatUserList.forEach((element) => {
          var elementList = (type === HOLDERS.shareHolders || type === HOLDERS.directors) ? element.id : (type === HOLDERS.promoters) ? element._id : null
          if (elementList) {
            list.push(elementList);
          }
          members.push(element.email);
        });

        if (members.length > 0) {
          setConversationLoader(true);
          var obj = {
            company: id,
            members: members,
            type: type,
            message: chatGroupMessage,
          };

          var channelRes = await addChannel(obj);

          if (channelRes.isExisted) {
            // already existed channel

            const twilioTokenResponse = await getTwilioToken("");
            if (twilioTokenResponse.status) {

              const client = await new Client(twilioTokenResponse.data);

              const conversation = await client.getConversationBySid(
                channelRes.data.channelId
              );
              await conversation.sendMessage(chatGroupMessage);
            }

            setConversationLoader(false);
            navigate(RoutesPaths.MESSAGE_DETAIL + '?room=' + channelRes.data.channelId, {
              state: { id: channelRes.data.channelId, data: channelRes.data },
            });
          } else {
            // Create a new channel that does not exist
            let data = {
              attributes: { companyId: id, companyName: selectedCompany.companyName },
              chatGroupMessage: chatGroupMessage,
              uniqueName: "",
              friendlyName: "",
              members: members
            }

            if (members.length > 0) {
              data = { ...data, uniqueName: chatGroupName, friendlyName: chatGroupName, }
            }

            const memberResp = await createMembersAPI(data);
            if (memberResp.status) {
              // getting list of all messages since this is an existing channel
              let obj1 = {
                company: id,
                members: members,
                users: list,
                channel: chatGroupName,
                masterUser: user.id,
                type: type,
                channelId: memberResp.data || "",
              };
              var channelRes = await addChannel(obj1);
              if (channelRes.status && channelRes.data.channelId) {
                setConversationLoader(false);
                navigate(RoutesPaths.MESSAGE_DETAIL + '?room=' + channelRes.data.channelId);
              } else {
                setConversationLoader(false);
              }
            }
          }
        }
      }
    } catch (e) {
      if (e.message === 'Conflict') {
        toast.error("Group name already in use, please try with different group name.")
      }
      setConversationLoader(false);
      throw new Error("Unable to create channel, please reload this page");
    }
  };

  const removeChatUser = (index) => {
    const newList = [...chatUserList];
    newList.splice(index, 1);
    setChatUserList(newList);
    setChatGroupName("");
  };

  useEffect(() => {
    if (chatUserList.length === 1 && chatGroupMessage !== "") {
      setisShowChatBtn(true);
    } else if (
      chatUserList.length > 1 &&
      chatGroupName !== "" &&
      chatGroupMessage !== ""
    ) {
      setisShowChatBtn(true);
    } else {
      setisShowChatBtn(false);
    }
  }, [chatUserList, chatGroupName, chatGroupMessage]);

  useEffect(() => {
    if (currentSelection) {
      setChatUserList([currentSelection])
    }
  }, [messageModal, currentSelection]);

  const firstGroupMessage = () => {
    let message =
      type === HOLDERS.shareHolders
        ? "Hello, shareholders! how are you?"
        : type === HOLDERS.directors
          ? "Hello, directors! how are you?"
          : "Hello, promoter! how are you?";

    return message;
  };

  const onChange = (obj) => {
    const exists = chatUserList.find((user) => user.email === obj.email);
    if (exists) return;
    setChatUserList([...chatUserList, obj]);
  };


  const addModalCloseFunc = () => {
    setChatUserList([]);
    setChatGroupMessage("");
    addModalClose();
  }

  return (
    <>
      <Modal
        show={messageModal}
        onHide={addModalClose}
        className="custom-modal add-new-member"
        id="addModalMessage"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalCloseFunc}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              {/* onSubmit={handleSubmit(onSubmit)} */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="edit-title" style={{marginBottom: '5px'}}>
                      Start message with{" "}
                      {type === HOLDERS.shareHolders
                        ? "shareholders"
                        : type === HOLDERS.directors
                          ? "directors"
                          : "promoter"}
                    </h5>
                  </div>
                  <div className="col-lg-12">
                    <h6 className="edit-subtitle">Account Name: {selectedCompany.companyName} {selectedCompany?.isDefaultCompany ? "" : " Business Dashboard"}</h6>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group message-section">
                      <Controller
                        render={() => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            options={userList}
                            getOptionLabel={(option) => type === HOLDERS.promoters ? option.first_name + " " + option.last_name : option.name}
                            getOptionValue={(option) => option.email}
                            value=""
                            onChange={onChange}
                            placeholder="Invite other shareholders to join the conversation..."
                          />
                        )}
                        name="select"
                        control={control}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <p className="conversation-text mb-3">
                      Have a conversation with:
                    </p>
                    <div className="row conversation-section" id="style-1">
                      {chatUserList &&
                        chatUserList.length > 0 &&
                        chatUserList.map((item, index) => (
                          <div
                            key={index}
                            className="col-lg-12 inner-section"
                          >
                            <div
                              className="profile-img"
                              style={{
                                backgroundImage: `url(${item &&
                                  item.profilePic &&
                                  item.profilePic.filename
                                  ? backendUrl + item.profilePic.filename
                                  : "assets/img/Frame1.svg"
                                  })`,
                              }}
                            ></div>
                            <div className="profile-name">
                              {(type === HOLDERS.shareHolders || type === HOLDERS.directors) && item.name}

                              {type === HOLDERS.promoters &&
                                item.first_name + " " + item.last_name}

                              <img
                                src="assets/img/Close-circle.svg"
                                alt=""
                                className="cursor-pointer"
                                onClick={() => removeChatUser(index)}
                              />
                            </div>
                            {/* {!item.isEditApproved && !item.isAppoint && (
                              <span>Amendement in progress</span>
                            )}
                            {!item.isDeleteApproved && (
                              <span>Removal in progress</span>
                            )}
                            {!item.isApproved ||
                              (item.isAppoint && item.isDeleteApproved && (
                                <span>Acceptance in progress</span>
                              ))} */}
                          </div>
                        ))}
                    </div>
                  </div>

                  {chatUserList.length > 1 && (
                    <>
                      <div className="col-lg-12 mt-4">
                        <p className="conversation-text">
                          Assign a group name
                          <span className="red">*</span>
                        </p>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            name="group_name"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Assign a group name"
                            value={chatGroupName}
                            onChange={(e) => setChatGroupName(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <textarea
                        maxLength={50}
                        type="text"
                        name="message"
                        className="form-control messageTextarea"
                        onChange={(e) => setChatGroupMessage(e.target.value)}
                        value={chatGroupMessage}
                        placeholder={firstGroupMessage()}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer modal-bottom-action justify-content-center">
                  {isShowChatBtn && (
                    <button type="submit" disabled={conversationLoader} className="btn agree-button">
                      {conversationLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} &nbsp;Start Conversation
                    </button>
                  )}
                  {!isShowChatBtn && (
                    <button
                      type="button"
                      disabled
                      className="btn disable-agree-button"
                    >
                      {conversationLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Start Conversation
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
