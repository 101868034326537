import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addOfficerAction } from "../../actions/officer";
import {
  CONTACT_US_TRIGER,
  country,
  CUSTOM_SELECT_STYLES,
  HOLDERS,
  POSITIONS,
  SELECT_BOX_THEME,
} from "../../constants";
import ContactUs from "../ContactUs/ContactUs";
import Select from "react-select";
import { addPromoterAction } from "../../actions/promoters";
import toast from "react-hot-toast";

export default function AddModal({
  addModalShow,
  addModalClose,
  title,
  pageType,
}) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const [copyLinkLoader, setCopyLinkLoader] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [addModalShow, reset]);

  const onSubmit = async (data) => {
    const { id } = selectedCompany;
    if (id) {
      data.company = id;
      data.countryCode = data.countryCode.dial_code;
      data.position = HOLDERS.directors;
      data.type = HOLDERS.directors;
      data.global = 0;
      dispatch(addOfficerAction(data, pageType));
      addModalClose();
      reset();
    }
  };

  const copyLink = async () => {
    setCopyLinkLoader(true);
    const { id } = selectedCompany;
    var resp = await dispatch(
      addPromoterAction(id, {
        global: 1,
        type: HOLDERS.directors,
        company: id,
        position: HOLDERS.directors,
      })
    );
    if (resp.status) {
      setCopyLinkLoader(false);
      const shortUrl = resp?.data?.short_url;
      if (shortUrl) {
        navigator.clipboard
          .writeText(shortUrl)
          .then(() => {
            toast.success("URL copied to clipboard");
          })
          .catch(() => {
            console.error("Failed to copy text: ");
          });
      }
    }
    addModalClose();
    reset();
  };
  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="custom-modal edit-information-width ps-0"
        id="addModalMessage">
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalClose}></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">Add a new director</h5>
              <p className="edit-description">
                A director is a person who is responsible for managing the
                affairs of the holding company and setting the business's
                strategic direction. A director has the legal duty to advance
                the interest of the holding company, act honestly and practise
                good faith in exercising the given powers. A director is also
                required to ensure there are resources within the holding
                company for the preparation of financial statements, corporate
                filings and other disclosures and record keeping (i.e. minutes
                of meetings).
              </p>
              <p className="edit-description">
                Every holding company needs a minimum of 1 director. You can
                invite as many of your professional networks as you like to
                discuss appointing them as directors. People who accept the
                invitation will show up in the message (chat) section. You can
                then communicate directly with them to explain and convince them
                to contribute to the holding company’s business.{" "}
              </p>
              <p className="edit-description">
                You can edit the information or remove other directors, as many
                times as you like. Changes made are only formalised later when
                all the procedures of approving the changes are carried out. We
                will contact you to advise you about the steps to formalise the
                changes
              </p>
              <ContactUs
                addEditValue="add"
                theme="spanContactUs"
                type={CONTACT_US_TRIGER.DIRECTOR}
                handleClose={addModalClose}
              />

              {/* <p className="edit-description">
                <a
                  href="https://www.kimbocorp.com/blog/roles-and-duties-as-a-director-of-a-virtual-business"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about directors
                </a>
              </p> */}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <p className="input-text heading">Full name</p>
                      <input
                        maxLength={50}
                        type="text"
                        className="form-control"
                        placeholder="Full name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span className="errorMessage">Name is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Email</p>
                      <input
                        maxLength={50}
                        type="text"
                        className="form-control"
                        placeholder="Email address"
                        {...register("share_email", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                        })}
                      />
                      {errors.share_email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.share_email?.type === "pattern" && (
                        <span className="errorMessage">Email is Invalid</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Country code</p>

                      <Controller
                        name="countryCode"
                        rules={{ required: true }}
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={country}
                            isClearable={true}
                            getOptionLabel={(option) =>
                              `${option.dial_code} (${option.name})`
                            }
                            getOptionValue={(option) => option.dial_code}
                            placeholder="Select"
                          />
                        )}
                      />
                      {errors.countryCode?.type === "required" && (
                        <span className="errorMessage">Enter country code</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Mobile number</p>
                      <input
                        maxLength={50}
                        type="number"
                        className="form-control"
                        placeholder="Mobile number"
                        {...register("phoneNumber", {
                          required: true,
                        })}
                      />
                      {errors.phoneNumber?.type === "required" && (
                        <span className="errorMessage">
                          Mobile number is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will firstly send an invitation to this person to invite
                    him/her to this business dashboard. You will be informed
                    once the invitation is accepted by this person. You can then
                    message him/her directly to discuss appointing him/her as a
                    director.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  {/* <ContactUs
                    addEditValue="add"
                    type={CONTACT_US_TRIGER.DIRECTOR}
                    handleClose={addModalClose}
                  /> */}
                  <button
                    type="button"
                    disabled={copyLinkLoader}
                    onClick={copyLink}
                    className={`btn btn-sm  mb-4 mt-3 contactus-modal-button `}>
                    {copyLinkLoader && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Copy invite link
                  </button>
                  <button type="submit" className="btn agree-button">
                    Invite
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
