import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addShareAction } from "../../actions/shareHolder";
import {
  CONTACT_US_TRIGER,
  country,
  CUSTOM_SELECT_STYLES,
  HOLDERS,
  SELECT_BOX_THEME,
} from "../../constants";
import Select from "react-select";
import ContactUs from "../ContactUs/ContactUs";
import { addPromoterAction } from "../../actions/promoters";
import toast from "react-hot-toast";

export default function AddModal({
  addModalShow,
  addModalClose,
  title,
  pageType,
}) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const [copyLinkLoader, setCopyLinkLoader] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [addModalShow, reset]);

  const onSubmit = async (data) => {
    const { id } = selectedCompany;
    if (id) {
      data.company = id;
      data.countryCode = data.countryCode.dial_code;
      data.position = HOLDERS.shareHolders;
      data.type = HOLDERS.shareHolders;
      data.global = 0;
      await dispatch(addShareAction(data, pageType));
      addModalClose();
      reset();
    }
  };

  const copyLink = async () => {
    setCopyLinkLoader(true);
    const { id } = selectedCompany;
    var resp = await dispatch(
      addPromoterAction(id, {
        global: 1,
        type: HOLDERS.shareHolders,
        company: id,
        position: HOLDERS.shareHolders,
      })
    );
    if (resp.status) {
      setCopyLinkLoader(false);
      const shortUrl = resp?.data?.short_url;
      if (shortUrl) {
        navigator.clipboard
          .writeText(shortUrl)
          .then(() => {
            toast.success("URL copied to clipboard");
          })
          .catch(() => {
            console.error("Failed to copy text: ");
          });
      }
    }
    addModalClose();
    reset();
  };

  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="custom-modal edit-information-width ps-0"
        id="addModalMessage">
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalClose}></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">Add a new shareholder</h5>
              <p className="edit-description">
                A shareholder is a person who owns a portion of the virtual
                business when he/she contributes, financially or
                non-financially, towards the virtual business. By paying for the
                shares, or being issued with shares, the shareholder is a
                partial owner of the virtual business.
              </p>
              <p className="edit-description">
                Here you can only add individual persons as shareholders to a
                virtual business. You can add as many as 20 shareholders to a
                virtual business.
              </p>
              <ContactUs
                addEditValue="add"
                type={CONTACT_US_TRIGER.SHAREHOLDER}
                theme="spanContactUs"
                handleClose={addModalClose}
              />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <p className="input-text heading">Full name</p>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Full name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span className="errorMessage">Name is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Email</p>
                      <input
                        maxLength={50}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Email address"
                        {...register("share_email", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                        })}
                      />
                      {errors.share_email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.share_email?.type === "pattern" && (
                        <span className="errorMessage">Email is Invalid</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5  mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Country code</p>

                      <Controller
                        name="countryCode"
                        rules={{ required: true }}
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={country}
                            isClearable={true}
                            getOptionLabel={(option) =>
                              `${option.dial_code} (${option.name})`
                            }
                            getOptionValue={(option) => option.dial_code}
                            placeholder="Select"
                          />
                        )}
                      />
                      {errors.countryCode?.type === "required" && (
                        <span className="errorMessage">Enter country code</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Mobile number</p>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        placeholder="Mobile number"
                        {...register("phoneNumber", {
                          required: true,
                        })}
                      />
                      {errors.phoneNumber?.type === "required" && (
                        <span className="errorMessage">
                          Mobile number is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will firstly send an invitation to this person to invite
                    him/her to this business dashboard. You will be informed
                    once the invitation is accepted by this person. You can then
                    message him/her directly to discuss appointing him/her as a
                    shareholder.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  {/* <ContactUs addEditValue="add" type={CONTACT_US_TRIGER.SHAREHOLDER} handleClose={addModalClose} /> */}
                  <button
                    type="button"
                    disabled={copyLinkLoader}
                    onClick={copyLink}
                    className={`btn btn-sm  mb-4 mt-3 contactus-modal-button `}>
                    {copyLinkLoader && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Copy invite link
                  </button>
                  <button type="submit" className="btn agree-button">
                    Invite
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
