import React, { useState } from "react";
import { I_POP_OVER } from "../../constants";
import InfoModalStatic from "../../pages/dashboard/infoModalStatic";
import moment from "moment";
import ContentLoader from "react-content-loader";

export default function LicensesTable() {
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };
  const [record, setRecord] = useState(2);

  const onLoad = () => {
    setRecord(record + 2);
  };

  const licenses = [
    // {
    //   licensesNumber: "xxxxxx",
    //   licensesName: "Type License Name Here...",
    //   issueDate: "03/06/2023",
    //   expiryDate: "10/04/2026",
    //   issuanceAgency: "Kimbosample Agency",
    // },
    // {
    //   licensesNumber: "xxxxxx",
    //   licensesName: "Type License Name Here...",
    //   issueDate: "03/06/2023",
    //   expiryDate: "10/04/2026",
    //   issuanceAgency: "Sister Agency Company",
    // },
    // {
    //   licensesNumber: "xxxxxx",
    //   licensesName: "Type License Name Here...",
    //   issueDate: "03/06/2023",
    //   expiryDate: "10/04/2026",
    //   issuanceAgency: "Kimbosample Agency",
    // },
  ];
  return (
    <>
      <div className="card-header pb-0">
        <div className="row">
          <div className="col-lg-7 col-6">
            <h3 className="business-font capital-head high-cap px-2">
              Licenses{" "}
              <img
                src="assets/img/info-icon.svg"
                onClick={() => handleClickInfo(I_POP_OVER.LICENSES)}
                alt=""
                className="m-0 pb-1 cursorPointer"
              />
            </h3>
          </div>

          <div className="col-lg-5 col-6 text-end">
            <div className="dropdown float-lg-end">
              <button
                className="btn add-button add-button-shareholder m-0 disabled"
              // onClick={handleShow}
              >
                {/* Add */}
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body px-0 pb-2 pt-2">
        {licenses && licenses.length > 0 ? (
          <div className="table-responsive">
            <table className="table align-items-center mb-0 table-sharholder">
              <thead>
                <tr className="tableHeadings">
                  <th>License Number</th>
                  <th className="">License Name</th>
                  <th className="action-heading">Issue Date</th>
                  <th className="status-heading">Expiry Date</th>
                  <th className="status-heading">Issuance Agency</th>
                </tr>
              </thead>
              <tbody className="text-row-color">
                {licenses &&
                  licenses.slice(0, record).map((list, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex">
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 shareHolderName">
                              {list.licensesNumber}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <span className="licensesData">
                          {list.licensesName}
                        </span>
                      </td>
                      <td className="align-middle ">
                        <span className="licensesData">
                          {moment(list.issueDate).format("DD/MM/YYYY")}
                        </span>
                      </td>

                      <td className="align-middle">
                        <span className="licensesData">
                          {moment(list.expiryDate).format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td className="align-middle">
                        <span className="licensesData">
                          {list.issuanceAgency}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* {licenses.length > record && (
                <button
                  onClick={onLoad}
                  className="btn btn-sm  view-button mb-4 mt-3"
                >
                  View all
                </button>
              )} */}
          </div>
        ) : (
          <div className="no-record d-flex pt-2 pb-2">
            <div className="d-flex flex-column justify-content-center ">
              <h6 className="mb-0 text-sm table-heading ">No license found</h6>
              {/* <div>
                <ContentLoader width="100%" height={15}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                </ContentLoader>
              </div> */}
            </div>
          </div>
        )}
      </div>

      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
