import axionIns from "../axios";

const getSettings = async () => {
  return axionIns.get("settings").then((response) => { return response.data });
};

const exportOject = {
  getSettings
};

export default exportOject;
