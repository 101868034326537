import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlanProductAction,
  selectedPlanProductAction,
} from "../../actions/subscriptionPlan";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import Currency from "../../components/currency/currency";
import { backendUrl, PRODUCT_GOLD_TYPE, SUBSCRIPTION_MODULE_TYPE } from "../../constants";
import { setPaymentMethodSelection } from "../../actions/stripe";
import ContentLoader from "react-content-loader";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Header from "../../components/header/header";
import Breadcrumbs from "../../components/header/breadcrumbs";

export default function Products({
  handlePlanPopupShowTrigger,
  activeSegment,
}) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const navigate = useNavigate();
  const {
    productPlanList,
    loadProductPlanList,
    count,
    selectedPlanProduct,
    selectedPlanProductCurrentPage,
  } = useSelector((state) => state.subscriptionPlanProducts);
  const { control } = useForm();

  const [page, setPage] = useState(
    selectedPlanProductCurrentPage && selectedPlanProductCurrentPage?.page
      ? selectedPlanProductCurrentPage?.page
      : 1
  );
  const [activePage, setActivePage] = useState(
    selectedPlanProductCurrentPage && selectedPlanProductCurrentPage?.page
      ? selectedPlanProductCurrentPage?.page - 1
      : 0
  );
  const limit = useWatch({ control, name: "limit" });

  useEffect(() => {
    callGetActionsOnLoad();
  }, [selectedCompany, page, limit, dispatch]);

  const callGetActionsOnLoad = () => {
    if (selectedCompany && selectedCompany?.id && page) {
      dispatch(
        getPlanProductAction(
          selectedCompany?.id,
          page,
          50,
          selectedPlanProductCurrentPage
        )
      );
      dispatch(setPaymentMethodSelection(null));
    }
  };

  const productPlanSelection = (item) => {
    dispatch(selectedPlanProductAction(item, page, activeSegment));
  };

  const planButtonTrigger = (item) => {
    if (selectedCompany?.isBuyEnabled) {
      if (item?.slug) {
        navigate(RoutesPaths.PRODUCT_PAGE.replace(":slug", item?.slug));
      }
    }
  };

  const shortenDescription = (description) => {
    const plainText = description.replace(/<\/?[^>]+(>|$)/g, "");
    const shortenedDescription =
      plainText.length > 100 ? plainText.substring(0, 70) + "..." : plainText;
    return shortenedDescription;
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  useEffect(() => {
    // Reset activePage to 0 whenever the "limit" field changes
    setActivePage(
      selectedPlanProductCurrentPage && selectedPlanProductCurrentPage?.page
        ? selectedPlanProductCurrentPage?.page - 1
        : 0
    );
    setPage(
      selectedPlanProductCurrentPage && selectedPlanProductCurrentPage?.page
        ? selectedPlanProductCurrentPage?.page
        : 1
    );
  }, [limit, selectedCompany]);

  return (
    <>
      <Header title="Products" subtitle="Products" />

      <div className="container-fluid minHeightVH py-4 paymentPage paddingBottomMD">
        {!loadProductPlanList ? (
          productPlanList && productPlanList.length > 0 ? (
            <div className="row">
              {productPlanList.map((item, index) => (
                (item?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ||
                  item?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PRODUCT) &&
                <div
                  key={index}
                  onClick={() => planButtonTrigger(item)}
                  className="col-6 col-md-4 col-lg-3">
                  <div className="productHolder">
                    <div className="productImageHolder">
                      <div className="d-flex align-items-start justify-content-between productStockKeyHolder">
                        <p className="suggestedText">
                          {selectedCompany?.subscriptionPlan &&
                            Object.keys(selectedCompany?.subscriptionPlan)
                              .length > 0 &&
                            selectedCompany?.subscriptionPlan?._id ===
                            item?.id && (
                              <div className="suggestedText">
                                SUGGESTED{" "}
                                {item?.planType?.value ===
                                  SUBSCRIPTION_MODULE_TYPE.PLAN
                                  ? "PLAN"
                                  : "PRODUCT"}
                              </div>
                            )}
                        </p>
                        {item?.stockKey && item?.stockKey > 0 && item?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && (
                          <p className="stockCount">
                            {item?.stockKey || 0} in stock
                          </p>
                        )}
                      </div>
                      <div
                        className="productImage"
                        style={{
                          backgroundImage: `url(${backendUrl + item?.image?.filename
                            })`,
                        }}></div>

                      <div className="hover-content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.shortDescription ||
                              shortenDescription(item?.targetAudience),
                          }}></p>
                      </div>
                    </div>
                    <div className="productDetailHolder">
                      <p>{item?.name}</p>
                      <h6>
                        <Currency currency={item?.currency?.value} />{" "}
                        <CurrencyFormat
                          currency={item?.currency?.value}
                          value={parseFloat(item?.price)}
                          decimal={2}
                        />
                      </h6>
                      {item?.smallDescription && (
                        <p className="productSlug">
                          *for {item?.smallDescription}
                        </p>
                      )}

                      {(item?.planType?.value ===
                        SUBSCRIPTION_MODULE_TYPE.PLAN ||
                        item?.planType?.value ===
                        SUBSCRIPTION_MODULE_TYPE.PRODUCT) && (
                          <button type="button">
                            {item?.planType?.value ===
                              SUBSCRIPTION_MODULE_TYPE.PLAN
                              ? "Upgrade"
                              : "Buy"}
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex">
              <div className="d-flex flex-column justify-content-center">
                <h6 className="text-sm table-heading productAlign pb-2">
                  No Products
                </h6>
              </div>
            </div>
          )
        ) : (
          <div>
            <div className="row">
              {[1, 2, 3, 4].map((item, index) => (
                <div key={index} className="col-md-4 col-lg-3 col-6">
                  <ContentLoader width={"100%"} height={164}>
                    <rect rx="5" ry="5" width={"100%"} height="164" />
                  </ContentLoader>

                  <div className="row mt-2">
                    {[1, 2, 3].map((item, index) => (
                      <div key={index} className="col-md-7">
                        <ContentLoader width={"100%"} height={20}>
                          <rect rx="5" ry="5" width={"100%"} height={50} />
                        </ContentLoader>
                      </div>
                    ))}
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <ContentLoader width={"100%"} height={50}>
                        <rect rx="5" ry="5" width={"100%"} height={50} />
                      </ContentLoader>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* {!loadProductPlanList &&
          productPlanList &&
          productPlanList.length > 0 && (
            <div className="tableBottom">
              <div className="filterButton">
                <div className="dropdownButton">
                  <Controller
                    name="limit"
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        className="select-border"
                        placeholder="10">
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Form.Select>
                    )}
                  />
                </div>
              </div>
              <div>
                {count > 0 && (
                  <ReactPaginate
                    previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                    nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                    pageCount={count}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination customPagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                )}
              </div>
            </div>
          )} */}
      </div >
    </>
  );
}
