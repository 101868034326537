import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Confirm(props) {
  return (

    <Modal className="custom-modal"
      {...props}>
      <Modal.Header className="p-0 m-0">
        <h5 className="edit-title"> Confirm</h5>
        <button type="button" onClick={() => props.onHide(false)} className="btn-close text-dark close-button"></button>
      </Modal.Header>
      <Modal.Body>Are you sure you wan't to logout?</Modal.Body>
      <Modal.Footer className="pt-3">
        <Button className="m-0 me-2" variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        <Button variant="primary" className="agree-button confirmbtn m-0" onClick={() => props.onHide(true)}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
