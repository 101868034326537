import React, { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Modal } from "react-bootstrap";

export default function Otp({ otpShow, otpClose, handleOtp, resendOtpClick }) {
  const [OTP, setOTP] = useState("");

  const renderButton = (buttonProps) => {
    const time = buttonProps?.remainingTime
    return (
      <><span>Didn’t receive a code? </span><button {...buttonProps}> Resend</button>{time > 0 && <span> (OTP in {time} sec)</span>}</>
    );
  };
  const renderTime = (remainingtime) => {
    return "";
  };
  return (
    <>
      <Modal show={otpShow} onHide={otpClose} className="otp_modal">
        <Modal.Header>
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={otpClose}
          >
          </button>
          <img src="./assets/img/otp-mobile.svg" />
          <h3 className="font-weight-bolder text-info text-gradient">Enter 6-digit code</h3>
          <p>For your safety, please refrain from sharing this code with anyone else.</p>
        </Modal.Header>
        <Modal.Body>
          <OTPInput
            inputClassName="otp_input"
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            separator={<span>-</span>}
          // secure
          />
        </Modal.Body>
        <Modal.Footer>
          <ResendOTP className="resend_otp" renderButton={renderButton} renderTime={renderTime} onResendClick={resendOtpClick} maxTime="30" />
          <button disabled={(OTP.length === 6) ? false : true} onClick={() => handleOtp(OTP)} className="btn agree-button">
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
