import React from "react";
import Header from "../../components/header/header";
import Upload from "./upload";
import UploadFile from "./uploadFile";
import { useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

export default function Files() {
  const { hasPermission, loadCompany } = useSelector((state) => state.company);

  return (
    <>
      <Header title="Files" subtitle="Files" />
      <div className="container-fluid inner-container-fluid py-4 paddingBottomMD filesPage">
        {!loadCompany ? (
          <div className="row">
            <div className={`col-lg-${hasPermission ? 5 : 0} col-xl-${hasPermission ? 3 : 0} col-md-${hasPermission ? 5 : 0} mb-4 img-div`}>
              {hasPermission && (
                <Upload />
              )}
            </div>
            <div className={`col-lg-${hasPermission ? 7 : 0} col-xl-${hasPermission ? 7 : 0} col-md-${hasPermission ? 7 : 0} mb-lg-0 mb-4`}>
              <div className="card">
                <UploadFile />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-5">
              <ContentLoader width="100%" height={350}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="350" />
              </ContentLoader>
            </div>
            <div className="col-md-7">
              <ContentLoader width="100%" height={100}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="100" />
              </ContentLoader>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
