import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  CUSTOM_SELECT_STYLES,
  I_POP_OVER,
  SELECT_BOX_THEME,
  companyType,
  companyCountry,
} from "../../constants";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RoutesPaths } from "../../Router/constant";
import {
  addInfoQuestions,
  getAuthorisationCallAction,
} from "../../actions/myinfo";
import { useNavigate } from "react-router";
import { addCompany } from "../../actions/company";
import InfoModalStatic from "../../pages/dashboard/infoModalStatic";
import { startLoaderAction } from "../../actions/updateReducerValue";

export default function VerifyBusiness({
  showVerify,
  verifyClose,
  setShowObjective,
}) {
  const { pendingCompanySelected } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const defaultValues = {
    isBusinessRegistered: "",
    verificationType: "",
    countryOfIncorporation: "",
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const isBusinessRegisteredWatch = watch("isBusinessRegistered");

  const stepBack = () => {
    setShowObjective(true);
    verifyClose();
  };

  useEffect(() => {
    const isSingapore = pendingCompanySelected?.isSingapore;
    if (isSingapore) {
      setValue("isBusinessRegistered", {
        label:
          isSingapore === true
            ? "Yes. This business is registered in Singapore."
            : isSingapore === false
            ? "No. This business is not registered in Singapore."
            : "",
        value: isSingapore === true ? true : isSingapore === false ? false : "",
      });

      setValue("verificationType", pendingCompanySelected?.verificationMethod);
      setValue("countryOfIncorporation", {
        name: pendingCompanySelected?.countryOfIncorporation,
      });
      if (pendingCompanySelected?.companyType) {
        setValue("companyType", {
          label: pendingCompanySelected?.companyType,
          value: pendingCompanySelected?.companyType,
        });
      }
    }
  }, [setValue, pendingCompanySelected, getValues]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (
        value?.verificationType === "myinfo" &&
        value?.isBusinessRegistered?.value !== true
      ) {
        setValue("isBusinessRegistered", {
          label: "Yes. This business is registered in Singapore.",
          value: true,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const saveQuestions = async (data) => {
    let questionsPayload = {
      isSingapore:
        data?.verificationType === "myinfo"
          ? true
          : data?.isBusinessRegistered?.value,
      verificationMethod: data?.verificationType,
      countryOfIncorporation:
        data?.verificationType === "standard"
          ? data?.countryOfIncorporation?.name
          : "",
      companyType:
        data?.verificationType === "standard" &&
        data?.countryOfIncorporation?.name !== "Not registered anywhere"
          ? data?.companyType?.label
          : "",
      company: pendingCompanySelected.id,
    };
    await dispatch(addInfoQuestions(questionsPayload));
  };

  const onSubmit = async (data) => {
    const { id } = pendingCompanySelected;
    if (id) {
      await saveQuestions(data);
      if (data.verificationType === "myinfo" && id) {
        const authoriseUrl = await dispatch(getAuthorisationCallAction(id));
        if (authoriseUrl && authoriseUrl.status && authoriseUrl.data) {
          await dispatch(
            addCompany(
              { isActive: false, id: pendingCompanySelected?.id },
              true
            )
          );
          verifyClose();
          reset();
          await dispatch(startLoaderAction());
          setTimeout(() => {
            window.location = authoriseUrl.data;
          }, 1000);
        }
      } else {
        await dispatch(
          addCompany({ isActive: true, id: pendingCompanySelected?.id }, true)
        );
        verifyClose();
        reset();
        navigate(RoutesPaths.DASHBOARD);
      }
    } else {
    }
  };

  const handleClose = async () => {
    const verificationType = getValues("verificationType") || null;
    const isBusinessRegistered = getValues("isBusinessRegistered") || null;
    const companyType = getValues("companyType") || null;
    const countryOfIncorporation = getValues("countryOfIncorporation") || null;
    const data = {
      verificationType,
      isBusinessRegistered,
      companyType,
      countryOfIncorporation,
    };
    await saveQuestions(data);
    reset()
    verifyClose();
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  const handleRadio = async (value) => {
    await trigger("verificationType");
    if (value !== "myinfo" && getValues("isBusinessRegistered")) {
      setValue("countryOfIncorporation", {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
      });
    }
    if (value === "myinfo") {
      setValue("countryOfIncorporation", {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
      });
    }
  };

  useEffect(() => {
    if (isBusinessRegisteredWatch && !isBusinessRegisteredWatch?.value) {
      setValue("countryOfIncorporation", { name: "Not registered anywhere" });
      const countryIndex = companyCountry.findIndex((x) => x.code === "SG");
      companyCountry[countryIndex].isDisabled = true;
    }
  }, [isBusinessRegisteredWatch]);

  return (
    <>
      <Modal
        show={showVerify}
        onHide={handleClose}
        className="objectiveModal ps-0 custom-modal">
        <div className="modal-header modalHeaderPadding">
          <img
            onClick={stepBack}
            className="stepBack cursorPointer"
            src="assets/img/backStep.svg"
            alt=""
          />
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={handleClose}
            aria-label="Close"></button>
        </div>
        <Modal.Body>
          <div className="row right-side-row landingPage">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              <h5 className="edit-title">Step 4: Verify your business</h5>
              <p className="edit-description">
                We will be sending you notifications directly to confirm and
                verify your business activities.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 verifyForm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group ">
                  <div className="row">
                    <div className="col-lg-12 stepDiv">
                      <p className="company-text">
                        Choose how you will verify your business.{" "}
                        <img
                          src="assets/img/info-icon.svg"
                          alt=""
                          className="m-0 pb-1 cursorPointer"
                          onClick={() =>
                            handleClickInfo(I_POP_OVER.VERIFY_BUSINESS)
                          }
                        />
                      </p>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <label
                        htmlFor="myinfo"
                        onClick={async (event) => {
                          handleRadio(event.target.value);
                        }}
                        className={
                          getValues("verificationType") === "myinfo"
                            ? "active verifyIndicator"
                            : "verifyIndicator"
                        }>
                        <input
                          type="radio"
                          id="myinfo"
                          value="myinfo"
                          hidden
                          {...register("verificationType", { required: true })}
                        />

                        <div>
                          <div>
                            <button type="button">Fastest</button>
                          </div>
                          <img src="assets/img/myinfo-logo.svg" alt="" />
                          <h5>myinfo business</h5>
                          <p>
                            Log into myinfo and give permission to retrieve your
                            details. Get your Singpass login details ready.
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <label
                        htmlFor="standard"
                        onClick={async (event) => {
                          handleRadio(event.target.value);
                        }}
                        className={
                          getValues("verificationType") === "standard"
                            ? "active verifyIndicator"
                            : "verifyIndicator"
                        }>
                        <input
                          type="radio"
                          id="standard"
                          value="standard"
                          hidden
                          {...register("verificationType", { required: true })}
                        />
                        <div>
                          <img src="assets/img/laptop-logo.svg" alt="" />
                          <h5>Manual Verification</h5>
                          <p>Manually fill in business details.</p>
                        </div>
                      </label>
                    </div>

                    {errors.verificationType?.type === "required" && (
                      <span className="errorMessage mt-3">
                        Choose method to verify
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-lg-12 stepDiv">
                      <p className="company-text">
                        Is this business already registered in Singapore?
                      </p>

                      <Controller
                        name="isBusinessRegistered"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={[
                              {
                                label:
                                  "Yes. This business is registered in Singapore.",
                                value: true,
                              },
                              {
                                label:
                                  "No. This business is not registered in Singapore.",
                                value: false,
                              },
                            ]}
                            isClearable={true}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            placeholder="Choose your answer"
                            onChange={async (selectedOption) => {
                              setValue("isBusinessRegistered", selectedOption);
                              if (selectedOption?.value) {
                                setValue("countryOfIncorporation", {
                                  name: "Singapore",
                                  dial_code: "+65",
                                  code: "SG",
                                });
                                await trigger("countryOfIncorporation");
                              }
                              await trigger("isBusinessRegistered");
                              getValues("countryOfIncorporation");
                            }}
                            isDisabled={
                              getValues("verificationType") === "myinfo"
                            }
                          />
                        )}
                      />
                      {errors.isBusinessRegistered?.type === "required" && (
                        <span className="errorMessage">Select answer</span>
                      )}
                    </div>
                  </div>
                </div>
                {getValues("verificationType") === "standard" && (
                  <>
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-lg-12 stepDiv">
                          <p className="company-text">
                            Is there any other country that this business is
                            registered in?*
                          </p>

                          <Controller
                            name="countryOfIncorporation"
                            rules={{ required: true }}
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                              <Select
                                theme={SELECT_BOX_THEME}
                                styles={CUSTOM_SELECT_STYLES}
                                className="select-border"
                                {...field}
                                options={[
                                  { name: "Not registered anywhere" },
                                ].concat(companyCountry)}
                                isClearable={true}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.name}
                                placeholder="Choose your country"
                                onChange={async (selectedOption) => {
                                  setValue(
                                    "countryOfIncorporation",
                                    selectedOption
                                  );
                                  await trigger("countryOfIncorporation");
                                }}
                                isDisabled={
                                  getValues("isBusinessRegistered")?.value
                                }
                              />
                            )}
                          />
                          {errors.countryOfIncorporation?.type ===
                            "required" && (
                            <span className="errorMessage">Select country</span>
                          )}
                        </div>
                      </div>
                    </div>
                    {getValues("countryOfIncorporation")?.name !==
                      "Not registered anywhere" && (
                      <div className="form-group ">
                        <div className="row">
                          <div className="col-lg-12 stepDiv">
                            <p className="company-text">Company Type*</p>

                            <Controller
                              name="companyType"
                              rules={{ required: true }}
                              control={control}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  theme={SELECT_BOX_THEME}
                                  styles={CUSTOM_SELECT_STYLES}
                                  className="select-border"
                                  {...field}
                                  options={companyType}
                                  isClearable={true}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  placeholder="Choose your company type"
                                />
                              )}
                            />
                            {errors.companyType?.type === "required" && (
                              <span className="errorMessage">
                                Select company type
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="modal-footer modal-bottom-action">
                  <button type="submit" className="btn agree-button">
                    Save and Proceed
                    <img src="assets/img/nextStep.svg" alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
