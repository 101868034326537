import { HOLDERS_PAGE_TYPE } from "../constants";
import referralService from "../services/referralInvites.service";
import { addPromoterAction, getPendingInvites } from "./promoters";
import {
  LOAD_REFERRAL_SKELETON,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  GET_REFERRAL_LIST,
} from "./types";

export const getReferralListAction = (type = null) => async (dispatch) => {
  dispatch({ type: LOAD_REFERRAL_SKELETON, payload: true });
  return await referralService.getReferralInvites(type).then((resp) => {
    dispatch({
      type: GET_REFERRAL_LIST,
      payload: resp.status ? resp.data : [],
    });
  });
};

export const addIndividualReferralAction = (payload) => async (dispatch) => {
  return referralService.addIndividualReferral(payload).then((resp) => {
    if (resp.status) {
      dispatch(getReferralListAction());
      if (payload?.global !== 1) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return resp;
  });
};

export const removeIndividualInviteAction = (id) => (dispatch) => {
  return referralService.removeIndividualInvite(id).then((resp) => {
    if (resp.status) {
      dispatch(getReferralListAction());
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};


export const editInvitePayoutAction = (id, payolad) => (dispatch) => {
  return referralService.editInvitePayout(id, payolad).then((resp) => {
    if (resp.status) {
      dispatch(getReferralListAction(1));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};