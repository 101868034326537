import React from "react";
import { Link } from "react-router-dom";
import { backendUrl } from "../../constants";
import { RoutesPaths } from "../../Router/constant";

export default function ProfileBanner({ user }) {
  return (
    <>
      <div
        className="page-header border-radius-xl mt-4 profile-header"
        style={{
          backgroundImage: "url(assets/img/profiletop.jpg)",
          backgroundPositionY: "50%",
        }}
      >
        <Link to={RoutesPaths.ACCOUNTSETTING} className=" account-setting-btn">
          <button className="btn upgrade-btn-dashboard account-setting-btn">
            Edit profile
          </button>
        </Link>
      </div>
      <div className="card card-body blur shadow-blur mx-3 mx-lg-1 mt-n6 overflow-hidden">
        <div className="row gx-4">
          <div className="col-auto">
            <div
              className="user_profile business-image hasLogo"
              style={{
                backgroundImage: `url(${
                  user && user.profilePic && user.profilePic.filename
                    ? backendUrl + user.profilePic.filename
                    : "assets/img/Frame1.svg"
                })`,
              }}
            ></div>
          </div>
          <div className="col-auto my-auto">
            <div className="h-100">
              <h5 className="font-weight-bolder phil-text phil-text-title">
                {user && user.first_name
                  ? user.first_name + " " + user.last_name
                  : ""}
              </h5>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper profile-title-location position-relative end-0">
              {user && user.city && user.country && (
                <div className="text-status locationAlignCenter">
                  <p>
                    <img alt="" src="assets/img/locatin.png" />
                    &nbsp; &nbsp;Location
                  </p>

                  <h6 className="mb-0 payment-text   Vancouver-text">
                    {user && user.city && user.country
                      ? (user.city ? user.city + ", " : "") + " " + user.country
                      : "-"}
                  </h6>
                </div>
              )}
              {/* <div className="text-status">
                <p>
                  <img alt="" src="assets/img/timezone.png" />{" "}
                  &nbsp;&nbsp;10:00 AM
                </p>
                <h6 className="mb-0 payment-text  Vancouver-text ">10:00 AM</h6>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
