import React, { useEffect, useState } from "react";
// import { STATIC_EXTERNAL_URL } from "../../constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { Client } from "@twilio/conversations";
import { getTwilioToken, sendMessageToAdmin } from "../../actions/chat";
import { useDispatch, useSelector } from "react-redux";
import { startLoaderAction, stopLoaderAction } from "../../actions/updateReducerValue";
import { CONTACT_US_TRIGER, MESSAGE_TYPE, MODAL_BUSINESS_IDENTIFY } from "../../constants";

export const companyLink = (selectedCompany) => {
  return `<a class="messageExternalLinkCompanyChange" href="javascript:void(0)" companyid="${selectedCompany?.id}">${selectedCompany?.companyName}</a>`
}

export const transactionId = (id) => {
  return `<strong>${id}</strong>`
}

export default function ContactUs(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation()
  const [contactLoader, setContactLoader] = useState(true);
  const { selectedCompany } = useSelector((state) => state.company);

  useEffect(() => {
    setContactLoader(false);
  }, [])

  const sendMessageToAdminfunc = async () => {
    setContactLoader(true);

    let message = "I am interested to create a holding company.";

    switch (props.type) {
      case CONTACT_US_TRIGER.SHARE_CAPITAL:
        message = `I am interested to add shares to my holding company for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.SHAREHOLDER:
        message = `I am interested to ${props?.addEditValue} a ${props?.addEditValue === 'add' ? "new" : ""} shareholder for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.DIRECTOR:
        message = `I am interested to ${props?.addEditValue} a ${props?.addEditValue === 'add' ? "new" : ""} director for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.PROMOTER:
        message = `I am interested to add a new promoter for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.REMOVE_SHARE_DIRETOR_PROMOTER:
        message = `I am interested to remove a ${props?.addEditValue} for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.INDIVIDUAL_REFERRAL:
        message = `I am interested to invite individual referral for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.PAID_UP_CAPITAL_PAYMENT:
        message = `I am interested to deposit paid-up capital for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.PAYMENT:
        message = `I am interested to buy ${props?.subscriptionPlanName} for ${companyLink(selectedCompany)}`;
        break;

      case MODAL_BUSINESS_IDENTIFY.LOGO:
        message = `I am interested to edit ${selectedCompany?.companyName} logo for ${companyLink(selectedCompany)}`;
        break;

      case MODAL_BUSINESS_IDENTIFY.COMPANY_NAME:
        message = `I am interested to edit ${selectedCompany?.companyName} name for ${companyLink(selectedCompany)}`;
        break;

      case MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE:
        message = `I am interested to edit ${selectedCompany?.companyName} website for ${companyLink(selectedCompany)}`;
        break;

      case MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY:
        message = `I am interested to edit ${selectedCompany?.companyName} activity for ${companyLink(selectedCompany)}`;
        break;

      case MODAL_BUSINESS_IDENTIFY.PRIMARY_ADDRESS:
        message = `I am interested to edit ${selectedCompany?.companyName} address for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.BOB_POTENTIAL_PURPOSE:
        message = `I like to engage with potential stakeholders for ${companyLink(selectedCompany)}`;
        break;

      case CONTACT_US_TRIGER.BOB_SPEAK_TO_ADMIN:
        message = `I am interested to have InProved’s help to add new stakeholders to ${companyLink(selectedCompany)}`;
        break;

      default:
        message = "I am interested to create a holding company.";
        break;
    }

    await sendMessageToAdmin(`${props.type === CONTACT_US_TRIGER.BOB_SPEAK_TO_ADMIN ? "<strong>Speak to admin:</strong>" : "<strong>Contact us:</strong>"} ${message}`, selectedCompany, MESSAGE_TYPE.CONTACT_US_BUTTON, navigate, [])
    setContactLoader(false);
    setTimeout(() => { props?.handleClose(); }, 100);
  }

  const goToAdminChat = async () => {
    dispatch(startLoaderAction())
    const twilioTokenResponse = await getTwilioToken("");
    if (twilioTokenResponse.status) {
      const client = await new Client(twilioTokenResponse.data);
      let conversationsPaginator = await client.getSubscribedConversations({ attributes: { isAdmin: true, isMasterAdmin: true } });
      let filteredConversations = conversationsPaginator.items.filter(conversation => {
        return conversation.attributes.isAdmin === true && conversation.attributes.isMasterAdmin === true;
      });
      if (filteredConversations.length > 0) {
        const conversation = filteredConversations[0];
        dispatch(stopLoaderAction())
        if (conversation.sid) {
          navigate(RoutesPaths.MESSAGE_DETAIL + '?room=' + conversation.sid);
        }
      }
    }
  }


  return <>
    <button
      type="button"
      disabled={contactLoader}
      onClick={sendMessageToAdminfunc}
      className={`btn btn-sm  mb-4 mt-3 contactus-modal-button ${props?.theme || ""}`}
    >
      {contactLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Contact us
    </button>
    {/* <a
      href={STATIC_EXTERNAL_URL.CONTACT}
      target="_blank"
      relation="noreferrer noopener nofollow"
      type="button"
      className="btn btn-sm  mb-4 mt-3 contactus-modal-button"
    >
      Contact Us
    </a> */}
  </>
}
