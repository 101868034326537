import axionIns from "../axios";

// const getStripePayment = () => {
//   return axionIns.get("stripe").then((response) => { return response.data });
// };

const getStripePaymentByCompanyId = async (payload, page, limit) => {
  return axionIns.get("stripe/company/" + payload + '?page=' + page + '&perPage=' + (limit || 10)).then((response) => { return response.data });
};

const createStripeToken = async (payload) => {
  return axionIns.post("stripe", JSON.stringify(payload)).then((response) => { return response.data });
};

const cancelStripe = async (payload) => {
  return axionIns.delete("stripe/company/" + payload).then((response) => { return response.data });
};

const getInvoiceDetails = async (invoiceId) => {
  return axionIns.get("stripe/invoice/" + invoiceId).then((response) => { return response.data });
};

const getStripePlans = async () => {
  return axionIns.get("stripe/plans").then((response) => { return response.data });
};

const createManualEntry = async (payload) => {
  return axionIns.post("stripe/manual", JSON.stringify(payload)).then((response) => { return response.data });
};

const currencyConverter = async (type, payload) => {
  return axionIns.post("currency/" + type, JSON.stringify(payload)).then((response) => { return response.data });
};

const getChargeReceiptUrl = async (paymentIntentId) => {
  return axionIns.get("stripe/receipt/" + paymentIntentId).then((response) => { return response.data });
};

const xtraFeesCalculator = async (payload) => {
  return axionIns.post("xtrafees", JSON.stringify(payload)).then((response) => { return response.data });
};

const currencyConversionCalc = async (payload) => {
  return axionIns.post("xtrafees/currencyconversioncalc", JSON.stringify(payload)).then((response) => { return response.data });
};

const getTransactionStatus = async (id) => {
  return axionIns.get("stripe/payment_history/" + id).then((response) => { return response.data });
};

const payWithCrypto = async (payload) => {
  return axionIns.post("triplea", JSON.stringify(payload)).then((response) => { return response.data });
};

const exportOject = {
  createStripeToken,
  // getStripePayment,
  getStripePaymentByCompanyId,
  cancelStripe,
  getInvoiceDetails,
  getStripePlans,
  createManualEntry,
  currencyConverter,
  getChargeReceiptUrl,
  xtraFeesCalculator,
  currencyConversionCalc,
  getTransactionStatus,
  payWithCrypto
};

export default exportOject;
