import React, { useState, useEffect } from 'react';
import ContentLoader from "react-content-loader";
import { USER_DASHBOARD_BULLION } from '../../../../constants';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDashboardAction } from '../../../../actions/userDashboard';

export default function PersonalAccountDetails({ isEditing, setIsEditing }) {
    const dispatch = useDispatch()
    const { isAdminLoggedIn, user } = useSelector((state) => state.auth);
    const { userDashboard, isUserDashboardLoading } = useSelector((state) => state.userDashboard);

    const {
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => {
        dispatch(updateUserDashboardAction(data))
        setIsEditing(null);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='accountBorderSection BuillionAccountPadding'>

                    {isUserDashboardLoading ? (
                        <div className='row HeadericonAlign'>
                            <div className='col-12 rightAlignText'>
                                <div style={{ width: '100%', marginLeft: 'auto' }}>
                                    <ContentLoader width="100%" height={15}>
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                    </ContentLoader>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='row HeadericonAlign'>
                            <div className='col-12 rightAlignText'>
                                {isAdminLoggedIn && (
                                    isEditing === USER_DASHBOARD_BULLION.PERSONAL ? (
                                        <button className="btn save-button customFilterButton" type='submit'>
                                            Save
                                        </button>
                                    ) : (
                                        <span className="btn btn-link  px-3 mb-0 edit-button" onClick={() => setIsEditing(USER_DASHBOARD_BULLION.PERSONAL)}>
                                            <i className="fas fa-pencil-alt edit-button me-2"></i>
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                    )}


                    <div className='container-fluid p-0'>
                        <div className='row'>
                            <div className='col-md-3 col-6'>
                                <h6 className='accountPrimaryText'>Personal account number</h6>
                                {isUserDashboardLoading ? (
                                    <div>
                                        <ContentLoader width="100%" height={15}>
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                        </ContentLoader>
                                    </div>
                                ) : (
                                    <div className='accountSecondaryText'>{user?.personalAccountNumber || 'XXXXXXXX-XXXXXX'}</div>
                                )}
                            </div>

                            <div className='col-md-3 col-6 rightAlignText'>
                                <h6 className='accountPrimaryText'>Total account balance</h6>
                                {isUserDashboardLoading ? (
                                    <div>
                                        <ContentLoader width="100%" height={15}>
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                        </ContentLoader>
                                    </div>
                                ) : (
                                    <div>
                                        {isEditing === USER_DASHBOARD_BULLION.PERSONAL ? (
                                            <input
                                                type='text'
                                                className='form-control customInputField textInputAlignText'
                                                {...register("totalAccountBalance", {})}
                                                defaultValue={userDashboard?.totalAccountBalance || ""}
                                            />
                                        ) : (
                                            <div className='accountSecondaryText'>{userDashboard?.totalAccountBalance || "-"}</div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 col-0'></div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
