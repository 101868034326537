import {
  CLEAR_TYPING_INDICATOR,
  GET_CHANNEL_LIST,
  REMOVE_TYPING_INDICATOR,
  RESET_CHANNEL_MESSAGE,
  SET_CHANNEL_LAST_MESSAGE,
  SET_CHANNEL_MESSAGE,
  SET_CHANNEL_NEW_MESSAGE,
  SET_TYPING_INDICATOR,
  SET_CLIENT,
  SET_CURRENT_CONVERSATION,
  SET_CHAT_CONVERSATION_MEDIA,
  SET_BULK_CHANNEL_MESSAGE,
  UPSERT_CONVERSATION,
  REMOVE_CONVERSATION,
  UPDATE_CURRENT_CONVERSATION,
  CONVERSATION_LAST_READ_INDEX,
  ADD_MESSAGES,
  PUSH_MESSAGES,
  REMOVE_MESSAGES,
  UPDATE_PARTICIPANTS,
  UPDATE_UNREAD_MESSAGES,
  UPDATE_CONVERSATION,
  ADD_ATTACHMENT,
  CLEAR_ATTACHMENTS,
  TYPING_STARTED,
  TYPING_ENDED,
  UPDATE_LOADING_STATE,
  SET_DEFAULT_CURRENT_MESSAGE_LENGTH,
  SET_SUCCESS_MESSAGE,
  SET_CHAT_SEARCH_RESULT,
  SET_CHAT_SEARCH_MESSAGE_ID,
  SET_FAILED_MESSAGE,
  SET_CHAT_LOADING_SKELETON,
  SET_LOAD_CLIENT,
  HOLD_SID_CONVERSATION,
  OPEN_LATEST_CONVERSATION,
  CHAT_MEDIA,
  LOADING_CHAT_MEDIA,
  CHAT_PREVIEWER_MEDIA
} from "./types";
import chatService from "../services/chat.service";
import { MESSAGE_TYPE, MESSAGING_LIMIT } from "../constants";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";
import { Client } from "@twilio/conversations";
import { RoutesPaths } from "../Router/constant";
import { uploadMultipleFileAction } from "./files";

export const addChannel = async (payload) => {
  return await chatService.addChannel(payload).then((res) => {
    return res;
  });
};

export const getChannelList = (companyId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    chatService.getChannelList(companyId).then((res) => {
      if (res.status) {
        dispatch({ type: GET_CHANNEL_LIST, payload: res.data });
      } else {
        dispatch({ type: GET_CHANNEL_LIST, payload: [] });
      }
      resolve(true);
    });
  });
};

export const getTwilioToken = async (string, id) => {
  return chatService.getTwilioToken(string, id).then((response) => {
    return response;
  });
};

export const updateChannel = (obj, companyId) => (dispatch) => {
  return chatService.updateChannel(obj).then((response) => {
    dispatch({ type: SET_CHANNEL_LAST_MESSAGE, payload: obj });
    return response;
  });
};

export const refreshChatReadStatus = (obj, companyId) => (dispatch) => {
  return chatService.refreshChatReadStatus(companyId, obj).then((response) => {
    dispatch(getChannelList(companyId));
    return response;
  });
};

export const setMessages =
  (conversationSid, message, hasPrevious) => (dispatch) => {
    dispatch({
      type: SET_CHANNEL_MESSAGE,
      payload: {
        message: message,
        sid: conversationSid,
        hasPrevious: hasPrevious,
      },
    });
  };

export const setMessage = (conversationSid, message) => (dispatch) => {
  dispatch({
    type: SET_CHANNEL_NEW_MESSAGE,
    payload: { message: message, sid: conversationSid },
  });
};

export const resetMessage = () => (dispatch) => {
  dispatch({ type: RESET_CHANNEL_MESSAGE });
};

export const setBulkMessages = (messages) => (dispatch) => {
  dispatch({ type: SET_BULK_CHANNEL_MESSAGE, payload: messages });
};

export const setTypingIndicationAction =
  (channelId, identity) => (dispatch) => {
    dispatch({
      type: SET_TYPING_INDICATOR,
      payload: { identity: identity, channelId: channelId },
    });
  };

export const removeTypingIndicationAction =
  (channelId, identity) => (dispatch) => {
    dispatch({
      type: REMOVE_TYPING_INDICATOR,
      payload: { identity: identity, channelId: channelId },
    });
  };

export const clearTypingIndicationAction = () => (dispatch) => {
  dispatch({ type: CLEAR_TYPING_INDICATOR });
};

export const setClientAction = (client) => (dispatch) => {
  dispatch({ type: SET_CLIENT, payload: client });
};

export const setConversationAction = (conversation) => (dispatch) => {
  dispatch({ type: SET_CURRENT_CONVERSATION, payload: conversation });
};

export const createMembersAPI = (members) => {
  return chatService.createNewMembers(members).then((resp) => {
    if (resp.status) {
      return Promise.resolve(resp);
    } else {
      return Promise.resolve(resp);
    }
  });
};

export const SetChatCurrentMedia = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_CONVERSATION_MEDIA, payload: payload });
};

// ------------------------------------------------------------------------------------------------------------

export const upsertConversation = (convo) => (dispatch) => {
  dispatch({ type: UPSERT_CONVERSATION, payload: convo });
  dispatch(chatLoadingAction(false))
};

export const removeConversation = (sid) => (dispatch) => {
  dispatch({ type: REMOVE_CONVERSATION, payload: sid });
};

export const updateCurrentConversation = (sid) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_CONVERSATION, payload: sid });
};

export const sliceCurrentConvoChatToDefault = (sid) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_CURRENT_MESSAGE_LENGTH, payload: sid });
};

export const setLastReadIndex = (index) => (dispatch) => {
  dispatch({ type: CONVERSATION_LAST_READ_INDEX, payload: index });
};

export const upsertMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const pushMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: PUSH_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const removeMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: REMOVE_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const updateLoadingState = (loadingStatus) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING_STATE,
    payload: loadingStatus,
  });
};

export const updateParticipants = (participants, sid) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_PARTICIPANTS,
    payload: { participants, sid },
    stateValue: getState()
  });
};

export const updateUnreadMessages = (channelSid, unreadCount) => (dispatch) => {
  dispatch({
    type: UPDATE_UNREAD_MESSAGES,
    payload: { channelSid, unreadCount },
  });
};

export const updateConversation = (channelSid, parameters) => (dispatch) => {
  dispatch({
    type: UPDATE_CONVERSATION,
    payload: { channelSid, parameters },
  });
};

export const addAttachment =
  (channelSid, messageSid, mediaSid, attachment) => (dispatch) => {
    dispatch({
      type: ADD_ATTACHMENT,
      payload: { channelSid, messageSid, mediaSid, attachment },
    });
  };

export const clearAttachments = (channelSid, messageSid) => (dispatch) => {
  dispatch({
    type: CLEAR_ATTACHMENTS,
    payload: { channelSid, messageSid },
  });
};

export const startTyping = (channelSid, participant) => (dispatch) => {
  dispatch({
    type: TYPING_STARTED,
    payload: { channelSid, participant },
  });
};

export const endTyping = (channelSid, participant) => (dispatch) => {
  dispatch({
    type: TYPING_ENDED,
    payload: { channelSid, participant },
  });
};

export const getMessages = async (conversation) => {
  return await conversation.getMessages();
};

export const getTypingMessage = (typingData) => {
  return typingData.length > 1
    ? `${typingData.length + " participants are typing..."}`
    : `${typingData[0] + " is typing..."}`;
};

export const addParticipantAction = async (payload) => {
  return chatService.addChannelParticipant(payload).then((response) => {
    return response;
  });
};

export const removeParticipantAction = async (payload) => {
  return chatService.removeChannelParticipant(payload).then((response) => {
    return response;
  });
};

export const searchAction = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_SEARCH_RESULT, payload: payload });
};

export const gotoMessageSearchMessageAction = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_SEARCH_MESSAGE_ID, payload: payload });
};

export const changeChannelNameAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return new Promise((resolve, reject) => {
    chatService.changeChannelName(payload).then((response) => {
      dispatch(stopLoaderAction());
      if (response.status)
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: response.data });
      else dispatch({ type: SET_FAILED_MESSAGE, payload: response.data });
      resolve(response);
    });
  });
};

// common function for send message to admin
export const sendMessageToAdmin = async (
  notificationBanner,
  selectedCompany,
  type,
  navigate,
  fileArray
) => {
  var sendMessage = "";
  try {
    if (type === MESSAGE_TYPE.BANNER) {
      sendMessage =
        `<strong>Notification ${notificationBanner?.callToAction?.label}:</strong> ` +
        reOrganiseMessage(notificationBanner?.message, selectedCompany);
    } else if (type === MESSAGE_TYPE.BOB_RESCHEDULE) {
      sendMessage = `<strong>Reschedule meeting:</strong> ` + reOrganiseMessage(notificationBanner);
    } else if (type === MESSAGE_TYPE.BIDASK) {
      sendMessage = `<strong>Bid Ask:</strong><br/>
          Quantity: ${notificationBanner.quantity}<br/>
          Price Bid Amount: ${notificationBanner.priceBidAmount}<br/>
          Price Bid Amount Total: ${notificationBanner.priceBidAmountTotal}<br/>
          Mode of delivery: ${notificationBanner.modeOfDelivery}
      `
      if (localStorage.getItem('bidAskResult')) localStorage.removeItem('bidAskResult'); localStorage.removeItem('bidAsk')

    } else if (type === MESSAGE_TYPE.USER_DASHBOARD || type === MESSAGE_TYPE.FORMALISE_MY_HOLDING_COMPANY || type === MESSAGE_TYPE.CONTACT_US_BUTTON) {
      sendMessage = notificationBanner;
    } else {
      sendMessage = notificationBanner !== "" ? `<strong>Need help:</strong> ` + reOrganiseMessage(notificationBanner) : `<strong>Leave message:</strong>`;
    }

    if (sendMessage) {
      const twilioTokenResponse = await getTwilioToken("");
      if (twilioTokenResponse.status) {
        const client = new Client(twilioTokenResponse.data);
        let conversationsPaginator = await client.getSubscribedConversations({
          attributes: { isAdmin: true, isMasterAdmin: true },
        });
        let filteredConversations = conversationsPaginator.items.filter(
          (conversation) => {
            return conversation.attributes.isAdmin === true && conversation.attributes.isMasterAdmin === true;
          }
        );
        if (filteredConversations.length > 0) {
          const conversation = filteredConversations[0];
          if (type === MESSAGE_TYPE.BANNER || type === MESSAGE_TYPE.BIDASK) {
            await conversation.sendMessage(sendMessage);
          }
          if (conversation.sid && (type === MESSAGE_TYPE.ASKADDA || type === MESSAGE_TYPE.LEAVEAMESSAGE || type === MESSAGE_TYPE.BOB_RESCHEDULE || type === MESSAGE_TYPE.USER_DASHBOARD || type === MESSAGE_TYPE.FORMALISE_MY_HOLDING_COMPANY || type === MESSAGE_TYPE.CONTACT_US_BUTTON)) {
            const newMessageBuilder = conversation.prepareMessage().setBody(sendMessage);
            const fileData = new FormData();
            for (const file of fileArray) {
              fileData.append("files", file);
            }
            if (fileArray.length > 0) {
              const filesResp = await uploadMultipleFileAction(fileData)
              if (filesResp && filesResp.length > 0) {
                chatMediaAddAction({ channelId: conversation?.sid, media: filesResp })
                newMessageBuilder.setAttributes({ files: filesResp });
              }
            }

            const messageIndex = await newMessageBuilder.build().send();
            try {
              await conversation.advanceLastReadMessageIndex(messageIndex ?? 0);
              if (type === MESSAGE_TYPE.ASKADDA || type === MESSAGE_TYPE.BOB_RESCHEDULE || type === MESSAGE_TYPE.USER_DASHBOARD || type === MESSAGE_TYPE.FORMALISE_MY_HOLDING_COMPANY || type === MESSAGE_TYPE.CONTACT_US_BUTTON) {
                navigate(
                  RoutesPaths.MESSAGE_DETAIL + "?room=" + conversation.sid
                );
              }
            } catch (e) {
              throw e;
            }
          }
        }
      }
    }
  } catch (error) {
  }
};

export const reOrganiseMessage = (message, selectedCompany) => {
  const isActive = selectedCompany?.subscriptionPlan?.isActive;
  const updatedMessage = message
    .replace("${companyName}", selectedCompany?.companyName)
    .replace(
      "${subscriptionPlan}",
      isActive ? selectedCompany?.subscriptionPlan?.name : ""
    );
  return updatedMessage;
};


export const chatLoadingAction = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_LOADING_SKELETON, payload: payload })
}


export const setChatLoadClientAction= () => (dispatch) => {
  dispatch({ type: SET_LOAD_CLIENT })
}

export const setSidConversationAction= (payload) => (dispatch) => {
  dispatch({ type: HOLD_SID_CONVERSATION, payload: payload })
}

export const openLatestConversationAction= (payload) => (dispatch) => {
  dispatch({ type: OPEN_LATEST_CONVERSATION, payload: payload })
}


export const chatMediaGetAction = (channelId) => async (dispatch) => {
  dispatch({ type: LOADING_CHAT_MEDIA, payload: true})
  return chatService.chatMediaGet(channelId).then((response) => {
    dispatch({ type: CHAT_MEDIA, payload: response?.status ? (response?.data ?? []) : [] })
    dispatch({ type: LOADING_CHAT_MEDIA, payload: false})
  });
};


export const chatMediaAddAction = async (payload) => {
  return chatService.chatMediaAdd(payload).then((response) => {
    // dispatch(chatMediaGetAction(payload?.channelId));
  });
};


export const emptyChatMediaAction = () => async (dispatch) => {
  dispatch({ type: CHAT_MEDIA, payload: [] })
};

export const chatMediaPreviewerAction = (currentImage, isViewerOpen) => async (dispatch) => {
  dispatch({ type: CHAT_PREVIEWER_MEDIA, payload: {currentImage, isViewerOpen} })
};