import axionIns from "../axios";

const getObjective = async (page, perPage) => {
  var url = "objective";
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};

let exportObj = {
  getObjective,
};

export default exportObj;
