import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RoutesPaths } from './constant';
import { clearFly } from '../actions/auth';

function PublicRoute({ children }) {
    const dispatch = useDispatch()
    const location = useLocation()
    const [queryParameters] = useSearchParams()
    const token = queryParameters.get("token");
    const { isLoggedIn } = useSelector(state => state.auth);

    if(isLoggedIn && token){
        dispatch(clearFly())
    } else if (isLoggedIn) {
        if(location.pathname===RoutesPaths.MYINFOCALLBACK){
            // return <Navigate to={RoutesPaths.MYINFOCALLBACK} />
        }else{
            return <Navigate to={RoutesPaths.DASHBOARD} />
        }
        // not logged in so redirect to login page with the return url
        
    } else { }
    // authorized so return child components
    return children;
}
export default PublicRoute;
