import React, { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { sendMessageToAdmin } from "../../actions/chat";
import { useNavigate } from "react-router";
import { MESSAGE_TYPE, PROFILE_ACTION } from "../../constants";
import { useDispatch } from "react-redux";
import { startLoaderAction, stopLoaderAction } from "../../actions/updateReducerValue";
import toast from "react-hot-toast";

export default function ConfirmMeeting({
  meetingOpen,
  meetingClose,
  handleConnect,
  item,
  selectedCompany
}) {

  const message = useRef(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rescheduleMeeting, setRescheduleMeeting] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleConfirmMeeting = async () => {

    if (rescheduleMeeting) {
      dispatch(startLoaderAction());
      if (message?.current?.value.trim().length > 0) {
        setButtonDisabled(true)
        await sendMessageToAdmin(
          message?.current?.value,
          selectedCompany,
          MESSAGE_TYPE.BOB_RESCHEDULE,
          navigate,
          []
        );
        dispatch(stopLoaderAction());
        setButtonDisabled(false)
        meetingClose();
      } else {
        toast.error("Please enter message to reschedule")
      }
    } else {
      setButtonDisabled(true)
      handleConnect(item, true);
      meetingClose();
      setButtonDisabled(false)
    }
  }

  return (
    <Modal
      show={meetingOpen}
      onHide={meetingClose}
      className="moddal-primary importModal confirmMeeting ps-0"
    >
      <Modal.Body>
        <div className="header">
          <div>
            <h4>Confirm{item?.action === PROFILE_ACTION.RESPOND ? "ed" : ""} Meeting&nbsp;
              {item?.first_name && item?.last_name && (
                `with ${item?.first_name + ' ' + item?.last_name}`
              )}
            </h4>
          </div>
          <i
            onClick={meetingClose}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modalBody importSampleFile">
          <div className="note">
            {item?.action === PROFILE_ACTION.CONFIRM ? (
              <p className="desc">Click 'Confirm Meeting' If you agree with the meeting details below:</p>
            ) : (
              <p className="desc">You agreed with the meeting details below:</p>
            )}
            <ul className="bulletSchedule">
              <li>Date Time: <span><TimeFormat time={item?.eventDetails?.date} /></span></li>
              <li>Location: <span>{item?.eventDetails?.location || "-"}</span></li>
              {item?.eventDetails?.description && (
                <li>Note: <span>{item?.eventDetails?.description || "-"}</span></li>
              )}
            </ul>
            {item?.action === PROFILE_ACTION.CONFIRM && (
              <>
                <p className="desc">Otherwise, you can opt for a reschedule here</p>
                <div className="rescheduleSetting">
                  <div className="rescheduleText">Reschedule this meeting </div>
                  <div className="annually-toggal-row">
                    <div className="form-check form-switch annually-btn">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        id="rememberMe"
                        checked={rescheduleMeeting}
                        onChange={(event) =>
                          setRescheduleMeeting(event.target.checked)
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <p className="desc">
                <span>IMPORTANT NOTE:</span> Lorem ipsum velit officia consequat
                duis enim velit mollit.
              </p> */}
              </>
            )}
          </div>
          {rescheduleMeeting && (
            <div className="row rescheduleTextarea">
              <div className="col-lg-12">
                <div className="form-group">
                  <p className="desc">Message Admin for a reschedule</p>
                  <textarea ref={message} rows="6" cols="50" maxLength="1000" autoComplete="off" type="text" className="form-control" placeholder="Type message here..." name="re"></textarea>
                </div>
              </div>
            </div>
          )}
          {item?.action === PROFILE_ACTION.CONFIRM && (
            <div className="importBtn">
              {/* <button
              className="customImportButton"
              onClick={() => handleclose(false)}
            >
              Cancel
            </button> */}
              <button
                disabled={buttonDisabled}
                className={`customAddButton removePadding ${rescheduleMeeting ? 'highlightBorder' : ''}`}
                onClick={handleConfirmMeeting}
              >
                {rescheduleMeeting ? "Reschedule" : "Confirm Meeting"}
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
