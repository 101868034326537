import React, { useEffect, useState } from "react";
import AddModal from "./addModal";
import EditModal from "./editModal";
import RemoveModal from "./removeModal";
import AppointModal from "./appointModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getShareHolderList,
} from "../../actions/shareHolder";
import { getPendingInvites } from "../../actions/promoters";
import {
  backendUrl,
  HOLDERS,
  HOLDERS_PAGE_TYPE,
  I_POP_OVER,
  POSITIONS,
} from "../../constants";
import InfoModalStatic from "../../pages/dashboard/infoModalStatic";
import StartMessageModel from "../message/StartMessageModel";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import AddEmail from "./addEmail";
import { getOfficerList } from "../../actions/officer";
import ContentLoader from "react-content-loader";
import TimeFormat from "../timeFormat/timeFormat";

export const INVITE_TYPE = {
  SHAREHOLDERS: 0,
  INVITES: 2,
};

export default function ShareTable(props) {
  const type = props.type;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const shareHolderRef = useRef(null);
  const { selectedCompany, hasPermission, loadCompany } = useSelector(
    (state) => state.company
  );
  const { objective } = selectedCompany
  const preDefaultShareholders = (objective?.length > 0 && objective[0]?.defaultShareholders) ? objective[0]?.defaultShareholders?.map(item => item.email) : []


  const { user } = useSelector(state => state.auth);
  const { shareHolder, pendingShareholderList, loadShareholder } = useSelector((state) => state.shareHolder);
  const { officers } = useSelector((state) => state.officers);
  const { holdNextInviteTempData } = useSelector((state) => state.promoters);

  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [appointModalShow, setAppointModalShow] = useState(false);
  const [performObj, setPerformObj] = useState({});
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const [messageModal, setMessageModal] = useState(false);
  const [deleteShareholderType, setDeleteShareholderType] = useState(null);
  const [shareHolderType, setShareHolderType] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(null);

  const [record, setRecord] = useState(5);
  const [addEmail, setAddEmail] = useState(false);

  useEffect(() => {
    if (props?.triggerHandleMessageInternal && holdNextInviteTempData?.type === HOLDERS.shareHolders) {
      const getList = shareHolder.find(x => x.email === holdNextInviteTempData?.email)
      if (getList) {
        props?.closeCongratulationsPopup()
        handleMessage(getList);
      } else {
        props?.closeCongratulationsPopup()
      }
    }
  }, [props?.triggerHandleMessageInternal])

  const addModalClose = () => {
    setAddModalShow(false);
  };

  const handleShow = () => {
    setAddModalShow(true);
  }

  const editModalClose = () => {
    setEditModalShow(false);
    setPerformObj({});
  };
  const handleEditModalShow = (valueObj) => {
    setPerformObj(valueObj);
    setEditModalShow(true);
  };

  const removeModalClose = () => {
    setRemoveModalShow(false);
  };

  const handleRemoveModalShow = (type, valueObj) => {
    setDeleteShareholderType(type);
    setPerformObj(valueObj);
    setRemoveModalShow(true);
  };

  const appointModalClose = () => {
    setAppointModalShow(false);
    navigate({ state: "" });
  };

  const handleAppointModalShow = (valueObj) => {
    setPerformObj(valueObj);
    setAppointModalShow(true);
  };

  const handleMessage = (list) => {
    setCurrentSelection(list)
    setMessageModal(true);
  };

  useEffect(() => {
    const { id } = selectedCompany;
    if (id && location) {
      // if (type === HOLDERS_PAGE_TYPE.INVITES) {
      //   dispatch(getPendingInvites(id, 2));
      // } else {
      // dispatch(getPendingInvites(id, 2));
      dispatch(getShareHolderList(id));
      dispatch(getOfficerList(id));
      // }
    }
  }, [dispatch, selectedCompany, location]);

  const onLoad = () => {
    setRecord(record + 5);
  };

  const getPositionName = (position, isAppointed) => {
    var returnVal = "";
    var filter = POSITIONS.filter((x) => x.value === position);
    if (filter.length > 0) {
      returnVal = filter[0].name;
    }
    return (isAppointed ? "Approved <br/>" : "Potential <br/>") + (returnVal ? returnVal.toLowerCase() : returnVal);
  };
  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  useEffect(() => {
    if (location.state === "inviteShareHolder") {
      navigate({ state: "" })
      setAddModalShow(true);
    } else if (location.state === "appointShareHolder") {
      navigate({ state: "" })
      shareHolderRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handleEmailShow = (value, type) => {
    setAddEmail(value);
    setShareHolderType(type);
  };

  const checkAppointPermission = (list) => {
    var returnValue = false
    const officersList = officers.filter(x => x.email === user.email && x.position === 2 && x.isAppointed === true)
    if (!list.isAppoint && !list.isAppointed && officersList.length > 0) {
      returnValue = true
    }
    if (!list.isAppoint && !list.isAppointed && !returnValue && list.id === selectedCompany.user && list.isDeleteApproved) {
      returnValue = true
    }

    if (!list.isAppoint && !list.isAppointed && !returnValue && selectedCompany.id === list.company._id && user.id === list.company.user && list.isDeleteApproved) {
      returnValue = true
    }
    return returnValue
  }

  const checkDeletePermission = (list) => {
    var returnValue = false
    if (list.isDeleteApproved && list.email === user.email) {
      returnValue = true
    }
    const officersList = officers.filter(x => x.email === user.email && x.isAppointed === true)
    if (list.isDeleteApproved && !list.isAppoint && !list.isAppointed && officersList.length > 0) {
      returnValue = true
    }
    return returnValue
  }

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div className="row">
            <div className="col-lg-7 col-6">
              <h3 className="business-font capital-head high-cap px-2">
                Shareholders
                {type === HOLDERS_PAGE_TYPE.INVITES && " invites"}{" "}
                <img
                  src="assets/img/info-icon.svg"
                  onClick={() => handleClickInfo(I_POP_OVER.SHAREHOLDERS)}
                  alt=""
                  className="m-0 pb-1 cursorPointer"
                />
              </h3>
            </div>

            <div className="col-lg-5 col-6 text-end">
              <div className="dropdown float-lg-end">
                {!loadShareholder && !loadCompany && selectedCompany &&
                  selectedCompany.id &&
                  hasPermission &&
                  // type !== HOLDERS_PAGE_TYPE.INVITES && (
                  <button
                    className="btn add-button add-button-shareholder m-0 companySpotlightStep1"
                    onClick={handleShow}
                  >
                    Add shareholders
                  </button>
                  // )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pb-2 pt-2">
          {shareHolder && shareHolder.length > 0 ? (
            <div className="table-responsive">
              <table
                ref={shareHolderRef}
                className="table mb-0 table-sharholder"
              >
                <thead>
                  <tr className="tableHeadings">
                    <th className="name-heading">Name</th>
                    {type !== HOLDERS_PAGE_TYPE.INVITES && (
                      <th className="position-heading">Position</th>
                    )}
                    {type === HOLDERS_PAGE_TYPE.INVITES && (
                      <th className="position-heading">Email address</th>
                    )}
                    <th className="date-heading">Date added</th>
                    <th className="action-heading">Actions</th>
                    <th className="status-heading">Status <img
                      src="assets/img/info-icon.svg"
                      onClick={() => handleClickInfo(type === HOLDERS_PAGE_TYPE.INVITES ? I_POP_OVER.SHAREHOLDERS_DIRECTOR_INVITEE_STATUS : I_POP_OVER.SHAREHOLDERS_DIRECTOR_STATUS)}
                      alt=""
                      className="m-0 pb-1 cursorPointer"
                    /></th>
                  </tr>
                </thead>
                <tbody className="text-row-color">
                  {shareHolder &&
                    shareHolder.slice(0, record).map((list, index) => (
                      <tr
                        className={
                          (!list.isApproved ? "active" : "",
                            list.isAppoint
                              ? "appoint"
                              : list.isAppointed
                                ? "appointed"
                                : "")
                        }
                        key={index}
                      >
                        <td>
                          <div className="d-flex">
                            <div className="d-flex flex-column justify-content-center">
                              {!loadShareholder && !loadCompany ? (
                                <h6 className="mb-0 shareHolderName">
                                  <div
                                    className="shareholder-img"
                                    style={{
                                      backgroundImage: `url(${list &&
                                        list.profilePic &&
                                        list.profilePic.filename
                                        ? backendUrl + list.profilePic.filename
                                        : "assets/img/Frame1.svg"
                                        })`,
                                    }}
                                  />
                                  {list.name}
                                  {((list.isVerified && !list.isAppoint) ||
                                    (list.isAppointed && !list.isAppoint)) && (
                                      <img
                                        className="verifiedIcon"
                                        src="assets/img/verified.svg"
                                        alt="verified.svg"
                                      />
                                    )}

                                  {list.isAppoint && (
                                    <img
                                      className="verifiedIcon"
                                      src="assets/img/ApprovedBadge.svg"
                                      alt="ApprovedBadge.svg"
                                    />
                                  )}
                                </h6>
                              ) : (
                                <div>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle text-sm">
                          {!loadShareholder && !loadCompany ? (
                            <span className="shareData">
                              {list.position &&
                                type !== HOLDERS_PAGE_TYPE.INVITES && (
                                  <span dangerouslySetInnerHTML={{
                                    __html: getPositionName(list.position, list.isAppointed),
                                  }}></span>
                                )}
                              {type === HOLDERS_PAGE_TYPE.INVITES && (
                                <div
                                  className="email-share limit-text cursor-pointer activity_wrapper"
                                  title={list.email}
                                >
                                  {list.email}
                                </div>
                              )}
                            </span>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                        <td className="align-middle ">
                          {!loadShareholder && !loadCompany ? (
                            <span className="shareCreateDate">
                              <TimeFormat time={list.createdAt} type={1} />
                              {/* {moment(list.createdAt).format("DD/MM/YYYY")} */}
                            </span>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>

                        {/* -----------------ACtions */}

                        <td className="text-center">

                          {!loadShareholder && !loadCompany ? (
                            hasPermission && (
                              !list.isAppointedSendInvite ? (
                                <>
                                  {!pendingShareholderList.some(x => x.email === list.email) ? (
                                    <>
                                      {!list.email && (
                                        <>
                                          <span
                                            className="btn btn-link px-0 mb-0 mx-1 right-delete-icon"
                                            data-tooltip-top="Send email to invite"
                                            onClick={() =>
                                              handleEmailShow(list, "shareHolder")
                                            }
                                          >
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                          </span>
                                        </>
                                      )}
                                      {list.isDeleteApproved && list.email && (
                                        <span
                                          className="btn btn-link px-0 mb-0 mx-1"
                                          onClick={() => handleEditModalShow(list)}
                                        >
                                          <i
                                            className="fas fa-pencil-alt"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      )}

                                      {list.isDeleteApproved && list.email && (
                                        user.email !== list.email && (
                                          <span
                                            className="btn btn-link px-0 mb-0 mx-1"
                                            onClick={() => handleMessage(list)}
                                          >
                                            <i
                                              className="fa-solid fa-comment-dots mx-2"
                                            ></i>
                                          </span>)
                                      )}

                                      {checkAppointPermission(list) && (
                                        <span
                                          className="btn btn-link px-0 mb-0 mx-1"
                                          onClick={() =>
                                            handleAppointModalShow(list)
                                          }
                                        >
                                          <i className="fas fa-user-check"></i>
                                        </span>
                                      )}

                                      {checkDeletePermission(list) && (
                                        <span
                                          className="btn btn-link px-0 mb-0 mx-1 right-delete-icon"
                                          onClick={() =>
                                            handleRemoveModalShow(
                                              INVITE_TYPE.SHAREHOLDERS,
                                              list
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      )}
                                      &nbsp;
                                    </>
                                  ) : pendingShareholderList.some(x => x.email === list.email && x.is_delete_requested === true && x.company === selectedCompany?.id) ? (
                                    <>&nbsp;</>
                                  ) : (
                                    (!preDefaultShareholders.includes(list.email) ? (
                                      <span
                                        className="btn btn-link px-0 mb-0 mx-1 right-delete-icon"
                                        onClick={() =>
                                          handleRemoveModalShow(
                                            INVITE_TYPE.INVITES,
                                            list
                                          )
                                        }
                                      >
                                        <i
                                          className="fas fa-trash-alt"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (<span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">&nbsp;</span>))
                                  )}
                                </>) : (
                                <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">&nbsp;</span>
                              )
                            )) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                        <td className="status-type">
                          {!loadShareholder && !loadCompany ? (
                            !pendingShareholderList.some(x => x.email === list.email) ? (
                              <>
                                {list.isAppointedSendInvite ? (
                                  <button className="btn pending-button-second m-0 ">
                                    Pending
                                  </button>
                                ) : (
                                  <>
                                    {list.email ? (
                                      !list.isApproved || (list.isAppoint && list.isDeleteApproved) ? (
                                        <button className="btn amendment-progress-button-second">
                                          Acceptance in progress
                                        </button>
                                      ) : (!list.isEditApproved && !list.isAppoint) ? (
                                        <button className="btn amendment-progress-button">
                                          Amendment in progress
                                        </button>
                                      ) : (!list.isDeleteApproved) ? (
                                        <button className="btn amendment-progress-button deletion">
                                          deletion in progress
                                        </button>
                                      ) : (
                                        <button className="btn active-button-second pending-button-second m-0">
                                          {list?.verification === 'myinfo' ? "Official" : "Invite accepted"}
                                        </button>
                                      )
                                    ) : (
                                      list?.verification === 'myinfo' && (
                                        <button className="btn active-button-second pending-button-second m-0">
                                          Official
                                        </button>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            ) : pendingShareholderList.some(x => x.email === list.email && x.is_delete_requested === true && x.company === selectedCompany?.id) ? (
                              <button className="btn amendment-progress-button deletion">
                                Removal in progress
                              </button>
                            ) : (
                              <button className="btn pending-button-second m-0 ">
                                Pending
                              </button>
                            )) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!loadShareholder && !loadCompany && shareHolder.length > record && (
                <button
                  onClick={onLoad}
                  className="btn btn-sm  view-button mb-4 mt-3"
                >
                  View all
                </button>
              )}
            </div>
          ) : (
            <div className="no-record d-flex pt-2 pb-2">
              <div className="d-flex flex-column justify-content-center ">
                {!loadShareholder && !loadCompany ? (
                  <h6 className="mb-0 text-sm table-heading ">
                    No shareholder added
                  </h6>
                ) : (
                  <div>
                    <ContentLoader width="100%" height={15}>
                      <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                    </ContentLoader>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <AddModal addModalShow={addModalShow} addModalClose={addModalClose} pageType={type} />
      <AddEmail
        emailShow={addEmail}
        emailClose={() => setAddEmail(false)}
        holdertype={shareHolderType}
      />
      <EditModal
        editModalShow={editModalShow}
        editModalClose={editModalClose}
        performObj={performObj}
        pageType={type}
      />
      <RemoveModal
        pendingShareholderList={pendingShareholderList}
        removeModalShow={removeModalShow}
        removeModalClose={removeModalClose}
        performObj={performObj}
        type={deleteShareholderType}
      />
      <AppointModal
        appointModalShow={appointModalShow}
        appointModalClose={appointModalClose}
        type={HOLDERS.shareHolders}
        performObj={performObj}
      />

      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />

      <StartMessageModel
        messageModal={messageModal}
        addModalClose={() => setMessageModal(false)}
        currentSelection={currentSelection}
        listing={shareHolder}
        type={HOLDERS.shareHolders}
      />
    </>
  );
}
