import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFiles } from "../../actions/files";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { Controller, useForm, useWatch } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { Form } from "react-bootstrap";

export default function UploadFile() {
  const dispatch = useDispatch();
  const { control } = useForm();
  const limit = useWatch({ control, name: "limit" });

  const { selectedCompany } = useSelector((state) => state.company);
  const { files, loadFiles, count, reset } = useSelector((state) => state.files);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    const setStateValues = () => {
      setPage(1)
      setActivePage(0)
    }
    setStateValues();
  }, [reset])

  useEffect(() => {
    const callGetActionsOnLoad = () => {
      const { id } = selectedCompany;
      if (id && page) {
        dispatch(getAllFiles(id, page, limit));
      }
    };
    callGetActionsOnLoad()
  }, [selectedCompany, page, limit, dispatch]);


  const getFileExt = (fileName) => {
    var splitText = fileName.split(".");
    return splitText[splitText.length - 1];
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  return (
    <>
      <div className="card-header pb-0">
        <div className="row">
          <div className="col-lg-6 col-7">
            <h5 className="payment-text">Files</h5>
          </div>
        </div>
      </div>
      <div className="card-body share-officer file-holder px-0 pb-2 pt-2">
        {!loadFiles ? (
          files && files.length > 0 ? (
            <div className="table-responsive">
              <table className="table align-items-center mb-0 table-sharholder table-files">
                <thead>
                  <tr className="tableHeadings">
                    <th className="text-sm table-heading">Name</th>
                    <th className="text-sm table-heading text-center">
                      Date Added
                    </th>
                    <th className="table-heading align-middle text-center text-sm">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="text-row-color">
                  {files &&
                    files.map((list, index) => (
                      <tr key={index}>
                        <td className="file-name-row">
                          <div className="d-flex px-0 py-1">
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href={list.name}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6 className="mb-0 px-0 text-sm file-text-table">
                                  {list.originalName ? list.originalName : "-"}
                                </h6>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className="text-sm table-heading text-center date-padding">
                          {list.createdAt && (
                            <TimeFormat time={list.createdAt} />
                          )}
                        </td>
                        <td className="align-middle text-center text-sm">
                          <a href={list.name} target="_blank" rel="noreferrer">
                            {/* <button
                      className="btn btn-link payment-text text-sm mb-0 px-0
                        ms-4"
                    > */}
                            <i
                              className="fas fa-file text-lg me-1"
                              aria-hidden="true"
                            ></i>
                            {list.name && getFileExt(list.name)}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <button className="btn btn-sm  view-button mb-4 mt-3 ">
            LOAD MORE
          </button> */}
            </div>
          ) : (
            <div className="no-record d-flex pt-2 pb-2">
              <div className="d-flex flex-column justify-content-center ">
                <h6 className="mb-0 text-sm table-heading ">No files added</h6>
              </div>
            </div>
          )
        ) : (
          <div className="p-3">
            {[100, 90, 80, 70, 80].map((item, index) => (
              <ContentLoader key={index} width={item + "%"} height={15}>
                <rect rx="5" ry="5" width={item + "%"} height="15" />
              </ContentLoader>
            ))}
          </div>
        )}
      </div>
      {!loadFiles && (
        files && files.length > 0 && (
          <div className="tableBottom">
            <div className="filterButton">
              <div className="dropdownButton">
                <Controller
                  name="limit"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      className="select-border"
                      placeholder="10"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Form.Select>
                  )}
                />
              </div>
            </div>
            <div>
              {count > 0 && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                  nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                  pageCount={count}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination customPagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={activePage}
                />
              )}
            </div>
          </div>
        ))}
    </>
  );
}
