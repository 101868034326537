import taskService from "../services/task.service";
import { GET_TASK_LIST, LOAD_TASK_SKELETON, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";


export const getTaskList = (companyId) => async (dispatch) => {
  // dispatch(startLoaderAction())
  dispatch({type: LOAD_TASK_SKELETON, payload: true});
  return taskService.getTask(companyId).then((resp) => {
    // dispatch(stopLoaderAction())
    if(resp.status){
      dispatch({type: GET_TASK_LIST ,payload: resp.data,});
    }else{
      dispatch({type: GET_TASK_LIST ,payload: [],});
    }
  });
};

export const addTaskAction = (payload) => (dispatch) => {
  return taskService.addTask(payload).then((resp) => {
    if (resp.status) {
      if(payload.company)
        dispatch(getTaskList(payload.company));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const updateTaskAction = (companyId, taskId, payload) => (dispatch) => {
  return taskService.updateTask(taskId, payload).then((resp) => {
    if (resp.status) {
      if(companyId)
        dispatch(getTaskList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const removeTask = (companyId, payload) => (dispatch) => {
  return taskService.deleteTask(payload).then((resp) => {
    if (resp.status) {
      dispatch(getTaskList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};
