import {
  GET_IMPORT_LIST,
  GET_SUGGESTED_PROFILE,
  IMPORT_ERROR_MESSAGE,
  IMPORT_PROGRESS,
  IMPORT_SUCCESS_MESSAGE,
  LOAD_BOB_SKELETON
} from "../actions/types";

const initialState = {
  bookOfBusinessResponseService: null,
  businessProfiles: {},
  importList: [],
  count: 0,
  importFileMsg: "",
  progress: 0,
  loadBob: false,
  temporaryBob: []
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_SUGGESTED_PROFILE:
      return {
        ...state,
        businessProfiles: payload,
        count: count,
        loadBob: false,
        bookOfBusinessResponseService: payload?.data?.[0]?.bookOfBusinessGroupId?.service || null,
        temporaryBob: payload?.temporaryBob || []
      };
    case LOAD_BOB_SKELETON:
      return {
        ...state,
        loadBob: payload
      };
    case GET_IMPORT_LIST:
      return {
        ...state,
        importList: payload,
      };
    case IMPORT_ERROR_MESSAGE:
      return {
        ...state,
        importFileMsg: payload,
      };
    case IMPORT_SUCCESS_MESSAGE:
      return {
        ...state,
        importFileMsg: payload,
      };
    case IMPORT_PROGRESS:
      return {
        ...state,
        progress: payload,
      };

    default:
      return state;
  }
}
