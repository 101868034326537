import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBannerAction } from "../../actions/banner";
import { backendUrl, BANNER_TYPE } from "../../constants";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";

export default function DashboardCarousel() {
  const dispatch = useDispatch();
  const { bannerList } = useSelector((state) => state.banner);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBannerAction());
  }, [dispatch]);

  const redirect = (info) => {
    switch (info.bannerType) {
      case BANNER_TYPE.PLAN:
        navigate(RoutesPaths.PRODUCT_PAGE.replace(":slug", info?.cta?.slug));
        break;
      case BANNER_TYPE.EXTERNAL:
        window.open(info.cta, "_blank");
        break;
      default:
        break;
    }
  };

  const getImage = (info) => {
    return isMobile ? info?.mobileImage?.filename : info?.webImage?.filename;
  };

  return (
    <>
      {bannerList && bannerList?.length > 0 && (
        <div className="row bannerRow">
          <div className="col-md-12 mx-auto">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showStatus={false}
              showIndicators={bannerList?.length >= 2 ? true : false}>
              {bannerList?.map((info, index) => (
                <div
                  onClick={() => redirect(info)}
                  key={index}
                  className="carousel-inner">
                  <img src={backendUrl + getImage(info)} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}
