import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessageToAdmin } from '../../../actions/chat'
import { useNavigate } from 'react-router'
import { getCompanyDashboardAction } from '../../../actions/companyDashboard'
import CompanyAccountDetails from './components/companyAccountDetails'
import CompanyBullionBalance from './components/compnayBullionBalance'
import CompanyMetalAllocation from './components/companyMetalAllocation'
import CompanyProfileLoss from './components/companyProfileLoss'


export default function BuillionAccountCompany() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(null);
    const [adminLoader, setAdminLoader] = useState(null);
    const { selectedCompany } = useSelector((state) => state.company);
    useEffect(() => {
        if (selectedCompany?.id) {
            dispatch(getCompanyDashboardAction(selectedCompany?.id))
        }
    }, [dispatch, selectedCompany])

    useEffect(() => {
        setAdminLoader(null);
    }, [])



    const sendMessageToAdminfunc = async (type, message, loaderType) => {
        setAdminLoader(loaderType)
        await sendMessageToAdmin(message, null, type, navigate, []);
    }


    return (
        <>
            {/* {isBrowser && <AddDashboard />} */}
            <CompanyAccountDetails isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
            {/* {isMobile
             && <AddDashboard />} */}
            <div className='row'>
                {/* <div className='col-md-6'>
                    <CashBalance isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div> */}
                <div className='col-md-6'>
                    <CompanyBullionBalance isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div>

                <div className='col-md-6'>
                    <CompanyMetalAllocation isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div>
                {/* <div className='col-md-6'>
                    <CompnayProductAllocation isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div> */}

                <div className='col-md-6'>
                    <CompanyProfileLoss isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div>
                {/* <div className='col-md-6'>
                    <AllInvites sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div> */}
            </div>
        </>
    )
}
