// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import { useState } from 'react';
// import { useRef } from 'react';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { createStripeTokenAction } from '../../actions/stripe';
// import { STRIPE_KEY } from '../../constants';
// import CheckoutForm from "./CheckoutForm"; 
// const stripePromise = loadStripe(STRIPE_KEY.PUBLISH_KEY_TEST);

export default function Stripe() {
  // const dispatch = useDispatch()
  // const { stripeToken, stripeSubscription } = useSelector((state) => state.stripe);

  // const loadRef = useRef(true);
  // const [ options, SetOptions ] = useState({clientSecret: null,})
  // useEffect(() => {
  //   if(stripeToken){
  //     SetOptions({
  //       clientSecret: stripeToken,
  //     })
  //   }
  //   if (loadRef.current) {
  //     if(stripeSubscription && stripeSubscription.amount){
  //       dispatch(createStripeTokenAction({
  //         amount: stripeSubscription.amount*100
  //       }));
  //     }
  //   }
  //   return () => {
  //     loadRef.current = false
  //   }
  // }, [stripeToken])

    return (
    <>
      {/* {options && options.clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      ) : (
        null
      )} */}
    </>
  )
};