import React from "react";
import { Modal } from "react-bootstrap";

export default function SaveModal({ saveModalShow, saveModalClose }) {
  return (
    <>
      <Modal className="custom-modal save-modal-func" show={saveModalShow} onHide={saveModalClose}>
        <div className="modal-header p-0">

          <button
            type="button"
            className="btn-close text-dark got-it-close close-button"
            onClick={saveModalClose}
          >

          </button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title confirmed-title">
            Your changes have been recorded
          </h5>
          {/* <p className="edit-description m-0">We'll be in touch to confirm your amendment</p> */}
        </div>
        <div className="modal-footer modal-bottom-action align-text-left">
          <button type="button" className="btn agree-button" onClick={saveModalClose}>
            Got it
          </button>
        </div>
      </Modal>
    </>
  );
}
