import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  backendUrl,
  privacy,
  terms,
  termsOfAdvisoryPlan,
} from "../../constants";
import { singleFileUploadAction } from "../../actions/files";
import ContactUs from "../ContactUs/ContactUs";
import { getAllCompany } from "../../actions/company";
import { handleWebsiteChange } from "../../pages/dashboard/businessCard";

export default function AddVirtualBusiness({
  setInfoModalStaticShow,
  setInfoModalStaticShowType,
  register,
  handleSubmit,
  getValues,
  onSubmit,
  setValue,
  errors,
  handleClose,
}) {
  const dispatch = useDispatch();
  const { pendingCompany, pendingCompanySelected } = useSelector(
    (state) => state.company
  );
  useEffect(() => {
    dispatch(getAllCompany());
  }, [dispatch]);
  useEffect(() => {
    setValue("logo", pendingCompanySelected?.logo);
    setValue("companyName", pendingCompanySelected?.companyName);
    setValue("companyWebsite", pendingCompanySelected?.companyWebsite);
    setValue("isTick", pendingCompanySelected?.isTick);
  }, [setValue]);

  const uploadFiles = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(singleFileUploadAction(formData)).then((resp) => {
      if (resp) {
        setValue("logo", resp?.filename);
      }
    });
    event.target.value = null;
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
          <h5 className="edit-title">Step 1: Start a holding company</h5>
          <p className="edit-description">
            {/* InProved holding company is a virtual holding company platform
            specific to the precious metals industry. It lets you create holding
            companies for the purpose of holding gold and precious metals assets
            tax-efficiently or develop gold and precious metals projects by
            appointing promoters, directors and shareholders from around the
            world. */}
            Are you advising or managing precious metals assets for yourself,
            clients, family or friends?
          </p>
          <p className="edit-description mb-0">
            {/* <strong>Here are some tips to get you started</strong> */}
            If so, please create a new company or register your existing company
            with us.
          </p>
          {/* <ol className="edit-description">
            <li>Here are some tips to get you started</li>
            <li>Complete the verification process</li>
            <li>Start adding your networks and invite suggested profiles</li>
            <li>Schedule virtual board meetings regularly</li>
            <li>
              Formalise the holding company to legalise the business and
              recognise assets in the holding company
            </li>
          </ol> */}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group ">
              <div className="row">
                <div className="col-lg-12">
                  <p className="input-text heading">Holding company name *</p>
                  <input
                    maxLength={50}
                    type="text"
                    className="form-control"
                    placeholder="Holding company name"
                    {...register("companyName", { required: true })}
                  />{" "}
                  {errors.companyName?.type === "required" && (
                    <span className="errorMessage">Name is required</span>
                  )}
                  {getValues("companyName")?.length > 0 &&
                    getValues("companyName")?.trimLeft().length < 1 && (
                      <span className="errorMessage">
                        Black spaces are not allowed
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="form-check form-check-info text-left tickCompany">
              <input
                id="flexCheck"
                maxLength={50}
                autoComplete="nope"
                className="form-check-input"
                type="checkbox"
                value=""
                {...register("isTick", {
                  required: false,
                })}
              />
              <label
                className="input-text heading cursor-pointer"
                htmlFor="flexCheck">
                Tick this box if this company is already incorporated somewhere
              </label>
            </div>
            <div className="form-group form-margin">
              <div className="row">
                <div className="col-12 mt-3 upload-holder">
                  <p className="input-text heading">Upload new holdco logo</p>
                  <label htmlFor="inputTag" className="upload-btn ">
                    Upload file
                    <input
                      id="inputTag"
                      type="file"
                      {...register("logo", { required: false })}
                      onChange={uploadFiles}
                      hidden
                    />
                  </label>
                  {getValues("logo") && getValues("logo").length > 0 && (
                    <img
                      width="35px"
                      height="38px"
                      src={backendUrl + getValues("logo")}
                      alt="company-logo"
                    />
                  )}
                </div>
              </div>
              <div className="form-group ">
                <div className="row">
                  <div className="col-lg-12 mt-3">
                    <p className="input-text heading">Company website</p>
                    <input
                      maxLength={50}
                      type="text"
                      className="form-control"
                      placeholder="Enter url"
                      {...register("companyWebsite", {
                        required: false,
                      })}
                      onChange={handleWebsiteChange}
                    />
                    {errors.companyWebsite?.type === "required" && (
                      <span className="errorMessage">
                        Enter Company website name
                      </span>
                    )}
                    {errors.companyWebsite?.type === "pattern" && (
                      <span className="errorMessage">
                        Company website invalid
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <p className="import-note">
              Important note:{" "}
              <span className="will-need-text">
                By continuing, you agree to our{" "}
                <a
                  href={terms}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="terms">
                 Terms and conditions
                </a>
                ,{" "}
                <a
                  href={privacy}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="terms">
                  Privacy policy
                </a>
              </span>
            </p>
            <div className="modal-footer modal-bottom-action">
              <ContactUs handleClose={handleClose} />
              <button
                type="submit"
                // onClick={toggleHere}
                className="btn agree-button">
                Save and Proceed <img src="assets/img/nextStep.svg" alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
