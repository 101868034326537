import { useNavigate } from "react-router";
import ContactUs, { companyLink, transactionId } from "../../../components/ContactUs/ContactUs";
import Currency from "../../../components/currency/currency";
import CurrencyFormat from "../../../components/currencyFormat/currencyFormat";
import { MESSAGE_TYPE, SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD_NAME } from "../../../constants";
import { sendMessageToAdmin } from "../../../actions/chat";
import { useState } from "react";
import TimeFormat from "../../../components/timeFormat/timeFormat";
import { useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

export default function TransactionSegment({ transactionsSegmentRecord, selectedCompany }) {
  const navigate = useNavigate();
  const [transactionSegmentLoader, SetTransactionSegmentLoader] = useState();
  const { transactionStatus, isTransactionStatusLoading } = useSelector((state) => state.stripe);

  const sendMessageToAdminfunc = async () => {
    SetTransactionSegmentLoader(true);
    // await sendMessageToAdmin(`<strong>Speak to admin:</strong> Hi there, I like to enquire about transaction ID ${companyLink(selectedCompany)}`, selectedCompany, MESSAGE_TYPE.CONTACT_US_BUTTON, navigate, [])
    await sendMessageToAdmin(`<strong>Speak to admin:</strong> Hi there, I like to enquire about transaction ID ${transactionId(transactionsSegmentRecord?.paymentId)}`, selectedCompany, MESSAGE_TYPE.CONTACT_US_BUTTON, navigate, [])
    SetTransactionSegmentLoader(false);
  }

  return (
    <>
      {transactionsSegmentRecord && Object.keys(transactionsSegmentRecord).length > 0 && (
        <div className="suggestedRightSection" style={{ backgroundImage: "url(assets/img/planBack.jpg)" }}>
          <div className="planHolder">
            <span className="statusTextHeadr">Status</span>
            <div className="planName statusTab">
              <h4>{transactionsSegmentRecord?.subscriptionPlan?.name ?? "Paid-up capital"}</h4>
              <div className="status-details">
                {!isTransactionStatusLoading ? transactionStatus?.length > 0 && transactionStatus.map((item, index) => (
                  <div key={index} className="status-item completed" >
                    <div className="status-icon">
                      {index === transactionStatus.length - 1 ? <img src="assets/img/status-active.svg" alt="" /> : <img src="assets/img/status-Icon.svg" alt="" />}
                      <div className="statusLine"></div>
                    </div>
                    <div className="status-info">
                      <p className="statusCall">{item.payment_status}</p>
                      <p className="dateTime"><TimeFormat time={item.createdAt} /></p>
                    </div>
                  </div>
                )) :
                  [1, 2, 3,].map((index) => (
                    <div key={index} className="status-item completed contentLoader">
                      <div className="status-icon">
                        <ContentLoader width={24} height={24}>
                          <rect x="0" y="0" rx="50" ry="50" width="100%" height="100%" />
                        </ContentLoader>
                      </div>
                      <div className="status-info">
                        <p className="statusCall">
                          <ContentLoader width={"100%"} height={10}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                          </ContentLoader>
                        </p>
                        <p className="dateTime">
                          <ContentLoader width={"100%"} height={10}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                          </ContentLoader>
                        </p>
                      </div>
                    </div>
                  ))
                }
              </div>
              <button disabled={transactionSegmentLoader} onClick={sendMessageToAdminfunc} className="speakToAdminBtn">{transactionSegmentLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Speak to admin</button>
              {/* <p>Date/Time: <TimeFormat time={transactionsSegmentRecord?.createdAt} /></p>
                            <p>Payment method: {SUPPORTED_PAYMENT_METHOD_NAME[transactionsSegmentRecord?.paymentMethodSelection] || ''}</p>
                            <p>Status: <span>{transactionsSegmentRecord?.payment_status}</span></p>
                            <div className="upgradePlan">
                                <div className="row">
                                    <div className="col-md-12">
                                        <button disabled={transactionSegmentLoader} onClick={sendMessageToAdminfunc} className="planUpgrade">{transactionSegmentLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Speak to admin</button>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div >
      )
      }
    </>
  )
}