import myInfoService from "../services/myinfo.service";
import {
  getAllCompany,
  selectCompanyDashboard,
  selectPendingCompany,
  setNotificationBanner,
} from "./company";
import {
  SET_FAILED_MESSAGE,
  START_LOADER,
  STOP_LOADER,
  CLEAR_NOTIFICATION,
  MY_INFO_LOADING,
  COMPANY_VERIFICATION_STEP_FAILED,
} from "./types";

export const getAuthorisationCallAction = (company) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return myInfoService.getAuthorisationCall(company).then((resp) => {
    dispatch({ type: STOP_LOADER });
    if (resp.status) {
      return resp;
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      return false;
    }
  });
};

export const getMyInfoDataAction = (payload) => async (dispatch,getState) => {
  dispatch({ type: START_LOADER });
  dispatch({ type: CLEAR_NOTIFICATION, payload: false });
  dispatch({ type: MY_INFO_LOADING, payload: true });
  return myInfoService.getMyInfoData(payload).then(async (resp) => {
    dispatch({ type: CLEAR_NOTIFICATION, payload: true });
    dispatch({ type: MY_INFO_LOADING, payload: false });
    dispatch({ type: STOP_LOADER });
    dispatch(setNotificationBanner(resp));
    if (resp?.status && resp?.company) {
      await dispatch(getAllCompany(resp?.company));
    } else {
      if (resp?.company) {
        dispatch(toggleCompanyVerificationFailed({ step: 4, data: resp.data }));
        const filter = getState()?.company?.pendingCompany?.filter(x=>x.id===resp?.company)[0] || null
        dispatch(selectPendingCompany(filter))
      }
    }
    return resp;
  });
};

export const toggleCompanyVerificationFailed = (payload) => (dispatch) => {
  dispatch({ type: COMPANY_VERIFICATION_STEP_FAILED, payload: payload });
};

export const addInfoQuestions = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return myInfoService.addInfoQUestions(payload).then(async (resp) => {
    dispatch({ type: STOP_LOADER });
    if (resp.status) {
      await dispatch(getAllCompany());
      dispatch(selectPendingCompany(resp?.data));
      return resp;
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      return false;
    }
  }).catch(() => {
    dispatch({ type: STOP_LOADER });
    return false;
  });
};
