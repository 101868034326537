import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PaymentModal from "../../pages/payment/paymentModal";
import { RoutesPaths } from "../../Router/constant";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AddModal from "../addBussiness/AddModal";
import {
  iniPopupFunction,
  logout,
  setGuideOpenAction,
  showCompanyAddAction,
  toggleMobileMenu,
} from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany, selectCompanyDashboard } from "../../actions/company";
import Confirm from "../confirm/confrm";
import {
  STATIC_EXTERNAL_URL,
  SUBSCRIPTION_MODULE_TYPE,
  backendUrl,
} from "../../constants";
import PaymentModalStripe from "../../pages/payment/paymentModalStripe";
import { paymentModalAction } from "../../actions/stripe";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { company, selectedCompany, isDefaultCompany, pendingCompany } =
    useSelector((state) => state.company);
  const { initPopup, sidebarMobile, showMenuSidebar, companyAddShow } =
    useSelector((state) => state.auth);
  const { isSubscribed } = useSelector((state) => state.stripe);

  const { stripeModal, stripeType } = useSelector((state) => state.stripe);
  const dashboardPath = useLocation(RoutesPaths.DASHBOARD);
  const landingPath = useLocation(RoutesPaths.LANDING);
  const paymentPath = useLocation(RoutesPaths.PAYMENT);
  const productPath = useLocation(RoutesPaths.PRODUCTS_LIST_PAGE);
  const shareCapitalPath = useLocation(RoutesPaths.SHARECAPITAL);
  const invitePath = useLocation(RoutesPaths.INVITE);
  const filePath = useLocation(RoutesPaths.FILE);
  const taskPath = useLocation(RoutesPaths.TASK);
  const profilePath = useLocation(RoutesPaths.PROFILE);
  const securityPath = useLocation(RoutesPaths.SECURITY);
  const VerificationPath = useLocation(RoutesPaths.VERIFICATION);
  const MessagePath = useLocation(RoutesPaths.MESSAGE_DETAIL);
  const businessDashboardPath = useLocation(RoutesPaths.BOOK_OF_BUSINESS);

  const [addModalShow, setAddModalShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentStripeModal, setPaymentStripeModal] = useState(false);
  const [showPopOver, SetShowPopOver] = useState(false);
  const [detailMenuToggle, SetDetailsMenuToggle] = useState(false);
  const loadRef = useRef(true);
  let overlayRef = useRef();

  useEffect(() => {
    if (loadRef && initPopup) {
      setTimeout(() => {
        handleAddShow();
        dispatch(iniPopupFunction(false));
      }, 1000);
    }
    return () => {
      loadRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (sidebarMobile) {
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.remove("g-sidenav-pinned");
    }
  }, [sidebarMobile]);

  const modalClose = () => {
    dispatch(paymentModalAction(false, stripeType));
  };
  const handleShow = () => {
    // dispatch(paymentModalAction(true, SUBSCRIPTION_MODULE_TYPE.SUBSCRIPTION));
    navigate(RoutesPaths.PAYMENT);
  };

  const addModalClose = () => {
    setAddModalShow(false);
    dispatch(showCompanyAddAction(false));
  };

  useEffect(() => {
    if (companyAddShow) {
      setAddModalShow(true);
    }
  }, [companyAddShow]);

  const handleAddShow = () => {
    overlayRef.current.click();
    setAddModalShow(true);
    SetShowPopOver(false);
  };

  const logoutClick = () => {
    setConfirmModal(true);
  };

  const logoutHide = (value) => {
    if (value) {
      dispatch(logout());
    }
    setConfirmModal(false);
  };

  const changeCompany = (companyId) => {
    navigate(RoutesPaths.DASHBOARD);
    dispatch(selectCompanyDashboard(companyId));
  };
  const StripeModalConfirm = (value) => {
    setPaymentStripeModal(value);
  };

  const handleClickShowPopover = () => {
    SetShowPopOver(true);
  };

  const mobileMenuOpen = () => {
    dispatch(toggleMobileMenu());
  };

  const refreshPage = () => {
    dispatch(getAllCompany());
  };

  // const popover = (
  //   <Popover
  //     id="popover-basic"
  //     className="sidebar-popover webkitScroll sidebar-new">
  //     <div className="company-menu-section">
  //       <Popover.Header as="h3" className="dropdown-head">
  //         Accounts
  //       </Popover.Header>
  //       {company &&
  //         company.length > 0 &&
  //         company.map((info, index) => (
  //           <span
  //             onClick={() => changeCompany(info.id)}
  //             key={index}
  //             className={`${selectedCompany.id === info.id ? "active" : ""}`}>
  //             <Popover.Body
  //               as="modal"
  //               className={`dropdown-menu-inner ${
  //                 info.id === selectedCompany.id ? "active" : ""
  //               }`}>
  //               <div
  //                 className={`business-image ${
  //                   info.logo ? "hasLogo" : "hasText"
  //                 } ${selectedCompany.id === info.id ? "active" : ""} ${
  //                   info?.isDefault ? "defaultUserAccount" : ""
  //                 }`}
  //                 style={{
  //                   backgroundImage: `url(${
  //                     !info?.isDefault && info.logo
  //                       ? backendUrl + info.logo
  //                       : "assets/img/business-shape.png"
  //                   })`,
  //                 }}>
  //                 {/* {(!info.logo && !info?.isDefault)
  //                     ? info.companyName
  //                       ? info.companyName?.substring(0, 1)
  //                       : "-"
  //                     : ""} */}
  //               </div>
  //               <div className="business-trend">
  //                 <p>{info.companyName}</p>{" "}
  //                 <p className="designation">Designation</p>{" "}
  //               </div>
  //             </Popover.Body>
  //           </span>
  //         ))}
  //     </div>
  //     <div className="company-menu-section pendingCompanyList">
  //       <Popover.Header as="h3" className="dropdown-head">
  //         Pending
  //       </Popover.Header>
  //       {pendingCompany &&
  //         Object.keys(pendingCompany).length > 0 &&
  //           <span
  //             className={`${selectedCompany.id === pendingCompany.id ? "active" : ""}`}>
  //             <Popover.Body
  //               as="modal"
  //               className={`dropdown-menu-inner ${
  //                 pendingCompany.id === selectedCompany.id ? "active" : ""
  //               }`}>
  //               <div
  //                 className={`business-image ${
  //                   pendingCompany.logo ? "hasLogo" : "hasText"
  //                 } ${selectedCompany.id === pendingCompany.id ? "active" : ""} ${
  //                   pendingCompany?.isDefault ? "defaultUserAccount" : ""
  //                 }`}
  //                 style={{
  //                   backgroundImage: `url(${
  //                     !pendingCompany?.isDefault && pendingCompany.logo
  //                       ? backendUrl + pendingCompany.logo
  //                       : "assets/img/business-shape.png"
  //                   })`,
  //                 }}>
  //               </div>
  //               <div className="business-trend">
  //                 <p>{pendingCompany.companyName}</p>{" "}
  //                 <p className="designation">Designation</p>{" "}
  //               </div>
  //             </Popover.Body>
  //           </span>
  //       }
  //     </div>
  //     <Popover.Body className="dropdown-menu-inner add-Company-btn">
  //       <div className="company-add" onClick={handleAddShow}>
  //         <img src="assets/img/add_dashboard.svg" className="me-2" alt="" />
  //         <p>Add new business</p>
  //       </div>
  //     </Popover.Body>
  //   </Popover>
  // );

  return (
    <aside
      // bg-white
      className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 custom-scroll ${sidebarMobile ? "bg-white" : ""
        }`}
      id="sidenav-main">
      <div ref={overlayRef} className="overlayRefDiv"></div>
      <div
        className={`sidenav-header ${company && company.length === 0 ? "emptyheaderDesktop" : ""
          }`}>
        <img
          onClick={() => navigate(RoutesPaths.DASHBOARD)}
          src="assets/img/inproved-logo.svg"
          className="inproved-logo cursorPointer"
          alt="InProved"
        />
        {/* <i
          className="fas fa-times cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none icon p-2"
          aria-hidden="true"
          onClick={mobileMenuOpen}></i>
        <div className="navbar-brand m-0">
          {company &&
            company.length > 0 &&
            company.slice(0, 3).map((info, index) => (
              <div
                key={index}
                onClick={() => changeCompany(info.id)}
                className={`business-image ${
                  info.logo ? "hasLogo" : "hasText"
                } ${selectedCompany.id === info.id ? "active" : ""} ${
                  info?.isDefault ? "defaultUserAccount" : ""
                }`}
                style={{
                  backgroundImage: `url(${
                    !info?.isDefault ? backendUrl + info.logo : ""
                  })`,
                }}>
                {(!info.logo && !info?.isDefault)
                  ? info.companyName
                    ? info.companyName?.substring(0, 1)
                    : "-"
                  : ""}
              </div>
            ))}

          {company && company.length === 0 ? (
            <></>
          ) : (
            <OverlayTrigger
              placement="right"
              trigger="click"
              rootClose
              overlay={popover}
            >
              <div
                className="sidebar-dropdown"
                onClick={handleClickShowPopover}>
                <img
                  alt=""
                  id="dropdown-button-dark-example1"
                  src="assets/img/three-dots.svg"
                  className="navbar-brand-image"
                />
              </div>
            </OverlayTrigger>
          )}
        </div> */}
      </div>
      {showMenuSidebar && (
        <>
          <div
            className="collapse navbar-collapse w-auto display-account ps sidebaralign"
            id="sidenav-collapse-main">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
            <NavLink
              active={dashboardPath?.isExact}
              to={RoutesPaths.LANDING}
              className="nav-link"
            >
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/dashboard-icon.svg"
                  className="active-icon"
                  alt=""
                />
                <img
                  src="assets/img/dashboard-icon-active.svg"
                  className="normal-icon"
                  alt=""
                />
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </NavLink>
          </li> */}

              <li className="nav-item">
                {/* <NavLink
                  active={dashboardPath?.isExact}
                  to={RoutesPaths.DASHBOARD}
                  className="nav-link"
                > */}
                {/* <div
                  className="nav-link cursorPointer"
                  onClick={() => SetDetailsMenuToggle(!detailMenuToggle)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/detail-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/detail-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Details </span>
                  <span className="iconAngle">
                    <i
                      className={`fa-solid fa-angle-${
                        detailMenuToggle ? "down" : "right"
                      }`}></i>
                  </span>
                </div> */}
                {/* </NavLink> */}
                {/* {detailMenuToggle && (
                  <ul className="navbar-nav submenu-nav-item"> */}
                {/* <li className="nav-item" onClick={refreshPage}> */}
                {!isDefaultCompany &&
                  <NavLink
                    active={landingPath?.isExact}
                    to={RoutesPaths.LANDING}
                    onClick={refreshPage}
                    className="nav-link">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-house"></i>
                    </div>
                    <span className="nav-link-text ms-1">Home</span>
                  </NavLink>
                }
                <NavLink
                  active={dashboardPath?.isExact}
                  to={RoutesPaths.DASHBOARD}
                  onClick={refreshPage}
                  className="nav-link">
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/detail-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/detail-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">{isDefaultCompany ? "Home" : "Details"}</span>
                </NavLink>
                {/* </li> */}
                {/* {isDefaultCompany ? (
                      <li className="nav-item disabled-link-sidebar">
                        <div className="nav-link">
                          <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <i className="fas fa-solid fa-dollar-sign"></i>
                          </div>
                          <span className="nav-link-text ms-1">
                            Share/Paid-Up Capital
                          </span>
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <NavLink
                          active={shareCapitalPath?.isExact}
                          to={RoutesPaths.SHARECAPITAL}
                          className="nav-link">
                          <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <i className="fas fa-solid fa-dollar-sign"></i>
                          </div>
                          <span className="nav-link-text ms-1">
                            Share/Paid-Up Capital
                          </span>
                        </NavLink>
                      </li>
                    )} */}
                {/* </ul>
                )} */}
              </li>
              <li className="nav-item">
                <NavLink
                  active={productPath?.isExact}
                  to={RoutesPaths.PRODUCTS_LIST_PAGE}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/product-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/product-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Products</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  active={paymentPath?.isExact}
                  to={RoutesPaths.PAYMENT}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/transaction-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/transaction-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Transactions</span>
                </NavLink>
              </li>
              <li className="nav-item">
                {/* {isDefaultCompany ? (
                  <div className="nav-link disabled-link-sidebar">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="fas fa-solid fa-dollar-sign"></i>
                    </div>
                    <span className="nav-link-text ms-1">
                      Share/Paid-Up Capital
                    </span>
                  </div>
                ) : (
                  <NavLink
                    active={shareCapitalPath?.isExact}
                    to={RoutesPaths.SHARECAPITAL}
                    className="nav-link">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="fas fa-solid fa-dollar-sign"></i>
                    </div>
                    <span className="nav-link-text ms-1">
                      Share/Paid-Up Capital
                    </span>
                  </NavLink>
                )} */}
                {!isDefaultCompany && (
                  <NavLink
                    active={shareCapitalPath?.isExact}
                    to={RoutesPaths.SHARECAPITAL}
                    className="nav-link">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/paid-up-active.svg"
                        className="active-icon"
                        alt=""
                      />
                      <img
                        src="assets/img/paid-up-icon.svg"
                        className="normal-icon"
                        alt=""
                      />
                    </div>
                    <span className="nav-link-text ms-1">
                      Share/Paid-Up Capital
                    </span>
                  </NavLink>
                )}
              </li>
              {!isDefaultCompany && (
                <li className="nav-item">
                  <NavLink
                    active={businessDashboardPath?.isExact}
                    to={RoutesPaths.BOOK_OF_BUSINESS}
                    className="nav-link"
                    onClick={() => SetDetailsMenuToggle(false)}>
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/bob-active.svg"
                        className="active-icon"
                        alt=""
                      />
                      <img
                        src="assets/img/bob-icon.svg"
                        className="normal-icon"
                        alt=""
                      />
                    </div>
                    <span className="nav-link-text ms-1">Book of business</span>
                  </NavLink>
                </li>
              )}

              <li className="nav-item">
                <NavLink
                  active={invitePath?.isExact}
                  to={RoutesPaths.INVITE}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/invite-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/invite-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">
                    {isDefaultCompany ? "Invites" : "Invite Stakeholders"}
                  </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  active={filePath?.isExact}
                  to={RoutesPaths.FILE}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/files-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/files-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Files</span>
                </NavLink>
              </li>

              {!isDefaultCompany && (
                <li className="nav-item">
                  <NavLink
                    active={taskPath?.isExact}
                    to={RoutesPaths.TASK}
                    className="nav-link"
                    onClick={() => SetDetailsMenuToggle(false)}>
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/tasks-active.svg"
                        className="active-icon"
                        alt=""
                      />
                      <img
                        src="assets/img/tasks-icon.svg"
                        className="normal-icon"
                        alt=""
                      />
                    </div>
                    <span className="nav-link-text ms-1">Tasks</span>
                  </NavLink>
                </li>
              )}
            </ul>
            <hr className="hrlineSidebar" />

            <span className="sidebar-account mb-2 pt-2">Account pages </span>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  active={profilePath?.isExact}
                  to={RoutesPaths.PROFILE}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/profile-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/profile-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Profile</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  active={MessagePath?.isExact}
                  to={RoutesPaths.MESSAGE_DETAIL}
                  className="nav-link">
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/message-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/message-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Message</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  active={securityPath?.isExact}
                  to={RoutesPaths.SECURITY}
                  className="nav-link">
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/security-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/security-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Security</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  active={VerificationPath?.isExact}
                  to={RoutesPaths.VERIFICATION}
                  className="nav-link"
                  onClick={() => SetDetailsMenuToggle(false)}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/verify-active.svg"
                      className="active-icon"
                      alt=""
                    />
                    <img
                      src="assets/img/verify-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1">Verification</span>
                </NavLink>
              </li>
            </ul>
            <hr className="hrlineSidebar" />

            <ul className="navbar-nav">
              <li className="nav-item" onClick={logoutClick}>
                <span className="nav-link">
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/logout-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>

                  <span className="nav-link-text ms-1 logout-icon">
                    Log out
                  </span>
                </span>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={STATIC_EXTERNAL_URL.CUSTOMER_SERVICES}
                  target="_blank"
                  rel="noreferrer noopener nofollow">
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img
                      src="assets/img/help-icon.svg"
                      className="normal-icon"
                      alt=""
                    />
                  </div>
                  <span className="nav-link-text ms-1 logout-icon">Help</span>
                </a>
              </li>
              <li className="nav-item">
                <span className="nav-link cursor-pointer" onClick={() => dispatch(setGuideOpenAction(true))}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <img src="assets/img/userGuide.svg" alt="" />

                  </div>
                  <span className="nav-link-text ms-1 logout-icon">User guide</span>
                </span>
              </li>
            </ul>
          </div>
          {/* {isSubscribed !== null &&
            !isSubscribed &&
            selectedCompany?.subscriptionPlan?.isActive && (
              <div
                className="card  shadow-none bg-gradient-primary mt-5"
                id="sidenavCard"
              >
                <div className="full-background "></div>
                <div className="card-body text-start p-3 w-100">
                  <img className="shortFav" src="favicon.png" alt="favicon.png" />
                  <div className="docs-info">
                    <h6 className="text-white up mb-0">Upgrade your plan</h6>
                    <p className="text-white ">
                      Formalise your virtual business with flexible monthly or
                      annual plans
                    </p>
                    <span
                      onClick={handleShow}
                      className="btn btn-white btn-sm w-100 mb-0"
                    >
                      View Plans
                    </span>
                  </div>
                </div>
              </div>
            )
          } */}
        </>
      )}

      <AddModal
        addModalShow={addModalShow}
        addModalClose={addModalClose}
        setAddModalShow={setAddModalShow}
      />
      <Confirm show={confirmModal} onHide={logoutHide} />

      <PaymentModal
        modalShow={stripeModal}
        modalClose={modalClose}
        StripeModalShow={StripeModalConfirm}
      />
      <PaymentModalStripe
        modalShow={paymentStripeModal}
        modalClose={() => StripeModalConfirm(false)}
      />
    </aside>
  );
}
