import { parseISO, descendingComparator } from "date-fns";
import {
  GET_INVITE_DETAIL_BY_TOKEN,
  GET_PROMOTER_LIST,
  GET_PENDING_INVITES,
  GET_SKIP_INVITES,
  INCREMENT_CURRENT_INVITE,
  GET_MERGE_PROMOTER_LIST,
  SET_HOLD_NEXT_INVITE_TEMP,
  SET_HOLD_NEXT_INVITE_TEMP_DATA,
  IS_VALID_TOKEN,
} from "../actions/types";
const initialState = {
  promoters: [],
  inviteRequest: {},
  pendingInvites: [],
  mergerPromoter: [],
  skipInvites: localStorage.getItem("skipInviteModal")
    ? JSON.parse(localStorage.getItem("skipInviteModal"))
    : [],
  currentInvite: 0,
  inviteStepsTourEntry: [
    {
      content: "",
      placement: "",
      target: "inviteride",
    },
    {
      content: "Content here.",
      placement: "bottom",
      styles: { options: { width: 600 } },
      target: ".inviteSpotlightStep",
      title: "Invite",
    },
  ],
  enableInviteTourRide: false,
  holdNextInviteTemp: false,
  holdNextInviteTempData: null,
  isValidToken: true,
};

export default function (state = initialState, action) {
  const { type, payload, acceptedPromoters, invitePassed } = action;
  switch (type) {
    case GET_PROMOTER_LIST:
      return {
        ...state,
        promoters: payload,
      };

    case GET_INVITE_DETAIL_BY_TOKEN:
      return {
        ...state,
        inviteRequest: payload,
      };

    case GET_PENDING_INVITES:
      return {
        ...state,
        pendingInvites: payload,
      };

    case GET_SKIP_INVITES:
      return {
        ...state,
        skipInvites: payload,
      };

    case INCREMENT_CURRENT_INVITE:
      return {
        ...state,
        currentInvite: payload,
      };

    case SET_HOLD_NEXT_INVITE_TEMP:
      return {
        ...state,
        holdNextInviteTemp: payload,
      };

    case SET_HOLD_NEXT_INVITE_TEMP_DATA:
      return {
        ...state,
        holdNextInviteTempData: payload,
      };

    case GET_MERGE_PROMOTER_LIST:
      // Merge arrays
      const combinedArr = payload.concat(acceptedPromoters);
      combinedArr.sort((a, b) => parseISO(b.createdAt) - parseISO(a.createdAt));
      if (combinedArr?.length > 0) {
        combinedArr?.forEach(combinedItem => {
          // Find if there's a matching email in inviteResp
          const inviteItem = invitePassed?.find(invite => invite?.email === combinedItem?.email);

          // If a match is found, update the createdAt field
          if (inviteItem) {
            combinedItem.createdAt = inviteItem.createdAt;
          }
        });
      }

      return {
        ...state,
        mergerPromoter: combinedArr,
      };
    case IS_VALID_TOKEN:
      return {
        ...state,
        isValidToken: payload,
      };
    default:
      return state;
  }
}
