import {
  GET_SUGGESTED_PROFILE,
  LOAD_BOB_SKELETON,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
} from "./types";

import bookOfBusinessService from "../services/bookOfBusiness.service";

export const addBusinessProfile =
  (payload, id, limit, status) => (dispatch) => {
    return bookOfBusinessService
      .addBusinessProfile(payload, id)
      .then(async (resp) => {
        if (resp.status) {
          await dispatch(getBusinessProfile(id, 1, limit,  status));
          return resp;
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };

export const editBusinessProfile =
  (payload, id, companyId, limit, status) => (dispatch) => {
    return bookOfBusinessService
      .editBusinessProfile(payload, id, companyId)
      .then(async (resp) => {
        if (resp.status) {
          // if(!resp?.details?.isTemp)
            // await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          await dispatch(getBusinessProfile(id, 1, limit, status));
          return resp;
        } else {
          dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };

export const removeBobAction = (id, companyId, limit, status) => (dispatch) => {
  return bookOfBusinessService
    .removeBusinessProfile(id, companyId)
    .then(async (resp) => {
      if (resp.status) {
        await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        await dispatch(getBusinessProfile(companyId, 1, limit, status));
        return resp;
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
    });
}

export const getBusinessProfile =
  (companyId, page, limit, status) => async (dispatch) => {
    dispatch({ type: LOAD_BOB_SKELETON, payload: true })
    return bookOfBusinessService
      .getBusinessProfile(companyId, page, limit, "", status)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_SUGGESTED_PROFILE,
            payload: resp,
            count: resp.count,
          });
        } else {
          dispatch({ type: GET_SUGGESTED_PROFILE, payload: [], count: 0 });
        }
      });
  };

export const importBusinessProfileAction =
  (payload, id, onProgress, limit, status) => (dispatch) => {
    return bookOfBusinessService
      .importBusinessProfile(payload, id, onProgress)
      .then(async (resp) => {
        if (resp.status) {
          dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.type });
          if (id) dispatch(getBusinessProfile(id, 1, limit, status));
        }
        return resp;
      })
      .catch((err) => {
        return err;
      });
  };

export const updateBusinessProfileAction =
  (companyId, id, payload, limit, status) => (dispatch) => {
    return bookOfBusinessService
      .updateBusinessProfile(companyId, id, payload)
      .then(async (resp) => {
        if (resp.status) {
          await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          dispatch(getBusinessProfile(companyId, 1, limit, status));
          return resp;
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };
