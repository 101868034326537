import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsAction } from "../../actions/settings";
import { SUPPORTED_PAYMENT_METHOD } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import { useLocation, useNavigate } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {
  createManualEntryAction,
  setBankTranferBackTrigger,
} from "../../actions/stripe";
import Currency from "../../components/currency/currency";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
export default function BankDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location?.pathname?.split("/").filter((x) => x);
  const {
    stripePlans,
    paymentMethodSelection,
    savedbankInfoDetails,
    manualPaymentProceeding,
  } = useSelector((state) => state.stripe);
  const { bankTransferDetail, cryptoNetworkDetail } = useSelector(
    (state) => state.settings
  );
  const cryptoDetail =
    (cryptoNetworkDetail &&
      cryptoNetworkDetail.find(
        (x) => x.id.toLowerCase() === savedbankInfoDetails?.currency.value
      )) ||
    {};
  const { selectedCompany } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getSettingsAction());
  }, [dispatch]);

  useEffect(() => {
    const checkIfSubscriptionDetailsAvailable = () => {
      if (
        (stripePlans && Object.keys(stripePlans).length === 0) ||
        (paymentMethodSelection &&
          paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD) ||
        (savedbankInfoDetails && Object.keys(savedbankInfoDetails).length === 0)
      ) {
        navigate(RoutesPaths.PRODUCTS_LIST_PAGE);
      }
    };
    checkIfSubscriptionDetailsAvailable();
  }, [stripePlans, savedbankInfoDetails]);

  const handleCompleteForm = async () => {
    const resp = await dispatch(createManualEntryAction(savedbankInfoDetails));
    if (resp?.status) {
      setTimeout(() => {
        navigate(RoutesPaths.PAYMENT, {
          state: { formData: 0, forceUpdate: true },
        });
      }, 1000);
    } else {
      toast.error(resp?.data);
    }
  };

  return (
    <>
      <Header
        title="Payments"
        subtitle="Payments"
        mobileHeader={
          paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
            ? "Bank details"
            : paymentMethodSelection ===
              SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
            ? `InProved ${savedbankInfoDetails?.currency?.label} details`
            : "-"
        }
      />
      <div className="container-fluid py-4 bankPage paddingBottomMD">
        {pathnames[1] == "payment" && (
          <h4 className="header">
            {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
              ? "Bank details"
              : paymentMethodSelection ===
                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
              ? `InProved ${savedbankInfoDetails?.currency?.label} details`
              : "-"}
          </h4>
        )}
        <div className="outerRow" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="userDetail">
                {paymentMethodSelection ===
                SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ? (
                  <p className="header">
                    Provide the following information to your bank to initiate a
                    transfer
                  </p>
                ) : paymentMethodSelection ===
                  SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? (
                  <p className="header">
                    Provide the following information to your institution to
                    initiate a {savedbankInfoDetails?.currency?.label} transfer
                  </p>
                ) : (
                  ""
                )}
                <div className="submitSection innerDetail">
                  {paymentMethodSelection ===
                    SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER && (
                    <ul className="listHolder">
                      {/* <li className="copyTextHolder">
                        <p>Wire funds to</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.wireTransferTo || "-"}</p>
                          <CopyToClipboard text={bankTransferDetail?.wireTransferTo}>
                            <img onClick={() => toast.success("Copied!")} src="assets/img/copyText.svg" alt="" />
                          </CopyToClipboard>
                        </div>
                      </li> */}

                      <li className="copyTextHolder">
                        <p>Bank account number</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.bankAccountNumber || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.bankAccountNumber}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Beneficiary name</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.beneficiaryName || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.beneficiaryName}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Bank name</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.bankAccountName || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.bankAccountName}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>
                      <li className="copyTextHolder">
                        <p>Bank address</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.bankAccountAddress || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.bankAccountAddress}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>SWIFT / BIC Code</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.swiftBICCode || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.swiftBICCode}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Beneficiary address</p>
                        <div className="copyText">
                          <p>{bankTransferDetail?.beneficiaryAddress || "-"}</p>
                          <CopyToClipboard
                            text={bankTransferDetail?.beneficiaryAddress}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Payment reference (related to the business of)</p>
                        <div className="copyText">
                          <p>{selectedCompany?.companyName || "-"}</p>
                          <CopyToClipboard text={selectedCompany?.companyName}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>
                    </ul>
                  )}

                  {paymentMethodSelection ===
                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (
                    <ul className="listHolder">
                      <li className="copyTextHolder">
                        <p>Network</p>
                        <div className="copyText">
                          <p>{cryptoDetail?.network || "-"}</p>
                          <CopyToClipboard text={cryptoDetail?.network}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Address/domain name</p>
                        <div className="copyText">
                          <p>{cryptoDetail?.domainName || "-"}</p>
                          <CopyToClipboard text={cryptoDetail?.domainName}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Trust wallet</p>
                        <div className="copyText">
                          <p>{cryptoDetail?.trustWallet || "-"}</p>
                          <CopyToClipboard text={cryptoDetail?.trustWallet}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>

                      <li className="copyTextHolder">
                        <p>Payment reference(related to the business of)</p>
                        <div className="copyText">
                          <p>{selectedCompany?.companyName || "-"}</p>
                          <CopyToClipboard text={selectedCompany?.companyName}>
                            <img
                              onClick={() => toast.success("Copied!")}
                              src="assets/img/copyText.svg"
                              alt=""
                            />
                          </CopyToClipboard>
                        </div>
                      </li>
                    </ul>
                  )}

                  <div className="submitBtns">
                    <button
                      className="backButton backToBankMD"
                      onClick={() => [
                        navigate(-1),
                        dispatch(setBankTranferBackTrigger(true)),
                      ]}>
                      Back to{" "}
                      {paymentMethodSelection ===
                      SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                        ? "bank"
                        : "crypto"}{" "}
                      transfer form
                    </button>
                    <button
                      disabled={manualPaymentProceeding}
                      className="upgradeBtn CompleteMD"
                      onClick={handleCompleteForm}>
                      Complete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="bankDetailNotify">
                {paymentMethodSelection ===
                  SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER && (
                  <ul>
                    <li>
                      By clicking COMPLETE, you are notifying us of your intent
                      to transfer{" "}
                      <strong>
                        <Currency
                          currency={savedbankInfoDetails?.currency?.value}
                        />{" "}
                        <CurrencyFormat
                          currency={savedbankInfoDetails?.currency?.value}
                          value={parseFloat(savedbankInfoDetails?.amount)}
                          decimal={2}
                        />
                      </strong>{" "}
                      via Wire Transfer to pay for goods or services bought for{" "}
                      <strong>{selectedCompany?.companyName}</strong> and that
                      you agree with these{" "}
                      <strong>
                        <u>
                          <a
                            href="https://inproved.com/terms-of-service/"
                            target="_blank"
                            rel="noreferrer">
                            terms
                          </a>
                        </u>
                      </strong>
                      .
                    </li>
                    <li>
                      Note this instruction should only be used to pay for goods
                      or services in{" "}
                      <strong>
                        <Currency
                          currency={savedbankInfoDetails?.currency?.value}
                        />
                      </strong>
                      . It is critical that you provide your bank accurate
                      information when transferring funds. We are unable to
                      assume responsibility for improperly applied instructions.
                    </li>
                    <li>
                      Note also that this notification DO NOT move any funds.
                      You will need to physically execute this transfer process
                      to confirm this transaction and to procure the quoted
                      goods or services. Follow the instructions provided before
                      this page to complete the transaction.
                    </li>
                  </ul>
                )}
                {paymentMethodSelection ===
                  SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (
                  <ul>
                    <li>
                      By clicking COMPLETE, you are notifying us of your intent
                      to transfer{" "}
                      <strong>
                        {savedbankInfoDetails?.currency?.label}{" "}
                        {savedbankInfoDetails?.amount}
                      </strong>{" "}
                      via <strong>{cryptoDetail?.network}</strong> to pay for
                      paid-up capital, goods or services bought for{" "}
                      <strong>{selectedCompany?.companyName}</strong> and that
                      you agree with these{" "}
                      <strong>
                        <u>
                          <a
                            href="https://inproved.com/terms-of-service/"
                            target="_blank"
                            rel="noreferrer">
                            terms
                          </a>
                        </u>
                      </strong>
                      .
                    </li>
                    <li>
                      Note this instruction should only be used to pay for
                      paid-up capital, goods or services for{" "}
                      <strong>{selectedCompany?.companyName}</strong>. It is
                      critical that you provide your exchange or wallet provider
                      accurate information when transferring tokens. We are
                      unable to assume responsibility for improperly applied
                      instructions.
                    </li>
                    <li>
                      Note also that this notification DO NOT move any tokens.
                      You will need to physically execute this transfer process
                      to confirm this transaction and to procure the quoted
                      goods or services. Follow the instructions provided before
                      this page to complete the transaction.
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
