import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserTwoFactorAction } from "../../actions/user";
import toast from "react-hot-toast";
import { useState } from "react";
import AddressProof from "../verification/AddressProof";
import { VERIFICATION_TYPE } from "../../constants";

export default function Authentication() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [proofModalShow, setProofModalShow] = useState(false);
  const [proofModalType, setProofModalType] = useState(null);

  const enableTwoFactor = () => {
    if (user && user.areaCode && user.phone) {
      var payload = {
        twoFactor: !user.twoFactor
      }
      dispatch(updateUserTwoFactorAction(payload))
    } else {
      toast.error("Please enter phone number to your account enable 2-factor authentication.");
      setProofModalShow(true);
      setProofModalType(VERIFICATION_TYPE.MOBILE)
    }
  }
  return (
    <>
      <div className="card-header pb-0 p-3">
        <div className="row">
          <div className="col-md-9  align-items-center">
            <h6 className="mb-0">{user && user.twoFactor ? 'Disable' : 'Enable'} 2-factor authentication </h6>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        <p className="edit-description-adv">
          Keep your account safe and turn on two-factor authentication.
        </p>
        <button onClick={enableTwoFactor} type="button" className={`btn ${user && user.twoFactor ? 'disagree-button' : ''} agree-button`}>
          {user && user.twoFactor ? 'Disable' : 'Enable'}
        </button>
      </div>

      <AddressProof
        proofModalShow={proofModalShow}
        proofModalClose={() => [setProofModalShow(false), setProofModalType(null)]}
        proofModalType={proofModalType}
      />
    </>
  );
}
