import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { BOB_SERVICES, CONTACT_US_TRIGER, I_POP_OVER, PROFILE_ACTION, PROFILE_STATUS } from "../../constants";
import { Controller } from "react-hook-form";
import ProfileForm, { getPotentialPurpose } from "./ProfileForm";
import ContactInfo from "./ContactInfo";
import PremiumAccess from "./PremiumAccess";
import ImportFile from "./ImportFile";
import { removeBobAction, updateBusinessProfileAction } from "../../actions/bookOfBusiness";
import ConfirmMeeting from "./ConfirmMeeting";
import ContentLoader from "react-content-loader";
import InfoModalStatic from "../dashboard/infoModalStatic";
import DeleteBob from "./DeleteBob";
import ContactUs from "../../components/ContactUs/ContactUs";

export default function ProfileDetail({
  activePage,
  count,
  handlePageClick,
  businessProfiles,
  bookOfBusinessResponseService,
  control,
  limit,
  setStatus,
  profileTab,
  setPage,
  setActivePage,
  handleSlider,
}) {
  const dispatch = useDispatch();
  const { loadBob, temporaryBob } = useSelector((state) => state.bookOfBusiness);
  const { user } = useSelector((state) => state.user);
  const { isDefaultCompany, selectedCompany } = useSelector((state) => state.company);
  const [isEdit, setIsEdit] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [bobSelected, setBobSelected] = useState(null);

  const [premiumOpen, setPremiumOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [meetingOpen, setMeetingOpen] = useState(false);
  const [meetingRequest, setMeetingRequest] = useState(false);
  const [meetingDetailItem, setMeetingDetailsItem] = useState(null);
  const [removeBobModalShow, setRemoveBobModalShow] = useState(false)
  const [isTempBob, setIsTempBob] = useState(false)
  const [deleteBobData, setDeleteBobData] = useState();

  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  const handleImport = (value) => {
    if (value === "import") {
      setImportOpen(true);
    } else {
      setFormOpen(true);
    }
  };

  const handleForm = async (event, value, detail) => {
    if (value === "edit") {
      event && event.stopPropagation();
      setBobSelected(detail);
      setIsEdit(true);
      setFormOpen(true);
      setIsTempBob(false);
    } else {
      if (temporaryBob.length > 0) {
        setBobSelected(temporaryBob[0]);
        setIsEdit(true);
        setFormOpen(true);
        setIsTempBob(true);
      } else {
        handleImport("add");
        setIsEdit(false);
        setIsTempBob(false);
      }

    }
  };

  const handleContact = (event, value) => {
    event.stopPropagation();
    setContactOpen(value);
  };

  const handleConnect = async (item, value) => {
    const { action } = item;

    if (item?.isAdmin) return false

    var curerntAction = null;
    switch (action) {
      case PROFILE_ACTION.CONNECT:
        curerntAction = PROFILE_ACTION.REQUESTED;
        break;
      case PROFILE_ACTION.SCHEDULED:
        curerntAction = PROFILE_ACTION.REQUESTED;
        break;
      case PROFILE_ACTION.CONFIRM:
        if (value) curerntAction = PROFILE_ACTION.RESPOND;
        break;
      default:
        break;
    }
    if (curerntAction && curerntAction !== action) {
      await dispatch(
        updateBusinessProfileAction(
          item.company._id,
          item.id,
          {
            action: curerntAction,
          },
          limit,
          profileTab
        )
      );
    }
  };

  const actionToBeTaken = (item, index) => {
    const { action, status, isAdmin, eventDetails } = item;
    const makePublicCondition = ["Connect", "Schedule a meeting", "Confirm meeting"]
    var actionButton = null;
    switch (status) {
      case PROFILE_STATUS.SUGGESTED_PROFILES:
        actionButton =
          action === PROFILE_ACTION.CONNECT
            ? "Connect"
            : action === PROFILE_ACTION.REQUESTED
              ? "Requested"
              : action === PROFILE_ACTION.ACCEPT
                ? "Requested"
                : "Requested";
        break;
      case PROFILE_STATUS.WARM_PROFILES:
        actionButton =
          action === PROFILE_ACTION.SCHEDULED
            ? "Schedule a meeting"
            : action === PROFILE_ACTION.REQUESTED
              ? "Requested"
              : action === PROFILE_ACTION.ACCEPT
                ? "Requested"
                : "Requested";
        break;
      case PROFILE_STATUS.MEETING_REQUESTS:
        actionButton =
          action === PROFILE_ACTION.CONFIRM && eventDetails
            ? "Confirm meeting"
            : action === PROFILE_ACTION.CONFIRM && !eventDetails
              ? "Waiting for meeting details"
              : action === PROFILE_ACTION.RESPOND
                ? "Responded"
                : action === PROFILE_ACTION.REQUESTED
                  ? "Requested"
                  : action === PROFILE_ACTION.ACCEPT
                    ? "Requested"
                    : "Requested";
        break;
      default:
        break;
    }

    if (actionButton) {
      const disableButtonStatus = ['Requested', 'Responded', 'Waiting for meeting details']
      const disabledButton = disableButtonStatus.includes(actionButton) || isAdmin ? true : false;
      let icon = 'user-group';
      if (actionButton === "Confirm meeting" || actionButton === "Waiting for meeting details") {
        icon = 'calendar-days';
      } else if (actionButton === "Responded") {
        icon = 'check-double'
      }
      return (
        <>
          <button
            className={`${disabledButton ? 'contact disabled' : 'ConnectBtn'} ${((actionButton === 'Connect' || actionButton === 'Make Public') && index === 0) ? 'bobSpotlightEntryStep1' : ""}`}
            // disabled={disabledButton}
            type="button"
            onClick={!item?.isAdmin ? (status === PROFILE_STATUS.MEETING_REQUESTS && action === PROFILE_ACTION.CONFIRM
              ? () => handleSetMeetingDetails(item)
              : () => handleConnect(item)) : null
            }
          >
            <i className={`fa-solid fa-${icon}`}></i>&nbsp;&nbsp;
            {selectedCompany?.isDefault ? (
              makePublicCondition.includes(actionButton) ? 'Make Public' : 'Public'
            ) : (
              actionButton
            )}
          </button>
          {(status === PROFILE_STATUS.MEETING_REQUESTS && action === PROFILE_ACTION.RESPOND) && (
            <div className="edit fill-primary" onClick={() => handleSetMeetingDetails(item)}>
              <i className="fa-regular fa-handshake"></i>
            </div>
          )}
        </>
      );
    } else {
      return "";
    }
  };


  const showEditButton = (item) => {
    const acccepShowEdit = [
      PROFILE_ACTION.REQUESTED,
      PROFILE_ACTION.CONNECT,
      PROFILE_ACTION.SCHEDULED,
      PROFILE_ACTION.CONFIRM,
    ];
    const acccepShowEditStatus = [
      PROFILE_STATUS.SUGGESTED_PROFILES,
      PROFILE_STATUS.WARM_PROFILES,
      PROFILE_STATUS.MEETING_REQUESTS,
    ];
    return acccepShowEditStatus.includes(item.status) &&
      acccepShowEdit.includes(item.action)
      ? true
      : false;
  };


  const handleSetMeetingDetails = (item) => {
    if (item.eventDetails) {
      setMeetingOpen(true)
      setMeetingDetailsItem(item)
    }
  }

  const removeBobModalCloseConfirm = async () => {
    if (deleteBobData.id)
      await dispatch(removeBobAction(deleteBobData.id, deleteBobData.company._id, limit, profileTab));
    setRemoveBobModalShow(false);
  };

  return (
    <>
      <div className="profileFilterHeader">
        <span
          className={`sliderText ${profileTab === PROFILE_STATUS.SUGGESTED_PROFILES ? "active" : ""}`}
          onClick={() => handleSlider(PROFILE_STATUS.SUGGESTED_PROFILES)}
        >
          Suggested
          <span
            className="profiles">
            {businessProfiles?.profileCount?.suggested || 0}
          </span>
          <img
            src="assets/img/info-icon.svg"
            onClick={() => handleClickInfo(isDefaultCompany ? I_POP_OVER.DEFAULT_SUGGESTED_PROFILE : I_POP_OVER.COMPANY_SUGGESTED_PROFILE)}
            alt=""
            className="m-0 pb-1 cursorPointer"
          />
        </span>
        {!isDefaultCompany && (
          <>
            <span
              className={`sliderText ${profileTab === PROFILE_STATUS.WARM_PROFILES ? "active" : ""}`}
              onClick={() => handleSlider(PROFILE_STATUS.WARM_PROFILES)}
            >
              Warm profiles
              <span
                className="profiles">
                {businessProfiles?.profileCount?.warm || 0}
              </span>
              <img
                src="assets/img/info-icon.svg"
                onClick={() => handleClickInfo(I_POP_OVER.COMPANY_WARM_PROFILE)}
                alt=""
                className="m-0 pb-1 cursorPointer"
              />
            </span>
            <span
              className={`sliderText ${profileTab === PROFILE_STATUS.MEETING_REQUESTS ? "active" : ""}`}
              onClick={() => handleSlider(PROFILE_STATUS.MEETING_REQUESTS)}
            >
              Meeting requests
              <span className="profiles">
                {businessProfiles?.profileCount?.meeting || 0}
              </span>
              <img
                src="assets/img/info-icon.svg"
                onClick={() => handleClickInfo(I_POP_OVER.COMPANY_MEETING_PROFILE)}
                alt=""
                className="m-0 pb-1 cursorPointer"
              />
            </span>
          </>
        )}
        {selectedCompany && selectedCompany.id && (
          <div className="filterButtons marginLeftAutoMD">
            <ContactUs theme={"bobContactUS customImportButton"} type={CONTACT_US_TRIGER.BOB_SPEAK_TO_ADMIN} />
            <button
              onClick={(event) => handleImport("import")}
              type="button"
              className="customImportButton"
            >
              <img src="assets/img/downloadIcon.svg" alt="" />
              Import
            </button>
            <button
              onClick={(event) => handleForm(event, "add", {})}
              type="button"
              className="customFilterButton bobSpotlightStep1"
            >
              {isDefaultCompany ? "Suggest profile" : "Add profile"}
            </button>
          </div>
        )}
      </div>
      <div className="profileCard">
        <div className="profileCardBody">
          <div className="tableResponsive scrollBOB">
            <table className="profileTable">
              {/* {businessProfiles?.data?.length > 0 && ( */}
              <thead>
                <tr>
                  <th>Edit profile</th>
                  <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? <span className="spanSection">Full name/<br />Country</span> : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "Product or service name" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? "Company name and type" : ""}</th>
                  <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "Suggested by" : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "Suggested by" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? "One sentence summary" : ""}</th>
                  <th>Potential {bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "role" : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? "purpose" : ""}</th>
                  <th>Industry</th>
                  <th>Contact info.</th>
                  <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "LinkedIn profile URL" : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? "Landing page URL" : ""}</th>
                  <th>Action&nbsp;
                    <img
                      src="assets/img/info-icon.svg"
                      onClick={() => handleClickInfo(isDefaultCompany ? I_POP_OVER.BOOK_OF_BUSINESS_ACTION_INDIVIDUAL : I_POP_OVER.BOOK_OF_BUSINESS_ACTION_COMPANY)}
                      alt=""
                      className="m-0 pb-1 cursorPointer"
                    /></th>
                </tr>
              </thead>
              {/* )} */}
              <>
                {!loadBob ? (
                  <tbody>
                    {businessProfiles?.data?.length > 0 ? (
                      businessProfiles?.data?.map((item, index) => (
                        <tr
                          onClick={() =>
                            item?.isAdmin && setPremiumOpen(true)
                          }
                          key={index}
                        >
                          <td>
                            <div className="icon-flex-button">
                              {!item?.isAdmin && showEditButton(item) && (
                                <div
                                  onClick={() => { setDeleteBobData(item); setRemoveBobModalShow(true) }}
                                  className="edit delete"
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </div>
                              )}
                              {!item?.isAdmin && showEditButton(item) && (
                                <div
                                  onClick={(event) => handleForm(event, "edit", item)}
                                  className="edit"
                                >
                                  <i className="fa-solid fa-pen"></i>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <p>
                              {BOB_SERVICES.PROFILES === bookOfBusinessResponseService ? (item?.first_name ? item?.first_name : "") + " " + (item?.last_name ? item?.last_name : "") : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.companyProfile : item.serviceProductName) + " " + (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.companyType : "") : ""}
                            </p>
                            <p className="detail">{item?.country?.name}</p>
                          </td>
                          <td>
                            <p>{(item?.user?.first_name ? item?.user?.first_name : "") + " " + (item?.user?.last_name ? item?.user?.last_name : "") || "-"}</p>
                            {/* <p>{[BOB_SERVICES.PROFILES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.companyProfile : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "From Company / Simple description" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.description : ""}</p> */}
                            {/* <p className="detail">
                              {item.description}
                            </p> */}
                          </td>
                          <td>
                            <p className="role">
                              {item?.bookOfBusinessGroupId?.isDefault ? (
                                item?.customPotentialPurposeInput
                              ) : (
                                getPotentialPurpose(item)
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="role">{item.industry}</p>
                          </td>
                          <td>
                            <button
                              onClick={(event) => !item?.isAdmin ? handleContact(event, item) : undefined}
                              className={`contact ${item?.isAdmin ? 'disabled' : ""}`}
                              type="button"
                            >
                              Contact info
                            </button>
                          </td>
                          <td>
                            <p>
                              {/* masked class if ont an owner and not subscribed */}
                              <a
                                className={
                                  item?.isAdmin
                                    ? "disabled-link"
                                    : ""
                                }
                                target="_blank"
                                rel="noreferrer"
                                href={bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? item.linkedInUrl : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.landingPageUrl : ""}
                              >
                                {bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? item.linkedInUrl : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.landingPageUrl : ""}
                              </a>
                            </p>
                          </td>
                          <td>{actionToBeTaken(item, index)}</td>
                        </tr>
                      ))
                    ) : (
                      !loadBob && (
                        <tr className="d-flex">
                          <td className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 text-sm table-heading text-center fullWidth">
                              No record found
                            </h6>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {[9, 7, 6, 4, 8, 10, 9, 6, 7, 8, 5, 4, 6, 9, 7, 5, 8].map((item, screenIndex) => (
                      <tr key={screenIndex}>
                        <td colSpan="8">
                          <ContentLoader width={(item * 10) + '%'} height={20}>
                            <rect x="0" y="0" rx="10" ry="10" width="100%" height="20" />
                          </ContentLoader>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            </table>
          </div>
          {!loadBob && count > 0 && (
            <div className="tableBottom">
              <div className="filterButton">
                <div className="dropdownButton">
                  <Controller
                    name="limit"
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        className="select-border"
                        placeholder="10"
                      >
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </Form.Select>
                    )}
                  />
                </div>
              </div>
              <div>
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                  nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                  pageCount={count}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination customPagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={activePage}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <ProfileForm
        formOpen={formOpen}
        formClose={() => { setFormOpen(false); setIsTempBob(false); setBobSelected(null); }}
        companyDetail={selectedCompany}
        bobSelected={bobSelected}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        limit={limit}
        setStatus={setStatus}
        setPage={setPage}
        setActivePage={setActivePage}
        status={profileTab}
        isTempBob={isTempBob}
      // bookOfBusiness={bookOfBusiness}
      />

      <DeleteBob
        removeBobModalShow={removeBobModalShow}
        removeBobModalClose={() => setRemoveBobModalShow(false)}
        removeBobModalCloseConfirm={removeBobModalCloseConfirm}
        actionData={deleteBobData}
      />

      <ContactInfo
        bookOfBusinessResponseService={bookOfBusinessResponseService}
        contactOpen={contactOpen}
        contactClose={() => setContactOpen(false)}
      />
      <ImportFile
        importOpen={importOpen}
        importClose={() => setImportOpen(false)}
        companyDetail={selectedCompany}
        status={profileTab}
        limit={limit}
      />
      <PremiumAccess
        premiumOpen={premiumOpen}
        premiumClose={() => setPremiumOpen(false)}
      />

      <ConfirmMeeting
        meetingOpen={meetingOpen}
        meetingClose={() => setMeetingOpen(false)}
        handleConnect={handleConnect}
        selectedCompany={selectedCompany}
        item={meetingDetailItem}
      />

      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />


    </>
  );
}
