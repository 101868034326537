import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserGuideAction,
  postNewsLetterAction,
} from "../../actions/userGuide";
import { backendUrl } from "../../constants";
import ContentLoader from "react-content-loader";

export default function UserGuide({ guideOpen, guideClose }) {
  const dispatch = useDispatch();
  const [showSteps, setShowSteps] = useState(false);

  const handleClose = () => {
    setShowSteps(false);
    guideClose();
    setCurrentStep(0);
  };
  const { userGuide, userGuideFlag } = useSelector((state) => state.userGuide);

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (guideOpen) {
      const callUserGuideAction = () => {
        dispatch(getUserGuideAction());
      };
      callUserGuideAction();
    }
  }, [dispatch, guideOpen]);
  const [currentStep, setCurrentStep] = useState(0);
  const [newsLetter, setNewsLetter] = useState(false);

  const nextStep = () => {
    if (currentStep < showSteps?.innerSteps?.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNewsLetter = (event) => {
    setNewsLetter(true);
    if (event.target.checked) {
      dispatch(postNewsLetterAction());
    }
  };

  const handleBack = () => {
    setShowSteps(false);
    setCurrentStep(0);
  };
  return (
    <>
      <Modal className="guide-modal" show={guideOpen} onHide={handleClose}>
        <div className="guideHeader">
          <p>
            {!showSteps
              ? `Welcome back, ${user && user?.first_name
                ? user?.first_name + " " + user.last_name
                : ""
              }`
              : showSteps.label}
          </p>
          <i onClick={handleClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <div className="bodyHeader">
            {showSteps ? (
              <span onClick={handleBack}>
                <i className="fa-solid fa-arrow-left"></i> Back to user guide
              </span>
            ) : (
              <p>Since you left, we have new updates for you.</p>
            )}
          </div>
          <div className="guideBody customScrollBar">
            {showSteps ? (
              <div
                className={`${showSteps ? "stepsRow" : ""} guideContent row`}
              >
                {showSteps.innerSteps[currentStep].img && (
                  <div className="imgHolder col-md-6">
                    <img src={`${backendUrl + showSteps.innerSteps[currentStep].img}`}
                      alt={showSteps.innerSteps[currentStep].img}
                    />
                  </div>
                )}
                <div className={`contentHolder col-md-${showSteps.innerSteps[currentStep].img ? 6 : 12}`}>
                  <h5>Step {showSteps.innerSteps[currentStep].step}.</h5>
                  <div
                    className="stepDesc"
                    dangerouslySetInnerHTML={{
                      __html: showSteps.innerSteps[currentStep].desc,
                    }}
                  ></div>
                  <div className="arrowAlign">
                    <button
                      className="stepPagination"
                      onClick={prevStep}
                      disabled={currentStep === 0}
                    >
                      <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <button
                      onClick={nextStep}
                      className="stepPagination"
                      disabled={
                        currentStep === showSteps?.innerSteps?.length - 1
                      }
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!userGuideFlag ? (
                  userGuide?.length > 0 ? (
                    userGuide?.map((item, index) => (
                      <div key={index} className="guideContent row">
                        {item.img && (
                          <div className="imgHolder col-md-6">
                            <img
                              src={`${backendUrl + item.img}`}
                              alt={item.img}
                            />
                          </div>
                        )}
                        <div className={`contentHolder col-md-${item.img ? 6 : 12}`}>
                          <h5>{item.label}</h5>
                          <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: item.desc,
                            }}
                          ></div>
                          {item?.innerSteps?.length > 0 && (
                            <button
                              onClick={() =>
                                item?.isExternalUrl
                                  ? window.open(item.url)
                                  : setShowSteps(item)
                              }
                              className="btn btn-sm  mb-4 mt-3 contactus-modal-button articalBtn"
                            >
                              {item?.isExternalUrl ? "See article" : "View steps"}
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="m-0">No user guide available!</p>
                  )
                ) : (
                  [1, 2].map((index) => (
                    <div key={index} className="pt-4 pb-2 skeltonSectionGuide">
                      <span>
                        <ContentLoader width={"100%"} height={150}>
                          {/* Only SVG shapes */}
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="150"
                          />
                        </ContentLoader>
                      </span>
                      <span>
                        <ContentLoader width={"100%"} height={150}>
                          {/* Only SVG shapes */}
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="40"
                          />
                          <rect
                            x="0"
                            y="60"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="40"
                          />
                          <rect
                            x="0"
                            y="120"
                            rx="5"
                            ry="5"
                            width="30%"
                            height="40"
                          />
                        </ContentLoader>
                      </span>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
          <div className="bodyFooter form-check">
            <div className="inputHolderGuide">
              <input
                maxLength={50}
                autoComplete="nope"
                className="form-check-input m-0"
                onChange={handleNewsLetter}
                type="checkbox"
                id="newsletter"
                checked={newsLetter}
                disabled={newsLetter}
              />
              <label htmlFor="newsletter">
                I want feature updates sent to my email
              </label>
            </div>
            {/* <button className="userGuide border-0">submit</button> */}
          </div>
        </div>
      </Modal>
    </>
  );
}
