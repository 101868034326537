import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router";

export default function ESignPopup({
  embedShowPopup,
  modalClose,
  url
}) {

  return (
    <>
      <Modal
        show={embedShowPopup}
        onHide={modalClose}
        className="custom-modal"
        id="esignPopup"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={modalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              <iframe src={url} title="W3Schools Free Online Web Tutorials" width="100%" height="650"></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
