import React, { useEffect, useState } from 'react'
import PersonalAccountDetails from './components/personalAccountDetails'
import CashBalance from './components/cashBalance'
import BullionBalance from './components/bullionBalance'
import MetalAllocation from './components/metalAllocation'
import ProductAllocation from './components/productAllocation'
import ProfileLoss from './components/profileLoss'
import { useDispatch } from 'react-redux'
import { getUserDashboardAction } from '../../../actions/userDashboard'
import { sendMessageToAdmin } from '../../../actions/chat'
import { startLoaderAction, stopLoaderAction } from '../../../actions/updateReducerValue'
import { useNavigate } from 'react-router'
import AddDashboard from '../addDashboard'
import { isBrowser, isMobile } from 'react-device-detect';
import AllInvites from './components/allInvites'
import { getReferralListAction } from '../../../actions/referralInvites'


export default function BuillionAccount() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(null);
    const [adminLoader, setAdminLoader] = useState(null);

    useEffect(() => {
        dispatch(getReferralListAction(1));
        dispatch(getUserDashboardAction())
    }, [dispatch])

    useEffect(() => {
        setAdminLoader(null);
    }, [])



    const sendMessageToAdminfunc = async (type, message, loaderType) => {
        // dispatch(startLoaderAction());
        setAdminLoader(loaderType)
        await sendMessageToAdmin(message, null, type, navigate, []);
        // dispatch(stopLoaderAction());
    }


    return (
        <>
            {/* {isBrowser && <AddDashboard />} */}
            <PersonalAccountDetails isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
            {/* {isMobile
             && <AddDashboard />} */}
            <div className='row'>
                <div className='col-md-6'>
                    <CashBalance isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div>
                <div className='col-md-6'>
                    <BullionBalance isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div>

                <div className='col-md-6'>
                    <MetalAllocation isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div>
                {/* <div className='col-md-6'>
                    <ProductAllocation isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div> */}

                <div className='col-md-6'>
                    <ProfileLoss isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
                </div>
                <div className='col-md-6'>
                    <AllInvites sendMessageToAdmin={sendMessageToAdminfunc} adminLoader={adminLoader} />
                </div>
            </div>
        </>
    )
}
