import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { backendUrl, TASK_STATUS_ENUM } from "../../constants";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function CardModal({ item, taskCardShow, taskCardClose, editItem }) {
  return (
    <>
      <Modal className="custom-modal" show={taskCardShow} onHide={taskCardClose}>
        <div className="modal-header task-header p-0">
          <button type="button" className="btn-close text-dark close-button" onClick={taskCardClose}></button>
        </div>
        <div className="modal-body task-img-padding">
          <h5 className="edit-title">
            {item.title ? item.title : '-'}
            {item.status !== TASK_STATUS_ENUM.COMPLETE && (
              <button type="button" className="edit-button-task text-dark" onClick={() => editItem(item)}>
                Edit
              </button>
            )}
          </h5>
          <span className="business-activities">Task description</span>
          <p className="business-activities">
            {item.description ? item.description : '-'}
          </p>
          <hr />
          <div className="card-status">
            <div className="text-status">
              <p>Status</p>
              <span className={item.status === TASK_STATUS_ENUM.BACKLOG ? 'active' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'inprogress' : 'complete'}>{item.status === TASK_STATUS_ENUM.BACKLOG ? 'BACKLOG' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'In Progress' : 'Complete'}</span>
            </div>
            <div className="text-status">
              <p>Progress</p>
              <span className="">{item.progress ? item.progress : 0}%</span>
              <div className="progress task-progress">
                <div
                  className="progress-bar task-progress-color"
                  role="progressbar"
                  aria-valuenow={item.progress ? item.progress : 0}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: (item.progress ? item.progress : 0) + "%" }}
                ></div>
              </div>
            </div>
            <div className="text-status">
              <p>Due date</p>
              <span className="font-weight-bolder payment-text">
                {item?.dueDate ? (<TimeFormat time={item?.dueDate} type={1} />) : '-'}
              </span>
            </div>
            <div className="text-status">
              <p>{item.attachments && item.attachments.length > 0 ? item.attachments.length : 'No'} Attachments</p>
            </div>
            {item.attachments.length > 0 && (
              <div className="attachmentsItems">
                {item.attachments.map((attachItem, index) => (
                  <p key={index}>
                    <a href={backendUrl + attachItem.filename} target="_blank" rel="noreferrer">{attachItem.originalName}</a></p>
                ))}
              </div>
            )}
          </div>
          {/* <div className="upload-here-img-task">
            <div className="img-width-task">
              <img
                src="assets/img/upload-cloud.png"
                className="upload-task"
                alt=""
              />
              <label className="drop-text">
                click to browse or drag and drop files here
                <input type="file" hidden name="" id="img-dropp" />
              </label>
            </div>
          </div> */}
        </div>
      </Modal>
    </>
  );
}
