import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  TWO_FACTOR_LOGIN_SUCCESS,
  SET_VERIFICATION_EMAIL,
  STOP_LOADER,
  START_LOADER,
  INIT_POPUP,
  DESTROY_SESSION,
  TOGGLE_MOBILE_MENU,
  CLEAR_NOTIFICATION,
  TOGGLE_MENU_SIDEBAR,
  TWILIO_TOKEN,
  RESET_PASSWORD_RESPONSE,
  SET_RESET_PASSWORD_VERIFICATION_EMAIL,
  TOGGLE_ASK_ADDA,
  USER_GUIDE_POPUP,
  USER_GUIDE_NAME,
  USER_GUIDE_TYPE,
  SET_LOGIN_EMAIL_TEMPORARILY,
  SET_COMPANY_MENU_ACTION,
  SET_COMPANY_ADD_SHOW_ACTION
} from "./types";
import AuthService from "../services/auth.service";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";
import { clearPromoterInviteModal } from "./promoters";
import { ZendeskAPI } from "react-zendesk";
import CryptoJS from "crypto-js";
import { MESSAGE_TYPE, secretPass } from "../constants";
import { enableTourRideAction, setNotificationBanner } from "./company";
import { sendMessageToAdmin } from "./chat";
import { BroadcastChannel } from 'broadcast-channel';
import { RoutesPaths } from "../Router/constant";
export const logoutChannel = new BroadcastChannel('logout');
export const loginChannel = new BroadcastChannel('login');

export const signup = (payload) => (dispatch) => {
  return AuthService.signup(payload).then(
    (resp) => {
      if (resp.status) {
        if (payload?.email) {
          var setPayload = { "email": payload?.email, "rememberme": true, "type": 2 }
          setLocalStorageRememberMe(setPayload);
        }
        dispatch(setLogin(resp.data))
      } else {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data, });
      }
      return Promise.resolve(resp);
    },
    // (error) => {
    //   const message =
    //     (error.response &&
    //       error.response.data &&
    //       error.response.data.message) ||
    //     error.message ||
    //     error.toString();
    //   dispatch({
    //     type: REGISTER_FAIL,
    //   });
    //   dispatch({
    //     type: SET_FAILED_MESSAGE,
    //     payload: message,
    //   });
    //   return Promise.reject();
    // }
  );
};

export const login = (payload) => (dispatch) => {
  dispatch(startLoaderAction())
  return AuthService.login(payload).then(
    (resp) => {
      if (resp.status) {
        if (resp.data.twoFactor) {
          if ('token' in resp.data) {
            localStorage.setItem('twoFactorToken', resp.data.token)
          }
          dispatch({ type: TWO_FACTOR_LOGIN_SUCCESS, payload: resp.data })
        } else {
          dispatch(setLogin(resp.data))
        }
        setLocalStorageRememberMe(payload);
        if (localStorage.getItem("refToken")) {
          localStorage.removeItem("refToken");
        }
      } else {
        dispatch({ type: LOGIN_FAIL, });
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data, });
      }
      dispatch(stopLoaderAction())
      return Promise.resolve();
    },
    // (error) => {
    //   const message =
    //     (error.response &&
    //       error.response.data &&
    //       error.response.data.message) ||
    //     error.message ||
    //     error.toString();
    //   dispatch({
    //     type: LOGIN_FAIL,
    //   });
    //   dispatch({
    //     type: SET_FAILED_MESSAGE,
    //     payload: message,
    //   });
    //   return Promise.reject();
    // }
  );
};

export const socialLogin = (payload) => (dispatch) => {
  return AuthService.socialLogin(payload).then(
    (resp) => {
      if (resp.status) {
        if (resp.data.twoFactor) {
          if ('token' in resp.data) {
            localStorage.setItem('twoFactorToken', resp.data.token)
          }
          dispatch({ type: TWO_FACTOR_LOGIN_SUCCESS, payload: resp.data })
        } else {
          dispatch(setLogin(resp.data))
        }
      } else {
        dispatch({ type: LOGIN_FAIL, });
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data, });
      }
      return Promise.resolve();
    },
  );
};

export const clearFly = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    // await ZendeskAPI("messenger", "logoutUser");
    localStorage.removeItem("selectedCompany");
    localStorage.removeItem("token");
    localStorage.removeItem("kimbocorp");
    dispatch({ type: DESTROY_SESSION });
    dispatch({ type: LOGOUT, });
    resolve(true)
  })
}

export const logout = (event) => (dispatch) => {
  return new Promise((resolve, reject) => {
    AuthService.logout().then(async (resp) => {
      if (resp.status) {
        const respLogout = await dispatch(logoutInvalidToken())
        resolve(respLogout)
      } else {
        resolve(false)
      }
    });
  })
};


export const logoutInvalidToken = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    // await ZendeskAPI("messenger", "logoutUser");
    // if (event === 'logout') {
    logoutChannel.postMessage("logout")
    // }
    localStorage.removeItem("kimbocorp");
    localStorage.removeItem("token");
    localStorage.removeItem("selectedCompany");
    dispatch({ type: DESTROY_SESSION });
    dispatch({ type: LOGOUT, });
    dispatch(clearPromoterInviteModal());
    // dispatch({ type: CHANGE_PERMISSION_UPDATES, payload: false })
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: "Successfully logged out", });
    setTimeout(() => {// window.location.reload()}, 500);
      resolve(true)
    });
  })

};

export const verifiedOTP = (resp) => (dispatch) => {
  dispatch(setLogin(resp))
}

const setLocalStorage = (data) => {
  if (data && data.token) {
    localStorage.setItem("kimbocorp", JSON.stringify(data));
    localStorage.setItem("token", data.token);
    // localStorage.setItem("zendeskToken", data.zendeskToken);
  }
}

const setLocalStorageRememberMe = (payload) => {
  if (payload.rememberme) {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(payload.password),
      secretPass
    ).toString();
    payload.password = data;
    localStorage.setItem("credential", JSON.stringify({ payload }));
  } else
    localStorage.removeItem("credential");
}

const setLogin = (data) => async (dispatch) => {
  if (data && data.token) {
    // loginChannel.postMessage('login')
    // await dispatch(logoutAllTabs())
    // loginChannel.postMessage("login")
    // dispatch({ type: USER_GUIDE_POPUP, payload: true });
    await dispatch(clearPromoterInviteModal());
    setLocalStorage(data)
    dispatch({ type: LOGIN_SUCCESS, payload: { user: data }, });
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: "Successfully logged in", });
  }
}

export const verifyEmailAction = (token) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return await AuthService.verifyEmail(token).then((resp) => {
    if (resp?.status && resp?.email) {
      var setPayload = { "email": resp?.email, "rememberme": true, "type": 2 }
      setLocalStorageRememberMe(setPayload);
    }
    setTimeout(() => {
      dispatch({ type: SET_VERIFICATION_EMAIL, payload: resp });
      dispatch({ type: STOP_LOADER });
    }, 1500);
  });
};


export const bidAskAdminMessage = () => async (dispatch) => {
  const bidAsk = localStorage.getItem('bidAskResult') ? JSON.parse(localStorage.getItem('bidAskResult')) : null
  if (bidAsk) {
    await sendMessageToAdmin(bidAsk, null, MESSAGE_TYPE.BIDASK, null, []);
  }
}


export const adminLoginVerifyAction = (payload) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  if('company' in payload && payload.company){
    localStorage.setItem("selectedCompany", payload.company);
  }else{
    localStorage.removeItem("selectedCompany");
  }
  dispatch({ type: DESTROY_SESSION });
  dispatch({ type: LOGOUT, });
  return await AuthService.adminLoginVerify(payload).then((resp) => {
    dispatch({ type: STOP_LOADER });
    if (resp.status) {
      dispatch(setLogin(resp.data))
    }
  });
};

export const iniPopupFunction = (value) => async (dispatch) => {
  dispatch({ type: INIT_POPUP, payload: value });
};


export const toggleMobileMenu = () => async (dispatch) => {
  dispatch({ type: TOGGLE_MOBILE_MENU });
};


export const clearAlertAction = (payload) => async (dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATION, payload: payload });
  dispatch(setNotificationBanner({}))
}
export const toggleMenuSidebar = (value) => async (dispatch) => {
  dispatch({ type: TOGGLE_MENU_SIDEBAR, payload: value });
};

export const setAskOpen = (value) => async (dispatch) => {
  dispatch({ type: TOGGLE_ASK_ADDA, payload: value });
};

export const twilioTokenAction = (value) => async (dispatch) => {
  dispatch({ type: TWILIO_TOKEN, payload: value });
};


export const forgotPasswordAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  return await AuthService.forgotPassword(payload).then((resp) => {
    dispatch(stopLoaderAction());
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data, });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data, });
    }
    return Promise.resolve(resp);
  });
};

export const forgotPasswordVerifyTokenAction = (payload) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_RESPONSE, payload: null });
  dispatch(startLoaderAction());
  return await AuthService.forgotPasswordVerifyToken(payload).then((resp) => {
    dispatch(stopLoaderAction());
    dispatch({ type: SET_RESET_PASSWORD_VERIFICATION_EMAIL, payload: resp.status });
    if (resp.status) {
      dispatch({ type: RESET_PASSWORD_RESPONSE, payload: resp.data.id });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve(resp);
  });
};

export const resetPasswordAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  return await AuthService.resetPassword(payload).then((resp) => {
    dispatch(stopLoaderAction());
    if (resp.status) {
      dispatch({ type: RESET_PASSWORD_RESPONSE, payload: null });
      dispatch({ type: SET_RESET_PASSWORD_VERIFICATION_EMAIL, payload: null });
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data, });
    }
    return Promise.resolve(resp);
  });
};

export const verifyAuthTokenAction = () => async (dispatch, getState) => {
  AuthService.verifyAuthToken().then(async (resp) => {
    dispatch(bidAskAdminMessage())
    if (resp.status === false && resp.data === "Invalid Token") {
      // Access the dispatch function
      if (getState()?.auth?.isLoggedIn && getState()?.auth?.isLoggedIn, getState()?.auth?.token) {
        dispatch(logoutInvalidToken())
      }
    }
  });
};

export const logoutAllTabs = () => async (dispatch) => {
  logoutChannel.onmessage = async (event) => {
    // await dispatch(logout(event));
    window.location.reload()
    logoutChannel.close();
  }

  loginChannel.onmessage = async (event) => {
    window.location.reload()
    loginChannel.close();
  }
}

export const setGuideOpenDefaultAction = (value) => async (dispatch) => {
  dispatch({ type: USER_GUIDE_POPUP, payload: value });
}

export const setGuideOpenAction = (value, guideType) => async (dispatch) => {
  dispatch({ type: USER_GUIDE_TYPE, payload: guideType });
  dispatch({ type: USER_GUIDE_POPUP, payload: value });
  if (value === false) {
    dispatch(enableTourRideAction(true))
  }
};

export const getUserEmailFromTokenAction = (token, navigate) => async (dispatch) => {
  return AuthService.getUserEmailFromTokenID(token).then(async (resp) => {
    if (resp?.status) {
      dispatch({ type: SET_LOGIN_EMAIL_TEMPORARILY, payload: resp?.data?.email });
      navigate(RoutesPaths.HOME)
    }
  })

}

export const showCompanyMenuAction = (payload) => (dispatch) => {
  dispatch({ type: SET_COMPANY_MENU_ACTION, payload: payload });
}

export const showCompanyAddAction = (payload) => (dispatch) => {
  dispatch({ type: SET_COMPANY_ADD_SHOW_ACTION, payload: payload });
}