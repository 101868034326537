import { HOLDERS, HOLDERS_PAGE_TYPE } from "../constants";
import shareholderService from "../services/shareholder.service";
import { addPromoterAction, getPendingInvites } from "./promoters";
import {
  GET_SHAREHOLDER_LIST,
  LOAD_SHAREHOLDER_SKELETON,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
} from "./types";

export const getShareHolderList = (companyId) => async (dispatch) => {
  dispatch({ type: LOAD_SHAREHOLDER_SKELETON, payload: true })
  return await shareholderService.getShareholder(companyId).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SHAREHOLDER_LIST, payload: resp.data, pendingInviteList: resp.pendingInviteList });
    } else {
      dispatch({ type: GET_SHAREHOLDER_LIST, payload: [], pendingInviteList: [] });
    }
  });
};

export const addShareAction = (payload, pageType) => (dispatch) => {
  dispatch({ type: LOAD_SHAREHOLDER_SKELETON, payload: true })
  return shareholderService.addShareholder(payload).then(async (resp) => {
    if (resp.status) {
      await dispatch(addPromoterAction(payload.company, payload));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    // if (pageType === HOLDERS_PAGE_TYPE.INVITES) {
    //   dispatch(getPendingInvites(payload.company, HOLDERS.shareHolders));
    // }else{
    dispatch(getShareHolderList(payload.company));
    // }
  });
};

export const updateShareAction =
  (companyId, shareHolderId, payload) => (dispatch) => {
    return shareholderService
      .updateShareholder(shareHolderId, payload)
      .then((resp) => {
        if (resp.status) {
          if (companyId) dispatch(getShareHolderList(companyId));
          dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        } else {
          dispatch({
            type: SET_FAILED_MESSAGE,
            payload: "Something wen't wrong",
          });
        }
      });
  };

export const removeShareHolder = (companyId, payload) => (dispatch) => {

  return shareholderService.deleteShareholder(payload).then((resp) => {
    if (resp.status) {
      dispatch(getShareHolderList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const appointShareholderAction = (companyId, payload) => (dispatch) => {
  return shareholderService.appointShareholder(payload).then((resp) => {
    if (resp.status) {
      dispatch(getShareHolderList(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const getShareHolderPendingList = (companyId, payload) => (dispatch) => {
  return shareholderService
    .pendingInviteShareholders(companyId, payload)
    .then((resp) => {
      if (resp.status) {
        dispatch({ type: GET_SHAREHOLDER_LIST, payload: resp.data, pendingInviteList: [] });
      } else {
        dispatch({ type: GET_SHAREHOLDER_LIST, payload: [], pendingInviteList: [] });
      }
    });
};

export const deleteShareholderInvite = (companyId, payload) => (dispatch) => {
  return shareholderService.deleteInvite(companyId, payload).then((res) => {
    // dispatch(getShareHolderPendingList(companyId, 2))
    dispatch(getShareHolderList(companyId));
    if (res.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: res.data })
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: res.data })
    }
  })
}

export const addShareholderChannels = (payload) => (dispatch) => {
  return shareholderService.addShareholderChannels(payload).then((res) => {
    if (res.status) {
      dispatch({ SET_SUCCESS_MESSAGE, payload: res.data })
    } else {
      dispatch({ SET_FAILED_MESSAGE, payload: res.data })
    }
  })
}
