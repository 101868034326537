import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "../../actions/auth";
import { RoutesPaths } from "../../Router/constant";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    dispatch(forgotPasswordAction(data))
  };

  return (
    <>
      <section className="min-vh-100 mb-8 signup-back">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 border-radius-lg"
          style={{ backgroundImage: "url(assets/img/kimbocorp-site-signup.png)" }}
        >
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-5 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0 card-login">
                <div className="card-header text-center pt-4">
                  <h5>Forgot your holding company password?</h5>
                </div>
                <div className="row text-center mx-auto">
                  <div className="mt-2 position-relative text-center">
                    <p className="text-sm mb-2 text-secondary text-border d-inline z-index-2 bg-white">
                      Enter the email address associated with your holding company account.
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <input
                        maxLength={50}
                        autoComplete="nope"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="errorMessage">Email is invalid</span>
                      )}
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-gradient-info w-100 my-4 mb-2"
                      >
                        Forgot password
                      </button>
                    </div>
                    <p className="text-sm mt-3 mb-0">
                      Already have an account?{" "}
                      <Link to={RoutesPaths.HOME} className="text-dark font-weight-bolder">
                        Sign in
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
