export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #ccc',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    color: state.isSelected ? '#fff' : '#333',
    backgroundColor: state.isSelected ? '#007bff' : '#fff',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    font: '12px',
  }),
  control: () => ({
    // width: 100,
    border: 'none', // Remove border
    boxShadow: 'none', // Remove box shadow
    backgroundColor: 'transparent', // Make background transparent
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'inline-flex',
    color: '#fff', // Change arrow color based on focus state
    '&:hover': {
      color: '#fff', // Change arrow color on hover
    },
    padding: '0px'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
};