import React from "react";
import { Modal } from "react-bootstrap";
import EditProfile from "../../components/EditProfile/EditProfile";
import { VERIFICATION_TYPE } from "../../constants";

export default function AddressProof({ proofModalShow, proofModalClose, proofModalType }) {
  return (
    <>
      <Modal
        show={proofModalShow}
        onHide={proofModalClose}
        className="otp_modal address_modal"
      >
        <div className="modal-header p-0">
          <button onClick={proofModalClose} type="button" className="btn-close text-dark close-button"></button>
        </div>

        <Modal.Header>
          <h3 className="font-weight-bolder text-info text-gradient">
            {proofModalType === VERIFICATION_TYPE.MOBILE ? (
              "Enter your mobile number"
            ) : proofModalType === VERIFICATION_TYPE.ADDRESS ? (
              "Upload your proof of address"
            ) : proofModalType === VERIFICATION_TYPE.IDENTITY ? (
              "Upload your proof of identity"
            ) : ""}
          </h3>
        </Modal.Header>

        <EditProfile proofModalType={proofModalType} triggerProofModalClose={proofModalClose} />

      </Modal>
    </>
  );
}
