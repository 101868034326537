import { USER_PROFILE, SET_USER_PROFILE, GET_USER_ACTIVITY, GET_BID_GOLD_PRICE, SET_BID_GOLD_HEADER_CURRENCY } from "../actions/types";
import { CURRENCY, CURRENCY_NAME, GOLD_BID_PRICE_CONST_KEY, SCORE_LIST } from "../constants";
const initialState = {
  user: {},
  activity: [],
  activityCount: 0,
  bidGoldHeaderCurrency: CURRENCY_NAME.find(x => x.value === CURRENCY.USD),
  bidGoldPrice: {},
  bidGoldHeader: {},
  lockGoldPrice: {}
};
export default function (state = initialState, action) {
  const { type, payload, price, lockGoldPrice } = action;
  switch (type) {
    case USER_PROFILE:
      let score = 0;
      if (payload && payload.id) {
        for (const [key, value] of Object.entries(SCORE_LIST)) {
          if (key in payload && payload[key]) {
            score = score + value
          }
        }
      }
      return {
        ...state,
        user: { ...payload, currentScore: score },
      };

    case SET_USER_PROFILE:
      const newArray = { ...state.user }; //making a new array
      newArray[payload.fieldName] = payload.value
      return {
        ...state,
        user: newArray,
      };

    case GET_USER_ACTIVITY:
      return {
        ...state,
        activity: (payload && payload.count > 0 && payload.data.length > 0) ? payload.data : [],
        activityCount: (payload && payload.count) ? payload.count : 0,
      };

    case GET_BID_GOLD_PRICE:
      return {
        ...state,
        bidGoldPrice: payload,
        lockGoldPrice: lockGoldPrice
      };

    case SET_BID_GOLD_HEADER_CURRENCY:
      const bidHeaderPayload = state.bidGoldPrice
      let bidHeaderLocal = {}
      const goldHeaderArray = [GOLD_BID_PRICE_CONST_KEY.GOLD1KG, GOLD_BID_PRICE_CONST_KEY.GOLD1G, GOLD_BID_PRICE_CONST_KEY.GOLD1KGHEDGE]
      for (const property in bidHeaderPayload) {
        if(goldHeaderArray.includes(property)){
          bidHeaderLocal[property] = {
            id: bidHeaderPayload[property].id,
            name: bidHeaderPayload[property].name,
            smallDescription: bidHeaderPayload[property].smallDescription,
            price: price[property] ?? (bidHeaderPayload[property].price ?? 0),
            currency: {value: payload?.value, name: payload?.value.toUpperCase()},
            inflation: bidHeaderPayload[property].inflation || false,
          }
        }
      }
      return {
        ...state,
        bidGoldHeaderCurrency: payload,
        bidGoldHeader: bidHeaderLocal,
      };

    default:
      return state;
  }
}
