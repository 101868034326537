import axionIns from "../axios";

const addChannel = (payload) => {
  return axionIns
    .post("chat/initiatechat", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const getChannelList = (companyId) => {
  return axionIns.get("chat/channellist/" + companyId).then((response) => {
    return response.data;
  });
};


const getTwilioToken = (string, id) => {
  let url = `user/${string}twiliotoken`;
  if(id) {
    url = `${url}?channel_admin_id=${id}`;
  }
  return axionIns.get(url).then((response) => {
    return response.data
  })
}

const updateChannel = (obj) => {
  return axionIns.post("chat/updatechannel", JSON.stringify(obj)).then((response) => {
    return response.data
  })
}


const refreshChatReadStatus = (channelId, obj) => {
  return axionIns.post("chat/refreshChatRoom/"+channelId, JSON.stringify(obj)).then((response) => {
    return response.data
  })
}

const createNewMembers = (members) => {
  return axionIns.post("chat/createMembers", JSON.stringify(members)).then((response) => {
    return response.data
  })
}

const getChatMedia = (payload) => {
  return axionIns.get("chat/getConversationMedia/" + payload).then((response) => {
    return response.data;
  });
}

const addChannelParticipant = (payload) => {
  return axionIns.put("/chat/addparticipant", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const removeChannelParticipant = (payload) => {
  return axionIns.put("/chat/removeparticipant", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const changeChannelName = (payload) => {
  return axionIns.put("/chat/changegroupname", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}


const chatMediaGet = async (channelId) => {
  return axionIns.get(`/chat/chatmedia/${channelId}`).then((response) => {
    return response.data;
  });
}

const chatMediaAdd = async (payload) => {
  return axionIns.post("/chat/chatmedia", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const exportObject = {
  addChannel,
  getChannelList,
  getTwilioToken,
  updateChannel,
  refreshChatReadStatus,
  createNewMembers,
  getChatMedia,
  addChannelParticipant,
  removeChannelParticipant,
  changeChannelName,
  chatMediaGet,
  chatMediaAdd
};
export default exportObject;
