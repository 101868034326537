import objectiveService from "../services/objective.service";
import {
  GET_OBJECTIVE,
} from "./types";

export const getObjectiveAction =
  (page, perPage) => (dispatch) => {
    return objectiveService
      .getObjective(page, perPage)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_OBJECTIVE,
            payload: resp.data,
          });
        } else {
          dispatch({ type: GET_OBJECTIVE, payload: [] });
        }
      });
  };