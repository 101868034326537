import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountSetting from "../pages/accountsetting/accountSetting";

import Dashboard from "../pages/dashboard/dashboard";
import Files from "../pages/files/files";
import InviteAccept from "../pages/inviteaccept";
import Invites from "../pages/invites";
import Landing from "../pages/landing/landing";
import Payment from "../pages/payment";
import Profile from "../pages/profile";
import Security from "../pages/security/security";
import ShareCapital from "../pages/sharecapital/shareCapital";
import SignIn from "../pages/signin/";
import SignUp from "../pages/signup/signUp";
import Task from "../pages/task";
import Verification from "../pages/verification";
import AdminLoginVerification from "../pages/admin-login-verification";
import VerifyEmail from "../pages/verify-email";
import { RoutesPaths } from "./constant";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import MessageDetail from "../pages/chatPage/messageDetail";
import ForgotPassword from "../pages/forgot-password/forgorPassword";
import ResetPassword from "../pages/resetPassword/resetPassword";
import ESign from "../pages/e-sign/Esign";
import SuccessSignNow from "../pages/e-sign/SuccessSignNow";
import SuggestedProfiles from "../pages/businessDashboard/SuggestedProfiles";
import { useSelector } from "react-redux";
import BankTransfer from "../pages/payment/BankTransfer";
import BankDetail from "../pages/payment/BankDetail";
import OldPayment from "../pages/payment/OldPayment";
import MyInfo from "../pages/MyInfo/MyInfo";
import ProductPage from "../pages/payment/ProductPage";
import Products from "../pages/payment/Products";
import Productlanding from "../pages/product-landing-page/Productlanding";
import Thankyou from "../pages/product-landing-page/Thankyou";
import Home from "../pages/dashboard/home";
import MakePayment from "../pages/payment/MakePayment";

export default function RouteComponent() {
  const { isDefaultCompany } = useSelector((state) => state.company);
  return (
    <>
      <Routes basename={'app'}>
        <Route path={RoutesPaths.SIGNIN} element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route exact path={RoutesPaths.INVITE_ACCEPT} element={<PublicRoute><InviteAccept /></PublicRoute>} />
        <Route exact path={RoutesPaths.SIGNUP} element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route exact path={RoutesPaths.FORGOT_PASSWORD} element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route exact path={RoutesPaths.RESET_PASSWORD} element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path={RoutesPaths.OLD_PAYMENT} element={<PrivateRoute><OldPayment /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PAYMENT} element={<PrivateRoute><Payment /></PrivateRoute>} />
        {!isDefaultCompany && (
          <>
            <Route exact path={RoutesPaths.SHARECAPITAL} element={<PrivateRoute><ShareCapital /></PrivateRoute>} />
            <Route exact path={RoutesPaths.TASK} element={<PrivateRoute><Task /></PrivateRoute>} />
          </>
        )}
        <Route exact path={RoutesPaths.MYINFOCALLBACK} element={<PublicRoute><MyInfo /></PublicRoute>} />
        <Route exact path={RoutesPaths.INVITE} element={<PrivateRoute><Invites /></PrivateRoute>} />
        <Route exact path={RoutesPaths.FILE} element={<PrivateRoute><Files /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PROFILE} element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route exact path={RoutesPaths.ACCOUNTSETTING} element={<PrivateRoute><AccountSetting /></PrivateRoute>} />
        <Route exact path={RoutesPaths.SECURITY} element={<PrivateRoute><Security /></PrivateRoute>} />
        <Route exact path={RoutesPaths.VERIFICATION} element={<PrivateRoute><Verification /></PrivateRoute>} />
        <Route exact path={RoutesPaths.LANDING} element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BOOK_OF_BUSINESS} element={<PrivateRoute><SuggestedProfiles /></PrivateRoute>} />
        <Route exact path={RoutesPaths.VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route exact path={RoutesPaths.ADMIN_LOGIN_VERIFICATION} element={<AdminLoginVerification />} />
        <Route exact path={RoutesPaths.MESSAGE_DETAIL} element={<PrivateRoute><MessageDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BANKTRANSFER} element={<PrivateRoute><BankTransfer /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PAYMENT_BANK_TRANSFER} element={<PrivateRoute><BankTransfer /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PAYMENT_BANK_DETAIL} element={<PrivateRoute><BankDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.MAKEPAYMENT} element={<PrivateRoute><MakePayment /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PAYMENT_MAKEPAYMENT} element={<PrivateRoute><MakePayment /></PrivateRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD_MAKEPAYMENT} element={<PrivateRoute><MakePayment /></PrivateRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD_BANKTRANSFER} element={<PrivateRoute><BankTransfer /></PrivateRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD_BANKDETAIL} element={<PrivateRoute><BankDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PRODUCT_PAGE} element={<PrivateRoute><ProductPage /></PrivateRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD_PRODUCT_PAGE} element={<PrivateRoute><ProductPage /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BANKDETAIL} element={<PrivateRoute><BankDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.E_SIGN} element={<PrivateRoute><ESign /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PRODUCTS_LIST_PAGE} element={<PrivateRoute><Products /></PrivateRoute>} />
        <Route exact path={RoutesPaths.SUCCESS_SIGNNOW} element={<SuccessSignNow />} />
        <Route exact path={RoutesPaths.PRODUCT_LANDING_PAGE} element={<PublicRoute><Productlanding /></PublicRoute>} />
        <Route exact path={RoutesPaths.PRODUCT_THANK_YOU} element={<PublicRoute><Thankyou /></PublicRoute>} />
        <Route path="*" element={<Navigate to={RoutesPaths.HOME} />} />
      </Routes>
    </>
  );
}
