import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MESSAGE_TYPE } from "../../constants";
import { downloadMediaOnFly } from "../../pages/chatPage/MessageBox";

export default function AskAdaModal({
  handleClose,
  askOpen,
  getName,
  uploadFiles,
  sentEmail,
  fileArray,
  leaveMessage,
  sendMessageToAdmin,
  removeMedia,
  message,
  error,
  base64Files
}) {
  return (
    <Modal show={askOpen} className="askModal" onHide={handleClose}>
      <div id="popover-basic" className="helpPopover webkitScroll">
        {!sentEmail ? (
          <>
            <div className="dropdown-head">
              <h5>Need any help?</h5>

              <i onClick={handleClose} className="fa-solid fa-xmark"></i>
            </div>

            <div className="needHelpMenu add-Company-btn">
              <textarea
                className="askQuestionHolder"
                placeholder="Ask a question or let us know your feedback here."
                ref={message}
              ></textarea>
              <div className="inputHolderImage">
                {fileArray.length > 0 && (
                  <div className="mediaInner p-0">
                    {fileArray.map((item, index) => (
                      <span key={index} className="removeImageHolder">
                        <img
                          src="assets/img/removeMedia.svg"
                          alt=""
                          className="removeMedia"
                          onClick={() => removeMedia(index)}
                        />
                        <span key={index}>

                          {/* <span key={index}> */}
                          <span className="addMedia" onClick={() => downloadMediaOnFly(item)}>
                            {item?.type.startsWith('image/') ? (
                              <span className="imagePreviewChat" style={{ backgroundImage: `url(${base64Files[index]})` }}></span>
                            ) : (
                              <>
                                <span className="addMediaPadding">
                                  <img className="imageView" src="assets/img/imageView.svg" alt="" />
                                  &nbsp;<span className="fileName">{item.name}</span>
                                </span>
                              </>
                            )}
                          </span>
                          {/* </span> */}





                          {/* <span
                            onClick={() => downloadMediaOnFly(item)}
                            className="addMedia"
                          >
                            <img
                              className="imageView"
                              src="assets/img/imageView.svg"
                              alt=""
                            />
                            <img
                              className="addMediatextImage"
                              src="assets/img/addmediaBack.svg"
                              alt=""
                            />
                            <span className="extensionName">
                              {getName(item.name)}
                            </span>
                            <span className="fileName">{item.name}</span>
                          </span> */}
                        </span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <label htmlFor="attach" className="attachFile">
                <i className="fa-solid fa-paperclip"></i>
                Attach a screenshot or a file
                <input
                  type="file"
                  name=""
                  onChange={uploadFiles}
                  multiple
                  hidden
                  id="attach"
                />
              </label>
              {error && <span className="errorMessage">{error}</span>}
              <div className="buttonHolder">
                <button className="leaveMessage" onClick={leaveMessage}>
                  <i className="fa-solid fa-paper-plane"></i>Leave a message
                </button>
                <button
                  onClick={() => sendMessageToAdmin(MESSAGE_TYPE.ASKADDA)}
                  className="chatWithUS"
                >
                  <i className="fa-solid fa-comment-dots"></i>Chat with us
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="needHelpMenu">
            <div className="emailSent">
              <h5>Your message was sent!</h5>
              <p>{sentEmail}</p>
              <button onClick={handleClose} className="chatWithUS">
                Okay
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
