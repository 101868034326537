import signNowService from "../services/signnow.service";
import { SET_ALL_TEMPLATE_FILES, SET_ALL_DOCUMENT_BY_CHANNEL, RESET_SIGNNOW_DOCUMENT, SET_SIGNNOW_DOCUMENT_SIGNER, SET_SIGNNOW_DOCUMENT_TEMPORARY, SET_SUCCESS_MESSAGE, SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL, STOP_LOADER, START_LOADER, REMOVE_SIGNNOW_DOCUMENT_SIGNER, SET_EMBED_ESIGN_POPUP_FLOW, SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID, SET_EMBED_ESIGN_POPUP_FLOW_LINK, SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE } from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";


export const getTemplateListAction = () => async (dispatch) => {
  return signNowService.getTemplateList().then((resp) => {
    dispatch({ type: SET_ALL_TEMPLATE_FILES, payload: resp.status ? resp.data : [] });
  });
};


export const defaultTemplateSignnowActionAction = (payload) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return new Promise((resolve, reject) => {
    signNowService.createSignnowDocument(payload).then((resp) => {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data, });
      dispatch({ type: STOP_LOADER });
      resolve(resp)
    });
  })
};



export const getAllDocumentByChannelIdAction = (channelId) => async (dispatch) => {
  return signNowService.getAllDocumentByChannelId(channelId).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_ALL_DOCUMENT_BY_CHANNEL, payload: resp.data });
    } else {
      dispatch({ type: SET_ALL_DOCUMENT_BY_CHANNEL, payload: [] });
    }
  });
};

export const getDownloadDocumentAction = (documentId) => async (dispatch) => {
  dispatch(startLoaderAction())
  const resp = await signNowService.getFileDownloadDocument(documentId)
  if (resp.status) {
    window.open(resp.data.link);
  }
  dispatch(stopLoaderAction())
};

export const resetAllSignatureRequestByChannelIdAction = () => async (dispatch) => {
  dispatch({ type: SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL, payload: [] });
};

export const setSignnowDocumentSignerAction = (payload) => (dispatch) => {
  dispatch({ type: SET_SIGNNOW_DOCUMENT_SIGNER, payload: payload });
};


export const removeSignnowDocumentSignerAction = (payload) => (dispatch) => {
  dispatch({ type: REMOVE_SIGNNOW_DOCUMENT_SIGNER, payload: payload });
};

export const resetSignnowDocumentAction = () => (dispatch) => {
  dispatch({ type: RESET_SIGNNOW_DOCUMENT });
};

export const setSignNowDocumentTemporaryAction = (payload) => (dispatch) => {
  dispatch({ type: SET_SIGNNOW_DOCUMENT_TEMPORARY, payload: payload });
};


export const setEmbedEsignPopupFlowToggle = (payload) => (dispatch) => {
  dispatch({ type: SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE, payload: payload });
};

export const setEmbedEsignPopupFlowLink = (payload) => (dispatch) => {
  dispatch({ type: SET_EMBED_ESIGN_POPUP_FLOW_LINK, payload: payload });
};

export const setEmbedEsignPopupFlowEdocumentId = (payload) => (dispatch) => {
  dispatch({ type: SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID, payload: payload });
};
