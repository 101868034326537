import subscriptionPlanService from "../services/subscriptionPlan.service";
import { paymentModalAction } from "./stripe";
import {
  GET_SUSBCRIPTION_PLAN_PRODUCTS,
  LOAD_PRODUCT_LIST,
  PRODUCT_PLAN_LOADER,
  SELECTED_SUSBCRIPTION_PLAN_PRODUCTS,
  SET_BANK_CRYPTO_DETAILS,
  SET_PAYMENT_METHOD_SELECTION,
  TRANSACTION_SEGMENT_LIST,
} from "./types";

export const getPlanProductAction =
  (companyId, page, limit, selectedPlanProductCurrentPage) =>
  async (dispatch, getState) => {
    dispatch({ type: LOAD_PRODUCT_LIST, payload: true });
    return subscriptionPlanService
      .getSubscriptonPlan(companyId, page, limit)
      .then((resp) => {
        let defaultIndexRecord = 0;
        if (
          selectedPlanProductCurrentPage &&
          selectedPlanProductCurrentPage?.id &&
          selectedPlanProductCurrentPage?.page
        ) {
          defaultIndexRecord =
            resp.data.length > 0
              ? resp.data.findIndex(
                  (x) => x.id === selectedPlanProductCurrentPage?.id
                )
              : {};
          defaultIndexRecord = defaultIndexRecord >= 0 ? defaultIndexRecord : 0;
        }
        dispatch({
          type: GET_SUSBCRIPTION_PLAN_PRODUCTS,
          payload: resp.status ? resp.data : [],
          defaultIndex: defaultIndexRecord,
          count: resp.status ? resp?.count : 0,
        });
      });
  };

export const getSingleSubscriptonPlan =
  (companyId, planId, selectedPlanPage) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_PLAN_LOADER, payload: true });

    return subscriptionPlanService
      .getSingleSubscriptonPlan(companyId, planId)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: SELECTED_SUSBCRIPTION_PLAN_PRODUCTS,
            payload: resp?.data,
            currentPage: selectedPlanPage?.page,
            activeSegment: selectedPlanPage?.activeSegment,
            activePaidUpIndex: selectedPlanPage?.activePaidUpIndex || 0,
          });
          dispatch(paymentModalAction(false, resp?.data?.planType?.value));
        } else {
          dispatch({
            type: SELECTED_SUSBCRIPTION_PLAN_PRODUCTS,
            payload: null,
            currentPage: selectedPlanPage?.page,
            activeSegment: selectedPlanPage?.activeSegment,
            activePaidUpIndex: selectedPlanPage?.activePaidUpIndex || 0,
          });
        }
        dispatch({ type: SET_BANK_CRYPTO_DETAILS, payload: null });
        return resp;
      });
  };

export const selectedPlanProductAction =
  (item, currentPage = null, activeSegment = null, activePaidUpIndex = null) =>
  async (dispatch) => {
    dispatch({
      type: SELECTED_SUSBCRIPTION_PLAN_PRODUCTS,
      payload: item,
      currentPage: currentPage,
      activeSegment: activeSegment,
      activePaidUpIndex: activePaidUpIndex || 0,
    });
    dispatch({ type: SET_BANK_CRYPTO_DETAILS, payload: null });
    dispatch({ type: SET_PAYMENT_METHOD_SELECTION, payload: null });
  };

export const selectedTransactionSegmentRecord =
  (payload) => async (dispatch) => {
    dispatch({ type: TRANSACTION_SEGMENT_LIST, payload: payload });
  };
