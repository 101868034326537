import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toggleMenuSidebar, toggleMobileMenu } from "../../actions/auth";
import { getpendingInviteAction } from "../../actions/promoters";
import { RoutesPaths } from "../../Router/constant";

export default function ScrollToTop(props) {
  const dispatch = useDispatch();
  const { sidebarMobile } = useSelector(state => state.auth);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    if (sidebarMobile) {
      mobileMenuOpen();
    }
    dispatch(toggleMenuSidebar(location.pathname===`${RoutesPaths.HOME}chat` ? false : true))
    dispatch(getpendingInviteAction())
  }, [location]);

  const mobileMenuOpen = () => {
    dispatch(toggleMobileMenu())
  }

  return <>{props.children}</>
}
