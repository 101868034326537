import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addCompany } from "../../actions/company";
import {
  ACTIVITY,
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
} from "../../constants";
import Select from "react-select";
import ContactUs from "../ContactUs/ContactUs";
import { useNavigate } from "react-router";
import AddObjective from "./AddObjective";
import { useState } from "react";

export default function AddActivity({
  activityModalShow,
  activityModalClose,
  newdata,
  handleClickInfo,
  setAddModalShow,
  setActivityModalShow,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [businessData, setBusinessData] = useState({});
  const [showObjective, setShowObjective] = useState(false);
  const { pendingCompanySelected } = useSelector((state) => state.company);
  useEffect(() => {
    reset();
    const primaryCode = ACTIVITY.find(
      (x) => x.title === pendingCompanySelected?.primaryActivity
    );
    const secondryCode = ACTIVITY.find(
      (x) => x.title === pendingCompanySelected?.secondaryActivity
    );
    if (primaryCode) {
      setValue("primaryActivity", {
        title: pendingCompanySelected?.primaryActivity,
        code: primaryCode.code,
      });
    }
    if (secondryCode) {
      setValue("secondaryActivity", {
        title: pendingCompanySelected?.secondaryActivity,
        code: secondryCode.code,
      });
    }
  }, [setValue, pendingCompanySelected, activityModalShow]);

  const stepBack = () => {
    activityModalClose();
    setAddModalShow(true);
  };

  const updateCompanyData = async (data) => {
    const primaryActivity = data.primaryActivity?.title || null;
    const secondaryActivity = data.secondaryActivity?.title || null;
    const allData = {
      ...newdata,
      ...data,
      primaryActivity,
      secondaryActivity,
      id: pendingCompanySelected ? pendingCompanySelected?.id : null,
    };
    await dispatch(addCompany(allData, false));
  };

  const onSubmit = async (data) => {
    var allData = { ...newdata, ...data };
    setBusinessData(allData);
    activityModalClose();
    setShowObjective(true);
    await updateCompanyData(allData);
    reset();
  };

  const handleClose = async () => {
    const primaryActivity = getValues("primaryActivity")?.title || null;
    const secondaryActivity = getValues("secondaryActivity")?.title || null;
    const data = { primaryActivity, secondaryActivity };
    await updateCompanyData(data);
    activityModalClose();
  };
  return (
    <>
      <Modal
        show={activityModalShow}
        onHide={handleClose}
        className="moddal-width addBusinessModal ps-0 custom-modal">
        <div className="modal-header modalHeaderPadding">
          <img
            onClick={stepBack}
            className="stepBack cursorPointer"
            src="assets/img/backStep.svg"
            alt=""
          />
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={handleClose}
            aria-label="Close"></button>
        </div>
        <Modal.Body>
          <div className="row right-side-row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              <h5 className="edit-title">Step 2: Add business activities</h5>
              <p className="edit-description">
                Every holding company is allowed to perform up to two business
                activities and thus two SSIC (Singapore standard industrial
                classification) codes. The primary code is compulsory while the
                secondary code is optional. You will need to select an SSIC code
                in mind when starting holding company.{" "}
              </p>
              <p className="edit-description">
                You can change the SSIC codes as many times as you need to.{" "}
              </p>
              <p className="edit-description">
                We will be sending you notifications directly to confirm and
                verify your business activities.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 pt-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-margin">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="input-text heading">
                        New primary business activity
                      </p>
                      <Controller
                        name="primaryActivity"
                        rules={{ required: true }}
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={ACTIVITY}
                            isClearable={true}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.code}
                            placeholder="Choose a primary business activity"
                          />
                        )}
                      />
                      {errors.primaryActivity?.type === "required" && (
                        <span className="errorMessage">
                          Primary activity is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-lg-12 mt-3">
                        <p className="input-text heading">
                          New secondary business activity
                        </p>
                        <Controller
                          name="secondaryActivity"
                          control={control}
                          // defaultValue={null}
                          render={({ field }) => (
                            <Select
                              theme={SELECT_BOX_THEME}
                              styles={CUSTOM_SELECT_STYLES}
                              className="select-border"
                              {...field}
                              options={ACTIVITY}
                              isClearable={true}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.code}
                              placeholder="Choose a secondary business activity"
                            />
                          )}
                        />
                        {errors.secondaryActivity?.type === "required" && (
                          <span className="errorMessage">
                            Secondary activity is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will need to contact you directly to confirm the
                    amendment.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs handleClose={handleClose} />
                  <button type="submit" className="btn agree-button">
                    Save and Proceed{" "}
                    <img src="assets/img/nextStep.svg" alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AddObjective
        businessData={businessData}
        showObjective={showObjective}
        activityModalShow={setActivityModalShow}
        objectiveClose={() => setShowObjective(false)}
        setShowObjective={setShowObjective}
      />
    </>
  );
}
