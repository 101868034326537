import axionIns from "../axios";

const getTask = (payload) => {
  return axionIns.get("tasks/"+payload).then((response) => {return response.data});
};

const addTask = (payload) => {
  return axionIns.post("tasks", JSON.stringify(payload)).then((response) => {return response.data});
};

const updateTask = (taskId, payload) => {
  return axionIns.put("tasks/"+taskId, JSON.stringify(payload)).then((response) => {return response.data});
};

const deleteTask  = (payload) => {
  return axionIns.delete("tasks/"+payload);
};

const exportOject = {
  getTask,
  addTask,
  updateTask,
  deleteTask,
};

export default exportOject;
