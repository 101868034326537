import axionIns from "../axios";

const getAuthorisationCall = (company) => {
  return axionIns
    .get("myinfo/getMyinfoAuthoriseApi/" + company)
    .then((response) => {
      return response.data;
    });
};

const getMyInfoData = (payload) => {
  return axionIns.post("myinfo/getMyInfoData", payload).then((response) => {
    return response.data;
  });
};

const addInfoQUestions = (payload) => {
  return axionIns.post("company/addinfoquestions", payload).then((response) => {
    return response.data;
  });
};

const exportObject = {
  getAuthorisationCall,
  getMyInfoData,
  addInfoQUestions,
};
export default exportObject;
