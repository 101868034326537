import axionIns from "../axios";

const getCompanyList = () => {
  return axionIns.get("company").then((response) => { return response.data });
};

const addCompanyList = (payload) => {
  return axionIns.post("company", JSON.stringify(payload)).then((response) => { return response.data });
};

const editCompanyList = (companyId, payload) => {
  return axionIns.put("company/" + companyId, JSON.stringify(payload)).then((response) => { return response });
};

const getCompanyActivityList = (companyId, page, perPage = 10, filterType) => {
  return axionIns.get("company/activity/" + companyId + "?page=" + page + "&perPage=" + perPage + "&filter=" + filterType).then((response) => { return response.data });
};

const notificationDeny = (payload) => {
  return axionIns.post("company/notification_deny", JSON.stringify(payload)).then((response) => { return response.data });
};

const getAllCompanyAppointedList = () => {
  return axionIns.get("company/list/appointed").then((response) => { return response.data });
};


const updateUserExperience = async (companyId, payload) => {
  return axionIns.put("company/user-experience/"+companyId, JSON.stringify(payload)).then((response) => { return response.data });
};


const exportObject = {
  getCompanyList,
  addCompanyList,
  editCompanyList,
  getCompanyActivityList,
  notificationDeny,
  getAllCompanyAppointedList,
  updateUserExperience
};
export default exportObject;
