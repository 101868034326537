import { IS_DEMO, IS_LIVE } from "../constants";
export const appSettings = IS_LIVE ? "/app" : IS_DEMO ? "" : "/appstaging";
export const RoutesPaths = {
  HOME: `${appSettings}/`,
  DASHBOARD: `${appSettings}/dashboard`,
  LANDING: `${appSettings}/home`,
  OLD_PAYMENT: `${appSettings}/oldpayment`,
  PAYMENT: `${appSettings}/payment`,
  PAYMENT_BANK_TRANSFER: `${appSettings}/payment/banktransfer`,
  PAYMENT_BANK_DETAIL: `${appSettings}/payment/banktransfer/bankdetail`,
  PAYMENT_MAKEPAYMENT: `${appSettings}/payment/banktransfer/makepayment`,
  SHARECAPITAL: `${appSettings}/sharecapital`,
  FILE: `${appSettings}/file`,
  INVITE: `${appSettings}/invite`,
  TASK: `${appSettings}/task`,
  SIGNIN: `${appSettings}/`,
  SIGNUP: `${appSettings}/sign-up`,
  FORGOT_PASSWORD: `${appSettings}/forgot-password`,
  RESET_PASSWORD: `${appSettings}/reset-password/:token`,
  PROFILE: `${appSettings}/profile`,
  ACCOUNTSETTING: `${appSettings}/editprofile`,
  SECURITY: `${appSettings}/security`,
  VERIFICATION: `${appSettings}/verification`,
  ADMIN_LOGIN_VERIFICATION: `${appSettings}/admin/verification/:userId/:adminId/:company`,
  INVITE_ACCEPT: `${appSettings}/promoter/:token`,
  VERIFY_EMAIL: `${appSettings}/verify-email/:token`,
  STRIPE_PAYMENT: `${appSettings}/stripe`,
  MYINFOCALLBACK: `${appSettings}/callback`,
  MESSAGE_DETAIL: `${appSettings}/chat`,
  BOOK_OF_BUSINESS: `${appSettings}/book-of-business`,
  BANKTRANSFER: `${appSettings}/products/:slug/banktransfer`,
  BANKDETAIL: `${appSettings}/products/:slug/banktransfer/bankdetail`,
  MAKEPAYMENT: `${appSettings}/products/:slug/banktransfer/makepayment`,
  DASHBOARD_BANKTRANSFER: `${appSettings}/dashboard/:slug/banktransfer`,
  DASHBOARD_BANKDETAIL: `${appSettings}/dashboard/:slug/banktransfer/bankdetail`,
  DASHBOARD_MAKEPAYMENT: `${appSettings}/dashboard/:slug/banktransfer/makepayment`,
  E_SIGN: `${appSettings}/e-sign`,
  SUCCESS_SIGNNOW: `${appSettings}/success-signnow`,
  PRODUCT_PAGE: `${appSettings}/products/:slug`,
  DASHBOARD_PRODUCT_PAGE: `${appSettings}/dashboard/:slug`,
  PRODUCTS_LIST_PAGE: `${appSettings}/products`,
  PRODUCT_LANDING_PAGE: `${appSettings}/product-landing`,
  PRODUCT_THANK_YOU: `${appSettings}/thank-you`,
};
