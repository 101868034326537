import React, { useEffect } from "react";
import Header from "../../components/header/header";
import ContactUs from "../../components/ContactUs/ContactUs";
import {
  backendUrl,
  CONTACT_US_TRIGER,
  PRODUCT_GOLD_TYPE,
  SUBSCRIPTION_MODULE_TYPE,
  SUPPORTED_PAYMENT_METHOD,
} from "../../constants";
import Currency from "../../components/currency/currency";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import {
  setPaymentMethodSelection,
  setTripleaUrl,
  subscriptionPlan,
} from "../../actions/stripe";
import { userBidGoldPriceLockAction } from "../../actions/user";
import toast from "react-hot-toast";
import { getSingleSubscriptonPlan } from "../../actions/subscriptionPlan";
import ContentLoader from "react-content-loader";

export default function ProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const planId = location?.pathname?.split("/").pop();
  const pathnames = location?.pathname?.split("/").filter((x) => x);

  const {
    stripeSubscription,
    stripePlans,
    stripeModal,
    paymentMethodSelection,
  } = useSelector((state) => state.stripe);
  const { selectedPlanProduct, selectedPlanProductCurrentPage, planLoader } =
    useSelector((state) => state.subscriptionPlanProducts);
  const { selectedCompany } = useSelector((state) => state.company);
  const { bidGoldHeader } = useSelector((state) => state.user);

  useEffect(() => {
    getPlanById();
  }, [location]);

  const getPlanById = async () => {
    const { id } = selectedCompany;
    if (planId) {
      var resp = await dispatch(
        getSingleSubscriptonPlan(id, planId, selectedPlanProductCurrentPage)
      );
      if (!resp?.status) {
        navigate(RoutesPaths.PRODUCTS_LIST_PAGE);
      }
    } else {
      navigate(RoutesPaths.PRODUCTS_LIST_PAGE);
    }
  };

  useEffect(() => {
    if (stripePlans && stripePlans?.supportedPaymentMethod?.length > 0) {
      dispatch(
        setPaymentMethodSelection(
          paymentMethodSelection
            ? paymentMethodSelection
            : stripePlans?.supportedPaymentMethod[0]?.value
        )
      );
    }
  }, [stripePlans, selectedPlanProduct]);

  const forwardPaymentPage = async () => {
    const stripePlanId = stripePlans?.id || stripePlans?._id;
    let checkGoldProduct = null;
    for (const property in bidGoldHeader) {
      if (bidGoldHeader[property].id === stripePlanId)
        checkGoldProduct = property;
    }

    if (checkGoldProduct) {
      await dispatch(
        userBidGoldPriceLockAction(paymentMethodSelection, {
          type: checkGoldProduct,
          price: stripePlans?.price,
        })
      );
    }
    dispatch(setTripleaUrl(null, false))
    if (pathnames[1] == "dashboard") {
      navigate(RoutesPaths.DASHBOARD_BANKTRANSFER.replace(":slug", planId));
    } else if (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO) {
      navigate(RoutesPaths.BANKTRANSFER.replace(":slug", planId));
    } else if (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD) {
      dispatch(subscriptionPlan(stripePlans));
      navigate(RoutesPaths.BANKTRANSFER.replace(":slug", planId));
    } else {
      toast.error("Please select a valid payment method");
    }
  };
  return (
    <>
      <Header title="Product" subtitle="Product" mobileHeader={planId} />

      <div className="container-fluid minHeightVH py-4 paymentPage paddingBottomMD">
        {planLoader ? (
          <div className="row">
            <div className="col-12 col-md-4">
              <ContentLoader width={"100%"} height={164}>
                <rect rx="5" ry="5" width={"100%"} height="164" />
              </ContentLoader>
              <div className="row mt-2">
                <div className="col-md-4">
                  <ContentLoader width={"100%"} height={30}>
                    <rect rx="5" ry="5" width={"100%"} height={50} />
                  </ContentLoader>
                </div>
              </div>
              <div className="row mt-2">
                {[1, 2, 3].map((item, index) => (
                  <div key={index} className="col-md-4">
                    <ContentLoader width={"100%"} height={50}>
                      <rect rx="5" ry="5" width={"100%"} height={50} />
                    </ContentLoader>
                  </div>
                ))}
              </div>
              <div className="row mt-4">
                {[1, 2].map((item, index) => (
                  <div key={index} className="col-md-6">
                    <ContentLoader width={"100%"} height={50}>
                      <rect rx="5" ry="5" width={"100%"} height={50} />
                    </ContentLoader>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-8">
              {[
                100, 90, 80, 70, 80, 75, 65, 60, 50, 55, 60, 90, 65, 70, 80,
              ].map((item, index) => (
                <ContentLoader key={index} width={item + "%"} height={15}>
                  <rect rx="5" ry="5" width={item + "%"} height="15" />
                </ContentLoader>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="productPlanCta">
                  <div className="productImageHolder">
                    <div
                      className="productImage"
                      style={{
                        backgroundImage: `url(${backendUrl + stripePlans?.image?.filename
                          })`,
                      }}></div>
                  </div>
                  <div className="choosePaymentMethod">
                    <div className="titlechoosePay">Choose how to pay:</div>
                    {stripePlans?.supportedPaymentMethod?.length > 0 && (
                      <>
                        <div className="row paymentMethodList">
                          {stripePlans?.supportedPaymentMethod.map(
                            (item, index) => (
                              <div
                                onClick={() =>
                                  dispatch(
                                    setPaymentMethodSelection(item?.value)
                                  )
                                }
                                key={index}
                                className="col-4">
                                <div
                                  className={`choosePaySelectHolder ${paymentMethodSelection === item?.value
                                    ? "choosePaySelectHolderActive"
                                    : ""
                                    }`}>
                                  <img
                                    src={`assets/img/${item?.value ===
                                      SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                                      ? paymentMethodSelection === item?.value
                                        ? "bankActive"
                                        : "bank"
                                      : item?.value ===
                                        SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                                        ? paymentMethodSelection === item?.value
                                          ? "creditCardsActive"
                                          : "creditCards"
                                        : item?.value ===
                                          SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                                          ? paymentMethodSelection === item?.value
                                            ? "blockchainActive"
                                            : "blockchain"
                                          : paymentMethodSelection === item?.value
                                            ? "bankActive"
                                            : "bank"
                                      }.svg`}
                                    alt={item?.name}
                                  />
                                  <h6 className="m-0">{item?.name}</h6>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}
                    <div className="row">
                      <div className="col-6">
                        {paymentMethodSelection && (
                          <div
                            onClick={forwardPaymentPage}
                            className="productUpgradePlanBtn">
                            {stripePlans?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? "Upgrade plan" : stripePlans?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL ? "Deposit paid-up capital" : stripePlans?.planType?.value === SUBSCRIPTION_MODULE_TYPE.FUNDS ? "Add capital" : "Buy product"}
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        <ContactUs
                          theme="planContactUS"
                          subscriptionPlanName={stripePlans?.name}
                          type={
                            stripePlans?.planType?.value ===
                              SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
                              ? CONTACT_US_TRIGER.PAID_UP_CAPITAL_PAYMENT
                              : CONTACT_US_TRIGER.PAYMENT
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="productPlanHolder">
                  <div className="productPlanPriceHolder">
                    <h4 className="productplanTitle">
                      {stripePlans?.name || "-"}
                    </h4>
                    <p className="recumentPrice">
                      <Currency currency={stripePlans?.currency?.value} />{" "}
                      <CurrencyFormat
                        currency={stripePlans?.currency?.value}
                        value={parseFloat(stripePlans?.price)}
                        decimal={2}
                      />
                    </p>
                    {stripePlans?.smallDescription && (
                      <p className="productSlug">
                        *for {stripePlans?.smallDescription}
                      </p>
                    )}
                  </div>
                  {stripePlans?.stockKey && stripePlans?.stockKey > 0 && stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM && (
                    <div className="stockContHolder">
                      <p className="stockCount">
                        {stripePlans?.stockKey || 0} in stock
                      </p>
                    </div>
                  )}
                  <div
                    className="planDescription"
                    dangerouslySetInnerHTML={{
                      __html: stripePlans?.targetAudience || "-",
                    }}></div>
                  <div className="row top-section">
                    {stripePlans?.assuredFeature &&
                      stripePlans?.assuredFeature?.length > 0 && (
                        <div className="col-md-6">
                          <h6 className="BottomBankPlantitle">
                            You are assured of:
                          </h6>
                          {stripePlans?.assuredFeature?.map((item, index) => (
                            <div key={index} className="youAreAssignReceive">
                              <img src="assets/img/assuerdIcon.svg" alt="" />
                              <p>{item}</p>
                            </div>
                          ))}
                        </div>
                      )}

                    {stripePlans?.guarentedFeature &&
                      stripePlans?.guarentedFeature?.length > 0 && (
                        <div className="col-md-6">
                          <h6 className="BottomBankPlantitle">
                            You are guaranteed to receive:
                          </h6>
                          {stripePlans?.guarentedFeature?.map((item, index) => (
                            <div key={index} className="youAreAssignReceive">
                              <img src="assets/img/assuerdIcon.svg" alt="" />
                              <p>{item}</p>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
