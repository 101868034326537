import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CUSTOM_SELECT_STYLES, ESIGN_TEMPLATE_TYPE, SELECT_BOX_THEME, backendUrl } from "../../constants";
import { defaultTemplateSignnowActionAction, removeSignnowDocumentSignerAction, resetSignnowDocumentAction, setSignnowDocumentSignerAction } from "../../actions/signnow";
import { RoutesPaths } from "../../Router/constant";
import { useCallback } from "react";
import { Document, Page } from 'react-pdf';
import { getSdkConversationObject } from "../../conversations-objects";
import { formatFileSize } from "../chatPage/userMessages";
import toast from "react-hot-toast";
import { isDesktop, isMobile } from "react-device-detect";

export default function Esign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conversations = useSelector((state) => state.convos);
  const {
    control,
    getValues,
    register,
    reset,
  } = useForm();

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => { setNumPages(numPages); }

  const { templateType, manualUpload, predefinedTemplates, currentSignnowRoomId, currentSignnowParticipants, currentSignnowSigner, manualReponseUploadFileName } = useSelector((state) => state.signNow);

  useEffect(() => {
    const loadFunc = () => {
      dispatch(resetSignnowDocumentAction())
    }
    loadFunc()
  }, [dispatch])

  const [step, setStep] = useState(1)

  const handleStep = useCallback((value) => {
    let stepValue = step + value
    if ((validateNext(value) && value > 0) || value < 0) {
      if (stepValue > 0 && stepValue < 4) {
        // if (parseInt(templateType) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE)) {
        //   stepValue = stepValue + value
        // }
        setStep(stepValue)
      }
    } else {
      return false
    }

    if (stepValue === 0) {
      navigate(-1)
    }
  })

  useEffect(() => {
    const checkIfTemplatehasValue = () => {
      if (!templateType) {
        handleStep(-1)
      }
    }
    checkIfTemplatehasValue()
    return () => { }
  }, [templateType, handleStep])



  const onSignerChange = (value) => {
    dispatch(setSignnowDocumentSignerAction(value))
  }

  const onSubmit = async () => {
    const title = getValues('title')
    const message = getValues('message')
    var payload = {
      title: title,
      message: message,
      documentName: parseInt(templateType) === ESIGN_TEMPLATE_TYPE.TEMPLATE ? predefinedTemplates?.name : manualUpload.length > 0 ? manualUpload[0].name : null,
      templateId: parseInt(templateType) === ESIGN_TEMPLATE_TYPE.TEMPLATE ? predefinedTemplates?.id : null,
      signers: [],
      tempSigners: [],
      roomId: currentSignnowRoomId,
      templateType: templateType,
      manualUpload: manualReponseUploadFileName || null,
    }

    currentSignnowSigner.forEach((element, index) => {
      const splitName = element.attributes.name.split(/\s+/);
      payload.signers.push(element.identity)
      payload.tempSigners.push({
        first_name: splitName[0].length > 0 ? splitName[0] : "Anonymous",
        last_name: splitName[1].length > 0 ? splitName[1] : "",
        email: element.identity,
      })
    });

    const resp = await dispatch(defaultTemplateSignnowActionAction(payload))
    // if (resp?.status) {
    //   // await dispatch(defaultTemplateYousignSignatureRequestAction(payload))
    //   await onMessageSend(payload)
    // }
    reset();
    navigate(RoutesPaths.MESSAGE_DETAIL + "?room=" + currentSignnowRoomId);
  };




  const onMessageSend = async (payload) => {
    const fileSize = await getFileSize();
    const title = getValues('title')
    if (title.trim().length === 0) {
      return;
    }
    const conversation = conversations.find((convo) => convo.sid === currentSignnowRoomId)
    const sdkConvo = getSdkConversationObject(conversation);
    // <div class="showMore">Show more</div>
    const messagePayload = `<div class="e-sign-pdf-message"><div class="e-sign-pdf-img-hold"><div class="e-sign-pdf-img"></div></div><div class="e-sign-pdf-name"><h6>${payload?.documentName}.pdf</h6><div class="titleDesc showLess"><h3>${payload?.title}</h3><p>${payload?.message}</p></div><div class="e-sign-pdf-button"><div class="e-sign-pdf-button-with-size"><p class="e-sign-pdf-size">${fileSize}</p><p class="e-sign-pdf-signer"><strong>Signers:</strong> ${payload?.tempSigners.map(fruit => fruit.first_name + ' ' + fruit.last_name).join(", ")}</p></div></div></div></div>`;
    const newMessageBuilder = sdkConvo.prepareMessage().setBody(messagePayload);
    const messageIndex = await newMessageBuilder.build().send();
    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
      return
    } catch (e) {
      throw e;
    }
  };

  const removeSigner = (index) => {
    dispatch(removeSignnowDocumentSignerAction(index))
  }

  const showFilteredAddPeople = (item, index, showRemoveIcon) => {
    return (
      <div
        key={index}
        className="col-lg-12 inner-section"
      >
        <div className="profile-name">
          {item.identity}
          {showRemoveIcon && (<span className="remove-name" onClick={() => removeSigner(index)}> <i className="fa-solid fa-x"></i> </span>)}
        </div>
        <div className="signerIndication">
          Signer {index + 1}
        </div>
      </div>
    )
  }

  // useEffect(() => {
  //   validateNext(step)
  // }, [watch('title')])

  const validateNext = (value) => {
    let returnValue = false
    if (value < 0) {
      returnValue = true
    } else {
      switch (step) {
        case 1:
          returnValue = currentSignnowSigner.length === 2 ? true : false
          if (currentSignnowSigner.length < 2) {
            toast.error("Please select atleast 2 signers.")
          }
          break;
        case 2:
          returnValue = true
          break;
        case 3:
          if (getValues('title')) {
            onSubmit()
          } else {
            returnValue = false
          }
          break;
        default:
          returnValue = false
          break;
      }
    }
    return returnValue
  }

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };


  const validateNextDisable = () => {
    const returnValue = validateNext(step)
    return !returnValue
  }



  const setFilePreview = (template = 'template') => {
    var filePreviewReturn = null
    switch (parseInt(templateType)) {
      case ESIGN_TEMPLATE_TYPE.TEMPLATE:
        filePreviewReturn = (predefinedTemplates && `${backendUrl}${template}/${predefinedTemplates.name}.pdf`) || null
        break;
      case ESIGN_TEMPLATE_TYPE.MANUAL:
        filePreviewReturn = (manualUpload && manualUpload[0]) || null
        break;
      default:
        break;
    }
    return filePreviewReturn || null
  }

  const getFileSize = async () => {
    const fileUrl = setFilePreview()
    let fileSizeReturn = '0KB';
    const response = await fetch(fileUrl, { method: 'HEAD' });
    if (response.ok) {
      const contentLength = response.headers.get('Content-Length');
      fileSizeReturn = formatFileSize(contentLength);
    }
    return fileSizeReturn;
  }

  const downloadTemplateSampleFile = () => {
    const urlReturn = setFilePreview('templates')
    if (urlReturn) {
      window.open(urlReturn)
    }
  }

  return (
    <>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 shadow-none mx-3 nav-back navbar-media" id="navbarBlur" navbar-scroll="true"
      >
        <div className="container-fluid short-container py-2 px-3 media-content esignMedia">
          <ul className="signer-steps">
            <li className={step >= 1 ? "active" : ""}>
              <span className="identify-step-avtar p-0"><img src="assets/img/addUser.svg" alt="" /></span>
              Add signers
            </li>
            <li className={step >= 2 ? "active" : ""}>
              <span className="identify-step-avtar p-0"><img src="assets/img/search_document.svg" alt="" /></span>
              {parseInt(templateType) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) ? "Preview document" : "Place fields"}
            </li>
            <li className={step >= 3 ? "active" : ""}>
              <span className="identify-step-avtar"><i className="fa fa-check" aria-hidden="true"></i></span>
              Save and send
            </li>
          </ul>
        </div>
      </nav>

      <div className="signer-body minHeightVH">
        <div className="container-fluid short-container">

          <div className="signer-body-content">
            <div className="signer-head">
              <h3>
                {/* <span onClick={() => handleStep(-1)} className="back"><i className="fa fa-angle-left" aria-hidden="true"></i> Back</span> */}
                Step {step}: {step === 1 ? "Add signers" : step === 2 ? (parseInt(templateType) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) ? "Preview document" : "Place Fields") : step === 3 ? "Save and send" : ""}</h3>
            </div>
            <form>

              <div className="signer-content">

                {step === 1 ? (
                  <>
                    <div className="signer-page-1">
                      <div className="fileUploadedSection">
                        <h6>File uploaded</h6>

                        <ul className="fileUploadedSection-filelist">
                          <li>
                            <img src="assets/img/Sign.svg" alt="sign" /> {parseInt(templateType) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) ? predefinedTemplates?.name + '.pdf' : manualUpload && manualUpload.length > 0 ? manualUpload[0]?.name : ""}
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-12 mt-5">
                        <p className="conversation-text mb-3">
                          Choose signer/s
                        </p>

                        <div className={`row ${currentSignnowSigner && currentSignnowSigner.length > 0 ? 'conversation-section customScroll' : ''}`}>
                          {currentSignnowSigner &&
                            currentSignnowSigner.length > 0 && (
                              currentSignnowSigner.map((item, index) => (
                                showFilteredAddPeople(item, index, true)
                              ))
                            )}
                        </div>


                        <div className={`col-lg-12 ${currentSignnowSigner.length > 0 ? 'mt-4' : ''}`}>
                          <div className="form-group message-section additional-margin">
                            <Controller
                              render={() => (
                                <Select
                                  theme={SELECT_BOX_THEME}
                                  styles={CUSTOM_SELECT_STYLES}
                                  options={currentSignnowParticipants}
                                  getOptionLabel={(option) => option.attributes.name + ' ' + option.identity}
                                  getOptionValue={(option) => option.identity}
                                  value=""
                                  onChange={onSignerChange}
                                  placeholder="Select signer from the list"
                                />
                              )}
                              {...register("participants", {
                                required: true,
                              })}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>) : (step === 2) ? (
                    <>


                      <div className="col-lg-12 mt-5 mb-5">
                        <p className="conversation-text mb-3">Signer/s</p>
                        <div className={`row ${currentSignnowSigner && currentSignnowSigner.length > 0 ? 'conversation-section customScroll' : ''}`}>
                          {currentSignnowSigner &&
                            currentSignnowSigner.length > 0 && (
                              currentSignnowSigner.map((item, index) => (
                                showFilteredAddPeople(item, index, false)
                              ))
                            )}
                        </div>
                      </div>

                      {setFilePreview() && (
                        <>
                          <div className="doc-pdf-preview">

                            <Document file={setFilePreview()} onLoadSuccess={onDocumentLoadSuccess}>
                              <Page pageNumber={pageNumber} textLayer={false} renderTextLayer={false} renderAnnotationLayer={false} />
                            </Document>

                            {isDesktop && (
                              <div className="page-controls">
                                {pageNumber > 1 && (
                                  <span className="spanButton" type="button" onClick={() => handlePageChange(pageNumber-1)}>‹</span>
                                )}
                                <span>{pageNumber} of {numPages}</span>
                                {pageNumber < numPages && (
                                  <span className="spanButton" type="button" onClick={() => handlePageChange(pageNumber+1)}>›</span>
                                )}
                              </div>
                            )}

                            {/* <Document file={setFilePreview()} onLoadSuccess={onDocumentLoadSuccess}>
                              {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                  textLayer={false} renderTextLayer={false} renderAnnotationLayer={false}
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                // width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                />
                              ))}
                            </Document> */}
                          </div>

                          {isMobile && (
                            <div className="page-controls-mobile">
                              {pageNumber > 1 && (
                                <span className="spanButton" type="button" onClick={() => handlePageChange(pageNumber-1)}>‹</span>
                              )}
                              <span>{pageNumber} of {numPages}</span>
                              {pageNumber < numPages && (
                                <span className="spanButton" type="button" onClick={() => handlePageChange(pageNumber+1)}>›</span>
                              )}
                            </div>
                          )}
                          {/* {numPages > 0 && (
                            <p className="total-pdf-pages">
                              Total pages: {numPages}
                            </p>
                          )} */}
                        </>
                      )}
                    </>
                  ) : (
                  <><div className="signer-page-3">
                    <div className="saveAndSendSection">
                      <h4>Save</h4>
                      <ul className="savesend-filelist">
                        <li>
                          <div className="wrap-file-save-name">

                            <div className="wrap-file-save">
                              <i className="fa-solid fa-file-pdf"></i>
                            </div>
                            <div className="wrap-file-name">
                              <p className="signerDoc"> {parseInt(templateType) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) ? predefinedTemplates?.name + '.pdf' : manualUpload && manualUpload.length > 0 ? manualUpload[0]?.name : ""}</p>
                              <p className="signerName">Signers: &nbsp;
                                {currentSignnowSigner.length > 0 && (
                                  currentSignnowSigner.map((item, index) => (
                                    <span key={index}>{item.attributes.name || item.identity}</span>
                                  ))
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="wrap-download-file">
                            <button onClick={downloadTemplateSampleFile} type="button" className="btn btn-sm contactus-modal-button"><i className="fa-solid fa-download"></i> Download</button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="saveAndSendSection mt-5">
                      <h4>Send</h4>
                      <ul className="sender-email-signer">
                        {currentSignnowSigner.length > 0 && (
                          currentSignnowSigner.map((item, index) => (
                            <li key={index}>{item.identity}</li>
                          ))
                        )}
                      </ul>
                    </div>
                    <div className="saveAndSendFormSection mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <p className="input-text heading">Title</p>
                            <input
                              maxLength={50}
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              {...register("title", {
                                required: true,
                              })}
                            />
                            {getValues("title")?.length === 0 && (
                              <span className="errorMessage">Title is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 mt-3 additional-margin-step-3">
                          <div className="form-group">
                            <p className="input-text heading">Message (Optional)</p>
                            <textarea
                              maxLength={500}
                              rows={8}
                              cols={50}
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Message"
                              {...register("message")}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="import-note">
                        <span className="will-need-text">
                          <strong>Important note</strong>: InProved admin will firstly prepare the document before sending out to the signers for e-sign. Typically your invited signer will sign first, followed by you, the initiator of this document.
                        </span>
                      </p>
                    </div>
                  </div></>
                )}


              </div>

              <div className="signer-footer-nav">
                <button type="button" onClick={() => handleStep(-1)} className="back contactus-modal-button">Back</button>
                <button type="button" onClick={() => handleStep(1)} className="btn agree-button">{step === 3 ? "Send" : "Next"}</button>
              </div>
            </form>

          </div>
        </div>

      </div >
    </>
  );
}
