import axionIns from "../axios";

const sendOtp = (payload) => {
    return axionIns.post("otp", JSON.stringify(payload)).then((response) => { return response.data });
};

const verifyOTP = (payload) => {
  return axionIns.put("otp", JSON.stringify(payload)).then((response) => { return response.data });
};

export default {
  sendOtp,
  verifyOTP,
};
