import { isMobile } from "react-device-detect";
import {
  GET_COMPANY_LIST,
  SELECTED_COMPANY,
  GET_COMPANY_ACTIVITY_LIST,
  CHANGE_PERMISSION_UPDATES,
  LOADING_COMPANY,
  SET_NOTIFICATION_BANNER,
  CLEAR_NOTIFICATION,
  LOAD_COMPANY_SKELETON,
  LOAD_COMPANY_ACTIVITY_SKELETON,
  LOAD_COMPANY_SKELETON_FORCE,
  SET_ALL_COMPANY_APPOINTED_LIST,
  SET_ENABLE_TOUR_RIDE,
  HIDE_BOB_TOUR_RIDE,
  COMPANY_VERIFICATION_STEP_FAILED,
  SET_PENDING_COMPANY_SELECT,
} from "../actions/types";
const initialState = {
  company: [],
  pendingCompany: [],
  selectCompanyIndex: 0,
  selectedCompany: {},
  loadCompany: true,
  loadCompanyForce: true,
  loadCompanyActivity: false,
  companyActivity: [],
  companyActivityCount: 0,
  hasPermission: false,
  loadingCompany: null,
  notificationBanner: {},
  bobNotification: {},
  clearAlert: true,
  allAppointedList: [],
  isDefaultCompany: true,
  steps: [],
  bobSteps: [],
  bobStepsEntry: [],
  showTourRide: null,
  showBobTourRide: null,
  showBobTourRideEntry: null,
  enableTourRide: false,
  companyVerificationStepFailed: null,
  pendingCompanySelected: null,
  validateNotDefaultCompany: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_LIST:
      return {
        ...state,
        company: payload?.data || [],
        pendingCompany: payload?.pendingCompany || [],
        hasPermission: false,
        loadCompany: false,
        validateNotDefaultCompany:
          payload?.data?.filter(x => !x?.isDefault)?.length > 0 ? true : false,
      };
    case SELECTED_COMPANY:
      let setIndex = payload.initial
        ? state.selectCompanyIndex
        : payload.index
          ? state.company.findIndex((x) => x.id === payload.index)
          : 0;
      let checkPermission = false;
      if (payload.currentUser && state.company[setIndex].user) {
        if (payload.currentUser === state.company[setIndex].user) {
          checkPermission = true;
        }
      }
      checkPermission = true;

      const isDefaultCompany = state.company[setIndex].isDefault || false;
      var storeReturnValue = {
        ...state,
        selectCompanyIndex: setIndex,
        selectedCompany: state.company[setIndex],
        hasPermission: checkPermission,
        loadingCompany: true,
        loadCompany: false,
        isDefaultCompany: isDefaultCompany,
        showTourRide: state.company[setIndex].showTourRide || false,
        showBobTourRide: state.company[setIndex].showBobTourRide || false,
        showBobTourRideEntry:
          (state.company[setIndex].showBobTourRide === false
            ? state.company[setIndex].showBobTourRideEntry
            : false) || false,
        enableTourRide: false,
        steps: isDefaultCompany
          ? [
            {
              content: "",
              placement: "",
              target: "hideGuide",
            },
            {
              content:
                "You can easily speak to us about diversifying to 99.99% gold bullion bars.",
              placement: "right",
              target: ".spotlightStep1",
              title: "Buy Bullion",
            },
            {
              content:
                "You can easily create a holdco at no cost, for your precious metals business, so that you can appoint directors, shareholders and other stakeholders.",
              placement: "bottom",
              styles: {
                options: {
                  width: 500,
                },
              },
              target: ".spotlightStep2",
              title: "Add holding company",
            },
          ]
          : [
            {
              content: "",
              placement: "",
              target: "hideGuide",
            },
            {
              content:
                "You can easily invite your networks to discuss investing in the business of this  holdco.",
              placement: "top",
              target: ".companySpotlightStep1",
              title: "Add shareholders",
            },
            {
              content:
                "You can easily invite your networks to discuss being appointed as directors of this holdco.",
              placement: "top",
              target: ".companySpotlightStep2",
              title: "Add appointments",
            },
            {
              content:
                "You can easily invite your networks to discuss being appointed as directors of this holdco.",
              placement: "right",
              target: ".companySpotlightStep3",
              title: "Edit share capital",
            },
            {
              content:
                "You can easily edit these items to complete the holdco.",
              placement: "bottom",
              target: ".companySpotlightStep4-1",
              title: "Edit holding company",
            },
            {
              content:
                "You can easily edit these items to complete the holdco.",
              placement: "bottom",
              target: ".companySpotlightStep4-2",
              title: "Edit holding company",
            },
            {
              content:
                "You can easily edit these items to complete the holdco.",
              placement: "bottom",
              target: ".companySpotlightStep4-3",
              title: "Edit holding company",
            },
            {
              content:
                "You can easily edit these items to complete the holdco.",
              placement: "bottom",
              target: ".companySpotlightStep4-4",
              title: "Edit holding company",
            },
            {
              content:
                "You can easily edit these items to complete the holdco.",
              placement: "bottom",
              target: ".companySpotlightStep4-5",
              title: "Edit holding company",
            },
            {
              content:
                "At any time, you can speak to us about incorporating this holdo in Singapore or other countries.",
              placement: "bottom",
              target: ".companySpotlightStep5",
              title: "Speak to admin",
            },
          ],
        bobSteps: [
          {
            content: "",
            placement: "",
            target: "bobride",
          },
          {
            content: isDefaultCompany
              ? "You can easily add your networks to the book of business as a reason for other users to contact YOU for the purpose of introducing this profile (or other profiles). Any profile you add will not be shown to anyone or published anywhere."
              : "You can easily add your networks to the book of business to allow us to invite this profile to the platform for you. Any profile you add will not be shown to anyone outside of this holding company.",
            placement: "right",
            styles: { options: { width: 600 } },
            target: ".bobSpotlightStep1",
            title: "Add profile",
          },
          {
            content:
              "Find out how to profit from adding new profiles to your book of business.",
            placement: "bottom",
            styles: { options: { width: 600 } },
            target: ".bobSpotlightStep2",
            title: "Notification",
          },
        ],
        bobStepsEntry: [
          {
            content: "",
            placement: "",
            target: "bobride",
          },
          {
            content: isDefaultCompany
              ? "You can click “make public” to indicate your interest to introduce this profile (and any other profiles) to other users for the purpose of advancing their business. Important to note that no profile information you enter will be shown in full to anyone as the profile details will be hidden for all users. Try it, you can delete the profile anytime."
              : "You can click “connect” to indicate your interest to meet this profile on this platform to discuss the business of this holding company. Any profile you add will not be shown to anyone outside of this holding company. Try it, you can delete the profile anytime.",
            placement: "right",
            styles: { options: { width: 600 } },
            target: ".bobSpotlightEntryStep1",
            title: isDefaultCompany ? "Make public" : "Connect",
          },
          {
            content:
              "Find out how to profit from adding new profiles to your book of business.",
            placement: "bottom",
            styles: { options: { width: 600 } },
            target: ".bobSpotlightStep2",
            title: "Notification",
          },
        ],
      };

      if (
        state.notificationBanner &&
        !state.notificationBanner.hasOwnProperty("force")
      ) {
        // company based notification banner
        var notificationBanner = state.company[setIndex]?.notification || {};
        if (
          Object.keys(notificationBanner).length > 0 &&
          "isActive" in notificationBanner
        ) {
          const notificationDeny =
            state.company[setIndex]?.notificationDeny || [];
          const responseDenyFilter = notificationDeny.filter(
            (x) =>
              x.user === payload.currentUser &&
              x?.cta?.value === notificationBanner?.callToAction?.value
          );
          if (responseDenyFilter.length === 0) {
            notificationBanner["status"] = notificationBanner.isActive;
            storeReturnValue["notificationBanner"] = notificationBanner;
            storeReturnValue["clearAlert"] = true;
          } else {
            notificationBanner["status"] = notificationBanner.isActive;
            storeReturnValue["notificationBanner"] = {};
          }
        } else {
          notificationBanner["status"] = false;
          storeReturnValue["notificationBanner"] = {};
        }
      }

      // company based bob notification banner
      var bobNotificationBanner =
        state.company[setIndex]?.bobNotification || {};
      if (
        Object.keys(bobNotificationBanner).length > 0 &&
        "isActive" in bobNotificationBanner
      ) {
        bobNotificationBanner["status"] = bobNotificationBanner.isActive;
        storeReturnValue["bobNotification"] = bobNotificationBanner;
        storeReturnValue["clearAlert"] = true;
      } else {
        bobNotificationBanner["status"] = false;
        storeReturnValue["bobNotification"] = {};
      }

      // if (notificationBanner['status'] === false && Object.keys(storeReturnValue['notificationBanner']).length === 0 && Object.keys(storeReturnValue['bobNotification']).length === 0) {
      //   storeReturnValue['showTourRide'] = state.company[setIndex].showTourRide || false
      // } else {
      //   storeReturnValue['showTourRide'] = false
      // }

      return storeReturnValue;

    case CHANGE_PERMISSION_UPDATES:
      return {
        ...state,
        hasPermission: payload,
      };

    case GET_COMPANY_ACTIVITY_LIST:
      return {
        ...state,
        companyActivity: payload && payload.data.length > 0 ? payload.data : [],
        companyActivityCount: payload && payload.count ? payload.count : 0,
        loadCompanyActivity: false,
      };

    case LOAD_COMPANY_ACTIVITY_SKELETON:
      return {
        ...state,
        loadCompanyActivity: payload,
      };

    case LOAD_COMPANY_SKELETON:
      return {
        ...state,
        loadCompany: payload,
      };

    case LOADING_COMPANY:
      return {
        ...state,
        loadingCompany: payload,
      };

    case LOAD_COMPANY_SKELETON_FORCE:
      return {
        ...state,
        loadCompanyForce: payload,
      };

    case SET_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBanner: payload,
      };

    case CLEAR_NOTIFICATION:
      return {
        ...state,
        clearAlert: payload,
      };

    case SET_ALL_COMPANY_APPOINTED_LIST:
      return {
        ...state,
        allAppointedList: payload,
      };

    case SET_ENABLE_TOUR_RIDE:
      return {
        ...state,
        enableTourRide: payload,
      };

    case HIDE_BOB_TOUR_RIDE:
      return {
        ...state,
        showBobTourRide: false,
        showBobTourRideEntry: false,
      };

    case COMPANY_VERIFICATION_STEP_FAILED:
      return {
        ...state,
        companyVerificationStepFailed: payload,
      };
    case SET_PENDING_COMPANY_SELECT:
      return {
        ...state,
        pendingCompanySelected: payload,
      };
    default:
      return state;
  }
}
