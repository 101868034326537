import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoaderAction, stopLoaderAction } from "../../actions/updateReducerValue";
import { pushMessages } from "../../actions/chat";
import MessageBox from "./MessageBox";
import { backendUrl } from "../../constants";
import ConversationViewSkeletonLayout from "./ConversationViewSkeletonLayout";

export const getName = (data) => {
  var nameResp = data && data.split(".").slice(-1)[0];
  return nameResp.toUpperCase() || "";
};

export const formatFileSize = (bytes,decimalPoint) => {
  if(bytes === 0) return '0 Bytes';
  var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const downloadMedia = (dispatch, media) => {
  const url = backendUrl + media.filename
  window.open(
    url,
    '_blank' // <- This is what makes it open in a new window.
  );
  // dispatch(startLoaderAction())
  // media.getContentTemporaryUrl().then(function (url) {
  //   dispatch(stopLoaderAction())
  //   // log media temporary URL
  //   window.open(
  //     url,
  //     '_blank' // <- This is what makes it open in a new window.
  //   );
  // });
}

export default function UserMessages(props) {
  const {conversation, client, openProfile, showProfile, setShowList, showList } = props;

  const dispatch = useDispatch();

  const sid = useSelector((state) => state.sid);
  const loadingStatus = useSelector((state) => state.loadingStatus);
  const participants =
    useSelector((state) => state.participants)[sid] ?? [];
  const messages = useSelector((state) => state.messages);
  const lastReadIndex = useSelector((state) => state.lastReadIndex);

  const pushMessageAction = (convoId, messages) => {
    dispatch(pushMessages(convoId, messages))
  }
  
  return (
    <>
      {sid && conversation && client ? (
        <>
          <MessageBox 
            key={sid}
            convoSid={sid}
            conversation={conversation}
            upsertMessage={pushMessageAction}
            client={client}
            messages={messages[sid]}
            loadingState={loadingStatus}
            participants={participants}
            lastReadIndex={lastReadIndex}
            openProfile={openProfile}
            showProfile={showProfile}
            showList={showList}
            setShowList={setShowList}
          />
        </>
      ) : (
        <>
          <ConversationViewSkeletonLayout type="MIDDLE"/>
        </>
      )}
    </>
  );
}
