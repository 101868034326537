import axionIns from "../axios";

const getShareholder = (payload) => {
  return axionIns.get("shareholders/" + payload).then((response) => {
    return response.data;
  });
};

const addShareholder = (payload) => {
  return axionIns
    .post("shareholders", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const updateShareholder = (shareHolderId, payload) => {
  return axionIns
    .put("shareholders/" + shareHolderId, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const deleteShareholder = (payload) => {
  return axionIns.delete("shareholders/" + payload).then((response) => {
    return response.data;
  });
};

const appointShareholder = (payload) => {
  return axionIns
    .put("shareholders/requestAppoint/" + payload)
    .then((response) => {
      return response.data;
    });
};

const pendingInviteShareholders = (companyId, payload) => {
  return axionIns
    .get("invite/pendinginvites/" + companyId + "/" + payload)
    .then((response) => {
      return response.data;
    });
};

const deleteInvite = (companyId, payload) => {
  return axionIns.delete("invite/" + companyId + '/' + payload).then((response) => {
    return response.data
  })
}


const addShareholderChannels = (payload) => {
  return axionIns.post("shareholders/initiatechat", JSON.stringify(payload)).then((response) => {
    return response.data
  })
}


const exportOject = {
  getShareholder,
  addShareholder,
  deleteShareholder,
  updateShareholder,
  appointShareholder,
  pendingInviteShareholders,
  deleteInvite,
  addShareholderChannels
};

export default exportOject;
