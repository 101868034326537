import moment from "moment";
import React, { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyActivity } from "../../actions/company";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function PaymentHistory() {
  const dispatch = useDispatch();
  const { selectedCompany, loadCompanyActivity, companyActivity, companyActivityCount } = useSelector((state) => state.company);
  const [page, setPage] = useState(1);
  const [filterType, setFilterType] = useState(-1);
  const [selectedPage, setSelectedPage] = useState(0);


  const getCompanyActivitylist = () => {
    if (selectedCompany.id) {
      dispatch(getCompanyActivity(selectedCompany.id, page, filterType))
    }
  }

  useEffect(() => {
    getCompanyActivitylist();
  }, [page])

  useEffect(() => {
    setPage(1)
    getCompanyActivitylist();
    setSelectedPage(0)
  }, [filterType])

  useEffect(() => {
    if (selectedCompany && selectedCompany.id) {
      setPage(1);
      setFilterType(-1)
      getCompanyActivitylist();
    }
  }, [selectedCompany])



  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSelectedPage(selectedPage)
    setPage(selectedPage + 1)
  };

  return (
    <>
      <div className="card-header pb-0 px-3">
        <div className="row">
          <div className="col-md-6 col-6">
            <div className="table-responsive capitalTable">
              <h6 className="business-font capital-head high-cap">
                Activity log
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-6 d-flex justify-content-end">
            {!loadCompanyActivity ? (
              <div className="filter-button">
                <DropdownButton
                  as={ButtonGroup}
                  key="down"
                  id="dropdown-button-drop-down"
                  drop="down"
                  variant="secondary"
                  title={`${filterType === -1 ? 'Latest' : "Oldest"}`}
                >
                  <Dropdown.Item className={`${filterType === -1 ? 'active' : ''}`} onClick={() => setFilterType(-1)}>Latest</Dropdown.Item>
                  <Dropdown.Item className={`${filterType === 1 ? 'active' : ''}`} onClick={() => setFilterType(1)}>Oldest</Dropdown.Item>
                </DropdownButton>
              </div>
            ): (
              <div>
                <ContentLoader width="100%" height={15} >
                  <rect rx="5" ry="5" width="100%" height="15" />
                </ContentLoader>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card-body pt-4 p-3">
        {/* <h6 className="text-uppercase text-body text-xs font-weight-bolder mb-3">
          Newest
        </h6> */}
        <ul className="list-group">

          {!loadCompanyActivity ? (
            companyActivity && companyActivity.length > 0 ? companyActivity.map((item, index) => (
              <div key={index} className="row mt-3">
                <div className="col-lg-8 col-xl-8  col-8">
                  <div className="d-flex flex-column">
                    <h6 className="profile-text text-sm mb-1">{item.description}</h6>
                    <span className="text-xs datetime">
                      <TimeFormat time={item.createdAt}/>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4  col-4">
                  <div className="d-flex-right text-right">
                    <h6 className="profile-text text-sm mb-1">{item.status === 1 ? 'Pending verification' : item.status === 2 ? "Completed" : "Rejected"}</h6>
                  </div>
                </div>
              </div>
            )) : (
              <div className="d-flex pt-3">
                <div className="d-flex flex-column justify-content-center ">
                  <h6 className="mb-0 text-sm table-heading">No Activity Log
                  </h6>
                </div>
              </div>
            )) : (
            <div>
              {[100, 90, 80, 70, 80, 75, 65, 60, 50, 55, 60, 90, 65, 70, 80].map((item, index) => (
                <ContentLoader key={index} width={item + '%'} height={15} >
                  <rect rx="5" ry="5" width={item + '%'} height="15" />
                </ContentLoader>
              ))}
            </div>
          )}




          {/* {companyActivity && companyActivity.length > 0 ? companyActivity.map((item, index) => (
            <li key={index} className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div className="d-flex align-items-center">
                <button className={`${item.symbol ? 'btn-outline-success' : 'btn-outline-danger'} btn btn-icon-only btn-rounded mb-0 me-3 btn-sm d-flex align-items-center justify-content-center `}>
                  <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </button>
                <div className="d-flex flex-column">
                  <h6 className="mb-1 share-Capital-text text-sm">
                    {item.description}
                  </h6>
                  <span className="text-xs">{moment(item.createdAt).format('DD MMMM YYYY,') + ' at ' + moment(item.createdAt).format(' h:mm A')} </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <p className="text-sm font-weight-bolder color-text-right">
                  - $ 2,500
                </p>
              </div>
            </li>
          )) : (
            <div className="d-flex pt-2">
              <div className="d-flex flex-column justify-content-center ">
                <h6 className="mb-0 text-sm table-heading text-center">No transaction history
                </h6>
              </div>
            </div>
          )} */}

        </ul>
        {/* {companyActivity.length > record && (
          <div className="row mt-3">
            <div className="col-lg-12 col-xl-12 col-12">
              <button className="btn btn-sm  view-button mb-4 mt-3" onClick={onLoad}>VIEW MORE</button>
            </div>
          </div>
        )} */}
        {companyActivityCount > 1 && (
          <div className="pagination-section">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={companyActivityCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              forcePage={selectedPage}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>
        )}
      </div>
    </>
  );
}
