import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { backendUrl, HOLDERS, POSITIONS } from "../../constants";
import { useNavigate } from "react-router";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";
import {
  removeIndividualInviteAction,
} from "../../actions/referralInvites";
import DenyPendingInvite from "./DenyPendingInvite";
import { denyPendingInviteAction, getpendingInviteAction, removePromoterInviteModalAction } from "../../actions/promoters";


export const getPositionName = (position) => {
  var returnVal = "";
  var filter = POSITIONS.filter((x) => x.value === position);
  if (filter.length > 0) {
    returnVal = filter[0].value === HOLDERS.promoters ?  filter[0].name : "Potential " + filter[0].name.toLocaleLowerCase();
  }
  return returnVal;
};


export default function IndividualInviteTableGlobal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasPermission } = useSelector((state) => state.company);

  const {
    referralInvites,
    loadReferral,
  } = useSelector((state) => state.referralInvites);
  const { pendingInvites } = useSelector((state) => state.promoters);

  const [addModalShow, setAddModalShow] = useState(false);
  const [record, setRecord] = useState(5);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [actionData, setActionData] = useState(null);

  const addModalClose = () => {
    setAddModalShow(false);
    navigate({ state: "" });
  };
  const handleAdd = () => setAddModalShow(true);

  const handleRemove = (item) => {
    setRemoveModalShow(true);
    setActionData(item);
  };

  const onLoad = () => {
    setRecord(record + 5);
  };

  useEffect(() => {
    dispatch(getpendingInviteAction());
  }, [dispatch]);

  const removeModalCloseConfirm = async () => {
    if (actionData.id) {
      await dispatch(denyPendingInviteAction(actionData?.company?._id, actionData.id));
    }
    setRemoveModalShow(false);
  };

  const handleAcknowledge = (item, indexValue) => {
    dispatch(removePromoterInviteModalAction(item?.token, indexValue))
  }

  return (
    <>
      <div className="share-officer">
        <div className="header-promoters">Pending invites</div>
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-6">
              </div>
              <div className="col-lg-6 col-6 my-auto text-end">
                &nbsp;
                {/* {hasPermission && (
                  <div className="dropdown float-lg-end">
                    <button
                      className="btn add-button add-button-shareholder m-0"
                      onClick={handleAdd}
                    >
                      Invite your friends
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2 pt-2">
            {!loadReferral ? (
              pendingInvites?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-sharholder">
                    <thead>
                      <tr className="tableHeadings">
                        <th className="position-heading">Received From</th>
                        <th className="company-heading">Company</th>
                        <th className="type-heading">Type</th>
                        <th className="date-heading">Date added</th>
                        <th className="action-heading">Actions</th>
                        <th className="status-heading">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-row-color">
                      {pendingInvites?.length > 0 &&
                        pendingInvites
                          ?.slice(0, record)
                          ?.map((item, index) => (
                            <tr className="" key={index}>
                              {/* <td>
                                <div className="d-flex">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 shareHolderName">
                                      <div
                                        className="shareholder-img"
                                        style={{
                                          backgroundImage: `url(${item &&
                                            item.profilePic &&
                                            item.profilePic.filename
                                            ? backendUrl +
                                            item.profilePic.filename
                                            : "assets/img/Frame1.svg"
                                            })`,
                                        }}
                                      />
                                      {item.type === HOLDERS.promoters ? (
                                        (item.first_name
                                          ? item.first_name
                                          : "") +
                                        " " +
                                        (item.first_name && item.last_name
                                          ? item.last_name
                                          : "")
                                      ) : (item.name)}
                                    </h6>
                                  </div>
                                </div>
                              </td> */}
                              <td className="text-sm table-heading date-padding">
                                <div
                                  className="shareCreateDate shareCreateEmail activity_wrapper limit-text cursor-pointer"
                                  title={item.user?.email}
                                >
                                  {item?.user?.first_name} {item?.user?.last_name} <br/>
                                  <span className="extraSmall">{item?.user?.email}</span>
                                </div>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <span className="shareCreateDate">
                                  {item.company?.companyName}
                                </span>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <span className="shareCreateDate">
                                  {getPositionName(item.type)}
                                </span>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <span className="shareCreateDate">
                                  <TimeFormat time={item.createdAt} type={1} />
                                </span>
                              </td>
                              <td className="action-icons d-flex align-item-center justify-content-center flex-direction-colum">

                                <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                  <i
                                    className="fas fa-check"
                                    aria-hidden="true"
                                    onClick={() => handleAcknowledge(item, index)}
                                  ></i>
                                </span>
                                &nbsp;&nbsp;

                                <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                  <i
                                    className="fas fa-times"
                                    aria-hidden="true"
                                    onClick={() => handleRemove(item)}
                                  ></i>
                                </span>

                                {/* <button className="btn active-button-second pending-button-second m-0">
                                  Click to acknowledge
                                </button>&nbsp;

                                <button className={`btn ${item?.acknowlegdeByUser ? "active-button-second" : ""} pending-button-second m-0`}>
                                  Deny
                                </button> */}


                                {/* {!item.isActive ? (<>&nbsp;</>) : (
                                  <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                    <i
                                      className="fas fa-trash-alt"
                                      aria-hidden="true"
                                      onClick={() => handleRemove(item)}
                                    ></i>
                                  </span>
                                )} */}
                              </td>

                              <td className="text-sm table-heading date-padding status-type">
                                <button className={`btn ${item.is_delete_requested ? "active-button-second" : ""} pending-button-second m-0`}>
                                  {item.is_delete_requested ? "Invite accepted" : "Pending"}
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {pendingInvites.length > record && (
                    <button
                      onClick={onLoad}
                      className="btn btn-sm  view-button mb-4 mt-3"
                    >
                      View all
                    </button>
                  )}
                </div>
              ) : (
                <div className="no-record d-flex pt-2 pb-2">
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 className="mb-0 text-sm table-heading ">
                      No new invites
                    </h6>
                  </div>
                </div>
              )
            ) : (
              <div className="table-responsive p-3">
                {[80, 60].map((item, index) => (
                  <ContentLoader key={index} width={item + "%"} height={15}>
                    <rect rx="5" ry="5" width={item + "%"} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Invites */}
        {/* <div className="header-promoters mt-5">Individual pending invites</div>
        <div className="card ">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-6">
                <h3 className="business-font capital-head high-cap px-2">
                  Pending invites
                </h3>
              </div>
              <div className="col-lg-6 col-6 my-auto text-end">
                {hasPermission && (
                  <div className="dropdown float-lg-end">
                    <button
                      className="btn add-button add-button-shareholder m-0"
                      onClick={handleAdd}
                    >
                      Invite your friends
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2 pt-2">
            {!loadPendingReferral ? (
              referralPendingInvites?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-sharholder">
                    <thead>
                      <tr className="tableHeadings">
                        <th className="name-heading">Name</th>
                        <th className="position-heading">Email address</th>
                        <th className="date-heading">Date added</th>
                        <th className="action-heading">Actions</th>
                        <th className="status-heading">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-row-color">
                      {referralPendingInvites?.length > 0 &&
                        referralPendingInvites
                          .slice(0, record)
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 shareHolderName">
                                      <div
                                        className="shareholder-img"
                                        style={{
                                          backgroundImage: `url(${item &&
                                              item.profilePic &&
                                              item.profilePic.filename
                                              ? backendUrl +
                                              item.profilePic.filename
                                              : "assets/img/Frame1.svg"
                                            })`,
                                        }}
                                      />
                                      {(item.first_name
                                        ? item.first_name
                                        : "") +
                                        " " +
                                        (item.first_name && item.last_name
                                          ? item.last_name
                                          : "")}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle text-sm">
                                <div
                                  className="shareCreateDate shareCreateEmail activity_wrapper limit-text cursor-pointer"
                                  title={item.email}
                                >
                                  {item.email}
                                </div>
                              </td>
                              <td className="align-middle ">
                                <span className="shareCreateDate">
                                  {moment(item.createdAt).format("DD/MM/YYYY")}
                                </span>
                              </td>
                              <td className="action-icons d-flex align-item-center justify-content-center flex-direction-colum">
                                <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                  <i
                                    className="fas fa-trash-alt"
                                    aria-hidden="true"
                                    onClick={() => handleRemove(item)}
                                  ></i>
                                </span>
                              </td>
                              <td className="status-type">
                                <button className="btn pending-button-second m-0 ">
                                  Pending
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  {referralPendingInvites.length > record && (
                    <button
                      onClick={onLoad}
                      className="btn btn-sm  view-button mb-4 mt-3"
                    >
                      View all
                    </button>
                  )}
                </div>
              ) : (
                <div className="no-record d-flex pt-2 pb-2">
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 className="mb-0 text-sm table-heading ">
                      No new invites
                    </h6>
                  </div>
                </div>
              )
            ) : (
              <div className="table-responsive p-3">
                {[90, 70].map((item, index) => (
                  <ContentLoader key={index} width={item + "%"} height={15}>
                    <rect rx="5" ry="5" width={item + "%"} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </div>
        </div> */}
      </div>
      <DenyPendingInvite
        removeModalShow={removeModalShow}
        removeModalClose={() => setRemoveModalShow(false)}
        removeModalCloseConfirm={removeModalCloseConfirm}
        actionData={actionData}
      />
    </>
  );
}
