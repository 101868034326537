import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header/header";
import UserList from "./userList";
import UserMessages from "./userMessages";
import UserProfile from "./userProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  getTwilioToken,
  setClientAction,
  upsertMessages,
  updateLoadingState,
  updateParticipants,
  updateUnreadMessages,
  startTyping,
  endTyping,
  upsertConversation,
  removeMessages,
  removeConversation,
  updateCurrentConversation,
  clearAttachments,
  getChannelList,
  chatLoadingAction,
  setChatLoadClientAction,
  setSidConversationAction,
  openLatestConversationAction,
  chatMediaGetAction,
  chatMediaPreviewerAction,
} from "../../actions/chat";
import { Client } from "@twilio/conversations";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { getShareHolderList } from "../../actions/shareHolder";
import { getOfficerList } from "../../actions/officer";
import { getPromoterAction } from "../../actions/promoters";
import ConversationViewSkeletonLayout from "./ConversationViewSkeletonLayout";
import { isMobile } from "react-device-detect";
import ImageViewer from 'react-simple-image-viewer';
import { getAllDocumentByChannelIdAction } from "../../actions/signnow";
import { PUSH_NOTIFICATION_MODULE } from "../../constants";

async function loadUnreadMessagesCount(convo, updateUnreadMessages, dispatch) {
  let count = 0;
  try {
    count =
      (await convo.getUnreadMessagesCount()) ??
      (await convo.getMessagesCount());
  } catch (e) {
    console.error("getUnreadMessagesCount threw an error", e);
  }
  dispatch(updateUnreadMessages(convo.sid, count));
}

async function handleParticipantsUpdate(
  participant,
  updateParticipants,
  dispatch
) {
  const result = await participant.conversation.getParticipants();
  dispatch(updateParticipants(result, participant.conversation.sid));
}

export default function MessageDetail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [connectionState, setConnectionState] = useState();
  const [showList, setShowList] = useState( isMobile ? true : false);
  const [clientIteration, setClientIteration] = useState(0);
  const [loadFirstChannel, setLoadFirstChannel] = useState(null);
  const sidRef = useRef("");
  const { client, loadClient, holdSidConversation, openedLatestConversation, currentImage, isViewerOpen, chatMediaFileName } = useSelector((state) => state.chat);

  const { user } = useSelector((state) => state.user);
  const { selectedCompany } = useSelector((state) => state.company);
  const sid = useSelector((state) => state.sid);
  sidRef.current = sid;
  const conversations = useSelector((state) => state.convos);
  const loadingStatus = useSelector((state) => state.loadingStatus);
  // const loadClient = useRef(true);
  const loadFirstRef = useRef(true);

  const [showProfile, setShowProfile] = useState(isMobile ? false : true);
  const { isChatLoading } = useSelector((state) => state.chat);

  useEffect(() => {
    if(isMobile) loadFirstRef.current = true;
  }, [])

  const handlePromiseRejection = async (func) => {
    return new Promise(async (resolve, reject) => {
      try {
        await func();
        resolve();
      } catch (e) {
        reject();
        throw e;
      }
    });
  };

  const updateTypingIndicator = (participant, sid, type) => {
    var localKimbo = localStorage.getItem("kimbocorp");
    if (localKimbo) {
      const localGetEmail = JSON.parse(localKimbo).email;
      const {
        attributes: { name },
        identity,
      } = participant;
      if (identity === localGetEmail) {
        return;
      }
      if (type === "start") {
        dispatch(startTyping(sid, name || identity || "Anonymous"));
      } else {
        dispatch(endTyping(sid, name || identity || "Anonymous"));
      }
    }
  };

  const openProfile = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    const { id } = selectedCompany;
    if (id) {
      const fetchData = async () => {
        await dispatch(getChannelList(id));
        dispatch(getShareHolderList(id));
        dispatch(getOfficerList(id));
        dispatch(getPromoterAction(id));
      };
      fetchData().catch(console.error);
    }
    return () => { };
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    if (loadClient.current) {
      dispatch(setChatLoadClientAction())
      getTwilioToken("").then(async (token) => {
        if (token.status) {
          const client = new Client(token.data);
          dispatch(setClientAction(client));

          client.on("conversationJoined", (conversation) => {
            // conversation.delete();
            dispatch(upsertConversation(conversation));

            conversation.on("typingStarted", (participant) => {
              handlePromiseRejection(() =>
                updateTypingIndicator(participant, conversation.sid, "start")
              );
            });

            conversation.on("typingEnded", async (participant) => {
              await handlePromiseRejection(async () =>
                updateTypingIndicator(participant, conversation.sid, "end")
              );
            });

            handlePromiseRejection(async () => {
              if (conversation.status === "joined") {
                const result = await conversation.getParticipants();
                dispatch(updateParticipants(result, conversation.sid));

                const messages = await conversation.getMessages();
                dispatch(upsertMessages(conversation.sid, messages.items));
                await loadUnreadMessagesCount(
                  conversation,
                  updateUnreadMessages,
                  dispatch
                );
              }
            });
          });

          client.on("conversationRemoved", async (conversation) => {
            dispatch(updateCurrentConversation(""));
            await handlePromiseRejection(async () => {
              dispatch(removeConversation(conversation.sid));
              dispatch(updateParticipants([], conversation.sid));
            });
          });
          client.on("messageAdded", async (message) => {
            await upsertMessage(message, upsertMessages, updateUnreadMessages);
            if (message.author === user.identity) {
              dispatch(clearAttachments(message.conversation.sid, "-1"));
            }
          });
          client.on("participantLeft", async (participant) => {
            await handlePromiseRejection(async () =>
              handleParticipantsUpdate(
                participant,
                updateParticipants,
                dispatch
              )
            );
          });
          client.on("participantUpdated", async (event) => {
            await handlePromiseRejection(async () =>
              handleParticipantsUpdate(
                event.participant,
                updateParticipants,
                dispatch
              )
            );
          });
          client.on("participantJoined", async (participant) => {
            await handlePromiseRejection(async () =>
              handleParticipantsUpdate(
                participant,
                updateParticipants,
                dispatch
              )
            );
          });
          client.on("conversationUpdated", async ({ conversation }) => {
            await handlePromiseRejection(() =>
              dispatch(upsertConversation(conversation))
            );
          });

          client.on("messageUpdated", async ({ message }) => {
            await handlePromiseRejection(async () =>
              upsertMessage(message, upsertMessages, updateUnreadMessages)
            );
          });

          client.on("messageRemoved", async (message) => {
            await handlePromiseRejection(() =>
              dispatch(removeMessages(message.conversation.sid, [message]))
            );
          });

          client.on("tokenAboutToExpire", async () => {
            const token = await getTwilioToken("refresh");
            if (token.status) {
              await client.updateToken(token.data);
            }
          });

          client.on("tokenExpired", async () => {
            const token = await getTwilioToken("refresh");
            if (token.status) {
              await client.updateToken(token.data);
              setClientIteration((x) => x + 1);
            }
          });

          client.on("connectionStateChanged", (state) => {
            setConnectionState(state);
          });

          updateLoadingState(false);
        }
        return () => {
          client?.removeAllListeners();
        };
      });
    }
  }, []);

  const upsertMessage = async (
    message,
    upsertMessages,
    updateUnreadMessages
  ) => {
    //transform the message and add it to redux
    await handlePromiseRejection(async () => {
      if (sidRef.current === message.conversation.sid) {
        await message.conversation.advanceLastReadMessageIndex(message.index);
      }
      dispatch(upsertMessages(message.conversation.sid, [message]));
      if (message?.attributes?.files?.length > 0) {
        dispatch(chatMediaGetAction(message.conversation.sid))
      }

      if(message?.attributes?.module === PUSH_NOTIFICATION_MODULE.ESIGN){
        dispatch(getAllDocumentByChannelIdAction(`${message.conversation.sid}`))
      }

      await loadUnreadMessagesCount(
        message.conversation,
        updateUnreadMessages,
        dispatch
      );
    });
  };


  // useEffect(() => {
  //   const convoId = searchParams.get("room");
  //   if (conversations && conversations.length > 0) {
  //     let localSidLoad = null;
  //     if (loadFirstRef && loadFirstRef.current) {
  //       loadFirstRef.current = false;
  //       if (convoId) {
  //         localSidLoad = convoId;
  //       } else {
  //         localSidLoad = conversations[0].sid;
  //       }
  //     } else {
  //       localSidLoad = sid;
  //     }
  //     if (holdSidConversation !== localSidLoad) {
  //       if (localSidLoad) {
  //         var filter = conversations.find((convo) => convo.sid === localSidLoad);
  //         if (filter) {
  //           setLoadFirstChannel(filter);
  //         }
  //         dispatch(setSidConversationAction(localSidLoad))
  //         dispatch(openLatestConversationAction(conversations.find((convo) => convo.sid === localSidLoad)))
  //         // return conversations.find((convo) => convo.sid === localSidLoad);
  //       }
  //     }
  //   }
  // }, [sid, conversations])


  // const openedConversation = useMemo(() => {
  //   const convoId = searchParams.get("room");
  //   if (conversations && conversations.length > 0) {
  //     let localSidLoad = null;
  //     if (loadFirstRef && loadFirstRef.current) {
  //       if (convoId) {
  //         localSidLoad = convoId;
  //       } else {
  //         localSidLoad = conversations[0].sid;
  //       }
  //     } else {
  //       localSidLoad = sid;
  //     }

  //     if (localSidLoad) {
  //       if(holdSidConversation){
  //         loadFirstRef.current = false;
  //       }
  //       if (holdSidConversation !== localSidLoad) {
  //         var filter = conversations.find((convo) => convo.sid === localSidLoad);
  //         if (filter) {
  //           setLoadFirstChannel(filter);
  //           dispatch(setSidConversationAction(localSidLoad))
  //           dispatch(openLatestConversationAction(filter))
  //         }
  //       }
  //       return conversations.find((convo) => convo.sid === localSidLoad);
  //     }
  //   }
  // }, [sid, conversations]);



  const openedConversation = useMemo(() => {
    const convoId = searchParams.get("room");
    if (conversations && conversations.length > 0) {
      let localSidLoad = null;

      if (loadFirstRef.current) {
        if (convoId) {
          localSidLoad = convoId;
        } else {
          localSidLoad = conversations[0].sid;
        }
      } else {
        localSidLoad = sid;
      }

      if (localSidLoad) {
        return conversations.find((convo) => convo.sid === localSidLoad);
      }
    }
    return null;
  }, [sid, conversations]);

  useEffect(() => {
    if (openedConversation) {
      if (holdSidConversation) {
        loadFirstRef.current = false;
      }
      if (holdSidConversation !== openedConversation.sid) {
        setLoadFirstChannel(openedConversation);
        dispatch(setSidConversationAction(openedConversation.sid));
        dispatch(openLatestConversationAction(openedConversation));
      }
    }
  }, [openedConversation, holdSidConversation, dispatch]);


  // const openedConversation = useMemo(() => {
  //   const convoId = searchParams.get("room");
  //   if (conversations && conversations.length > 0) {
  //     let localSidLoad = null;
  //     if (loadFirstRef && loadFirstRef.current) {
  //       loadFirstRef.current = false;
  //       if (convoId) {
  //         localSidLoad = convoId;
  //       } else {
  //         localSidLoad = conversations[0].sid;
  //       }
  //     } else {
  //       localSidLoad = sid;
  //     }
  //     if (holdSidConversation !== localSidLoad) {
  //       if (localSidLoad) {
  //         // var filter = conversations.find((convo) => convo.sid === localSidLoad);
  //         // if (filter) {
  //         //   setLoadFirstChannel(filter);
  //         // }
  //         dispatch(setSidConversationAction(localSidLoad))
  //         return conversations.find((convo) => convo.sid === localSidLoad);
  //       }
  //     }
  //   }
  // }, [sid, conversations]);

  if (conversations === undefined || conversations === null) {
    return <div className="empty">Empty Div</div>;
  }


  const closeImageViewer = () => {
    dispatch(chatMediaPreviewerAction(0, false));
  };

  return (
    <>
      <Header changePosition={true} title="" subtitle="" />
      <div className="container-fluid message-page">
        {isMobile && (
          <div
            className={`userlistToggle ${showList ? 'hasShowList' : 'noShowList'}`}
            onClick={() => setShowList(!showList)}
          >
            {showList ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </>
            )}
          </div>
        )}
        <div className="row messageRow">
          <div
            className={
              showList
                ? "col-md-4 col-lg-3 messageLeft customScroll showUserlist"
                : "col-md-4 col-lg-3 messageLeft customScroll"
            }
          >
            {/* {isMobile && (
              <div className="closeUserList"><i className="fa-solid fa-xmark"></i></div>
            )} */}

            <UserList
              loadFirstChannel={loadFirstChannel}
              setShowList={setShowList}
              showList={showList}
              loadFirstRef={loadFirstRef.current}
            />
          </div>
          <div
            className={
              showProfile
                ? "col-md-5 col-lg-6 messageRight collapsediv"
                : "col-md-8 col-lg-9 messageRight"
            }
          >
            <UserMessages
              conversation={openedConversation}
              client={client}
              setShowList={setShowList}
              showList={showList}
              loadingState={loadingStatus}
              openProfile={openProfile}
              showProfile={showProfile}
            />
          </div>
          <div
            className={
              showProfile
                ? "col-md-3 col-lg-3 userProfile customScroll showProfile"
                : "col-md-3 userProfile customScroll"
            }
          >

            {openedConversation && !isChatLoading ? (
              <UserProfile
                conversation={openedConversation}
                client={client}
                setShowProfile={setShowProfile}
              />
            ) : (
              <ConversationViewSkeletonLayout type="RIGHT" />
            )}
          </div>
        </div>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={chatMediaFileName}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ background: '#52594d', zIndex: 9999999999999 }}
        />
      )}
    </>
  );
}
