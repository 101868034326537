import React from "react";
import { useLocation } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { Link } from "react-router-dom";

export default function Productheader({handleScrollToSection}) {
  const location = useLocation();

  return (
    <div className="topHeaderHolder">
      <div className="topShadeSection">
        <div className="container-fluid screenResolation">
          <div className="row mobile-overflow">
            <div className="col-6 col-md-4 toptext">
              <a
                href="https://wa.me/+6588993720"
                target="_blank"
                rel="noreferrer"
              >
                <p className="m-0">Whatsapp +65 8899 3720</p>
              </a>
            </div>
            <div
              className="col-6 col-md-4 toptext"
              style={{ textAlign: "center" }}
            >
              <a
                href="mailto:support@inproved.com"
                rel="noreferrer"
                target="_blank"
              >
                <p className="m-0">support@inproved.com</p>
              </a>
            </div>
            <div
              className="col-6 col-md-4 toptext"
              style={{ textAlign: "right" }}
            >
              <a href="tel:+6569505010" target="_blank" rel="noreferrer">
                <p className="m-0">Product enquiries +65 6950 5010</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="headerBottomSection">
        <div className="container-fluid screenResolation">
          <div className="row">
            <div className="col-6 col-md-6 alignimgSec">
              <Link to={RoutesPaths.PRODUCT_LANDING_PAGE}>
                <img
                  src="assets/img/landingPage/InProvedLogo.svg"
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </div>
            <div
              className="col-6 col-md-6 alignMobSec"
              style={{ textAlign: "right" }}
            >
              {location.pathname !== "/appstaging/thank-you" && (
                <button className="registerBuy" onClick={handleScrollToSection}>Register to Buy</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
