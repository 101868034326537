import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import company from "./company";
import capital from "./capital";
import shareHolder from "./shareHolder";
import officers from "./officers";
import files from "./files";
import user from "./user";
import task from "./task";
import promoters from "./promoters";
import stripe from "./stripe";
import banner from "./banner";
import myinfo from "./myinfo";
import chat from "./chat";
import objective from "./objectiveReducer";
import signNow from "./signNow";
import { DESTROY_SESSION } from "../actions/types";


import convoReducer, { ReduxConversation } from "./convoReducer";
import sidReducer from "./currentConvoReducer";
import messageReducer, { ChatMessagesState } from "./messageListReducer";
import loadingReducer from "./loadingReducer";
import participantReducer, { ParticipantsType } from "./participantsReducer";
import unreadMessagesReducer, { UnreadMessagesState, } from "./unreadMessagesReducer";
import attachmentsReducer, { AttachmentsState } from "./attachmentsReducer";
import typingDataReducer, { TypingDataState } from "./typingDataReducer";
import lastReadIndexReducer from "./lastReadIndexReducer";
import userGuide from './userGuide'
import bookOfBusiness from "./bookOfBusiness"
import subscriptionPlanProducts from "./subscriptionPlanProducts"
import settings from "./settings"
import userDashboard from "./userDashboard"
import referralInvites from "./referralInvites"
import companyDashboard from "./companyDashboard";

const appReducer = combineReducers({
  auth,
  message,
  company,
  capital,
  shareHolder,
  officers,
  files,
  user,
  task,
  promoters,
  stripe,
  banner,
  myinfo,
  chat,
  objective,
  userGuide,
  signNow,
  bookOfBusiness,
  subscriptionPlanProducts,
  settings,
  userDashboard,
  referralInvites,
  companyDashboard,
  convos: convoReducer,
  sid: sidReducer,
  lastReadIndex: lastReadIndexReducer,
  messages: messageReducer,
  loadingStatus: loadingReducer,
  participants: participantReducer,
  unreadMessages: unreadMessagesReducer,
  attachments: attachmentsReducer,
  typingData: typingDataReducer,
});

const rootReducer = (state, action) => {
  // // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    state = undefined;
    action = { type: '@@INIT' }
  }
  return appReducer(state, action);
};
export default rootReducer;