import React from "react";
import { Modal } from "react-bootstrap";

export default function PromoteModal({ modalShow, modalClose }) {
  return (
    <>
      <Modal className="custom-modal" show={modalShow} onHide={modalClose}>
        <div className="modal-header p-0">
          
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={modalClose}
          >
          </button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title">
            Promoter
          </h5>
          <p className="edit-description">
            A promoter is a firm or a person who does the preliminary work
            related to the starting of business, including its promotion,
            progression, incorporation and solicits people to be stakeholder of
            the business.
          </p>
          <p className="edit-description">
            Any person, including consultants, lawyers, engineers and banker can
            perform the role of promoter. Promoters generally owe a duty of
            utmost good faith, so as not to mislead any potential stakeholders,
            and disclose all material fact about company’s business.
          </p>

          <p className="edit-description">
            Generally, promoters are in a fiduciary relationship with the
            business and its shareholders, and must avoid conflicts of interests
            and exercise reasonable care in performing their duties. They must
            refrain from self dealing or other type of abuse to take advantage
            of their position as a promoter, self dealing occurs, for example,
            when a promoter unfairly profits from the conduct of business by
            charging higher prices for the goods they sell to the business then
            it would otherwise pay.
          </p>
          <span className="edit-description">The fiduciary duties of promoters include:</span>
          <ul className="edit-description">
            <li>
                not to make any secret profit out of the promotion of business.
                Secret profit is made by entering into a transaction on their
                behalf and then selling the concerned property back to the
                business at a profit, without disclosing the profits to its
                members. Promoters can make profits in dealing with the
                business, provided they disclose these profits to the business
                and its member.
            </li>
            <li>
                to make full disclosure to the business of all relevant facts,
                including any profit made by them in transactions with the
                business.
            </li>
          </ul>
          <button
            type="button"
            onClick={modalClose}
            className="btn agree-button save-btn got-it-btn"
          >
            Got it
          </button>
        </div>
      </Modal>
    </>
  );
}
