import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TWO_FACTOR_LOGIN_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  SET_AUTH_USER_INFO,
  SET_VERIFICATION_EMAIL,
  INIT_POPUP,
  TOGGLE_MOBILE_MENU,
  TOGGLE_MENU_SIDEBAR,
  RESET_PASSWORD_RESPONSE,
  SET_RESET_PASSWORD_VERIFICATION_EMAIL,
  TOGGLE_ASK_ADDA,
  USER_GUIDE_POPUP,
  SET_LOGIN_EMAIL_TEMPORARILY,
  SET_COMPANY_MENU_ACTION,
  SET_COMPANY_ADD_SHOW_ACTION
} from "../actions/types";
const user = localStorage.getItem("kimbocorp")
  ? JSON.parse(localStorage.getItem("kimbocorp"))
  : null;
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
// const zendeskToken = localStorage.getItem("zendeskToken") ? localStorage.getItem("zendeskToken") : null;
const initialState = {
  isLoggedIn: user ? true : false,
  user: user,
  token: token,
  // zendeskToken: zendeskToken,
  twoFactorStorage: null,
  loader: false,
  verificationEmail: null,
  initPopup: null,
  sidebarMobile: false,
  showMenuSidebar: false,
  resetPasswordId: null,
  verificationResetEmail: null,
  askOpen: false,
  isAdminLoggedIn: user?.isAdminLogin ? true : false,
  userGuideOpen: false,
  temporaryLoginEmail: null,
  companyMenuShow: false,
  companyAddShow: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        isAdminLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isAdminLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isAdminLoggedIn: payload?.user?.isAdminLogin ? true : false,
        user: payload.user,
        token: payload.user.token,
        // zendeskToken: payload.user.zendeskToken,
        twoFactorStorage: null,
        initPopup: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isAdminLoggedIn: false,
        user: null,
        token: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isAdminLoggedIn: false,
        user: null,
        token: null,
        initPopup: false,
      };
    case TWO_FACTOR_LOGIN_SUCCESS:
      return {
        ...state,
        twoFactorStorage: payload,
      };
    case START_LOADER:
      return {
        ...state,
        loader: true,
      };
    case STOP_LOADER:
      return {
        ...state,
        loader: false,
      };
    case SET_AUTH_USER_INFO:
      return {
        ...state,
        user: payload,
      };
    case SET_VERIFICATION_EMAIL:
      return {
        ...state,
        verificationEmail: payload,
      };
    case INIT_POPUP:
      return {
        ...state,
        initPopup: false,
      };

    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        sidebarMobile: !state.sidebarMobile,
      };

    case TOGGLE_MENU_SIDEBAR:
      return {
        ...state,
        showMenuSidebar: payload,
      };

    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        resetPasswordId: payload,
      };
    case SET_RESET_PASSWORD_VERIFICATION_EMAIL:
      return {
        ...state,
        verificationResetEmail: payload,
      };
    case TOGGLE_ASK_ADDA:
      return {
        ...state,
        askOpen: payload,
      };
    case USER_GUIDE_POPUP:
      return {
        ...state,
        userGuideOpen: payload,
      };
    
    case SET_LOGIN_EMAIL_TEMPORARILY:
      return {
        ...state,
        temporaryLoginEmail: payload,
      };

    case SET_COMPANY_MENU_ACTION:
      return {
       ...state,
        companyMenuShow: payload,
      };

    case SET_COMPANY_ADD_SHOW_ACTION:
      return {
        ...state,
        companyAddShow: payload,
      };    

    default:
      return state;
  }
}
