import React from "react";
import { Modal } from "react-bootstrap";
import { getPositionName } from "./IndividualInviteTableGlobal";

export default function DenyPendingInvite({
  removeModalShow,
  removeModalClose,
  removeModalCloseConfirm,
  actionData,
}) {
  return (
    <Modal
      show={removeModalShow}
      onHide={removeModalClose}
      className="custom-modal delete-information-width"
    >
      <div className="modal-header p-0">
        <button
          type="button"
          className="btn-close text-dark close-button"
          onClick={removeModalClose}
        ></button>
      </div>
      <div className="modal-body">
        <h5 className="edit-title">
          Are you sure you want to deny this invite received from
        </h5>
        <div className="row">
          <div className="col-lg-12">
            <p className="input-text heading">Name</p>
            <p className="col-form-label company-text p-0 m-0">
              {(actionData && actionData?.user?.first_name
                ? actionData?.user?.first_name
                : "") +
                " " +
                (actionData && actionData?.user?.first_name && actionData?.user?.last_name
                  ? actionData?.user?.last_name
                  : "")}&nbsp;
                {`(${actionData?.user?.email})`}
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <p className="input-text heading">Company</p>
            <p className="col-form-label company-text p-0 m-0">
              {actionData?.company?.companyName}
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <p className="input-text heading">Position</p>
            <p className="col-form-label company-text p-0 m-0">
              {getPositionName(actionData?.type)}
            </p>
          </div>
        </div>

        {/* <p className="import-note">
          Important note:{" "}
          <span className="will-need-text">
            We will send you notifications to confirm the amendment
          </span>
        </p> */}

        <div className="modal-footer modal-bottom-action">
          <button
            onClick={removeModalCloseConfirm}
            type="button"
            className="btn agree-button"
          >
            Agree & Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}
