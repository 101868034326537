import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import InfoModal from "./infoModal";
import { Controller, useForm } from "react-hook-form";
import { CAPITAL, CONTACT_US_TRIGER, CUSTOM_SELECT_STYLES, SELECT_BOX_THEME } from "../../constants";
import { addCapital } from "../../actions/capital";
import { useDispatch, useSelector } from "react-redux";
import ContactUs from "../../components/ContactUs/ContactUs";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import ContentLoader from "react-content-loader";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import Select from "react-select";
import toast from "react-hot-toast";

export default function CapitalCard(props) {
  const dispatch = useDispatch();
  const { hasPermission, loadCompany, isDefaultCompany } = useSelector((state) => state.company);
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [capModalShow, setCapModalShow] = useState(false);
  const [infoModalShow, setInfoModalShow] = useState(false);

  const capModalClose = () => {
    setCapModalShow(false);
    reset();
  };
  const handleCapShow = () => {
    setCapModalShow(true);
    reset();
  };

  const [contactLoader, setContactLoader] = useState(true);

  const onSubmit = async (data) => {
    setContactLoader(true);
    if (props?.company?.id) {
      data.company = props.company?.id;
      data.capitalType = props.capitalType;
      data.shareType = parseInt(data?.shareType?.value);
      data.currency = data?.currency?.value;

      const paidUpCapitalPayload = {
        shareCapital: data.shareCapital,
        currency: data.currency,
        shareType: data.shareType,
        company: data.company,
        capitalType: CAPITAL.paidUp
      }

      await dispatch(addCapital(data));
      await dispatch(addCapital(paidUpCapitalPayload));
      reset();
      capModalClose();
    }
    setContactLoader(false);
  };

  useEffect(() => {
    setContactLoader(false);
  }, [])

  const pageType = props?.page ? props?.page : null;

  const location = useLocation();
  const tabData = location.state?.formData || 1;

  const navigate = useNavigate();

  const togglePayment = () => {
    navigate(RoutesPaths.PAYMENT, { state: { formData: tabData } });
  }

  return (
    <>
      <div className="card-body px-0 pb-3 pt-3">
        <div className="table-responsive capitalTable overflowScroll">

          <div className="d-flex align-items-center justify-content-between px-3">
            <div className="d-flex flex-column justify-content-center">
              <h3 className="business-font capital-head high-cap">
                {props.title}&nbsp;
                {pageType === null && (
                  <img
                    src="assets/img/info-icon.svg"
                    onClick={() => setInfoModalShow(true)}
                    alt=""
                    className="m-0 pb-1 cursorPointer"
                  />
                )}
              </h3>
            </div>
            <div className={`d-flex flex-column justify-content-center ${props.capitalType === CAPITAL.shared ? "companySpotlightStep3" : ""}`}>
              {props?.company?.id && (
                <>
                  {hasPermission && !isDefaultCompany && props.capitalType === CAPITAL.shared && (
                    <span
                      className="btn btn-link  px-3 mb-0 edit-button"
                      onClick={handleCapShow}
                    >
                      <i
                        className="fas fa-pencil-alt edit-button me-2"
                        aria-hidden="true"
                      ></i>
                    </span>
                  )}
                  {props.capitalType === CAPITAL.paidUp ? (
                    <span
                      className="btn btn-link  px-3 mb-0 edit-button with-image"
                      onClick={togglePayment}
                    >
                      <img
                        src="assets/img/pay.svg"
                        alt=""
                        className="m-0 p-1 cursorPointer"
                      />
                    </span>
                    // <div className="d-flex px-2 justify-content-end">
                    //   <div className="d-flex flex-column justify-content-center">
                    //     <button className="tableButtonpayup" onClick={togglePayment}>
                    //       <img
                    //         src="assets/img/pay.svg"
                    //         alt=""
                    //         className="m-0 p-1 cursorPointer"
                    //       />
                    //     </button>
                    //   </div>
                    // </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>

          <table className="table mb-0 overflowScroll">
            <tbody>
              <tr>
                <th>
                  <div className="d-flex px-2">
                    <div className="d-flex flex-column justify-content-center ">
                      <h6 className="mb-0 text-sm table-heading">
                        {props.subtitle}
                      </h6>


                    </div>
                  </div>
                </th>
                {pageType !== null && (
                  <>
                    <th></th>
                  </>
                )}
                {props.capitalType === CAPITAL.shared ? (
                  <th>
                    <div className="d-flex px-2">
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm table-heading">
                          Number of shares
                        </h6>
                      </div>
                    </div>
                  </th>
                ) : (
                  <>
                    <th>
                      <div className="d-flex px-2">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading">&nbsp;</h6>
                        </div>
                      </div>
                    </th>
                  </>
                )}
                {pageType === null ? (
                  <>
                    <th>
                      <div className="d-flex px-2">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading">
                            Currency
                          </h6>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex px-2">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading">
                            Share type
                          </h6>
                        </div>
                      </div>
                    </th>
                  </>
                ) : (
                  <>
                    <th></th>
                  </>
                )}
                {/* <th className="text-right">
                  {props?.company?.id && (
                    <>
                      {hasPermission && !isDefaultCompany && props.capitalType === CAPITAL.shared && (
                        <span
                          className="btn btn-link  px-3 mb-0 edit-button"
                          onClick={handleCapShow}
                        >
                          <i
                            className="fas fa-pencil-alt edit-button me-2"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                      {props.capitalType === CAPITAL.paidUp ? (
                        <div className="d-flex px-2 justify-content-end">
                          <div className="d-flex flex-column justify-content-center">
                            <button className="tableButtonpayup" onClick={togglePayment}>
                              <img
                                src="assets/img/pay.svg"
                                alt=""
                                className="m-0 p-1 cursorPointer"
                              />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </th> */}
              </tr>
              {props?.capital &&
                props?.capital.length > 0 &&
                props?.capital.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">
                              $ <CurrencyFormat value={item.shareCapital} />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                    {pageType !== null && (
                      <>
                        <td></td>
                      </>
                    )}
                    {props.capitalType === CAPITAL.shared ? (
                      <td>
                        {!loadCompany ? (
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center">
                              <span className="business-font">
                                <CurrencyFormat value={item.shares} />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <ContentLoader width="100%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </div>
                        )}
                      </td>
                    ) : (
                      <>
                        <td>
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center">
                              <span className="business-font">&nbsp;</span>
                            </div>
                          </div>
                        </td>
                      </>
                    )}
                    {pageType === null ? (
                      <>
                        <td>
                          {!loadCompany ? (
                            <div className="d-flex px-2 ">
                              <div className="d-flex flex-column justify-content-center">
                                <span className="business-font ">
                                  {item.currency}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                        <td>
                          {!loadCompany ? (
                            <div className="d-flex px-2 ">
                              <div className="d-flex flex-column justify-content-center">
                                <span className="business-font ">
                                  {item.shareType === 1
                                    ? "Ordinary"
                                    : item.shareType === 2
                                      ? "Preference"
                                      : ""}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                      </>
                    )}
                    <td>
                      {!loadCompany && props?.company?.id && (
                        <button
                          className="btn amendment-progress-button"
                          onClick={handleCapShow}
                          hidden
                        >
                          Amendment in progress
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              {props.capital && props.capital.length === 0 && (
                <tr>
                  <td>
                    {!loadCompany ? (
                      <div className="d-flex px-2 ">
                        <div className="d-flex flex-column justify-content-center ">
                          <span className="business-font">-</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <ContentLoader width="100%" height={15}>
                          <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                        </ContentLoader>
                      </div>
                    )}
                  </td>
                  {props.capitalType === CAPITAL.shared ? (
                    <td>
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                  ) : (
                    <td>
                      <div className="d-flex px-2 ">
                        <div className="d-flex flex-column justify-content-center ">
                          <span className="business-font">&nbsp;</span>
                        </div>
                      </div>
                    </td>
                  )}
                  <td>
                    {!loadCompany ? (
                      <div className="d-flex px-2 ">
                        <div className="d-flex flex-column justify-content-center ">
                          <span className="business-font">-</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <ContentLoader width="100%" height={15}>
                          <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                        </ContentLoader>
                      </div>
                    )}
                  </td>
                  <td>
                    {!loadCompany ? (
                      <div className="d-flex px-2 ">
                        <div className="d-flex flex-column justify-content-center ">
                          <span className="business-font">-</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <ContentLoader width="100%" height={15}>
                          <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                        </ContentLoader>
                      </div>
                    )}
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {!loadCompany && props?.company?.id && (
          <>
            {!props.capitalIsApproved && (
              <div className="additional-action pt-2">
                <button className="btn amendment-progress-button-second">
                  Acceptance in progress
                </button>
              </div>
            )}
            {!props.capitalIsEditApproved && (
              <div className="additional-action pt-2">
                <button className="btn amendment-progress-button">
                  Amendment in progress
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* My Info */}
      {props?.company?.verificationMethod === "myinfo" && props?.myInfoCapital && props?.myInfoCapital.length > 0 && (
        <>
          <hr></hr>
          <div className="card-body px-0 pb-3 pt-3">
            <div className="table-responsive capitalTable overflowScroll">


              <div className="d-flex align-items-center justify-content-between px-3">
                <div className="d-flex flex-column justify-content-center">
                  <h3 className="business-font capital-head high-cap">
                    MyInfo {props.title}&nbsp;
                    {pageType === null && (
                      <img
                        src="assets/img/info-icon.svg"
                        onClick={() => setInfoModalShow(true)}
                        alt=""
                        className="m-0 pb-1 cursorPointer"
                      />
                    )}
                  </h3>
                </div>
              </div>

              <table className="table mb-0">
                <tbody>
                  <tr>
                    <th>
                      <div className="d-flex px-2">
                        <div className="d-flex flex-column justify-content-center ">
                          <h6 className="mb-0 text-sm table-heading">
                            {props.subtitle}
                          </h6>
                        </div>
                      </div>
                    </th>
                    {pageType !== null && (
                      <>
                        <th></th>
                      </>
                    )}
                    {props.capitalType === CAPITAL.shared ? (
                      <th>
                        <div className="d-flex px-2">
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 text-sm table-heading">
                              Number of shares
                            </h6>
                          </div>
                        </div>
                      </th>
                    ) : (
                      <>
                        <th>
                          <div className="d-flex px-2">
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="mb-0 text-sm table-heading">
                                &nbsp;
                              </h6>
                            </div>
                          </div>
                        </th>
                      </>
                    )}
                    {pageType === null ? (
                      <>
                        <th>
                          <div className="d-flex px-2">
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="mb-0 text-sm table-heading">
                                Currency
                              </h6>
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex px-2">
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="mb-0 text-sm table-heading">
                                Share Type
                              </h6>
                            </div>
                          </div>
                        </th>
                      </>
                    ) : (
                      <>
                        <th></th>
                      </>
                    )}
                    <th className="text-right">
                      {/* {props?.company?.id && (
                    <>
                      {hasPermission && (
                        <span
                          className="btn btn-link  px-3 mb-0 edit-button"
                          onClick={handleCapShow}
                        >
                          <i
                            className="fas fa-pencil-alt edit-button me-2"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                    </>
                  )} */}
                    </th>
                  </tr>
                  {props?.myInfoCapital &&
                    props?.myInfoCapital.length > 0 &&
                    props?.myInfoCapital.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {!loadCompany ? (
                            <div className="d-flex px-2 ">
                              <div className="d-flex flex-column justify-content-center ">
                                <span className="business-font">
                                  $ <CurrencyFormat value={item.shareCapital} />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                        {pageType !== null && (
                          <>
                            <td></td>
                          </>
                        )}
                        {props.capitalType === CAPITAL.shared ? (
                          <td>
                            {!loadCompany ? (
                              <div className="d-flex px-2 ">
                                <div className="d-flex flex-column justify-content-center">
                                  <span className="business-font">
                                    <CurrencyFormat value={item.shares} />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <ContentLoader width="100%" height={15}>
                                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                </ContentLoader>
                              </div>
                            )}
                          </td>
                        ) : (
                          <>
                            <td>
                              <div className="d-flex px-2 ">
                                <div className="d-flex flex-column justify-content-center">
                                  <span className="business-font">&nbsp;</span>
                                </div>
                              </div>
                            </td>
                          </>
                        )}
                        {pageType === null ? (
                          <>
                            <td>
                              {!loadCompany ? (
                                <div className="d-flex px-2 ">
                                  <div className="d-flex flex-column justify-content-center">
                                    <span className="business-font ">
                                      {item.currency}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </div>
                              )}
                            </td>
                            <td>
                              {!loadCompany ? (
                                <div className="d-flex px-2 ">
                                  <div className="d-flex flex-column justify-content-center">
                                    <span className="business-font ">
                                      {item.shareType.desc}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </div>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td></td>
                          </>
                        )}
                        <td>
                          {!loadCompany && props?.company?.id && (
                            <button
                              className="btn amendment-progress-button"
                              onClick={handleCapShow}
                              hidden
                            >
                              Amendment in progress
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  {props.myInfoCapital && props.myInfoCapital.length === 0 && (
                    <tr>
                      <td>
                        {!loadCompany ? (
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center ">
                              <span className="business-font">-</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <ContentLoader width="100%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </div>
                        )}
                      </td>
                      {props.capitalType === CAPITAL.shared ? (
                        <td>
                          {!loadCompany ? (
                            <div className="d-flex px-2 ">
                              <div className="d-flex flex-column justify-content-center ">
                                <span className="business-font">-</span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <ContentLoader width="100%" height={15}>
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                              </ContentLoader>
                            </div>
                          )}
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center ">
                              <span className="business-font">&nbsp;</span>
                            </div>
                          </div>
                        </td>
                      )}
                      <td>
                        {!loadCompany ? (
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center ">
                              <span className="business-font">-</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <ContentLoader width="100%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </div>
                        )}
                      </td>
                      <td>
                        {!loadCompany ? (
                          <div className="d-flex px-2 ">
                            <div className="d-flex flex-column justify-content-center ">
                              <span className="business-font">-</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <ContentLoader width="100%" height={15}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                            </ContentLoader>
                          </div>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      <Modal
        show={capModalShow}
        onHide={capModalClose}
        className="moddal-width custom-modal companyEdit-modal ps-0"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={capModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">
                {props.capital.length === 0 ? "Add" : "Edit"}{" "}
                {props.capitalType === CAPITAL.shared
                  ? "share"
                  : props.capitalType === CAPITAL.paidUp
                    ? "paid-up"
                    : ""}{" "}
                capital
              </h5>
              {props.capitalType === CAPITAL.shared && (
                <div>
                  <p className="edit-description">
                    Every business needs a minimum of 1 share and 1 dollar (SGD or USD), as a share capital. Some businesses require a
                    larger share capital for reasons such as tendering for a
                    contract or attracting stakeholders.
                  </p>
                  <p className="edit-description">
                    You can add shares, share capital, in SGD or USD
                    and determine the share type as many times as you need to.
                    You cannot subtract shares and share capital.
                  </p>
                  <p className="edit-description">
                    We will be sending you notifications to confirm the share
                    capital additions.
                  </p>
                </div>
              )}

              {props.capitalType === CAPITAL.paidUp && (
                <div>
                  <p className="edit-description">
                    Every business needs a minimum of 1 Singapore dollar, as a
                    paid-up capital. Some businesses require a larger paid-up
                    capital for reasons such as tendering for a contract or
                    attracting stakeholders.
                  </p>
                  <p className="edit-description">
                    You can increase paid-up capital, in different currencies
                    and determine the share type as many times as you need to.
                    You cannot subtract paid-up capital.
                  </p>
                  <p className="edit-description">
                    We will be sending you notifications to confirm paid-up
                    capital additions.
                  </p>
                </div>
              )}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7 mt-4 mt-lg-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">
                        Current{" "}
                        {props.capitalType === CAPITAL.shared
                          ? "share"
                          : props.capitalType === CAPITAL.paidUp
                            ? "paid-up"
                            : ""}{" "}
                        capital
                      </p>
                      <span className="business-font">
                        {props.capital.length > 0 && "$ "}{" "}
                        {props.capital.length === 0 ? (
                          "-"
                        ) : (
                          <CurrencyFormat
                            value={
                              props.capital[props.capital.length - 1]
                                .shareCapital
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">
                          Add{" "}
                          {props.capitalType === CAPITAL.shared
                            ? "share"
                            : props.capitalType === CAPITAL.paidUp
                              ? "paid-up"
                              : ""}{" "}
                          capital
                        </p>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Amount to add"
                          maxLength={20}
                          {...register("shareCapital", {
                            required: true,
                            min: { 
                              value: 100, // Minimum value
                              message: 'Minimum value must be at least 100' 
                            } 
                          })}
                          onKeyPress={(event) => {
                            // Allow only numbers, Backspace, and Delete keys
                            const allowedChars = /[0-9\b\.]/;
                            if (!allowedChars.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.shareCapital?.type === "min" && (
                          <span className="errorMessage">{errors?.shareCapital?.message}</span>
                        )}
                        {errors.shareCapital?.type === "required" && (
                          <span className="errorMessage">
                            Add{" "}
                            {props.capitalType === CAPITAL.shared
                              ? "Share"
                              : props.capitalType === CAPITAL.paidUp
                                ? "Paid-Up"
                                : ""}{" "}
                            capital is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {props.capitalType === CAPITAL.shared && (
                  <div className="row mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                      <div className="form-group">
                        <p className="input-text heading">Number of shares</p>
                        <span className="business-font">
                          {props.capital.length === 0
                            ? "-"
                            : props.capital[props.capital.length - 1].shares}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                      <div className="form-group">
                        <div>
                          <p
                            htmlFor="recipient-name"
                            className="input-text heading"
                          >
                            Add number of shares
                          </p>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Number of shares to be added"
                            maxLength={20}
                            {...register("shares", {
                              required: true,
                            })}
                            onKeyPress={(event) => {
                              // Allow only numbers, Backspace, and Delete keys
                              const allowedChars = /[0-9\b\.]/;
                              if (!allowedChars.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.shares?.type === "required" && (
                            <span className="errorMessage">
                              Number of shares to be added
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">Currency</p>
                      <span className="business-font">
                        {props.capital.length === 0
                          ? "-"
                          : props.capital[props.capital.length - 1].currency}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">
                          {props.capitalType === CAPITAL.shared
                            ? "Share"
                            : props.capitalType === CAPITAL.paidUp
                              ? "Paid-Up"
                              : ""}{" "}
                          capital currency
                        </p>
                        <Controller
                          name="currency"
                          rules={{ required: true }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="select-border newFormSelect"
                              placeholder="Choose currency"
                              {...field}
                              options={[
                                {
                                  value: 'USD',
                                  label: "USD"
                                }, {
                                  value: "SGD",
                                  label: "SGD"
                                }
                              ]}
                              getOptionValue={(option) => `${option.value}`}
                              theme={SELECT_BOX_THEME}
                              styles={CUSTOM_SELECT_STYLES}
                            >
                            </Select>
                          )}
                        />
                        {/* <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("currency", {
                            required: true,
                          })}
                        >
                          <option value="USD">USD</option>
                          <option value="SGD">SGD</option>
                        </Form.Select> */}
                        {errors.currency?.type === "required" && (
                          <span className="errorMessage">
                            Select your currency
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">Share type</p>
                      <span className="business-font">
                        {props.capital.length === 0
                          ? "-"
                          : props.capital[props.capital.length - 1]
                            .shareType === 1
                            ? "Ordinary"
                            : "Preference"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">Choose share type</p>

                        <Controller
                          name="shareType"
                          rules={{ required: true }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="select-border newFormSelect"
                              placeholder="Choose share type"
                              {...field}
                              options={[
                                {
                                  value: 1,
                                  label: "Ordinary"
                                }, {
                                  value: 2,
                                  label: "Preference"
                                }
                              ]}
                              getOptionValue={(option) => `${option.value}`}
                              theme={SELECT_BOX_THEME}
                              styles={CUSTOM_SELECT_STYLES}
                            >
                            </Select>
                          )}
                        />






                        {/* <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("shareType", {
                            required: true,
                          })}
                        >
                          <option value="1">Ordinary</option>
                          <option value="2">Preference</option>
                        </Form.Select> */}
                        {errors.shareType?.type === "required" && (
                          <span className="errorMessage">
                            Select your share type
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note: &nbsp;
                  <span className="will-need-text">
                    By proceeding, we will notify you directly and advise you
                    accordingly on the next steps to formalise the changes.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs type={CONTACT_US_TRIGER.SHARE_CAPITAL} handleClose={capModalClose} />
                  <button type="submit" className="btn agree-button" disabled={contactLoader}>
                    {contactLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)}&nbsp;Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <InfoModal
        infoModalShow={infoModalShow}
        infoModalClose={() => setInfoModalShow(false)}
        capitalType={props.capitalType}
      />
    </>
  );
}
