import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { ADMIN_USER, backendUrl, CUSTOM_SELECT_STYLES, HOLDERS, SELECT_BOX_THEME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoaderAction,
  stopLoaderAction,
} from "../../actions/updateReducerValue";
import { addParticipantAction, removeParticipantAction } from "../../actions/chat";

export default function ManagePeople({
  messageModal,
  addModalClose,
  participants,
  currentConvoSid,
  isAdminConversation
}) {
  const {
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.user);
  const { channelList } = useSelector((state) => state.chat);
  const { shareHolder } = useSelector((state) => state.shareHolder);
  const { officers } = useSelector((state) => state.officers);
  const [listType, SetListType] = useState([])
  const [chatShowUserList, SetChatShowUserList] = useState([])
  const [chatUserList, setChatUserList] = useState([])
  const [removedUser, setRemovedUser] = useState([]);
  const [isShowChatBtn, setisShowChatBtn] = useState(false);

  const onSubmit = async (data) => {
    try {
      let { id } = selectedCompany;
      if (id && currentConvoSid) {
        let members = [];
        chatUserList.forEach((element) => {
          var email = element.email
          if (element.identity) {
            email = element.identity
          }
          if (!members.includes(email)) {
            members.push(email);
          }
        });

        dispatch(startLoaderAction())

        if (removedUser.length > 0) {
          await removeParticipantAction({ "channel": currentConvoSid, "member": removedUser })
        }

        if (members.length > 0) {
          await addParticipantAction({ "channel": currentConvoSid, "member": members })
        }
        dispatch(stopLoaderAction())
        addModalCloseFunc()
      }
    } catch (e) {
      dispatch(stopLoaderAction());
    }
  };

  const removeChatUser = (index, removedUserEmail) => {
    const newList = [...chatUserList];
    newList.splice(index, 1);
    setChatUserList(newList);
    if (removedUserEmail) {
      const prepareRemovedUser = removedUser;
      if (!prepareRemovedUser.includes(removedUserEmail)) {
        setRemovedUser([...removedUser, removedUserEmail])
      }
    }
  };

  useEffect(() => {
    setisShowChatBtn(chatUserList.length > 1 ? true : false);
  }, [chatUserList]);

  useEffect(() => {
    if (messageModal) {
      const effectUpdate = () => {
        var prepareListType = []
        const excludedKeys = [HOLDERS.secretary, HOLDERS.officer];
        for (const [key, value] of Object.entries(HOLDERS)) {
          if (!excludedKeys.includes(value)) {
            prepareListType = [...prepareListType, { value: value, label: key.charAt(0).toUpperCase() + key.slice(1) }]
          }
        }
        SetListType(prepareListType)
        setChatUserList([...chatUserList, ...participants]);
      }
      effectUpdate()
    }
  }, [messageModal]);


  useEffect(() => {
    const setHoldersValue = () => {
      if (getValues('holderType') && getValues('holderType').value) {
        switch (getValues('holderType').value) {
          case HOLDERS.shareHolders:
            SetChatShowUserList(shareHolder.filter(x => x.email !== ""))
            break;
          case HOLDERS.directors:
            SetChatShowUserList(officers.filter(x => x.email !== ""))
            break;
          case HOLDERS.promoters:
            SetChatShowUserList(selectedCompany.promoters)
            break;
          default:
            break;
        }
      }
    }
    setHoldersValue()
  }, [watch(["holderType"])])

  const onChange = (obj) => {
    const exists = chatUserList.find((user) => (user.email === obj.email) || (user.identity === obj.email));
    if (exists) return;
    setChatUserList([...chatUserList, obj]);
  };


  const addModalCloseFunc = () => {
    SetListType([])
    SetChatShowUserList([])
    setChatUserList([])
    setRemovedUser([])
    addModalClose();
  }

  const showRemove = (identity) => {
    let returnVal = true;
    const channelListInfo = channelList.find(x => x.channelId === currentConvoSid)
    if (identity === user.email || identity === channelListInfo?.masterUser?.email || ADMIN_USER.includes(identity)) {
      returnVal = false
    }
    return returnVal
  }

  return (
    <>
      <Modal
        show={messageModal}
        onHide={addModalCloseFunc}
        className="custom-modal add-new-member"
        id="addModalMessage"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalCloseFunc}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="edit-title">
                      Add/Remove Chat Members
                    </h5>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-group message-section">
                      <Controller
                        name="holderType"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={listType}
                            isClearable={true}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            placeholder="Select Position"
                          />
                        )}
                      />
                      {errors.holderType?.type === "required" && (
                        <span className="errorMessage">
                          Select Position
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group message-section">
                      <Controller
                        render={() => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            options={chatShowUserList}
                            getOptionLabel={(option) => ('position' in option) ? option.name : option.first_name + " " + option.last_name}
                            getOptionValue={(option) => option.email}
                            value=""
                            onChange={onChange}
                            placeholder="Invite people to join the conversation..."
                          />
                        )}
                        name="select"
                        control={control}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4 conversation-text-margin">
                    <p className="conversation-text mb-3">
                      Have a conversation with:
                    </p>
                    <div className="row conversation-section" id="style-1 customScroll">
                      {chatUserList &&
                        chatUserList.length > 0 &&
                        chatUserList.map((item, index) => (
                          <div
                            key={index}
                            className="col-lg-12 inner-section"
                          >
                            {item.identity ? (
                              <div
                                className="profile-img"
                                style={{
                                  backgroundImage: `url(${item &&
                                    item.attributes &&
                                    item.attributes.image
                                    ? backendUrl + item.attributes.image
                                    : "assets/img/Frame1.svg"
                                    })`,
                                }}
                              ></div>
                            ) : (
                              <div
                                className="profile-img"
                                style={{
                                  backgroundImage: `url(${item &&
                                    item.profilePic &&
                                    item.profilePic.filename
                                    ? backendUrl + item.profilePic.filename
                                    : "assets/img/Frame1.svg"
                                    })`,
                                }}
                              ></div>
                            )}
                            <div className="profile-name">
                              {item.identity ? (
                                item.attributes.name || item.identity
                              ) : (
                                ('position' in item) ? item.name : item.first_name + " " + item.last_name
                              )}
                              {(showRemove(item.identity) && !isAdminConversation) && (
                                <img
                                  src="assets/img/Close-circle.svg"
                                  alt=""
                                  className="cursor-pointer"
                                  onClick={() => removeChatUser(index, item.identity ? item.identity : null)}
                                />
                              )}
                            </div>
                            {/* {!item.identity && (
                              <>
                                {!item.isEditApproved && !item.isAppoint && (
                                  <span>Amendement in progress</span>
                                )}
                                {!item.isDeleteApproved && (
                                  <span>Removal in progress</span>
                                )}
                                {!item.isApproved ||
                                  (item.isAppoint && item.isDeleteApproved && (
                                    <span>Acceptance in progress</span>
                                  ))}
                              </>
                            )} */}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <p className="import-note">Important note: <span className="will-need-text">Please be aware that newly added members can still access previous chat messages. </span></p>
                <div className="modal-footer modal-bottom-action justify-content-center">
                  {isShowChatBtn && (
                    <button type="submit" className="btn agree-button">
                      Save changes
                    </button>
                  )}
                  {!isShowChatBtn && (
                    <button
                      type="button"
                      disabled
                      className="btn disable-agree-button"
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
