import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmailFromTokenAction, login, socialLogin, verifiedOTP } from "../../actions/auth";
import { useEffect } from "react";
import Otp from "../../components/OTP/Otp";
import { GoogleLogin } from "react-google-login";
import { kimbo_google_client_id, secretPass } from "../../constants";
import { Link, useSearchParams } from "react-router-dom";
import { LOGIN_TYPE } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import { sendOtpAuthentication, verifOtpAction } from "../../actions/user";
import { toast } from "react-hot-toast";
import CryptoJS from "crypto-js";

export default function SignIn() {
  const [otpShow, setOtpShow] = useState(false);
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams()
  const { twoFactorStorage, isLoggedIn, token, temporaryLoginEmail } = useSelector((state) => state.auth);
  const [otpPhoneNumber, SetOtpPhoneNumber] = useState(null);
  const navigate = useNavigate();
  const [passwordView, setPasswordView] = useState(false);
  const loadRef = useRef(true);
  const loadRefSignIn = useRef(true);
  const {
    register,
    handleSubmit,
    setValue,
    // reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.type = LOGIN_TYPE.NORMAL
    data.token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
    if(localStorage.getItem("refToken")){
      data.refToken = localStorage.getItem("refToken");
    }
    dispatch(login(data))
  };

  useEffect(() => {
    if (loadRef.current) {
      // if (twoFactorStorage && twoFactorStorage.token) {
      //   setOtpShow(true)
      //   setTimeout(() => { recapchaVerifier(); }, 1000);
      // }

      if (localStorage.getItem("credential")) {
        var credentials = JSON.parse(localStorage.getItem("credential"))
        const { email, password, rememberme } = credentials.payload;
        const bytes = CryptoJS.AES.decrypt(password, secretPass);
        const data = bytes.toString(CryptoJS.enc.Utf8) ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : '';
        setValue('email', email)
        setValue('password', data)
        setValue('rememberme', rememberme)
      }
    }
    getLogin();
    return () => {
      loadRef.current = false
    }
  }, [isLoggedIn, twoFactorStorage, setValue]);


  useEffect(() => {
    if (twoFactorStorage && twoFactorStorage.twoFactor) {
      if (twoFactorStorage.areaCode + twoFactorStorage.phone) {
        SetOtpPhoneNumber(twoFactorStorage.areaCode + twoFactorStorage.phone)
        setOtpShow(true)
      } else {
        toast.error("Something wen't wrong, please try again later")
      }
    }
  }, [twoFactorStorage])


  const getLogin = () => {
    if (isLoggedIn) {
      navigate(RoutesPaths.DASHBOARD);
    }
  };


  const googleResponse = async (response) => {
    dispatch(socialLogin(response.tokenId))
      .then(() => {
        navigate(RoutesPaths.DASHBOARD);
      })
      .catch(() => { });
  }
  const onFailure = async (response) => {
  }

  useEffect(() => {
    sendOTP();
  }, [otpPhoneNumber])

  // Sent OTP
  const sendOTP = () => {
    if (otpPhoneNumber) {
      const payload = {
        phoneNumber: otpPhoneNumber
      }
      dispatch(sendOtpAuthentication(payload, twoFactorStorage && twoFactorStorage.token ? twoFactorStorage.token : null))
    }
  }

  const ValidateOtp = async (otp) => {
    var payload = {
      otp: otp
    }
    const verifyStatus = await dispatch(verifOtpAction(payload, twoFactorStorage && twoFactorStorage.token ? twoFactorStorage.token : null))
    if (verifyStatus) {
      setOtpShow(false)
      dispatch(verifiedOTP(twoFactorStorage))
    }
  }

  const handleOtp = (value) => {
    if (value.length === 6) {
      ValidateOtp(value)
    }
  }
  const handleResendOtpClick = () => {
    sendOTP();
  }


  useEffect(() => {
    const token = queryParameters.get("token");
    if (loadRefSignIn.current) {
      const takeAction = async () => {
        if (token) {
          dispatch(getUserEmailFromTokenAction(token, navigate))
        }
      }
      takeAction().catch(console.error);
    }

    return () => {
      loadRefSignIn.current = false
    }
  }, [queryParameters]);


  useEffect(() => {
    if(temporaryLoginEmail){
      setValue('email', temporaryLoginEmail)
    }
  }, [temporaryLoginEmail])


  return (
    <>
      <section className="signIn-back">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                <div className="card card-plain card-login">
                  <div className="card-header pb-0 text-left bg-transparent">
                    <h3 className="font-weight-bolder text-info text-gradient">
                      Sign in
                    </h3>

                    <p className="mb-0">
                      Enter your username and password to sign in
                    </p>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <label>Email</label>
                      <div className="mb-3">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="errorMessage">Email is invalid</span>
                        )}
                      </div>
                      <label>Password</label>
                      <div className="mb-3 handleEye">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type={passwordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", { required: true, minLength: 8 })}
                        />
                        <div className="viewEye" onClick={() => setPasswordView(!passwordView)}><i className={`fa fa-eye${passwordView ? "-slash" : ""}`} aria-hidden="true"></i></div>
                        {errors.password?.type === "required" && (
                          <span className="errorMessage">
                            Password is required
                          </span>
                        )}
                        {errors.password?.type === "minLength" && (
                          <span className="errorMessage">
                            Password must be atleast 8 characters long{" "}
                          </span>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check form-switch">
                            <input
                              maxLength={50}
                              autoComplete="nope"
                              className="form-check-input"
                              type="checkbox"
                              id="rememberMe"
                              {...register("rememberme", { required: false })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check form-switch text-right">
                            <Link
                              to={RoutesPaths.FORGOT_PASSWORD}
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Forgot your password?
                            </Link>
                          </div>
                        </div>
                      </div>


                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bg-gradient-info w-100 mt-4 mb-0"
                        >
                          Sign in
                        </button>
                      </div>

                      {/* <button
                          type="button"
                          onClick={googleLogin}
                          className="btn w-100 mt-4 mb-0 google-login"
                        >
                          <img src="assets/img/google-logo.svg" alt="" />
                          Log In With Google{" "}
                        </button> */}

                        {/* <GoogleLogin
                          clientId={kimbo_google_client_id}
                          buttonText="Login"
                          render={renderProps => (

                            <button
                              type="button"
                              onClick={renderProps.onClick}
                              className="btn w-100 mt-4 mb-0 google-login"
                            >
                              <img src="assets/img/google-logo.svg" alt="" />
                              Log In With Google{" "}
                            </button>
                          )}
                          onSuccess={googleResponse}
                          onFailure={onFailure}
                        /> */}
                    </form>
                  </div>

                  <div className="card-footer  pt-0 px-lg-2 px-1">
                    <p className="mb-4 text-sm mx-auto">
                      Don't have an account?{" "}
                      <Link
                        to={RoutesPaths.SIGNUP}
                        className="text-dark font-weight-bolder"
                      >
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                {/* <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8 sign-in-img">
                  <div className="oblique-image signIn-back  position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Otp otpShow={otpShow} otpClose={() => [setOtpShow(false), SetOtpPhoneNumber(null)]} handleOtp={handleOtp} resendOtpClick={handleResendOtpClick} />
    </>
  );
}
