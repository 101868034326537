import axionIns from "../axios";

const getTemplateList = async () => {
  return axionIns.get(`signnow/getTemplateList`).then((response) => { return response.data });
};

const createSignnowDocument = async (payload) => {
  return axionIns.post("signnow/createDocument", JSON.stringify(payload)).then((response) => { return response.data });
};

const getAllDocumentByChannelId = async (channelId) => {
  return axionIns.get(`signnow/getAllDocumentByChannelId/${channelId}`).then((response) => { return response.data });
};

const getSignatureDetailsByDocumentId = async (documentId) => {
  return axionIns.get(`signnow/getSignerDetailsById/${documentId}`).then((response) => { return response.data });
};

const getFileDownloadDocument = async (documentId) => {
  return axionIns.get(`signnow/getFileDownloadDocument/${documentId}`).then((response) => { return response.data });
};

const exportOject = {
  getTemplateList,
  createSignnowDocument,
  getAllDocumentByChannelId,
  getSignatureDetailsByDocumentId,
  getFileDownloadDocument
};

export default exportOject;
