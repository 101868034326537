import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import ShareTable from "../../components/sharetable/shareTable";
import ActivityCard from "./activityCard";
import AddressCard from "./addressCard";
import BusinessCard from "./businessCard";
import CapitalCard from "./capitalCard";
import AddressEdit from "./AddressEdit";
import { useDispatch, useSelector } from "react-redux";
import OfficerTable from "../../components/officer/OfficerTable";
import { getCapitalList } from "../../actions/capital";
import { CAPITAL, HOLDERS_PAGE_TYPE, MESSAGE_TYPE } from "../../constants";
import { getStripeSubscriptionAction } from "../../actions/stripe";
import { useLocation, useNavigate } from "react-router-dom";
import LicensesTable from "../../components/License/LicensesTable";
import { getMyInfoDataAction } from "../../actions/myinfo";
import BusinessDetail from "./businessDetail";
import { getChannelList, sendMessageToAdmin } from "../../actions/chat";
import ContentLoader from "react-content-loader";
import BuillionAccount from "./buillionAccount/buillionAccount";
import Joyride, { STATUS, EVENTS, ACTIONS } from "react-joyride";
import { updateUserExperienceAction } from "../../actions/company";
import { companyLink } from "../../components/ContactUs/ContactUs";
import SpeakToAdmin from "../../components/SpeakToAdmin/SpeakToAdmin";
import DashboardCarousel from "./dashboardCarousel";

export default function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    selectedCompany,
    hasPermission,
    loadingCompany,
    isDefaultCompany,
    steps,
    showTourRide,
    notificationBanner,
    bobNotification,
    enableTourRide,
  } = useSelector((state) => state.company);
  const { bannerList } = useSelector((state) => state.banner);

  const showDefaultJoyRideDashboard =
    Object.keys(notificationBanner).length === 0 &&
      Object.keys(bobNotification).length === 0
      ? true
      : false;
  const { officers } = useSelector((state) => state.officers);
  const { user } = useSelector((state) => state.user);
  const { isSubscribed } = useSelector((state) => state.stripe);
  const {
    shareCapital,
    paidUpCapital,
    myInfoShareCapital,
    myInfopaidUpCapital,
    sharedCapitalIsApproved,
    sharedCapitalIsEditApproved,
    paidUpCapitalIsApproved,
    paidUpCapitalIsEditApproved,
  } = useSelector((state) => state.capital);
  const [addrModalShow, setAddrModalShow] = useState(false);
  const [capitalSlider, setCapitalSlider] = useState(true);
  const [appointSlider, setAppointSlider] = useState(true);
  // const [myInfo, setMyInfo] = useState();

  const [run, SetRun] = useState(false);
  const [stepIndex, SetStepIndex] = useState(0);
  const [skipTriggered, setSkipTriggered] = useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    if (enableTourRide) {
      setTimeout(() => {
        SetRun(enableTourRide);
      }, 400);
    } else {
      SetRun(false);
    }
  }, [enableTourRide]);

  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    setCurrentStep(data.index);
    if (finishedStatuses.includes(status)) {
      const { id } = selectedCompany;
      if (id) dispatch(updateUserExperienceAction(id, { showTourRide: false }));
      SetRun(false);
      // EVENTS.STEP_BEFORE,
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      SetStepIndex(nextStepIndex);

      if (!isDefaultCompany) {
        if (index === 1) {
          if (action === ACTIONS.PREV) {
            SetRun(false);
            setAppointSlider(true);
            if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
              SetStepIndex(index);
            } else {
              SetStepIndex(stepIndex - 1);
            }
            setTimeout(() => {
              SetRun(true);
            }, 1500);
          }
          if (action === ACTIONS.NEXT) {
            SetRun(false);
            setAppointSlider(false);
            if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
              SetStepIndex(index);
            } else {
              SetStepIndex(stepIndex + 1);
            }
            setTimeout(() => {
              SetRun(true);
            }, 1500);
          }
        } else {
          SetStepIndex(nextStepIndex);
        }
      } else {
        SetStepIndex(nextStepIndex);
      }
    }
  };

  useEffect(() => {
    SetStepIndex(0);
    SetRun(false);
    const queryParams = new URLSearchParams(location.search);
    if (location.search) {
      const code = queryParams.get("code") || null;
      const state = queryParams.get("state") || null;
      const error = queryParams.get("error") || null;
      const errorDesc = queryParams.get("error-description") || null;
      if ((code && state) || (error && state)) {
        var splitState = state.split("-");
        const identify = splitState[splitState.length - 1];
        if (splitState.length > 0) {
          dispatch(
            getMyInfoDataAction({
              status: code ? true : false,
              code: code ? code : null,
              reason: errorDesc,
              company: splitState[0],
              errorType: error,
            })
          );
        }
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    const { id } = selectedCompany;
    if (id) {
      dispatch(getChannelList(id));
      dispatch(getCapitalList(id));
      dispatch(getStripeSubscriptionAction(id, 1, 100));
    }
  }, [dispatch, selectedCompany, hasPermission, user]);

  const addrModalClose = () => {
    setAddrModalShow(false);
  };

  useEffect(() => {
    if (
      location.state === "inviteDirector" ||
      location.state === "appointDirector"
    ) {
      setAppointSlider(false);
    }
  }, [location]);

  const checkCompanyPermission = () => {
    var returnValue = false;
    if (user.id === selectedCompany.user) returnValue = true;

    if (!returnValue) {
      const directorList = officers?.filter((x) => x.email === user.email);
      if (directorList.length > 0) returnValue = true;
    }
    return returnValue;
  };

  useEffect(() => {
    let timeout;
    if (run && !skipTriggered) {
      timeout = setTimeout(() => {
        SetStepIndex((prevIndex) => prevIndex + 1);
        setSkipTriggered(true);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [run, skipTriggered]);

  const handleCloseNotification = () => {
    const { id } = selectedCompany;
    if (id) dispatch(updateUserExperienceAction(id, { showTourRide: false }));
    SetRun(false);
  };

  return (
    <>
      <div className="hideGuide"></div>
      <Joyride
        locale={{
          skip: "X",
          next: "Next",
          last: "Got it",
        }}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        continuous
        hideCloseButton
        run={run}
        stepIndex={stepIndex}
        scrollToFirstStep
        // showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            fontSize: "10px",
          },
        }}
        hideBackButton={currentStep === 1}
      />
      <Header
        title="Dashboard"
        subtitle={
          (selectedCompany && selectedCompany.companyName
            ? selectedCompany.companyName
            : "") + `${isDefaultCompany ? "" : " Business Dashboard"}`
        }
        load={true}
      />

      {selectedCompany &&
        selectedCompany.id &&
        showDefaultJoyRideDashboard &&
        showTourRide &&
        !isDefaultCompany && (
          <div className="container-fluid pt-4 pb-0 container-top landingPage">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 mb-md-0 mb-0">
                <div className="banner success">
                  <p>Find out how to edit your holding company</p>
                  <button
                    type="button"
                    onClick={() =>
                      setTimeout(() => {
                        SetRun(showTourRide);
                      }, 1500)
                    }
                    className={false ? "btn agree-button" : "bannerBtn"}>
                    Learn more
                  </button>
                  <button
                    type="button"
                    className="close-banner"
                    onClick={handleCloseNotification}
                    aria-label="Close">
                    <img src="assets/img/close-banner.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="container-fluid py-4 container-top landingPage paddingBottomMD">
        {isDefaultCompany && <DashboardCarousel />}
        {loadingCompany ? (
          selectedCompany && selectedCompany.id && !isDefaultCompany ? (
            <>
              <div className="row my-0 mb-lg-4">
                <div className="col-lg-12 col-xl-12 col-md-12 mb-md-0 mb-0">
                  <BusinessCard
                    company={selectedCompany}
                    permission={checkCompanyPermission}
                  />
                </div>
              </div>

              <div className="row my-0 my-lg-4">
                <div className="col-lg-8 col-xl-8 col-md-12 mb-md-0 mb-4 minHeightVH">
                  <div className="card businessDetail">
                    <BusinessDetail company={selectedCompany} />
                  </div>

                  <div className="card mt-4">
                    <ActivityCard
                      company={selectedCompany}
                      permission={checkCompanyPermission}
                    />
                  </div>
                  <div className="card mt-4">
                    <AddressCard permission={checkCompanyPermission} />
                  </div>
                  <div className="card mt-4">
                    <LicensesTable type={HOLDERS_PAGE_TYPE.DASHBOARD} />
                  </div>
                  <div className="capitalHolder">
                    {/* <h5 className="sliderHeader">Capital</h5> */}
                    <span
                      className={`sliderText ${capitalSlider ? "active" : ""}`}
                      onClick={() => setCapitalSlider(true)}>
                      Share capital
                    </span>
                    <span
                      className={`sliderText ${!capitalSlider ? "active" : ""}`}
                      onClick={() => setCapitalSlider(false)}>
                      Paid-up capital
                    </span>
                    {capitalSlider ? (
                      <div className="card mt-3">
                        <CapitalCard
                          company={selectedCompany}
                          capitalType={CAPITAL.shared}
                          capital={shareCapital}
                          myInfoCapital={myInfoShareCapital}
                          capitalIsApproved={sharedCapitalIsApproved}
                          capitalIsEditApproved={sharedCapitalIsEditApproved}
                          title="Share capital"
                          subtitle="Share capital amount"
                        />
                      </div>
                    ) : (
                      <div className="card mt-3">
                        <CapitalCard
                          company={selectedCompany}
                          capitalType={CAPITAL.paidUp}
                          capital={paidUpCapital}
                          myInfoCapital={myInfopaidUpCapital}
                          capitalIsApproved={paidUpCapitalIsApproved}
                          capitalIsEditApproved={paidUpCapitalIsEditApproved}
                          title="Paid-up capital"
                          subtitle="Paid-up capital Amount"
                        />
                      </div>
                    )}
                  </div>

                  <div className="capitalHolder">
                    {/* <h5 className="sliderHeader">Insert Label Here</h5> */}
                    <span
                      className={`sliderText ${appointSlider ? "active" : ""}`}
                      onClick={() => setAppointSlider(true)}>
                      Shareholders
                    </span>
                    <span
                      className={`sliderText ${!appointSlider ? "active" : ""}`}
                      onClick={() => setAppointSlider(false)}>
                      Directors
                    </span>
                    {appointSlider ? (
                      <div className="row my-0 my-md-4 share-officer">
                        <div
                          className={`col-lg-${isSubscribed !== null && !isSubscribed ? "12" : "12"
                            } col-xl-${isSubscribed !== null && !isSubscribed ? "12" : "12"
                            } col-md-12 mb-md-0 mb-4 mt-4 mt-md-0`}>
                          <ShareTable type={HOLDERS_PAGE_TYPE.DASHBOARD} />
                        </div>
                      </div>
                    ) : (
                      <div className="row my-0 my-md-4 share-officer officer-section">
                        {/* isSubscribed !== null && !isSubscribed ? "8" : "12" old */}
                        <div
                          className={`col-lg-${isSubscribed !== null && !isSubscribed ? "12" : "12"
                            } col-xl-${isSubscribed !== null && !isSubscribed ? "12" : "12"
                            } col-md-12 mb-md-0 mb-4 mt-4 mt-md-0`}>
                          <OfficerTable type={HOLDERS_PAGE_TYPE.DASHBOARD} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 mt-lg-0 mt-md-3 mt-xl-0 mt-0 mt-lg-4 mb-4 mb-lg-0">
                  <div className="card">
                    <div className="card-header card-border">
                      <div className="card-body full-background card-formalize">
                        <h2 className="business-font mt-0">
                          {" "}
                          We can build holding companies for you
                        </h2>
                        <h6 className="mb-0 text-sm table-heading mt-3">
                          Sit back to enjoy owning from Singapore:
                        </h6>
                        <ul>
                          <li>Investment businesses like FOs and SPVs</li>
                          <li>Active businesses like consulting</li>
                          <li>Regulated businesses like crypto and funds</li>
                        </ul>

                        <div className="buttonLink">
                          <SpeakToAdmin />
                          {/* <button
                            disabled={formaliseLoader}
                            onClick={sendMessageToAdminfunc}
                            className="btn card-btn m-0 companySpotlightStep5"
                          >
                            {formaliseLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Speak to admin
                          </button> */}
                          {/* <Link to={RoutesPaths.PAYMENT} className="btn card-btn m-0">
                            Formalise my holding company
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <CommonCard
              title="UEN"
              type={MODAL_BUSINESS_IDENTIFY.UEN}
              company={selectedCompany.uen}
            />
            <CommonCard
              title="Registration Date"
              type={MODAL_BUSINESS_IDENTIFY.REGISTRATION_DATE}
              company={
                selectedCompany.registrationDate &&
                moment(selectedCompany.registrationDate).format("DD/MM/YYYY")
              }
            />
            <CommonCard
              title="Entity Type"
              type={MODAL_BUSINESS_IDENTIFY.ENTITY_TYPE}
              company={selectedCompany.entityType}
            />
            <CommonCard
              title="Entity Status"
              type={MODAL_BUSINESS_IDENTIFY.ENTITY_STATUS}
              company={selectedCompany.entityStatus}
            />
            <CommonCard
              title="Company Type"
              type={MODAL_BUSINESS_IDENTIFY.COMPANY_TYPE}
              company={selectedCompany.companyType}
            />
            <CommonCard
              title="Country of Incorporation"
              type={MODAL_BUSINESS_IDENTIFY.COUNTRY_OF_INCORPORATION}
              company={selectedCompany.countryOfIncorporation}
            />
            <CommonCard
              title="Business Expiry"
              type={MODAL_BUSINESS_IDENTIFY.BUSINESS_EXPIRY}
              company={
                selectedCompany.businessExpiry &&
                moment(selectedCompany.businessExpiry).format("DD/MM/YYYY")
              }
            />
            <CommonCard
              title="Business Constitution"
              type={MODAL_BUSINESS_IDENTIFY.BUSINESS_CONSTITUTION}
              company={selectedCompany.businessConstitution}
            /> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <BuillionAccount />
            </>
          )
        ) : (
          <div className="row">
            <div className="col-md-2">
              <ContentLoader width="100%" height={120}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="120" />
              </ContentLoader>
            </div>
            <div className="col-md-6">
              <ContentLoader width="100%" height={120}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="120" />
              </ContentLoader>
            </div>
            <div className="col-md-4">
              <ContentLoader width="100%" height={120}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="120" />
              </ContentLoader>
            </div>

            <div className="col-md-8 bottomDashSkelton">
              <ContentLoader width="100%" height={350}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="350" />
              </ContentLoader>
            </div>
            <div className="col-md-4 bottomDashSkelton">
              <ContentLoader width="100%" height={350}>
                <rect x="0" y="0" rx="10" ry="10" width="100%" height="350" />
              </ContentLoader>
            </div>
          </div>
        )}
      </div>

      <AddressEdit
        addrModalShow={addrModalShow}
        addrModalClose={addrModalClose}
      />
    </>
  );
}
