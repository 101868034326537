import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { addTaskAction, updateTaskAction } from "../../actions/tasks";
import { Range, getTrackBackground } from "react-range";
import moment from "moment";
import { backendUrl, CUSTOM_SELECT_STYLES, SELECT_BOX_THEME, TASK_STATUS } from "../../constants";
import filesService from "../../services/files.service";
import DropZoneTemplate from "../files/DropZoneTemplate";
import Select from "react-select";

export default function TaskModal({
  taskModalShow,
  taskModalClose,
  editItem,
  trigger,
}) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);
  const [rangeValue, setRangeValue] = useState(0);
  const [attachments, setAttachments] = useState([]);
  // const loadRef = useRef(true);
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // if (loadRef.current && !editItem) {
    setRangeValue(0);
    setAttachments([]);
    reset();
    // }
    if (editItem && editItem.id) {
      setValue("title", editItem.title);
      setValue("description", editItem.description);
      setValue("status", TASK_STATUS.find(x=> x.value===editItem.status));
      setValue("progress", editItem.progress);
      setRangeValue(editItem.progress);
      setValue("assignee", selectedCompany?.promoters.find(x=>x._id===editItem.assignee));
      setValue("dueDate", new Date(editItem.dueDate.slice(0, -1)));
      setAttachments(editItem.attachments);
    }
    // return () => {
    //   loadRef.current = false
    // }
  }, [editItem, trigger, reset, setValue, selectedCompany]);

  const uploadFiles = (event) => {
    const files = event;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    uploadDispatchFile(formData);
  };

  const uploadDispatchFile = (formData) => {
    filesService.uploadMultipleFiles(formData).then((resp) => {
      if (resp.status) {
        if (resp.filename.length > 0) {
          setAttachments([...attachments, ...resp.filename]);
        }
      }
    });
  };

  const onSubmit = (data) => {
    if (selectedCompany.id) {
      data.company = selectedCompany.id;
      data.dueDate = moment(data.dueDate).format("YYYY-MM-DD");
      data.progress = data.progress ? data.progress : 0;
      data.attachments = attachments.length > 0 ? attachments : [];
      data.assignee = data?.assignee?._id || null
      data.status = data?.status?.value || null
      if (editItem && editItem.id) {
        dispatch(updateTaskAction(selectedCompany.id, editItem.id, data));
      } else {
        dispatch(addTaskAction(data));
      }
      setRangeValue(0);
      setAttachments([]);
      taskModalClose();
      reset();
    }
  };

  const setRangeValueFunc = (value) => {
    setRangeValue(value);
    setValue("progress", value && value[0] ? value[0] : 0);
  };

  const removeAttachment = (index) => {
    setAttachments([
      ...attachments.slice(0, index),
      ...attachments.slice(index + 1, attachments.length),
    ]);
  };

  return (
    <>
      <Modal
        show={taskModalShow}
        onHide={taskModalClose}
        className="modal-card-task"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header p-0">
            <button
              type="button"
              onClick={taskModalClose}
              className="btn-close text-dark close-button"
            ></button>
          </div>

          <div className="modal-body task-img-padding">
            <h5 className="modal-title">Task title</h5>
            <div className="title-input mt-3 mb-3">
              <input
                className="form-control"
                type="text"
                placeholder="Enter task title"
                {...register("title", { required: true })}
              />
              {errors.title?.type === "required" && (
                <span className="errorMessage">Title is required</span>
              )}
            </div>
            <p className="input-text heading">Task description</p>
            <textarea
              className="form-control"
              rows="4"
              // className="card taskInput"
              placeholder="Enter task description"
              {...register("description", { required: true })}
            />
            {errors.description?.type === "required" && (
              <span className="errorMessage">Description is required</span>
            )}
            <hr className="mt-4" />
            <div className="card-status">
              <div className="text-status alignSelect">
                <p>Status</p>
                <Controller
                  name="status"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="select-border newFormSelect selectAssignStatus"
                      placeholder="Choose a status"
                      {...field}
                      options={TASK_STATUS || []}
                      getOptionValue={(option) => `${option.value}`}
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                    >
                    </Select>
                  )}
                />
                {errors.status?.type === "required" && (
                  <span className="errorMessage">Status is required</span>
                )}
              </div>

              <div className="text-status align-items-center m-0">
                <p>Progress</p>
                <span className="rangeAlign">{rangeValue}%</span>
                <Controller
                  control={control}
                  className="form-control"
                  name="progress"
                  render={({ field }) => (
                    <Range
                      step={1}
                      min={0}
                      max={100}
                      values={[rangeValue]}
                      onChange={(value) => setRangeValueFunc(value)} // send value to hook form
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "10px",
                            width: "80%",
                            borderRadius:"10px",
                            backgroundColor: "#E9ECEF",
                            marginLeft: "10px",
                            background: getTrackBackground({
                              values: [rangeValue],
                              colors: ["#f3c258", "#ccc"],
                              min: "0",
                              max: "100",
                            }),
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "10px",
                            width: "10px",
                            backgroundColor: "#999",
                            borderRadius: "5px",
                          }}
                        ></div>
                      )}
                    />
                  )}
                />
              </div>
              {errors.progress?.type === "required" && (
                <div className="errorDiv">
                  <span className="errorMessage">Progress is required</span>
                </div>
              )}

              <div className="text-status alignSelect">
                <p>Assignees</p>
                <Controller
                  name="assignee"
                  rules={{ required: false }}
                  control={control}
                  // defaultValue={null}
                  render={({ field }) => (
                    <Select
                      className="select-border newFormSelect selectAssignStatus"
                      placeholder="Choose a assignees"
                      {...field}
                      options={selectedCompany?.promoters || []}
                      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                      getOptionValue={(option) => `${option._id}`}
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                    >
                    </Select>
                  )}
                />
              </div>
              {/* {errors.assignee?.type === "required" && (
                <div className="errorDiv">
                  <span className="errorMessage">Assignee is required</span>
                </div>
              )} */}
              <div className="text-status newtaskfield alignSelect">
                <p style={{ width: "88px" }}>Due date</p>
                <Controller
                  control={control}
                  className="form-control"
                  name="dueDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      placeholderText="Select date"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              {errors.dueDate?.type === "required" && (
                <div className="errorDiv">
                  <span className="errorMessage">Due date is required</span>
                </div>
              )}

              <div className="text-status">
                <p>Attachments</p>
              </div>

              {attachments.length > 0 && (
                <div className="attachmentsItems">
                  {attachments.map((attachItem, index) => (
                    <p key={index}>
                      <a
                        href={backendUrl + attachItem.filename}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {attachItem.originalName}
                      </a>
                      <span
                        className="remove"
                        onClick={() => removeAttachment(index)}
                      >
                        Remove
                      </span>
                    </p>
                  ))}
                </div>
              )}
              <div className="upload-here-img-task pt-0">
                <DropZoneTemplate onUpload={uploadFiles} multiple="true" />
              </div>
            </div>
            <button type="submit" className="btn agree-button float-right">
              {editItem && editItem.id ? "Update" : "Add"} task
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
