import React, { useEffect, useRef, useState } from "react";
import {
  useSearchParams
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { forgotPasswordVerifyTokenAction, resetPasswordAction, signup, socialLogin } from "../../actions/auth";
import { GoogleLogin } from "react-google-login";
import { kimbo_google_client_id } from "../../constants";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { LOGIN_TYPE } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import { getInviteByTokenAction } from "../../actions/promoters";
import Loader from "../../components/loader/loader";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { token } = params;

  const { resetPasswordId, loader, verificationResetEmail } = useSelector((state) => state.auth);

  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const loadRef = useRef(true);

  const onSubmit = (data) => {
    if (resetPasswordId) {
      if (data.password === data.confirmPassword) {
        var payload = { ...data, userId: resetPasswordId, token: token }
        dispatch(resetPasswordAction(payload))
          .then((resp) => {
            if(resp.status){
              navigate("/");
            }
          })
          .catch(() => { });
      } else {
        toast.error("Password and confirm password does not match.")
      }
    } else {
      toast.error("Something wen't wrong, please try after sometime.")
    }
  };

  useEffect(() => {
    if (loadRef.current) {
      if (token) {
        dispatch(forgotPasswordVerifyTokenAction({ token: token }))
      } else {
        toast.error("Something wen't wrong, please try after sometime.")
      }
    }
    return () => {
      loadRef.current = false
    }
  }, [token]);

  return (
    <>
      {!loader ? (
          <section className="min-vh-100 mb-8 signup-back">
            <div
              className="page-header align-items-start min-vh-50 pt-5 pb-11 border-radius-lg"
              style={{ backgroundImage: "url(assets/img/kimbocorp-site-signup.png)" }}
            >
            </div>
            <div className="container">
              <div className="row mt-lg-n10 mt-md-n11 mt-n10">
                <div className="col-xl-5 col-lg-5 col-md-7 mx-auto">
                  <div className="card z-index-0 card-login">
                    <div className="card-header text-center pt-4">
                      <h5>Reset {verificationResetEmail ? 'your' : ""} password</h5>
                    </div>
                    {verificationResetEmail && (
                      <div className="row text-center mx-auto">
                        <div className="mt-2 position-relative text-center">
                          <p className="text-sm mb-2 text-secondary text-border d-inline z-index-2 bg-white">
                            Enter new holding company password to continue with your account.
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {verificationResetEmail ? (
                          <>
                            <div className="mb-3 handleEye">
                              <input
                                maxLength={50}
                                autoComplete="nope"
                                type={passwordView ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                {...register("password", {
                                  required: true,
                                  minLength: 8,
                                })}
                              />
                              <div className="viewEye" onClick={() => setPasswordView(!passwordView)}><i className={`fa fa-eye${passwordView ? "-slash" : ""}`} aria-hidden="true"></i></div>
                              {errors.password?.type === "required" && (
                                <span className="errorMessage">
                                  Password is required
                                </span>
                              )}
                              {errors.password?.type === "minLength" && (
                                <span className="errorMessage">
                                  Password must be atleast 8 characters long{" "}
                                </span>
                              )}
                            </div>
                            <div className="mb-3 handleEye">
                              <input
                                maxLength={50}
                                autoComplete="nope"
                                type={confirmPasswordView ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm Password"
                                {...register("confirmPassword", {
                                  required: true,
                                  minLength: 8,
                                })}
                              />
                              {/* -slash */}
                              <div className="viewEye" onClick={() => setConfirmPasswordView(!confirmPasswordView)}><i className={`fa fa-eye${confirmPasswordView ? "-slash" : ""}`} aria-hidden="true"></i></div>
                              {errors.confirmPassword?.type === "required" && (
                                <span className="errorMessage">
                                  Confirm password is required
                                </span>
                              )}
                              {errors.confirmPassword?.type === "minLength" && (
                                <span className="errorMessage">
                                  Confirm password must be atleast 8 characters long{" "}
                                </span>
                              )}
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn bg-gradient-info w-100 my-4 mb-2"
                              >
                                Reset password
                              </button>
                            </div>
                          </>
                        ) : (<>
                          Thank you, your token has been expired, please request again to sent reset password link again.
                          &nbsp;
                          <br/>
                          <br/>
                          </>
                        )}
                        <p className="text-sm mt-3 mb-0">
                          Already have an account?{" "}
                          <Link to={RoutesPaths.HOME} className="text-dark font-weight-bolder">
                            Sign in
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      ) : (
        <Loader />
      )}
    </>
  );
}
