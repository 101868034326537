import React, { useCallback } from "react";
import { useDropzone } from 'react-dropzone'

export default function DropZoneTemplate({ onUpload, multiple }) {
  const onDrop = useCallback(acceptedFiles => {
    if (multiple) {
      onUpload(acceptedFiles);
    } else {
      acceptedFiles.forEach(element => {
        onUpload(element);
      });
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div className="upload-img" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive && (
          <div className="fileUpload">
            <p className="drag-drop-text">Click upload to browse or drag and drop files here</p>
          </div>
        )}
      </div>
    </>
  );
}
