import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import InfoModal from "../dashboard/infoModal";
import { useDispatch, useSelector } from "react-redux";
import { getCapitalList } from "../../actions/capital";
import CapitalCard from "../dashboard/capitalCard";
import { CAPITAL } from "../../constants";
import Joyride, { STATUS } from "react-joyride";

export default function ShareCapital() {
  const dispatch = useDispatch()
  const { selectedCompany, loadCompany } = useSelector((state) => state.company);
  const { shareCapital, paidUpCapital, myInfoShareCapital, myInfopaidUpCapital, sharedCapitalIsApproved, sharedCapitalIsEditApproved, paidUpCapitalIsApproved, paidUpCapitalIsEditApproved, shareCapitalSpotlightSteps, showCapitalJoy } = useSelector((state) => state.capital);
  const [run, SetRun] = useState(false)
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    const { id } = selectedCompany
    if (id)
      dispatch(getCapitalList(id));
  }, [dispatch, selectedCompany]);

  const [infoModalShow, setInfoModalShow] = useState(false);
  const [capitalType, SetCapitalType] = useState(false);
  const infoModalClose = () => {
    setInfoModalShow(false);
  };
  const handleInfoShow = (type) => {
    SetCapitalType(type);
    setInfoModalShow(true);
  }

  useEffect(() => {
    if (showCapitalJoy) {
      setTimeout(() => { SetRun(showCapitalJoy) }, 400)
    } else {
      SetRun(false)
    }
  }, [showCapitalJoy])

  

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    setCurrentStep(data.index); 
    if (finishedStatuses.includes(status)) {
      SetRun(false)
    }
  };


  return (
    <>
      <Header title="Capital" subtitle="Share and Paid-up capital" />
      <div className="bobride"></div>
      <Joyride
        callback={handleJoyrideCallback}
        // locale={{skip: "X",}}
        locale={{
          skip: "X",
          next: "Next",
          last: "Got it",
        }}
        continuous
        disableScrolling={true}
        hideCloseButton
        run={run}
        scrollToFirstStep
        // showProgress
        showSkipButton
        steps={shareCapitalSpotlightSteps}
        styles={{
          options: {
            zIndex: 10000,
          },
          transform: 'translate3d(9px, 416px, 0px)',
        }}
        hideBackButton={currentStep === 1}
      />
      <div className="container-fluid minHeightVH py-4">
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-2">
            {/* font-weight-bolder Edit-Business-text */}
            <h5 className="main-page-title">
              Share capital
            </h5>
            {/* business-activities */}
            <p className="edit-description-adv">
              Share capital refers to the amount of money that shareholders have committed to the holding company. Share capital can be issued with or without full payment from the shareholders.
            </p>
          </div>
          <div className="col-lg-8 col-md-6 mb-md-0 mb-4">
            <div className="card">
              <CapitalCard
                company={selectedCompany}
                capitalType={CAPITAL.shared}
                capital={shareCapital}
                myInfoCapital={myInfoShareCapital}
                capitalIsApproved={sharedCapitalIsApproved}
                capitalIsEditApproved={sharedCapitalIsEditApproved}
                title="Share capital"
                subtitle="Share capital amount"
              // page="sharecapital"
              />
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-4 col-md-6 mt-2">
            <h5 className="main-page-title">
              Paid-up capital
            </h5>
            <p className="edit-description-adv">
              Paid-up capital is the total sum of investment capital received by any holding company from its shareholders in exchange for shares. In other words, it refers to the amount of money a holding company has received from shareholders who have paid for their purchased shares.
            </p>
            {/* <p className="edit-description-adv">
              <span onClick={() => handleInfoShow(CAPITAL.paidUp)} className="learn-text">
                Learn more
              </span> about paid-up capital
            </p> */}
          </div>
          <div className="col-lg-8 col-md-6 mb-md-0 mb-4">
            <div className="card">
              <CapitalCard
                company={selectedCompany}
                capitalType={CAPITAL.paidUp}
                capital={paidUpCapital}
                myInfoCapital={myInfopaidUpCapital}
                capitalIsApproved={paidUpCapitalIsApproved}
                capitalIsEditApproved={paidUpCapitalIsEditApproved}
                title="Paid-up capital"
                subtitle="Paid-up capital amount"
              // page="sharecapital"
              />
            </div>
          </div>
        </div>
      </div>
      <InfoModal
        infoModalShow={infoModalShow}
        infoModalClose={infoModalClose}
        capitalType={capitalType}

      />

    </>
  );
}
