import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordAction } from "../../actions/user";

export default function EditPassword() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      var payload = {
        currentPassword: data.currentPassword,
        password: data.password,
        confirmPassword: data.confirmPassword
      };
      dispatch(updatePasswordAction(payload));
      reset();
    } else {
      toast.error("Password and confirm password must be matched");
    }
  };
  return (
    <>
      <div className="card-body">
        {user.isSocialLogin ? (
          <div className="form-group form-margin">
            <div className="row">
              <div className="col-lg-12">
                <h6 className="col-form-label edit-password-title">Edit password</h6>
                <p className="edit-description-adv">You currently use Google sign-in to login. We will only ask for your InProved password if we need to verify your identity.</p>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group form-margin">
              <div className="row">
                <h6 className="col-form-label edit-password-title">Edit password</h6>
                <div className="col-lg-12">
                  <p className="input-text heading">Current password</p>
                  <div className="eye-show">
                    <input
                      maxLength={50}
                      autoComplete="off"
                      type={showPassword.currentPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter password"
                      {...register("currentPassword", { required: true })}
                    />
                    <i onClick={() => setShowPassword({ ...showPassword, currentPassword: !showPassword.currentPassword })} className={`fa-regular fa-eye${showPassword.currentPassword ? "-slash" : ""}`}></i>
                  </div>
                  {errors.currentPassword?.type === "required" && (
                    <span className="errorMessage">Password is required</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-6 mt-3">
                    <p className="input-text heading">Enter new password</p>
                    <div className="eye-show">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type={showPassword.newPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter password"
                        {...register("password", { required: true, minLength: 8, })}
                      />
                      <i onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })} className={`fa-regular fa-eye${showPassword.newPassword ? "-slash" : ""}`}></i>
                    </div>
                    {errors.password?.type === "required" && (
                      <span className="errorMessage">
                        New password is required
                      </span>
                    )}
                    {errors.password?.type === "minLength" && (
                      <span className="errorMessage">
                        Password must be atleast 8 characters long{" "}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 mt-3">
                    <p className="input-text heading">Reenter new password</p>
                    <div className="eye-show">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type={showPassword.confirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter password"
                        {...register("confirmPassword", { required: true, minLength: 8, })}
                      />
                      <i onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })} className={`fa-regular fa-eye${showPassword.confirmPassword ? "-slash" : ""}`}></i>
                    </div>
                    {errors.confirmPassword?.type === "required" && (
                      <span className="errorMessage">
                        Reenter new password is required
                      </span>
                    )}
                    {errors.confirmPassword?.type === "minLength" && (
                      <span className="errorMessage">
                        Confirm Password must be atleast 8 characters long{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn agree-button save-btn">
              Save changes
            </button>
          </form>
        )}
      </div>
    </>
  );
}
