import { GET_BANNER_LIST } from "../actions/types";
const initialState = {
  bannerList: [],
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BANNER_LIST:
      return {
        ...state,
        bannerList: payload,
      };
    default:
      return state;
  }
}
