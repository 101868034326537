import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  logout,
  setGuideOpenAction,
  showCompanyAddAction,
  showCompanyMenuAction,
  toggleMobileMenu,
} from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Confirm from "../confirm/confrm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { backendUrl, HOLDERS, notificationAction } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import AlertMessage from "../commonAlert/alertMessage";
import UserGuide from "../userGuide/UserGuide";
import ContentLoader from "react-content-loader";
import { userBidGoldPriceAction } from "../../actions/user";
import CurrencyFormat from "../currencyFormat/currencyFormat";
import {
  bidGoldHeaderCurrencyAction,
  paymentModalAction,
} from "../../actions/stripe";
import { selectedPlanProductAction } from "../../actions/subscriptionPlan";
import GoldBid from "./goldbid";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  selectCompanyDashboard,
  selectPendingCompany,
} from "../../actions/company";
import Breadcrumbs from "./breadcrumbs";

export default function Header(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, bidGoldPrice, bidGoldHeaderCurrency, bidGoldHeader } =
    useSelector((state) => state.user);
  const [confirmModal, setConfirmModal] = useState(false);
  const { planLoader } = useSelector((state) => state.subscriptionPlanProducts);

  const {
    company,
    selectedCompany,
    notificationBanner,
    loadCompany,
    isDefaultCompany,
    pendingCompany,
    validateNotDefaultCompany
  } = useSelector((state) => state.company);
  const { isSubscribed } = useSelector((state) => state.stripe);

  const { userGuideOpen, showMenuSidebar, companyMenuShow } = useSelector(
    (state) => state.auth
  );
  const logoutClick = () => {
    setConfirmModal(true);
  };

  useEffect(() => {
    // dispatch(userBidGoldPriceAction());
  }, []);

  const logoutHide = (value) => {
    if (value) {
      dispatch(logout());
    }
    setConfirmModal(false);
  };

  const mobileMenuOpen = () => {
    dispatch(toggleMobileMenu());
  };

  const changeCompany = (companyId) => {
    navigate(RoutesPaths.DASHBOARD);
    dispatch(selectCompanyDashboard(companyId));
  };

  const getBanner = () => {
    const alertMessage = (
      <div className="container-fluid pt-4 pb-0 container-top landingPage">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 mb-md-0 mb-0">
            {!loadCompany && <AlertMessage />}
          </div>
        </div>
      </div>
    );

    if (notificationBanner.hasOwnProperty("force")) {
      return alertMessage;
    } else {
      switch (notificationBanner?.callToAction?.value) {
        case notificationAction.NOTI_MYINFO_VERIFICATION?.value:
          if (!selectedCompany?.isBusinessVerified) {
            return alertMessage;
          }
          break;
        case notificationAction.NOTI_UPGRADE_NOW?.value:
          if (selectedCompany?.subscriptionPlan?.isActive && !isSubscribed) {
            return alertMessage;
          }
          break;
        default:
          return alertMessage;
          break;
      }
    }
  };

  const handleStipePopupAction = (productKey) => {
    if (location.pathname === RoutesPaths.BANKTRANSFER) {
      navigate(RoutesPaths.PAYMENT);
    }

    const product = bidGoldPrice[productKey];
    if (Object.keys(product).length > 0) {
      dispatch(selectedPlanProductAction(product));
      dispatch(paymentModalAction(true, product?.planType?.value));
    }
  };

  const handleCurrencyChange = (event) => {
    let payload = {};
    for (const property in bidGoldPrice) {
      payload[property] = parseFloat(bidGoldPrice[property].price);
    }
    dispatch(bidGoldHeaderCurrencyAction(event, payload));
  };

  const companyFloatingMenu = (
    <div
      className={`floatingCompanyMenuHolder ${companyMenuShow ? "active" : ""}`}
      onClick={() => dispatch(showCompanyMenuAction(false))}>
      <div className="sidebar-new sidebar-popover popover floatingCompanyMenu">
        <div
          id="popover-basic"
          className="sidebar-popover webkitScroll sidebar-new">
          <div className="company-menu-section">
            <h3 className="dropdown-head">User account</h3>
            {company &&
              company.length > 0 &&
              company.map((info, index) => info?.isDefault && (
                <span
                  onClick={() => changeCompany(info.id)}
                  key={index}
                  className={`${selectedCompany.id === info.id ? "active" : ""
                    }`}>
                  <div
                    className={`dropdown-menu-inner ${info.id === selectedCompany.id ? "active" : ""
                      }`}>
                    <div
                      className={`business-image ${info.logo ? "hasLogo" : "hasText"
                        } ${selectedCompany.id === info.id ? "active" : ""} ${info?.isDefault ? "defaultUserAccount" : ""
                        }`}
                      style={{
                        backgroundImage: `url(${!info?.isDefault && info.logo
                          ? backendUrl + info.logo
                          : "assets/img/business-shape.png"
                          })`,
                      }}>
                      {/* {(!info.logo && !info?.isDefault)
                        ? info.companyName
                          ? info.companyName?.substring(0, 1)
                          : "-"
                        : ""} */}
                    </div>
                    <div className="business-trend">
                      <p>{info.companyName}</p>{" "}
                      <p className="designation">
                        {info?.companyRoleUser?.join(" | ") || ""}
                      </p>{" "}
                    </div>
                  </div>
                </span>
              ))}
          </div>
          <div className="company-menu-section">
            {company && company.length > 0 && validateNotDefaultCompany && <h3 className="dropdown-head">Holding companies</h3>}
            {company &&
              company.length > 0 &&
              company.map((info, index) => !info?.isDefault && (
                <span
                  onClick={() => changeCompany(info.id)}
                  key={index}
                  className={`${selectedCompany.id === info.id ? "active" : ""
                    }`}>
                  <div
                    className={`dropdown-menu-inner ${info.id === selectedCompany.id ? "active" : ""
                      }`}>
                    <div
                      className={`business-image ${info.logo ? "hasLogo" : "hasText"
                        } ${selectedCompany.id === info.id ? "active" : ""} ${info?.isDefault ? "defaultUserAccount" : ""
                        }`}
                      style={{
                        backgroundImage: `url(${!info?.isDefault && info.logo
                          ? backendUrl + info.logo
                          : "assets/img/business-shape.png"
                          })`,
                      }}>
                      {/* {(!info.logo && !info?.isDefault)
                        ? info.companyName
                          ? info.companyName?.substring(0, 1)
                          : "-"
                        : ""} */}
                    </div>
                    <div className="business-trend">
                      <p>{info.companyName}</p>{" "}
                      <p className="designation">
                        {info?.companyRoleUser?.join(" | ") || ""}
                      </p>{" "}
                    </div>
                  </div>
                </span>
              ))}
          </div>
          {pendingCompany && pendingCompany.length > 0 && (
            <div className="company-menu-section pendingCompanyList">
              <h3 className="dropdown-head">Pending</h3>
              {pendingCompany?.map((info, index) => (
                <span
                  key={index}
                  onClick={() => {
                    dispatch(showCompanyAddAction(true));
                    dispatch(selectPendingCompany(info));
                  }}
                // className={`${
                //   selectedCompany.id === info.id ? "active" : ""
                // }`}
                >
                  {/* ${
                      info.id === info.id ? "active" : ""
                    } */}
                  <div className={`dropdown-menu-inner `}>
                    <div
                      className={`business-image ${info.logo ? "hasLogo" : "hasText"
                        } ${selectedCompany.id === info.id ? "active" : ""} ${info?.isDefault ? "defaultUserAccount" : ""
                        }`}
                      style={{
                        backgroundImage: `url(${!info?.isDefault && info.logo
                          ? backendUrl + info.logo
                          : "assets/img/business-shape.png"
                          })`,
                      }}></div>
                    <div className="business-trend">
                      <p>{info.companyName}</p>{" "}
                      <p className="designation">
                        {info?.companyRoleUser?.join(" | ") || ""}
                      </p>{" "}
                    </div>
                  </div>
                </span>
              ))}
            </div>
          )}
          <div className="dropdown-menu-inner add-Company-btn">
            <div
              className="company-add"
              onClick={() => {
                dispatch(showCompanyAddAction(true));
                dispatch(selectPendingCompany(null));
              }}>
              <img src="assets/img/add_dashboard.svg" className="me-2" alt="" />
              <p>Add new business</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const showCompanyMenu = () => {
    dispatch(showCompanyMenuAction(true));
  };

  useEffect(() => {
    if (companyMenuShow) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }
  }, [companyMenuShow]);
  return (
    <>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 shadow-none mx-3 nav-back navbar-media mobile-header"
        id="navbarBlur"
        navbar-scroll="true">
        <div className="container-fluid py-1 px-2 ">
          {location.pathname === RoutesPaths.MESSAGE_DETAIL && (
            <nav aria-label="breadcrumb">
              <li className="highlightOpenDashboard">
                <div className="openDashboard">
                  <Link
                    className="d-flex align-items-center"
                    to={RoutesPaths.DASHBOARD}>
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/dashboard-icon-active.svg"
                        className="normal-icon"
                        alt=""
                      />
                    </div>
                    <span className="nav-link-text ms-1">Dashboard</span>
                  </Link>
                </div>
              </li>
              {/* {location.pathname !== RoutesPaths.MESSAGE_DETAIL &&
              (!loadCompany ? (
                <h5 className="font-weight-bolder mt-2 mb-3 phil-text companyWidth">
                  {(selectedCompany && selectedCompany.companyName
                    ? selectedCompany.companyName
                    : "") + `${isDefaultCompany ? "" : " Business Dashboard"}`}
                </h5>
              ) : !props.load ? (
                <h5 className="font-weight-bolder mt-2 mb-3 phil-text companyWidth">
                  {(selectedCompany && selectedCompany.companyName
                    ? selectedCompany.companyName
                    : "") + `${isDefaultCompany ? "" : " Business Dashboard"}`}
                </h5>
              ) : (
                <div>
                  <ContentLoader
                    width="100%"
                    height={15}
                    backgroundColor={"var(--primary-color)"}
                    foregroundColor={"var(--third-color)"}>
                    <rect rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
              ))} */}
            </nav>
          )}
          <div
            className="collapse navbar-collapse mt-sm-0 mt-5 me-md-0 me-sm-4 justify-content-between headerHolder"
            id="navbar">
            <GoldBid />
            <ul className="companyChangeHolder justify-content-end align-items-center">
              <li
                onClick={showCompanyMenu}
                className="nav-item d-flex align-items-center cursorPointer">
                <div
                  className="business-image hasLogo"
                  style={{
                    backgroundImage: `url(${user && user.profilePic && user.profilePic.filename
                      ? backendUrl + user.profilePic.filename
                      : "assets/img/Shape.png"
                      })`,
                  }}></div>
                <div className="businessNameHolder">
                  <p>
                    {(selectedCompany && selectedCompany.companyName
                      ? selectedCompany.companyName
                      : "") +
                      `${isDefaultCompany ? "" : " Business Dashboard"}`}
                  </p>
                  <p className="designation">
                    {selectedCompany?.companyRoleUser?.join(" | ") || ""}
                  </p>
                </div>
              </li>

              {showMenuSidebar && (
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center media-content-li">
                  <span
                    className="nav-link text-body p-0"
                    id="iconNavbarSidenav">
                    <div
                      className="sidenav-toggler-inner"
                      onClick={mobileMenuOpen}>
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                    </div>
                  </span>
                </li>
              )}
              {/* <li className="nav-item px-3 d-flex align-items-center">
                <span className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </span>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center position-relative">
                <span className="notify-icon">
                  <b>4</b>
                </span>
                <span
                  className="nav-link text-body p-0"
                  id="dropdownMenuButton"
                >
                  <i className="fa fa-bell cursor-pointer"></i>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      {getBanner()}
      <Confirm show={confirmModal} onHide={logoutHide} />
      <UserGuide
        guideOpen={userGuideOpen}
        guideClose={() => dispatch(setGuideOpenAction(false))}
      />
      {companyFloatingMenu}
      {location?.pathname.split("/")[2] === "products" && (
        <Breadcrumbs mobileHeader={props?.mobileHeader} loader={planLoader} />
      )}
    </>
  );
}
