import React from "react";
import { Modal } from "react-bootstrap";
import Stripe from "../../components/stripe/Stripe";

export default function PaymentModalStripe({modalShow, modalClose,  }) {
  return (
    <Modal show={modalShow} onHide={modalClose}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Stripe Payment Checkout
        </h5>
        <button
          type="button"
          className="btn-close text-dark"
          onClick={modalClose}
        >
          
        </button>
      </div>
      <div className="modal-body">
        <Stripe />
      </div>
    </Modal>
  );
}
