import { GET_SHAREHOLDER_LIST, LOAD_SHAREHOLDER_SKELETON } from "../actions/types";
const initialState = {
  shareHolder: [],
  pendingShareholderList: [],
  loadShareholder: false
};
export default function (state = initialState, action) {
  const { type, payload, pendingInviteList } = action;
  switch (type) {
    case GET_SHAREHOLDER_LIST:
      return {
        ...state,
        shareHolder: payload,
        pendingShareholderList: pendingInviteList,
        loadShareholder: false
      };
    case LOAD_SHAREHOLDER_SKELETON:
      return {
        ...state,
        loadShareholder: payload
      };
    default:
      return state;
  }
}
