import {
  GET_SUSBCRIPTION_PLAN_PRODUCTS,
  LOAD_PRODUCT_LIST,
  PRODUCT_PLAN_LOADER,
  SELECTED_SUSBCRIPTION_PLAN_PRODUCTS,
  TRANSACTION_SEGMENT_LIST,
} from "../actions/types";
const initialState = {
  productPlanList: [],
  count: 0,
  selectedPlanProduct: {},
  selectedPlanProductCurrentPage: localStorage.getItem("paymentSelection")
    ? JSON.stringify(localStorage.getItem("paymentSelection"))
    : null,
  loadProductPlanList: false,
  transactionsSegmentRecord: {},
  planLoader: false,
};
export default function SubscriptionPlanProducts(state = initialState, action) {
  const {
    type,
    payload,
    count,
    defaultIndex,
    currentPage,
    activeSegment,
    activePaidUpIndex,
  } = action;
  switch (type) {
    case GET_SUSBCRIPTION_PLAN_PRODUCTS:
      localStorage.removeItem("paymentSelection");
      return {
        ...state,
        productPlanList: payload,
        count: count,
        selectedPlanProduct: payload.length > 0 ? payload[defaultIndex] : {},
        loadProductPlanList: false,
        selectedPlanProductCurrentPage: null,
        transactionsSegmentRecord: null,
      };
    case SELECTED_SUSBCRIPTION_PLAN_PRODUCTS:
      const currentPaymentPageData = {
        id: payload?.id,
        page: currentPage,
        activeSegment: activeSegment,
        activePaidUpIndex: activePaidUpIndex,
      };
      localStorage.setItem(
        "paymentSelection",
        JSON.stringify(currentPaymentPageData)
      );
      return {
        ...state,
        selectedPlanProduct: payload,
        selectedPlanProductCurrentPage: currentPaymentPageData,
        transactionsSegmentRecord: null,
        planLoader: false,
      };
    case LOAD_PRODUCT_LIST:
      return {
        ...state,
        loadProductPlanList: payload,
      };

    case TRANSACTION_SEGMENT_LIST:
      return {
        ...state,
        transactionsSegmentRecord: payload,
      };

    case PRODUCT_PLAN_LOADER:
      return {
        ...state,
        planLoader: payload,
      };
    default:
      return state;
  }
}
