import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDetailAction } from "../../actions/stripe";
import ContentLoader from "react-content-loader";

export default function PaymentInvoice() {
  const dispatch = useDispatch()

  const { subscriptions, loadSubscription } = useSelector((state) => state.stripe);
  const [record, setRecord] = useState(3);
  const onLoad = () => {
    setRecord(subscriptions.length)
  };

  const getInvoiceAction = (id) => {
    dispatch(getInvoiceDetailAction(id))
  }
  return (
    <>
      <div className="payment-invoice-section">
        <div className="card-header pb-0 p-3">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <h6 className="font-weight-bolder payment-text">Invoices</h6>
            </div>
            {subscriptions && subscriptions.length > 5 && (
              <div className="col-6 text-end">
                <button onClick={onLoad} className="btn btn-sm upgrade-btn">
                  View all
                </button>
              </div>
            )}
          </div>
        </div>
        {!loadSubscription ? (
          subscriptions && subscriptions.length > 0 ? (
            <div className="card-body p-3 pb-0">
              <ul className="list-group">
                {subscriptions.slice(0, record).map((item, index) => (
                  <li key={index} className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div className="d-flex flex-column">
                      <h6 className="mb-1 payment-text-invoice-head font-weight-bold text-sm">
                        {moment.unix(item.current_period_start).format('MMMM DD, YYYY')}
                      </h6>
                      <span className="text-xs invoice-id-text">#{item.latest_invoice}</span>
                    </div>
                    <div className="d-flex align-items-center invoice-id-amount text-sm">
                      ${item.amount / 100}
                    </div>
                    <div className="d-flex align-items-center text-sm">
                      {(item.amount / 100) > 0 && (
                        <button onClick={() => getInvoiceAction(item.latest_invoice)} className="btn btn-link text-sm invoice-id-amount mb-0 px-0 ms-4">
                          <img src="assets/img/pdf.svg" alt="pdf" />
                          PDF
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="no-record d-flex p-3">
              <div className="d-flex flex-column justify-content-center ">
                <h6 className="mb-0 text-sm table-heading ">
                  No Invoices
                </h6>
              </div>
            </div>
          )
        ) : (


          <div className="p-3">
            {[100, 90, 80, 70, 80, 75, 65, 60, 50, 55, 60, 90, 65, 70, 80].map((item, index) => (
              <ContentLoader key={index} width={item + '%'} height={15} >
                <rect rx="5" ry="5" width={item + '%'} height="15" />
              </ContentLoader>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
