import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header/header";
import { CAPITAL } from "../../constants";
import CapitalCard from "../dashboard/capitalCard";
import PaymentHistory from "./paymentHistory";
import PaymentInvoice from "./paymentInvoice";
import PaymentPlan from "./paymentPlan";
import { useDispatch, useSelector } from "react-redux";
import { getCapitalList } from "../../actions/capital";
import { RoutesPaths } from "../../Router/constant";
import PaymentConfirmation from "./PaymentConfirmation";
import { useRef } from "react";
import { useState } from "react";
import { getStripeSubscriptionAction } from "../../actions/stripe";

export default function OldPayment() {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const { selectedCompany, isDefaultCompany } = useSelector((state) => state.company);
  const { isSubscribed } = useSelector((state) => state.stripe);
  const { paidUpCapital, myInfopaidUpCapital, paidUpCapitalIsApproved, paidUpCapitalIsEditApproved } = useSelector((state) => state.capital);
  const [paymentType, setPaymentSuccess] = useState(false);
  const [paymentConfirmationModalShow, SetPaymentConfirmationModalShow] = useState(false);
  const loadRef = useRef(true);

  useEffect(() => {
    const { id } = selectedCompany
    if (id) {
      dispatch(getCapitalList(id));
      dispatch(getStripeSubscriptionAction(id));
    }
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    if (loadRef.current) {
      const type = queryParams.get("type");
      if (type === "success") {
        setPaymentSuccess(true);
        SetPaymentConfirmationModalShow(true);

        setTimeout(() => {
          const { id } = selectedCompany
          if (id) {
            dispatch(getStripeSubscriptionAction(id));
          }
        }, 1000);
      } else if (type === "failed") {
        setPaymentSuccess(false);
        SetPaymentConfirmationModalShow(true);
      }
    }
    return () => {
      loadRef.current = false
    }
  }, [dispatch, selectedCompany]);

  return (
    <>
      <Header title="Payments" subtitle="Payments" />
      <div className="container-fluid inner-container-fluid py-4 paddingBottomMD">
        <div className="row">
          <div className={`col-lg-${(isSubscribed !== null && !isSubscribed) ? 12 : 12} mb-lg-0 mb-4`}>
            {isSubscribed !== null && !isSubscribed && (
              <>
                <h5 className="main-page-title">Subscription</h5>
                <p className="edit-description-adv">
                  Your company is currently on a <strong className="font-weight-bolder">free plan</strong>. Convert your company
                  into legally registered entity in Singapore by upgrading to
                  company plans.
                </p>
              </>
            )}
          </div>
          <div className={`col-lg-${(isSubscribed !== null && !isSubscribed) ? 12 : 12} mb-lg-0 mb-4`}>
            <div className="card ">
              <PaymentPlan />
            </div>
            {!isDefaultCompany && (
              <div className="card mt-4">
                {/* <CapitalCard
                  title="Paid-up capital"
                  subtitle="Paid-up capital amount"
                /> */}

                <CapitalCard
                  company={selectedCompany}
                  capitalType={CAPITAL.paidUp}
                  capital={paidUpCapital}
                  myInfoCapital={myInfopaidUpCapital}
                  capitalIsApproved={paidUpCapitalIsApproved}
                  capitalIsEditApproved={paidUpCapitalIsEditApproved}
                  title="Paid-up capital"
                  subtitle="Paid-up capital amount"
                />
              </div>
            )}
            <div className="card mt-4">
              <PaymentInvoice />
            </div>

            <div className='col-md-12 mt-4'>
              <div className="card h-100">
                <PaymentHistory />
                {/* <ProfileActivity /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className={`col-${(isSubscribed !== null && !isSubscribed) ? 3 : 12}`}></div>
          <div className={`col-12 col-lg-${(isSubscribed !== null && !isSubscribed) ? 9 : 12}`}>
            <div className="row">
              <div className="col-md-5 mt-4">
                <div className="card">
                  <PaymentInvoice />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-12 mt-0 mt-lg-4">
            <Footer
              linkObj={{
                head: "Manage your share capital and paid-up capital",
                title: "Issue new shares and add paid-up capital",
                link: RoutesPaths.SHARECAPITAL,
              }}
            />
          </div>
        </div>
      </div>
      <PaymentConfirmation paymentType={paymentType} paymentConfirmationModalShow={paymentConfirmationModalShow} paymentConfirmationModalClose={() => SetPaymentConfirmationModalShow(false)} />
    </>
  );
}
