import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  addInviteAcceptAction,
  denyPendingInviteAction,
  holdNextInviteDataAction,
  holdNextInviteTemporaryAction,
  showCurrentInvite,
  skipPromoterInviteModal,
} from "../../actions/promoters";
import { addChannel } from "../../actions/chat";
import { HOLDERS } from "../../constants";
import { createMembersAPI } from "../../actions/chat";
import { RoutesPaths } from "../../Router/constant";
import { getAllCompany, selectCompanyDashboard } from "../../actions/company";
import DenyPendingInvite from "../InvitesIndividualGlobal/DenyPendingInvite";
import { getPositionName } from "../InvitesIndividualGlobal/IndividualInviteTableGlobal";

export default function InviteConfirmation({
  orderModalShow,
  orderModalClose,
  inviteRequest,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { selectedCompany } = useSelector((state) => state.company);
  const { pendingInvites, currentInvite } = useSelector((state) => state.promoters);
  const [acknowledgeLoader, SetacknowledgeLoader] = useState(false)
  const [removeModalShow, setRemoveModalShow] = useState(false);

  useEffect(() => {
    SetacknowledgeLoader(false)
  }, [orderModalShow])

  const forwardAction = async (inviteRequest) => {
    const awaitResp = await dispatch(
      addInviteAcceptAction(inviteRequest.company._id, inviteRequest.token)
    );
    SetacknowledgeLoader(false)
    orderModalClose("click");
    if (awaitResp.status) {
      dispatch(showCurrentInvite(currentInvite + 1));
      dispatch(holdNextInviteTemporaryAction(true));
      dispatch(holdNextInviteDataAction(inviteRequest));
      navigate(RoutesPaths.INVITE);
    }
  }

  const handleProceed = async () => {
    if (inviteRequest.token) {
      SetacknowledgeLoader(true)
      if (inviteRequest.email === inviteRequest.user.email && inviteRequest.email === user.email) {
        await forwardAction(inviteRequest)
      } else {
        var list = [];
        var members = [];
        // add members list
        var elementList = (inviteRequest.type === HOLDERS.shareHolders || inviteRequest.type === HOLDERS.directors) ? inviteRequest.id : (inviteRequest.type === HOLDERS.promoters) ? inviteRequest._id : null
        if (elementList) {
          list.push(elementList);
        }
        members.push(inviteRequest.email);
        const chatGroupMessage = `Welcome to ${inviteRequest.company.companyName}, you are speaking with ${inviteRequest.user.first_name} ${inviteRequest.user.last_name}`
        var obj = {
          company: inviteRequest.company._id,
          members: members,
          type: inviteRequest.type,
          message: chatGroupMessage,
          invitor: inviteRequest.user._id
        };

        var channelRes = await addChannel(obj);
        if (channelRes.isExisted) {
          await forwardAction(inviteRequest)
        }
        else {
          // create new channel if not exists
          try {

            let data = {
              attributes: { companyId: inviteRequest.company._id, companyName: inviteRequest.company.companyName },
              chatGroupMessage: chatGroupMessage,
              members: members,
              channel_admin_id: inviteRequest.user._id
            }

            var memberResp = { status: false }
            if (members && members.length > 0) {
              memberResp = await createMembersAPI(data);
            }

            if (memberResp.status) {
              // getting list of all messages since this is an existing channel
              let obj1 = {
                company: inviteRequest.company._id,
                members: members,
                users: list,
                channel: "",
                masterUser: inviteRequest.user._id,
                type: inviteRequest.type,
                channelId: memberResp.data || "",
                invitor: inviteRequest.user._id
              };
              var channelRes1 = await addChannel(obj1);
              if (channelRes1.status && channelRes1.data.channelId) {
                await forwardAction(inviteRequest)
              } else {
                SetacknowledgeLoader(false)
              }
            }
          } catch (error) {
            SetacknowledgeLoader(false)
          }
        }
      }

    } else {
      toast.error("Something wen't wrong, Please try again later.");
    }
  };

  const handleDenyProceed = () => {
    setRemoveModalShow(true)
  }

  const removeModalCloseConfirm = async () => {
    setRemoveModalShow(false)
    SetacknowledgeLoader(true)
     if (inviteRequest.id) {
      const awaitResp = await dispatch(denyPendingInviteAction(inviteRequest?.company?._id, inviteRequest.id));
      orderModalClose("click");
      SetacknowledgeLoader(false)
      if (awaitResp.status) {
        dispatch(showCurrentInvite(0));
        dispatch(holdNextInviteTemporaryAction(false));
        dispatch(holdNextInviteDataAction(null));
      }
      
    }
    setTimeout(() => { SetacknowledgeLoader(false) }, 10000)
  }

  const inviteCancelTrigger = () => {
    if (pendingInvites.length > 0) {
      dispatch(skipPromoterInviteModal(pendingInvites));
    }
    orderModalClose();
  };

  return (
    <>
      <Modal
        className="custom-modal"
        show={orderModalShow}
        onHide={orderModalClose}
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={inviteCancelTrigger}
          ></button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title">Invite confirmation</h5>
          {inviteRequest && inviteRequest?.token && (
            <>
              <h6 className="title-description">
                You have been invited to <strong>{inviteRequest && inviteRequest?.token && inviteRequest?.company?.companyName}{" "}</strong> dashboard by <strong>{inviteRequest && inviteRequest?.token && inviteRequest?.user?.first_name + " " + inviteRequest?.user?.last_name}{" "} ({inviteRequest && inviteRequest?.token && inviteRequest?.user?.email})</strong> as a {getPositionName(inviteRequest.type)}.
              </h6>
              <p className="edit-description">You can communicate directly with the rest of the people in the holding company and remove yourself whenever you like.</p>
            </>
          )}
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="">
              <button
                disabled={acknowledgeLoader}
                onClick={handleDenyProceed}
                type="button"
                className="m-0 btn btn-secondary"
              >
                {acknowledgeLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)}&nbsp;Deny
              </button>
            </div>
            <div className="">
              <button
                disabled={acknowledgeLoader}
                onClick={handleProceed}
                type="button"
                className="btn agree-button"
              >
                {acknowledgeLoader && (<i className="animateSpin fa-solid fa-circle-notch"></i>)}&nbsp;Click to acknowledge
              </button>
            </div>
          </div>

        </div>
      </Modal>
      <DenyPendingInvite
        removeModalShow={removeModalShow}
        removeModalClose={() => setRemoveModalShow(false)}
        removeModalCloseConfirm={removeModalCloseConfirm}
        actionData={inviteRequest}
      />
    </>
  );
}
