import React from 'react'
import BuillionAccountCompany from './buillionAccountCompany/buillionAccountCompany'
import Header from '../../components/header/header'
import DashboardCarousel from './dashboardCarousel'

export default function home() {
    return (
        <>
            <Header title="Home" load={true} />
            <DashboardCarousel />

            <BuillionAccountCompany />
        </>

    )
}
