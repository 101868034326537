import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setStripePlansAction,
  subscriptionPlan,
  setPaymentMethodSelection,
} from "../../actions/stripe";
import Currency from "../../components/currency";
import { CONTACT_US_TRIGER, SUBCRIPTION_PLAN_NAME, SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD, USER_GUIDE_MODAL, backendUrl } from "../../constants";
import OrderConfirmation from "./OrderConfirmation";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import ContactUs from "../../components/ContactUs/ContactUs";
import { userBidGoldPriceLockAction } from "../../actions/user";
import { setGuideOpenAction } from "../../actions/auth";

export default function PaymentModal({
  modalShow,
  modalClose,
  StripeModalShow,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    stripeSubscription,
    stripePlans,
    stripeModal,
    paymentMethodSelection,
  } = useSelector((state) => state.stripe);
  const { bidGoldHeader } = useSelector((state) => state.user);
  const { selectedCompany } = useSelector((state) => state.company);

  // useEffect(() => {

  // dispatch(setHandleStripeModulePlan(selectedCompany))

  // if (showStripePlanByModule?.isActive)
  //   dispatch(
  //     setStripePlansAction(
  //       showStripePlanByModule?.monthlyPriceId ? showStripePlanByModule?.monthPriceResponse : {},
  //       showStripePlanByModule?.annualPriceId ? showStripePlanByModule?.annualPriceResponse : {}
  //     )
  //   );
  // if (!orderModalShow) {
  // handlePlanChange(false);
  // }
  // }, [dispatch, selectedCompany, stripeModal, orderModalShow, stripePlans]);

  // const handlePlanChange = (checked) => {

  //   var plan = checked
  //     ? SUBCRIPTION_PLAN_NAME.MONTHLY
  //     : SUBCRIPTION_PLAN_NAME.ANNUALLY;
  //   var filterPlan = stripePlans.filter((x) => x.interval === plan);
  //   if (filterPlan.length > 0) {
  //     dispatch(subscriptionPlan(filterPlan[0]));
  //   }
  // };

  const forwardPaymentPage = async () => {
    const stripePlanId = stripePlans?.id || stripePlans?._id;
    let checkGoldProduct = null;
    for (const property in bidGoldHeader) {
      if (bidGoldHeader[property].id === stripePlanId)
        checkGoldProduct = property;
    }

    if (checkGoldProduct) {
      await dispatch(
        userBidGoldPriceLockAction(paymentMethodSelection, {
          type: checkGoldProduct,
          price: stripePlans?.price,
        })
      );
    }
    if (
      paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER ||
      paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
    ) {
      modalClose();
      navigate(RoutesPaths.PAYMENT_BANK_TRANSFER);
    } else if (
      paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
    ) {
      dispatch(subscriptionPlan(stripePlans));
      modalClose();
      navigate(RoutesPaths.PAYMENT_BANK_TRANSFER);
      //
      // handleOrderShow()
    } else {
      toast.error("Please select a valid payment method");
    }
  };

  const openUseGuide = (guideName) => {
    dispatch(setGuideOpenAction(true, guideName));
    modalClose()
  };

  return (
    <>
      <Modal
        className="upgrad-modal-width custom-plan-modal custom-modal SuggestModalCustom"
        show={modalShow}
        onHide={modalClose}>
        <div className="modal-body">
          {/* <div className="row content-text-payment">
            <div className="col-md-6 col-12">
              <div className="leftHolder">
                <div
                  className="planBack"
                  style={{
                    backgroundImage: `url(${
                      selectedCompany?.subscriptionPlan &&
                      showStripePlanByModule?.image &&
                      showStripePlanByModule?.image?.filename
                        ? backendUrl +
                          showStripePlanByModule?.image?.filename
                        : "assets/img/planBack.png"
                    })`,
                  }}
                >
                  <div className="overlay">
                    <h4>{showStripePlanByModule?.name}</h4>
                  </div>
                </div>
                <div className="plandesc">
                  <p>{showStripePlanByModule?.targetAudience}</p>
                </div>
                <div className="planPrice">
                  {stripeSubscription && stripeSubscription.amount > 0 && (
                  <h1 className="payment-text number-text">
                    <Currency currency={stripeSubscription?.currency} />
                    <CurrencyFormat
                      value={
                        stripeSubscription && stripeSubscription.amount
                          ? stripeSubscription.amount
                          : 0
                      }
                    />
                  </h1>
                  )}
                  <div className="annually-toggal-row">
                    <div className="annually-toggal">
                      {stripePlans.length > 0 && (
                        <label
                          className={`form-check-label annually-lable ${
                            stripeSubscription &&
                            stripeSubscription.interval ===
                              SUBCRIPTION_PLAN_NAME.ANNUALLY
                              ? "active"
                              : ""
                          }`}
                          htmlFor="rememberMe"
                        >
                          Annually
                        </label>
                      )}
                      {stripePlans.length > 1 && (
                        <>
                        <div className="form-check form-switch annually-btn">
                          <input
                            maxLength={50}
                            autoComplete="off"
                            className="form-check-input"
                            type="checkbox"
                            id="rememberMe"
                            checked={
                              stripeSubscription &&
                              stripeSubscription.interval ===
                                SUBCRIPTION_PLAN_NAME.MONTHLY
                                ? true
                                : false
                            }
                            onChange={(event) =>
                              handlePlanChange(event.target.checked)
                            }
                          />
                        </div>
                        <label
                          className={`form-check-label annually-lable ${
                            stripeSubscription &&
                            stripeSubscription.interval ===
                              SUBCRIPTION_PLAN_NAME.MONTHLY
                              ? "active"
                              : ""
                          }`}
                          htmlFor="rememberMe"
                        >
                          Monthly
                        </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="listStageHold">
                <div className="listHolder custom-scroll">
                  <ul className="planDetail">
                    <h5>You are assured of:</h5>
                    {showStripePlanByModule?.assuredFeature?.map(
                      (item, index) => (
                        <li key={index}>
                          <img src="assets/img/planList.svg" alt="" />
                          <p>{item}</p>
                        </li>
                      )
                    )}
                  </ul>
                  <ul className="planDetail">
                    <h5>You are guaranteed to receive:</h5>
                    {showStripePlanByModule?.guarentedFeature?.map(
                      (item, index) => (
                        <li key={index}>
                          <img src="assets/img/planList.svg" alt="" />
                          <p>{item}</p>
                        </li>
                      )
                    )}
                  </ul>
                  {stripeSubscription && stripeSubscription.amount > 0 && (
                    <button
                      type="button"
                      onClick={handleOrderShow}
                      className="btn card-btn payment-bottom-btn mb-0"
                    >
                      UPGRADE PLAN
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-md-12 closeSuggestedModal">
            <i className="fa-solid fa-xmark" onClick={modalClose} />
          </div>
          <div
            className="row BackgroundPlanBankTransfer"
            style={{ backgroundImage: `url("assets/img/FramePlanBank.png")` }}>
            <div className="col-md-12">
              <div className="planAlignmentSection">
                <h4 className="planTitle">{stripePlans?.name || "-"}</h4>
                <div className="recumentPrice">
                  <Currency currency={stripePlans?.currency?.value} />{" "}
                  <CurrencyFormat
                    currency={stripePlans?.currency?.value}
                    value={parseFloat(stripePlans?.price)}
                    decimal={2}
                  />
                </div>
              </div>
              <div
                className="planDescription"
                dangerouslySetInnerHTML={{
                  __html: stripePlans?.targetAudience || "-",
                }}></div>
            </div>
          </div>
          {(stripePlans?.assuredFeature &&
            stripePlans?.assuredFeature?.length > 0) ||
            (stripePlans?.guarentedFeature &&
              stripePlans?.guarentedFeature?.length > 0 && (
                <div className="row bottomPlanBankTransfer top-section">
                  {stripePlans?.assuredFeature &&
                    stripePlans?.assuredFeature?.length > 0 && (
                      <div className="col-md-6">
                        <h6 className="BottomBankPlantitle">
                          You are assured of:
                        </h6>
                        {stripePlans?.assuredFeature?.map((item, index) => (
                          <div key={index} className="youAreAssignReceive">
                            <img src="assets/img/planList1.svg" alt="" />
                            <p>{item}</p>
                          </div>
                        ))}
                      </div>
                    )}

                  {stripePlans?.guarentedFeature &&
                    stripePlans?.guarentedFeature?.length > 0 && (
                      <div className="col-md-6">
                        <h6 className="BottomBankPlantitle">
                          You are guaranteed to receive:
                        </h6>
                        {stripePlans?.guarentedFeature?.map((item, index) => (
                          <div key={index} className="youAreAssignReceive">
                            <img src="assets/img/planList1.svg" alt="" />
                            <p>{item}</p>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}

          {/* <div className="row bottomPlanBankTransfer">
            {stripePlans?.assuredFeature && stripePlans?.assuredFeature?.length > 0 && (
              <div className="col-md-6">
                {stripePlans?.assuredFeature?.map(
                  (item, index) => (
                    <div key={index} className="youAreAssignReceive">
                      <img src="assets/img/planList1.svg" alt="" />
                      <p>{item}</p>
                    </div>
                  )
                )}
              </div>
            )}

            {stripePlans?.guarentedFeature && stripePlans?.guarentedFeature?.length > 0 && (
              <div className="col-md-6">
                {stripePlans?.guarentedFeature?.map(
                  (item, index) => (
                    <div key={index} className="youAreAssignReceive">
                      <img src="assets/img/planList1.svg" alt="" />
                      <p>{item}</p>
                    </div>
                  )
                )}
              </div>
            )}
          </div> */}

          <div className="row footerBankPlan">
            <div className="col-12 mb-3">
              <div className="titlechoosePay">Choose how to pay:</div>
            </div>
            <div
              className={`col-md-${
                stripePlans?.planType?.value ===
                SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
                  ? 8
                  : 9
              } choosePayAlign choosePayAlignMobile`}>
              {stripePlans?.supportedPaymentMethod?.length > 0 && (
                <>
                  <div className="choosePayAlign">
                    {stripePlans?.supportedPaymentMethod.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          dispatch(setPaymentMethodSelection(item?.value))
                        }
                        className={`choosePaySelect ${
                          paymentMethodSelection === item?.value
                            ? "choosePaySelectActive"
                            : ""
                        }`}>
                        <img
                          src={`assets/img/${
                            item?.value ===
                            SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER
                              ? "bank"
                              : item?.value ===
                                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD
                              ? "creditCards"
                              : item?.value ===
                                SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO
                              ? "blockchain"
                              : "bank"
                          }.svg`}
                          alt={item?.name}
                        />
                        <h6 className="m-0">{item?.name}</h6>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            {paymentMethodSelection && (
              <div
                onClick={forwardPaymentPage}
                className={`col-md-${
                  stripePlans?.planType?.value ===
                  SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
                    ? 4
                    : 3
                } choosePayAlign upgradePlanSection`}>
                <div className="upgradePlanBtn">
                  <h6>
                    {stripePlans?.planType?.value ===
                    SUBSCRIPTION_MODULE_TYPE.PLAN
                      ? "Upgrade plan"
                      : stripePlans?.planType?.value ===
                        SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
                      ? "Deposit paid-up capital"
                      : "Buy product"}
                  </h6>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 justify-content-center">
                <ContactUs
                  subscriptionPlanName={stripePlans?.name}
                  type={
                    stripePlans?.planType?.value ===
                    SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL
                      ? CONTACT_US_TRIGER.PAID_UP_CAPITAL_PAYMENT
                      : CONTACT_US_TRIGER.PAYMENT
                  }
                  theme="spanConatctUS spanTextWhite"
                  handleClose={modalClose}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
