import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import InfoModalStatic from "../../pages/dashboard/infoModalStatic";
import AddActivity from "./AddActivity";
import AddVirtualBusiness from "./AddVirtualBusiness";
import { addCompany, getAllCompany } from "../../actions/company";
import { PREDEFINED_ADDRESS } from "../../constants";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addInfoQuestions } from "../../actions/myinfo";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";

export default function AddModal({
  addModalShow,
  addModalClose,
  setAddModalShow,
}) {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [activityModalShow, setActivityModalShow] = useState(false);
  const [newdata, setNewData] = useState({});
  const navigate = useNavigate()
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const { pendingCompanySelected } = useSelector((state) => state.company);
  const activityModalClose = () => {
    setActivityModalShow(false);
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  const setNewDataHandle = (data) => {
    setNewData(data);
  };

  const setActivityModalTrigger = (value) => {
    setActivityModalShow(value);
  };

  const dispatch = useDispatch();

  watch(["logo"]);

  const updateForm = async (data, type) => {
    if ("logo" in data && data?.logo?.length === 0) {
      data.logo = "";
    }
    let resp = null;
    let companyRes = null;
    let payload = {
      companyName: data?.companyName,
      companyWebsite: data?.companyWebsite,
      city: PREDEFINED_ADDRESS.CITY,
      country: PREDEFINED_ADDRESS.COUNTRY,
      postalCode: PREDEFINED_ADDRESS.POSTAL_CODE,
      primaryAddress: PREDEFINED_ADDRESS.LINE1,
      logo: data.logo,
      id: pendingCompanySelected ? pendingCompanySelected?.id : null,
      isTick: data.isTick,
    };

    if (data?.companyName?.trimLeft().length > 0) {
      resp = await dispatch(addCompany(payload, false));
      if (type) {
        if (!resp?.data?.isTick && resp?.status) {
          const updatePayload = {
            primaryActivity: "Other holding companies",
            isActive: true,
            id: resp?.data?.id || null,
          };
          let questionsPayload = {
            company: resp?.data?.id,
            objective: null,
            isSingapore: null,
            verificationMethod: "standard",
            countryOfIncorporation: "",
            companyType: "",
          };
          const respCallAgain = await dispatch(
            addInfoQuestions(questionsPayload)
          );
          if (respCallAgain?.status) {
            companyRes = await dispatch(addCompany(updatePayload, true));
            navigate(RoutesPaths.DASHBOARD)
            return companyRes;
          } else {
            return resp;
          }
        } else {
          return resp;
        }
      }
    }
  };

  const onSubmit = async (data) => {
    if (data?.companyName?.trimLeft().length > 0) {
      const successCompanyResp = await updateForm(data, true);
      if (successCompanyResp.status) {
        setNewData(data);
        if (data.isTick) {
          setActivityModalShow(true);
        }
        addModalClose();
        reset();
      }
    }
  };

  const handleClose = async () => {
    let data = {
      companyName: getValues("companyName") || null,
      companyWebsite: getValues("companyWebsite") || null,
      logo: getValues("logo") || null,
      isTick: getValues("isTick") || null,
    };
    await updateForm(data, false);
    addModalClose();
    reset();
  };

  return (
    <>
      <Modal
        show={addModalShow}
        onHide={handleClose}
        className="moddal-width addBusinessModal custom-modal companyEdit-modal ps-0 add-modal">
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={handleClose}></button>
        </div>
        <div className="modal-body">
          <AddVirtualBusiness
            handleClickInfo={handleClickInfo}
            setNewData={setNewDataHandle}
            setActivityModalShow={setActivityModalTrigger}
            setInfoModalStaticShow={setInfoModalStaticShow}
            setInfoModalStaticShowType={setInfoModalStaticShowType}
            register={register}
            handleSubmit={handleSubmit}
            getValues={getValues}
            onSubmit={onSubmit}
            errors={errors}
            setValue={setValue}
            handleClose={handleClose}
          />
        </div>
      </Modal>
      <AddActivity
        activityModalShow={activityModalShow}
        activityModalClose={activityModalClose}
        newdata={newdata}
        setAddModalShow={setAddModalShow}
        setActivityModalShow={setActivityModalShow}
      />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
