import React, { useRef } from "react";
import { useState } from "react";
import { getName } from "../../pages/chatPage/userMessages";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setAskOpen } from "../../actions/auth";
import AskAdaModal from "./AskAdaModal";
import { sendMessageToAdmin } from "../../actions/chat";
import { useNavigate } from "react-router";
import {
  startLoaderAction,
  stopLoaderAction,
} from "../../actions/updateReducerValue";
import { MESSAGE_TYPE } from "../../constants";
import { leaveMessageAction } from "../../actions/leaveMessage";
export default function AskAda() {
  const { selectedCompany } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const { askOpen } = useSelector((state) => state.auth);
  const [sentEmail, setSentEmail] = useState(false);
  const [error, setError] = useState(false);

  const message = useRef();
  const [fileArray, setFileArray] = useState([]);
  const [base64Files, setBase64Files] = useState([]);
  const navigate = useNavigate();
  const sendMessageToAdminfunc = async (type) => {
    if (message?.current?.value || fileArray?.length > 0) {
      dispatch(startLoaderAction());
      await sendMessageToAdmin(
        message?.current?.value,
        selectedCompany,
        type,
        navigate,
        fileArray
      );
      if (type === MESSAGE_TYPE.ASKADDA) {
        handleClose();
      } else {
        const formData = new FormData();
        formData.append("message", message?.current?.value);
        fileArray.forEach((element) => {
          formData.append("files", element);
        });
        if (formData) {
          var resp = await dispatch(leaveMessageAction(formData));
          setSentEmail(resp);
        }
      }
      dispatch(stopLoaderAction());
    } else {
      setError("Please enter message or attachments");
    }
  };

  const leaveMessage = () => {
    sendMessageToAdminfunc(MESSAGE_TYPE.LEAVEAMESSAGE);
  };


  const uploadFiles = (event) => {
    const maxfileUploadSize = 1024 * 1024 * 20;
    const files = Array.from(event.target.files);

    const readers = [];
    const filesUpload = [];

    for (const file of files) {
      if (file.size > maxfileUploadSize) {
        event.target.value = null;
        toast.error("Maximum file upload size is 20mb.");
        return;
      }

      // Push the file to be uploaded to backend
      filesUpload.push(file);

      // Create a promise for reading file as base64
      if (file.type.startsWith('image/')) {
        readers.push(
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
        );
      }else{
        readers.push(new Promise((resolve, reject) => {resolve(null)}))
      }
    }

    // Update state with uploaded files
    setFileArray((oldArray) => [...oldArray, ...filesUpload]);

    // Read all files asynchronously and update base64Files state
    Promise.all(readers).then((results) => {
      setBase64Files((oldBase64Array) => [...oldBase64Array, ...results]);
    });

    event.target.value = null; // Reset input field
  };

  // const uploadFiles = (event) => {
  //   var filesUpload = [];
  //   var maxfileUploadSize = 1024 * 1024 * 20;
  //   for (const file of event.target.files) {
  //     if (file.size > maxfileUploadSize) {
  //       event.target.value = null;
  //       toast.error("Maximum file upload size is 20mb.");
  //       return false;
  //     }
  //     filesUpload.push(file);
  //   }
  //   setFileArray((oldArray) => [...oldArray, ...filesUpload]);
  //   event.target.value = null;
  //   setError(false);
  // };

  const removeMedia = (index) => {
    fileArray.splice(index, 1);
    base64Files.splice(index, 1);
    setFileArray([...fileArray]);
    setBase64Files([...base64Files]);
  };

  const handleClose = () => {
    dispatch(setAskOpen(false));
    setSentEmail(false);
    setFileArray([]);
    setBase64Files([])
    setError(false);
  };

  return (
    <>
      <div
        className="needHelpHolder"
        onClick={() => dispatch(setAskOpen(!askOpen))}
      >
        <img src="assets/img/chat-bot.png" alt="Help"/>
        {/* <i className="fa-solid fa-question"></i> */}
      </div>
      <AskAdaModal
        handleClose={handleClose}
        askOpen={askOpen}
        getName={getName}
        uploadFiles={uploadFiles}
        sentEmail={sentEmail}
        fileArray={fileArray}
        leaveMessage={leaveMessage}
        sendMessageToAdmin={sendMessageToAdminfunc}
        removeMedia={removeMedia}
        message={message}
        error={error}
        base64Files={base64Files}
      />
    </>
  );
}
