import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { editCompany } from "../../actions/company";
import { useDispatch, useSelector } from "react-redux";
import { singleFileUploadAction } from "../../actions/files";
import { backendUrl, I_POP_OVER, MODAL_BUSINESS_IDENTIFY } from "../../constants";
import ContactUs from "../../components/ContactUs/ContactUs";
import InfoModalStatic from "./infoModalStatic";
import ContentLoader from "react-content-loader";

export const handleWebsiteChange = (event) => {
  const value = event.target.value;
  const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
  if (value.length === 1 && value.length < 2 && !value.startsWith('https://')) {
    event.target.value = isBackspace ? `` : `https://${value}`;
  }
};

export default function BusinessCard(props) {
  const dispatch = useDispatch();
  const { selectedCompany, hasPermission, loadCompany } = useSelector(
    (state) => state.company
  );
  const [editModalShow, setEditModalShow] = useState(false);
  const [modalIdentify, setModalIdentify] = useState(null);

  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const editModalClose = () => {
    setEditModalShow(false);
    setModalIdentify(null)
  };
  const handleEditShow = (value) => {
    setEditModalShow(true);
    setModalIdentify(value)
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  watch(["logo"]);

  useEffect(() => {
    if (modalIdentify === MODAL_BUSINESS_IDENTIFY.LOGO) {
      reset();
      setValue(modalIdentify, selectedCompany[modalIdentify]);
    } else {
      reset();
    }
  }, [setValue, modalIdentify]);

  const onSubmit = (data) => {
    var payload = {
      [modalIdentify]: data[modalIdentify]
    }
    if ('logo' in payload && payload.logo.length === 0) {
      payload.logo = "";
    }
    dispatch(editCompany(selectedCompany.id, payload));
    editModalClose();
    reset();
  };

  const uploadFiles = (event) => {
    const file = event.target.files[0];
    uploadDispatchFile(file);
    event.target.value = null;
  };

  const uploadDispatchFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(singleFileUploadAction(formData)).then(resp => {
      if (resp) {
        setValue("logo", resp?.filename);
      }
    });
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type)
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3 my-lg-0">
          <div className="card h-100">
            <div className={`card-body px-0 pb-2 pt-3`}>
              <div className="table-responsive bussinessTabel">
                <table className="table  mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex px-2 py-1">
                          <div className="d-flex flex-column justify-content-center ">
                            <h6 className="mb-0 text-sm table-heading">Logo</h6>
                            {!loadCompany ? (
                              <div className={`business-image ${selectedCompany.logo ? 'hasLogo' : 'hasText'}`} style={{ backgroundImage: `url(${backendUrl + selectedCompany.logo})` }}>
                                {/* {!selectedCompany.logo ? selectedCompany.companyName ? selectedCompany.companyName?.substring(0, 1) : "-" : ""} */}
                              </div>
                            ) : (
                              <div className="skeltonSection">
                                <span>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        {!loadCompany && selectedCompany && selectedCompany.id && (
                          <>
                            {hasPermission && props.permission() ? (
                              <span
                                className="btn btn-link  px-3 mb-0 edit-button companySpotlightStep4-1"
                                onClick={() => handleEditShow(MODAL_BUSINESS_IDENTIFY.LOGO)}
                              >
                                <i
                                  className="fas fa-pencil-alt edit-button me-2"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : null}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              {!loadCompany && selectedCompany && selectedCompany.id && (
                <>
                  {!props.company.isApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button-second">
                        Acceptance in progress
                      </button>
                    </div>
                  ) : null}
                  {!props.company.isLogoEditApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button">
                        Amendment in progress
                      </button>
                    </div>
                  ) : null}

                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3 my-lg-0">
          <div className="card h-100">
            <div className="card-body px-0 pb-3 pt-3">
              <div className="table-responsive bussinessTabel">
                <table className="table  mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex px-2 py-1">
                          <div className="d-flex flex-column justify-content-center ">
                            <h6 className="mb-0 text-sm table-heading">
                              Holding company name
                              {" "}<img
                                src="assets/img/info-icon.svg"
                                onClick={() => handleClickInfo(I_POP_OVER.BUSINESS_NAME)}
                                alt=""
                                className="m-0 pb-1 cursorPointer"
                              />
                            </h6>
                            {!loadCompany ? (
                              <span className="business-font business_wrapper_name">
                                {props.company.companyName
                                  ? props.company.companyName
                                  : "-"}
                              </span>
                            ) : (
                              <div className="skeltonSection">
                                <span>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        {!loadCompany && selectedCompany && selectedCompany.id && (
                          <>
                            {hasPermission && props.permission() ? (
                              <span
                                className="btn btn-link  px-3 mb-0 edit-button companySpotlightStep4-2"
                                onClick={() => handleEditShow(MODAL_BUSINESS_IDENTIFY.COMPANY_NAME)}
                              >
                                <i
                                  className="fas fa-pencil-alt edit-button me-2"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : null}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              {!loadCompany && selectedCompany && selectedCompany.id && (
                <>
                  {!props.company.isApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button-second">
                        Acceptance in progress
                      </button>
                    </div>
                  ) : null}
                  {!props.company.isNameEditApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button">
                        Amendment in progress
                      </button>
                    </div>
                  ) : null}

                </>
              )}


            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3 my-lg-0">

          <div className="card h-100">
            <div className="card-body px-0 pb-3 pt-3">
              <div className="table-responsive bussinessTabel">
                <table className="table  mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex px-2 py-1">
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 text-sm table-heading">Website
                              {" "}<img
                                src="assets/img/info-icon.svg"
                                onClick={() => handleClickInfo(I_POP_OVER.WEBSITE)}
                                alt=""
                                className="m-0 pb-1 cursorPointer"
                              /></h6>
                            {!loadCompany ? (
                              <span className="business-font company-xyz-text business_wrapper_link">
                                {props.company.companyWebsite ? props.company.companyWebsite : "-"}
                              </span>
                            ) : (
                              <div className="skeltonSection" >
                                <span>
                                  <ContentLoader width="100%" height={15}>
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                                  </ContentLoader>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        {!loadCompany && selectedCompany && selectedCompany.id && (
                          <>
                            {hasPermission && props.permission() ? (
                              <span
                                className="btn btn-link  px-3 mb-0 edit-button companySpotlightStep4-3"
                                onClick={() => handleEditShow(MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE)}
                              >
                                <i
                                  className="fas fa-pencil-alt edit-button me-2"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : null}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              {!loadCompany && selectedCompany && selectedCompany.id && (
                <>
                  {!props.company.isApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button-second">
                        Acceptance in progress
                      </button>
                    </div>
                  ) : null}
                  {!props.company.isWebsiteEditApproved ? (
                    <div className="additional-action">
                      <button className="btn amendment-progress-button">
                        Amendment in progress
                      </button>
                    </div>
                  ) : null}

                </>
              )}


            </div>
          </div>
        </div>
      </div>
      <Modal
        show={editModalShow}
        onHide={editModalClose}
        className="moddal-width custom-modal companyEdit-modal ps-0"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={editModalClose}
          >
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">Edit holding company {modalIdentify === MODAL_BUSINESS_IDENTIFY.LOGO ? "logo" : modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME ? "name" : modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE ? "website" : ''}</h5>
              {modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME && (
                <>
                  <p className="edit-description">
                    Every holding companies needs a name to differentiate itself from other
                    businesses. Typically a business name needs to be unique and
                    cannot be identical to one that is already registered by
                    existing entities in corporate registrars.
                  </p>
                  <p className="edit-description">
                    You can change the holding company name as many times as you need to.
                  </p>
                  <p className="edit-description">
                    We will be sending you notifications directly to confirm if the
                    name has been used elsewhere and advise accordingly to suggest
                    changes to it.
                  </p>
                </>
              )}

              {modalIdentify === MODAL_BUSINESS_IDENTIFY.LOGO && (
                <>
                  <p className="edit-description">A logo communicates ownership, values and quality. It grabs attention, makes a strong first impression, is the foundation of your identity, is memorable, separates you from other holding companies, fosters loyalty, and is identifiable by your audience.</p>
                  <p className="edit-description">You can change your logo as many times as you need to.</p>
                </>
              )}

              {modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE && (
                <>
                  <p className="edit-description">For due diligence purposes, it is preferable to enter a website url that is similar to the name of the holding company</p>
                </>
              )}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7 mt-4 mt-lg-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                {modalIdentify === MODAL_BUSINESS_IDENTIFY.LOGO && (
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-5">
                        <p className="input-text heading">Virtual business logo</p>
                        {getValues("logo") && getValues("logo").length > 0 ? (
                          <img
                            src={backendUrl + getValues("logo")}
                            alt="company-logo"
                          />
                        ) : (

                          <div className="business-image hasText">
                            {/* {!selectedCompany.logo ? selectedCompany.companyName ? selectedCompany.companyName?.substring(0, 1) : "-" : ""} */}
                          </div>
                        )}
                      </div>
                      <div className="col-7 upload-holder">
                        <p className="input-text heading">Upload new virtual business logo</p>
                        <label htmlFor="inputTag" className="upload-btn ">
                          Upload file
                          <input
                            id="inputTag"
                            type="file"
                            hidden
                            {...register("logo", { required: false })}
                            onChange={uploadFiles}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group form-margin">
                  {modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME && (
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="input-text heading">Current name</p>
                        <p
                          htmlFor="recipient-name "
                          className="col-form-label company-text"
                        >
                          {props.company.companyName}
                        </p>
                        <p className="input-text heading">New name</p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Enter a new name for your holding company"
                          {...register("companyName", {
                            required: true,
                          })}
                        />
                        {errors.companyName?.type === "required" && (
                          <span className="errorMessage">
                            New virtual business name is required
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {modalIdentify === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE && (
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="input-text heading">Current website</p>
                          <p className="col-form-label company-text">
                            {props.company.companyWebsite}
                          </p>
                          <p className="input-text heading">New holding company website</p>
                          <input
                            maxLength={50}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Enter a new website for your virtual business"
                            {...register("companyWebsite", {
                              required: true,
                              // pattern:
                              //   /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                            },
                            )}
                            onChange={handleWebsiteChange}
                          />
                          {errors.companyWebsite?.type === "required" && (
                            <span className="errorMessage">
                              Enter new virtual business website url is required
                            </span>
                          )}
                          {errors.companyWebsite?.type === "pattern" && (
                            <span className="errorMessage">
                              Company website invalid
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will send you notifications to confirm the amendment
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs type={modalIdentify} handleClose={editModalClose} />
                  <button type="submit" className="btn agree-button">
                    Agree & continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
