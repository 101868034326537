import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorisationCallAction } from "../../actions/myinfo";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { MESSAGE_TYPE, notificationAction } from "../../constants";
import { clearAlertAction } from "../../actions/auth";
import { reOrganiseMessage, sendMessageToAdmin } from "../../actions/chat";
import { notificationDenyAction } from "../../actions/company";

export default function AlertMessage( { bob } ) {
  const { clearAlert, selectedCompany, notificationBanner, bobNotification } = useSelector(
    (state) => state.company
  );
  const notificationBannerLocal = bob ? bobNotification : notificationBanner;
  const { shareHolder } = useSelector((state) => state.shareHolder);
  const { officers } = useSelector((state) => state.officers);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const { id } = selectedCompany;
    if (id) {
      if (!selectedCompany.isBusinessVerified) {
        const authoriseUrl = await dispatch(getAuthorisationCallAction(id));
        if (authoriseUrl && authoriseUrl.status && authoriseUrl.data) {
          window.location = authoriseUrl.data;
        }
      }
    } else {
      navigate(RoutesPaths.DASHBOARD);
    }
  };

  const handleAction = (value) => {
    sendMessageToAdmin(notificationBannerLocal, selectedCompany, MESSAGE_TYPE.BANNER);
    switch (value.value) {
      case notificationAction.NOTI_MYINFO_VERIFICATION?.value:
        onSubmit();
        break;
      case notificationAction.NOTI_VERIFY_BUSINESS?.value:
        navigate(RoutesPaths.DASHBOARD, { state: "steps" });
        break;
      case notificationAction.NOTI_COMPLETE_DUE_DILIGENCE?.value:
        navigate(RoutesPaths.ACCOUNTSETTING);
        break;
      case notificationAction.NOTI_LEARN_MORE?.value:
        break;
      case notificationAction.NOTI_INVITE_POTENTIAL_SHAREHOLDERS?.value:
        navigate(RoutesPaths.DASHBOARD, { state: "inviteShareHolder" });
        break;
      case notificationAction.NOTI_INVITE_POTENTIAL_DIRECTORS?.value:
        navigate(RoutesPaths.DASHBOARD, { state: "inviteDirector" });
        break;
      case notificationAction.NOTI_INVITE_POTENTIAL_PROMOTERS?.value:
        navigate(RoutesPaths.INVITE, { state: "invitePromoter" });
        break;
      case notificationAction.NOTI_CHAT_NOW?.value:
        break;
      case notificationAction.NOTI_UPGRADE_NOW?.value:
        navigate(RoutesPaths.PAYMENT, { state: "UPGRADE" });
        break;
      case notificationAction.NOTI_VIEW_FILES?.value:
        navigate(RoutesPaths.FILE);
        break;
      case notificationAction.NOTI_SCHEDULE_SHAREHOLDERS_MEETINGS?.value:
        break;
      case notificationAction.NOTI_SCHEDULE_DIRECTORS_MEETINGS?.value:
        break;
      case notificationAction.NOTI_SCHEDULE_PROMOTERS_MEETINGS?.value:
        break;
      case notificationAction.NOTI_APPOINT_SHAREHOLDER?.value:
        if (shareHolder && shareHolder.length > 0) {
          navigate(RoutesPaths.DASHBOARD, { state: "appointShareHolder" });
        } else {
          navigate(RoutesPaths.DASHBOARD, { state: "inviteShareHolder" });
        }
        break;
      case notificationAction.NOTI_APPOINT_OFFICER?.value:
        if (officers && officers.length > 0) {
          navigate(RoutesPaths.DASHBOARD, { state: "appointDirector" });
        } else {
          navigate(RoutesPaths.DASHBOARD, { state: "inviteDirector" });
        }
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    if (
      "callToAction" in notificationBannerLocal &&
      notificationBannerLocal.callToAction &&
      selectedCompany &&
      "id" in selectedCompany
    ) {
      dispatch(
        notificationDenyAction({
          cta: notificationBannerLocal.callToAction,
          company: selectedCompany.id,
        })
      );
    }
    dispatch(clearAlertAction(false));
  };
  return (
    // success class for green banner else empty ""
    <>
      {clearAlert && notificationBannerLocal.status && (
        <div className={`banner ${notificationBannerLocal.color ? "success" : ""} `}>
          <p>{reOrganiseMessage(notificationBannerLocal?.message, selectedCompany)}</p>
          {notificationBannerLocal?.callToAction && (
            <button
              type="button"
              onClick={() => handleAction(notificationBannerLocal?.callToAction)}
              className={bob ? "btn agree-button" : "bannerBtn"}
            >
              {notificationBannerLocal?.callToAction?.value ===
              notificationAction?.NOTI_MYINFO_VERIFICATION?.value ? (
                <img src="assets/img/myInfoRetry.svg" alt="myInfo" />
              ) : (
                notificationBannerLocal?.callToAction?.label
              )}
            </button>
          )}

          {/* close button */}
          {!bob && (
            <button
              type="button"
              className="close-banner"
              onClick={handleClose}
              aria-label="Close"
            >
              <img src="assets/img/close-banner.svg" alt="" />
            </button>
          )}
        </div>
      )}
    </>
  );
}
