import { GET_USER_GUIDE, LOAD_USER_GUIDE_SKELETON, USER_GUIDE_TYPE } from "../actions/types";
import { USER_GUIDE_MODAL } from "../constants";
const initialState = {
  userGuide: [],
  identityType: USER_GUIDE_MODAL.DEFAULT,
  newsLetter: false,
  userGuideFlag: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_GUIDE:
      return {
        ...state,
        userGuide: payload,
        userGuideFlag: false,
      };
    case LOAD_USER_GUIDE_SKELETON: {
      return {
        ...state,
        userGuideFlag: payload,
      };
    }
    case USER_GUIDE_TYPE: {
      return {
        ...state,
        identityType: payload,
      };
    }
    default:
      return state;
  }
}
