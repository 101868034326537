import axionIns from "../axios";

const getUserDashboard = async () => {
  return axionIns.get("userdashboard").then((response) => { return response.data });
};


const updateUserDashboard = async (payload) => {
  return axionIns.put("userdashboard", JSON.stringify(payload)).then((response) => { return response.data });
};

export default {
  getUserDashboard,
  updateUserDashboard
};
