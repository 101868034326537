import { GET_TASK_LIST, ADD_NEW_TASK, LOAD_TASK_SKELETON } from "../actions/types";
import { TASK_STATUS_ENUM } from "../constants";
const initialState = {
  taskBacklog: [],
  taskInProgress: [],
  taskComplete: [],
  addNewTask: {},
  loadTask: false
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TASK_LIST:
      var taskBacklog = payload.filter(x=> x.status === TASK_STATUS_ENUM.BACKLOG)
      var taskInProgress = payload.filter(x=> x.status === TASK_STATUS_ENUM.INPROGRESS)
      var taskComplete = payload.filter(x=> x.status === TASK_STATUS_ENUM.COMPLETE)

      return {
        ...state,
        taskBacklog: taskBacklog,
        taskInProgress: taskInProgress,
        taskComplete: taskComplete,
        loadTask: false
      };
    case ADD_NEW_TASK:
      return {
        ...state,
        addNewTask : payload
      };
    case LOAD_TASK_SKELETON:
      return {
        ...state,
        loadTask : payload
      };
    default:
      return state;
  }
}
