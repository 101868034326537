import companyService from "../services/company.service";

import {
  GET_COMPANY_LIST,
  SET_SUCCESS_MESSAGE,
  SELECTED_COMPANY,
  SET_FAILED_MESSAGE,
  GET_COMPANY_ACTIVITY_LIST,
  START_LOADER,
  STOP_LOADER,
  LOADING_COMPANY,
  SET_NOTIFICATION_BANNER,
  CLEAR_NOTIFICATION,
  LOAD_COMPANY_SKELETON,
  LOAD_COMPANY_ACTIVITY_SKELETON,
  LOAD_COMPANY_SKELETON_FORCE,
  SET_ALL_COMPANY_APPOINTED_LIST,
  SET_ENABLE_TOUR_RIDE,
  HIDE_BOB_TOUR_RIDE,
  SET_PENDING_COMPANY_SELECT,
} from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getAllCompany =
  (newCompany = null, inviteAcceptCompanyId = null) =>
  async (dispatch, getState) => {
    if (getState()?.company?.loadCompanyForce) {
      dispatch({ type: LOAD_COMPANY_SKELETON, payload: true });
    }
    return await companyService.getCompanyList().then((resp) => {
      dispatch(getAllAppointedListAction());
      if (resp.status) {
        dispatch({ type: GET_COMPANY_LIST, payload: resp });
        if (resp.data.length > 0) {
          if (inviteAcceptCompanyId) {
            dispatch(
              selectCompanyDashboard(
                inviteAcceptCompanyId,
                localStorage.getItem("selectedCompany") ? false : true
              )
            );
          } else {
            if (newCompany) {
              dispatch(
                selectCompanyDashboard(
                  resp.data[resp.data.length - 1].id,
                  localStorage.getItem("selectedCompany") ? false : true
                )
              );
            } else {
              dispatch(
                selectCompanyDashboard(
                  localStorage.getItem("selectedCompany")
                    ? localStorage.getItem("selectedCompany")
                    : null,
                  localStorage.getItem("selectedCompany") ? false : true
                )
              );
            }
          }
        } else {
          dispatch({ type: LOAD_COMPANY_SKELETON, payload: false });
          dispatch({ type: LOADING_COMPANY, payload: true });
        }
      } else {
        dispatch({ type: LOAD_COMPANY_SKELETON, payload: false });
        dispatch({ type: GET_COMPANY_LIST, payload: [] });
      }
    });
  };

export const getAllAppointedListAction = () => (dispatch) => {
  return companyService.getAllCompanyAppointedList().then(async (resp) => {
    dispatch({ type: SET_ALL_COMPANY_APPOINTED_LIST, payload: resp.data });
  });
};

export const addCompany = (payload, hideLoader) => async (dispatch) => {
  dispatch({ type: LOAD_COMPANY_SKELETON_FORCE, payload: hideLoader });
  return companyService.addCompanyList(payload).then(async (resp) => {
    if (resp.status) {
      if (resp?.data?.isActive) {
        localStorage.setItem("selectedCompany", resp?.data?.id);
        dispatch(
          selectCompanyDashboard(
            resp?.data?.id,
            localStorage.getItem("selectedCompany") ? false : true
          )
        );
        dispatch(getAllCompany(true));
      } else {
        dispatch(getAllCompany());
        dispatch(selectPendingCompany(resp?.data));
      }
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: "Company saved successfully",
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve(resp);
  });
};

export const editCompany = (companyId, payload) => (dispatch) => {
  return companyService.editCompanyList(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: "Company updated Successfully",
      });
      dispatch(getAllCompany());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
    return Promise.resolve();
  });
};

export const selectCompanyDashboard =
  (companyId, initial = false) =>
  (dispatch, getState) => {
    // dispatch({ type: START_LOADER });
    if (companyId === "null") {
      companyId = null;
    }
    if (getState()?.company?.loadCompanyForce) {
      dispatch({ type: LOAD_COMPANY_SKELETON, payload: true });
      dispatch({ type: LOAD_COMPANY_SKELETON_FORCE, payload: true });
    }
    setTimeout(() => {
      var companyListStore = getState()?.company?.company || [];
      var companyListStorePending = getState()?.company?.pendingCompany || [];
      const payloadIndex = companyId
        ? companyListStore.findIndex((x) => x.id === companyId)
        : 0;
      if (payloadIndex > 2) {
        const oldCompanyIdIndex = companyListStore.findIndex(
          (x) => x.id === getState()?.company?.selectedCompany?.id
        );
        if (oldCompanyIdIndex > -1 && oldCompanyIdIndex < 3) {
          companyListStore.splice(
            oldCompanyIdIndex,
            0,
            companyListStore[payloadIndex]
          );
        } else {
          companyListStore.unshift(companyListStore[payloadIndex]);
        }
        companyListStore.splice(payloadIndex + 1, 1);

        dispatch({
          type: GET_COMPANY_LIST,
          payload: {
            status: true,
            data: companyListStore,
            pendingCompany: companyListStorePending,
          },
        });
      } else {
        companyId = companyListStore[payloadIndex]?.id;
      }
      localStorage.setItem("selectedCompany", companyId);
      dispatch({
        type: SELECTED_COMPANY,
        payload: {
          index: companyId,
          initial: initial,
          currentUser: getState().user.user.id,
        },
      });
      // dispatch({ type: STOP_LOADER });
    }, 500);
  };

export const addCompanyActivity = (companyId, payload) => (dispatch) => {
  return companyService.editCompanyList(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: "Company Activity Updated.",
      });
      dispatch(getAllCompany());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
    return Promise.resolve();
  });
};

export const addCompanyAddress = (companyId, payload) => (dispatch) => {
  return companyService.editCompanyList(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: "Company Address Updated.",
      });
      dispatch(getAllCompany());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
    return Promise.resolve();
  });
};

export const getCompanyActivity =
  (companyId, page, limit, filterType) => async (dispatch) => {
    dispatch({ type: LOAD_COMPANY_ACTIVITY_SKELETON, payload: true });
    // dispatch(startLoaderAction());
    return await companyService
      .getCompanyActivityList(companyId, page, limit, filterType)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_COMPANY_ACTIVITY_LIST, payload: resp });
        } else {
          dispatch({ type: GET_COMPANY_ACTIVITY_LIST, payload: null });
        }
        // dispatch(stopLoaderAction());
      });
  };

export const setNotificationBanner = (payload) => async (dispatch) => {
  dispatch({ type: SET_NOTIFICATION_BANNER, payload: payload });
};

export const notificationDenyAction = (payload) => async (dispatch) => {
  return await companyService.notificationDeny(payload).then((resp) => {});
};

export const updateUserExperienceAction =
  (companyId, payload) => async (dispatch) => {
    await companyService.updateUserExperience(companyId, payload);
    dispatch(getAllCompany());
  };

export const enableTourRideAction = (payload) => async (dispatch, getState) => {
  if (getState()?.company?.selectedCompany?.showTourRide) {
    dispatch({ type: SET_ENABLE_TOUR_RIDE, payload: payload });
  }
};

export const hideTourRideAction = () => async (dispatch) => {
  dispatch({ type: HIDE_BOB_TOUR_RIDE });
};
export const selectPendingCompany = (pendingCompany) => async (dispatch) => {
  dispatch({ type: SET_PENDING_COMPANY_SELECT, payload: pendingCompany });
};
