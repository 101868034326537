import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addCompanyAddress } from "../../actions/company";
import ContactUs from "../../components/ContactUs/ContactUs";
import SaveModal from "../../components/savemodal/saveModal";
import { CUSTOM_SELECT_STYLES, MODAL_BUSINESS_IDENTIFY, SELECT_BOX_THEME, country } from "../../constants";
import Select from "react-select";

export default function AddressEdit({ addrModalShow, addrModalClose }) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [saveModalShow, setSaveModalShow] = useState(false);
  const saveModalClose = () => {
    setSaveModalShow(false);
  };

  // useEffect(() => {
  //   setValue("primaryAddress", selectedCompany.primaryAddress);
  //   setValue("secondaryAddress", selectedCompany.secondaryAddress);
  //   setValue("city", selectedCompany.city);
  //   setValue("postalCode", selectedCompany.postalCode);
  // }, [selectedCompany, setValue]);


  const handleCloseModal = () => {
    addrModalClose();
    reset();
  }

  const onSubmit = (data) => {
    if (selectedCompany.id) {
      data.country = data.country.name
      dispatch(addCompanyAddress(selectedCompany.id, data));
      handleCloseModal();
    }
  };

  return (
    <>
      <Modal
        show={addrModalShow}
        onHide={handleCloseModal}
        className="custom-modal moddal-width ps-0"
      >
        <div className="modal-header pt-2">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={handleCloseModal}
          >
          </button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">
                Edit Business Address
              </h5>
              <p className="edit-description">
                Every business needs a business address for official mailing
                purposes. The address cannot be a P.O box type address. We
                typically use a default address in Singapore at the start.
              </p>
              <p className="edit-description">
                You can keep the default or change the address as many times as
                you need to.
              </p>
              <p className="edit-description">
                We will be sending you notifications to confirm and verify your
                business address.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 col-xl-7 mt-4 mt-lg-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-margin">
                  <div className="row">
                    <div className="col-lg-12  register-col">
                      <p className="input-text heading">
                        Current Registered Business Address
                      </p>
                      <h6 className="mb-4">
                        {selectedCompany.primaryAddress &&
                          selectedCompany.primaryAddress + ", "}{" "}
                        {selectedCompany.secondaryAddress &&
                          selectedCompany.secondaryAddress + ", "}{" "}
                        {selectedCompany.city && selectedCompany.city + ", "}{" "}
                        {selectedCompany.country &&
                          selectedCompany.country + " - "}{" "}
                        {selectedCompany.postalCode}
                      </h6>
                    </div>
                    <div className="col-lg-12 register-col">
                      <div className="form-group">
                        <p
                          className="input-text heading"
                        >
                          Address *
                        </p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="12 Maple St."
                          {...register("primaryAddress", {
                            required: true,
                          })}
                        />
                        {errors.primaryAddress?.type === "required" && (
                          <span className="errorMessage">
                            Enter your Address
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-lg-6 register-col">
                      <div className="form-group">
                        <p
                          className="input-text heading"
                        >
                          Address Line 2
                        </p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Block 234"
                          {...register("secondaryAddress", {
                            required: false,
                          })}
                        />
                        {errors.secondaryAddress?.type === "required" && (
                          <span className="errorMessage">
                            Enter your Address 2
                          </span>
                        )}
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-4 register-col mt-3">
                      <div className="form-group">
                        <p
                          className="input-text heading"
                        >
                          City *
                        </p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Singapore"
                          {...register("city", {
                            required: true,
                          })}
                        />
                        {errors.city?.type === "required" && (
                          <span className="errorMessage">
                            Enter your City Name
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 register-col mt-3">
                      <div className="form-group">
                        <p
                          className="input-text heading"
                        >
                          Country *
                        </p>

                        <Controller
                        name="country"
                        rules={{ required: true }}
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={country}
                            isClearable={true}
                            getOptionLabel={(option) =>
                              `${option.dial_code} (${option.name})`
                            }
                            getOptionValue={(option) => option.dial_code}
                            placeholder="Select"
                          />
                        )}
                      />
                        {/* <Form.Select
                          className="form-control"
                          {...register("country")}
                        >
                          {country.map((info, index) => (
                            <option key={index}>{info.name}</option>
                          ))}
                        </Form.Select> */}
                        {errors.country?.type === "required" && (
                          <span className="errorMessage">
                            Enter your country name
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 register-col  mt-3">
                      <div className="form-group">
                        <p
                          className="input-text heading"
                        >
                          Post Code
                        </p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="1044"
                          {...register("postalCode", {
                            required: true,
                          })}
                        />
                        {errors.post?.type === "required" && (
                          <span className="errorMessage">
                            Enter your Post Code
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will need to contact you directly to confirm the
                    amendment.
                  </span>
                </p>

                <div className="modal-footer modal-bottom-action">
                  <ContactUs type={MODAL_BUSINESS_IDENTIFY.PRIMARY_ADDRESS}  handleClose={handleCloseModal} />
                  <button type="submit" className="btn agree-button">
                    Agree & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <SaveModal
        saveModalShow={saveModalShow}
        saveModalClose={saveModalClose}
      />
    </>
  );
}
