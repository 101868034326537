import React, { useState, useEffect, useRef } from "react";
import ContentLoader from "react-content-loader";
import {
  CURRENCY,
  MESSAGE_TYPE,
  PRODUCT_GOLD_TYPE_NAME_INITIAL,
  USER_DASHBOARD_BULLION,
} from "../../../../constants";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserDashboardAction,
  userDashboardTourAction,
} from "../../../../actions/userDashboard";
import { bidGoldBullionCurrency } from "../../../../actions/stripe";
import CurrencyFormat from "../../../../components/currencyFormat/currencyFormat";
import { RoutesPaths } from "../../../../Router/constant";
import { useNavigate } from "react-router";

export default function BullionBalance({
  isEditing,
  setIsEditing,
  sendMessageToAdmin,
  adminLoader,
}) {
  const { control, reset, register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bullionRecord, isUserDashboardBullionBalanceLoading } = useSelector(
    (state) => state.userDashboard
  );
  const { isAdminLoggedIn } = useSelector((state) => state.auth);
  const { bidGoldHeader } = useSelector((state) => state.user);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bullionRecord",
  });

  useEffect(() => {
    const getBullionBalance = () => {
      reset();
      bullionRecord &&
        bullionRecord?.length > 0 &&
        bullionRecord.forEach((element) => {
          append({
            type: element?.type,
            currency: element?.currency,
            bullion: element?.bullion,
            weight: element?.weight,
            balance: element?.balance,
            convertionCurrency: element?.convertionCurrency,
            convertionBalance: element?.convertionBalance,
          });
        });
    };
    getBullionBalance();
  }, [bullionRecord]);

  const onSubmit = async (data) => {
    await dispatch(updateUserDashboardAction(data));
    setIsEditing(null);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accountBorderSection">
          {isUserDashboardBullionBalanceLoading ? (
            <div className="row cashHederSection cashHederSectionPadding">
              <div className="col-md-12">
                <div style={{ width: "100%", marginLeft: "auto" }}>
                  <ContentLoader width="100%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          ) : (
            <div className="row cashHederSection cashHederSectionPadding">
              <div className="col-md-5 col-6 button_align">
                <h6 className="cashHeaderText cashDarkText m-0">Bullion</h6>
              </div>
              {/* <div className='col-md-7 col-6 flexSection mobileAlign'>
                                <div>
                                    {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                                        <input
                                            type='text'
                                            className='form-control customInputField textInputAlignText textInputAlignTextM'
                                            {...register("bullionBalance", {})}
                                            defaultValue={userDashboard?.bullionBalance || ""}
                                        />
                                    ) : (
                                        <h6 className='cashHeaderText cashGreyText m-0'>{userDashboard?.bullionBalance || "-"}</h6>
                                    )}
                                </div>

                                {isAdminLoggedIn && (
                                    isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                                        <button className="btn save-button customFilterButton iconMarginLeft" type='submit'>
                                            Save
                                        </button>
                                    ) : (
                                        <span className="btn btn-link  px-3 mb-0 edit-button iconMarginLeft" onClick={() => setIsEditing(USER_DASHBOARD_BULLION.BULLIONBALANCE)}>
                                            <i className="fas fa-pencil-alt edit-button me-2"></i>
                                        </span>
                                    )
                                )}
                            </div> */}
              {isAdminLoggedIn && (
                <div className="col-md-7 col-6 flexSection mobileAlign">
                  <span className="btn btn-link  px-3 mb-0 edit-button faded-edit iconMarginLeft"></span>
                </div>
              )}
            </div>
          )}

          <div className="col-12">
            <hr className="m-0"></hr>
          </div>

          <div className="cashBottomPadding">
            <div className="row">
              <div className="col-5">
                <p className="currentBalanceText m-0">Bullion</p>
              </div>
              <div
                className={`col-${isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? 3 : 4
                  }`}>
                <p className="currentBalanceText m-0">Weight</p>
              </div>
              <div
                className={`col-${isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? 4 : 3
                  } rightAlignText`}>
                <p className="currentBalanceText m-0">Value</p>
              </div>
              {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                <div className="col-2 deleteIcon">
                  <p className="currentBalanceText m-0">Action</p>
                </div>
              ) : (
                <></>
              )}
            </div>

            {isUserDashboardBullionBalanceLoading ? (
              <div className="row">
                <div className="col-12 currentBalanceDetails">
                  <ContentLoader width="100%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            ) : (
              fields?.map((record, index) => (
                <div key={record.id} className="row">
                  <div className="col-5">
                    {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                      <input
                        type="text"
                        className="form-control customInputField mb-2"
                        {...register(`bullionRecord.${index}.bullion`)}
                      />
                    ) : (
                      <h6 className="currentBalanceDetails m-0">
                        {record?.bullion || "-"}
                      </h6>
                    )}
                  </div>

                  <div
                    className={`col-${isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE
                      ? 3
                      : 4
                      }`}>
                    {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                      <input
                        type="text"
                        className="form-control customInputField mb-2"
                        {...register(`bullionRecord.${index}.weight`)}
                      />
                    ) : (
                      <h6 className="currentBalanceDetails m-0">
                        {record?.weight || "-"}{" "}
                        {record?.type ===
                          PRODUCT_GOLD_TYPE_NAME_INITIAL.HEDGEKILOGRAM
                          ? PRODUCT_GOLD_TYPE_NAME_INITIAL.KILOGRAM
                          : record?.type || "-"}
                      </h6>
                    )}
                  </div>
                  <div
                    className={`col-${isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE
                      ? 4
                      : 3
                      } rightAlignText`}>
                    {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                      <input
                        type="text"
                        className="form-control customInputField mb-2 textInputAlignText"
                        {...register(`bullionRecord.${index}.balance`)}
                      />
                    ) : (
                      <h6 className="currentBalanceDetails m-0">
                        {record?.type ===
                          PRODUCT_GOLD_TYPE_NAME_INITIAL.KILOGRAM ? (
                          <>
                            {record?.convertionCurrency?.toUpperCase()}{" "}
                            <CurrencyFormat
                              currency={record?.convertionCurrency}
                              value={parseFloat(bidGoldHeader?.gold1kg?.price)}
                              decimal={2}
                            />
                          </>
                        ) : record?.type ===
                          PRODUCT_GOLD_TYPE_NAME_INITIAL.GRAM ? (
                          <>
                            {record?.convertionCurrency?.toUpperCase()}{" "}
                            <CurrencyFormat
                              currency={record?.convertionCurrency}
                              value={parseFloat(bidGoldHeader?.gold1g?.price)}
                              decimal={2}
                            />
                          </>
                        ) : record?.type ===
                          PRODUCT_GOLD_TYPE_NAME_INITIAL.HEDGEKILOGRAM ? (
                          <>
                            {record?.convertionCurrency?.toUpperCase()}{" "}
                            <CurrencyFormat
                              currency={record?.convertionCurrency}
                              value={parseFloat(
                                bidGoldHeader?.gold1kghedge?.price
                              )}
                              decimal={2}
                            />
                          </>
                        ) : (
                          <>
                            {record?.convertionCurrency?.toUpperCase()}{" "}
                            <CurrencyFormat
                              currency={record?.convertionCurrency}
                              value={parseFloat(record?.convertionBalance)}
                              decimal={2}
                            />
                          </>
                        )}
                      </h6>
                    )}
                  </div>
                  {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
                    <div className="col-2 deleteIcon">
                      <div
                        onClick={() => {
                          remove(index);
                        }}>
                        <span className="mb-0">
                          <i className="fa fa-times-circle edit-button m-0"></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            )}

            {isEditing === USER_DASHBOARD_BULLION.BULLIONBALANCE ? (
              <div className="row">
                <div className="col-12 addRowAlignIcon addRightPadding">
                  <span
                    className="btn btn-link px-3 mb-0 edit-button"
                    onClick={() => {
                      append({ bullion: "", weight: "", balance: "" });
                    }}>
                    <i className="fa-solid fa-plus edit-button"></i>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {isUserDashboardBullionBalanceLoading ? (
              <div className="row cashBottomButton">
                <div className="col-7 skeletonFlexBB">
                  <ContentLoader width="85%" height={50}>
                    <rect x="0" y="0" rx="10" ry="10" width="85%" height="40" />
                  </ContentLoader>

                  <ContentLoader width="85%" height={50}>
                    <rect x="0" y="0" rx="10" ry="10" width="85%" height="40" />
                  </ContentLoader>
                </div>
                <div className="col-5 skeletonFlexEnd">
                  <div style={{ width: "80%", marginLeft: "auto" }}>
                    <ContentLoader width="100%" height={50}>
                      <rect
                        x="0"
                        y="0"
                        rx="10"
                        ry="10"
                        width="100%"
                        height="40"
                      />
                    </ContentLoader>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row cashBottomButton">
                <div className="col-7 button_align">
                  <button
                    disabled={
                      adminLoader === 3 || adminLoader > 0 ? true : false
                    }
                    type="button"
                    // onClick={() =>
                    //   sendMessageToAdmin(
                    //     MESSAGE_TYPE.USER_DASHBOARD,
                    //     "<strong>Buy Bullion</strong>: I am interested to procure bullion",
                    //     3
                    //   )
                    // }
                    onClick={() => navigate(RoutesPaths.PRODUCTS_LIST_PAGE)}
                    className="customFilterButton mobileViewImportButton buttonRIghtMargin spotlightStep1">
                    {adminLoader === 3 && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Buy bullion
                  </button>
                </div>
                <div className="col-5 rightAlignText">
                  <button
                    disabled={
                      adminLoader === 4 || adminLoader > 0 ? true : false
                    }
                    type="button"
                    onClick={() =>
                      sendMessageToAdmin(
                        MESSAGE_TYPE.USER_DASHBOARD,
                        "<strong>Sell Bullion</strong>: I am interested to sell bullion",
                        4
                      )
                    }
                    className="customImportButton mobileViewImportButton m-0">
                    {adminLoader === 4 && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Sell bullion
                  </button>
                  {/* <button
                    disabled={
                      adminLoader === 5 || adminLoader > 0 ? true : false
                    }
                    type="button"
                    onClick={() =>
                      sendMessageToAdmin(
                        MESSAGE_TYPE.USER_DASHBOARD,
                        "<strong>Withdraw funds</strong>: I am interested to withdraw funds",
                        5
                      )
                    }
                    className="customFilterButton mobileViewImportButton">
                    {adminLoader === 5 && (
                      <i className="animateSpin fa-solid fa-circle-notch"></i>
                    )}{" "}
                    Withdraw funds
                  </button> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
