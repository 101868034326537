import React from "react";
import { Modal } from "react-bootstrap";

export default function PaymentConfirmation({ paymentConfirmationModalShow, paymentConfirmationModalClose, paymentConfirmationModalCloseButton, paymentType }) {
  return (
    <Modal className="payment-confirmation" show={paymentConfirmationModalShow} onHide={paymentConfirmationModalClose}>
      <div className="modal-header text-center p-0">
        <h5 className="modal-title">
          {paymentType ? 'Success' : 'Failed'}
        </h5>
        <button
          type="button"
          className="btn-close text-dark close-button"
          onClick={paymentConfirmationModalClose}
        >

        </button>
      </div>
      <div className="modal-body">
        <p className="text-sm information-text">
          {paymentType ? 'Thank you. Your purchase has been captured successfully.' : 'Opps, Your payment is failed, please try again later.'}

        </p>
        <button
          onClick={paymentConfirmationModalCloseButton}
          type="button"
          className="btn agree-button"
          style={{margin: '0 auto'}}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
