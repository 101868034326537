import { GET_OFFICER_LIST, LOAD_OFFICER_SKELETON } from "../actions/types";
const initialState = {
  officers: [],
  pendingOfficerList: [],
  loadOfficer: false
};
export default function (state = initialState, action) {
  const { type, payload, pendingInviteList } = action;
  switch (type) {
    case GET_OFFICER_LIST:
      return {
        ...state,
        officers: payload,
        pendingOfficerList: pendingInviteList,
        loadOfficer: false
      };
    case LOAD_OFFICER_SKELETON:
      return {
        ...state,
        loadOfficer: payload
      };
    default:
      return state;
  }
}
