import { useNavigate } from "react-router";
import ContactUs from "../../../components/ContactUs/ContactUs";
import Currency from "../../../components/currency/currency";
import CurrencyFormat from "../../../components/currencyFormat/currencyFormat";
import { CONTACT_US_TRIGER, PRODUCT_GOLD_TYPE, SUBSCRIPTION_MODULE_TYPE } from "../../../constants";

export default function ProductPlanSegment({ activeSegment, selectedPlanProduct, selectedCompany, handlePlanPopupShow }) {
    const navigate = useNavigate()
    return (
        <>
            {Object.keys(selectedPlanProduct).length > 0 && (
                <div className="suggestedRightSection" style={{ backgroundImage: "url(assets/img/planBack.jpg)" }}>
                    <div className="planHolder">
                        {(selectedCompany?.subscriptionPlan && Object.keys(selectedCompany?.subscriptionPlan).length > 0 && selectedCompany?.subscriptionPlan?._id === selectedPlanProduct?.id) && (
                            <span className="suggestedTextPopup">SUGGESTED {selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? "PLAN" : "PRODUCT"}</span>
                        )}
                        <div className="planName">
                            <h4>{selectedPlanProduct?.name}</h4>
                            <div className="planDescription" dangerouslySetInnerHTML={{ __html: selectedPlanProduct?.targetAudience || ""}}></div>
                            <div className="planDetail">
                                {selectedPlanProduct?.isEmptyButton ? (
                                    selectedPlanProduct?.targetAudience2 && (
                                        <div className="detailInner">
                                            <img src="assets/img/IconShield.svg" alt="planIcon" />
                                            <p>{selectedPlanProduct?.targetAudience2}</p>
                                        </div>
                                    )
                                ) : (

                                    <>
                                        <div className="detailInner">
                                            {[PRODUCT_GOLD_TYPE.GRAM, PRODUCT_GOLD_TYPE.KILOGRAM].includes(selectedPlanProduct?.goldProductType?.value) ? (
                                                <>
                                                    <img src="assets/img/IconProduct.svg" alt="productIcon" />
                                                    <p>{selectedPlanProduct?.goldProductType?.name ? `Price / ${selectedPlanProduct?.goldProductType?.name}` : "-"}</p>
                                                </>
                                            ): (
                                                selectedPlanProduct?.planType.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? (
                                                    <>
                                                        <img src="assets/img/IconShield.svg" alt="planIcon" />
                                                        <p>Payment plan</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src="assets/img/IconProduct.svg" alt="productIcon" />
                                                        <p>{selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL ? "Investment capital in exchange for shares" : "One-time purchase"}</p>
                                                    </>
                                                )
                                            )}
                                            
                                        </div>
                                        <div className="planPrice">
                                            <p className="salePrice"><Currency currency={selectedPlanProduct?.currency?.value} /> <CurrencyFormat currency={selectedPlanProduct?.currency?.value} value={parseFloat(selectedPlanProduct?.price)} decimal={2} /></p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="upgradePlan">
                                <div className="row">
                                    <div className="col-md-6">
                                        <ContactUs subscriptionPlanName={selectedPlanProduct?.name} type={activeSegment === 0 ? CONTACT_US_TRIGER.PAYMENT : activeSegment === 1 ? CONTACT_US_TRIGER.PAID_UP_CAPITAL_PAYMENT : ""} theme="sidePaymentBanner" handleClose={() => handlePlanPopupShow(false)} />
                                    </div>
                                    <div className="col-md-6">
                                        {selectedPlanProduct?.isEmptyButton ? (
                                            <button onClick={() => navigate(selectedPlanProduct?.emptyButtonTrigger.redirectUrl)} className="planUpgrade">{selectedPlanProduct?.emptyButtonTrigger.buttonText}</button>
                                        ) : (
                                            <button disabled={(selectedCompany?.isBuyEnabled ? false : true) || (activeSegment === 1 && selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL && parseFloat(selectedPlanProduct?.price) < 100)} onClick={handlePlanPopupShow} className="planUpgrade">{selectedCompany?.isBuyEnabled ? (activeSegment === 0 ? selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? "Upgrade plan" : "Buy product" : activeSegment === 1 ? "Deposit paid-up capital" : "") : ("Deposit paid-up capital")}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}