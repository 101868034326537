import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CAPITAL, I_POP_OVER, SUBSCRIPTION_MODULE_TYPE, SUBSCRIPTION_MODULE_TYPE_NAME, SUPPORTED_PAYMENT_METHOD_LIST, SUPPORTED_PAYMENT_METHOD_NAME } from "../../constants";
import { addCapital } from "../../actions/capital";
import { useDispatch, useSelector } from "react-redux";
import ContactUs from "../../components/ContactUs/ContactUs";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import ContentLoader from "react-content-loader";
import InfoModal from "../dashboard/infoModal";
import { getSettingsAction } from "../../actions/settings";
import { selectedPlanProductAction } from "../../actions/subscriptionPlan";
import { paymentModalAction } from "../../actions/stripe";
import { RoutesPaths } from "../../Router/constant";
import InfoModalStatic from "../dashboard/infoModalStatic";
export default function PaidUpCapital(props) {
  const dispatch = useDispatch();
  const { loadCompany, selectedCompany } = useSelector((state) => state.company);
  const { settings } = useSelector((state) => state.settings)
  const { defaultStripePriceId } = settings
  const { paidUpAmountPaid } = useSelector((state) => state.capital);
  const { selectedPlanProduct, selectedPlanProductCurrentPage } = useSelector((state) => state.subscriptionPlanProducts)
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props?.capital.length > 0) {
      planButtonTrigger(props?.capital[(selectedPlanProductCurrentPage && selectedPlanProductCurrentPage?.activePaidUpIndex > 0) ? selectedPlanProductCurrentPage?.activePaidUpIndex : 0], false)
    } else {
      emptyButtonTrigger()
    }
  }, [props?.capital])

  useEffect(() => {
    dispatch(getSettingsAction())
  }, [dispatch])

  const [infoModalShow, setInfoModalShow] = useState(false);

  const emptyButtonTrigger = async () => {
    const payload = {
      name: "Add paid-up capital",
      targetAudience: "Ordinary or preference",
      targetAudience2: "Deposit via card, crypto or bank transfer",
      planType: {},
      isActive: false,
      baseRequestCurrency: null,
      currency: null,
      price: 0,
      stripePriceId: null,
      supportedPaymentMethod: null,
      capital: {},
      isEmptyButton: true,
      emptyButtonTrigger: {
        buttonText: "Add paid-up capital",
        redirectUrl: RoutesPaths.SHARECAPITAL
      }
    }
    await dispatch(selectedPlanProductAction(payload))
  }

  const validateEnablePayCapital = (item, showPayableAmountTrigger = false) => {
    const paidAmountResp = paidUpAmountPaid.find(x => x.currency.toLowerCase() === item?.currency?.toLowerCase() && parseInt(x.shareType) === item?.shareType)
    const priceAmount = (paidAmountResp && Object.keys(paidAmountResp).length > 0) ? (parseFloat(item?.shareCapital) - parseFloat(paidAmountResp?.paidAmount)) : item?.shareCapital
    if(showPayableAmountTrigger){
      return parseFloat(priceAmount) > 0 ? parseFloat(priceAmount) : 0
    }else{
      return parseFloat(priceAmount) < 100 ? true : false
    }
  }

  const planButtonTrigger = async (item, modalEnable, activePaidUpindex) => {
    const paidAmountResp = paidUpAmountPaid.find(x => x.currency.toLowerCase() === item?.currency?.toLowerCase() && parseInt(x.shareType) === item?.shareType)
    const priceAmount = (paidAmountResp && Object.keys(paidAmountResp).length > 0) ? (parseFloat(item?.shareCapital) - parseFloat(paidAmountResp?.paidAmount)).toFixed(2) : item?.shareCapital

    const payload = {
      name: "Paid-up capital",
      targetAudience: `You are depositing paid-up capital to ${selectedCompany?.companyName} in return for share ownership in the named company.`,
      planType: {
        value: SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL,
        name: SUBSCRIPTION_MODULE_TYPE_NAME[SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL]
      },
      isActive: true,
      baseRequestCurrency: item?.currency?.toLowerCase() || null,
      currency: { "value": item?.currency?.toLowerCase(), "label": item?.currency?.toUpperCase() },
      price: (parseFloat(priceAmount) > 0 ? priceAmount : 0) || 0,
      stripePriceId: defaultStripePriceId ? defaultStripePriceId[item?.currency?.toLowerCase()] : null || null,
      supportedPaymentMethod: SUPPORTED_PAYMENT_METHOD_LIST || [],
      capital: item
    }
    await dispatch(selectedPlanProductAction(payload, null, props?.activeSegment, activePaidUpindex))
    if (parseFloat(priceAmount) >= 100) {
      if (modalEnable) {
        dispatch(paymentModalAction(true, payload?.planType?.value));
      }
    }
  }

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  }

  return (
    <>
      <div className="tableHolder paymentPaidupTableHolder">

        <table>
          <thead className="tableHeader">
            <tr>
              <th className="paidIp-Capital">Paid-up capital amount</th>
              <th className="paidupCurrency">Currency</th>
              <th className="paidupShareType">Share type</th>
              <th className="paidIp-Capital">Status</th>
              <th className="paidupAction">Action</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {props?.capital &&
              props?.capital.length > 0 &&
              props?.capital.map((item, index) => (
                <React.Fragment key={index}>
                  <tr className="cursorPointer">
                    <td className={`paidIp-Capital ${index === selectedPlanProductCurrentPage?.activePaidUpIndex ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item, false, index)}>
                      <div className="planName">
                        <p className="productDetailsName">
                          $ <CurrencyFormat value={validateEnablePayCapital(item, true) || "-"} />
                          &nbsp;
                          {validateEnablePayCapital(item) && (
                            <img
                              src="assets/img/info-icon.svg"
                              onClick={() => handleClickInfo(I_POP_OVER.PAYMENT_PAID_UP_CAPITAL_LIMIT)}
                              alt=""
                              className="m-0 pb-1 cursorPointer"
                            />
                          )}
                        </p>
                      </div>
                    </td>
                    <td className={`paidupCurrency ${index === selectedPlanProductCurrentPage?.activePaidUpIndex ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item, false, index)}>
                      <div className="d-flex flex-column justify-content-center">
                        <p className="productDetailsInfo m-0">{item.currency || "-"}</p>
                      </div>
                    </td>
                    <td className={`paidupShareType ${index === selectedPlanProductCurrentPage?.activePaidUpIndex ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item, false, index)}>
                      <div className="d-flex">
                        <div className="d-flex flex-column justify-content-center">
                          <span className="business-font productDetailsInfo">
                            {item.shareType === 1
                              ? "Ordinary"
                              : item.shareType === 2
                                ? "Preference"
                                : ""}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className={`paidIp-Capital  ${index === selectedPlanProductCurrentPage?.activePaidUpIndex ? 'activeRow' : ''}`} onClick={() => planButtonTrigger(item, false, index)}>
                      {!loadCompany && props?.company?.id && (
                        <>
                          {/* {!props.capitalIsApproved && (
                        <div>
                          <button className="btn amendment-progress-button-second">
                            Acceptance in progress
                          </button>
                        </div>
                      )} */}

                          {props?.amendmentFlag[item?.currency.toLowerCase() + item?.shareType] && (
                            <div>
                              <button className="btn amendment-progress-button">
                                Amendment in progress
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                    <td className={`${index === selectedPlanProductCurrentPage?.activePaidUpIndex ? 'paidupAction activeRow' : ''}`}>
                      <button disabled={validateEnablePayCapital(item)} className={`tableButton`} onClick={() => planButtonTrigger(item, true, index)}>
                        <img
                          src="assets/img/payBlack.svg"
                          alt=""
                          className="m-0 px-1 pl-0 cursorPointer"
                        />
                        Pay capital
                      </button>
                    </td>
                  </tr>
                  {index < props?.capital.length - 1 && (
                    <tr>
                      <th className="tableHrsection" colSpan="5">
                        <hr />
                      </th>
                    </tr>
                  )}
                </React.Fragment>
              ))}

            {props.capital && props.capital.length === 0 && (
              <tr>
                <td className="paidIp-Capital">
                  {!loadCompany ? (
                    <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ContentLoader width="100%" height={15}>
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                      </ContentLoader>
                    </div>
                  )}
                </td>
                <td className="paidupCurrency">
                  {!loadCompany ? (
                    <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ContentLoader width="100%" height={15}>
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                      </ContentLoader>
                    </div>
                  )}
                </td>
                <td className="paidupShareType">
                  {!loadCompany ? (
                    <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ContentLoader width="100%" height={15}>
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                      </ContentLoader>
                    </div>
                  )}
                </td>
                <td className="paidIp-Capital">
                  {!loadCompany ? (
                    <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ContentLoader width="100%" height={15}>
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                      </ContentLoader>
                    </div>
                  )}
                </td>
                <td className="paidupAction">
                  {!loadCompany ? (
                    <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ContentLoader width="100%" height={15}>
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                      </ContentLoader>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {props?.company?.verificationMethod === "myinfo"  && props?.myInfoCapital && props?.myInfoCapital.length > 0 && (
          <div className="myinfoCapital">
            <h3>
              MyInfo paid-up capital{" "}
              <img
                src="assets/img/info-icon.svg"
                onClick={() => setInfoModalShow(true)}
                alt=""
                className="m-0 pb-1 cursorPointer"
              />
            </h3>

            <table>
              <tbody className="tableBody">
                {props?.myInfoCapital &&
                  props?.myInfoCapital.length > 0 &&
                  props?.myInfoCapital.map((item, index) => (
                    <tr key={index}>
                      <td className="paidIp-Capital">
                        <div className="planName">
                          <p className="productDetailsName">
                            $ <CurrencyFormat value={item.shareCapital || "-"} />
                          </p>
                        </div>
                      </td>
                      <td className="paidupCurrency">
                        <div className="d-flex flex-column justify-content-center">
                          <p className="productDetailsInfo m-0">{item.currency || "-"}</p>
                        </div>
                      </td>
                      <td className="paidupShareType">
                        <div className="d-flex flex-column justify-content-center">
                          <p className="productDetailsInfo m-0"> {item.shareType.desc || "-"}</p>
                        </div>
                      </td>
                      <td className="paidIp-Capital">
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                        {/* {!loadCompany && props?.company?.id && (
                        <>
                          {!props.capitalIsApproved && (
                            <div>
                              <button className="btn amendment-progress-button-second">
                                Acceptance in progress
                              </button>
                            </div>
                          )}
                          {!props.capitalIsEditApproved && (
                            <div>
                              <button className="btn amendment-progress-button">
                                Amendment in progress
                              </button>
                            </div>
                          )}
                        </>
                      )} */}
                      </td>
                      <td className="paidupAction">
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                {props.myInfoCapital && props.myInfoCapital.length === 0 && (
                  <tr>
                    <td className="paidIp-Capital">
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                    <td className="paidupCurrency">
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                    <td className="paidupShareType">
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                    <td className="paidIp-Capital">
                      {!loadCompany ? (
                        <div className="d-flex px-2 ">
                          <div className="d-flex flex-column justify-content-center ">
                            <span className="business-font">-</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContentLoader width="100%" height={15}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                          </ContentLoader>
                        </div>
                      )}
                    </td>
                    <td className="paidupAction">
                      <div className="d-flex px-2 ">
                      <div className="d-flex flex-column justify-content-center ">
                        <span className="business-font">-</span>
                      </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <InfoModal
          infoModalShow={infoModalShow}
          infoModalClose={() => setInfoModalShow(false)}
          capitalType={props.capitalType}
        />

        <Modal
          show={props.capModalShow}
          onHide={props.capModalClose}
          className="moddal-width custom-modal companyEdit-modal ps-0"
        >
          <div className="modal-header p-0">
            <button
              type="button"
              className="btn-close text-dark close-button"
              onClick={props.capModalClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
                <h5 className="edit-title">
                  {props.capital.length === 0 ? "Add" : "Edit"}{" "}
                  {props.capitalType === CAPITAL.shared
                    ? "Share"
                    : props.capitalType === CAPITAL.paidUp
                      ? "Paid-Up"
                      : ""}{" "}
                  Capital
                </h5>
                {props.capitalType === CAPITAL.shared && (
                  <div>
                    <p className="edit-description">
                      Every business needs a minimum of 1 share and 1 dollar (SGD or USD), as a share capital. Some businesses require a
                      larger share capital for reasons such as tendering for a
                      contract or attracting stakeholders.
                    </p>
                    <p className="edit-description">
                      You can add shares, share capital, in SGD or USD
                      and determine the share type as many times as you need to.
                      You cannot subtract shares and share capital.
                    </p>
                    <p className="edit-description">
                      We will be sending you notifications to confirm the share
                      capital additions.
                    </p>
                  </div>
                )}

                {props.capitalType === CAPITAL.paidUp && (
                  <div>
                    <p className="edit-description">
                      Every business needs a minimum of 1 Singapore dollar, as a
                      paid-up capital. Some businesses require a larger paid-up
                      capital for reasons such as tendering for a contract or
                      attracting stakeholders.
                    </p>
                    <p className="edit-description">
                      You can increase paid-up capital, in different currencies
                      and determine the share type as many times as you need to.
                      You cannot subtract paid-up capital.
                    </p>
                    <p className="edit-description">
                      We will be sending you notifications to confirm paid-up
                      capital additions.
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7 mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">
                        Current{" "}
                        {props.capitalType === CAPITAL.shared
                          ? "Share"
                          : props.capitalType === CAPITAL.paidUp
                            ? "Paid-Up"
                            : ""}{" "}
                        Capital
                      </p>
                      <span className="business-font">
                        {props.capital.length > 0 && "$ "}{" "}
                        {props.capital.length === 0 ? (
                          "-"
                        ) : (
                          <CurrencyFormat
                            value={
                              props.capital[props.capital.length - 1]
                                .shareCapital
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">
                          Add{" "}
                          {props.capitalType === CAPITAL.shared
                            ? "Share"
                            : props.capitalType === CAPITAL.paidUp
                              ? "Paid-Up"
                              : ""}{" "}
                          Capital
                        </p>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Amount to add"
                          maxLength={10}
                          {...register("shareCapital", {
                            required: true,
                          })}
                        />
                        {errors.shareCapital?.type === "required" && (
                          <span className="errorMessage">
                            Add{" "}
                            {props.capitalType === CAPITAL.shared
                              ? "Share"
                              : props.capitalType === CAPITAL.paidUp
                                ? "Paid-Up"
                                : ""}{" "}
                            capital is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {props.capitalType === CAPITAL.shared && (
                  <div className="row mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                      <div className="form-group">
                        <p className="input-text heading">Number of Shares</p>
                        <span className="business-font">
                          {props.capital.length === 0
                            ? "-"
                            : props.capital[props.capital.length - 1].shares}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                      <div className="form-group">
                        <div>
                          <p
                            htmlFor="recipient-name"
                            className="input-text heading"
                          >
                            Add number of shares
                          </p>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Number of shares to be added"
                            maxLength={10}
                            {...register("shares", {
                              required: true,
                            })}
                          />
                          {errors.shares?.type === "required" && (
                            <span className="errorMessage">
                              Number of shares to be added
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">Currency</p>
                      <span className="business-font">
                        {props.capital.length === 0
                          ? "-"
                          : props.capital[props.capital.length - 1].currency}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">
                          {props.capitalType === CAPITAL.shared
                            ? "Share"
                            : props.capitalType === CAPITAL.paidUp
                              ? "Paid-Up"
                              : ""}{" "}
                          capital currency
                        </p>
                        <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("currency", {
                            required: true,
                          })}
                        >
                          <option value="USD">USD</option>
                          <option value="SGD">SGD</option>
                        </Form.Select>
                        {errors.currency?.type === "required" &&
                          getValues("currency") === "" && (
                            <span className="errorMessage">
                              Select your Currency
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 colWidth-mobile">
                    <div className="form-group">
                      <p className="input-text heading">Share Type</p>
                      <span className="business-font">
                        {props.capital.length === 0
                          ? "-"
                          : props.capital[props.capital.length - 1]
                            .shareType === 1
                            ? "Ordinary"
                            : "Preference"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-4 col-sm-6 col-xs-6 col-xl-8 colWidth-mobile">
                    <div className="form-group">
                      <div>
                        <p className="input-text heading">Choose share type</p>
                        <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("shareType", {
                            required: true,
                          })}
                        >
                          <option value="1">Ordinary</option>
                          <option value="2">Preference</option>
                        </Form.Select>
                        {errors.shareType?.type === "required" &&
                          getValues("shareType") === "" && (
                            <span className="errorMessage">
                              Select your Share type
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note: &nbsp;
                  <span className="will-need-text">
                    By proceeding, we will notify you directly and advise you
                    accordingly on the next steps to formalise the changes.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs handleClose={() => setInfoModalShow(false)} />
                  <button type="submit" className="btn agree-button">
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />

    </>
  );
}
