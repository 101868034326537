import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BOB_CTA, SUBSCRIPTION_MODULE_TYPE } from "../../constants";
import AlertMessage from "../../components/commonAlert/alertMessage";
import { paymentModalAction } from "../../actions/stripe";

export default function PremiumAccess({ premiumOpen, premiumClose }) {
  const { selectedCompany } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const handleclose = () => {
    premiumClose();
  };

  const handleBobSubscription = () => {
    premiumClose();
    dispatch(paymentModalAction(true, SUBSCRIPTION_MODULE_TYPE.BOB));
  }

  return (
    <Modal
      show={premiumOpen}
      onHide={handleclose}
      className="moddal-primary importModal premiumModal ps-0"
    >
      <Modal.Body>
        <div className="header">
          <div>
            <h4>{selectedCompany.bobCta === BOB_CTA.NOTIFICATION ? "Notification" : selectedCompany.bobCta === BOB_CTA.SUBSCRIPTION ? "Premium Access Needed" : "-"}</h4>
          </div>
          <i onClick={handleclose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modalBody">
          <div className="premiumContent">
            {selectedCompany.bobCta === BOB_CTA.NOTIFICATION ? (
              <AlertMessage bob={true} />
            ) : (
              <>
                <p>
                  Additional information about this profile can only be accessed by {"Insert Subscription Title"} members.
                </p>
                <p>
                  Click Subscribe for complete access to all suggested profiles and
                  expand your business.
                </p>
              </>
            )}
          </div>
          {selectedCompany.bobCta === BOB_CTA.SUBSCRIPTION && (
            <div className="importBtn">
              <button
                onClick={handleBobSubscription}
                type="submit"
                className="btn agree-button"
              >
                Subscribe
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
