import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { singleFileUploadAction } from "../../actions/files";
import { updateShareAction } from "../../actions/shareHolder";
import {
  backendUrl,
  CONTACT_US_TRIGER,
  country,
  CUSTOM_SELECT_STYLES,
  POSITIONS,
  SELECT_BOX_THEME,
} from "../../constants";
import Select from "react-select";
import ContactUs from "../ContactUs/ContactUs";

export default function EditModal({
  editModalShow,
  editModalClose,
  performObj,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  watch(["passportCopy", "poa"]);

  const onSubmit = (data) => {
    if (performObj.id) {
      data.company = performObj.company._id;
      data.countryCode = data.countryCode.dial_code;
      data.nationality = data.nationality.name;
      delete data["id"];
      dispatch(updateShareAction(performObj.company._id, performObj.id, data));
      editModalClose();
      reset();
    }
  };

  useEffect(() => {
    setValue("name", performObj.name);
    setValue("id", performObj.id);
    setValue("share_email", performObj.email);
    setValue(
      "countryCode",
      country.filter((x) => x.dial_code === performObj.countryCode)[0] || ""
    );
    setValue("phoneNumber", performObj.phoneNumber);
    setValue(
      "nationality",
      country.filter((x) => x.name === performObj.nationality)[0] || ""
    );
    setValue("position", performObj.position);
    setValue("address1", performObj.address1);
    setValue("address2", performObj.address2);
    setValue("city", performObj.city);
    setValue("postalCode", performObj.postalCode);
    setValue("passportCopy", performObj.passportCopy);
    setValue("poa", performObj.poa);
  }, [setValue, performObj]);

  const uploadFiles = (event, name) => {
    const file = event.target.files[0];
    uploadDispatchFile(file, name);
    event.target.value = null;
  };

  const uploadDispatchFile = (file, name) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(singleFileUploadAction(formData)).then((resp) => {
      if (resp) {
        setValue(name, resp);
      }
    });
  };

  return (
    <>
      <Modal
        show={editModalShow}
        onHide={editModalClose}
        className="custom-modal edit-information-width"
        id="directorModalMessage"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={editModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-xl-4">
              <h5 className="edit-title">Edit Shareholder Information</h5>
              <p className="edit-description">
                A shareholder is a person who owns a portion of the virtual
                business when he/she contributes, financially or
                non-financially, towards the virtual business. By paying for the
                shares, or being issued with shares, the shareholder is a
                partial owner of the virtual business.
              </p>
              <p className="edit-description">
                Here you can only add individual persons as shareholders to a
                virtual business. You can add as many as 20 shareholders to a
                virtual business.
              </p>
              <p className="edit-description">
                Every business also needs a minimum of 1 shareholder to the
                business. By default, we add the creator of this virtual
                business as shareholder.
              </p>
              <p className="edit-description">
                You can edit the information or remove other shareholders, as
                many times as you like. Changes made are only formalised later
                when all the procedures of approving the changes are carried
                out. We will contact you to advise you about the steps to
                formalise the changes.
              </p>
              {/* <p className="edit-description">
                Learn more about editing shareholders.
              </p> */}
            </div>
            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-6 col-xl-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <p className="input-text heading">Full name</p>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Shareholder Name"
                        {...register("name", {
                          required: true,
                          maxLength: 50,
                          minLength: 3,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span className="errorMessage">Name is required</span>
                      )}
                      {errors.name?.type === "maxLength" && (
                        <span className="errorMessage">
                          Name may not be longer than 50 characters
                        </span>
                      )}
                      {errors.name?.type === "minLength" && (
                        <span className="errorMessage">
                          Minimum 3 characters required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="input-text heading">ID number</p>
                      <input
                        readOnly
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="ID Number"
                        {...register("id", {
                          required: true,
                        })}
                      />
                      {errors.id?.type === "required" && (
                        <span className="errorMessage">
                          Enter your ID Number
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-4 mt-3">
                        <p className="input-text heading">Email address</p>
                        <input
                          readOnly
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Email address"
                          {...register("share_email", {
                            required: true,
                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          })}
                        />
                        {errors.share_email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.share_email?.type === "pattern" && (
                          <span className="errorMessage">Email is Invalid</span>
                        )}
                      </div>

                      <div className="col-lg-4  mt-3">
                        <p className="input-text heading">Country code</p>
                        <Controller
                          name="countryCode"
                          rules={{ required: true }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              theme={SELECT_BOX_THEME}
                              styles={CUSTOM_SELECT_STYLES}
                              className="select-border"
                              {...field}
                              options={country}
                              isClearable={true}
                              getOptionLabel={(option) =>
                                `${option.dial_code} (${option.name})`
                              }
                              getOptionValue={(option) => option.dial_code}
                              placeholder="Select"
                            />
                          )}
                        />
                        {/* <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("countryCode", {
                            required: true,
                          })}
                          style={{ paddingRight: "45px" }}
                        >
                          <option value="">Select</option>
                          {country.map((info, index) => (
                            <option key={index} value={info.dial_code}>
                              {info.dial_code} ({info.name})
                            </option>
                          ))}
                        </Form.Select> */}
                        {errors.countryCode?.type === "required" && (
                          <span className="errorMessage">
                            Enter Country Code
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4  mt-3">
                        <p className="input-text heading">Mobile number</p>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="number"
                          className="form-control"
                          placeholder="Mobile Number"
                          {...register("phoneNumber", {
                            required: true,
                          })}
                        />
                        {errors.phoneNumber?.type === "required" && (
                          <span className="errorMessage">
                            Mobile Number is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-12  mt-3">
                        <p className="input-text heading">Proposed position</p>

                        <Form.Select
                          className="select-border"
                          aria-label="Default select example"
                          {...register("position", {
                            required: true,
                          })}
                        >
                          {POSITIONS &&
                            POSITIONS.map(
                              (item, index) =>
                                index === 1 && (
                                  <option key={index} value={item.value}>
                                    {item.name}
                                  </option>
                                )
                          )}
                        </Form.Select>
                        {getValues("position") === "" && (
                          <span className="errorMessage">
                            Select your Position
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {getValues("share_email")=== user.email && (
                  <div className="row">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6  mt-3">
                          <p className="input-text heading">Passport copy</p>

                          <label
                            htmlFor="passportCopy"
                            className="form-control recipient-file"
                          >
                            <span className="text">
                              {getValues("passportCopy") &&
                              getValues("passportCopy").filename
                                ? getValues("passportCopy")?.originalName
                                : "Select File..."}
                            </span>
                            <span
                              className="uploadbtn
                            "
                            >
                              Upload
                            </span>
                          </label>

                          <input
                            maxLength={50}
                            autoComplete="off"
                            type="file"
                            className="form-control d-none"
                            placeholder=""
                            {...register("passportCopy", { required: false })}
                            id="passportCopy"
                            onChange={(e) => uploadFiles(e, "passportCopy")}
                          />
                          {getValues("passportCopy") &&
                            getValues("passportCopy").filename && (
                              <div className="mt-2">
                                <a
                                  href={`${
                                    backendUrl +
                                    getValues("passportCopy")?.filename
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {getValues("passportCopy")?.originalName}
                                </a>
                              </div>
                            )}
                        </div>

                        <div className="col-lg-6 mt-3">
                          <p className="input-text heading">
                            Proof of address copy
                          </p>

                          <label
                            htmlFor="poa"
                            className="form-control recipient-file"
                          >
                            <span className="text">
                              {getValues("poa") && getValues("poa")?.filename
                                ? getValues("poa")?.originalName
                                : "Select File..."}
                            </span>
                            <span
                              className="uploadbtn
                            "
                            >
                              Upload
                            </span>
                          </label>

                          <input
                            maxLength={50}
                            autoComplete="off"
                            type="file"
                            className="form-control d-none"
                            placeholder=""
                            {...register("poa", { required: false })}
                            id="poa"
                            onChange={(e) => uploadFiles(e, "poa")}
                          />
                          {getValues("poa") && getValues("poa")?.filename && (
                            <div className="mt-2">
                              <a
                                href={`${
                                  backendUrl + getValues("poa")?.filename
                                }`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {getValues("poa")?.originalName}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-12  mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Residential address</p>
                      <input
                        maxLength={50}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="12 Mapple St"
                        {...register("address1", {
                          required: true,
                        })}
                      />
                      {errors.address1?.type === "required" && (
                        <span className="errorMessage">
                          Enter your residential address
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label shreholder-text"
                      >
                        Address Line 2
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Address Line 2"
                        {...register("address2", {
                          required: true,
                        })}
                      />
                      {errors.address2?.type === "required" && (
                        <span className="errorMessage">
                          Enter your Address 2
                        </span>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-4  mt-3">
                    <div className="form-group">
                      <p className="input-text heading">City</p>
                      <input
                        maxLength={50}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Singapore"
                        {...register("city", {
                          required: true,
                        })}
                      />
                      {errors.city?.type === "required" && (
                        <span className="errorMessage">
                          Enter your City Name
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4  mt-3">
                    <p className="input-text heading">Country</p>
                    <Controller
                      name="nationality"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={SELECT_BOX_THEME}
                          styles={CUSTOM_SELECT_STYLES}
                          className="select-border"
                          {...field}
                          options={country}
                          isClearable={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          placeholder="Select"
                        />
                      )}
                    />
                    {/* <Form.Select
                      className="select-border"
                      aria-label="Default select example"
                      {...register("nationality", {
                        required: true,
                      })}
                    >
                      {country.map((info, index) => (
                        <option key={index}>{info.name}</option>
                      ))}
                    </Form.Select> */}
                    {errors.nationality?.type === "required" && (
                      <span className="errorMessage">Select your Country</span>
                    )}
                  </div>

                  <div className="col-lg-4  mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Post Code</p>
                      <input
                        maxLength={50}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="1044"
                        {...register("postalCode", {
                          required: true,
                        })}
                      />
                      {errors.postalCode?.type === "required" && (
                        <span className="errorMessage">
                          Enter your Post Code
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    You can edit the information as many times as you like. We
                    will send you notifications when you request changes and
                    contact you directly to advise on the steps to formalise the
                    changes.
                  </span>
                </p>
                <div className="modal-footer modal-bottom-action">
                  <ContactUs addEditValue="edit" type={CONTACT_US_TRIGER.SHAREHOLDER} handleClose={editModalClose} />
                  <button type="submit" className="btn agree-button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
