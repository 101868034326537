import axionIns from "../axios";

const getSubscriptonPlan = async (companyId, page, limit) => {
  return axionIns
    .get(
      `subscriptionplan/${companyId}?page=${page}&perPage=${limit ? limit : 5}`
    )
    .then((response) => {
      return response.data;
    });
};

const getSingleSubscriptonPlan = async (companyId, planId) => {
  return axionIns
    .get(`subscriptionplan/${companyId}/${planId}`)
    .then((response) => {
      return response.data;
    });
};

const exportOject = {
  getSubscriptonPlan,
  getSingleSubscriptonPlan,
};

export default exportOject;
