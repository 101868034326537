import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../../components/ContactUs/ContactUs";
import { CONTACT_US_TRIGER } from "../../constants";
import { getPositionName } from "../InvitesIndividualGlobal/IndividualInviteTableGlobal";

export default function ContratulationsPopup({
  show,
  close,
  actionData,
  goToChatPopup
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        className="custom-modal delete-information-width"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={close}
          ></button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title">
            Congratulations, you have accepted the invite from {actionData?.user?.first_name + ' ' + actionData?.user?.last_name} for {actionData?.company?.companyName}.
          </h5>
          <div className="row">
            <div className="col-lg-6 mt-4">
              <p className="input-text heading">Company</p>
              <p className="col-form-label company-text p-0 m-0">
                {actionData?.company?.companyName}
              </p>
            </div>

            <div className="col-lg-6 mt-4">
              <p className="input-text heading">Position</p>
              <p className="col-form-label company-text p-0 m-0">
                {getPositionName(actionData?.type)}
              </p>
            </div>
          </div>
          {/* <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              We will send you notifications to confirm the amendment
            </span>
          </p> */}

          <div className="modal-footer modal-bottom-action">
            <button
              onClick={goToChatPopup}
              type="button"
              className="btn agree-button"
            >
              Start message
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
