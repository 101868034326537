import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileAction } from "../../actions/user";
import SaveModal from "../../components/savemodal/saveModal";
// import { Link } from "react-router-dom";
// import { logout } from "../../actions/auth";
// import Confirm from "../../components/confirm/confrm";
// import { STATIC_EXTERNAL_URL } from "../../constants";
// import { RoutesPaths } from "../../Router/constant";

export default function ProfileInfo() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { user } = useSelector((state) => state.user);

  // const [confirmModal, setConfirmModal] = useState(false);
  // const logoutClick = () => {
  //   setConfirmModal(true)
  // };
  // const logoutHide = (value) => {
  //   if (value) {
  //     dispatch(logout());
  //   }
  //   setConfirmModal(false)
  // }

  const [handleSummary, sethandleSummary] = useState(false);
  const [saveModalShow, setSaveModalShow] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setValue("profileHeading", user.profileHeading);
      setValue("profileSummary", user.profileSummary);
    }
  }, [user, setValue]);

  const handleSummaryClick = () => {
    if (handleSummary) {
      reset();
    } else {
      if (user && user.id) {
        setValue("profileHeading", user.profileHeading);
        setValue("profileSummary", user.profileSummary);
      }
    }
    sethandleSummary(!handleSummary);

  }

  const onSubmit = (data) => {
    dispatch(updateUserProfileAction(data));
    handleSummaryClick()
    // setSaveModalShow(true);
  };


  return (
    <>
      {handleSummary ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card-header pb-0">
              <div className="row">
                <div className="col-md-8 col-8 mt-2">
                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder="Professional headline"
                    id="recipient-name"
                    {...register("profileHeading", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                    })}
                  />
                  {errors.profileHeading?.type === "required" && (
                    <span className="errorMessage">Heading is required</span>
                  )}
                  {errors.profileHeading?.type === "maxLength" && (
                    <span className="errorMessage">
                      Heading may not be longer than 100 characters
                    </span>
                  )}
                  {errors.profileHeading?.type === "minLength" && (
                    <span className="errorMessage">
                      Minimum 3 characters required
                    </span>
                  )}
                </div>
                <div className="col-md-4 col-4 text-end">
                  <span
                    onClick={handleSummaryClick}
                    className="btn btn-link  px-3 mb-0  text-sm lorem-text"
                  >
                    <i className={`fas fa-${handleSummary ? "xmark" : "pencil-alt"} me-2`} aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="card-body p-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      rows="10"
                      cols="50"
                      maxLength={1000}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Summary"
                      id="recipient-name"
                      {...register("profileSummary", {
                        required: true,
                        maxLength: 1000,
                        minLength: 3,
                      })}
                    />
                    {errors.profileSummary?.type === "required" && (
                      <span className="errorMessage">Summary is required</span>
                    )}
                    {errors.profileSummary?.type === "maxLength" && (
                      <span className="errorMessage">
                        Summary may not be longer than 1000 characters
                      </span>
                    )}
                    {errors.profileSummary?.type === "minLength" && (
                      <span className="errorMessage">
                        Minimum 3 characters required
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12  mt-3 colWidth-mobile">
                  <button
                    type="submit"
                    className="btn agree-button accountBtn"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-md-8 col-8 d-flex align-items-center">
                <h6 className="mb-0 profile-head">
                  {user && user.profileHeading ? user.profileHeading : "Profile information"}</h6>
              </div>
              <div className="col-md-4 col-4 text-end">
                <span
                  onClick={handleSummaryClick}
                  className="btn btn-link  px-3 mb-0  text-sm lorem-text"
                >
                  <i className={`fas fa-${handleSummary ? "xmark" : "pencil-alt"} me-2`} aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <p className="lorem-text">
              {user && user.profileSummary ? user.profileSummary : "-"}
            </p>
          </div>
        </>
      )}
      <SaveModal
        saveModalShow={saveModalShow}
        saveModalClose={() => setSaveModalShow(false)}
      />
    </>
  );
}
