import axionIns from "../axios";

const getOfficer = (payload) => {
  return axionIns.get("officers/" + payload).then((response) => {
    return response.data;
  });
};

const addOfficer = (payload) => {
  return axionIns.post("officers", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
};

const updateOfficer = (officerId, payload) => {
  return axionIns
    .put("officers/" + officerId, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const deleteOfficer = (payload) => {
  return axionIns.delete("officers/" + payload).then((response) => {
    return response.data;
  });
};

const appointDirector = (payload) => {
  return axionIns.put("officers/requestAppoint/" + payload).then((response) => {
    return response.data;
  });
};

const pendingInviteOfficers = (company, payload) => {
  return axionIns
    .get("invite/pendinginvites/" + company + "/" + payload)
    .then((response) => {
      return response.data;
    });
};


const deleteInvite = (companyId, payload) => {
  return axionIns.delete("invite/" + companyId + '/' + payload).then((response) => {
    return response.data
  })
}

const addDirectorChannels = (payload) => {
  return axionIns.post("officers/initiatechat", JSON.stringify(payload)).then((response) => {
    return response.data
  })
}

const exportOject = {
  getOfficer,
  addOfficer,
  updateOfficer,
  deleteOfficer,
  appointDirector,
  pendingInviteOfficers,
  deleteInvite,
  addDirectorChannels
};

export default exportOject;
