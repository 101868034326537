import React from "react";
import { Modal } from "react-bootstrap";
import { BOB_SERVICES, POSITIONS, PROFILE_STATUS } from "../../constants";
import { getPotentialPurpose } from "./ProfileForm";

export default function SuccessModal({
  successOpen,
  successClose,
  isEdit,
  reset,
  status
}) {
  const handleclose = () => {
    successClose();
    reset();
  };

  return (
    <Modal
      show={successOpen}
      onHide={handleclose}
      className="moddal-primary importModal ps-0"
    >
      <Modal.Body>
        <div className="header">
          <div>
            <h4>
              {isEdit ? (
                `${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "Profile"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "Company"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or Products"
                ) : ("")} updated successfully`
              ) : (
                status === PROFILE_STATUS.SUGGESTED_PROFILES ? `Suggested ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "Profile"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "Company"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or Products"
                ) : ("")} Added` : status === PROFILE_STATUS.WARM_PROFILES ? `Warm ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "Company"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or Products"
                ) : ("")} Added` : status === PROFILE_STATUS.MEETING_REQUESTS ? "Meeting Request Added" : ""
              )}
            </h4>
          </div>
          <i onClick={handleclose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modalBody">
          <div className="successBody profileCardBody">
            <p className="descrip">
              {!isEdit
                ? `You have ${successOpen?.company?.isDefault ? 'added' : "suggested"} the following 
                ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile(s)"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company(s)"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service(s) or Product(s)"
                ) : ("")} ${successOpen?.company?.isDefault ? 'under' : "to"}`
                : `You have added the following 
                 
                ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile(s)"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company(s)"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service(s) or Product(s)"
                ) : ("")}
                ${successOpen?.company?.isDefault ? 'under' : "to"}`}{" "}
              <span>{successOpen?.company?.companyName || "-"}</span>
              {successOpen?.company?.isDefault ? ". Click “make public” in the suggested list to indicate your interest to work with other users." : ""}
            </p>

            <div className="tableResponsive">
              <table className="profileTable succesTable">
                <thead>
                  <tr>
                    <th>
                      {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                        "Full Name"
                      ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                        "Company"
                      ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                        "Service or Product Name"
                      ) : ("")}
                    </th>
                    {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES || successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) && (
                      <th>Company {successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES ? "Type" : ""}</th>
                    )}
                    <th>Industry</th>
                    <th>Potential Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                          successOpen?.first_name + ' ' + successOpen?.last_name
                        ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                          successOpen?.companyProfile
                        ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                          successOpen?.serviceProductName
                        ) : ("")}
                      </p>
                    </td>
                    {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES || successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) && (
                      <td>
                        <p>{(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES ? successOpen?.companyType : successOpen?.companyProfile) || "-"}</p>
                        <p className="detail">
                          {successOpen?.positionProfile}
                        </p>
                      </td>
                    )}
                    <td>
                      <p>{successOpen?.industry}</p>
                    </td>
                    <td>
                      {successOpen?.bookOfBusinessGroupId?.isDefault ? (
                        successOpen?.customPotentialPurposeInput
                      ) : (
                        <p>{getPotentialPurpose(successOpen)}</p>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="note">
            {successOpen?.company?.isDefault ? (
              <p className="desc"><span>Important note:</span>&nbsp;
               {/* By confirming, you are suggesting to other users that you know this profile and can introduce this profile to them. So you may then be invited by other users who may be interested to know and work with this profile. */}
                “Make public" will allow InProved to show your email address and full name to other users who are interested in having you introduce this profile (or any profile you add) to them. Note that no attempt will be made by anyone to connect or engage with this profile as the profile details will be hidden for all users. You may then receive email notifications and other app notifications should you be invited by other users to make introduction to this profile (or any profile you add).
          </p>
            ) : (
              <p className="desc"><span>Important note:</span> You will need to click "connect" from the list to allow us to match you with this profile. You may then receive email notifications and other app notifications should this profile show interest.</p>
            )}
          </div>

          <div className="importBtn">
            <button
              onClick={handleclose}
              type="submit"
              className="customFilterButton"
            >
              GOT IT
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
