import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../../components/ContactUs/ContactUs";

export default function DeletePayment({
  removeModalShow,
  removeModalClose,
  removeModalCloseConfirm,
  actionData
}) {
  return (
    <>
      <Modal
        show={removeModalShow}
        onHide={removeModalClose}
        className="delete-information-width"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={removeModalClose}
          >
          </button>
        </div>
        <div className="modal-body">
          <h5 className="modal-title" id="exampleModalLabel">
            Are you sure you want to cancel this subscription?
          </h5>
          <div className="row">
            <div className="col-lg-5">
              <p className="col-form-label shreholder-text ">Subscription Plan</p>
              {actionData && actionData.length > 0 && (
                <span className="col-form-label company-text">Exempt plan paid {actionData[0].interval === 'month' ? "monthly" : actionData[0].interval === 'year' ? "annually" : "daily"}</span>
              )}
            </div>
          </div>

          <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              By proceeding, we will notify you directly and advise you accordingly on the next steps to formalise the changes.
            </span>
          </p>

          <div className="modal-footer modal-bottom-action">
            <ContactUs handleClose={removeModalClose} />
            <button
              onClick={removeModalCloseConfirm}
              type="button"
              className="btn agree-button"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
