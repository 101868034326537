import axionIns from "../axios";

const getCapital = async (payload) => {
  return axionIns.get("capital/"+payload).then((response) => {return response.data});
};

const addCapital = async (payload) => {
  return axionIns.post("capital", JSON.stringify(payload)).then((response) => {return response.data});
};

const exportObject = {
  getCapital,
  addCapital,
};
export default exportObject;
