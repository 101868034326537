import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { getCapitalList } from "../../actions/capital";
import PaymentConfirmation from "./PaymentConfirmation";
import { useRef } from "react";
import { useState } from "react";
import {
  getStripeSubscriptionAction,
  paymentModalAction,
} from "../../actions/stripe";

import PaidUpCapital from "./PaidUpCapital";
import PlanActivity from "./PlanActivity";
import { Form } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { Controller, useForm } from "react-hook-form";
import PaymentTransaction from "./PaymentTransaction";
import {
  CAPITAL,
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
  SUBSCRIPTION_MODULE_TYPE,
} from "../../constants";
import Currency from "../../components/currency/currency";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import Select from "react-select";
import ContactUs from "../../components/ContactUs/ContactUs";
import PaidUpCapitalSegment from "./Segment/PaidUpCapitalSegment";
import ProductPlanSegment from "./Segment/ProductPlanSegment";
import ActivityLogSegment from "./Segment/ActivityLogSegment";
import TransactionSegment from "./Segment/TransactionSegment";
import ProductsOld from "./ProductsOld";

export default function Payment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reset } = useForm();
  const location = useLocation();
  const tabDatafetch = location.state?.formData;
  const tabDatafetchforceUpdate = location.state?.forceUpdate;
  const [filterType, setFilterType] = useState({
    value: "-1",
    label: "Latest",
  });
  const queryParams = new URLSearchParams(window.location.search);
  const {
    paidUpCapital,
    myInfopaidUpCapital,
    paidUpCapitalIsApproved,
    paidUpCapitalIsEditApproved,
    amendmentFlag,
  } = useSelector((state) => state.capital);
  const {
    selectedCompany,
    isDefaultCompany,
    loadCompanyActivity,
    hasPermission,
  } = useSelector((state) => state.company);
  const {
    selectedPlanProduct,
    transactionsSegmentRecord,
    selectedPlanProductCurrentPage,
  } = useSelector((state) => state.subscriptionPlanProducts);

  const [capModalShow, setCapModalShow] = useState(false);

  const capModalClose = () => {
    setCapModalShow(false);
    reset();
  };
  const handleCapShow = () => {
    setCapModalShow(true);
    reset();
  };

  const [paymentType, setPaymentSuccess] = useState(false);
  const [paymentConfirmationModalShow, SetPaymentConfirmationModalShow] =
    useState(false);
  const loadRef = useRef(true);

  const [activeSegment, setActiveSegment] = useState(
    tabDatafetchforceUpdate
      ? tabDatafetch
      : selectedPlanProductCurrentPage &&
        selectedPlanProductCurrentPage?.activeSegment
        ? selectedPlanProductCurrentPage?.activeSegment
        : tabDatafetch
          ? tabDatafetch
          : 0
  );

  useEffect(() => {
    const { id } = selectedCompany;
    if (id && activeSegment === 1) {
      dispatch(getCapitalList(id));
    }
  }, [dispatch, selectedCompany, activeSegment]);

  useEffect(() => {
    if (loadRef.current) {
      const type = queryParams.get("type");
      if (type === "success") {
        setPaymentSuccess(true);
        SetPaymentConfirmationModalShow(true);

        setTimeout(() => {
          const { id } = selectedCompany;
          if (id) {
            dispatch(getStripeSubscriptionAction(id));
          }
        }, 1000);
      } else if (type === "failed") {
        setPaymentSuccess(false);
        SetPaymentConfirmationModalShow(true);
      }
    }
    return () => {
      loadRef.current = false;
    };
  }, [dispatch, selectedCompany]);

  const handleSegment = (value) => {
    setActiveSegment(value);
  };

  const segmentArray = [
    // { name: "Products", id: 0 },
    { name: "Transactions", id: 0 },
    { name: "Activity log", id: 2 },
  ];

  if (!isDefaultCompany) {
    segmentArray.splice(1, 0, { name: "Paid-up capital", id: 1 });
  }

  const handlePlanPopupShow = (flag) => {
    if (selectedCompany?.isBuyEnabled) {
      dispatch(paymentModalAction(flag, selectedPlanProduct?.planType?.value));
    }
  };

  const paidUpCapitalShow = () => {
    navigate(RoutesPaths.SHARECAPITAL, { state: { formData: 1 } });
  };

  const selectActivity = [
    { value: "-1", label: "Latest" },
    { value: "1", label: "Oldest" },
  ];

  const paymentConfirmationModalCloseButton = () => {
    SetPaymentConfirmationModalShow(false);
    handleSegment(0);
  };

  return (
    <>
      <Header title="Payments" subtitle="Payments" />

      <div className="container-fluid minHeightVH py-4 paymentPage paddingBottomMD">
        {/* <div className="row">
          <div className="col-md-8">
            <div className="paymentHeader">
              <div className="segmentHolder">
                {segmentArray?.map((item, index) => (
                  <div
                    onClick={() => handleSegment(item.id)}
                    key={index}
                    className={`segment ${item.id === activeSegment ? "active" : ""
                      }`}
                  >
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>

              {activeSegment === 1 && selectedCompany?.id && (
                <>
                  {hasPermission && !isDefaultCompany && (
                    <button className="addCapital" onClick={handleCapShow}>
                      <img src="assets/img/addCapital.svg" alt="" />
                      Add Paid Capital
                    </button>
                  )}
                </>
              )}
              {activeSegment === 3 &&
                (!loadCompanyActivity ? (
                  <div className="filter-button">
                    <Form.Select
                      className="select-border"
                      placeholder="10"
                      value={filterType}
                      onChange={(event) => setFilterType(event.target.value)}
                    >
                      <option value={-1}>Latest</option>
                      <option value={1}>Oldest</option>
                    </Form.Select>
                  </div>
                ) : (
                  <div>
                    <ContentLoader width="100%" height={15}>
                      <rect rx="5" ry="5" width="100%" height="15" />
                    </ContentLoader>
                  </div>
                ))}
            </div>
          </div>
        </div> */}
        <div className="row mobilePaymentRow">
          <div className="col-md-8 order-md-1 order-1">
            <div className="paymentHeader">
              <div className="segmentHolder">
                {segmentArray?.map((item, index) => (
                  <div
                    onClick={() => handleSegment(item.id)}
                    key={index}
                    className={`segment ${item.id === activeSegment ? "active" : ""
                      }`}>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>

              {activeSegment === 1 && selectedCompany?.id && (
                <>
                  {hasPermission && !isDefaultCompany && (
                    // onClick={handleCapShow}/
                    <button className="addCapital" onClick={paidUpCapitalShow}>
                      <img src="assets/img/addCapital.svg" alt="" />
                      Add paid-up capital
                    </button>
                  )}
                </>
              )}
              {activeSegment === 2 && (
                <div className="filter-button">
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    value={filterType}
                    onChange={(event) => setFilterType(event)}
                    options={selectActivity}></Select>
                </div>
              )}
            </div>
            {/* activeSegment === 0 ? (
              <div className="tableHolder staticTable">
                <ProductsOld handlePlanPopupShowTrigger={handlePlanPopupShow} activeSegment={activeSegment} />
              </div>
            ) :  */}
            {activeSegment === 0 ? (
              <PaymentTransaction />
            ) : activeSegment === 1 ? (
              !isDefaultCompany && (
                <PaidUpCapital
                  company={selectedCompany}
                  capitalType={CAPITAL.paidUp}
                  capital={paidUpCapital}
                  myInfoCapital={myInfopaidUpCapital}
                  capitalIsApproved={paidUpCapitalIsApproved}
                  capitalIsEditApproved={paidUpCapitalIsEditApproved}
                  title="Paid-up capital"
                  subtitle="Paid-up capital amount"
                  capModalShow={capModalShow}
                  capModalClose={capModalClose}
                  handlePlanPopupShowTrigger={handlePlanPopupShow}
                  amendmentFlag={amendmentFlag}
                  activeSegment={activeSegment}
                />
              )
            ) : activeSegment === 2 ? (
              <div className="tableHolder activityPlan">
                <PlanActivity
                  filterType={filterType}
                  setFilterType={setFilterType}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-4 customSide order-md-2 order-2">
            {activeSegment === 0 ? (
              <TransactionSegment
                activeSegment={activeSegment}
                transactionsSegmentRecord={transactionsSegmentRecord}
                selectedCompany={selectedCompany}
                handlePlanPopupShow={handlePlanPopupShow}
              />
            ) : activeSegment === 1 ? (
              <ProductPlanSegment
                activeSegment={activeSegment}
                selectedPlanProduct={selectedPlanProduct}
                selectedCompany={selectedCompany}
                handlePlanPopupShow={handlePlanPopupShow}
              />
            ) : activeSegment === 2 ? (
              <ActivityLogSegment selectedCompany={selectedCompany} />
            ) : (
              ""
            )}
            {/* {(activeSegment === 0) && Object.keys(selectedPlanProduct).length > 0 && (
              <div className="suggestedRightSection" style={{ backgroundImage: "url(assets/img/planBack.jpg)" }}>
                <div className="planHolder">
                  {(selectedCompany?.subscriptionPlan && Object.keys(selectedCompany?.subscriptionPlan).length > 0 && selectedCompany?.subscriptionPlan?._id === selectedPlanProduct?.id) && (
                    <span className="suggestedTextPopup">SUGGESTED {selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? "PLAN" : "PRODUCT"}</span>
                  )}
                  <div className="planName">
                    <h4>{selectedPlanProduct?.name}</h4>
                    <p>
                      {selectedPlanProduct?.targetAudience}
                    </p>
                    <div className="planDetail">
                      <div className="detailInner">
                        {selectedPlanProduct?.planType.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? (
                          <>
                            <img src="assets/img/IconShield.svg" alt="planIcon" />
                            <p>Payment plan</p>
                          </>
                        ) : (
                          <>
                            <img src="assets/img/IconProduct.svg" alt="productIcon" />
                            <p>One-time purchase</p>
                          </>
                        )}
                      </div>
                      <div className="planPrice">
                        <p className="salePrice"><Currency currency={selectedPlanProduct?.currency?.value} /> <CurrencyFormat value={parseFloat(selectedPlanProduct?.price)} /></p>
                      </div>
                    </div>
                    <div className="upgradePlan">
                      <div className="row">
                        <div className="col-md-6">
                          <ContactUs theme="sidePaymentBanner"/>
                        </div>
                        <div className="col-md-6">
                          <button onClick={handlePlanPopupShow} className="planUpgrade">{selectedPlanProduct?.planType?.value === SUBSCRIPTION_MODULE_TYPE.PLAN ? "Upgrade Plan" : "Buy Product"}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>

      <PaymentConfirmation
        paymentType={paymentType}
        paymentConfirmationModalShow={paymentConfirmationModalShow}
        paymentConfirmationModalClose={() =>
          SetPaymentConfirmationModalShow(false)
        }
        paymentConfirmationModalCloseButton={
          paymentConfirmationModalCloseButton
        }
      />
    </>
  );
}
