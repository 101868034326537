import { CURRENCY_LOCALE_STRING } from "../../constants";

export default function CurrencyFormat({ value, decimal, currency }) {
  const currencyFormat = currency ? CURRENCY_LOCALE_STRING[currency] : CURRENCY_LOCALE_STRING.usd
  if (value && typeof value === 'number') {
    var payload = {
      minimumFractionDigits: (decimal && decimal > 0) ? decimal : 0,
      maximumFractionDigits: (decimal && decimal > 0) ? decimal : 0,
    }
    const formattedValue = value.toLocaleString(currencyFormat, payload);
    return formattedValue === '-0.00' ? 0.00 : formattedValue;
  } else {
    return 0;
  }
}
