import {
  SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE,
  GET_COMPANY_DASHBOARD,
  SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING,
  SET_COMPANY_DASHBOARD_BULLION_RECORD,
  SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING,
  SET_COMPANY_DASHBOARD_METAL_ALLOCATION,
  SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING,
  SET_COMPANY_DASHBOARD_PROFIT_LOSS,
  COMPANY_DASHBOARD_LOADING_SKELETON
} from "./types";
import stripeService from "../services/stripe.service";
import { PRODUCT_GOLD_TYPE_NAME_INITIAL, SUPPORTED_PAYMENT_METHOD } from "../constants";
import moment from "moment";
import compnayDashboardService from "../services/compnayDashboard.service";

export const getCompanyDashboardAction = (payload) => async (dispatch) => {
  dispatch({ type: COMPANY_DASHBOARD_LOADING_SKELETON, payload: true });
  return compnayDashboardService.getCompanyDashboard(payload).then(async (resp) => {
    if (resp.status) {
      // console.log(resp.data)
      dispatch({ type: GET_COMPANY_DASHBOARD, payload: resp.status ? resp.data : {} });
      dispatch(companyDashboardBullionBalanceUpdate(resp.data?.bullionRecord))
      dispatch(companyDashboardMetalAllocationUpdate(resp.data?.metalAllocation))
    }
  }
  );
};

export const companyDashboardBullionBalanceUpdate = (payloadResp) => async (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING, payload: true })
  // Bullion Reecord 
  if (payloadResp?.length > 0) {
    const promises = payloadResp?.map(item =>
      stripeService.currencyConverter(SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER, { from: item.currency, to: getState()?.user?.bidGoldHeaderCurrency?.value, amount: parseFloat(item.balance) })
    );
    const details = await Promise.all(promises);

    const updatedData = payloadResp?.map((item, index) => ({
      ...item,
      convertionCurrency: getState()?.user?.bidGoldHeaderCurrency?.value,
      convertionBalance: details[index]?.data // Update balance with the fetched rate
    }));
    dispatch({ type: SET_COMPANY_DASHBOARD_BULLION_RECORD, payload: updatedData || [] });
  } else {
    dispatch({ type: SET_COMPANY_DASHBOARD_BULLION_RECORD, payload: [] });
  }
  // Bullion Reecord  
};

export const companyDashboardMetalAllocationUpdate = (payloadResp) => async (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING, payload: true })
  if (payloadResp?.length > 0) {
    // Metal Allocation 
    const promises = payloadResp?.map(item =>
      stripeService.currencyConverter(SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER, { from: item.currency, to: getState()?.user?.bidGoldHeaderCurrency?.value, amount: parseFloat(item.allocation) })
    );
    const details = await Promise.all(promises);

    const updatedData = payloadResp?.map((item, index) => ({
      ...item,
      convertionCurrency: getState()?.user?.bidGoldHeaderCurrency?.value,
      convertionBalance: details[index]?.data // Update balance with the fetched rate
    }));
    dispatch({ type: SET_COMPANY_DASHBOARD_METAL_ALLOCATION, payload: updatedData || [] });
    // Metal Allocation

    dispatch(companyDashboardProfitLossUpdate(updatedData))
  } else {
    dispatch({ type: SET_COMPANY_DASHBOARD_METAL_ALLOCATION, payload: [] });
    dispatch(companyDashboardProfitLossUpdate([]))
  }
};

export const companyDashboardProfitLossUpdate = (payloadResp) => async (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING, payload: true })

  let profitAndLossGold = 0;
  if (payloadResp?.length > 0) {
    payloadResp?.forEach(element => {
      switch (element.type) {
        case PRODUCT_GOLD_TYPE_NAME_INITIAL.KILOGRAM:
          const totalKg = parseInt(element?.quantity) * element?.convertionBalance;
          const kgGoldPrice = getState()?.user?.bidGoldHeader?.gold1kg?.price || 0;
          const totalKgGoldPrice = kgGoldPrice * parseInt(element?.quantity);
          const calcKgPrice = totalKg - totalKgGoldPrice;
          profitAndLossGold = profitAndLossGold - calcKgPrice;
          break;
        case PRODUCT_GOLD_TYPE_NAME_INITIAL.GRAM:
          const totalG = parseInt(element?.quantity) * element?.convertionBalance;
          const gGoldPrice = getState()?.user?.bidGoldHeader?.gold1g?.price || 0;
          const totalgGoldPrice = gGoldPrice * parseInt(element?.quantity);
          const calcgPrice = totalG - totalgGoldPrice;
          profitAndLossGold = profitAndLossGold + calcgPrice;

          break;
        case PRODUCT_GOLD_TYPE_NAME_INITIAL.HEDGEKILOGRAM:
          const totalhKg = parseInt(element?.quantity) * element?.convertionBalance;
          const hkgGoldPrice = getState()?.user?.bidGoldHeader?.gold1kg?.price * 0.01 || 0;
          const totalhKgGoldPrice = hkgGoldPrice * parseInt(element?.quantity);
          const calchKgPrice = totalhKg - totalhKgGoldPrice;
          profitAndLossGold = profitAndLossGold - calchKgPrice;

          break;
        default:
          break;
      }

    });
  }

  const updatedData = {
    asOf: payloadResp?.length > 0 ? moment().format("DD/MM/YYYY") : "-",
    convertionCurrency: payloadResp?.length > 0 ? getState()?.user?.bidGoldHeaderCurrency?.value.toUpperCase() : "-",
    ytd: payloadResp?.length > 0 ? profitAndLossGold : "-",
    allocation: payloadResp?.length > 0 ? "Gold" : "-"
  }

  dispatch({ type: SET_COMPANY_DASHBOARD_PROFIT_LOSS, payload: updatedData || {} });
};

export const updateCompanyDashboardAction = (id, payload) => async (dispatch) => {
  return compnayDashboardService.updateCompanyDashboard(id, payload).then(
    (resp) => {
      dispatch({ type: resp.status ? SET_SUCCESS_MESSAGE : SET_FAILED_MESSAGE, payload: resp.data })
      dispatch(getCompanyDashboardAction(id));
    }
  );
};
