import React from "react";
import Header from "../../components/header/header";
import EditProfile from "../../components/EditProfile/EditProfile";

export default function AccountSetting() {
  return (
    <>
      <Header title="profile" subtitle="Edit Profile" />
      <div className="container-fluid inner-container-fluid py-4 paddingBottomMD">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <h5 className="main-page-title font-weight-bolder mb-2">Edit profile</h5>
            <p className="edit-description-adv">
              The information on this page will not appear in any public domain but is used for our due diligence and account verification purposes. 
              {/* <a rel="noreferrer" href={privacy} target="_blank"> Learn more</a> about our due diligence process. */}
            </p>
          </div>

          <div className="col-lg-12 col-sm-12">
            <EditProfile />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <Footer
              linkObj={{
                title: "Edit Public Profile",
                link: "/profile",
                title2: "Password and Security Settings",
                link2: "/security",
              }}
            />
          </div>
        </div> */}
      </div>
      {/* <SaveModal
        saveModalShow={saveModalShow}
        saveModalClose={saveModalClose}
      /> */}
    </>
  );
}
