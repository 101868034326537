import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addShareAction } from "../../actions/shareHolder";
import { addOfficerAction } from "../../actions/officer";
import { HOLDERS } from "../../constants";
export default function AddEmail({ emailShow, emailClose, holdertype }) {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state) => state.company);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("share_email", emailShow?.preInfoEmail);
  }, [emailShow]);

  const onSubmit = (data) => {
    const { id } = selectedCompany;
    if (id) {
      let payload = {};
      if (holdertype === "shareHolder") {
        payload = {
          company: id,
          type: HOLDERS.shareHolders,
          shareHolderId: emailShow?.id,
          share_email: data?.share_email,
          name: emailShow?.name,
          isAppointed: true,
          isAppointedSendInvite: true
        };
        dispatch(addShareAction(payload));
      } else {
        payload = {
          company: id,
          type: HOLDERS.directors,
          officerId: emailShow?.id,
          share_email: data?.share_email,
          name: emailShow?.name,
          isAppointed: true,
          isAppointedSendInvite: true
        };
        dispatch(addOfficerAction(payload));
      }
      emailClose();
      reset();
    }
  };
  return (
    <Modal
      show={emailShow}
      onHide={emailClose}
      className="custom-modal AddEmailModal ps-0"
      id="addModalMessage"
    >
      <div className="modal-header p-0">
        <button
          type="button"
          className="btn-close text-dark close-button"
          onClick={emailClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row right-side-row">
          <div className="col-lg-12">
            <h5 className="edit-title">Send an email to {emailShow?.name}</h5>
          </div>
          <div className="col-lg-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Enter Email</p>
                    <input
                      maxLength={50}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Email address"
                      {...register("share_email", {
                        required: true,
                        pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                      })}
                    />
                    {errors.share_email?.type === "required" && (
                      <span className="errorMessage">Email is required</span>
                    )}
                    {errors.share_email?.type === "pattern" && (
                      <span className="errorMessage">Email is Invalid</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer modal-bottom-action">
                <ContactUs handleClose={emailClose} />
                <button type="submit" className="btn agree-button">
                  Send Invite
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
