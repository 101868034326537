import axionIns from "../axios";

const getCompanyDashboard = async (id) => {
  return axionIns.get(`businessdashboard/${id}`).then((response) => { return response.data });
};


const updateCompanyDashboard = async (id, payload) => {
  return axionIns.put(`businessdashboard/${id}`, JSON.stringify(payload)).then((response) => { return response.data });
};

export default {
  getCompanyDashboard,
  updateCompanyDashboard
};
