import toast from "react-hot-toast";
import { SET_ALL_TEMPLATE_FILES, SET_ALL_DOCUMENT_BY_CHANNEL, SET_SIGNNOW_DOCUMENT_TEMPORARY, SET_SIGNNOW_DOCUMENT_SIGNER, REMOVE_SIGNNOW_DOCUMENT_SIGNER, RESET_SIGNNOW_DOCUMENT, SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL, SET_SIGNATURE_LIST_SIGNERS, SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE, SET_EMBED_ESIGN_POPUP_FLOW_LINK, SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID } from "../actions/types";

const initialState = {
  templateFileList: [],
  currentSignnowRoomId: null,
  currentSignnowParticipants: [],
  currentSignnowSigner: [],
  templateType: null,
  manualUpload: null,
  manualReponseUploadFileName: null,
  predefinedTemplates: null,
  currentDocumentListByChannelId: [],
  currentSignatureListByChannelId: [],
  signerListByRequestId: [],

  embedShowPopup: null,
  embedLinkUrl: null,
  esignDocumentId: null
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SIGNNOW_DOCUMENT_TEMPORARY:
      return {
        ...state,
        templateType: payload.templateType,
        manualUpload: payload.manualUpload,
        manualReponseUploadFileName: payload.manualReponseUploadFileName,
        predefinedTemplates: payload.defaultFileTemplate,
        currentSignnowRoomId: payload.roomId,
        currentSignnowParticipants: payload.participants,
      };


    case SET_SIGNNOW_DOCUMENT_SIGNER:
      let currentSignnowParticipants = state.currentSignnowParticipants
      let currentSignnowSigner = state.currentSignnowSigner
      if (currentSignnowSigner.length < 2 && currentSignnowSigner.filter(x => x.identity === payload.identity).length === 0) {
        currentSignnowSigner = [...currentSignnowSigner, payload]
        const indexValue = currentSignnowParticipants.findIndex(x => x.identity === payload.identity)
        if (indexValue > -1) {
          currentSignnowParticipants[indexValue].isDisabled = true
        }
      } else {
        toast.error("Only 2 signers are allowed.")
      }

      return {
        ...state,
        currentSignnowSigner: currentSignnowSigner,
        currentSignnowParticipants: currentSignnowParticipants
      }

    case REMOVE_SIGNNOW_DOCUMENT_SIGNER:
      const index = payload;
      let currentYouSignParticipantsRemove = state.currentSignnowParticipants
      let currentYouSignSignerRemove = state.currentSignnowSigner

      if (currentYouSignSignerRemove.length > 0) {
        const currentIdentity = currentYouSignSignerRemove[index].identity;
        currentYouSignSignerRemove.splice(index, 1);

        const indexValue = currentYouSignParticipantsRemove.findIndex(x => x.identity === currentIdentity)
        if (indexValue > -1) {
          currentYouSignParticipantsRemove[indexValue].isDisabled = false
        }

      }
      return {
        ...state,
        currentSignnowSigner: currentYouSignSignerRemove,
        currentSignnowParticipants: currentYouSignParticipantsRemove
      }

    case RESET_SIGNNOW_DOCUMENT:
      return {
        ...state,
        currentSignnowSigner: []
      }

    case SET_ALL_DOCUMENT_BY_CHANNEL:
      return {
        ...state,
        currentDocumentListByChannelId: payload
      }

    case SET_SIGNATURE_LIST_SIGNERS:
      return {
        ...state,
        signerListByRequestId: payload
      }

    case SET_ALL_SIGNATURE_REQUEST_BY_CHANNEL:
      return {
        ...state,
        currentSignatureListByChannelId: payload
      }

    case SET_ALL_TEMPLATE_FILES:
      return {
        ...state,
        templateFileList: payload
      }

    case SET_EMBED_ESIGN_POPUP_FLOW_TOGGLE:
      return {
        ...state,
        embedShowPopup: payload
      }

    case SET_EMBED_ESIGN_POPUP_FLOW_LINK:
      return {
        ...state,
        embedLinkUrl: payload
      }

    case SET_EMBED_ESIGN_POPUP_FLOW_EDOCUMENT_ID:
      return {
        ...state,
        esignDocumentId: payload
      }



    default:
      return state;
  }
}
