import {
  USER_PROFILE,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  GET_USER_ACTIVITY,
  GET_BID_GOLD_PRICE,
  STOP_LOADER,
} from "./types";
import userService from "../services/user.service";
import sendOtpService from "../services/otp.service";
import { logout } from "./auth";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";
import { bidGoldHeaderCurrencyAction, saveManualDetails, setOverrideLockPrice } from "./stripe";
import { SUPPORTED_PAYMENT_METHOD } from "../constants";

export const updatePasswordAction = (payload) => (dispatch) => {
  return userService.updatePassword(payload).then(
    (resp) => {
      if (resp.status) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      return Promise.resolve();
    }
  );
};


export const getProfileAction = () => (dispatch) => {
  return userService.getProfile().then(
    (resp) => {
      if (resp.status) {
        dispatch({ type: USER_PROFILE, payload: resp.data });
      } else {
        dispatch(logout());
        dispatch({ type: USER_PROFILE, payload: [] });
      }
      // dispatch({ type: STOP_LOADER })
      return Promise.resolve();
    }
  );
};

export const updateUserTwoFactorAction = (payload) => (dispatch) => {
  return userService.updateProfile(payload).then(
    (resp) => {
      if (resp.status) {
        dispatch(getProfileAction());
        var message = "2-factor Authentication ";
        if (payload.twoFactor) {
          message = message + 'Enabled.'
        } else {
          message = message + 'Disabled.'
        }
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: message });
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      return Promise.resolve();
    }
  );
};

export const updateUserProfileAction = (payload) => (dispatch) => {
  return userService.updateProfile(payload).then(
    (resp) => {
      if (resp.status) {
        dispatch(getProfileAction());
        // dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      return Promise.resolve();
    }
  );
};


export const getUserActivityAction = (page, filterType) => (dispatch) => {
  dispatch(startLoaderAction())
  return userService.getActivity(page, filterType).then(
    (resp) => {
      if (resp.status) {
        dispatch({ type: GET_USER_ACTIVITY, payload: resp });
      } else {
        dispatch({ type: GET_USER_ACTIVITY, payload: null });
      }
      dispatch(stopLoaderAction())
      return Promise.resolve();
    }
  );
};


export const sendOtpAuthentication = (payload, token = null) => (dispatch) => {
  dispatch(startLoaderAction());
  return sendOtpService.sendOtp(payload, token).then(
    (resp) => {
      if (resp.status) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      dispatch(stopLoaderAction());
      return Promise.resolve();
    }
  );
}

export const verifOtpAction = (payload, token = null) => (dispatch) => {
  dispatch(startLoaderAction())
  return sendOtpService.verifyOTP(payload, token).then(
    (resp) => {
      if (resp.status) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        if(localStorage.getItem('twoFactorToken')){
          localStorage.removeItem('twoFactorToken')
        }  
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      dispatch(stopLoaderAction())
      return resp.status;
    }
  );
}

export const setJoyRideCompleteAction = (type) => async (dispatch) => {
  await userService.userExperinceUpdate({ "data": type })
}

export const userBidGoldPriceAction = () => async (dispatch, getState) => {
  return userService.bidGoldPrice().then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_BID_GOLD_PRICE, payload: resp?.data?.goldBidMapping || {}, lockGoldPrice: resp?.data });
      if (getState()?.user?.bidGoldHeaderCurrency) {
        let payloadSend = {}
        for (const property in resp?.data?.goldBidMapping) {
          payloadSend[property] = parseFloat(resp?.data?.goldBidMapping[property].price)
        }
        dispatch(bidGoldHeaderCurrencyAction(getState()?.user?.bidGoldHeaderCurrency, payloadSend))
      }
    } else {
      dispatch({ type: GET_BID_GOLD_PRICE, payload: {} });
    }
  }
  );
}

export const userBidGoldPriceLockAction = (paymentMethodSelection, payload) => async (dispatch, getState) => {
  if(paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER){
    let currencyDetails = { currency: getState()?.user?.bidGoldHeaderCurrency };
    let paymentSavedinfo = getState()?.user?.user?.bankInfo ?? null
    if (paymentSavedinfo) {
      currencyDetails = { ...currencyDetails, ...paymentSavedinfo }
    }
    if(currencyDetails){
      dispatch(saveManualDetails(currencyDetails))
    }
  }
  
  return userService.bidGoldPriceLock(payload).then((resp) => {
    dispatch(setOverrideLockPrice(resp?.lockGoldPrice[payload?.type]?.price))
    return Promise.resolve();
  });
}