import bannerService from "../services/banner.service";
import { GET_BANNER_LIST } from "./types";

export const getBannerAction = () => async (dispatch) => {
  return await bannerService.getBanner().then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_BANNER_LIST, payload: resp.data });
    } else {
      dispatch({ type: GET_BANNER_LIST, payload: [] });
    }
  });
};
