import axionIns from "../axios";

const updatePassword = async (payload) => {
  return axionIns.post("user/update-password", JSON.stringify(payload)).then((response) => { return response.data });
};

const getProfile = async () => {
  return axionIns.get("user/me").then((response) => { return response.data });
};

const updateProfile = async (payload) => {
  return axionIns.put("user", JSON.stringify(payload)).then((response) => { return response.data });
};

const getActivity = async (page, filterType) => {
  return axionIns.get("user/activity/?page=" + page + "&filter=" + filterType).then((response) => { return response.data });
};

const userExperinceUpdate = async (payload) => {
  return axionIns.put("user/user-experience", JSON.stringify(payload)).then((response) => { return response.data });
};

const bidGoldPrice = async () => {
  return axionIns.get("user/bid-gold-price").then((response) => { return response.data });
};

const bidGoldPriceLock = async (payload) => {
  return axionIns.put("user/bid-gold-price-lock", JSON.stringify(payload)).then((response) => { return response.data });
};

export default {
  updatePassword,
  getProfile,
  updateProfile,
  getActivity,
  userExperinceUpdate,
  bidGoldPrice,
  bidGoldPriceLock
};
