import filesService from "../services/files.service";
import { GET_FILES, LOAD_FILES_SKELETON, RESET_FILES_TRIGGER, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getAllFiles = (payload, page, limit) => async (dispatch) => {
  dispatch({ type: LOAD_FILES_SKELETON, payload: true });
  // dispatch(startLoaderAction())
  return filesService.getFiles(payload, page, limit).then((resp) => {
    // dispatch(stopLoaderAction())
    if (resp.status) {
      dispatch({ type: GET_FILES, payload: resp.data, count: resp.status ? resp?.count : 0 });
    } else {
      dispatch({ type: GET_FILES, payload: [], count: 0 });
    }
  });
};

export const addFilesAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  dispatch({ type: RESET_FILES_TRIGGER })
  return filesService.addFiles(payload).then((resp) => {
    dispatch(stopLoaderAction());
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
      if (payload.get('company')) {
        dispatch(getAllFiles(payload.get('company')));
      }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong, please try again later." });
    }
  });
};

// new
// single File upload action
export const singleFileUploadAction = (payload) => (dispatch) => {
  return filesService.uploadFile(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: "File Uploaded Successfully", });
      return resp.filename
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong, please try again later." });
      return null;
    }
  });
};


export const uploadMultipleFileAction = (payload) => {
  return filesService.uploadMultipleFiles(payload).then((resp) => {
    if (resp.status) {
      return resp.filename
    } else {
      return null;
    }
  });
};