import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router";

export default function PayWithCryptoPopup({ embedShowPopup, modalClose, url }) {
    return (
        <>
            <div className="modal-body">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
                        <iframe src={url} width="100%" height="650"></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}

