import React, { useEffect, useState } from "react";
import { getChatUserImage, getChatUserName } from "./userList";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CUSTOM_SELECT_STYLES, ESIGN_TEMPLATE_TYPE, SELECT_BOX_THEME, backendUrl } from "../../constants";
import { Accordion } from "react-bootstrap";
import { downloadMedia, getName } from "./userMessages";
import { toast } from "react-hot-toast";
import ManagePeople from "../../components/message/ManagePeople";
import { changeChannelNameAction, chatMediaGetAction } from "../../actions/chat";
import { Controller, useForm, useWatch } from "react-hook-form";
import DropZoneTemplate from "../files/DropZoneTemplate";
import Select from "react-select";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { singleFileUploadAction } from "../../actions/files";
import { setSignNowDocumentTemporaryAction, getAllDocumentByChannelIdAction, getDownloadDocumentAction, getTemplateListAction, setEmbedEsignPopupFlowToggle, setEmbedEsignPopupFlowLink, setEmbedEsignPopupFlowEdocumentId } from "../../actions/signnow";
import ESignPopup from "./ESignPopup";
import { selectCompanyDashboard } from "../../actions/company";
import ContentLoader from "react-content-loader";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import ChatImagePreviewer from "../../components/ChatImagePreviewer/ChatImagePreviewer";


export const noData = (data) => {
  return (
    <div className="commingSoon">
      <div>
        <img src="assets/img/commingSoon.svg" alt="" />
        <p>{data}</p>
      </div>
    </div>
  )
}

export default function UserProfile({ setShowProfile, conversation, client }) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    watch,
  } = useForm(
    {
      defaultValues: {
        templateType: ESIGN_TEMPLATE_TYPE.TEMPLATE.toString()
      }
    }
  );


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { isChatLoading, chatMedia, chatMediaFileName, chatMediaDocs, loadingChatMediaFile } = useSelector((state) => state.chat);
  const { templateFileList, currentDocumentListByChannelId, embedShowPopup, embedLinkUrl, esignDocumentId } = useSelector((state) => state.signNow);
  const { company } = useSelector((state) => state.company);
  const [editConvoTrigger, setEditConvoTrigger] = useState(false);
  const [editConvo, setEditConvo] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [documentFileViewAll, setDocumentFileViewAll] = useState(false);
  const [previewFileTabChange, setPreviewFileTabChange] = useState(null);
  const documentFileLimit = 3;


  const sid = useSelector((state) => state.sid);
  const participants =
    useSelector((state) => state.participants)[sid] ?? [];
  // const messages = useSelector((state) => state.messages[sid] ?? []);
  // const conversationMedia = messages.filter(x => x.attachedMedia.length > 0)

  const [uploadedTemplateFile, setUploadedTemplateFile] = useState([]);

  // useWatch to avoid unnecessary re-renders
  const templateType = useWatch({ control, name: 'templateType' });


  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (embedShowPopup && event?.source?.location?.href === `${window.location.origin}${RoutesPaths.SUCCESS_SIGNNOW}?document_id=${esignDocumentId}`) {
        setTimeout(() => {
          dispatch(setEmbedEsignPopupFlowToggle(false))
          dispatch(setEmbedEsignPopupFlowLink(null))
          dispatch(setEmbedEsignPopupFlowEdocumentId(null))
        }, 5000);
      }
    });
  }, [esignDocumentId, embedShowPopup])

  useEffect(() => {
    const changedChannelTrigger = async () => {
      if (sid) {
        dispatch(getTemplateListAction())
        dispatch(getAllDocumentByChannelIdAction(`${sid}`))
        dispatch(chatMediaGetAction(sid))
        setDocumentFileViewAll(false)
      }
    }
    changedChannelTrigger()
  }, [sid, dispatch])

  var sidebarList = [
    {
      id: 1,
      img: "assets/img/Sign.svg",
      name: "Sign document",
    },
    {
      id: 2,
      img: "assets/img/addUser.svg",
      name: "Chat members",
    },
    {
      id: 3,
      img: "assets/img/paymentIcon.svg",
      name: "Payments",
    },
    {
      id: 4,
      img: "assets/img/viewFile.svg",
      name: "View files",
    },
    {
      id: 5,
      img: "assets/img/addNewTask.svg",
      name: "Add new task",
    },
  ];

  // if (conversation?.attributes?.isAdmin) {
  //   sidebarList = sidebarList.slice(1);
  // }

  const showFilteredAddPeople = (item, index) => {
    return (
      <div
        key={index}
        className="col-lg-12 inner-section"
      >
        <div
          className="profile-img"
          style={{
            backgroundImage: `url(${item &&
              item.attributes.image
              ? backendUrl + item.attributes.image
              : "assets/img/Frame1.svg"
              })`,
          }}
        ></div>
        <div className="profile-name">
          {item.attributes.name || item.identity}
        </div>
      </div>
    )
  }


  const handleUpdateConvoName = async () => {
    if (editConvo.length > 3) {
      await dispatch(changeChannelNameAction({ channelSid: sid, friendlyName: editConvo }))
      setEditConvo("")
      setEditConvoTrigger(false)
    } else {
      toast.error("Group name should be more than 3 characters")
    }
  }

  const uploadTemplateFiles = (event) => {
    const files = event;
    if (files.length === 1) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      dispatch(singleFileUploadAction(formData)).then((resp) => {
        if (resp) {
          setValue("manualReponseUploadFileName", resp?.filename);
        }
      });
      setUploadedTemplateFile(files)
      setValue("manualUpload", files)
    }
  }

  const onSubmitTemplate = async (values) => {
    await dispatch(setSignNowDocumentTemporaryAction(values))
    navigate(RoutesPaths.E_SIGN)
  }


  useEffect(() => {
    register('manualUpload')
    register('defaultFileTemplate')
    register('roomId')
    register('participants')
    register('manualReponseUploadFileName')
  }, [register])

  useEffect(() => {
    const renderResetField = () => {
      resetField('manualUpload')
      resetField('defaultFileTemplate')
      setValue('defaultFileTemplate', null);
      setUploadedTemplateFile([])
      setValue('roomId', sid)
      setValue('participants', participants)
    }
    renderResetField()
  }, [templateType, sid, participants])

  const getSignedFilter = (item, type) => {
    const signerCount = item?.signers.filter(x => x.status === 2)
    if (type) {
      return item?.signers.length > 0 ? signerCount.length - 1 === item?.signers.length - 1 ? true : false : null
    } else {
      return `${signerCount.length > 1 ? signerCount.length - 1 : 0}/${item?.signers.length - 1}`
    }

  }

  const getSigningLink = (item) => {
    const result = item?.signers.filter(x => x.user === user?.id)

    if (result.length > 0) {
      if (result[0].status === 1 && result[0].signUrl?.url_no_signup) {
        return (
          <>
            <span onClick={() => signingEmbedClick(result[0]?.signUrl?.url_no_signup, result[0]?.documentId)}>
              <i className="fa-solid fa-pencil"></i> Sign
            </span>
          </>
        )
      } else if (result[0].status === 2) {
        return 'Completed'
      } else {
        return 'In-progress'
      }
    } else {
      return 'In-progress'
    }
  }

  const signingEmbedClick = (url, documentId) => {
    dispatch(setEmbedEsignPopupFlowToggle(true))
    dispatch(setEmbedEsignPopupFlowLink(url))
    dispatch(setEmbedEsignPopupFlowEdocumentId(documentId))
  }

  const OptionLabelWithImage = ({ option }) => {
    return (
      <div>
        <img src="assets/img/Sign.svg" alt="Signature icon" style={{ marginRight: '5px' }} />
        {option.name}
      </div>
    );
  };


  const downloadSignatureFile = (item) => {
    const documentId = item?.signers[item?.signers.length - 1]?.documentId
    dispatch(getDownloadDocumentAction(documentId))
  };

  const printCompanyName = (attribute) => {
    if (attribute?.companyId && attribute?.companyName) {
      return (
        <p className="messageCompanyLink" onClick={() => changeCompany(attribute?.companyId)}>{attribute?.companyName || ""}</p>
      )
    } else {
      const defaultCompany = company.filter(x => x.isDefault === true)[0]
      return (
        <p className="messageCompanyLink" onClick={() => changeCompany(defaultCompany?.id)}>{defaultCompany?.companyName || ""}</p>
      )
    }
  }

  const changeCompany = async (companyId) => {
    await dispatch(selectCompanyDashboard(companyId));
    navigate(RoutesPaths.DASHBOARD);
  };

  // useEffect(() => {
  //   const clickLink = () => {
  //     const elements = document.getElementsByClassName("showMore");
  //     for (var i = 0; i < elements.length; i++) {
  //       elements[i].addEventListener('click', function () {
  //         // Perform actions based on the click event
  //         var parent = this.parentNode;

  //         // Toggle the class on the parent element
  //         parent.classList.toggle('showLess');
  //       });
  //     }
  //   }

  //   clickLink()
  // }, [sid]

  return (
    <>
      {sid && conversation && client && (
        <>
          <div className="profile-header">
            <button
              type="button"
              className="btn-close text-dark close-button"
              onClick={() => setShowProfile(false)}
            ></button>

            {isChatLoading ? (
              <div className='row'>
                <div className='col-md-12'>
                  <ContentLoader width={64} height={64}>
                    <rect x="0" y="0" rx="50" ry="50" width="64" height="64" />
                  </ContentLoader>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${getChatUserImage(
                    participants,
                    user
                  )})`,
                }}
                className="profile_photo">
              </div>
            )}

            {isChatLoading ? (
              <div style={{ marginLeft: "8px" }}>
                <div style={{ height: "20px" }}>
                  <ContentLoader width="80%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="80%" height="15" />
                  </ContentLoader>
                </div>
                <div style={{ height: "20px" }}>
                  <ContentLoader width="60%" height={15} >
                    <rect x="0" y="0" rx="5" ry="5" width="60%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            ) : (
              <div className="userInfo">
                {participants.length > 2 && (editConvoTrigger ? (
                  <h5>
                    <input className="editConvoInput" type="text" name="" onChange={(e) => setEditConvo(e.target.value)} value={editConvo} /> &nbsp;
                    <span onClick={handleUpdateConvoName} className="editConvo"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;
                    <span onClick={() => setEditConvoTrigger(false)} className="editConvo"><i className="fa fa-close" aria-hidden="true"></i></span>
                  </h5>
                ) : (
                  <h5>
                    <div dangerouslySetInnerHTML={{ __html: conversation?.friendlyName || getChatUserName(participants, user) }}></div>
                    {!conversation?.attributes?.isAdmin && (
                      <span onClick={() => { setEditConvoTrigger(true); setEditConvo(conversation?.friendlyName || getChatUserName(participants, user, null, [], null, true)) }} className="editConvo">
                        <i className="fa fa-pencil-square" aria-hidden="true"></i>
                      </span>
                    )}
                  </h5>
                ))}
                {printCompanyName(conversation?.attributes)}
                <p className="time">
                  {moment().format("h:mm") + " GMT" + moment().format("Z")}&nbsp;
                </p>
              </div>
            )}
          </div>

          <div className="profile-body">
            <ul>
              <>
                <Accordion>
                  {sidebarList.map((item, index) => (
                    <Accordion.Item
                      className={activeAccordion === index ? 'listHolder active' : 'listHolder'}
                      key={index}
                      eventKey={index}
                    >
                      {isChatLoading ? (
                        <div>
                          <div>
                            <ContentLoader width="100%" height={34}>
                              <rect x="0" y="0" rx="5" ry="5" width="100%" height="34" />
                            </ContentLoader>
                          </div>
                        </div>
                      ) : (
                        <Accordion.Header onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}>
                          <li>
                            <img src={item.img} className="icon" alt="" />
                            <p>{item.name}</p>
                            <div className="arrowIcon rotateArrow">
                              <img src="assets/img/profileArrow.svg" alt="" />
                            </div>
                          </li>
                        </Accordion.Header>
                      )}

                      <Accordion.Body>
                        {
                          item.id === 1 ? (
                            <>
                              <div className="typeOfFiles">
                                <form onSubmit={handleSubmit(onSubmitTemplate)}>
                                  <ul className="template-types">
                                    <li>
                                      <div className="liAlign">
                                        <input id="template" type="radio" value={ESIGN_TEMPLATE_TYPE.TEMPLATE} {...register("templateType", { required: true })} />
                                        <label htmlFor="template">
                                          Choose file template
                                        </label>
                                      </div>

                                      {parseInt(watch('templateType')) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) && (
                                        <div className="default-template-document">
                                          <Controller
                                            name="defaultFileTemplate"
                                            rules={{ required: true }}
                                            control={control}
                                            // defaultValue={null}
                                            render={({ field }) => (
                                              <Select
                                                theme={SELECT_BOX_THEME}
                                                styles={CUSTOM_SELECT_STYLES}
                                                className="select-border"
                                                {...field}
                                                options={templateFileList.filter(x=>x.isSupportAdmin===((conversation?.attributes?.isAdmin && conversation?.attributes?.isMasterAdmin) || false))}
                                                isClearable={true}
                                                formatOptionLabel={(option) => (
                                                  <OptionLabelWithImage key={option.name} option={option} />
                                                )}
                                                getOptionValue={(option) => option.id}
                                                placeholder="Select"
                                              />
                                            )}
                                          />
                                        </div>
                                      )}
                                    </li>
                                    <li>
                                      <div className="template-upload-radio">
                                        {/* <input disabled={false} id="manual" type="radio" value={ESIGN_TEMPLATE_TYPE.MANUAL} {...register("templateType", { required: true })} /> */}
                                        <label htmlFor="template">
                                          Manually upload file (coming soon)
                                        </label>
                                      </div>
                                      {parseInt(watch('templateType')) === parseInt(ESIGN_TEMPLATE_TYPE.MANUAL) && (
                                        <div className="template-upload-document">
                                          <div className="upload-here-img-task pt-0">
                                            <DropZoneTemplate id="dropFile" onUpload={uploadTemplateFiles} multiple="false" />
                                          </div>
                                        </div>
                                      )}

                                      {parseInt(watch('templateType')) === ESIGN_TEMPLATE_TYPE.MANUAL && uploadedTemplateFile.length > 0 && (
                                        <div className="uploaded-file-list">
                                          <h6>Uploaded document</h6>
                                          <ul>
                                            {uploadedTemplateFile.map((item, index) => (
                                              <li key={index}>
                                                <div className="left-uploaded-file-name">
                                                  <img src="assets/img/Sign.svg" alt="Sign.svg" />{item.name}
                                                </div>
                                                <div className="left-uploaded-change-file-name">
                                                  <button htmlFor="dropFile">Change file</button>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </li>
                                  </ul>
                                  <div className="col-lg-12 col-sm-12 col-xs-12  mt-3" style={{ overflow: "hidden" }}>
                                    <button type="submit" className="btn agree-button accountBtn">{parseInt(watch('templateType')) === parseInt(ESIGN_TEMPLATE_TYPE.TEMPLATE) || uploadedTemplateFile.length > 0 ? "Proceed" : "Upload a Document"}</button>
                                  </div>
                                </form>

                                {currentDocumentListByChannelId.length > 0 && (
                                  <div className="uploaded-file-list mt-4">
                                    <div className="document-list-file">
                                      <div className="document-list-file-header">
                                        <div className="row">
                                          <div className="col-md-9 col-9"><h6>Documents for signing</h6></div>
                                          <div className="col-md-3 col-3"><h6 className="text-center">Action</h6></div>
                                        </div>
                                      </div>

                                      <div className="document-list-file-body">
                                        {currentDocumentListByChannelId.slice(0, documentFileViewAll ? currentDocumentListByChannelId.length : documentFileLimit).map((item, index) => (
                                          <div key={index} className="row">
                                            <div className="col-md-9 col-9">
                                              <div className="left-uploaded-file-holder">
                                                <div className="left-uploaded-file-img">
                                                  <img src="assets/img/Sign.svg" alt="Sign" />
                                                </div>
                                                <div className="left-uploaded-file-name-holder">
                                                  <div className="left-uploaded-file-name">{item.documentName}.pdf</div>
                                                  <div className="left-uploaded-file-date">Date: <TimeFormat time={item?.createdAt} />
                                                    <span className="dot"></span>
                                                    Signed: {getSignedFilter(item, false)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-3 col-3">
                                              {getSignedFilter(item, true) ? (
                                                <div onClick={() => downloadSignatureFile(item)} className="left-uploaded-change-file-name download">
                                                  <i className="fa-solid fa-eye"></i> View
                                                </div>
                                              ) : (
                                                <div className="left-uploaded-change-file-name inprogress">
                                                  {getSigningLink(item)}
                                                </div>
                                              )}
                                            </div>
                                            {index < (documentFileViewAll ? (currentDocumentListByChannelId.length - 1) : (documentFileLimit - 1)) && (
                                              <div className="col-md-12">
                                                <hr />
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>

                                      {!documentFileViewAll && currentDocumentListByChannelId.length > documentFileLimit && (
                                        <div className="document-list-view-all">
                                          <button onClick={() => setDocumentFileViewAll(true)} className="btn view-all">View all</button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : item.id === 2 ? (
                            <>
                              <h5 className="partitipantMemberCountHead">Chat Members ({participants.length})</h5>
                              <div className={`row ${participants && participants.length > 0 ? 'conversation-section customScroll' : ''}`}>
                                {participants &&
                                  participants.length > 0 ? (
                                  participants.map((item, index) => (
                                    showFilteredAddPeople(item, index)
                                  ))
                                ) : (
                                  noData("No people to add...")
                                )}
                              </div>
                              {!conversation?.attributes?.isAdmin && (
                                <button onClick={() => setMessageModal(true)} type="button" className="btn card-btn add-morepeople-bottom-btn mb-0">Manage people</button>
                              )}
                            </>
                          ) : (item.id === 4) ? (
                            loadingChatMediaFile ? noData("Loading please wait ...") : (
                              // chatMedia.length > 0 ? (
                              <>

                                <Tabs onSelect={(index) => setPreviewFileTabChange(index)}>
                                  <TabList>
                                    <Tab>Images</Tab>
                                    <Tab>Documents</Tab>
                                  </TabList>
                                  <TabPanel>
                                    <ChatImagePreviewer type="image" chatMedia={chatMedia} dispatch={dispatch} previewFileTabChange={previewFileTabChange} />
                                  </TabPanel>
                                  <TabPanel>
                                    <ChatImagePreviewer type="other" chatMedia={chatMediaDocs} dispatch={dispatch} previewFileTabChange={previewFileTabChange} />
                                  </TabPanel>
                                </Tabs>

                              </>
                              // ) : (
                              //   noData("No attached files...")
                              // )
                            )
                          ) : (
                            <div className="commingSoon">
                              <div>
                                <img src="assets/img/commingSoon.svg" alt="" />
                                <p>Coming soon...</p>
                              </div>
                            </div>
                          )
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </>
            </ul>

            {/* <div className="addTask">
          <div className="taskDiv">
            <p>Add Task +</p>
          </div>
        </div> */}
          </div>
        </>
      )}
      <ManagePeople
        currentConvoSid={sid}
        participants={participants}
        messageModal={messageModal}
        isAdminConversation={conversation?.attributes?.isAdmin}
        addModalClose={() => setMessageModal(false)}
      />
      <ESignPopup
        embedShowPopup={embedShowPopup}
        modalClose={() => dispatch(setEmbedEsignPopupFlowToggle(false))}
        url={embedLinkUrl} />
    </>
  );
}
