import axionIns from "../axios";

const addBusinessProfile = async (payload, id) => {
  return axionIns
    .post(`bob/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const editBusinessProfile = async (payload, id, companyId) => {
  return axionIns
    .put(`bob/${id}/${companyId}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const removeBusinessProfile = async (id, companyId) => {
  return axionIns
    .delete(`bob/${companyId}/${id}`)
    .then((response) => {
      return response.data;
    });
};

const getBusinessProfile = async (companyId, page, limit, search, status) => {
  return axionIns
    .get(
      `bob/${companyId}?page=${page}&perPage=${limit ? limit : 25}&search=${search ? search : ""
      }&status=${status}`
    )
    .then((response) => {
      return response.data;
    });
};

const importBusinessProfile = async (payload, id, onProgress) => {
  let progress = 0;
  const config = {
    onUploadProgress: (progressEvent) => {
      progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress); // Call the callback function with progress
    },
  };

  return new Promise((resolve, reject) => {
    axionIns
      .post(`file/bob/importcsv/${id}`, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateBusinessProfile = async (companyId, id, payload) => {
  return axionIns
    .put(`bob/status/${companyId}/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const exportOject = {
  addBusinessProfile,
  editBusinessProfile,
  getBusinessProfile,
  importBusinessProfile,
  updateBusinessProfile,
  removeBusinessProfile,
};

export default exportOject;
