import React from "react";
import ReactPaginate from 'react-paginate';
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function Activity({ activity, activityCount, selectedPage, handlePageClick }) {

  return (
    <>
      <div className="card-body activity-logs p-3">
        <h6 className="text-body today-text-profile">Today</h6>
        {activity && activity.length > 0 ? activity.map((item, index) => (
          <div key={index} className="row mt-3">
            <div className="col-lg-8 col-xl-8  col-8">
              <div className="d-flex flex-column">
                <h6 className="profile-text text-sm mb-1">{item.description}</h6>
                <span className="datetime">
                  <TimeFormat time={item.createdAt} />
                </span>
                {/* <span className="text-xs datetime">{moment(item.createdAt).format('DD MMMM YYYY,') + ' at ' + moment(item.createdAt).format(' h:mm A')}</span> */}
              </div>
            </div>
            <div className="col-lg-4 col-xl-4  col-4">
              <div className="d-flex-right text-right">
                <h6 className="profile-text text-sm mb-1">{item.status === 1 ? 'Pending verification' : item.status === 2 ? "Completed" : "Rejected"}</h6>
              </div>
            </div>
          </div>
        )) : (
          <div className="d-flex pt-3">
            <div className="d-flex flex-column justify-content-center ">
              <h6 className="mb-0 text-sm table-heading text-center">No Activity Log
              </h6>
            </div>
          </div>
        )}

        {/* {activityCount > 1 && (
          <div className="pagination-section">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={activityCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>
        )} */}



        {activityCount > 0 && (
          <div className="tableBottom activeLogPadding">
            <div className="filterButton">
              <div className="dropdownButton">
                {/* <Controller
                  name="limit"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      className="select-border"
                      placeholder="10"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Form.Select>
                  )}
                /> */}
              </div>
            </div>
            <div>
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                pageCount={activityCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination customPagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={selectedPage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
