import React from "react";
import Productheader from "./Productheader";
import Productfooter from "./Productfooter";

export default function Thankyou() {
  return (
    <>
      <Productheader />
      <div className="thankYouSection">
        <div className="container-fluid screenResolation">
          <div className="row">
            <div className="col-md-12 thankYouSectionInner">
              <img src="assets/img/landingPage/success.svg" alt="" />
              <div>
                <h2>Thank you</h2>
                <h6>
                  Thank you for registering account. A verification link has
                  been sent to email address. Please click on the link that has
                  been sent to your email account to verify your email and
                  continue the registration process.
                </h6>
                <hr />
                <p>
                  Please check your spam folder if you dont see the email in
                  your account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Productfooter />
    </>
  );
}
