import { useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/sidebar/sidebar";
import RouteComponent from "./Router/router";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "./actions/company";
import { getProfileAction, userBidGoldPriceAction } from "./actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useEffect, useRef } from "react";
import Loader from "./components/loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useState } from "react";

import { gapi } from "gapi-script";
import { backendUrl, IS_DEMO, kimbo_google_client_id } from "./constants";
import { getpendingInviteAction, showCurrentInvite } from "./actions/promoters";
import InviteConfirmation from "./pages/dashboard/InviteConfirmation";
import { RoutesPaths } from "./Router/constant";
import AskAda from "./components/askAdaTooltip/AskAda";
import { logoutAllTabs, logoutInvalidToken, setAskOpen, verifyAuthTokenAction } from "./actions/auth";
import io from 'socket.io-client';
import CopyrightFooter from "./components/CopyrightFooter/CopyrightFooter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const socket = io(backendUrl);


function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: kimbo_google_client_id,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);
  const { isLoggedIn, loader, token, askOpen, user } = useSelector((state) => state.auth);
  const { loadingMyInfo } = useSelector((state) => state.myinfo);
  const { pendingInvites, skipInvites, currentInvite, holdNextInviteTemp } = useSelector(
    (state) => state.promoters
  );
  const [sidebarShow, setSidebarShow] = useState(true);
  const [showAskAda, setShowAskAda] = useState(true);
  const [inviteModalShow, setInviteModalShow] = useState(false);

  const sid = useSelector((state) => state.sid);
  const { client } = useSelector((state) => state.chat);
  const conversations = useSelector((state) => state.convos);

  const loadRef = useRef(true);
  const loadRefSocket = useRef(true);

  useEffect(() => {
    // if(loadRefSocket.current){
    // Listen for logout event
    socket.on('logout', (userId) => {
      // if(isLoggedIn && user?.id===userId){
      verifyCombineToken()
      // }
    });

    socket.on('gold-bid-price', () => {
      dispatch(userBidGoldPriceAction())
    })

    loadRefSocket.current = false
    // }
  }, [])

  useEffect(() => {
    const callCombineLogoutFunction = () => {
      dispatch(logoutAllTabs())
      browserChangeEvent()
    }
    callCombineLogoutFunction();
  }, [])

  const browserChangeEvent = () => {
    document.addEventListener('visibilitychange', function (ev) {
      if (document.visibilityState === 'visible') {
        verifyCombineToken()
      }
    });
  }

  useEffect(() => {
    const callCombineVerifyFunction = () => {
      verifyCombineToken()
    }
    callCombineVerifyFunction();
  }, [dispatch, isLoggedIn, token, location])

  const verifyCombineToken = () => {
    if (isLoggedIn === true && token !== null) {
      dispatch(verifyAuthTokenAction())
    } else {
      if (localStorage.getItem('kimbocorp') && localStorage.getItem('token')) {
        dispatch(verifyAuthTokenAction())
      }
    }
  }

  // Trigger sth, when Connection comes back | Connection gets lost
  window.ononline = (event) => {
    toast.success("Network connectivity refreshed");
  };

  window.onoffline = (event) => {
    toast.error("Network connectivity lost, please refresh");
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileAction());
      dispatch(getAllCompany());
      loadRef.current = true;
    }

    if (loadRef.current) {
      dispatch(getpendingInviteAction());
    }
    return () => {
      loadRef.current = false;
    };
  }, [dispatch, isLoggedIn]);


  const checkSkipInvites = useCallback(() => {
    if (
      pendingInvites.length > 0 &&
      pendingInvites[currentInvite] &&
      pendingInvites[currentInvite].token && !holdNextInviteTemp && location.pathname !== RoutesPaths.MESSAGE_DETAIL
    ) {
      if (!skipInvites.includes(pendingInvites[currentInvite].token)) {
        setInviteModalShow(true);
      } else {
        if (currentInvite < pendingInvites.length - 1) {
          dispatch(showCurrentInvite(currentInvite + 1));
        }
      }
    }
  });

  useEffect(() => {
    if (pendingInvites.length > 0) {
      checkSkipInvites();
    }

    return () => {
      orderInviteModalCloseFunc(true);
    } 
  }, [currentInvite, pendingInvites]);

  useEffect(() => {
    if (location && location.pathname) {
      const locationPath = location.pathname.split("/");
      if (locationPath[IS_DEMO ? 1 : 2] === "verify-email" || locationPath[IS_DEMO ? 1 : 2] === "e-sign" || locationPath[IS_DEMO ? 1 : 2] === "success-signnow") {
        setSidebarShow(false);
      } else {
        setSidebarShow(true);
      }
      setShowAskAda(locationPath[IS_DEMO ? 1 : 2] === 'chat' ? false : true)
    }
    dispatch(showCurrentInvite(0))
  }, [location, dispatch]);

  const orderInviteModalCloseFunc = (trigger) => {
    setInviteModalShow(false);
    if (!trigger) {
      setTimeout(() => {
        if (currentInvite < pendingInvites.length - 1) {
          dispatch(showCurrentInvite(0));
        }
      }, 300);
    }
  };

  return (
    <>
      <>
        {isLoggedIn && sidebarShow && <Sidebar />}
        {(loader ||
          loadingMyInfo) && <Loader />}
        <Toaster
          position="top-center"
          containerStyle={{ zIndex: 123465789 }}
          toastOptions={{
            className: "",
            duration: 5000,
            success: {
              iconTheme: {
                primary: "var(--primary-color)",
              },
            },
          }}
        />
        <ScrollToTop />
        <InviteConfirmation
          inviteRequest={
            pendingInvites.length > 0 ? pendingInvites[currentInvite] : null
          }
          orderModalShow={inviteModalShow}
          orderModalClose={orderInviteModalCloseFunc}
        />
        <main className="main-content position-relative h-100 border-radius-lg ">
          <RouteComponent></RouteComponent>
          {isLoggedIn &&
            <>
              {showAskAda && (
                <AskAda />
              )}
              <CopyrightFooter />
            </>}
        </main>
      </>
    </>
  );
}

export default App;
