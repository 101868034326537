import {
  GET_USER_GUIDE,
  LOAD_USER_GUIDE_SKELETON,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  USER_GUIDE_TYPE,
} from "./types";
import userGuideService from "../services/user-guide.service";

export const getUserGuideAction = () => async (dispatch, getState) => {
  const identity = getState()?.userGuide?.identityType || 1
  dispatch({ type: GET_USER_GUIDE, payload: [] });
  dispatch({ type: LOAD_USER_GUIDE_SKELETON, payload: true });
  return userGuideService.getUserGuide(identity).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_USER_GUIDE, payload: resp.data });
    } else {
      dispatch({ type: GET_USER_GUIDE, payload: [] });
    }
    return Promise.resolve();
  });
};

export const postNewsLetterAction = () => (dispatch) => {
  return userGuideService.postNewsLetter().then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve();
  });
};