import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { adminLoginVerifyAction } from "../../actions/auth";
import Loader from "../../components/loader/loader";
import { RoutesPaths } from "../../Router/constant";

export default function AdminLoginVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const loadRef = useRef(true);

  const params = useParams();
  const { userId, adminId, company } = params;
  useEffect(() => {
    if (loadRef.current && userId && adminId) {
      proceedSignal()
    }
    return () => {
      if(!isLoggedIn){
        loadRef.current = false;
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(RoutesPaths.DASHBOARD);
    }
  }, [isLoggedIn])

  const proceedSignal = () => {
    dispatch(adminLoginVerifyAction({userId, adminId, company}));
  }

  return (
    <>
      <Loader />
    </>
  );
}
