import axionIns from "../axios";

const leaveMessage = async (payload) => {
  return await axionIns.post("/help/leave-message", payload).then((response) => { return response.data });
};

const exportObject = {
  leaveMessage,
};
export default exportObject;
