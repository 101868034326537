import { CHAT_RESPONSE, GET_CHANNEL_LIST, TWILIO_TOKEN, SET_CHANNEL_LAST_MESSAGE, SET_CHANNEL_MESSAGE, SET_CHANNEL_NEW_MESSAGE, RESET_CHANNEL_MESSAGE, REMOVE_TYPING_INDICATOR, CLEAR_TYPING_INDICATOR, SET_TYPING_INDICATOR, SET_CLIENT, SET_CURRENT_CONVERSATION, SET_CHAT_CONVERSATION_MEDIA, SET_BULK_CHANNEL_MESSAGE, SET_CHAT_SEARCH_RESULT, SET_CHAT_SEARCH_MESSAGE_ID, SET_CHAT_LOADING_SKELETON, SET_LOAD_CLIENT, HOLD_SID_CONVERSATION, OPEN_LATEST_CONVERSATION, CHAT_MEDIA, LOADING_CHAT_MEDIA, CHAT_PREVIEWER_MEDIA } from "../actions/types";
import { backendUrl } from "../constants";
const initialState = {
  client: null,
  channelRes: {},
  channelList: [],
  channelIds: [],
  twilioToken: null,
  currentConvoId: null,
  bulkMessages: [],
  allMessages: [],
  messages: [],
  typingIndicator: {},
  conversation: null,
  conversationMedia: [],
  searchQuery: "",
  searchMessageId: null,
  isChatLoading: true,
  loadClient: {
    current: true
  },
  holdSidConversation: null,
  openedLatestConversation: {},
  chatMedia: [],
  chatMediaFileName: [],
  chatMediaDocs: [],
  loadingChatMediaFile: false,
  currentImage: 0,
  isViewerOpen: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHAT_RESPONSE:
      return {
        ...state,
        channelRes: payload,
      };
    case GET_CHANNEL_LIST:
      return {
        ...state,
        channelList: payload,
        channelIds: payload.map(x => x.channelId)
      };
    case TWILIO_TOKEN:
      return {
        ...state,
        twilioToken: payload,
      };

    case SET_CHANNEL_LAST_MESSAGE:
      state.channelList.filter(function (element) {
        if (element.id === payload.chatId) {
          element.lastMessage = payload.message
          element.unreadMessageCount = payload.unreadMessageCount
        }
      })
      return {
        ...state,
        channelList: state.channelList,
      };
    case SET_CHANNEL_MESSAGE:
      var messageIndex = state.allMessages.findIndex(x => x.sid === payload.sid)
      if (messageIndex === -1) {
        state.allMessages.push({ sid: payload.sid, message: payload.message })
      } else {
        if (payload.hasPrevious) {
          state.allMessages[messageIndex] = { sid: payload.sid, message: [...payload.message, ...state.allMessages[messageIndex].message] }
        } else {
          state.allMessages[messageIndex] = { sid: payload.sid, message: payload.message }
        }
      }

      var filterMessage = state.allMessages.filter(x => x.sid === payload.sid)
      return {
        ...state,
        currentConvoId: payload.sid,
        allMessages: state.allMessages,
        messages: filterMessage.length > 0 ? filterMessage[0].message : [],
      };


    case SET_CHANNEL_NEW_MESSAGE:
      var messageIndex1 = state.allMessages.findIndex(x => x.sid === payload.sid)
      if (messageIndex1 > -1) {
        state.allMessages[messageIndex1] = { sid: payload.sid, message: [...state.allMessages[messageIndex1].message, payload.message] }
      }

      var filterMessage1 = state.allMessages.filter(x => x.sid === state.currentConvoId)
      return {
        ...state,
        allMessages: state.allMessages,
        messages: filterMessage1.length > 0 ? filterMessage1[0].message : [],
      };

    case RESET_CHANNEL_MESSAGE:
      return {
        ...state,
        messages: [],
      };

    case SET_BULK_CHANNEL_MESSAGE:
      return {
        ...state,
        bulkMessages: payload,
      };

    case SET_TYPING_INDICATOR:
      var identity = payload.identity;
      var channelId = payload.channelId;
      var channelTyping = state.typingIndicator[channelId] || []
      if (channelTyping.indexOf(identity) === -1) {
        channelTyping.push(identity)
      }
      state.typingIndicator[channelId] = channelTyping
      return {
        ...state,
        typingIndicator: state.typingIndicator,
      };

    case REMOVE_TYPING_INDICATOR:
      var identity1 = payload.identity;
      var channelId1 = payload.channelId;
      var array1 = state.typingIndicator[channelId1] || []
      var index1 = array1.indexOf(identity1)
      if (index1 !== -1) {
        array1.splice(index1, 1);
      }
      state.typingIndicator[channelId1] = array1
      return {
        ...state,
        typingIndicator: state.typingIndicator,
      };

    case CLEAR_TYPING_INDICATOR:
      return {
        ...state,
        typingIndicator: {},
      };

    case SET_CLIENT:
      return {
        ...state,
        client: payload,
      };

    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        conversation: payload,
      };

    case SET_CHAT_CONVERSATION_MEDIA:
      return {
        ...state,
        conversationMedia: payload,
      };

    case SET_CHAT_SEARCH_RESULT:
      return {
        ...state,
        searchQuery: payload,
      };

    case SET_CHAT_SEARCH_MESSAGE_ID:
      return {
        ...state,
        searchMessageId: payload,
      };

    case SET_CHAT_LOADING_SKELETON:
      return {
        ...state,
        isChatLoading: payload,
      };

    case SET_LOAD_CLIENT:
      return {
        ...state,
        loadClient: {
          current: false
        },
      };

    case HOLD_SID_CONVERSATION:
      return {
        ...state,
        holdSidConversation: payload,
      };

    case OPEN_LATEST_CONVERSATION:
      return {
        ...state,
        openedLatestConversation: payload,
      };

    case CHAT_MEDIA:

      const chatImages = payload.filter(x=>x.mimetype.startsWith('image/'));
      return {
        ...state,
        chatMedia: chatImages,
        chatMediaFileName: chatImages.map(file => backendUrl + file.filename),
        chatMediaDocs: payload.filter(x=>!x.mimetype.startsWith('image/'))
      };

    case LOADING_CHAT_MEDIA:
      return {
        ...state,
        loadingChatMediaFile: payload,
      };

    case CHAT_PREVIEWER_MEDIA:
      return {
        ...state,
        currentImage: payload.currentImage,
        isViewerOpen: payload.isViewerOpen
      };

    default:
      return state;
  }
}
