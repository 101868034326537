import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ContactUs from "../../components/ContactUs/ContactUs";

export default function EditPromoterModal({
  editPromoterModalShow,
  editPromoterModalClose,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    editPromoterModalClose();
    reset();
  };
  return (
    <>
      <Modal
        show={editPromoterModalShow}
        onHide={editPromoterModalClose}
        className="edit-information-width ps-0"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={editPromoterModalClose}
          >
          </button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title" id="exampleModalLabel">
                Edit Promoter Information
              </h5>
              <p className="promoter-description">
                Every business can appoint as many promoters as required.
              </p>
              <p className="promoter-description">
                A promoter is any person who plays a part in forming a company
                or establishing its business (usually potential shareholders or
                directors of the business).
              </p>
              <p className="promoter-description">
                A promoter can act for or as an agent of a business, or provide
                services or its own skillset to the business.
              </p>
              <p className="promoter-description">
                A promoter who acts for or as an agent of the business before it
                has been legally incorporated will be personally liable for any
                contract they make on behalf or with the business.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label shreholder-text"
                      >
                        Promoter Full Name
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="John Cole"
                        {...register("firstName", {
                          required: true,
                          maxLength: 20,
                          minLength: 3,
                        })}
                      />
                      {errors.firstName?.type === "required" && (
                        <span className="errorMessage">Name is required</span>
                      )}
                      {errors.firstName?.type === "maxLength" && (
                        <span className="errorMessage">
                          Name may not be longer than 20 characters
                        </span>
                      )}
                      {errors.firstName?.type === "minLength" && (
                        <span className="errorMessage">
                          Minimum 3 characters required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label shreholder-text "
                        >
                          Promoter Email
                        </label>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Email address"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="errorMessage">Email is Invalid</span>
                        )}
                      </div>

                      <div className="col-lg-2">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label shreholder-text "
                        >
                          Country Code
                        </label>

                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="message-text"
                          placeholder="+63"
                          {...register("area", {
                            required: true,
                          })}
                          style={{ paddingRight: "45px" }}
                        />
                        {errors.area?.type === "required" && (
                          <span className="errorMessage">
                            Enter your Country Code
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label shreholder-text"
                        >
                          Mobile Number
                        </label>
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="number"
                          className="form-control"
                          placeholder="Mobile Number"
                          {...register("mobile", {
                            required: true,
                          })}
                        />
                        {errors.mobile?.type === "required" && (
                          <span className="errorMessage">
                            Mobile Number is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="import-note">
                  Important note:{" "}
                  <span className="will-need-text">
                    We will send the promoter an email confirmation of this
                    change request.
                  </span>
                </p>
                <div className="modal-footer">
                  <ContactUs handleClose={editPromoterModalClose} />
                  <button type="submit" className="btn agree-button">
                    Agree & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
