import { HOLDERS } from "../constants";
import promoterService from "../services/promoters.service";
import { getAllCompany } from "./company";
import { getOfficerList } from "./officer";
import { getShareHolderList } from "./shareHolder";

import {
  GET_PROMOTER_LIST,
  GET_MERGE_PROMOTER_LIST,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  GET_INVITE_DETAIL_BY_TOKEN,
  GET_PENDING_INVITES,
  GET_SKIP_INVITES,
  INCREMENT_CURRENT_INVITE,
  GET_SHAREHOLDER_LIST,
  GET_OFFICER_LIST,
  SET_HOLD_NEXT_INVITE_TEMP,
  SET_HOLD_NEXT_INVITE_TEMP_DATA,
  IS_VALID_TOKEN,
} from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getPromoterAction = (companyId) => async (dispatch) => {
  return await promoterService.getInvite(companyId).then((resp) => {
    if (resp.status) {
      dispatch({
        type: GET_MERGE_PROMOTER_LIST,
        payload: resp.data,
        acceptedPromoters: resp.companyAcceptedPromoters,
        invitePassed: resp?.invitePassed
      });
      dispatch({ type: GET_PROMOTER_LIST, payload: resp.data });
    } else {
      dispatch({ type: GET_PROMOTER_LIST, payload: [] });
    }
  });
};

export const addPromoterAction = (companyId, payload) => async (dispatch) => {
  return promoterService.addInvite(companyId, payload).then((resp) => {
    if (resp.status) {
      // if (companyId) dispatch(getPendingInvites(companyId, payload.type));
      if (HOLDERS.promoters === payload.type) {
        dispatch(getPromoterAction(companyId));
      }
      if (payload?.global !== 1) {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return resp;
  });
};

export const updatePromoterAction =
  (companyId, inviteId, payload) => (dispatch) => {
    return promoterService
      .updateInvite(companyId, inviteId, payload)
      .then((resp) => {
        if (resp.status) {
          if (companyId) dispatch(getPromoterAction(companyId));
          dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        } else {
          dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };

export const removePromoterCompanyAction =
  (companyId, payload) => (dispatch) => {
    return promoterService
      .deleteCompanyInvite(companyId, payload)
      .then((resp) => {
        if (resp.status) {
          dispatch(getAllCompany());
          if (companyId) dispatch(getPromoterAction(companyId));
          dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        } else {
          dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };

export const removePromoterAction = (companyId, payload) => (dispatch) => {
  return promoterService.deleteInvite(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch(getAllCompany());
      if (companyId) dispatch(getPromoterAction(companyId));
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};

export const denyPendingInviteAction = (companyId, payload) => (dispatch) => {
  return promoterService.deleteInvite(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch(getpendingInviteAction());
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve(resp);
  });
};

export const addInviteAcceptAction = (companyId, token) => (dispatch) => {
  dispatch(startLoaderAction());
  return promoterService.addInviteAccept(token).then((resp) => {
    if (resp.status) {
      if (localStorage.getItem("INVITE_PROMOTER_TOKEN")) {
        localStorage.removeItem("INVITE_PROMOTER_TOKEN");
      }
      dispatch(showCurrentInvite(0));
      dispatch({ type: GET_INVITE_DETAIL_BY_TOKEN, payload: {} });
      dispatch(getAllCompany(true, companyId));
      dispatch(getpendingInviteAction());
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
      return resp;
    } else {
      dispatch(stopLoaderAction());
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      return { status: false };
    }
  });
};

export const getInviteByTokenAction = (token) => async (dispatch) => {
  if (token === "clear") {
    dispatch({ type: GET_INVITE_DETAIL_BY_TOKEN, payload: {} });
  } else {
    dispatch(startLoaderAction());
    return await promoterService.getInviteByToken(token).then((resp) => {
      if (resp.status) {
        dispatch({
          type: GET_INVITE_DETAIL_BY_TOKEN,
          payload: resp.data.length > 0 ? resp.data[0] : {},
        });
        dispatch({ type: IS_VALID_TOKEN, payload: resp.status });
      } else {
        dispatch({ type: GET_INVITE_DETAIL_BY_TOKEN, payload: {} });
        dispatch({ type: IS_VALID_TOKEN, payload: resp.status });
      }
      dispatch(stopLoaderAction());
    });
  }
};

export const getpendingInviteAction = () => async (dispatch) => {
  // dispatch(startLoaderAction());
  return await promoterService.getpendingInvite().then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_PENDING_INVITES, payload: resp.data });
    } else {
      dispatch({ type: GET_PENDING_INVITES, payload: [] });
    }
    // dispatch(stopLoaderAction());
  });
};

export const skipPromoterInviteModal = (pendingInvites) => async (dispatch) => {
  const skipInviteModal = pendingInvites.map((item) => item.token);
  localStorage.setItem("skipInviteModal", JSON.stringify(skipInviteModal));
  dispatch({ type: GET_SKIP_INVITES, payload: skipInviteModal });

  // OLD CODE
  // var skipInviteModal = localStorage.getItem("skipInviteModal")
  //   ? JSON.parse(localStorage.getItem("skipInviteModal"))
  //   : [];
  // if (!skipInviteModal.includes(token)) {
  //   skipInviteModal.push(token);
  //   localStorage.setItem("skipInviteModal", JSON.stringify(skipInviteModal));
  //   dispatch({ type: GET_SKIP_INVITES, payload: skipInviteModal });
  // }
  // OLD CODE
};

export const removePromoterInviteModalAction =
  (inviteId, indexValue) => async (dispatch) => {
    var skipInviteModal = localStorage.getItem("skipInviteModal")
      ? JSON.parse(localStorage.getItem("skipInviteModal"))
      : [];
    const index = skipInviteModal.indexOf(inviteId);
    if (index > -1) {
      // only splice array when item is found
      skipInviteModal.splice(index, 1); // 2nd parameter means remove one item only
    }
    localStorage.setItem("skipInviteModal", JSON.stringify(skipInviteModal));
    dispatch({ type: GET_SKIP_INVITES, payload: skipInviteModal });
    dispatch(showCurrentInvite(indexValue));
    dispatch(getpendingInviteAction());
  };

export const clearPromoterInviteModal = () => async (dispatch) => {
  var skipInviteModal = localStorage.getItem("skipInviteModal");
  if (skipInviteModal) {
    localStorage.removeItem("skipInviteModal");
    dispatch({ type: GET_SKIP_INVITES, payload: [] });
  }
};

export const showCurrentInvite = (payload) => (dispatch) => {
  dispatch({ type: INCREMENT_CURRENT_INVITE, payload: payload });
};

export const holdNextInviteTemporaryAction = (payload) => (dispatch) => {
  dispatch({ type: SET_HOLD_NEXT_INVITE_TEMP, payload: payload });
};

export const holdNextInviteDataAction = (payload) => (dispatch) => {
  dispatch({ type: SET_HOLD_NEXT_INVITE_TEMP_DATA, payload: payload });
};

export const appointPromoterAction =
  (companyId, payload, proposedPosition) => (dispatch) => {
    return promoterService
      .appointPromoter(companyId, payload, proposedPosition)
      .then((resp) => {
        if (resp.status) {
          dispatch(getAllCompany(null, companyId));
          dispatch(getOfficerList(companyId));
          dispatch(getShareHolderList(companyId));
          dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        } else {
          dispatch({
            type: SET_FAILED_MESSAGE,
            payload: "Something wen't wrong",
          });
        }
      });
  };

// export const getPendingInvites = (companyId, payload) => async (dispatch) => {
//   return promoterService.getPendingInvites(companyId, payload).then((resp) => {
//     dispatch({
//       type: GET_PROMOTER_LIST,
//       payload: resp.status ? resp.data : [],

//     });
//   });
// };

export const addPromoterChannels = (payload) => (dispatch) => {
  return promoterService.addPromoterChannels(payload).then((res) => {
    if (res.status) {
      dispatch({ SET_SUCCESS_MESSAGE, payload: res.data });
    } else {
      dispatch({ SET_FAILED_MESSAGE, payload: res.data });
    }
  });
};
