import { GET_FILES, LOAD_FILES_SKELETON, RESET_FILES_TRIGGER } from "../actions/types";
const initialState = {
  files: [],
  loadFiles: false,
  count: 0,
  reset: 0,
};
export default function filesReducer(state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_FILES:
      return {
        ...state,
        files: payload,
        loadFiles: false,
        count: count,
      };
    case LOAD_FILES_SKELETON:
      return {
        ...state,
        loadFiles: payload,
      };

    case RESET_FILES_TRIGGER:
      return {
        ...state,
        reset: state.reset + 1,
      };
    default:
      return state;
  }
}
