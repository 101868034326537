import { isDesktop, isMobile } from "react-device-detect";
import { GET_CAPITAL_LIST, LOAD_CAPITAL_SKELETON } from "../actions/types";
import { CAPITAL } from "../constants";
const initialState = {
  showCapitalJoy: false,
  shareCapital: [],
  paidUpCapital: [],
  myInfoShareCapital: [],
  myInfopaidUpCapital: [],
  sharedCapitalIsApproved: true,
  sharedCapitalIsEditApproved: true,
  paidUpCapitalIsApproved: true,
  paidUpCapitalIsEditApproved: true,
  paidUpAmountPaid: [],
  amendmentFlag: {},
  shareCapitalSpotlightSteps: [
    {
      content: '',
      placement: '',
      target: 'bobride',
    },
    {
      content: 'To add paid-up capital, you first have to add share capital. The same share capital number you added will be reflected in the paid-up capital section as a payable.',
      placement: isMobile ? 'right' : "right",
      target: '.companySpotlightStep3',
      title: 'Share capital',
    },
  ]
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CAPITAL_LIST:
      let normalCapital = payload.data;
      let myinfoCapital = payload.myinfo;

      let shareCapital = normalCapital.length > 0 ? normalCapital.filter(x => x.capitalType === CAPITAL.shared) : [];
      let paidUpCapital = normalCapital.length > 0 ? normalCapital.filter(x => x.capitalType === CAPITAL.paidUp) : [];

      let myInfoShareCapital = myinfoCapital.length > 0 ? myinfoCapital.filter(x => x.capitalType === CAPITAL.shared) : [];
      let myInfopaidUpCapital = myinfoCapital.length > 0 ? myinfoCapital.filter(x => x.capitalType === CAPITAL.paidUp) : [];

      let sharedCapitalIsApproved = shareCapital.length > 0 && (shareCapital.filter(x => !x.isApproved).length > 0) ? false : true;
      let sharedCapitalIsEditApproved = sharedCapitalIsApproved ? shareCapital.filter(x => !x.isEditApproved).length > 0 ? false : true : true;

      let paidUpCapitalIsApproved = paidUpCapital.length > 0 && paidUpCapital.filter(x => !x.isApproved).length > 0 ? false : true;
      let paidUpCapitalIsEditApproved = paidUpCapitalIsApproved ? paidUpCapital.filter(x => !x.isEditApproved).length > 0 ? false : true : true;

      let amendmentFlag = {}
      if (!paidUpCapitalIsEditApproved) {
        const filterUsdOrdinary = paidUpCapital.filter(x => !x.isEditApproved && x.currency.toLowerCase() === 'usd' && x.shareType === 1).length
        const filterUsdPreference = paidUpCapital.filter(x => !x.isEditApproved && x.currency.toLowerCase() === 'usd' && x.shareType === 2).length
        const filterSgdOrdinary = paidUpCapital.filter(x => !x.isEditApproved && x.currency.toLowerCase() === 'sgd' && x.shareType === 1).length
        const filterSgdPreference = paidUpCapital.filter(x => !x.isEditApproved && x.currency.toLowerCase() === 'sgd' && x.shareType === 2).length
        amendmentFlag['usd1'] = filterUsdOrdinary > 0 ? true : false
        amendmentFlag['usd2'] = filterUsdPreference > 0 ? true : false
        amendmentFlag['sgd1'] = filterSgdOrdinary > 0 ? true : false
        amendmentFlag['sgd2'] = filterSgdPreference > 0 ? true : false
      }

      return {
        ...state,
        shareCapital: shareCapital,
        paidUpCapital: paidUpCapital,
        sharedCapitalIsApproved: sharedCapitalIsApproved,
        sharedCapitalIsEditApproved: sharedCapitalIsEditApproved,
        paidUpCapitalIsApproved: paidUpCapitalIsApproved,
        paidUpCapitalIsEditApproved: paidUpCapitalIsEditApproved,
        myInfoShareCapital: myInfoShareCapital,
        myInfopaidUpCapital: myInfopaidUpCapital,
        paidUpAmountPaid: payload.paidUpAmountPaid,
        amendmentFlag: amendmentFlag,
        showCapitalJoy: true,
      };

    case LOAD_CAPITAL_SKELETON:
      return {
        ...state,
        loadCapital: payload
      }
    default:
      return state;
  }
}
