import { CURRENCY, CURRENCY_CRYPTO_NAME, CURRENCY_NAME, SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD } from "../constants";
import stripeService from "../services/stripe.service";
import { SET_STRIPE_SECRET_TOKEN, SET_BANK_CRYPTO_DETAILS, SET_STRIPE_MODAL, SET_STRIPE_SUBSCRIPTION, SET_PAYMENT_METHOD_SELECTION, SET_FAILED_MESSAGE, START_LOADER, STOP_LOADER, SET_STRIPE_PAYMENT, SET_SUCCESS_MESSAGE, SET_STRIPE_PLANS, LOAD_STRIPE_PAYMENT, SET_STRIPE_PAYABLE_AMOUNT, TRANSACTION_SEGMENT_LIST, SET_BID_GOLD_HEADER_CURRENCY, SET_OVERRIDE_PLAN_PRODUCT_PRICE, SET_BANK_TRANSFER_BACK_TRIGGER, MANUAL_PAYMENT_PROCEEDING, SET_FORCE_CART_PAYABLE_AMOUNT, SET_HOLD_CURRENCY_AMOUNT_PAID_UP, SET_CURRENCY_WATCH, SET_TRANSACTION_STATUS, SET_TRANSACTION_STATUS_LOADER, SET_TRIPLEA_URL, SET_TRIPLEA_STATE } from "./types";
import { getProfileAction } from "./user";
import { userDashboardBullionBalanceUpdate, userDashboardMetalAllocationUpdate } from "./userDashboard";


export const paymentModalAction = (stripeModal, stripeType) => (dispatch, getState) => {
  var showStripePlanByModule = {}
  switch (stripeType) {
    case SUBSCRIPTION_MODULE_TYPE.BOB:
      showStripePlanByModule = getState()?.company?.selectedCompany?.bobSubscriptionPlan || {}
      break;
    default:
      showStripePlanByModule = getState()?.subscriptionPlanProducts?.selectedPlanProduct || {}
      break;
  }

  if (showStripePlanByModule?.isActive) {
    dispatch({ type: SET_STRIPE_PLANS, payload: showStripePlanByModule });
  }
  dispatch({ type: SET_STRIPE_MODAL, payload: { stripeModal, stripeType, showStripePlanByModule } });
};


export const getStripeSubscriptionAction = (companyId, page, limit) => (dispatch) => {
  // dispatch({ type: START_LOADER });
  dispatch({ type: LOAD_STRIPE_PAYMENT, payload: true })
  return stripeService.getStripePaymentByCompanyId(companyId, page, limit).then((resp) => {
    // dispatch({ type: STOP_LOADER });
    if (resp.status) {
      dispatch({ type: SET_STRIPE_PAYMENT, payload: resp.data, subscriptionCount: resp.count });
      dispatch({ type: TRANSACTION_SEGMENT_LIST, payload: resp.data.length > 0 ? resp.data[0] : {} });
      if (resp?.data?.length > 0) {
        dispatch(getTransactionStatusAction(resp?.data[0]?.id))
      }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }

  });
};


export const createStripeTokenAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  setTimeout(async () => {
    return stripeService.createStripeToken(payload).then((resp) => {
      if (resp.status) {
        dispatch({ type: SET_STRIPE_SECRET_TOKEN, payload: resp.data.url });
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      dispatch({ type: STOP_LOADER });
    });
  }, 1500);
};

export const subscriptionPlan = (payload) => (dispatch) => {
  dispatch({ type: SET_STRIPE_SUBSCRIPTION, payload: payload });
};


export const cancelStripeSubscriptionAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER })
  return stripeService.cancelStripe(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
    return resp;
  });
};


export const getInvoiceDetailAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER })
  return stripeService.getInvoiceDetails(payload).then((resp) => {
    if (resp.status) {
      if (resp.data) {
        window.location.href = resp.data
        setTimeout(() => {
          dispatch({ type: STOP_LOADER })
        }, 500);
      }
    } else {
      dispatch({ type: STOP_LOADER })
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
}

export const getChargeReceiptUrlAction = (paymentIntentId) => async (dispatch) => {
  dispatch({ type: START_LOADER })
  return stripeService.getChargeReceiptUrl(paymentIntentId).then((resp) => {
    if (resp.status) {
      if (resp.data) {
        window.open(
          resp.data,
          '_blank' // <- This is what makes it open in a new window.
        );
        setTimeout(() => {
          dispatch({ type: STOP_LOADER })
        }, 500);
      }
    } else {
      dispatch({ type: STOP_LOADER })
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
}

// export const getStripePlansAction = () => (dispatch) => {
//   dispatch({ type: START_LOADER })
//   return stripeService.getStripePlans().then((resp) => {
//     dispatch({ type: STOP_LOADER })
//     if (resp.status) {
//       dispatch({ type: SET_STRIPE_PLANS, payload: resp.data });
//     } else {
//       dispatch({ type: SET_STRIPE_PLANS, payload: [] });
//     }
//   });
// }

// export const setStripePlansAction = (data) => (dispatch) => {
//   dispatch({ type: SET_STRIPE_PLANS, payload: data });
// }



export const extraFeeCalcAction = (paymentMethod, moduleType, payload) => async (dispatch) => {
  return stripeService.xtraFeesCalculator(payload).then((resp) => {
    dispatch({ type: SET_STRIPE_PAYABLE_AMOUNT, payload: resp?.status ? resp.data : null, paymentMethod: paymentMethod, moduleType: moduleType })
    return Promise.resolve(resp);
  });
}

export const currencyConversionCalcHighAction = (payload) => async (dispatch) => {
  dispatch(forceCartAmountPayableAction({ "baseAmountRate": 0, "transactionFees": 0, "payableAmount": 0 }))
  return stripeService.currencyConversionCalc(payload).then((resp) => {
    dispatch(forceCartAmountPayableAction(resp?.status ? resp.data : null), payload)
    return Promise.resolve(resp);
  });
}

export const setPaymentMethodSelection = (data) => (dispatch, getState) => {
  dispatch({ type: SET_PAYMENT_METHOD_SELECTION, payload: data })
  const currency = getState()?.stripe?.stripePlans?.currency?.value || null
  const paymentMethodSelection = getState()?.stripe?.paymentMethodSelection || null
  var currencyDetails = null;
  let paymentSavedinfo = null;
  switch (paymentMethodSelection) {
    case SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER:
      currencyDetails = CURRENCY_NAME.find((item) => item.value === currency)
      paymentSavedinfo = getState()?.user?.user?.bankInfo ?? null
      break;
    case SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD:
      currencyDetails = getState()?.stripe?.stripePlans?.currency
      paymentSavedinfo = { amount: getState()?.stripe?.stripePlans?.price }
      break;
    case SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO:
      currencyDetails = CURRENCY_CRYPTO_NAME.find((item) => item.value === 'usdt')
      paymentSavedinfo = getState()?.user?.user?.cryptoInfo ?? null
      break;
    default:
      break;
  }

  if (paymentSavedinfo) {
    currencyDetails = { currency: currencyDetails, ...paymentSavedinfo }
  } else {
    currencyDetails = { currency: currencyDetails }
  }

  if (currencyDetails) {
    dispatch({ type: SET_BANK_CRYPTO_DETAILS, payload: currencyDetails });
  }
}

export const saveManualDetails = (payload) => async (dispatch) => {
  dispatch({ type: SET_BANK_CRYPTO_DETAILS, payload: payload })
}


export const createManualEntryAction = (payload) => async (dispatch) => {
  dispatch({ type: START_LOADER })
  dispatch({ type: MANUAL_PAYMENT_PROCEEDING, payload: true })
  return stripeService.createManualEntry(payload).then((resp) => {
    dispatch({ type: resp.status ? SET_SUCCESS_MESSAGE : SET_FAILED_MESSAGE, payload: resp.data });
    if (!resp.status) {
      dispatch({ type: MANUAL_PAYMENT_PROCEEDING, payload: true })
    }
    dispatch(getProfileAction());
    dispatch({ type: STOP_LOADER })
    return resp
  });
}

export const currencyPayableAction = (type, moduleType, payload) => async (dispatch) => {
  // dispatch({ type: SET_STRIPE_PAYABLE_AMOUNT, payload:  payload, paymentMethod: type, moduleType: moduleType })
}

export const forceCartAmountPayableAction = (payload, payloadData = null) => async (dispatch) => {
  dispatch({ type: SET_FORCE_CART_PAYABLE_AMOUNT, payload: payload, })
}

export const updateStripePayableAmountAction = (payload) => async (dispatch) => {
  dispatch(forceCartAmountPayableAction({ "baseAmountRate": payload, "transactionFees": 0, "payableAmount": payload }))
}

export const bidGoldHeaderCurrencyAction = (currency, conversionBidPayload) => async (dispatch, getState) => {
  const { gold1kg, gold1g } = conversionBidPayload;
  if (gold1kg && gold1g) {
    const payload1 = { from: CURRENCY.USD, to: currency?.value, amount: parseFloat(gold1kg) };
    const payload2 = { from: CURRENCY.USD, to: currency?.value, amount: parseFloat(gold1g) };

    try {
      // Execute both requests concurrently using Promise.all()
      const [response1, response2] = await Promise.all([
        stripeService.currencyConverter(SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER, payload1),
        stripeService.currencyConverter(SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER, payload2)
      ]);
      // Dispatch the action with the desired payload
      dispatch({ type: SET_BID_GOLD_HEADER_CURRENCY, payload: currency, price: { gold1kg: response1.data, gold1g: response2.data } });
      if (getState()?.company?.isDefaultCompany && getState()?.userDashboard?.userDashboard?.bullionRecord && getState()?.userDashboard?.userDashboard?.bullionRecord.length > 0) {
        dispatch(userDashboardBullionBalanceUpdate(getState()?.userDashboard?.userDashboard?.bullionRecord))
        dispatch(userDashboardMetalAllocationUpdate(getState()?.userDashboard?.userDashboard?.metalAllocation))
      }
    } catch (error) {
      // Handle errors from any of the requests
      console.error('Error:', error);
    }
  }
};

export const setOverrideLockPrice = (amount) => async (dispatch) => {
  dispatch({ type: SET_OVERRIDE_PLAN_PRODUCT_PRICE, payload: amount ?? null })
}

export const setBankTranferBackTrigger = (type) => async (dispatch) => {
  dispatch({ type: SET_BANK_TRANSFER_BACK_TRIGGER, payload: type })
}

export const setCurrencyWatch = (payload) => async (dispatch) => {
  dispatch({ type: SET_CURRENCY_WATCH, payload: payload })
}

export const getTransactionStatusAction = (id) => (dispatch) => {
  dispatch({ type: SET_TRANSACTION_STATUS_LOADER, payload: true })
  return stripeService.getTransactionStatus(id).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_TRANSACTION_STATUS, payload: resp.data })
    }
  });
};

export const payWithCryptoAction = (payload) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return stripeService.payWithCrypto(payload).then((resp) => {
    if (resp.status) {
      // if (resp?.data?.hosted_url) {
      //   window.open(resp?.data?.hosted_url, "_self", "noopener,noreferrer");
      // }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp?.data?.message ? "Something wen't wrong" : resp?.data ? resp?.data : "Something wen't wrong" });
    }
    dispatch({ type: STOP_LOADER });
    return Promise.resolve(resp);
  });
}

export const setTripleaUrl = (payload, state) => async (dispatch) => {
  dispatch({ type: SET_TRIPLEA_URL, payload: payload })
  dispatch({ type: SET_TRIPLEA_STATE, payload: state })
}