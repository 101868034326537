import { MY_INFO_LOADING } from "../actions/types";
const initialState = {
  loadingMyInfo: null,
};
export default function myinfo(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MY_INFO_LOADING:
      return {
        ...state,
        loadingMyInfo: payload
      };
    default:
      return state;
  }
}
