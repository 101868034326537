import React from 'react'
import { privacy, PRODUCT_GOLD_TYPE, SUBSCRIPTION_MODULE_TYPE, SUPPORTED_PAYMENT_METHOD, terms, termsOfAdvisoryPlan } from '../../constants'
import CurrencyFormat from '../../components/currencyFormat/currencyFormat'
import Currency from '../../components/currency/currency'
import { useSelector } from 'react-redux';

export default function PlanDetailComponent({ quantityWatch }) {
    const {
        stripePlans,
        paymentMethodSelection,
        stripeType,
        isOverrideProduct,
        cartAmount,
    } = useSelector((state) => state.stripe);
    const { lockGoldPrice } = useSelector((state) => state.user);
    return (
        <div className="planDetail">
            <div className="planName">
                <h4>{stripePlans?.name || "-"}</h4>
                {stripeType === SUBSCRIPTION_MODULE_TYPE.PAIDUPCAPITAL && (
                    <div className="usdTransfer">
                        <div className="planPrice">
                            <p>Current paid-up capital</p>
                            <p>
                                <CurrencyFormat
                                    currency={stripePlans?.currency?.value}
                                    value={parseFloat(stripePlans?.price)}
                                    decimal={2}
                                />
                            </p>
                        </div>
                        <div className="planPrice">
                            <p>Currency</p>
                            <p>
                                <Currency currency={stripePlans?.currency?.value} />
                            </p>
                        </div>
                        <div className="planPrice p-0">
                            <p>Share type</p>
                            <p>
                                {stripePlans?.capital?.shareType === 1
                                    ? "Ordinary"
                                    : "Preference"}
                            </p>
                        </div>
                    </div>
                )}

                {stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.HEDGEKILOGRAM ? (
                    <>
                        <div className="planPrice usdTransferAnnual">
                            <p>Price</p>
                            <p>
                                <Currency currency={stripePlans?.currency?.value} />{" "}
                                <CurrencyFormat
                                    currency={stripePlans?.currency?.value}
                                    value={parseFloat(
                                        lockGoldPrice?.goldBidMapping?.gold1kg?.price
                                    )}
                                    decimal={2}
                                />
                            </p>
                        </div>
                        <div className="planPrice usdTransferAnnual pt-2">
                            <p>Cost of hedge</p>
                            <p>
                                <Currency currency={stripePlans?.currency?.value} />{" "}
                                <CurrencyFormat
                                    currency={stripePlans?.currency?.value}
                                    value={parseFloat(cartAmount?.price)}
                                    decimal={2}
                                />
                                {isOverrideProduct && " x " + quantityWatch?.value}
                            </p>
                        </div>
                        {
                            paymentMethodSelection !== SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD &&
                            <div className="planPrice usdTransferAnnual pt-2">
                                <p>Payable amount</p>
                                <p>
                                    <Currency currency={stripePlans?.currency?.value} />{" "}
                                    <CurrencyFormat
                                        currency={stripePlans?.currency?.value}
                                        value={parseFloat(cartAmount?.payableAmount)}
                                        decimal={2}
                                    />
                                </p>
                            </div>
                        }
                    </>
                ) : (
                    <div className="planPrice usdTransferAnnual">
                        <p>Price</p>
                        <p>
                            <Currency currency={stripePlans?.currency?.value} />{" "}
                            <CurrencyFormat
                                currency={stripePlans?.currency?.value}
                                value={parseFloat(cartAmount?.price)}
                                decimal={2}
                            />
                            {isOverrideProduct && " x " + quantityWatch?.value}
                        </p>
                    </div>
                )}
                {((paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD || (stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM || stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.GRAM)) ||
                    ((stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM || stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.GRAM) && (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO))) && (
                        <>
                            <div className="planPrice usdTransferAnnual pt-2">
                                <p>{(stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.KILOGRAM || stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.GRAM) && (paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO) ? 'Storage fee' : `Transaction fee (${cartAmount?.charges}%)`} </p>
                                {paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ?
                                    <p>Included</p>
                                    :
                                    stripePlans?.goldProductType?.value === PRODUCT_GOLD_TYPE.GRAM && paymentMethodSelection !== SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD ?
                                        <p>Included</p>
                                        :
                                        <p>
                                            <Currency currency={stripePlans?.currency?.value} />{" "}
                                            <CurrencyFormat
                                                currency={stripePlans?.currency?.value}
                                                value={parseFloat(cartAmount?.transactionFees)}
                                                decimal={2}
                                            />
                                        </p>
                                }
                            </div>
                            <div className="planPrice usdTransferAnnual pt-2">
                                <p>Payable amount</p>
                                <p>
                                    <Currency currency={stripePlans?.currency?.value} />{" "}
                                    <CurrencyFormat
                                        currency={stripePlans?.currency?.value}
                                        value={parseFloat(cartAmount?.payableAmount)}
                                        decimal={2}
                                    />
                                </p>
                            </div>
                        </>
                    )}
            </div>
            <div className="planTerms">
                <p>Please complete the steps below:</p>
                {paymentMethodSelection ===
                    SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER && (
                        <ol className="olPadding">
                            <li>
                                Feel free to check with us when sending funds. Please
                                ensure the amount and currency is accurate.
                            </li>
                            <li>Get bank account details on the next page.</li>
                            <li>Initiate the transfer of funds with your bank.</li>
                            <li>
                                InProved will notify you directly upon receipt of your
                                funds. To review your deposit status please refer to the
                                transaction tab in the payments page.
                            </li>
                        </ol>
                    )}
                {paymentMethodSelection ===
                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO && (
                        <ol className="olPadding">
                            <li>Select your preferred token and see the estimated amount to be sent.</li>
                            <li>Click “Pay with crypto” and complete your verification.</li>
                            <li>Get the token sending address and QR code. You will need to send within 20 mins of seeing the address and QR code.</li>
                        </ol>
                    )}

                {paymentMethodSelection ===
                    SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD && (
                        <ol className="olPadding">
                            <li>
                                Feel free to check with us when sending funds. Please
                                ensure the amount and currency is correct.
                            </li>
                            <li>
                                Enter your card details on the next page. Note that we
                                do not keep card details on InProved.com as the payment
                                is handled by payment provider stripe.com.
                            </li>
                            <li>
                                You will get a notification that card payment is
                                successfully. To review your payment status, please
                                refer to the transaction tab on the payments page.
                            </li>
                        </ol>
                    )}
            </div>
            <div className="planFooter">
                <p>
                    <a href={terms} target="_blank" rel="noopener noreferrer">
                        Terms and conditions
                    </a>
                </p>
                <p>
                    <a href={privacy} target="_blank" rel="noopener noreferrer">
                        Privacy policy
                    </a>
                </p>
            </div>
        </div >
    )
}
