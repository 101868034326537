import { GET_COMPANY_DASHBOARD, COMPANY_DASHBOARD_LOADING_SKELETON, SET_COMPANY_DASHBOARD_BULLION_RECORD, SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING, SET_COMPANY_DASHBOARD_METAL_ALLOCATION, SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING, SET_COMPANY_DASHBOARD_PROFIT_LOSS, SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING } from "../actions/types";
const initialState = {
  companyDashboard: {},
  isCompanyDashboardLoading: true,
  compnayBullionRecord: [],
  isCompanyDashboardBullionBalanceLoading: true,
  companyMetalAllocation: [],
  isCompanyDashboardMetalAllocationLoading: true,
  companyProfitAndLoss: {},
  isCompanyDashboardProfitLossLoading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_DASHBOARD:

      return {
        ...state,
        companyDashboard: payload,
        isCompanyDashboardLoading: false,
      };

    case COMPANY_DASHBOARD_LOADING_SKELETON:
      return {
        ...state,
        isCompanyDashboardLoading: payload,
      };

    case SET_COMPANY_DASHBOARD_BULLION_RECORD:
      return {
        ...state,
        compnayBullionRecord: payload,
        isCompanyDashboardBullionBalanceLoading: false
      };

    case SET_COMPANY_DASHBOARD_BULLION_RECORD_LOADING:
      return {
        ...state,
        isCompanyDashboardBullionBalanceLoading: payload
      };

    case SET_COMPANY_DASHBOARD_METAL_ALLOCATION:
      return {
        ...state,
        companyMetalAllocation: payload,
        isCompanyDashboardMetalAllocationLoading: false
      };

    case SET_COMPANY_DASHBOARD_METAL_ALLOCATION_LOADING:
      return {
        ...state,
        isCompanyDashboardMetalAllocationLoading: payload
      };

    case SET_COMPANY_DASHBOARD_PROFIT_LOSS:
      return {
        ...state,
        companyProfitAndLoss: payload,
        isCompanyDashboardProfitLossLoading: false
      };

    case SET_COMPANY_DASHBOARD_PROFIT_LOSS_LOADING:
      return {
        ...state,
        isCompanyDashboardProfitLossLoading: payload
      };

    default:
      return state;
  }
}
