import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { singleFileUploadAction } from "../../actions/files";
import {
  sendOtpAuthentication,
  updateUserProfileAction,
  verifOtpAction,
} from "../../actions/user";
import {
  backendUrl,
  country,
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
  VERIFICATION_TYPE,
} from "../../constants";
import Otp from "../OTP/Otp";
import SaveModal from "../savemodal/saveModal";
import Select from "react-select";
import { toast } from "react-hot-toast";

export default function EditProfile({
  proofModalType,
  triggerProofModalClose,
}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [otpShow, setOtpShow] = useState(false);
  const [holdUserNewData, setHoldUserNewData] = useState(true);
  const [otpPhoneNumber, SetOtpPhoneNumber] = useState(null);

  const {
    control,
    watch,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  watch(["profilePic", "identityProof", "addressProof"]);
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [phoneRequired, SetPhoneRequired] = useState(false);

  const transferAreaCode = watch("areaCode");
  const transferPhoneNumber = watch("phone");


  useEffect(() => {
    if ((transferAreaCode && Object.keys(transferAreaCode).length > 0) || ((transferPhoneNumber && transferPhoneNumber.length > 0) || (transferAreaCode===null && transferPhoneNumber.length > 0))) {
      SetPhoneRequired(true)
    } else {
      SetPhoneRequired(false)
    }

  }, [transferPhoneNumber, transferAreaCode])


  useEffect(() => {
    if (user.id && !proofModalType) {
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("email", user.email);
      setValue(
        "areaCode",
        user.areaCode
          ? country.filter((x) => x.dial_code === user.areaCode)[0] || ""
          : ""
      );
      setValue(
        "nationality",
        user.nationality
          ? country.filter((x) => x.name === user.nationality)[0] || ""
          : ""
      );
      setValue("profilePic", user.profilePic);
      setValue("address1", user.address1);
      setValue("address2", user.address2);
      setValue("city", user.city);
      setValue("zip", user.zip);
      setValue(
        "country",
        user.country
          ? country.filter((x) => x.name === user.country)[0] || ""
          : ""
      );
      setValue("phone", user.phone);
      setValue("identityProof", user.identityProof);
      setValue("addressProof", user.addressProof);
    }
  }, [user, setValue, proofModalType]);

  const saveModalClose = () => {
    setSaveModalShow(false);
    triggerProofModalClose();
  };

  const onSubmit = (data) => {
    var prevNumber = null;
    var newPhoneNumber = 'null';
    if (!proofModalType) {
      data.areaCode = data?.areaCode?.dial_code || null;
      prevNumber = user.areaCode + user.phone;
      newPhoneNumber = (data.areaCode + data.phone) || 'null';
      data.nationality = data.nationality?.name;
      data.country = data.country?.name;
    } else {
    }
    if (proofModalType > 0) {
      if (proofModalType === VERIFICATION_TYPE.ADDRESS) {
        if ("addressProof" in data) {
        } else {
          toast.error("Please select proof of address to continue.");
          return false;
        }
      } else if (proofModalType === VERIFICATION_TYPE.IDENTITY) {
        if ("identityProof" in data) {
        } else {
          toast.error("Please select proof of indentity to continue.");
          return false;
        }
      }
    }
    if (data.addressProof?.filename !== user.addressProof?.filename)
      data.isAddressVerified = false;
    if (data.identityProof?.filename !== user.identityProof?.filename)
      data.isIdentityVerified = false;

    let proceedSave = false;
    if (proofModalType > 0) { proceedSave = true }
    if (!proceedSave && newPhoneNumber === 'null') { proceedSave = true }
    if (!proceedSave && (prevNumber === newPhoneNumber)) { proceedSave = true }
    if (proceedSave) {
      if (!proofModalType) {
        data.phone = (data?.phone ? data?.phone : null)
      }
      dispatch(updateUserProfileAction(data));
      setSaveModalShow(true);
    } else {
      SetOtpPhoneNumber(newPhoneNumber);
      setHoldUserNewData(data);
      setOtpShow(true);
    }
  };

  const uploadFiles = (event, name) => {
    const file = event.target.files[0];
    const fileType = file.type.split("/");
    if (
      name === "profilePic" &&
      fileType.length > 0 &&
      fileType[0] !== "image"
    ) {
      toast.error("The type of the upload file should be an image.");
      event.target.value = null;
      return false;
    }
    uploadDispatchFile(file, name);
    event.target.value = null;
  };

  const uploadDispatchFile = (file, name) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(singleFileUploadAction(formData)).then((resp) => {
      if (resp) {
        setValue(name, resp);
      }
    });
  };

  // Validate OTP
  const ValidateOtp = async (otp) => {
    var payload = {
      otp: otp,
    };
    const verifyStatus = await dispatch(verifOtpAction(payload));
    if (verifyStatus) {
      setOtpShow(false);
      holdUserNewData.isPhonVerified = true;
      dispatch(updateUserProfileAction(holdUserNewData));
      setSaveModalShow(true);
      SetOtpPhoneNumber(null);
    }
  };

  useEffect(() => {
    sendOTP();
  }, [otpPhoneNumber]);

  // Sent OTP
  const sendOTP = () => {
    if (otpPhoneNumber) {
      const payload = {
        phoneNumber: otpPhoneNumber,
      };
      dispatch(sendOtpAuthentication(payload));
    }
  };

  const handleOtp = (value) => {
    if (value.length === 6) {
      ValidateOtp(value);
    }
  };

  const handleResendOtpClick = () => {
    sendOTP();
  };

  const saveModalCloseTrigger = () => {
    setSaveModalShow(false);
    window.location.reload(true)
  };

  return (
    <>
      <div className="card profile-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          {proofModalType >= 0 ? (
            <>
              {proofModalType === VERIFICATION_TYPE.MOBILE && (
                <div className="row">
                  <div className="col-lg-12 mt-3 mb-3">
                    <div className="form-group">
                      <p className="input-text heading">Country code</p>

                      <Controller
                        name="areaCode"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={country}
                            isClearable={true}
                            getOptionLabel={(option) =>
                              `${option.dial_code} (${option.name})`
                            }
                            getOptionValue={(option) => option.dial_code}
                            placeholder="Select"
                          />
                        )}
                      />

                      {/* <Form.Select
                        className="select-border"
                        aria-label="Default select example"
                        {...register("areaCode", {
                          required: true,
                        })}
                        style={{ paddingRight: "45px" }}
                      >
                        <option value="">Select Country Code</option>
                        {country.map((info, index) => (
                          <option key={index} value={info.dial_code}>
                            {info.dial_code}
                          </option>
                        ))}
                      </Form.Select> */}
                      {errors.areaCode?.type === "required" && (
                        <span className="errorMessage">
                          Select your country code
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3 mb-3">
                    <div className="form-group">
                      <p className="input-text heading">Mobile number</p>
                      <input
                        maxLength={50}
                        min="0"
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        placeholder="Mobile"
                        {...register("phone", {
                          required: true,
                        })}
                      />
                      {errors.phone?.type === "required" && (
                        <span className="errorMessage">
                          Mobile number is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {proofModalType === VERIFICATION_TYPE.ADDRESS && (
                <div className="row">
                  <div className="col-lg-12 mt-3 mb-3">
                    <p className="input-text heading">Proof of address</p>
                    <input
                      maxLength={50}
                      type="file"
                      autoComplete="off"
                      className="form-control"
                      id="identityProof"
                      onChange={(evt) => uploadFiles(evt, "addressProof")}
                    />
                    {getValues("addressProof") &&
                      getValues("addressProof")?.filename &&
                      getValues("addressProof")?.originalName && (
                        <p>
                          <a
                            href={
                              backendUrl + getValues("addressProof").filename
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {getValues("addressProof")?.originalName}
                          </a>
                        </p>
                      )}
                    <span className="file-conditions">
                      Secondary document for verification purposes. Accepted
                      files are utility bills, bank statements or mobile phone
                      bills less than 3 months from day of upload, or any
                      government issued card, all with name and residential
                      address printed
                    </span>
                  </div>
                </div>
              )}

              {proofModalType === VERIFICATION_TYPE.IDENTITY && (
                <div className="row">
                  <div className="col-lg-12 mt-3 mb-3">
                    <p className="input-text heading">Passport file</p>

                    <input
                      maxLength={50}
                      type="file"
                      autoComplete="off"
                      className="form-control"
                      id="identityProof"
                      onChange={(evt) => uploadFiles(evt, "identityProof")}
                    />
                    {getValues("identityProof") &&
                      getValues("identityProof")?.filename &&
                      getValues("identityProof")?.originalName && (
                        <p>
                          <a
                            href={
                              backendUrl + getValues("identityProof")?.filename
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {getValues("identityProof")?.originalName}
                          </a>
                        </p>
                      )}
                    <span className="file-conditions">
                      Note: Primary identification used for verification
                      purposes
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="row">
                {/* <<h3
              className="col-form-label profile-head mb-3"
            >
              Edit Account Information
            </h3>> */}
                <div className="col-lg-6 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">First name</p>
                    <input
                      maxLength={50}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      id="recipient-name"
                      {...register("first_name", {
                        required: true,
                        maxLength: 20,
                        minLength: 3,
                      })}
                    />
                    {errors.first_name?.type === "required" && (
                      <span className="errorMessage">
                        First name is required
                      </span>
                    )}
                    {errors.first_name?.type === "maxLength" && (
                      <span className="errorMessage">
                        First name may not be longer than 20 characters
                      </span>
                    )}
                    {errors.firstName?.type === "minLength" && (
                      <span className="errorMessage">
                        Minimum 3 characters required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Last name</p>
                    <input
                      maxLength={50}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      {...register("last_name", { required: true })}
                    />
                    {errors.last_name?.type === "required" && (
                      <span className="errorMessage">
                        Last name is required
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Login email</p>
                    <input
                      maxLength={50}
                      autoComplete="off"
                      readOnly
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <span className="errorMessage">Email is required</span>
                    )}
                    {errors.email?.type === "pattern" && (
                      <span className="errorMessage">Email is Invalid</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Country code</p>
                    <Controller
                      name="areaCode"
                      rules={{ required: phoneRequired }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={SELECT_BOX_THEME}
                          styles={CUSTOM_SELECT_STYLES}
                          className="select-border"
                          {...field}
                          options={country}
                          isClearable={true}
                          getOptionLabel={(option) =>
                            `${option.dial_code} (${option.name})`
                          }
                          getOptionValue={(option) => option.dial_code}
                          placeholder="Select"
                        />
                      )}
                    />
                    {('areaCode' in errors && 'phone' in errors) ? "" : errors.areaCode?.type === "required" && (
                      <span className="errorMessage">
                        Select your country code
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Mobile number</p>
                    <input
                      maxLength={50}
                      min="0"
                      autoComplete="off"
                      type="number"
                      className="form-control"
                      placeholder="Mobile"
                      {...register("phone", {
                        required: phoneRequired,
                      })}
                    />
                    {('areaCode' in errors && 'phone' in errors) ? "" : errors.phone?.type === "required" && (
                      <span className="errorMessage">
                        Mobile number is required
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Nationality</p>
                    <Controller
                      name="nationality"
                      rules={{ required: false }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={SELECT_BOX_THEME}
                          styles={CUSTOM_SELECT_STYLES}
                          className="select-border"
                          {...field}
                          options={country}
                          isClearable={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          placeholder="Select"
                        />
                      )}
                    />
                    {errors.nationality?.type === "required" && (
                      <span className="errorMessage">
                        Select your nationality
                      </span>
                    )}   
                  </div>
                </div>
                <div
                  className={`col-lg-${getValues("profilePic") &&
                    getValues("profilePic")?.filename &&
                    getValues("profilePic")?.originalName
                    ? 5
                    : 6
                    } mt-3`}
                >
                  <p className="input-text heading">Profile picture</p>

                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="file"
                    className="form-control"
                    id="profilePic"
                    accept="image/*"
                    onChange={(evt) => uploadFiles(evt, "profilePic")}
                  />
                </div>

                {getValues("profilePic") &&
                  getValues("profilePic")?.filename &&
                  getValues("profilePic")?.originalName && (
                    <div className="col-lg-1 mt-3">
                      <a
                        href={backendUrl + getValues("profilePic")?.filename}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          className="business-image hasLogo mt-4"
                          style={{
                            backgroundImage: `url(${backendUrl + getValues("profilePic")?.filename
                              })`,
                          }}
                        ></div>
                      </a>
                    </div>
                  )}
              </div>
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Address line</p>
                    <input
                      maxLength={50}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Address line"
                      {...register("address1", {
                        required: false,
                      })}
                    />
                    {errors.address1?.type === "required" && (
                      <span className="errorMessage">Enter your address 1</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">City</p>
                    <input
                      maxLength={50}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Singapore"
                      {...register("city", {
                        required: false,
                      })}
                    />
                    {errors.city?.type === "required" && (
                      <span className="errorMessage">Enter your city name</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Country</p>

                    <Controller
                      name="country"
                      rules={{ required: false }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={SELECT_BOX_THEME}
                          styles={CUSTOM_SELECT_STYLES}
                          className="select-border"
                          {...field}
                          options={country}
                          isClearable={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          placeholder="Select"
                        />
                      )}
                    />
                    {errors.nationality?.type === "required" && (
                      <span className="errorMessage">
                        Select your nationality
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 mt-3">
                  <div className="form-group">
                    <p className="input-text heading">Post code</p>
                    <input
                      maxLength={50}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Code"
                      {...register("zip", {
                        required: false,
                      })}
                    />
                    {errors.zip?.type === "required" && (
                      <span className="errorMessage">Enter your post code</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-3">
                  <p className="input-text heading">Proof of address</p>
                  {user?.isAddressVerified === false ? (
                    <p className=" text-sm ponit-text pending mt-0 mb-0">
                      Pending verification
                    </p>
                  ) : (
                    <input
                      maxLength={50}
                      type="file"
                      autoComplete="off"
                      className="form-control"
                      id="identityProof"
                      onChange={(evt) => uploadFiles(evt, "addressProof")}
                    />
                  )}

                  {getValues("addressProof") &&
                    getValues("addressProof")?.filename &&
                    getValues("addressProof")?.originalName && (
                      <p>
                        <a
                          href={backendUrl + getValues("addressProof").filename}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getValues("addressProof")?.originalName}
                        </a>
                      </p>
                    )}
                  <span className="file-conditions">
                    Secondary document for verification purposes. Accepted files
                    are utility bills, bank statements or mobile phone bills
                    less than 3 months from day of upload, or any government
                    issued card, all with name and residential address printed
                  </span>
                </div>
                <div className="col-lg-6 mt-3">
                  <p className="input-text heading">Passport file</p>

                  {user?.isIdentityVerified === false ? (
                    <p className=" text-sm ponit-text pending mt-0 mb-0">
                      Pending verification
                    </p>
                  ) : (
                    <input
                      maxLength={50}
                      type="file"
                      autoComplete="off"
                      className="form-control"
                      id="identityProof"
                      onChange={(evt) => uploadFiles(evt, "identityProof")}
                    />
                  )}
                  {getValues("identityProof") &&
                    getValues("identityProof")?.filename &&
                    getValues("identityProof")?.originalName && (
                      <p>
                        <a
                          href={
                            backendUrl + getValues("identityProof")?.filename
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getValues("identityProof")?.originalName}
                        </a>
                      </p>
                    )}
                  <span className="file-conditions">
                    Note: Primary identification used for verification purposes
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="row">
            {proofModalType >= 0 && (
              <div className="col-lg-6 col-sm-6 col-xs-6 col-6 mt-3">
                <button
                  type="button"
                  onClick={triggerProofModalClose}
                  className="btn btn-sm  mb-4 mt-3 contactus-modal-button"
                >
                  Cancel
                </button>
              </div>
            )}
            <div className="col-lg-6 col-sm-6 col-xs-6 col-6 mt-3">
              <button type="submit" className="btn agree-button accountBtn">
                {proofModalType >= 0 ? "Continue" : "Save changes"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <SaveModal
        saveModalShow={saveModalShow}
        saveModalClose={saveModalCloseTrigger}
      />
      <Otp
        otpShow={otpShow}
        otpClose={() => setOtpShow(false)}
        handleOtp={handleOtp}
        resendOtpClick={handleResendOtpClick}
      />
    </>
  );
}
