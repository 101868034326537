import React from "react";
export default function SuccessSignNow() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 Maincontainer">
            <div className="Innercontainer">
              <div className="Mainheading">
                E-Sign document signed successfully
              </div>
              <div className="msgicon">
                <i className="fa-solid fa-envelope"></i>
              </div>
              <div className="declaration">
                <>Thank you for signing the document.</>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
}
