import React from "react";
import { Link } from "react-router-dom";

export default function Footer({linkObj}) {
  return (
    <>
      <div className="footer-row">
        <h6 className="looking-text-bottom">{linkObj?.head ? linkObj?.head : "Looking for something else?"}</h6>
        {linkObj?.title && linkObj?.link  && (
          <Link to={linkObj?.link} className=" icon-move-right mt-auto">
            <p className="manage-share-text">
              {linkObj?.title}
              <i
                className="fas fa-arrow-right manage-share-text  ms-1"
                aria-hidden="true"
              ></i>
            </p>
          </Link>
        )}

        {linkObj?.title2 && linkObj?.link2  && (
          <Link to={linkObj?.link2} className=" icon-move-right mt-auto">
            <p className="manage-share-text">
              {linkObj?.title2}
              <i
                className="fas fa-arrow-right manage-share-text  ms-1"
                aria-hidden="true"
              ></i>
            </p>
          </Link>
        )}
      </div>
    </>
  );
}
