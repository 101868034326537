import React from "react";
import { Modal } from "react-bootstrap";
import ContactUs from "../../components/ContactUs/ContactUs";
import { CONTACT_US_TRIGER } from "../../constants";

export default function DeletePromoter({
  removeProModalShow,
  removeProModalClose,
  removeProModalCloseConfirm,
  actionData,
}) {
  return (
    <>
      <Modal
        show={removeProModalShow}
        onHide={removeProModalClose}
        className="custom-modal delete-information-width"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={removeProModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <h5 className="edit-title">
            Are you sure you want to remove this promoter?
          </h5>
          <div className="row">
            <div className="col-lg-12">
              <p className="input-text heading">Promoter Name</p>
              <p className="col-form-label company-text p-0 m-0">
                {(actionData && actionData.first_name
                  ? actionData.first_name
                  : "") +
                  " " +
                  (actionData && actionData.first_name && actionData.last_name
                    ? actionData.last_name
                    : "")}
              </p>
            </div>
          </div>

          <p className="import-note">
            Important note:{" "}
            <span className="will-need-text">
              We will send you notifications to confirm the amendment
            </span>
          </p>

          <div className="modal-footer modal-bottom-action">
            <ContactUs  addEditValue="promoter" type={CONTACT_US_TRIGER.REMOVE_SHARE_DIRETOR_PROMOTER} handleClose={removeProModalClose} />
            <button
              onClick={removeProModalCloseConfirm}
              type="button"
              className="btn agree-button"
            >
              Agree & Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
