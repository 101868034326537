import axionIns from "../axios";

const getInvite = async (companyId) => {
  return axionIns.get("invite/" + companyId).then((response) => {
    return response.data;
  });
};

const addInvite =async  (companyId, payload) => {
  return axionIns
    .post("invite/" + companyId, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const updateInvite =async  (companyId, inviteId, payload) => {
  return axionIns
    .put("invite/" + companyId + "/" + inviteId, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const deleteCompanyInvite =async  (companyId, payload) => {
  return axionIns
    .post("company/removePromoter/" + companyId, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const deleteInvite =async  (companyId, payload) => {
  return axionIns
    .delete("invite/" + companyId + "/" + payload)
    .then((response) => {
      return response.data;
    });
};

const denyPendingInvite = async (companyId, payload) => {
  return axionIns
    .delete("invite/deny/" + companyId + "/" + payload)
    .then((response) => {
      return response.data;
    });
};

const addInviteAccept = async (token) => {
  return axionIns.post("invite/accept/" + token, {}).then((response) => {
    return response.data;
  });
};

const getInviteByToken = async (token) => {
  return axionIns.get("auth/invite/token/" + token).then((response) => {
    return response.data;
  });
};

const getpendingInvite =async  () => {
  return axionIns.get("invite/pending").then((response) => {
    return response.data;
  });
};

const appointPromoter = async (companyId, payload, proposedPosition) => {
  return axionIns
    .put("invite/requestAppoint/" + companyId + "/" + payload, { proposedPosition: proposedPosition })
    .then((response) => {
      return response.data;
    });
};

const getPendingInvites = async (companyId, payload) => {
  return axionIns
    .get("invite/pendinginvites/" + companyId + "/" + payload)
    .then((response) => {
      return response.data;
    });
};

const addPromoterChannels = async (payload) => {
  return axionIns.post("invite/initiatechat", JSON.stringify(payload)).then((response) => {
    return response.data
  })
}
const exportOject = {
  getInvite,
  addInvite,
  updateInvite,
  deleteCompanyInvite,
  deleteInvite,
  addInviteAccept,
  getInviteByToken,
  getpendingInvite,
  appointPromoter,
  getPendingInvites,
  addPromoterChannels,
  denyPendingInvite
};

export default exportOject;
